import moment from "moment";

export const subCategories = [
  "Breathing",
  "Moving",
  "Acknowledging",
  "Belonging",
  "Working",
  "Learning",
  "Noticing",
  "Meaning",
  "Giving",
  "Relating",
  "Caring",
  "Communicating"
];

export const invalidChars = [
  "-",
  "+",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0",
];

export function greeting() {
  const hour = moment().hour();
  if (hour > 15) {
    return "Good evening";
  }

  if (hour > 11) {
    return "Good afternoon";
  }

  return 'Good morning';
}

export const interestToolTipBrief = {
  Breathing: "Promoting relaxation, focus and better sensory awareness as you allow yourself to be more present.",
  Moving: "Understanding the importance of physical movement and how to incorporate small steps into your busy life.",
  Acknowledging: "Increasing emotional intelligence and honing abilities to identify, manage and acknowledge emotions.",
  Belonging: "Cultivating your sense of social identity and connectedness within your workplace, community or a group.",
  Working: "Promoting fulfilment in your work and chosen careers through activities that bring out your best talents and skills.",
  Learning: "Boosting your abilities and skills and opening the mind to new ideas and experiences to benefit total well-being.",
  Noticing: "Noticing, or becoming aware, of your immediate surroundings to get out of auto-pilot mode and be present with your senses.",
  Meaning: "Exploring and finding meaning in your life's guiding beliefs, values and principles as they align with your everyday.",
  Giving: "Boosting total well-being by focusing on what you have to offer to others from small everyday acts to larger commitments.",
  Relating: "Adding value to life by building meaningful relationships with people at home, work and community.",
  Caring: "Cultivating your well-being by building interconnections to your community and environment through care and compassion.",
  Communicating: "Creating positive internal and external dialogues, better listening skills and an understanding of communication gaps."
}

export const instructorSurvey = {
  "question-1": {
    description: "What Do You Plan to Contribute as a Well-being Provider to the Network? (e.g. programs, courses, content, counselling/therapy support etc.)",
    answer: ""
  },
  "question-2": {
    description: "Do you have a graduate/post-graduate degree in counselling, clinical psychology?",
    answer: ""
  },
  "question-3": {
    description: `Do you have certification/s as a Well-being Provider? (e.g. diploma in positive psychology, cognitive behavioral therapy (CBT), etc.)`,
    answer: ""
  }
}

export const roles = {
  'wp': 'Well-being Provider',
  'ps': 'Peer Supporter',
  'wc': 'Well-being Champion'
}

/**
 * Field on registration form that words will be automatically capitalized
 * @type {string[]}
 */
export const AUTOCAPITALIZED_FIELD = [
  "firstName",
  "lastName",
  "jobTitle"
]

// Array of free email domains to be excluded when users
// try to register
export const freeEmailDomains = [
  "intellect", 
  "mindfi", 
  "gmail", 
  "yahoo", 
  "hotmail", 
  "live",
  "windowslive", 
  "outlook", 
  "icloud", 
  "aol",
  "msn"
]
