import React from 'react';

import SideNav from '../../component/staticPages/SideNav';

import "./superAdmin.css";

import SmEmotions from "../../assets/svg/SmEmotions.svg";
import SmBelonging from "../../assets/svg/SmBelonging.svg";
import SmBreathing from "../../assets/svg/SmBreathing.svg";
import SmMoving from "../../assets/svg/SmMoving.svg";

import DataSort from '../../assets/svg/SuperAdmin/DataSort.svg';
import RedInfo from '../../assets/svg/SuperAdmin/RedInfo.svg';
import Attechment from '../../assets/svg/SuperAdmin/Attechment.svg';
import Mail from '../../assets/svg/SuperAdmin/Mail.svg';
import Accept from '../../assets/svg/SuperAdmin/Accept.svg';
import Reject from '../../assets/svg/SuperAdmin/Reject.svg';
import Delete from '../../assets/svg/SuperAdmin/Delete.svg';

const huddleList = () => {
    return (
        <>
            <div className='row m-0 d-flex newsfeed_bg time_exchange_bg_full_height pb-5'>

                <div className='news_left p-0'>

                    <SideNav />

                </div>

                <div className='news_right time_exchange_auto_height p-0'>

                    <div className='row m-0'>
                        <div className='col-12 p-5'>

                            <div className='row m-0 mt-4'>
                                <div className='col-12 p-0'>
                                    <p className='mb-0 sup_adm_heading'>Event List</p>
                                </div>
                            </div>

                            <div className='row m-0 mt-4'>
                                <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                    <div className='sup_adm_hdl_swap_btn_sec px-4 d-flex align-items-center justify-content-between'>

                                        <button className='supadm_hdl_swap_btn supadm_hdl_swap_btn_act'>Happening now</button>
                                        <button className='supadm_hdl_swap_btn'>On schedule</button>
                                        <button className='supadm_hdl_swap_btn'>History</button>

                                    </div>
                                </div>
                            </div>

                            <div className='row m-0 mt-2'>
                                <div className='col-12 p-0'>
                                    <div className='row m-0 mt-3'>

                                        <div className="col-12 p-0 ps-2 pt-2 pb-2 d-flex align-itmes-center experts_event_by_overflow">
                                            <button className="ps-3 pe-3 pt-2 pb-2 me-4 experts_filter_by_event active_experts_filter_by_event">All</button>
                                            <button className="ps-3 pe-3 pt-2 pb-2 me-4 experts_filter_by_event d-flex align-items-center"> <img className="me-3" src={SmEmotions} alt="" /> Emotions</button>
                                            <button className="ps-3 pe-3 pt-2 pb-2 me-4 experts_filter_by_event d-flex align-items-center"> <img className="me-3" src={SmBelonging} alt="" /> Belonging</button>
                                            <button className="ps-3 pe-3 pt-2 pb-2 me-4 experts_filter_by_event d-flex align-items-center"> <img className="me-3" src={SmBreathing} alt="" /> Breathing</button>
                                            <button className="ps-3 pe-3 pt-2 pb-2 me-4 experts_filter_by_event d-flex align-items-center"> <img className="me-3" src={SmMoving} alt="" /> Moving</button>
                                            <button className="ps-3 pe-3 pt-2 pb-2 me-4 experts_filter_by_event d-flex align-items-center"> <img className="me-3" src={SmMoving} alt="" /> Working</button>
                                            <button className="ps-3 pe-3 pt-2 pb-2 me-4 experts_filter_by_event d-flex align-items-center"> <img className="me-3" src={SmMoving} alt="" /> Learning</button>
                                            <button className="ps-3 pe-3 pt-2 pb-2 me-4 experts_filter_by_event d-flex align-items-center"> <img className="me-3" src={SmMoving} alt="" /> Noticing</button>
                                            <button className="ps-3 pe-3 pt-2 pb-2 me-4 experts_filter_by_event d-flex align-items-center"> <img className="me-3" src={SmMoving} alt="" /> Meating</button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className='row m-0 mt-4'>
                                <div className='col-12 p-0'>

                                    <div className='row m-0 sup_adm_tbl_head d-flex align-items-center'>
                                        <div className='col-3 p-0 ps-5'>
                                            <p className='mb-0 ms-1 sup_adm_tblhd_heading'>Event Name &nbsp; <img src={DataSort} alt='' /> </p>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <p className='mb-0 sup_adm_tblhd_heading'>Hosted by &nbsp; <img src={DataSort} alt='' /></p>
                                        </div>
                                        <div className='col-1 p-0'>
                                            <p className='mb-0 sup_adm_tblhd_heading'>Date &nbsp; <img src={DataSort} alt='' /></p>
                                        </div>
                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 sup_adm_tblhd_heading'>Participants &nbsp; <img src={DataSort} alt='' /></p>
                                        </div>
                                        <div className='col-1 p-0 d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 sup_adm_tblhd_heading'>Category &nbsp; <img src={DataSort} alt='' /></p>
                                        </div>
                                    </div>

                                    <div className='row m-0 sup_adm_tbl_head sup_adm_tbl_rows d-flex align-items-center'>
                                        <div className='col-3 p-0 ps-4 d-flex align-items-center'>
                                            <div className="sup_red_info_img">
                                                <img src={RedInfo} alt='' />
                                            </div>
                                            <p className='mb-0 ms-2 sup_adm_tbl_name'>Theresa Lim</p>
                                        </div>
                                        <div className='col-3 p-0 d-flex align-items-center'>
                                            <p className='mb-0 sup_adm_tbl_curr_job'>Consultant at Company X</p>
                                        </div>
                                        <div className='col-1 p-0 d-flex align-items-center'>
                                            <button className='sup_adm_exp_btn p-0 m-0'>Theraphist</button>
                                        </div>
                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 sup_adm_tbl_status'>Rejected</p>
                                        </div>
                                        <div className='col-1 p-0 d-flex align-items-center justify-content-center'>
                                            <img src={Attechment} alt='' />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </>
    );
};

export default huddleList;