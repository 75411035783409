import React, {useEffect, useState} from 'react';
import '../../../css/setting.css';
import {useNavigate} from "react-router-dom";

import Account from '../../../assets/account.png';
import {deleteFriend, getAllFriends} from '../../../utils/apiUrlsSetup';
import AddFriendModal from './component/addFriend-modal';
import LoggedInLayout from "../../../component/layout/LoggedInLayout.tsx";

const Setting = () => {

    const navigate = useNavigate();

    const [tab, setTab] = useState(5);

    //const [noEvenetData, setnoEvenetData] = useState(true)

    const [friendListshow, setFriendListshow] = useState([]);
    const [show, setShow] = useState(false);

    const [showadd, setShowadd] = useState(false);

    const [addfriendModal, setAddFriendModal] = useState(false);

    const handleShow = () => setShow(true);

    const handleClose = () => setShow(false);

    const [isShowInviteFrndModal, setIsShowInviteFrndModal] = useState(false);


    const handleShowAdd = () => {

        setShow(false)
        setShowadd(true);
    }

    useEffect(() => {
        getFriendList();
    }, [])

    const getFriendList = async () => {
        let responseData = await getAllFriends();
        if (responseData.status === 200) {
            setFriendListshow(responseData.data.data)
        }

    }

    const handleUnfriend = async (id) => {
        let objData = {}
        objData.userId = id
        let responseData = await deleteFriend(objData);
        if (responseData.status === 200) {
            getFriendList();
        }

    }

    const handleViewProfile = (id) => {
        navigate(`/friends/${id}`)
    }

    const handleInviteModal = () => {
        setIsShowInviteFrndModal(true)
    }

    return (
        <LoggedInLayout title={"Friends"}>
            <div className='row g-4'>
                <div className='col-3 d-none d-lg-block' onClick={() => setTab(5)}>
                    <div className='shadow-sm bg-white tw-h-[80vh] tw-rounded-tl-sm tw-rounded-bl-sm'>
                        <div
                            className={`d-flex px-2 py-3 justify-content-start align-items-start border-bottom
                            ${tab === 5 ? "tw-border-e-2 tw-border-[#109CF1]" : ""}`}
                        >
                            <img src={Account}/>

                            <div className='setting_details ms-1'>
                                <p className={`mb-0 ${tab === 5 ? "active_setting_name" : "setting_name"}`}>Friends</p>
                                <p className='mb-0 setting_decription'>Your Friends List</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-12 col-lg-9'>
                    <div className='shadow-sm bg-white tw-rounded-tr-sm tw-rounded-br-sm'>
                        {
                            tab === 1 && <>
                                <div className='row m-0'>
                                    <div className='col-12 p-0 p-4 Border'>
                                        <p className='mb-0 paymenet_heading'>Payment preferences</p>
                                    </div>

                                </div>

                                <div className='row m-0'>
                                    <div className='col-12 p-0 p-4'>
                                        <p className='mb-0 paymenet_details'>You haven’t set your payment
                                            preferences.<br/>
                                            Click “Add Payment Method” to set your first payment preferences.</p>
                                    </div>

                                </div>
                                <div className='row m-0 mt-5'>
                                    <div className='col-6 p-0 mt-5 p-4'>

                                        <button className="btn payment_add_btn" onClick={handleShow}>
                                            Add Payment Method
                                        </button>

                                        <div className={`modal fade ${show ? 'show' : ''}`} style={{
                                            display: show ? 'block' : 'none',
                                            background: show ? 'rgba(0,0,0,0.5)' : ''
                                        }} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                                             aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content modal_cont">
                                                    <div className="modal-header p-4">
                                                        <h5 className="modal-title set_payment_heading"
                                                            id="exampleModalLabel">Set payment preferences</h5>

                                                    </div>
                                                    <div className="modal-body p-4">
                                                        <div className='row m-0 mt-2'>
                                                            <div className='col-12 p-0'>
                                                                <label className="form-label payment_lable">Payment
                                                                    method:</label>
                                                                <select className="form-select payment_input"
                                                                        aria-label="Default select example">
                                                                    <option selected value="1">Paypal</option>
                                                                    <option value="2">Stripe</option>
                                                                    <option value="3">cash</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-4'>
                                                            <div className='col-12 p-0'>
                                                                <label className="form-label payment_lable">PayPal
                                                                    email address:</label>
                                                                <input type="email"
                                                                       className="form-control payment_input"
                                                                       placeholder="name@example.com"></input>

                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-4'>
                                                            <div className='col-12 p-0'>
                                                                <label className="form-label payment_lable">PayPal
                                                                    email address:</label>
                                                                <input type="email"
                                                                       className="form-control payment_input"
                                                                       placeholder="+65 83292364"></input>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div
                                                        className="modal-footer d-flex justify-content-center align-items-center pb-5">
                                                        <button className="btn payment_form_cancel"
                                                                onClick={handleClose}>Close
                                                        </button>
                                                        <button type="button"
                                                                className="btn payment_form_save">Save
                                                        </button>

                                                        <button className="btn payment_form_save"
                                                                onClick={handleShowAdd}>
                                                            Save
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`modal fade ${showadd ? 'show' : ''}`} style={{
                                            display: showadd ? 'block' : 'none',
                                            background: showadd ? 'rgba(0,0,0,0.5)' : ''
                                        }} id="exampleModal1" data-bs-keyboard="false" tabIndex="-1"
                                             aria-labelledby="exampleModalLabel1" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered main_modal modal-lg">
                                                <div className="modal-content add_modal ps-3 pe-3">
                                                    <div className="modal-header add_modal_header mt-5">
                                                        <h5 className="modal-title add_modal_title"
                                                            id="exampleModal1"></h5>
                                                    </div>
                                                    <div
                                                        className="modal-body ps-3 pe-3 p-0 d-flex flex-column justify-content-end align-items-center mt-4">
                                                        <p className='mb-0 add_modal_heading text-center'>Payment
                                                            preferences have been added</p>
                                                        <p className='mb-0 mt-2 add_modal_details text-center'>Check
                                                            your email and make a confirmation within 24 hours to
                                                            validate this change.</p>
                                                    </div>
                                                    <div
                                                        className="modal-footer d-flex justify-content-center align-items-center">
                                                        <button className="btn add_payment_btn"
                                                                data-bs-dismiss="modal">Ok
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='col-6 p-0 mt-5 p-4 d-flex justify-content-end'>
                                        <button className='payment_save_btn'>Save</button>

                                    </div>

                                </div>
                            </>
                        }

                        {/* ------------------------------frnd----------------------------------- */}

                        {
                            tab === 5 && <div className={"d-flex flex-column tw-h-[80vh]"}>
                                <div className="flex-shrink-0 border-bottom p-3 d-flex flex-column gap-3">
                                    <div
                                        className="d-flex fw-semibold montserrat align-items-center justify-content-end tw-text-[rgb(16,156,241)] tw-text-sm"
                                    >
                                        <div
                                            onClick={() => setAddFriendModal(true)}
                                            className={"tw-cursor-pointer"}>
                                            Add Friends <i className={"bi bi-plus-lg ms-2"}></i>
                                        </div>
                                    </div>

                                    <div className="input-group mb-3 bg-light rounded">
                                        <div className="input-group-text border-0">
                                            <i className="bi bi-search text-secondary opacity-50"/>
                                        </div>
                                        <input type="text"
                                               className="form-control setting_frnd_search border-0 bg-transparent"
                                               placeholder="Search friends"
                                        />
                                    </div>
                                </div>

                                <div className="flex-grow-1 overflow-auto">
                                    {
                                        friendListshow.map((list, i) => {
                                            return (
                                                <div className='border-bottom' key={i}>
                                                    <div className="p-3">
                                                        <div className='row'>
                                                            <div
                                                                className='col-lg-10 col-8 d-flex align-items-center justify-content-start gap-3'>

                                                                <img src={list.profilePic ? list.profilePic : "/static/png/Male.png"}
                                                                     style={{
                                                                         width: "35px",
                                                                         height: "35px"
                                                                     }}
                                                                     className={"rounded-circle object-fit-cover tw-object-center"}
                                                                     alt=""/>

                                                                <p className='mb-0 montserrat tw-text-sm'>{list.firstName}</p>
                                                            </div>

                                                            <div
                                                                className='col-lg-2 col-4 d-flex align-items-center justify-content-end'>

                                                                <button className='settings_friend_btn dropdown-toggle'
                                                                        type="button"
                                                                        id={`settings_friend_btn_drodwn_${list.id}`}
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false">
                                                                    Friend
                                                                </button>

                                                                <ul className="dropdown-menu"
                                                                    aria-labelledby={`settings_friend_btn_drodwn_${list.id}`}>
                                                                    <li className="dropdown-item settings_friend_btn_ul_li"
                                                                        onClick={() => handleViewProfile(list.id)}>View
                                                                        Profile
                                                                    </li>
                                                                    <li className="dropdown-item settings_friend_btn_ul_li"
                                                                        onClick={() => handleUnfriend(list.id)}>Unfriend
                                                                    </li>
                                                                </ul>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <AddFriendModal addfriendModal={addfriendModal} showCancelBtn closeModal={setAddFriendModal}/>
        </LoggedInLayout>
    )

}
export default Setting;
