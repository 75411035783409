import React, { useState } from 'react'
import NotificationBellResponsive from "../../../assets/svg/NotificationBellResponsive.svg";
import HamburgerResponsive from "../../../assets/svg/HamburgerResponsive.svg";
import EmojiClaver from "../../../assets/svg/EmojiClaver.svg";

import LogoTab from "../../../assets/Logo_Tab.png";

import Feeling from '../feeling/feeling';
import Connecting from '../connecting/connecting';
import Functioning from '../functioning/functioning';

import Event from '../component/event';
import ResponsiveNav from '../../../component/staticPages/responsiveNavBar/responsiveNav';

const CommunityTabMobile = (props) => {

    const [showpages, setShowpages] = useState(1)

    return (
        <div>
            <div className='row m-0 pb-5 pb-md-0 pb-lg-0 mob_tab_responsive_view responsive_background'>
                <div className='col-12 p-0 pb-5'>

                    {/* <div className='row m-0 responsive_nav_bottom_border'>
                        <div className='col-12 p-0 ps-4 pe-4'>

                            <div className='row m-0 pt-3 pb-3 pt-md-4 pb-md-4'>
                                <div className='col-4 p-0 d-flex align-items-center'>
                                    <img className='responsive_nav_logo' config={LogoTab} alt='' />
                                </div>

                                <div className='col-4 p-0 d-flex align-items-center justify-content-center'>
                                    <p className='mb-0 responsive_page_name'>Community</p>
                                </div>

                                <div className='col-4 p-0 d-flex align-items-center justify-content-end'>
                                    <img className='me-4 responsive_nav_notification_bell' config={NotificationBellResponsive} alt='' />
                                    <img className='responsive_nav_hamburger' config={HamburgerResponsive} alt='' />
                                </div>
                            </div>

                        </div>
                    </div> */}

                    <ResponsiveNav heading="Experiences" head="Experiences" />


                    <div className='row m-0'>
                        <div className='col-12 p-0'>

                            <div className='row m-0 ps-3 pe-3 ps-md-0 pe-md-0 pt-4 pb-4 d-flex align-items-center justify-content-center'>
                                <div className='col-12 col-md-7 p-1 responsive_tab_bar ps-md-0 pe-md-0'>
                                    <button className={`responsive_tab_bar_btn ${showpages === 1 ? "active_responsive_tab_bar_btn" : ""}`}onClick={()=>setShowpages(1)}>Based on Categories</button>
                                    <button className={`responsive_tab_bar_btn ${showpages === 2 ? "active_responsive_tab_bar_btn" : ""}`} onClick={()=>setShowpages(2)}>Recommended For You</button>
                                </div>
                            </div>

                            <div className='row m-0 hide_in_mobile mb-3'>
                                <div className='col-12 p-0 ps-3 pe-3 ps-md-4 pe-md-4'>
                                    <p className='mb-0 responsive_inside_main_heading'>Experiences</p>
                                </div>
                            </div>

                            {/* <div className='row m-0 mt-4 ps-4 pe-4'>
                                <div className='col-12 p-3 ps-5 pe-5 responsive_triple_tab_section d-flex align-items-center justify-content-between bg-info'>
                                    <p className='mb-0 leaderboard_time_day leaderboard_active_day'>Feeling</p>
                                    <p className='mb-0 leaderboard_time_day'>Functioning</p>
                                    <p className='mb-0 leaderboard_time_day'>Connecting</p>
                                </div>
                            </div>
                            
                            <div className='row m-0 mt-4 ps-4 pe-4'>
                                <div className='col-12 p-0'>
                                    <p className='mb-0 resp_sub_heading'>Well-Being Destination (Feeling)</p>
                                    <p className='mb-0 mt-2 resp_sub_info'>Visit these destinations to <span>elevate your feeling</span> of achieving total well-being.</p>
                                </div>
                            </div> */}
                            {
                                showpages === 1 && <>
                                    <div className='row m-0 mt-md-4'>
                                        <div className='col-12 p-0 ps-3 pe-3 ps-md-4 pe-md-4'>

                                            <div className='community_destination_box'>

                                                <div className='row m-0'>

                                                    <div className='col-12 p-3 ps-md-5 pe-md-5 leaderboard_time responsive_triple_tab_section d-flex align-items-center justify-content-between'>
                                                        <p className={`mb-0 leaderboard_time_day ${props.communityPage === 1 ? 'leaderboard_active_day' : ''}`} onClick={() => { props.handleClick(1) }}>Feeling</p>
                                                        <p className={`mb-0 leaderboard_time_day ${props.communityPage === 2 ? 'leaderboard_active_day' : ''}`} onClick={() => { props.handleClick(2) }}>Functioning</p>
                                                        <p className={`mb-0 leaderboard_time_day ${props.communityPage === 3 ? 'leaderboard_active_day' : ''}`} onClick={() => { props.handleClick(3) }}>Connecting</p>
                                                    </div>

                                                </div>

                                                <div className='row m-0'>
                                                    <div className='col-12 p-0'>

                                                        {
                                                            props.communityPage === 1 && <Feeling />
                                                        }

                                                        {
                                                            props.communityPage === 2 && <Functioning />}

                                                        {
                                                            props.communityPage === 3 && <Connecting />
                                                        }

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className='row m-0 mt-4'>
                                        <div className='col-12 p-0 ps-3 pe-3 ps-md-4 pe-md-4'>
                                            <p className='mb-0 resp_sub_heading'>Take me to a random room today!</p>

                                            <button className='resp_wbd_bottom_btn mt-3'> <img config={EmojiClaver} alt='' /> Get me to a random room!</button>
                                        </div>
                                    </div> */}
                                </>
                            }

                            {
                                showpages === 2 && <div className="row m-0 mt-2">
                                    <div className="col-12 p-0 ps-3 pe-3 ps-md-4 pe-md-4">

                                        <Event />

                                    </div>
                                </div>
                            }

                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default CommunityTabMobile