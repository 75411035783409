import React, { useState, useEffect } from 'react';

import "../../../css/notification.css";

import { getNotifications, respondToFriendRequest, changeNotificationStatus } from '../../../utils/apiUrlsSetup';

import NotificationWeb from './notification-web';
import { useNavigate } from 'react-router-dom';
import {HOME} from "../../../config/ROUTES";

const getStatusName = (value) => {
    if (value === "friend-request") {
        return {
            name: "Friend Request",
            color: "yellow_btn",
        };
    }

    else if (value === "time-exchange") {
        return {
            name: "Time Exchange",
            color: "dark_blue_btn",
        };
    }

    else if (value === "well-being") {
        return {
            name: "Well-Being",
            color: "red_btn",
        };
    }

    else if (value === "journey") {
        return {
            name: "Journey",
            color: "green_btn",
        };
    }

    else if (value === "expert" || value === "Supporter") {
        return {
            name: "Supporter",
            color: "dark_pink_btn",
        };
    }

    else if (value === "organisation") {
        return {
            name: "Community",
            color: "sky_blue_btn",
        };
    }

    else if (value === "community") {
        return {
            name: "Community",
            color: "sky_blue_btn",
        };
    }

    else if (value === "my-kaliedoscope") {
        return {
            name: "myKaleidoscope",
            color: "green_btn",
        };
    }

    else if (value === "event") {
        return {
            name: "Experiences",
            color: "sky_blue_btn",
        };
    }

    else if (value === "experience") {
        return {
            name: "Experiences",
            color: "dark_blue_btn",
        };
    }

    else if (value === "giving") {
        return {
            name: "Giving",
            color: "green_btn",
        };
    }

    else if (value === "admin") {
        return {
            name: "Admin",
            color: "dark_blue_btn",
        };
    }

    else {
        return {
            name: "",
            color: "",
        };
    }

}

const getTitle = (value, obj) => {
    let title = value.split(" ");
    if (obj.firstName === "Super") {
        obj.firstName = "Super Admin";
    }
    title[0] = `${obj.firstName}`;

    if (value === "You are invited to organisation") {
        return value;
    }
    else {
        return title.join(" ");
    }
}

const getSubTitle = (value, obj, accept, reject, type) => {
    if (value === "Friend request received") {
        return ""
        // return <p className='mb-0 notification_responde'>Respond to {obj.firstName}: &nbsp;&nbsp; <span className='req_accept cursor_pointer' onClick={() => accept(obj.userId)}>Accept &nbsp; </span><span className='req_reject cursor_pointer' onClick={() => reject(obj.userId)}>| &nbsp; Decline</span></p>
    }

    else if (type === "time-exchange") {
        return "Use your free hours to book a session with Vanessa for free."
    }

    else if (type === "expert" || type === "journey") {
        return "You get an extra 4H free to spend on coaching sessions."
    }

    else if (type === "well-being") {
        return "Invite more friends and get 2H per friend invited, up to 20H for free!"
    }
}

const Notification = () => {

    const [notfications, setNotifications] = useState([]);

    const [limit, setLimit] = useState(10);

    const navigate = useNavigate();

    const [count, setCount] = useState("");

    useEffect(() => {
        changeStatusOfNotifications();
    }, []);

    const changeStatusOfNotifications = async () => {
        let obj = {
            status: 'read'
        }
        let response = await changeNotificationStatus(obj);
        if (response.status === 200) {
            getAllNotifications(limit);
        }
    }

    const fetchMoreData = () => {
        setLimit(limit + 5);
        setTimeout(() => {
            getAllNotifications(limit + 5);
        }, 1000);
    };

    const getAllNotifications = async (limit) => {
        let resData = await getNotifications(limit);
        if (resData.status === 200) {
            setCount(resData.data.data.count);
            setNotifications(resData.data.data.notificationArray);
        }
    }

    const accept = async (id) => {
        let obj = { userId: id, status: "accepted" };
        const data = await respondToFriendRequest(obj);
        if (data.status === 200) {
            getAllNotifications();
        }
    }

    const reject = async (id) => {
        let obj = { userId: id, status: "rejected" };
        const data = await respondToFriendRequest(obj);
        if (data.status === 200) {
            getAllNotifications();
        }
    }

    const deleteNotification = async (id) => {
        let obj = {
            status: 'deleted'
        }
        let response = await changeNotificationStatus(obj, id);
        if (response.status === 200) {
            getAllNotifications();
        }
    }

    const navigateToFriends = async (id) => {
        navigate(`/friends/${id.userId}`)
    }

    const navigateToApproval = async () => {
        navigate(`/mycalendar`)
    }

    const navigateToOranisation = async (id) => {
        navigate(`/organisation/${id.organisationId}`, { state: { memebersTabActive: true } })
    }

    const navigateToProfile = async (id) => {
        navigate(`/admin/coach-profile/${id.userId}`);
    }

    const navigateToMyKaliedoscope = async (id) => {
        navigate(HOME)
    }

    const navigateToOranisationPost = async (id) => {
        navigate(`/organisation/${id.organisationId}`, { state: { postTabActive: true } })
    }

    const navigateToExperience = async (id) => {
        if (id.eventId) {
            navigate(`/event/${id.eventId}`);
        }
    }

    return (
        <>
            <NotificationWeb
                deleteNotification={deleteNotification}
                notfications={notfications}
                getStatusName={getStatusName}
                getTitle={getTitle}
                getSubTitle={getSubTitle}
                accept={accept}
                reject={reject}
                navigateToApproval={navigateToApproval}
                navigateToFriends={navigateToFriends}
                navigateToOranisation={navigateToOranisation}
                navigateToProfile={navigateToProfile}
                navigateToOranisationPost={navigateToOranisationPost}
                navigateToMyKaliedoscope={navigateToMyKaliedoscope}
                fetchMoreData={fetchMoreData}
                navigateToExperience={navigateToExperience}
                count={count}
            />
        </>
    )
}

export default Notification;