import React from 'react';

import Header from '../../component/staticPages/header';
import Footer from '../../component/staticPages/footer';

const About = () => {
    return (
        <>
            <div className="bg-login">
                <div className="container-xl">
                    <Header/>
                </div>
            </div>

            <div className="position-relative">
                <div className={"bg-login position-absolute top-0 start-0 w-100 z-n1 tw-h-[50vh]"}/>
                <div
                    className='mx-auto tw-max-w-[800px] px-2 py-5 d-flex flex-column gap-4 inter tw-font-[500] tw-text-[#18214D]'>
                    <h1 className={"text-center fw-bold lg:tw-text-6xl tw-text-2xl"}>
                        Powering the Well-being Champions Network
                    </h1>

                    <div className={"position-relative mx-auto"}>
                        <img className='mx-auto mw-100 tw-max-h-[575px]' src={"/static/jpg/european-little-girl.jpg"} alt=""/>
                        <span
                            className="position-absolute top-100 w-100 montserrat text-secondary tw-text-sm text-center">
                            Photo: @Natanavo
                        </span>
                    </div>

                    <p className={"mt-3"}>
                        About the Well-being Champions Network
                    </p>

                    <p>
                        The Well-being Champions Network is an initiative supported by the Tripartite
                        Alliance Limited,
                        which is jointly set up by: <a target='_blank' href="https://mom.gov.sg"
                                                       rel="noreferrer">&nbsp;Ministry of Manpower
                        (MOM)</a>, <a target='_blank'
                                      href="https://www.ntuc.org.sg/wps/portal/up2/home/eserviceslanding"
                                      rel="noreferrer">National Trades Union Congress
                        (NTUC)</a> and&nbsp;
                        <a target='_blank' href="https://snef.org.sg" rel="noreferrer">Singapore
                            National Employers Federation (SNEF).</a>
                    </p>

                    <p>According to the World Health Organisation
                        (WHO), good work is beneficial for our mental health. A healthy state of mental
                        well-being can also contribute to improved productivity. Conversely, a negative
                        working environment or unemployment can cause poor physical and mental health.
                        Based on data collected from the Ministry of Manpower (MOM), one in three
                        employees found it challenging to cope with work demands and pressures or felt
                        exhausted physically and psychologically from work. These employees who
                        experienced work stress or burnout took more medical leave per year and/or
                        encountered more accidents or near-miss incidents compared to other employees.
                        Strengthening mental well-being in the workplace could lead to greater
                        productivity and safety.</p>

                    <p>Therefore, there is a need to create a
                        safe and open culture in workplaces for employees with mental health concerns to
                        seek help without the fear of being discriminated. As part of Singapore’s
                        National Mental Health and Well-being Strategy 2023, it is recommended for
                        employers to have Well-being Champion(s), who will</p>

                    <p>
                        (i) rally senior management to implement policies and to support employees’
                        mental well-being; (ii) organise and curate mental well-being programmes and
                        resources; and (iii) establish a system to refer persons in distress to
                        professional help.
                    </p>

                    <p>Given that managing workplace mental
                        well-being is a new and yet dynamic area, a community-based approach via the
                        Network would be a perfect setting for Workplace Mental Well-being Champions to
                        learn from experiences and best practices through peers and experts.</p>

                    <p>
                        The Well-being Champions Network is
                        powered by Kaleidoscope, a Community of communities that will bring the
                        workplace well-being ecosystem together into a unified and collaborative
                        space.
                    </p>

                    <p>
                        Kaleidoscope is a Community of communities
                        to experience the Well-being Champions Network centred on Feeling Well™,
                        Functioning Well™ and Connecting Well™. Through authentic conversations and
                        expressions such as well-being stories, curated quests, and a first-of-its-kind
                        well-being exchange space enhanced with specialist and peer supporters,
                        Kaleidoscope aims to grow well-being across Workplaces while creating
                        sustainable social-value chains.
                    </p>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default About;