import React from 'react';
import Dicline from "../../../assets/svg/DiclineButton.svg";
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
import LoggedInLayout from "../../../component/layout/LoggedInLayout.tsx";
import PageHeader from "../../../component/PageHeader";

const NotificationWeb = (props) => {
  return (
    <LoggedInLayout title={"Notifications"}>
      <div className="d-flex flex-column gap-3">
        <PageHeader title={"Notifications"}/>

        {!props.notfications.filter(v => v.status !== "deleted").length ?
          <p className="text-center my-5">No notifications found</p> : null}

        <InfiniteScroll
          dataLength={props.notfications.length} //This is important field to render the next data
          next={props.fetchMoreData}

          hasMore={true}
          loader={props.notfications.length ? <h4 className="text-center my-4">
            {props.notfications.length && props.notfications.length === props.count ? null :
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only"></span>
              </div>}
          </h4> : null}
          endMessage={
            <p style={{textAlign: 'center'}}>
              <b>Yay! You have seen it all</b>
            </p>
          }
          className={"d-flex flex-column gap-3"}
        >
          {(props.notfications || []).filter(v => v.status !== "deleted").map((v, i) => {
            return (
              <div className='p-3 notification_list' key={i}>
                <div className="d-flex flex-nowrap align-items-center gap-2">
                  <div className={"flex-shrink-0 tw-w-[50px]"}>
                    <img
                      src={Dicline} alt='' onClick={() => props.deleteNotification(v.id)}
                      className="tw-cursor-pointer mx-auto"
                    />
                  </div>

                  <div className='flex-grow-1 p-0 cursor_pointer d-flex flex-column gap-2' onClick={(e) => {
                    if (v.title === "Someone reacted") {
                      e.stopPropagation();
                      window.location.href = `/p/${v.dataObject.postId}`
                      return;
                    }

                    if (v.type === "friend-request") {
                      e.stopPropagation();
                      props.navigateToFriends(v.dataObject)
                    } else if (v.type === "community" && (v.title === "You are invited to organisation" || v.title === "Someone joined your organisation")) {
                      e.stopPropagation();
                      props.navigateToOranisation(v.dataObject)
                    } else if (v.type === "admin") {
                      e.stopPropagation();
                      props.navigateToProfile(v.dataObject)
                    } else if (v.type === "Supporter") {
                      e.stopPropagation();
                      props.navigateToApproval(v.dataObject)
                    } else if (v.type === "my-kaliedoscope") {
                      e.stopPropagation();
                      props.navigateToMyKaliedoscope(v.dataObject)
                    } else if (v.type === "community") {
                      e.stopPropagation();
                      props.navigateToOranisationPost(v.dataObject)
                    } else if (v.type === "giving") {
                      e.stopPropagation();

                      if (v.dataObject.offerId) {
                        window.location.href = `/time-exchange/exchange?offerId=${v.dataObject.offerId}`;
                      } else {
                        window.location.href = `/time-exchange/exchange`;
                      }

                    } else if (v.type === 'experience') {
                      e.stopPropagation();
                      props.navigateToExperience(v.dataObject);
                    }
                  }}>
                    <div
                      className={"d-flex flex-nowrap justify-content-between align-items-center"}>
                      <button
                        className={`notification_button ${props.getStatusName(v.type).color}`}>
                        {props.getStatusName(v.type).name}
                      </button>
                      <div className={"d-lg-none d-block"}>
                        <p className='mb-0 notification_time'>
                          {moment(v.createdAt).format('hh:mm')} at {moment(v.createdAt).format('MMM DD, YYYY')}
                        </p>
                      </div>
                    </div>

                    <p className='notification_subject lh-base'>{v.body}</p>

                    <div>
                      {props.getSubTitle(v.title, v.dataObject, props.accept, props.reject, v.type)}
                    </div>
                  </div>

                  <div
                    className='flex-shrink-0 d-lg-flex align-items-center justify-content-center d-none'>
                    <p className='mb-0 notification_time'>
                      {moment(v.createdAt).format('hh:mm')} at {moment(v.createdAt).format('MMM DD, YYYY')}
                    </p>
                  </div>

                </div>

              </div>
            )
          })}

        </InfiniteScroll>

      </div>
    </LoggedInLayout>
  )
}

export default NotificationWeb