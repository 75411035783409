import React, {useEffect, useState} from 'react';
import "../../../component/staticPages/responsiveNavBar/responsiveNav.css";
import Back from "../../../assets/svg/Back.svg";
import NewsFeed_lg_Img from "../../../assets/NewsFeed_lg_Img.png";
import Like from "../../../assets/svg/Like.svg";
import Comment from "../../../assets/svg/Comment.svg";
import Share from "../../../assets/svg/Share.svg";
import Crossbtn from '../../../assets/Crossbtn.png';
import Saly2 from '../../../assets/Saly2.png';
import Delete from "../../../assets/svg/Delete.svg";
import Close from "../../../assets/svg/Close.svg";
import {sendEventParticipants} from '../../../utils/apiUrlsSetup';
import {useNavigate} from 'react-router';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Photo_Video from "../../../assets/svg/PhotoVideo.svg";
import {PostClipboard} from "../../../utils/plainClipboard";
import Linkify from "react-linkify";

Quill.register("modules/clipboard", PostClipboard, true);

const NewsFeedMobile = (props) => {
    let frndsData = [...props.friendsList];
    const [invitedUserList, setInvitedUserList] = useState(["", ""]);
    const [selectedFrnds, setselectedFrnds] = useState([]);
    const [search, setSearch] = useState("");
    const [showpage, setShowPage] = useState(1)
    const [sentIvitationModal, setSentIvitationModal] = useState(false)
    const [sentIvitationModal_1, setSentIvitationModal_1] = useState(false)
    const [registerModal, setRegisterModal] = useState(false)
    const navigate = useNavigate()

    const [comment, setComment] = useState("");
    const [subComment, setSubComment] = useState("");


    useEffect(() => {
        return () => {
            if (localStorage.getItem('link')) {
                localStorage.removeItem('link')
            }
        }
    }, [])


    const sendEventParticipantsToUsers = async () => {
        let obj = {
            userIdArray: selectedFrnds.map(v => v.id)
        }
        const response = await sendEventParticipants(props.eventData.event.id, obj);
        if (response.status === 200) {
            setShowPage(1)
            setSentIvitationModal(true)
        }
    }
    return (
        <div>
            <div className='row m-0'>
                <div className='col-12 p-0 newsfeed_mob_scr' style={{height: "100vh"}}>

                    {
                        showpage === 1 && <>
                            <div className='row m-0 responsive_nav_bottom_border'>
                                <div className='col-12 p-0 ps-4 pe-4'>

                                    <div className='row m-0 pt-3 pb-3 pt-md-4 pb-md-4'>

                                        <div className='col-1 p-0 d-flex align-items-center'>
                                            <img className='responsive_back' src={Back} alt='' onClick={() => {
                                                navigate('/experiences')
                                            }}/>
                                        </div>
                                        <div className='col-4 p-0 d-flex align-items-center'>
                                            <p className='mb-0 alt_nav_heading'></p>
                                        </div>
                                        <div className='col-7 p-0 d-flex align-items-center justify-content-end'>
                                            <p className='mb-0 alt_nav_free'>Free</p>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div className='row m-0 mobile_post mobile_post_main px-3'>
                                <div className='col-12 p-0 mobile_post_overflow'>

                                    <div className='row m-0 mt-3'>
                                        <div className='col-12 p-0'>
                                            <p className='mb-0 newsfeed_uper_heading'> {props.eventDetails.name}</p>
                                            <p className="mb-0 mt-3 newsfeed_event_info">
                                                <span className="cursor_pointer"
                                                      onClick={() => navigate(`/friends/${props.eventDetails.createdByUser.id}`)}>  {props.eventDetails.createdByUser.firstName} {props.eventDetails.createdByUser.lastName} </span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className='row m-0 mt-3'>
                                        <div className='col-12 p-0'>
                                            <img className='newsfeed_mobile_img'
                                                 src={props.eventDetails.image ? props.eventDetails.image : NewsFeed_lg_Img}
                                                 alt=''/>
                                        </div>
                                    </div>

                                    <div className='row m-0 mt-3'>

                                        {props.adminDataEvent ? <>

                                            <button style={{width: '100%'}} className="take_down_btn mb-3"
                                                    onClick={() => props.setShowEventDelete(true)}>Take-down Event
                                            </button>


                                        </> : null}


                                        <div className='col-6 p-0'>
                                            <p className='mb-0 newsfeed_mob_date'>Date and Time</p>
                                            <p className='mb-0 newsfeed_mob_time mt-1'>

                                                {props.moment(props.eventDetails.startDate).format("D MM") === props.moment(props.eventDetails.endDate).format("D MM") ? <>

                                                    {props.moment(props.eventDetails.startDate).utc().format("D MMM YYYY")},{" "}
                                                    {props.moment(props.eventDetails.startDate).utc().format("dddd")} <br/>
                                                    {props.moment(props.eventDetails.startDate).utc().format("hh:mm a")} - {props.moment(props.eventDetails.endDate).utc().format("hh:mm a")}


                                                </> : <>

                                                    {props.moment(props.eventDetails.startDate).utc().format("D MMM YYYY")},{" "}
                                                    {props.moment(props.eventDetails.startDate).utc().format("dddd")}
                                                    <br/> To <br/> {props.moment(props.eventDetails.endDate).utc().format("D MMM YYYY")},{" "}
                                                    {props.moment(props.eventDetails.endDate).utc().format("dddd")}
                                                    <br/> {props.moment(props.eventDetails.startDate).utc().format("hh:mm a")} - {props.moment(props.eventDetails.endDate).utc().format("hh:mm a")}

                                                </>}

                                            </p>


                                        </div>


                                        <div className='col-6 p-0'>
                                            <p className='mb-0 mt-4 newsfeed_people_no'>
                                                {props.eventDetails.participants && props.eventDetails.participants.length} people
                                                have registered,
                                                <br/> <a href="#" style={{color: '#410db7'}}
                                                         onClick={() => props.setParticipantsList(props.eventDetails.participants)}>click
                                                here</a> to see details
                                                {/* <br /> including {props.eventDetails.participants && props.eventDetails.participants.length > 0 ? props.friendsList.length : 0} of your friends */}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='row m-0 mt-3'>
                                        <div className='col-12 p-0'>
                                            <p className='mb-0 newsfeed_about_line'>About this event</p>
                                        </div>
                                    </div>

                                    <div className='row m-0 mt-2'>
                                        <div className='col-12 p-0'>
                                            <p className='mb-0 newsfeed_about_details'>
                                                <Linkify
                                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                                        <a
                                                            className={"text-primary"}
                                                            target="blank"
                                                            href={decoratedHref}
                                                            key={key}>
                                                            {decoratedText}
                                                        </a>
                                                    )}>{props.eventDetails.description}</Linkify>
                                            </p>
                                        </div>
                                    </div>

                                    <div className='row m-0 mt-3'>
                                        <div className='col-4 p-0 d-flex align-items-center justify-content-start'>
                                            <div
                                                className="event_action_img_huddle cursor_pointer  d-flex justify-content-center align-items-center ">
                                                <img
                                                    className="cursor_pointer"
                                                    onClick={() => props.handleLike(props.eventDetails.id)}
                                                    src={Like} alt=""/>
                                            </div>
                                            <p className="mb-0 ms-2 event_action_like_count">
                                                {props.eventDetails.reactionCount}
                                            </p>
                                        </div>
                                        <div className='col-4 p-0 d-flex align-items-center justify-content-center'>
                                            <div
                                                className="event_action_img_huddle cursor_pointer d-flex justify-content-center align-items-center ">
                                                <img
                                                    className="cursor_pointer"
                                                    onClick={() => props.showCommentBox(true)}
                                                    src={Comment}
                                                    alt=""
                                                />
                                            </div>
                                            <p className="mb-0 ms-2 event_action_cmnt_count">
                                                {props.comments.length}
                                            </p>
                                        </div>
                                        <div className='col-4 p-0 d-flex align-items-center justify-content-end'>
                                            <div
                                                className="event_action_img_huddle cursor_pointer d-flex justify-content-center align-items-center ">
                                                <img
                                                    className=""
                                                    src={Share}
                                                    alt=""
                                                    onClick={() => {
                                                        props.setIsShowInviteFrndModal(true)
                                                    }
                                                        //  setShowPage(2)

                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    {props.commentBox && (
                                        <>
                                            <div className="row m-0 mt-2">'
                                                <div className='col-12 p-0 d-flex'>
                                                    <div className="input-group input-group-post">
                                                        <ReactQuill placeholder="Add a comment..." modules={{
                                                            toolbar: false,
                                                            clipboard: {
                                                                // toggle to add extra line breaks when pasting HTML:
                                                                matchVisual: false,
                                                            }
                                                        }} theme="snow" value={comment} onChange={(event) => {
                                                            props.setShowImageComment(true);
                                                            setComment(event);
                                                            props.setComment(event);
                                                        }}/>

                                                        <span
                                                            className="input-group-text comment_input_capture d-flex align-items-center justify-content-around"
                                                            id="basic-addon1"
                                                        >

                                                            <input type='file' id="file-input" className="d-none"
                                                                   multiple onChange={(e) => {
                                                                props.saveImageData(e.target.files[0]);
                                                                props.setShowImageComment(true)
                                                                if (e.target.files[0]) {
                                                                    const file = URL.createObjectURL(e.target.files[0]);
                                                                    props.setImageUrl(file);
                                                                }
                                                                e.target.value = null;
                                                            }}/>
                                                            <label for="file-input">
                                                                <img className="ms-3" src={Photo_Video}/>
                                                            </label>
                                                        </span>
                                                    </div>
                                                </div>

                                                {props.showImageComment ?
                                                    <div className="d-flex flex-column mt-3 image_preview_box">

                                                        {props.imageUrl ?
                                                            <div className="position-relative" style={{width: '300px'}}>
                                                                <img src={props.imageUrl} alt="" style={{width: '300px'}}/>
                                                                <div className="remove_image" onClick={() => {
                                                                    props.setImageUrl("");
                                                                    props.saveImageData(null);
                                                                }}><img src={Crossbtn} alt=""/></div>
                                                            </div> : null}

                                                        {comment.length ?
                                                            <button class="my-3 book_an_slot" data-bs-toggle="modal"
                                                                    data-bs-target="#slotBookModal"
                                                                    onClick={() => {
                                                                        if (props.imageUrl) {
                                                                            setComment("");
                                                                            props.submitComment("image", props.eventDetails, props.imageData)
                                                                        } else {
                                                                            setComment("");
                                                                            props.submitComment("text", props.eventDetails, comment)
                                                                        }
                                                                    }}>Post</button> : null}


                                                    </div> : null}

                                                {(props.comments || []).map((v, i) => {
                                                    return (
                                                        <div
                                                            key={i}
                                                            className="row m-0 pt-3 pb-3 evt_bottom_border d-flex"
                                                        >
                                                            <div className="EventProfilePic_section p-0">

                                                                <div
                                                                    className='nf_mobile_profile_img d-flex align-items-center'>
                                                                    <img
                                                                        src={props.userDetails.id === v.id ? props.userDetails.profilePic : "/static/png/Male.png"}
                                                                        alt=""/>
                                                                </div>
                                                            </div>
                                                            <div className="EventComments_section p-0 px-2">
                                                                <p className='mb-0 user_commented'>
                                                                    <b>{v.user.firstName} {v.user.lastName}</b></p>
                                                                <p className='mb-0 user_commented'
                                                                   dangerouslySetInnerHTML={{__html: v.comment}}></p>
                                                                {v.attachment ?
                                                                    <img width="300px" height="150px" className="my-2"
                                                                         src={v.attachment} alt=""/> : null}
                                                                <p className="mb-0 mt-2 user_commented_like_cmnt">
                                                                    <span
                                                                        className="cursor_pointer"
                                                                        onClick={() => props.handleLike(v.eventId, v.id)}
                                                                    >
                                                                        {v.reactionCount} Likes
                                                                    </span>{" "}
                                                                    &bull; <span className="cursor_pointer" onClick={() => {
                                                                    props.setShowRepliedCommentInput(true);
                                                                    props.setShowRepliedCommentId(v.id);
                                                                }}>{v.repliesCount} Replies</span>
                                                                </p>
                                                            </div>

                                                            {props.showRepliedCommentId === v.id && props.showRepliedCommentInput && <>
                                                                <div className='row m-0 mt-3'>
                                                                    <div
                                                                        className='col-12 p-0 d-flex align-items-center justify-content-end'>

                                                                        <div class="d-flex w-100">

                                                                            <div className="input-group input-group-post">
                                                                                <ReactQuill placeholder="Add a comment..."
                                                                                            modules={{
                                                                                                toolbar: false,
                                                                                                clipboard: {
                                                                                                    // toggle to add extra line breaks when pasting HTML:
                                                                                                    matchVisual: false,
                                                                                                }
                                                                                            }} theme="snow"
                                                                                            value={subComment}
                                                                                            onChange={(event) => {
                                                                                                // setShowImageComment(true);
                                                                                                setSubComment(event);
                                                                                                props.setSubComment(event);
                                                                                            }}/>

                                                                                <span
                                                                                    className="input-group-text comment_input_capture d-flex align-items-center justify-content-around"
                                                                                    id="basic-addon1"
                                                                                >
                                                                                    <input type='file' id="file-input"
                                                                                           className="d-none" multiple
                                                                                           onChange={(e) => {
                                                                                               props.saveImageData(e.target.files[0]);
                                                                                               props.setShowImageComment(true)
                                                                                               if (e.target.files[0]) {
                                                                                                   const file = URL.createObjectURL(e.target.files[0]);
                                                                                                   props.setImageUrl(file);
                                                                                               }
                                                                                               e.target.value = null;
                                                                                           }}/>
                                                                                    <label for="file-input">
                                                                                        <img className="ms-3"
                                                                                             src={Photo_Video}/>
                                                                                    </label>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {<div
                                                                        className="d-flex flex-column mt-3 image_preview_box">

                                                                        {props.imageUrl ? <div className="position-relative"
                                                                                               style={{width: '300px'}}>
                                                                            <img src={props.imageUrl} alt=""
                                                                                 style={{width: '300px'}}/>
                                                                            <div className="remove_image" onClick={() => {
                                                                                props.setImageUrl("");
                                                                                props.saveImageData(null);
                                                                            }}><img src={Crossbtn} alt=""/></div>
                                                                        </div> : null}

                                                                        {subComment.length ?
                                                                            <button class="my-3 book_an_slot"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#slotBookModal"
                                                                                    onClick={() => {
                                                                                        if (props.imageUrl) {
                                                                                            setSubComment("");
                                                                                            props.submitComment("image", props.eventDetails, props.imageData, v.id)
                                                                                        } else {
                                                                                            setSubComment("");
                                                                                            props.submitComment("text", props.eventDetails, subComment, v.id)
                                                                                        }
                                                                                    }}>Post</button> : null}


                                                                    </div>}

                                                                </div>
                                                                <div
                                                                    className="d-flex justify-content-end align-items-end flex-column">
                                                                    {v.replies.map((v, i) => {
                                                                        return <div className="d-flex align-items-center"
                                                                                    style={{width: '94%'}} key={i}>
                                                                            <div
                                                                                className='EventProfilePic_section py-3 p-0'>

                                                                                <div className='EventProfilePic'>
                                                                                    <img className='EventProfilePic_img'
                                                                                         src={v.user.profilePic ? v.user.profilePic : "/static/png/Male.png"}
                                                                                         alt=""/>
                                                                                </div>

                                                                            </div>

                                                                            <div
                                                                                className='EventComments_section py-3 p-0 px-2'>
                                                                                <p className='mb-0 user_commented'>
                                                                                    <b>{v.user.firstName} {v.user.lastName}</b>
                                                                                </p>
                                                                                <p className='mb-0 user_commented'
                                                                                   dangerouslySetInnerHTML={{__html: v.comment}}></p>
                                                                                {v.attachment ?
                                                                                    <img width="200px" height="150px"
                                                                                         className="my-2" src={v.attachment}
                                                                                         alt=""/> : null}

                                                                            </div>
                                                                        </div>
                                                                    })}
                                                                </div>
                                                            </>}
                                                        </div>
                                                    );
                                                })}

                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>


                            <div className='row m-0 mob_btm_fixed_bar fixed-bottom' style={{height: "90px"}}>
                                <div
                                    className='col-12 p-0 px-3 mob_btm_fixed_bar_inside back_col d-flex align-items-center justify-content-end'>
                                    {/* <button className='post_btn' onClick={() => props.setShow(true)}>Register</button> */}
                                    {props.userDetails.id !== props.eventDetails.createdByUserId && !props.adminDataEvent && props.eventDetails.participants && !props.eventDetails.participants.map(v => v.userId).includes(props.userDetails.id) &&
                                        <button className='post_btn' onClick={() => setRegisterModal(true)}>
                                            Register
                                        </button>}

                                    {props.userDetails.id !== props.eventDetails.createdByUserId && !props.adminDataEvent && props.eventDetails.participants && props.eventDetails.participants.map(v => v.userId).includes(props.userDetails.id) &&
                                        <button className="post_btn"
                                                onClick={() => window.open(`${props.eventDetails.link}`)}>
                                            Attend Event
                                        </button>}

                                </div>

                            </div>


                            {/* <!--Modal-1--> */}

                            <div
                                class={`modal fade ${registerModal ? "show" : ""}`}
                                style={{
                                    display: registerModal ? "block" : "none",
                                    background: registerModal ? "rgba(0,0,0,0.5)" : "",
                                }}
                                id="exampleModalRegBtn"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabelReg"
                                aria-hidden="true"
                            >
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div className="row m-0 register_event_modal">
                                            <div className="col-12 py-3">
                                                <div className="row m-0">
                                                    <p className="text-center confirm_text mb-1">
                                                        Event registration
                                                    </p>
                                                </div>

                                                <div className="row m-0 mt-3">
                                                    <div className="col-4 p-0">
                                                        <img
                                                            className="newsfeed_mobile__modal_img"
                                                            width={"145px"}
                                                            height="90px"
                                                            src={
                                                                props.eventDetails.image
                                                                    ? props.eventDetails.image
                                                                    : props.NewsFeedlgImgModel
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="col-8 p-0">
                                                        <div className="row m-0">
                                                            <div className="col-12 p-0">
                                                                <p className="mb-0 title_text_mob ms-3">
                                                                    {props.eventDetails.name}
                                                                </p>
                                                                {props.moment(props.eventDetails.startDate).format("D MM") === props.moment(props.eventDetails.endDate).format("D MM") ? <>


                                                                    <p className="mb-0 evt_reg_model_time_text mt-2">
                                                                        {props.moment(props.eventDetails.startDate).utc().format("D MMM YYYY")},{" "}
                                                                        {props.moment(props.eventDetails.startDate).utc().format("dddd")}
                                                                    </p>
                                                                    <p className="mb-0 evt_reg_model_time_text mt-1">
                                                                        {props.moment(props.eventDetails.startDate).utc().format("hh:mm a")}
                                                                        &nbsp;-&nbsp;
                                                                        {props.moment(props.eventDetails.endDate).utc().format("hh:mm a")}
                                                                    </p>

                                                                </> : <>

                                                                    <p className="mb-0 evt_reg_model_time_text mt-2">
                                                                        {props.moment(props.eventDetails.startDate).utc().format("D MMM YYYY")},{" "}
                                                                        {props.moment(props.eventDetails.startDate).utc().format("dddd")}
                                                                    </p>
                                                                    <p className="mb-0 evt_reg_model_time_text mt-1">To</p>
                                                                    {/* <p className='mb-0 evt_time mt-1'>8:00 PM - 9:30PM</p> */}
                                                                    <p className="mb-0 evt_reg_model_time_text mt-1">
                                                                        {props.moment(props.eventDetails.endDate).utc().format("D MMM YYYY")},{" "}
                                                                        {props.moment(props.eventDetails.endDate).utc().format("dddd")}
                                                                    </p>
                                                                    <p className="mb-0 evt_reg_model_time_text mt-1">
                                                                        {props.moment(props.eventDetails.startDate).utc().format("hh:mm a")}
                                                                        &nbsp;-&nbsp;
                                                                        {props.moment(props.eventDetails.endDate).utc().format("hh:mm a")}
                                                                    </p>

                                                                </>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row m-0">
                                                    <div
                                                        className="col-12 p-0 px-2 py-4 d-flex align-items-center justify-content-start">
                                                        <p className=" mb-0 event_det_text_mob">
                                                            You are registering for this event and will be listed as a
                                                            participant.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="row m-0 d-flex flex-column ">
                                                    <div
                                                        className="event_cancel_btn event_cancel_btn_mob"
                                                        onClick={() => setRegisterModal(false)}
                                                    >
                                                        Cancel
                                                    </div>
                                                    <div
                                                        className="mt-3 event_register_done_btn event_register_done_btn_mob"
                                                        onClick={props.handleShowRegister}
                                                    >
                                                        Yes
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* <!--Modal-2--> */}
                            <div
                                class={`modal fade ${props.showRegister ? "show" : ""}`}
                                style={{
                                    display: props.showRegister ? "block" : "none",
                                    background: props.showRegister ? "rgba(0,0,0,0.5)" : "",
                                }}
                                id="exampleModalRegisterBtn"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabelReg2"
                                aria-hidden="true"
                            >
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div className="row m-0">
                                            <div className="col-12 ps-5 pe-5 pt-5">
                                                <div className="row m-0">
                                                    <div
                                                        className="col-10 p-0 d-flex align-items-center justify-content-start">
                                                        <p className=" mb-0 evt_reg_model_info_text">
                                                            Registration for
                                                        </p>
                                                    </div>
                                                    <div
                                                        className="col-2 p-0 d-flex align-items-center justify-content-end">
                                                        <img
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModalCloseBtn"
                                                            onClick={props.handleShow1}
                                                            className=""
                                                            src={Close}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row m-0">
                                                    <div className="col-12 p-0">
                                                        <p className="mb-0 mt-4 evt_reg_model_bold_text">
                                                            {props.eventDetails.name}
                                                        </p>

                                                        {props.moment(props.eventDetails.startDate).format("D MM") === props.moment(props.eventDetails.endDate).format("D MM") ? <>


                                                            <p className="mb-0 evt_reg_model_time_text mt-2">
                                                                {props.moment(props.eventDetails.startDate).utc().format("D MMM YYYY")},{" "}
                                                                {props.moment(props.eventDetails.startDate).utc().format("dddd")}
                                                            </p>
                                                            <p className="mb-0 evt_reg_model_time_text mt-1">
                                                                {props.moment(props.eventDetails.startDate).utc().format("hh:mm a")}
                                                                &nbsp;-&nbsp;
                                                                {props.moment(props.eventDetails.endDate).utc().format("hh:mm a")}
                                                            </p>

                                                        </> : <>

                                                            <p className="mb-0 evt_reg_model_time_text mt-2">
                                                                {props.moment(props.eventDetails.startDate).utc().format("D MMM YYYY")},{" "}
                                                                {props.moment(props.eventDetails.startDate).utc().format("dddd")}
                                                            </p>
                                                            <p className="mb-0 evt_reg_model_time_text mt-1">To</p>
                                                            {/* <p className='mb-0 evt_time mt-1'>8:00 PM - 9:30PM</p> */}
                                                            <p className="mb-0 evt_reg_model_time_text mt-1">
                                                                {props.moment(props.eventDetails.endDate).utc().format("D MMM YYYY")},{" "}
                                                                {props.moment(props.eventDetails.endDate).utc().format("dddd")}
                                                            </p>
                                                            <p className="mb-0 evt_reg_model_time_text mt-1">
                                                                {props.moment(props.eventDetails.startDate).utc().format("hh:mm a")}
                                                                &nbsp;-&nbsp;
                                                                {props.moment(props.eventDetails.endDate).utc().format("hh:mm a")}
                                                            </p>

                                                        </>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row m-0 mt-3">
                                            <div className="col-12 p-0">
                                                <img
                                                    className=""
                                                    width={"100%"}
                                                    src={
                                                        props.eventDetails.image
                                                            ? props.eventDetails.image
                                                            : props.NewsFeedlgImgModel
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                        </div>

                                        <div className="row m-0 mt-2">
                                            <div className="col-12 p-0 ps-5 pe-5 pt-4 pb-5">
                                                <p className="mb-0 model_reg_done_messege">
                                                    Thank
                                                    you <b>{props.userDetails.firstName} {props.userDetails.lastName}</b> for
                                                    registering for the
                                                    {" "}{props.eventDetails.name}
                                                </p>
                                                <p className="mb-0 mt-2 model_reg_done_info_messege">
                                                    A confirmation been sent to your
                                                    email {props.userDetails.email}.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div
                                class={`modal fade ${sentIvitationModal ? "show" : ""}`}
                                style={{
                                    display: sentIvitationModal ? "block" : "none",
                                    background: sentIvitationModal ? "rgba(0,0,0,0.5)" : "",
                                }}
                                id="exampleModalRegBtn"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabelReg"
                                aria-hidden="true"
                            >
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div className="row m-0 register_event_modal">

                                            <div className="col-12 py-3">

                                                <div className='row m-0'>
                                                    <div
                                                        className='col-12 p-0 d-flex justify-content-center align-items-center'>
                                                        <img src={Saly2} alt=''/>
                                                    </div>
                                                </div>

                                                <div className="row m-0">
                                                    <p className="text-center invitation_sure_text mb-1">
                                                        Invitations sent!
                                                    </p>
                                                </div>

                                                <div className="row m-0 mt-2">
                                                    <p className="text-center invitation_sure_pera mb-0">
                                                        Your friends will be notified that <br/>you have invited them to the<br/> huddle
                                                    </p>
                                                </div>

                                                <div
                                                    className="row m-0 mt-3 d-flex justify-content-center align-items-center">
                                                    <button className='invitation_send_btn' onClick={() =>
                                                        setSentIvitationModal(false)
                                                    }>Ok
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                class={`modal fade ${props.showEventDelete ? "show" : ""}`}
                                style={{
                                    display: props.showEventDelete ? "block" : "none",
                                    background: props.showEventDelete ? "rgba(0,0,0,0.5)" : "",
                                }}
                                id="exampleModalCloseBtn"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabelReg3"
                                aria-hidden="true"
                            >
                                <div class="modal-dialog modal-dialog-centered modal-md">
                                    <div class="modal-content">
                                        <div className="row m-0">
                                            <div className="col-12 p-0 ps-2 pe-2 pt-4 pb-4">
                                                <div className="row m-0">
                                                    <div className="col-12 p-0">
                                                        <h4 className="mb-0 text-center">
                                                            Are you sure?
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div className="row m-0 my-4">
                                                    <div className="col-12 p-0">
                                                        <p className="mb-0 model_promt_text text-center">
                                                            Your action will delete all of this event <br/>
                                                            detail and cannot be undone.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="row m-0 mt-3">
                                                    <div className="col-2 p-0"></div>
                                                    <div
                                                        className="col-4 p-0 d-flex justify-content-start align-items-center pe-2">
                                                        <button className="take_down_btn take_down_btn_outline"
                                                                onClick={() => props.setShowEventDelete(false)}>Cancel
                                                        </button>
                                                    </div>
                                                    <div
                                                        className="col-4 p-0 d-flex justify-content-end align-items-center ps-2">
                                                        <button className="take_down_btn"
                                                                onClick={() => props.deleteEvent(props.eventDetails.id)}>Yes
                                                        </button>
                                                    </div>
                                                    <div className="col-2 p-0"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div
                                class={`modal fade ${sentIvitationModal_1 ? "show" : ""}`}
                                style={{
                                    display: sentIvitationModal_1 ? "block" : "none",
                                    background: sentIvitationModal_1 ? "rgba(0,0,0,0.5)" : "",
                                }}
                                id="exampleModalRegBtn"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabelReg"
                                aria-hidden="true"
                            >
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div className="row m-0 register_event_modal">

                                            <div className="col-12 py-3">

                                                <div className='row m-0'>
                                                    <div className='col-12 p-0 d-flex justify-content-center align-items-center'>
                                                        <img config={Saly2} alt='' />
                                                    </div>
                                                </div>

                                                <div className="row m-0">
                                                    <p className="text-center invitation_sure_text mb-1">
                                                    Invitations sent!
                                                    </p>
                                                </div>

                                                <div className="row m-0 mt-2">
                                                    <p className="text-center invitation_sure_heading mb-1">
                                                    2H has been added to your<br/> time balance
                                                    </p>
                                                </div>

                                                <div className="row m-0 mt-2">
                                                    <p className="text-center invitation_sure_pera mb-0">
                                                        Your friends will be notified that <br />you have invited them to the<br /> huddle
                                                    </p>
                                                </div>

                                                <div className="row m-0 mt-3 d-flex justify-content-center align-items-center">
                                                    <button className='invitation_send_btn' onClick={() => setSentIvitationModal_1(false)}>Ok</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </>}

                    {/* {
                        showpage === 2 && <> <div className='row m-0 responsive_nav_bottom_border'>
                            <div className='col-12 p-0 ps-4 pe-4'>

                                <div className='row m-0 pt-3 pb-3 pt-md-4 pb-md-4'>

                                    <div className='col-1 p-0 d-flex align-items-center'>
                                        <img className='responsive_nav_logo' config={Crossbtn} alt='' onClick={() => setShowPage(1)} />
                                    </div>
                                    <div className='col-7 p-0 d-flex align-items-center'>
                                        <p className='mb-0 alt_nav_heading'>Invite friends</p>
                                    </div>

                                </div>

                            </div>
                        </div>

                            <div className='row m-0 mobile_post mobile_post_main px-3'>
                                <div className='col-12 p-0 mobile_post_overflow'>

                                    <div className='row m-0 mt-4'>
                                        <div className='col-12 p-0'>
                                            <div class="input-group huddle_top_input position_relative">
                                                <input value={search} onChange={(e) => setSearch(e.target.value)} type="text" class="form-control all_exp_page_lg_input" placeholder="Search people" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                <span class="input-group-text all_exp_page_lg_span" id="basic-addon2"><img config={Search} alt='' /></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row m-0 mt-3'>
                                        <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                            <p className='mb-0 invite_user_heading' onClick={() => setShowPage(3)}>Inviting new user?</p>
                                        </div>
                                    </div>

                                    <div className='row m-0'>
                                        <div className='col-12 p-0'>
                                            <div className="user_pill_background">
                                                {selectedFrnds.map((v, i) => {
                                                    return <div class="user_pill d-flex align-items-center" key={i}>
                                                        <img width="20px" config={v.profilePic ? v.profilePic : AssociatedPhoto} alt="" />
                                                        <p className='mb-0 community_users_list_names ps-2'>{v.firstName} {v.lastName}</p>
                                                        <img className="cursor_pointer ps-2" onClick={() => {
                                                            let copySelectedFrnds = [...selectedFrnds];
                                                            let filterItems = copySelectedFrnds.filter(val => val.id !== v.id);
                                                            setselectedFrnds(filterItems);
                                                        }} config={CloseBtn} width="20px" alt="" />
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row m-0 mt-3'>
                                        <div className='col-12 p-0'>

                                            <div className="me-3">
                                                {frndsData.filter(el => {
                                                    return selectedFrnds.indexOf(el) === -1;
                                                }).filter((u) =>
                                                    search
                                                        ? (u.firstName + " " + u.lastName)
                                                            .toLowerCase()
                                                            .indexOf(search.toLowerCase()) >= 0
                                                        : true
                                                ).map((v, i) => {
                                                    return <div className='row m-0 mt-2' key={i}>
                                                        <div className='col-12 p-0 community_users_list' onClick={() => setselectedFrnds([...selectedFrnds, v])}>

                                                            <div className='row m-0 pt-2 pb-2'>

                                                                <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                                                    <div className='community_users_list_img_box'>
                                                                        <img config={v.profilePic ? v.profilePic : AssociatedPhoto} alt="" />
                                                                    </div>
                                                                </div>

                                                                <div className='col-10 p-0 d-flex align-items-center'>
                                                                    <p className='mb-0 community_users_list_names'>{v.firstName} {v.lastName}</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>



                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className='row m-0 mob_btm_fixed_bar fixed-bottom' style={{ height: "92px" }}>
                                <div className='col-12 p-0 px-3 mob_btm_fixed_bar_inside back_col d-flex align-items-center justify-content-end'>
                                    <button className='post_btn' onClick={() => sendEventParticipantsToUsers()}>Invite</button>

                                </div>

                            </div>


                        </>} */}

                    {
                        showpage === 3 && <>
                            <div className='row m-0 responsive_nav_bottom_border'>
                                <div className='col-12 p-0 ps-4 pe-4'>

                                    <div className='row m-0 pt-3 pb-3 pt-md-4 pb-md-4'>

                                        <div className='col-1 p-0 d-flex align-items-center'>
                                            <img className='responsive_nav_logo' src={Crossbtn} alt=''
                                                 onClick={() => setShowPage(1)}/>
                                        </div>
                                        <div className='col-7 p-0 d-flex align-items-center'>
                                            <p className='mb-0 alt_nav_heading'>Invite new friends</p>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div className='row m-0 mobile_post mobile_post_main px-3'>
                                <div className='col-12 p-0 mobile_post_overflow'>

                                    <div className='row m-0 mt-3'>
                                        <div className='col-12 p-0'>
                                            <p className='mb-0 invite_new_user'>Get 2H free from inviting new user!</p>
                                        </div>
                                    </div>

                                    <div className='row m-0'>
                                        <div className='col-12 p-0'>
                                            <div className="me-3">
                                                {invitedUserList.map((v, i) => {
                                                    return <div className='row m-0 mt-3' key={i}>
                                                        <div className='col-11 p-0'>
                                                            {/* <input className='ps-4 pt-2 pb-2 add_new_user_to_invite_input' type="text" placeholder='samuel.hez@gmail.com' /> */}
                                                            <input onChange={(e) => {
                                                                let values = [...invitedUserList];
                                                                values[i] = e.target.value;
                                                                setInvitedUserList(values);
                                                            }} value={v} type="email"
                                                                   class="form-control add_new_user_to_invite_input"
                                                                   id="exampleFormControlInput1"
                                                                   placeholder="samuel.hez@gmail.com"></input>
                                                        </div>
                                                        <div
                                                            className='col-1 p-0 d-flex align-items-center justify-content-end'>
                                                            <img className='cursor_pointer' src={Delete} alt=''
                                                                 onClick={() => {
                                                                     let values = [...invitedUserList];
                                                                     let remaingItems = values.filter((v, idx) => idx !== i);
                                                                     setInvitedUserList(remaingItems);
                                                                 }}/>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row m-0 mt-3'>
                                        <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                            <button
                                                className='add_new_user_to_invite_btn add_new_user_to_invite_btn_unactive'
                                                onClick={() => {
                                                    setInvitedUserList([...invitedUserList, ""]);
                                                }}>Add email
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='row m-0 mob_btm_fixed_bar fixed-bottom' style={{height: "92px"}}>
                                <div
                                    className='col-12 p-0 px-3 mob_btm_fixed_bar_inside back_col d-flex align-items-center justify-content-end'>
                                    <button className='post_btn'>Invite</button>
                                </div>
                            </div>
                        </>
                    }
                </div>

            </div>
            {props.isShowInviteFrndModal &&
                <props.InviteFriendModal setIsShowInviteFrndModal={() => props.setIsShowInviteFrndModal(false)}
                                         isShowInviteFrndModal={props.isShowInviteFrndModal}
                                         friendsList={props.friendsList} eventData={{event: props.eventDetails}}/>}
        </div>


    )
}

export default NewsFeedMobile;
