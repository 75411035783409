import {useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {verifyEmail} from "../utils/apiUrlsSetup";
import Swal from "sweetalert2";
import {useNavigate} from "react-router";

export default function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token")
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) return;
    verifyEmail(token).then(response => {
      const _apiMsg = response.data.message;
      let header;
      let body;
      let icon;
      let action = () => {
      };
      if (_apiMsg === "Account inactive" || _apiMsg === "Email not found") {
        header = 'Account Not Found'
      } else if (_apiMsg) {
        header = 'Message';
      } else if (response.status === 200) {
        header = 'Account Activated!'
        navigate("/")
      } else {
        header = 'Account not activated!';
      }

      if (_apiMsg === 'Email not verified') {
        body = "Your email is not verified yet. Please check your email to verify your account."
        icon = "warning"
      } else if (_apiMsg === "Email verified successfully") {
        body = "Your email has been verified successfully"
        icon = "success"
        action = () => navigate("/")
      } else if (_apiMsg) {
        body = _apiMsg
        action = () => navigate("/")
      } else if (response.status !== 200) {
        body = "Your account is pending approval! Please wait for your account to be activated."
        icon = "info"
      } else {
        body = "Your account could not be activated due to invalid activation link. Try requesting for a new link."
        icon = "error"
      }

      Swal.fire(header, body, icon)
      action()
    })
  }, [token])

  return <></>
}