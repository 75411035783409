import React from 'react';
import {Link} from "react-router-dom";

import Mail from "../../assets/svg/Mail.svg";
import KscopeLogo from "../../assets/wcn_logo.svg"

const Footer = () => {
    return (
        <div className={"bg-yellow text-darkblue inter"}>
            <div className="container-lg">
                <div className="py-lg-5 py-3">
                    <div className='row gy-lg-5 gy-3 mb-5'>
                        <div className='col-xl-2 col-lg-3 col-md-4 col-6'>
                            <p className='mb-0 inter fw-bold pb-2 pb-lg-4 ls-near'>Well-being Champions Network</p>
                            <div className={"fw-semibold inter d-flex flex-column gap-2"}>
                            <a href="https://wellbeingchampions.sg/for-organisations/" target="_blank" 
                                className="text-decoration-none" rel={"noreferrer"}>
                                For Organisations
                            </a>
                            <a href="https://wellbeingchampions.sg/for-champions/" target="_blank" 
                                className="text-decoration-none" rel={"noreferrer"}>
                                For Champions
                            </a>
                            <a href="https://wellbeingchampions.sg/events/" target="_blank" 
                                className="text-decoration-none" rel={"noreferrer"}>
                                Events &amp; Press
                            </a>
                            <a href="https://wellbeingchampions.sg/resources-2/" target="_blank" 
                                className="text-decoration-none" rel={"noreferrer"}>
                                Resources
                            </a>
                            <a href="https://wellbeingchampions.sg/contact-us/" target="_blank" 
                                className="text-decoration-none" rel={"noreferrer"}>
                                Contact Us
                            </a>
                            </div>
                        </div>

                        <div className='col-xl-2 col-lg-2 col-md-4 col-6'>
                            <p className='mb-0 inter fw-bold pb-2 pb-lg-4 ls-near'>Terms & Conditions</p>
                            <div className={"fw-semibold inter d-flex flex-column gap-2"}>
                                <a href="https://kaleidoscope-public.s3.ap-southeast-1.amazonaws.com/kaleidoscope_privacy_policy.pdf"
                                   target="_blank" className='text-decoration-none' rel={"noreferrer"}>
                                    Privacy Policy
                                </a>
                                <a href="https://kaleidoscope-public.s3.ap-southeast-1.amazonaws.com/20231009.Disclaimer.pdf"
                                   target="_blank" className='text-decoration-none' rel={"noreferrer"}>
                                    Disclaimer
                                </a>
                            </div>
                        </div>

                        <div className='col-xl-2 col-lg-3 col-md-4 col-6'>
                            <p className='mb-0 fw-bold pb-2 pb-lg-4 ls-near'>Get In Touch</p>
                            <div className={"fw-semibold d-flex flex-column gap-2"}>
                                <a href={"mailto: hello@wellbeingchampions.sg"}
                                   className={"text-decoration-none d-flex align-items-center gap-3"}>
                                    <img src={Mail} alt="" width={30} className={"d-none d-xl-block"}/> hello@wellbeingchampions.sg
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className='row gy-lg-5 gy-3'>
                        <div className='col-xl col-lg col-md col'>
                            <p className='mb-0 inter fw-bold pb-2 pb-lg-4 ls-near'>Business Address</p>
                            <div className='fw-semibold d-flex flex-column gap-2'>
                                <p>c/o Workplace Safety and Health Council</p>                  
                                <p>1500 Bendemeer Rd, #04-01</p>
                                <p>Ministry of Manpower Services Centre</p>
                                <p>Singapore 339946</p>
                            </div>                            
                        </div>
                    </div>
                </div>

                <hr className="m-0 border-white border-2 bg-white opacity-100"/>

                <footer>
                    <div className="row">
                        <div className="col-3">
                            <Link to='/' className="d-lg-flex align-items-center d-none">
                                <img
                                    className='tw-h-[120px] tw-w-[130px]'
                                    src={KscopeLogo} alt=""/>
                            </Link>
                        </div>
                        <div
                            className="col-lg-6 col-12 small fw-bold d-flex align-items-center justify-content-center py-4 text-center">
                            Copyright © 2024 Well-being Champions Network.
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Footer;
