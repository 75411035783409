import React, {useEffect, useState} from "react";
import {createOffer, fetchInterestAPI, getTimeExPoints} from "../../../utils/apiUrlsSetup";
import {Link, useNavigate} from "react-router-dom";
import ExchangeReportModal from "./exchange-report-modal";
import LoggedInLayout from "../../../component/layout/LoggedInLayout.tsx";
import Points from "./Points.tsx";
import {growfield} from "../../../utils/textarea_helper";
import Swal from "sweetalert2";

const DAYS = {
  "Mon": "Monday",
  "Tue": "Tuesday",
  "Wed": "Wednesday",
  "Thu": "Thursday",
  "Fri": "Friday",
  "Sat": "Saturday",
  "Sun": "Sunday",
}

const TIME_SECTION = {
  "Morning": "07:00am - 12:00pm",
  "Afternoon": "12:00pm - 17:00pm",
  "Night": "17:00pm - 22:00pm",
}

const CreateOfferForm = () => {
  /**
   * @type {[Interest[], React.Dispatch<React.SetStateAction<Interest[]>>]}
   */
  const tabState = useState([]);
  const [tab, setTab] = tabState

  const [intresseIdtArray, setintressetIdArray] = useState([]);

  const [exchangeReportModal, setExchangeReportModal] = useState(false);

  const [titleError, setTitleError] = useState(null)
  const [descriptionError, setDescriptionError] = useState(null)
  const [durationError, setDurationError] = useState(null)
  const [interestError, setInterestError] = useState(null)
  const [availabilityError, setAvailabilityError] = useState(null)
  const [point, setPoints] = useState({});

  const getTimeExchangePoints = async () => {
    const responseData = await getTimeExPoints();
    if (responseData.status === 200) {
      setPoints(responseData.data.data);
    }
  }

  function handleReportSuccessModal() {
    Swal.fire("Thanks for telling us!", `We will evaluate the experience and will tell you the update of this report.`, "success")
  }

  const navigate = useNavigate();

  const [offer, setoffer] = useState({
    title: "",
    description: "",
    category: [],
    noOfHours: 1,
    isClosed: false,
    availability: [],
  });

  useEffect(() => {
    getInterestApi();
    getTimeExchangePoints();
  }, []);

  useEffect(() => {
    if (intresseIdtArray.length) {
      setInterestError('')
    }
  }, [intresseIdtArray])

  const onChangeHandler = (e) => {
    if (e.target.type === "checkbox") {
      const value = e.target.value;
      const availability = [...offer.availability];
      if (availability.indexOf(value) === -1) {
        availability.push(value);
      } else {
        const index = availability.indexOf(value);
        availability.splice(index, 1);
      }

      setoffer({...offer, availability: availability});
    } else {
      let value = e.target.value;
      let name = e.target.name;
      setoffer({...offer, [name]: value});
    }

    if (e.target.name === 'title') {
      setTitleError('')
    }
    if (e.target.name === 'description') {
      setDescriptionError('')
    }
    if (e.target.name === 'noOfHours') {
      setDurationError('')
    }
    if (e.target.name === 'availability') {
      setAvailabilityError('')
    }
    if (e.target.name === 'availability') {
      setAvailabilityError('')
    }

  };

  const handleSubmit = async () => {
    const isValidate = validationHandler()
    if (!isValidate) return
    offer.category = intresseIdtArray;
    offer.publishedAt = new Date();
    const responseData = await createOffer(offer);
    if (responseData.status === 200) {
      Swal.fire("Gift Posted!", "Now everyone can see and request your gift", "success")
      navigate("/time-exchange")
    }
  };

  const validationHandler = () => {
    var isValidate = true
    if (offer.title === "") {
      setTitleError('Title is required.')
      isValidate = false
    }
    if (offer.description === "") {
      setDescriptionError('Description is required.')
      isValidate = false
    }
    if (offer.noOfHours === '') {
      setDurationError('Duration is required.')
      isValidate = false
    }
    if (intresseIdtArray.length === 0) {
      setInterestError('Selection of at least one area of interest is required.')
      isValidate = false
    }
    if (offer.availability.length === 0) {
      setAvailabilityError(' Availability is required.')
      isValidate = false
    }

    return isValidate
  }


  const handleCloseModal = (path) => {
    navigate(`/time-exchange`);
  };

  const getInterestApi = async () => {
    let responseData = await fetchInterestAPI();
    setTab(responseData?.data?.data?.interestArray ?? []);
  };

  return (
    <LoggedInLayout title={"Post a Gift"}>
      <div className="container">
        <div className="d-flex flex-column gap-4">
          <div>
            {/* Title */}
            <div className="d-flex justify-content-between">
              <h1 className={"tw-text-[#410db7] montserrat fw-bold tw-text-2xl m-0"}>Post a Gift</h1>
              <Points wbPoints={point.wbPoints} wbTime={point.wbTime}/>
            </div>

            <p className={"tw-text-[#410db7] tw-leading-5 mt-1"}>
              A well-being gift is an offer of your time to listen to,
              mentor, or do an act of kindness for a fellow community member.
            </p>
          </div>

          <div className="card card-body border-0 gap-4">
            <div className="form-group">
              <label htmlFor="title">Title of Well-being Gift</label>
              <input
                type="text"
                className="form-control"
                name="title"
                onChange={onChangeHandler}
              />
              <span className="text-danger m-0">{titleError}</span>
            </div>

            <div className="form-group">
              <label htmlFor="description" className={"tw-leading-5"}>Description</label>
              <textarea
                placeholder={`Provide a description of your well-being gift. This will appear in the body of your post.`}
                className="form-control"
                name="description"
                rows="3"
                onChangeCapture={(e) => {
                  /**
                   * @type {HTMLTextAreaElement}
                   */
                  const textarea = e.target;
                  onChangeHandler(e)
                  growfield(textarea, 3)
                }}
              ></textarea>
              <span className="text-danger m-0">{descriptionError}</span>
            </div>

            <div className="form-group">
              <label htmlFor="area_of_interest">Area of Interest</label>
              <div className="tw-font-medium tw-text-sm text-secondary tw-mb-2">
                (Choose 1 to 3 tags)
              </div>
              <div className={"row g-3"}>
                {tab.map(e => {
                  return (
                    <div key={e.id} className={"col-lg-3 col-md-4 col-6"}>
                      <div
                        className={`
                    tw-border
                    tw-cursor-pointer
                    tw-rounded-[60px]
                    tw-border-[#54bfed]
                    ${intresseIdtArray.includes(e.name) ? "active_interest" : ""}
                  `}
                        onClick={() => {
                          if (intresseIdtArray.includes(e.name)) {
                            setintressetIdArray(prev => [...prev.filter(p => p !== e.name)])
                          } else if (intresseIdtArray.length < 3) {
                            setintressetIdArray(prev => [...prev, e.name])
                          }
                        }}
                      >
                        <div
                          className="d-flex tw-gap-0.5 px-2 p-2">
                          <img src={e.image} alt={e.image}
                               className={"px-2 tw-h-full tw-object-center tw-object-fill"}/>
                          {e.name}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <span className="text-danger m-0">{interestError}</span>
            </div>

            <div className="form-group">
              <label htmlFor="title">Duration</label>
              <select name="noOfHours" className={"form-select"} id="noOfHours"
                      onChange={onChangeHandler}>
                <option value={0.5}>0.5 Hour</option>
                <option value={1}>1 Hour</option>
                <option value={1.5}>1.5 Hours</option>
                <option value={2}>2 Hours</option>
              </select>
              <span className="text-danger m-0">{durationError}</span>
            </div>


            <div className="form-group">
              <label htmlFor="title" className={"tw-leading-5"}>
                Availability (Please indicate your availability for the exchange. You can confirm the exact time
                with the fellow member accepting your gift later.)
              </label>

              <div className="card card-body mt-2">
                <div className="tw-grid tw-grid-cols-9">
                  <div className="tw-col-span-2"></div>
                  {Object.keys(DAYS).map(day =>
                    <div className="text-center" key={day}>
                      <p className="mb-0 cal_text_head cal_text_head_offer">
                        {day}
                      </p>
                    </div>
                  )}
                </div>

                {Object.entries(TIME_SECTION).map(([time_label, time_range]) => {
                  return (
                    <div className="tw-grid tw-grid-cols-9 tw-mt-3" key={time_label}>
                      <div className="tw-col-span-2">
                        <p className="mb-0 cal_text_head cal_text_head_offer">
                          {time_label}
                        </p>
                        <p className="mb-0 cal_text_head cal_text_head_offer">
                          {time_range}
                        </p>
                      </div>
                      {Object.keys(DAYS).map((val, index) => {
                        return (
                          <div key={index}>
                            <div className="form-check tw-flex tw-items-center tw-justify-center">
                              <input
                                className="form-check-input circle_white_box"
                                type="checkbox"
                                value={`${DAYS[val]}_${time_label}`}
                                id="flexCheckDefault"
                                name="availability"
                                onChange={onChangeHandler}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )
                })}
              </div>

              <span className="text-danger m-0">{availabilityError}</span>
            </div>


            <div className="gap-4 d-flex align-items-center justify-content-center">
              <Link to={"/time-exchange"}
                    className="white_box_cancel tw-flex tw-items-center tw-justify-center"
              >
                Cancel
              </Link>
              <button
                className="white_box_confirm"
                onClick={handleSubmit}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      {exchangeReportModal ? (
        <ExchangeReportModal
          setExchangeReportModal={setExchangeReportModal}
          exchangeReportModal={exchangeReportModal}
          isReport={true}
          bidId={0}
          offerId={0}
          handleReportSuccessModal={handleReportSuccessModal}
          handleClose={() => setExchangeReportModal(false)}
        />
      ) : null}
    </LoggedInLayout>
  );
};

export default CreateOfferForm;
