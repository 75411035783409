import {Link} from "react-router-dom";
import moment from "moment/moment";
import DocumentPostView from "./DocumentPostView";
import {Quill} from "react-quill";
import React, {useEffect, useState} from "react";
import 'react-quill/dist/quill.snow.css';
import {PostClipboard} from "../../../../utils/plainClipboard";
import {Tooltip as ReactTooltip} from "react-tooltip";
import PostText from "../../../../component/PostText";
import {getPosts} from "../../../../utils/apiUrlsSetup";
import ImagePostCarousel from "./ImagePostCarousel";

Quill.register("modules/clipboard", PostClipboard, true);

/**
 * @param {{
 *   id: number,
 *   showFullAttachments: boolean
 * }} props
 * @returns {Element}
 * @constructor
 */
export function PostCardPreview(props) {

  /**
   * @type {[Post | null, React.Dispatch<React.SetStateAction<Post | null>>]}
   */
  const postState = useState(null);
  const [post, setPost] = postState

  useEffect(() => {
    if (!props.id) {
      setPost(null)
      return;
    }
    if (props.id && post?.id !== props.id) {
      getPosts(props.id).then(response => {
        setPost(response.data.data.postArray[0]);
      });
    }
  }, [props.id, post?.id, setPost]);

  if (!post) return <></>

  return (
    <div className={"bg-white p-3 pb-1 rounded-4 d-flex flex-column gap-3 border"}>

      {/* First row containing author and action menu */}
      <div className={"d-flex justify-content-between"}>

        {/* Author */}
        <div className={"d-flex align-items-center gap-2"}>

          {/* Avatar */}
          <img src={post.user.profilePic ? post.user.profilePic : "/static/png/Male.png"}
               className={"tw-w-[38px] lg:tw-w-[48px] tw-aspect-square flex-shrink-0 tw-rounded-3xl object-fit-cover tw-object-center"}
               alt={"Profile Pic"}
          />

          {/* Author and Post Information. Can be organization or user. */}
          <div className={"d-flex flex-column"}>
            <div className="d-flex align-items-center gap-1">
              <Link
                to={post.organisationId ? `/organisation/${post.organisationId}` : `/friends/${post.user.id}`}
                className="d-flex tw-text-[#18214d] fw-bold tw-text-sm"
              >
                {/* Author's First Name and Last Name */}
                {post.user.firstName} {post.user.lastName}
              </Link>

              {/* Add info if this post is a repost */}
              {post.linkedPostId &&
                <div className={"tw-text-sm text-secondary"}>
                  <small>shared a post</small>
                </div>
              }
            </div>
            <div className={"d-flex gap-1 align-items-center"}>
              {/* Mobile: How many days ago since posted*/}
              <div
                className="mb-0 montserrat text-secondary opacity-50 ls-near tw-text-xs tw-font-medium">
                {moment(post.createdAt).fromNow()}
              </div>

              {/* Separate with a dot */}
              <i className="bi bi-dot"></i>

              <div className="mb-0 tw-text-xs text-secondary">
                {post.organisation?.name ?
                  <span
                    className={"tw-cursor-default"}
                    data-tooltip-id={`visibility-${post.id}`}
                    data-tooltip-content={`Visible to ${post.organisation.name}`}
                  >
                    {post.organisation.name}

                    <i className={"bi bi-people ms-1"}/>
                  </span>
                  :
                  <>
                    {post.visible === "everyone" && (
                      <i className={"bi bi-globe"}
                         data-tooltip-id={`visibility-${post.id}`}
                         data-tooltip-content={`Visible to ${post.visible[0].toUpperCase()}${post.visible.slice(1)}`}
                      />
                    )}
                    {post.visible === "friends-only" && (
                      <i className={"bi bi-people"}
                         data-tooltip-id={`visibility-${post.id}`}
                         data-tooltip-content={`Visible to ${post.visible[0].toUpperCase()}${post.visible.slice(1)}`}
                      />
                    )}
                    {post.visible === "only-me" && (
                      <i className={"bi bi-lock"}
                         data-tooltip-id={`visibility-${post.id}`}
                         data-tooltip-content={`Visible to ${post.visible[0].toUpperCase()}${post.visible.slice(1)}`}
                      />
                    )}
                  </>
                }

                <ReactTooltip
                  id={`visibility-${post.id}`}
                  place={"right"}
                />
              </div>

              {/* Mobile: Category Tag / Badge if any */}
              {((post.subCategory ?? []).length > 0 && post.subCategory[0]) && (
                <>
                  {/* Separate with a dot */}
                  <i className="bi bi-dot"></i>

                  <div className={"badge tw-bg-[#b6d7f2] tw-font-[500] text-dark"}>
                    {post.subCategory[0]}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Fourth row, post's attachments. Each type have different view */}
      {post.type === "image" && (
        <div>
          {props.showFullAttachments ? (
            <>
              {/* Carousel view for lot-of-images */}
              {post.attachment.length > 1 && <ImagePostCarousel images={post.attachment}/>}
              {post.attachment.length === 1 &&
                <img className="tw-w-full" alt={"Post"} src={post.attachment[0]}/>
              }
            </>
          ) : (
            <div className={"text-start tw-font-medium tw-text-md tw-text-[#410db7]"}>
              <i className="bi bi-image"/> 1 image attached
            </div>
          )}
        </div>
      )}

      {/* Fourth row, post's attachments. This one for document attachment */}
      {post.type === "document" && (
        <DocumentPostView
          url={post.attachment[0]}
          count={post.count}
        />
      )}

      {/* Fourth row, post's attachments. This one for video attachment */}
      {post.type === "video" && (
        <div>
          <video controls className={"tw-max-h-[500px] mx-auto"}>
            <source src={post.attachment[0]} type="video/mp4"/>
          </video>
        </div>
      )}

      {/* Second row containing post's content, linkified */}
      <div className={"inter fw-normal text-dark tw-whitespace-break-spaces"}>
        <PostText content={post.description}/>
      </div>
    </div>
  )
}