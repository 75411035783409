import { SAVE } from './../../types/types';

export const points = (state = {}, action) => {
    switch (action.type) {
        case SAVE:
            return {
                ...state,
                points: action.payload
            }
        default: return state
    }

}