import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";


const Banner = (props) => {
    return (
        <Carousel showIndicators={false} autoPlay={true}>
            {
                props.huddleData.map(e => {
                    return (
                        <div className="banner-container" onClick={() => props.navigate(`/event/${e.id}`)}>
                            <img src={e.image}/>
                        </div>
                    )
                })
            }

        </Carousel>
    );
};

export default Banner
