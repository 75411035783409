import React from 'react'

const RejectModal = (props) => {
    return (
        <div>
            <div class={`modal fade ${props.isOpenModal ? 'show' : ''}`} style={{ display: props.isOpenModal ? 'block' : 'none', background: props.isOpenModal ? 'rgba(0,0,0,0.5)' : '' }} id="coachRejectModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">

                        <div className='row m-0'>
                            <div className='col-12 p-0 px-3 py-3'>

                                <div className='row m-0'>
                                    <div className='col-lg-6 col-md-6 col-10 p-0 d-flex align-items-center justify-content-center'>
                                        <p className='mb-0 sup_adm_modal_heading'>Rejection form</p>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-2 p-0 d-flex align-items-center justify-content-end'>
                                        <button type="button" class="btn-close" onClick={() => props.setIsOpenModal(false)}></button>
                                    </div>
                                </div>

                                <div className='row m-0 mt-3'>
                                    <div className="col-12 p-0">
                                        <p className='mb-0 admin_reject_pera'>Reason for rejection:</p>
                                    </div>
                                </div>

                                <div className='row m-0 mt-3'>
                                    <div className="col-12 p-0">
                                        <textarea className='p-2 admin_reject_textarea' placeholder='Type your reasons for rejecting the organisation here.'></textarea>
                                    </div>
                                </div>

                                <div className='row m-0 mt-3'>
                                    <div className="col-12 p-0">
                                        <p className='mb-0 admin_reject_pera2 text-lg-start text-md-start text-center'>The admin who applied will get notified by email that you rejected their profile.</p>
                                    </div>
                                </div>

                                <div className='row m-0 mt-4'>
                                    <div className='col-12 p-0 d-flex flex-lg-row flex-md-row  flex-column align-items-center justify-content-end'>
                                        <button className='coach_edit_btn_reject coach_edit_btn_reject_mob p-0 me-lg-3' onClick={() => props.setIsOpenModal(false)}>Cancel</button>
                                        <button className='coach_btn_reject_accept coach_edit_btn_reject_mob ms-lg-3 mt-lg-0 mt-md-0 mt-3' onClick={() => props.handleRejection(props.id)}>Confirm Rejection</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default RejectModal
