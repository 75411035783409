import React from "react";

/**
 *
 * @param {string} title
 * @param {ReactNode?} rightElement
 * @returns {Element}
 * @constructor
 */
export default function PageHeader({title, rightElement}) {
    return (
        <div className="d-flex justify-content-between">
            <h1 className={"tw-text-[#54bfed] montserrat fw-bold tw-text-2xl m-0 lh-base"}>{title}</h1>
            {rightElement}
        </div>
    )
}