import React, {useEffect} from "react";
import Routing from "./Routing";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import SOS from "../component/modals/sos"
import ReactGA from 'react-ga';
import FullScreenImage from "./FullScreenImage";
import {useZStore} from "../utils/zStore";


function Layout() {
  const store = useZStore()
  ReactGA.initialize('G-JM6S9QV2MV');

  const handleClickOutside = (e) => {
    if (!e.target.classList.contains("reactionShowTopup")) {
      store.setShowingReactionTopId(``)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    ReactGA.pageview(window.location.pathname);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  },[])

  return (
    <>
      <Routing />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <SOS/>
      <FullScreenImage />
    </>
  );
}

export default Layout;
