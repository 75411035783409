import React, {useEffect, useState} from "react";

import "../organization/organization.css";
import {getOrgs, getOrgsForUser, getProgramById,} from "../../utils/apiUrlsSetup";
import {useParams} from "react-router";
import {Link} from "react-router-dom";
import parse from "html-react-parser";
import EnquiryModal from "../../component/modals/enquiryModal/enquiryModal";
import CreateProgramModal from "./components/createProgramModal";
import LoggedInLayout from "../../component/layout/LoggedInLayout.tsx";
import _ from "lodash"

const Program = () => {
  const [program, setProgram] = useState({});
  const [userOrganisation, setUserOrganisation] = useState([]);

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [isOpen, setIsOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const {id} = useParams();

  useEffect(() => {
    getProgramById(id).then(response => {
      if (response && response.status === 200) {
        setProgram(response.data.data.program);
      }
    })
  }, [id]);

  useEffect(() => {
    getOrgs(id).then(response => {
      if (response.status === 200) {
        getOrgsForUser("accepted").then(responseAll => {
          if (responseAll.status === 200) {
            const userOrg = responseAll.data.data.organisationArray.organisationArray.filter(
              (v) => v.status !== "deleted" && v.createdByUserId === userDetails.id
            );

            if (userOrg) {
              setUserOrganisation([...userOrg]);
            }
          }
        })
      }
    })
  }, [id, userDetails.id]);

  if (_.isEmpty(program)) {
    return <>Loading...</>
  }

  return (
    <LoggedInLayout>

      <div className="d-flex flex-column gap-3">
        {/* Page Header */}
        <div className={"d-flex flex-column gap-2"}>
          <p className="newsfeed_event_heading tw-font-semibold">
            {program.title}
          </p>

          <p className="newsfeed_event_info">
            Programme By{" "}
            <Link
              className={"inter fw-bold tw-text-[#410db7]"}
              to={userDetails.id === program.organisation.createdByUserId ? `/profile` : `/friends/${program.organisation.createdByUserId}`}>
              {program.organisation.createdByUser.firstName}
              {" "}
              {program.organisation.createdByUser.lastName}
            </Link>
          </p>

          <img
            height="350px"
            className={"w-100"}
            style={{objectFit: "contain"}}
            src={program.image}
            alt=""
          />
        </div>

        {/* Page Content */}
        <div className="d-flex flex-wrap flex-md-nowrap gap-3">
          <div className="flex-grow-1 order-2 order-md-1 d-flex flex-column gap-3">
            <p className="about_evt">About the Programme</p>

            <p className="about_evt_info">
              {parse(program.description)}
            </p>

            <hr className="m-0"/>

            <p className="about_evt">What will you take away?</p>
            <p className="about_evt_info">
              {parse(program.userLearnings)}
            </p>

            <hr/>

            <p className="about_evt">Who is the programme designed for?</p>
            <p className="about_evt_info">
              {parse(program.preRequisites)}
            </p>
          </div>

          <div className="flex-shrink-0 order-1 order-md-2 tw-w-full md:tw-w-fit d-flex flex-column gap-3">
            <p className="evt_type">Community</p>
            <Link
              to={`/organisation/${program.organisation.id}`}
            >
              <img
                className="tw-w-[30px] tw-h-[30px] rounded-circle tw-object-contain bg-white tw-object-center"
                src={program.organisation.image}
                alt=""
              />
            </Link>
            {
              parseInt(userDetails.id) !== program.userId && (
                <button className="evt_reg_btn text-white"
                        onClick={() => setIsOpen(!isOpen)}>
                  Enquire Now
                </button>
              )
            }

            {
              parseInt(userDetails.id) === program.userId ? (
                <button className="evt_reg_btn text-white"
                        onClick={() => setOpenModal(true)}>
                  Edit Programme
                </button>
              ) : null
            }

          </div>
        </div>
      </div>

      <EnquiryModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={`Enquiry for program --> ${program.title}`}
      />

      {openModal && (
        <CreateProgramModal
          setOpenModal={(value) => {
            setOpenModal(value);
          }}
          userOrganisation={userOrganisation}
          program={program}
          openModal={openModal}
        />
      )}
    </LoggedInLayout>
  )
};

export default Program;
