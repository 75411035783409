import React, { useEffect, useState } from 'react';
import SideNav from '../../../component/staticPages/SideNav';

import Seacrh from '../../../assets/svg/WhiteSearch.svg';
import Calender from '../../../assets/svg/Calender.svg';
import List_alt from '../../../assets/svg/List.svg';
import Left_arrow from '../../../assets/svg/LeftVector.svg';
import Right_arrow from '../../../assets/svg/RightVector.svg';
import '../../../css/community_tab.css'
import Orange from '../../../assets/orange_rectangle.png';
import Green from '../../../assets/green_rectangle.png';
import Red from '../../../assets/red_rectangle.png';
import Video from '../../../assets/videocam_comm.png';
import Worng from '../../../assets/worng.png';
import Right from '../../../assets/right_green.png';
import Chat from "../../../assets/svg/SideNav/Chat.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import comm_eve_1 from "../../../assets/comm_eve_1.png";
import comm_eve_2 from "../../../assets/comm_eve_2.png";
import comm_eve_3 from "../../../assets/comm_eve_3.png";
import BackCross from "../../../assets/BackCross.png";
import ResponsiveNav from '../../../component/staticPages/responsiveNavBar/responsiveNav'
import { createChat } from '../../../utils/apiUrlsSetup';

const CommunityCoachesWeb = (props) => {
    const [events, setEvents] = useState([]);

    const navigateToLink = useNavigate();

    const [filteredSession, setFilteredSession] = useState([])

    const [currentMonth, setCurrentMonth] = useState(moment().format('MMMM YYYY'))

    const [currentMonthMoment, setCurrentMonthMoment] = useState(moment())

    const [currentWeekMoment, setCurrentWeekMoment] = useState(moment())

    const [duration, setDuration] = useState('Monthly')

    const [firstName, setFirstName] = useState("")

    const [filteredEvents, setFilteredEvents] = useState([]);

    const [showEventModal, setShowEventModal] = useState(false);
    const [currentEventDetails, setCurrentEventDetails] = useState({});
    const [showSessionModal, setShowSessionModal] = useState(false);
    const [currentSessionDetails, setCurrentSessionDetails] = useState({});

    const [isOpen, setIsOpen] = useState(false);
    const [moreEvents, setMoreEvents] = useState([]);

    const [currentWeek, setCurrentWeek] = useState(Math.ceil(moment().date() / 7))

    const calendarStyle = (event) => {
        return {
            style: {
                backgroundColor: 'white',
                color: "black", //but why doesn't this work?
                borderLeft: event.type === "session" ? `3px solid ${event.status === "pending" ? "yellow" : event.status === "rejected" ? "red" : "green"}`
                    : `3px solid ${event.status === "invited" ? "yellow" : event.status === "not-attending" ? "red" : "green"}`,
                borderRadius: '0px'
            },
        };
    };

    useEffect(() => {
        var data = [...props.sessions]
        if (data.length) {
            var filteredData = data.filter(d => moment(d.startDate).utc().format('MMMM YYYY') === currentMonthMoment.format('MMMM YYYY'))
            setFilteredSession(filteredData)
        }
    }, [props.sessions])

    useEffect(() => {
        var data = [...props.eventData]
        if (data.length) {
            var filteredData = data.filter(d => moment(d.event.startDate).utc().format('MMMM YYYY') === currentMonthMoment.format('MMMM YYYY'))
            setFilteredEvents(filteredData)
        }
    }, [props.eventData])

    useEffect(() => {
        if (props.activeTab !== 'List') {
            var data = [...props.sessions]
            var evts = data.map(value => {
                return {
                    end: value.endDate.split("T")[0],
                    start: value.startDate.split("T")[0],
                    title: "1-1 Session",
                    backgroundColor: "#ff0000",
                    status: value.status,
                    data: value,
                    type: "session",
                    modalHead: 'Detail Session'
                }
            })
            data = [...props.eventData]
            data.forEach(ele => {
                evts.push({
                    end: ele.event.endDate.split("T")[0],
                    start: ele.event.startDate.split("T")[0],
                    title: `${ele.event.name}`,
                    backgroundColor: "#ff0000",
                    status: ele.status,
                    data: ele.event,
                    type: "event"

                })
            })
            setEvents(evts)
        }
    }, [props.activeTab, props.eventData, props.sessions])

    const handleDuration = (event) => {
        const value = event.target.value
        setDuration(value)
        var data = [...props.sessions]
        if (value === 'Weekly') {
            var mondayDate = moment(currentWeekMoment._d).weekday(1)
            var sundayDate = moment(currentWeekMoment._d).weekday(7)
            var filteredData = data.filter(d => moment(d.startDate)._d >= mondayDate._d && moment(d.startDate)._d <= sundayDate._d)
            data = [...props.eventData]
            var filteredEvents = data.filter(d => moment(d.event.startDate)._d >= mondayDate._d && moment(d.event.startDate)._d <= sundayDate._d)
            setFilteredEvents(filteredEvents)
            setFilteredSession(filteredData)
        } else {
            var filteredData = data.filter(d => moment(d.startDate).format('MMMM YYYY') === currentMonthMoment.format('MMMM YYYY'))
            data = [...props.eventData]
            var filteredEvents = data.filter(d => moment(d.event.startDate).format('MMMM YYYY') === currentMonthMoment.format('MMMM YYYY'))
            setFilteredEvents(filteredEvents)
            setFilteredSession(filteredData)
        }
    }

    const changeMonth = (direction) => {
        var date
        var data = [...props.sessions]
        if (direction === 'right') {
            date = currentMonthMoment.add(1, 'month')
        } else {
            date = currentMonthMoment.subtract(1, 'month')
        }

        var filteredData = data.filter(d => moment(d.startDate).utc().format('MMMM YYYY') === date.format('MMMM YYYY'))
        data = [...props.eventData]
        var filteredEvents = data.filter(d => moment(d.event.startDate).utc().format('MMMM YYYY') === date.format('MMMM YYYY'))
        setFilteredEvents(filteredEvents)
        setFilteredSession(filteredData)
        setCurrentMonthMoment(date)
        setCurrentWeekMoment(date)
        setCurrentMonth(date.format('MMMM YYYY'))
    }

    const changeWeek = (direction) => {
        var mondayDate
        var sundayDate
        var data = [...props.sessions]
        if (direction === 'right') {
            if (currentWeek < 5) {
                setCurrentWeek(currentWeek + 1)
                var nextWeek = moment(currentWeekMoment._d).add(1, 'week')
                setCurrentWeekMoment(nextWeek)
                mondayDate = moment(nextWeek._d).clone().weekday(1)
                sundayDate = moment(nextWeek._d).clone().weekday(7)
            }
        } else {
            if (currentWeek > 1) {
                setCurrentWeek(currentWeek - 1)
                var prevWeek = moment(currentWeekMoment._d).subtract(1, 'week')
                setCurrentWeekMoment(prevWeek)
                mondayDate = moment(prevWeek._d).clone().weekday(1)
                sundayDate = moment(prevWeek._d).clone().weekday(7)
            }
        }

        if (currentWeek < 5 && currentWeek > 1) {
            var filteredData = data.filter(d => moment(d.startDate)._d >= mondayDate._d && moment(d.startDate)._d <= sundayDate._d)
            data = [...props.eventData]
            var filteredEvents = data.filter(d => moment(d.event.startDate)._d >= mondayDate._d && moment(d.event.startDate)._d <= sundayDate._d)
            setFilteredEvents(filteredEvents)
            setFilteredSession(filteredData)
        }
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    useEffect(() => {
        setFirstName(capitalizeFirstLetter(props.userDetails.firstName))
    }, [])

    const handleRequest = async (session, flag) => {
        var status
        if (session.userId === props.userDetails.id) {
            status = 'cancelled'
        } else if (flag === 'left' && session.instructorId === props.userDetails.id) {
            status = 'rejected'
        } else if (flag === 'right' && session.instructorId === props.userDetails.id) {
            status = 'confirmed'
        }

        const response = await props.updateBid({ status: status, oneToOneSessionId: session.id })

        if (response && response.status === 200) {
            await props.getSessionData()
        }

        if (showSessionModal) {
            setShowSessionModal(false)
        }
    }

    const updateEventInvite = async (status, event) => {
        const data = { status: status }
        const response = await props.updateEventInvitation(event.eventId, data)
        if (response && response.status === 200) {
            await props.getEventData();
            setShowEventModal(false);
        }
    }

    const rediretToChatPage = async (id) => {
        let obj = {
            membersIdArray: [id],
        };
        let res = await createChat(obj);
        if (res.status === 200) {
            navigateToLink("/chat", { state: id });
        }
    };


    return (
        <div>
            <div className="row m-0 w-100 d-lg-none d-none d-md-flex top_chat_input_tab_parent">
                <ResponsiveNav headerName="Community" heading="Community" />
            </div>
            <div className='row m-0 d-flex newsfeed_bg comminty_coaches_web'>

                <div className='news_left p-0 d-none d-lg-block'>
                    <SideNav community={true} />
                </div>

                <div className='news_right p-0 '>

                    <div className='row m-0'>
                        <div className='col-12 p-4 pe-lg-5'>

                            <div className='row m-0 mt-3'>
                                <div className='col-lg-12 col-md-10 p-0'>
                                    <p className='community_heading'>{`Hello, ${firstName}!`}</p>
                                </div>
                                <div className='list_calender ms-4 mb-3  d-flex col-2 p-0 d-lg-none justify-content-end'>
                                    <div className={`${props.activeTab === "List" ? "activeClassName" : ""} calender cursor_pointer d-flex justify-content-center align-items-center`}>
                                        <img src={List_alt} onClick={() => props.setActiveTab("List")} />
                                    </div>
                                    <div className={`${props.activeTab === "Calendar" ? "activeClassName" : ""} calender cursor_pointer d-flex justify-content-center align-items-center ms-2`}>
                                        <img src={Calender} onClick={() => props.setActiveTab("Calendar")} />
                                    </div>
                                </div>
                            </div>


                            <div className='row m-0 mt-1 align-items-center mb-3 mb-lg-0'>
                                {
                                    props.activeTab === "List" ? (
                                        <div className='col-6 p-0 d-flex'>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <p className='comm_month mb-0 '>{currentMonth}</p>
                                            </div>
                                            <div className='ms-lg-4 ms-4 d-flex justify-content-center align-items-center'>
                                                <p className='comm_week mb-0'><img className='' src={Left_arrow} onClick={() => duration === 'Monthly' ? changeMonth('left') : changeWeek('left')} />
                                                    {duration === 'Monthly' ? currentMonth : `Week ${currentWeek} of 5`}
                                                    <img className='' src={Right_arrow} onClick={() => duration === 'Monthly' ? changeMonth('right') : changeWeek('right')} /></p>
                                            </div>
                                            <div className='ms-4 ms-lg-5'>
                                                <div className="btn-group pt-1" style={{ background: '#ffff', borderRadius: '8px' }}>
                                                    <select value={duration} name="duration" className="form-select admin_select_userdetails admin_select_userdetails_md admin_sel" aria-label="Default select example" onChange={handleDuration}>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    ) : <div className='col-6 p-0 d-flex'></div>
                                }


                                <div className='col-6 p-0 d-flex justify-content-end align-items-center'>

                                    <div className='list_calender  d-flex d-none d-lg-flex'>
                                        <div className={`${props.activeTab === "List" ? "activeClassName" : ""} calender cursor_pointer d-flex justify-content-center align-items-center`}>
                                            <img src={List_alt} onClick={() => props.setActiveTab("List")} />
                                        </div>
                                        <div className={`${props.activeTab === "Calendar" ? "activeClassName" : ""} calender cursor_pointer d-flex justify-content-center align-items-center ms-2`}>
                                            <img src={Calender} onClick={() => props.setActiveTab("Calendar")} />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {props.activeTab === "List" ? <>

                                <div className='row m-0 mt-1'>
                                    <div className='col-12 p-0'>
                                        <p className=' mb-0 session'>1-1 Sessions</p>
                                    </div>
                                </div>


                                <div className='row m-0 mt-3'>
                                    <div className='col-12 p-0'>

                                        {/* Orange */}
                                        <div className='row m-0'>
                                            <div className='col-12 p-0'>
                                                <button className='orange_btn session_btn'>PENDING REQUEST</button>
                                            </div>
                                        </div>

                                        {
                                            filteredSession.filter(session => session.status === 'pending').length > 0 ?
                                                (filteredSession.map((session, i) => {
                                                    if (session.status === 'pending') {
                                                        return (
                                                            // <div className='row m-0 session_bg pb-2 ps-4 pt-2'>
                                                            //     <div className='col-5 p-0 d-flex justify-content-start align-items-center'>
                                                            //         <img className='me-2' config={Orange} />
                                                            //         <p className='mb-0 session_detail ms-2' >{`Sessions with ${session.instructor.id !== props.userDetails.id ? session.instructor.firstName : session.user.firstName}`}</p>

                                                            //     </div>

                                                            //     <div className='col-7 p-0 d-flex'>
                                                            //         {
                                                            //             session.offer.category ? (
                                                            //                 <div className='col-3'>
                                                            //                     <button className='mental'>{session.offer.category}</button>
                                                            //                 </div>
                                                            //             ) : <div className='col-3'>
                                                            //             </div>
                                                            //         }


                                                            //         <div className='ms-5'>
                                                            //             <p className='day mb-0 ms-5'>{moment(session.startDate).format('DD-MM-YYYY')}</p>
                                                            //         </div>

                                                            //         <div className='ms-5'>
                                                            //             <p className='time mb-0 ms-5'>{`${session.timeSlot} - ${moment(session.timeSlot + session.offer.noOfHours >= 24 ? session.timeSlot + session.offer.noOfHours - 24 : session.timeSlot + session.offer.noOfHours, "H").format("h A")}`}</p>
                                                            //         </div>

                                                            //         <div className='ms-5' >

                                                            //             <img className='ms-5' config={Worng} onClick={() => handleRequest(session, 'left')} />
                                                            //             {
                                                            //                 session.userId === props.userDetails.id ?
                                                            //                     null : <img className='ms-4' config={Right} onClick={() => handleRequest(session, 'right')} />
                                                            //             }

                                                            //         </div>
                                                            //     </div>
                                                            // </div>

                                                            <div className='row m-0 session_bg pb-2 ps-4 pt-2' key={i}>
                                                                <div className='col-5 p-0 d-flex justify-content-start align-items-center'>
                                                                    <img className='me-2' src={Orange} />
                                                                    <p className='mb-0 session_detail ms-2' >{`Sessions with ${session.instructor.id !== props.userDetails.id ? session.instructor.firstName : session.user.firstName}`}</p>

                                                                </div>

                                                                <div className='col-7 p-0 d-flex'>

                                                                    <div className="row m-0 w-100 align-items-center">
                                                                        {

                                                                            session.offer.category ? (
                                                                                <div className='col-2'>
                                                                                    {session.offer.category.map((v, i) => <button className='mental' key={i}>{v}</button>)}
                                                                                </div>
                                                                            ) : <div className='col-2'>
                                                                            </div>
                                                                        }


                                                                        <div className='col-3'>
                                                                            <p className='day mb-0 ms-5'>{moment(session.startDate).utc().format('DD-MM-YYYY')}</p>
                                                                        </div>

                                                                        <div className='col-4'>
                                                                            {/* <p className='time mb-0 ms-5'>{`${session.timeSlot} - ${moment(session.timeSlot + session.offer.noOfHours >= 24 ? session.timeSlot + session.offer.noOfHours - 24 : session.timeSlot + session.offer.noOfHours, "H").format("h A")}`}</p> */}

                                                                            <p className='time mb-0 ms-5'>
                                                                                {session.timeSlot}:00 {session.timeSlot < 12 ? 'AM' : 'PM'} - {session.timeSlot + 1}:00 {(session.timeSlot + 1) < 12 ? 'AM' : 'PM'}
                                                                            </p>
                                                                        </div>

                                                                        <div className='col-2'>
                                                                            <div className='d-flex align-items-center' >
                                                                                <img className='ms-5 tw-cursor-pointer' src={Worng} onClick={() => handleRequest(session, 'left')} />
                                                                                {
                                                                                    session.userId === props.userDetails.id ?
                                                                                        null : <img className='ms-4 tw-cursor-pointer' src={Right} onClick={() => handleRequest(session, 'right')} />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })) : <div style={{ textAlign: 'center' }} className='row m-0 session_bg pb-2 ps-4 pt-2'>
                                                    No Pending Request
                                                </div>
                                        }


                                        {/* orange */}
                                        {/* Green */}

                                        <div className='row m-0 mt-4'>
                                            <div className='col-12 p-0'>
                                                <button className='green_btn session_btn'>CONFIRMED</button>
                                            </div>
                                        </div>
                                        {
                                            filteredSession.filter(session => session.status === 'confirmed').length > 0 ?
                                                (filteredSession.map((session, i) => {
                                                    if (session.status === 'confirmed') {
                                                        return (
                                                            <div className='row m-0 session_bg pb-2 ps-4 pt-2' key={i}>
                                                                <div className='col-5 p-0 d-flex justify-content-start align-items-center'>
                                                                    <img className='me-2' src={Green} />
                                                                    <p className='mb-0 session_detail ms-2'>{`Sessions with ${session.instructor.id !== props.userDetails.id ? session.instructor.firstName : session.user.firstName}`}</p>

                                                                </div>

                                                                <div className='col-7 p-0 d-flex'>

                                                                    <div className="row m-0 w-100 align-items-center">
                                                                        {

                                                                            session.offer.category ? (
                                                                                <div className='col-2'>
                                                                                    {session.offer.category.map((v, i) => <button className='mental' key={i}>{v}</button>)}
                                                                                </div>
                                                                            ) : <div className='col-2'>
                                                                            </div>
                                                                        }


                                                                        <div className='col-3'>
                                                                            <p className='day mb-0 ms-5'>{moment(session.startDate).utc().format('DD-MM-YYYY')}</p>
                                                                        </div>

                                                                        <div className='col-4'>
                                                                            {/* <p className='time mb-0 ms-5'>{`${session.timeSlot} - ${moment(session.timeSlot + session.offer.noOfHours >= 24 ? session.timeSlot + session.offer.noOfHours - 24 : session.timeSlot + session.offer.noOfHours, "H").format("h A")}`}</p> */}
                                                                            <p className='time mb-0 ms-5'>
                                                                                {session.timeSlot}:00 {session.timeSlot < 12 ? 'AM' : 'PM'} - {session.timeSlot + 1}:00 {(session.timeSlot + 1) < 12 ? 'AM' : 'PM'}
                                                                            </p>
                                                                        </div>
                                                                        <div className='col-2'>
                                                                            <div className='ms-5 cursor_pointer d-flex justify-content-center align-items-center' >
                                                                                <img className='' src={Chat} onClick={() =>
                                                                                    rediretToChatPage(session.userId)
                                                                                } alt="" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })) : <div style={{ textAlign: 'center' }} className='row m-0 session_bg pb-2 ps-4 pt-2'>
                                                    No Confirmed Session
                                                </div>
                                        }




                                        {/* Green */}


                                        {/* red */}
                                        <div className='row m-0 mt-4'>
                                            <div className='col-12 p-0'>
                                                <button className='red_btn session_btn'>REJECTED</button>
                                            </div>
                                        </div>
                                        {
                                            filteredSession.filter(session => session.status === 'rejected').length > 0 ?
                                                (filteredSession.map((session, i) => {
                                                    if (session.status === 'rejected') {
                                                        return (
                                                            <div className='row m-0 session_bg pb-2 ps-4 pt-2' key={i}>
                                                                <div className='col-5 p-0 d-flex justify-content-start align-items-center'>
                                                                    <img className='me-2' src={Red} />
                                                                    <p className='mb-0 session_detail ms-2'>{`Sessions with ${session.instructor.id !== props.userDetails.id ? session.instructor.firstName : session.user.firstName}`}</p>

                                                                </div>

                                                                <div className='col-7 p-0 d-flex'>
                                                                    <div className="row m-0 align-items-center w-100 align-items-center">
                                                                        {

                                                                            session.offer.category ? (
                                                                                <div className='col-2'>
                                                                                    {session.offer.category.map((v, i) => <button className='mental' key={i}>{v}</button>)}
                                                                                </div>
                                                                            ) : <div className='col-2'>
                                                                            </div>
                                                                        }


                                                                        <div className='col-3'>
                                                                            <p className='day mb-0 ms-5'>{moment(session.startDate).utc().format('DD-MM-YYYY')}</p>
                                                                        </div>

                                                                        <div className='col-4'>
                                                                            {/* <p className='time mb-0 ms-5'>{`${session.timeSlot} - ${moment(session.timeSlot + session.offer.noOfHours >= 24 ? session.timeSlot + session.offer.noOfHours - 24 : session.timeSlot + session.offer.noOfHours, "H").format("h A")}`}</p> */}

                                                                            <p className='time mb-0 ms-5'>
                                                                                {session.timeSlot}:00 {session.timeSlot < 12 ? 'AM' : 'PM'} - {session.timeSlot + 1}:00 {(session.timeSlot + 1) < 12 ? 'AM' : 'PM'}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })) : <div style={{ textAlign: 'center' }} className='row m-0 session_bg pb-2 ps-4 pt-2'>
                                                    No Rejected Session
                                                </div>
                                        }



                                        {/* red */}

                                        {/* orange-huddles */}

                                        <div className='row m-0 mt-4'>
                                            <div className='col-12 p-0'>
                                                <p className=' mb-0 session'>Events</p>
                                            </div>
                                        </div>

                                        <div className='row m-0 mt-2'>
                                            <div className='col-12 p-0'>
                                                <button className='orange_btn session_btn'>INVITATIONS</button>
                                            </div>
                                        </div>
                                        {
                                            filteredEvents.filter(event => event.status === 'invited').length > 0 ?
                                                (filteredEvents.map((value, index) => {
                                                    if (value.status === 'invited') {
                                                        return (
                                                            <div className='row m-0 session_bg pb-2 ps-4 pt-2' key={index}>
                                                                <div className='col-5 p-0 d-flex justify-content-start align-items-center'>
                                                                    <img className='me-2' src={Orange} />
                                                                    <p className='mb-0 session_detail ms-2'>{value.event.name}</p>
                                                                </div>

                                                                <div className='col-7 p-0 d-flex align-items-center'>

                                                                    <div className='row m-0 w-100 align-items-center'>

                                                                        <div className={`overflow_pills col-3 ${value.event.category.length === 1 ? "d-flex align-items-center" : ""}`}> {
                                                                            value.event.category.map((cat, i) => {
                                                                                return (
                                                                                    <div className='' key={i}>
                                                                                        <button className='mental'>{cat}</button>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        } </div>

                                                                        <div className='col-3'>
                                                                            <p className='day mb-0 ms-5'>{moment(value.event.startDate).utc().format('DD-MM-YYYY')}</p>
                                                                        </div>

                                                                        <div className='col-3'>
                                                                            <p className='time mb-0 ms-5'>10 - 11 AM</p>
                                                                        </div>

                                                                        <div className='col-3' >
                                                                            {
                                                                                moment(value.event.endDate)._d >= moment()._d ? (
                                                                                    <>
                                                                                        <img className='ms-5' src={Worng} onClick={() => updateEventInvite('not-attending', value)} />
                                                                                        <img className='ms-4' src={Right} onClick={() => updateEventInvite('attending', value)} />
                                                                                    </>
                                                                                ) : null
                                                                            }

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                })) : <div style={{ textAlign: 'center' }} className='row m-0 session_bg pb-2 ps-4 pt-2'>
                                                    No Invitation For Event
                                                </div>
                                        }

                                        {/* orange-huddles */}

                                        {/* Green-huddles */}

                                        <div className='row m-0 mt-4'>
                                            <div className='col-12 p-0'>
                                                <button className='green_btn session_btn'>GOING</button>
                                            </div>
                                        </div>

                                        {
                                            filteredEvents.filter(event => event.status === 'attending').length > 0 ?
                                                (filteredEvents.map((value, index) => {
                                                    if (value.status === 'attending') {
                                                        return (
                                                            <div className='row m-0 session_bg pb-2 ps-4 pt-2' key={index}>
                                                                <div className='col-5 p-0 d-flex justify-content-start align-items-center'>
                                                                    <img className='me-2' src={Green} />
                                                                    <p className='mb-0 session_detail ms-2 cursor_pointer' onClick={() => navigateToLink(`/event/${value.eventId}`)}>{value.event.name}</p>

                                                                </div>

                                                                <div className='col-7 p-0 d-flex align-items-center'>

                                                                    <div className="row w-100 m-0 align-items-center">
                                                                        <div className='overflow_pills col-3'> {
                                                                            value.event.category.map((cat, i) => {
                                                                                return (
                                                                                    <div className='' key={i}>
                                                                                        <button className='mental'>{cat}</button>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }</div>


                                                                        <div className='col-3'>
                                                                            <p className='day mb-0 ms-5'>{moment(value.event.startDate).utc().format('DD-MM-YYYY')}</p>
                                                                        </div>

                                                                        <div className='col-4'>
                                                                            <p className='time mb-0 ms-5'>{moment(value.event.startDate).utc().format('hh:mm a')} - {moment(value.event.endDate).utc().format('hh:mm a')}</p>
                                                                        </div>

                                                                        <div className='col-1'>
                                                                            {
                                                                                moment(value.event.endDate)._d >= moment()._d ? (
                                                                                    <div className='ms-5 vedio d-flex justify-content-center align-items-center' >
                                                                                        <img className='cursor_pointer' src={Video} onClick={() => window.open(`${value.event.link}`)} />
                                                                                    </div>
                                                                                ) : null
                                                                            }

                                                                        </div>

                                                                        {/* <div className='col-1' >
                                                                    <img className='ms-5 mb-0' config={Delete} />
                                                                </div> */}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })) : <div style={{ textAlign: 'center' }} className='row m-0 session_bg pb-2 ps-4 pt-2'>
                                                    No Going Event
                                                </div>
                                        }


                                        {/* Green-huddles */}

                                    </div>
                                </div>
                            </> :

                                <props.Calendar
                                    localizer={props.localizer}
                                    defaultDate={new Date()}
                                    defaultView="month"
                                    events={events}
                                    views={["month"]}
                                    navigate={["NEXT"]}
                                    style={{ height: "100vh" }}
                                    eventPropGetter={calendarStyle}
                                    onSelectEvent={(evt) => {
                                        if (evt.type === "event") {
                                            setShowEventModal(true);
                                            setCurrentEventDetails(evt)
                                        }
                                        else {
                                            setShowSessionModal(true)
                                            setCurrentSessionDetails(evt);
                                        }
                                    }}
                                    onShowMore={(events, date) => {
                                        setMoreEvents(events);
                                        setIsOpen(true);
                                    }}
                                    slotGroupPropGetter={5}
                                />

                            }

                        </div>
                    </div>

                </div>
            </div>

            {showEventModal ? <div class={`modal fade ${showEventModal ? 'show' : ''}`} style={{ display: showEventModal ? 'block' : 'none', background: showEventModal ? 'rgba(0,0,0,0.5)' : '' }} id="communityModals1" tabIndex="-1" aria-labelledby="communityModals1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered event_modal_community width_tab_view" role="document">
                    <div class="modal-content">
                        <div class="modal-header d-flex justify-content-end">
                            <img src={BackCross} alt="" className='cursor_pointer' onClick={() => setShowEventModal(false)} />
                        </div>
                        <div class="modal-body p-4">
                            <h5>{currentEventDetails.data.name}</h5>
                            <p>{moment(currentEventDetails.data.startDate).utc().format("ddd, MMM, DD")} - {moment(currentEventDetails.data.startDate).utc().format("hh:mm a")} - {moment(currentEventDetails.data.endDate).utc().format("hh:mm a")}</p>
                            <div className="row m-0 align-items-center">
                                <div className="col-1 p-0">
                                    <img src={comm_eve_1} width="20px" alt="" />
                                </div>
                                <div className="col-9 p-0">
                                    <p className="mb-0">Live in Kaleidoscope</p>
                                </div>
                                <div className="col-2 p-0 d-flex align-items-center justify-content-end">
                                    {currentEventDetails.status === "attending" ? <button className=" go_btn go_btn_blue" onClick={() => window.open(`${currentEventDetails.data.link}`, "_blank")}>Go</button> : <button className="go_btn">Go</button>}
                                </div>
                            </div>

                            <div className="row m-0 mt-3">
                                <div className="col-1 p-0">
                                    <img src={comm_eve_2} width="20px" alt="" />
                                </div>
                                <div className="col-9 p-0">
                                    <p className="mb-0">50 Attendees</p>
                                    <span>10 of your friends attending this huddle</span>
                                </div>
                            </div>

                            <div className="row m-0 mt-3">
                                <div className="col-1 p-0">
                                    <img src={comm_eve_3} width="20px" alt="" />
                                </div>
                                <div className="col-9 p-0">
                                    <p>Event by {currentEventDetails && currentEventDetails.data.createdByUser.firstName}</p>
                                </div>
                            </div>

                            {currentEventDetails.data.status === "invited" ? <div className="row m-0 require_approval_box">
                                <div className="col-6 p-0">
                                    <span>Requires your approval</span>
                                </div>
                                <div className="col-6 p-0 d-flex align-items-center justify-content-end">
                                    <p className="reject_text mb-0" onClick={() => updateEventInvite('not-attending', { eventId: currentEventDetails.data.id })}>Reject</p>
                                    <button className="accept_btn ms-3" onClick={() => updateEventInvite('attending', { eventId: currentEventDetails.data.id })}>Accept</button>
                                </div>
                            </div> : null}
                        </div>
                    </div>
                </div>
            </div> : null}

            {showSessionModal ? <div class={`modal fade ${showSessionModal ? 'show' : ''}`} style={{ display: showSessionModal ? 'block' : 'none', background: showSessionModal ? 'rgba(0,0,0,0.5)' : '' }} id="communityModals1" tabIndex="-1" aria-labelledby="communityModals1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered event_modal_community width_tab_view" role="document">
                    <div class="modal-content">
                        <div class="modal-header d-flex justify-content-end">
                            <img
                                src={BackCross}
                                alt=""
                                className='cursor_pointer'
                                onClick={() =>
                                    setShowSessionModal(false)
                                    // setShowEventModal(true)
                                } />
                        </div>
                        <div class="modal-body p-4 text-center text-lg-start">
                            <h5>{currentSessionDetails.title}</h5>
                            <p>{moment(currentSessionDetails.start).format('ddd, MMM, YYYY')} - {currentSessionDetails.data.timeSlot}:00 {currentSessionDetails.data.timeSlot < 12 ? 'AM' : 'PM'} to {currentSessionDetails.data.timeSlot + 1}:00 {(currentSessionDetails.data.timeSlot + 1) < 12 ? 'AM' : 'PM'}</p>
                            <div className="row m-0 align-items-center">
                                <div className="col-lg-1 col-5 text-end text-lg-start pe-lg-0 pe-2 p-0">
                                    <img className='' src={Chat} alt="" />
                                </div>
                                <div className="col-lg-9 col-6 p-0 d-flex align-items-center justify-content-start">
                                    {currentSessionDetails.status === "confirmed" ? <button onClick={() =>
                                        rediretToChatPage(currentSessionDetails.data.userId)
                                    } className=" go_btn go_btn_blue">Go to Chat</button> : <button className=" go_btn" disabled>Go to Chat</button>}
                                </div>
                            </div>

                            <div className="row m-0 mt-3">
                                <div className="col-lg-1 col-5 text-end text-lg-start pe-lg-0 pe-2 p-0">
                                    <img src={currentSessionDetails.data.user.profilePic ? currentSessionDetails.data.user.profilePic : comm_eve_2} style={{borderRadius: '50%'}}  width="20px" height="20px" alt="" />
                                </div>
                                <div className="col-lg-9 col-6 p-0 d-flex align-items-center justify-content-start">
                                    <p className="mb-0">{`${currentSessionDetails.data.user.firstName} ${currentSessionDetails.data.user.lastName}`}</p>
                                </div>
                            </div>

                            {
                                currentSessionDetails.status === 'pending' ? (
                                    <div className="row m-0 require_approval_box mt-4">
                                        <div className="col-6 p-0">
                                            <span>Requires your approval</span>
                                        </div>
                                        <div className="col-6 p-0 d-flex align-items-center justify-content-end">
                                            <p className="reject_text mb-0" onClick={() => handleRequest(currentSessionDetails.data, 'left')}>Reject</p>
                                            {
                                                currentSessionDetails.data.userId === props.userDetails.id ?
                                                    null : <button className="accept_btn ms-3" onClick={() => handleRequest(currentSessionDetails.data, 'right')}>Accept</button>
                                            }
                                        </div>
                                    </div>
                                ) : null
                            }

                        </div>
                    </div>
                </div>
            </div> : null}

            <div class={`modal fade ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none', background: isOpen ? 'rgba(0,0,0,0.5)' : '' }} id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content model_border_radius">

                        <div class="modal-body p-3 d-flex flex-column align-items-center justify-content-center" style={{ fontSize: '13px' }}>
                            <p className="model_bold_text text-center mb-4">More Session/Events</p>
                            {moreEvents.map((v, i) => {
                                return <>

                                    <div className="w-100 d-flex align-items-center mb-3 cursor_pointer" onClick={() => {
                                        setIsOpen(false);
                                        if (v.type === "event") {
                                            setCurrentEventDetails(v)
                                            setShowEventModal(true)
                                        }
                                        else {
                                            setCurrentSessionDetails(v);
                                            setShowSessionModal(true)
                                        }
                                    }}>
                                        <p className="mb-0 text-left" style={{ fontWeight: 600 }} >{v.title}</p>
                                        &nbsp;
                                        {v.type === "session" ? <p className="mb-0">with {v.data.user.firstName} {v.data.user.lastName}</p> :
                                            <p className="mb-0">created by {v.data.createdByUser.firstName} {v.data.createdByUser.lastName}</p>
                                        }
                                        &nbsp; - &nbsp;
                                        {v.type === "session" ? <p className="mb-0">{moment(v.start).format('ddd, MMM, YYYY')} - {v.data.timeSlot}:00 {v.data.timeSlot < 12 ? 'AM' : 'PM'} to {v.data.timeSlot + 1}:00 {(v.data.timeSlot + 1) < 12 ? 'AM' : 'PM'}</p> :
                                            <p className="mb-0">{moment(v.data.startDate).utc().format("ddd, MMM, DD")} - {moment(v.data.startDate).utc().format("hh:mm a")} - {moment(v.data.endDate).utc().format("hh:mm a")}</p>
                                        }
                                    </div>


                                </>
                            })}
                            <div className="col-lg-12 col-3 p-0 d-flex justify-content-center">
                                <button className="model_login_button mt-2" onClick={() => setIsOpen(false)}>Close</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default CommunityCoachesWeb;
