import {Modal, ModalBody, ModalHeader} from "reactstrap";
import React from "react";

/**
 * @param {{
 *   isOpen: boolean,
 *   onChanged: (string) => void,
 *   close: VoidFunction,
 *   active: string,
 *   options: {label: string, id: string}[]
 * }} props
 * @return {JSX.Element}
 * @constructor
 */
const FilterModal = (props) => {

  return (
    <Modal isOpen={props.isOpen} centered toggle={props.close}>
      <ModalHeader toggle={props.close} className={"align-items-start border-0 pb-0"}>
        Change Post Filter
      </ModalHeader>
      <ModalBody>
        <div
          className='d-flex align-items-center gap-lg-3 gap-1 justify-content-lg-start justify-content-between flex-wrap'>
          {props.options.map(button => {
            return (
              <button
                key={button.id}
                type={"button"}
                className={`lg:tw-text-sm tw-text-xs small px-lg-3 px-2 text-nowrap newsfeed_btn ${props.active === button.id ? "newsfeed_btn_active" : ""}`}
                onClick={() => props.onChanged(button.id)}>
                {button.label}
              </button>
            )
          })}
        </div>
      </ModalBody>
    </Modal>
  )
};

export default FilterModal;
