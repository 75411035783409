import React from 'react';
import '../../container/newsFeed/newsFeeds/newsFeeds.css';
import LoggedInLayout from "../../component/layout/LoggedInLayout.tsx";
import FeedsSubPage from "../../component/FeedsSubPage";

const NewsFeeds = () => {
  return (
    <>
      <LoggedInLayout title={"Feeds"}>
        <FeedsSubPage/>
      </LoggedInLayout>
    </>
  )
}

export default NewsFeeds;


