import {getTimeExPoints} from "../apiUrlsSetup";
// eslint-disable-next-line no-unused-vars
import useSWR, {SWRResponse} from "swr";

/**
 * @return {SWRResponse<GetTimeExPointsResponse, any, {revalidateIfStale: boolean, revalidateOnFocus: boolean, revalidateOnMount: boolean, revalidateOnReconnect: boolean}>}
 */
export default function useTimeExPoints() {
  return useSWR(
    "timeExPoints",
    async () => {
      const responseData = await getTimeExPoints();
      return responseData.data.data;
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false
    }
  )
}