import React from 'react';
import SideNav from '../../../component/staticPages/SideNav';

import EmotionsEmoji from "../../../assets/svg/EmotionsEmoji.svg"
import CommunityFilter from "../../../assets/svg/CommunityFilter.svg"
import CommunityBan from "../../../assets/svg/CommunityBan.svg"
import CommunityAddBtn from "../../../assets/svg/CommunityAddBtn.svg"

const manageHuddle = () => {
    return (
        <>
            <div className="row m-0 d-flex newsfeed_bg time_exchange_bg_full_height time_exe_web">
                <div className="news_left p-0">
                    <SideNav timeExchange={true} />
                </div>

                <div className="news_right time_exchange_auto_height p-5 position-relative">

                    {/* Red Strip */}

                    {/* <div className='row m-0 mb-4'>
                        <div className='col-12 p-0 ps-4 red_strip d-flex align-items-center'>
                            <p className='mb-0 red_strip_text'>You have deleted an event.</p>
                        </div>
                    </div> */}

                    {/* Red Strip */}

                    {/* Section-1 */}

                    {/* <div className="row m-0">
                        <div className="col-12 p-0">
                            <p className='mb-0 community_heading'>Experience</p>
                        </div>
                    </div>

                    <div className='row m-0 mt-3'>
                        <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                            <div className='community_huddle_slider_section d-flex align-items-center justify-content-between px-3'>
                                <button className='comm_btn_transparent act_comm_btn_transparent'>Happening now</button>
                                <button className='comm_btn_transparent'>On schedule</button>
                                <button className='comm_btn_transparent'>History</button>
                            </div>
                        </div>
                    </div>

                    <div className='row m-0'>
                        <div className='col-12 p-0 ps-2 py-3 comm_x_slider d-flex align-items-center'>
                            <button className='all_btn me-3'>All</button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img config={EmotionsEmoji} alt='' /> <p className='mb-0'>Emotions</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img config={EmotionsEmoji} alt='' /> <p className='mb-0'>Belonging</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img config={EmotionsEmoji} alt='' /> <p className='mb-0'>Breathing</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img config={EmotionsEmoji} alt='' /> <p className='mb-0'>Moving</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img config={EmotionsEmoji} alt='' /> <p className='mb-0'>Working</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img config={EmotionsEmoji} alt='' /> <p className='mb-0'>Learning</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img config={EmotionsEmoji} alt='' /> <p className='mb-0'>Noticing</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img config={EmotionsEmoji} alt='' /> <p className='mb-0'>Meaning</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img config={EmotionsEmoji} alt='' /> <p className='mb-0'>Emotions</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img config={EmotionsEmoji} alt='' /> <p className='mb-0'>Emotions</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img config={EmotionsEmoji} alt='' /> <p className='mb-0'>Emotions</p> </button>
                        </div>
                    </div>

                    <div className='row m-0'>
                        <div className='col-12 p-0'>

                            <div className='community_super_admin_tbl d-flex align-items-center'>
                                <div className='tbl_head tbl_head_1 ps-4 py-3'>Event Name <img className='ms-2' config={CommunityFilter} alt='' /> </div>
                                <div className='tbl_head tbl_head_2 ps-4 py-3'>Hosted by <img className='ms-2' config={CommunityFilter} alt='' /> </div>
                                <div className='tbl_head tbl_head_3 ps-4 py-3'>Date <img className='ms-2' config={CommunityFilter} alt='' /> </div>
                                <div className='tbl_head tbl_head_4 ps-4 py-3'>Participants <img className='ms-2' config={CommunityFilter} alt='' /> </div>
                                <div className='tbl_head tbl_head_5 ps-4 py-3'>Category <img className='ms-2' config={CommunityFilter} alt='' /> </div>
                            </div>
                            
                            <div className='community_super_admin_tbl d-flex align-items-center'>
                                <div className='tbl_head tbl_head_1 ps-4 event_nm py-3'>Mental Health with Executives</div>
                                <div className='tbl_head tbl_head_2 ps-4 event_hst_by py-3'>Kaleidoscope Team</div>
                                <div className='tbl_head tbl_head_3 ps-4 event_dt py-3'>Sep 10, 2021</div>
                                <div className='tbl_head tbl_head_4 ps-4 event_prtc py-3'>67 participants</div>
                                <div className='tbl_head tbl_head_5 ps-4 py-3'> <button className='event_cat'>Belonging</button> </div>
                            </div>

                            <div className='community_super_admin_tbl d-flex align-items-center'>
                                <div className='tbl_head tbl_head_1 ps-4 event_nm py-3'>Mental Health with Executives</div>
                                <div className='tbl_head tbl_head_2 ps-4 event_hst_by py-3'>Kaleidoscope Team</div>
                                <div className='tbl_head tbl_head_3 ps-4 event_dt py-3'>Sep 10, 2021</div>
                                <div className='tbl_head tbl_head_4 ps-4 event_prtc py-3'>67 participants</div>
                                <div className='tbl_head tbl_head_5 ps-4 py-3'> <button className='event_cat'>Belonging</button> </div>
                            </div>

                            <div className='community_super_admin_tbl d-flex align-items-center'>
                                <div className='tbl_head tbl_head_1 ps-4 event_nm py-3'>Mental Health with Executives</div>
                                <div className='tbl_head tbl_head_2 ps-4 event_hst_by py-3'>Kaleidoscope Team</div>
                                <div className='tbl_head tbl_head_3 ps-4 event_dt py-3'>Sep 10, 2021</div>
                                <div className='tbl_head tbl_head_4 ps-4 event_prtc py-3'>67 participants</div>
                                <div className='tbl_head tbl_head_5 ps-4 py-3'> <button className='event_cat'>Belonging</button> </div>
                            </div>

                        </div>
                    </div> 
                    
                    <div className='row m-0'>
                        <div className='col-12 p-0 pe-4 d-flex align-items-center justify-content-end position-absolute bottom-0 end-0'>

                            <img config={CommunityAddBtn} alt='' />

                        </div>
                    </div> */}

                    {/* Section-1 */}

                    <div className="row m-0">
                        <div className="col-12 p-0">
                            <p className='mb-0 community_heading'>Experience</p>
                        </div>
                    </div>

                    <div className='row m-0 mt-3'>
                        <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                            <div className='community_huddle_slider_section d-flex align-items-center justify-content-between px-3'>
                                <button className='comm_btn_transparent'>Happening now</button>
                                <button className='comm_btn_transparent act_comm_btn_transparent'>On schedule</button>
                                <button className='comm_btn_transparent'>History</button>
                            </div>
                        </div>
                    </div>

                    <div className='row m-0'>
                        <div className='col-12 p-0 ps-2 py-3 comm_x_slider d-flex align-items-center'>
                            <button className='all_btn me-3'>All</button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img src={EmotionsEmoji} alt='' /> <p className='mb-0'>Emotions</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img src={EmotionsEmoji} alt='' /> <p className='mb-0'>Belonging</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img src={EmotionsEmoji} alt='' /> <p className='mb-0'>Breathing</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img src={EmotionsEmoji} alt='' /> <p className='mb-0'>Moving</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img src={EmotionsEmoji} alt='' /> <p className='mb-0'>Working</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img src={EmotionsEmoji} alt='' /> <p className='mb-0'>Learning</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img src={EmotionsEmoji} alt='' /> <p className='mb-0'>Noticing</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img src={EmotionsEmoji} alt='' /> <p className='mb-0'>Meaning</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img src={EmotionsEmoji} alt='' /> <p className='mb-0'>Emotions</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img src={EmotionsEmoji} alt='' /> <p className='mb-0'>Emotions</p> </button>
                            <button className='emoji_btns me-3 d-flex align-items-center justify-content-between px-3'> <img src={EmotionsEmoji} alt='' /> <p className='mb-0'>Emotions</p> </button>
                        </div>
                    </div>

                    <div className='row m-0'>
                        <div className='col-12 p-0'>

                            <div className='community_super_admin_tbl d-flex align-items-center'>
                                <div className='tbl_head tbl_head_11 ps-4 py-3'>Event Name <img className='ms-2' src={CommunityFilter} alt='' /> </div>
                                <div className='tbl_head tbl_head_22 ps-4 py-3'>Hosted by <img className='ms-2' src={CommunityFilter} alt='' /> </div>
                                <div className='tbl_head tbl_head_33 ps-4 py-3'>Date <img className='ms-2' src={CommunityFilter} alt='' /> </div>
                                <div className='tbl_head tbl_head_44 ps-4 py-3'>Participants <img className='ms-2' src={CommunityFilter} alt='' /> </div>
                                <div className='tbl_head tbl_head_55 ps-4 py-3'>Category <img className='ms-2' src={CommunityFilter} alt='' /> </div>
                                <div className='tbl_head tbl_head_66 ps-4 py-3'>Action</div>
                            </div>

                            <div className='community_super_admin_tbl d-flex align-items-center'>
                                <div className='tbl_head tbl_head_11 ps-4 event_nm py-3'>Mental Health with Executives</div>
                                <div className='tbl_head tbl_head_22 ps-4 event_hst_by py-3'>Kaleidoscope Team</div>
                                <div className='tbl_head tbl_head_33 ps-4 event_dt py-3'>Sep 10, 2021</div>
                                <div className='tbl_head tbl_head_44 ps-4 event_prtc py-3'>67 participants</div>
                                <div className='tbl_head tbl_head_55 ps-4 py-3'> <button className='event_cat'>Belonging</button> </div>
                                <div className='tbl_head tbl_head_66 ps-4 py-3'> <img data-bs-toggle="modal" data-bs-target="#banModal" className='ms-2' src={CommunityBan} alt='' /> </div>
                            </div>

                            <div className='community_super_admin_tbl d-flex align-items-center'>
                                <div className='tbl_head tbl_head_11 ps-4 event_nm py-3'>Mental Health with Executives</div>
                                <div className='tbl_head tbl_head_22 ps-4 event_hst_by py-3'>Kaleidoscope Team</div>
                                <div className='tbl_head tbl_head_33 ps-4 event_dt py-3'>Sep 10, 2021</div>
                                <div className='tbl_head tbl_head_44 ps-4 event_prtc py-3'>67 participants</div>
                                <div className='tbl_head tbl_head_55 ps-4 py-3'> <button className='event_cat'>Belonging</button> </div>
                                <div className='tbl_head tbl_head_66 ps-4 py-3'> <img className='ms-2' src={CommunityBan} alt='' /> </div>
                            </div>

                            <div className='community_super_admin_tbl d-flex align-items-center'>
                                <div className='tbl_head tbl_head_11 ps-4 event_nm py-3'>Mental Health with Executives</div>
                                <div className='tbl_head tbl_head_22 ps-4 event_hst_by py-3'>Kaleidoscope Team</div>
                                <div className='tbl_head tbl_head_33 ps-4 event_dt py-3'>Sep 10, 2021</div>
                                <div className='tbl_head tbl_head_44 ps-4 event_prtc py-3'>67 participants</div>
                                <div className='tbl_head tbl_head_55 ps-4 py-3'> <button className='event_cat'>Belonging</button> </div>
                                <div className='tbl_head tbl_head_66 ps-4 py-3'> <img className='ms-2' src={CommunityBan} alt='' /> </div>
                            </div>

                        </div>
                    </div>

                    <div className='row m-0'>
                        <div className='col-12 p-0 pe-4 d-flex align-items-center justify-content-end position-absolute bottom-0 end-0'>

                            <img src={CommunityAddBtn} alt='' />

                        </div>
                    </div>

                </div>
            </div>

            {/* Modal */}

            <div class="modal fade" id="banModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body p-3">

                            <div className='row m-0'>
                                <div className='col-12 p-0'>
                                    <p className='mb-0 modal_heading text-center'>Are you sure?</p>
                                    <p className='mb-0 mt-2 modal_info_text'>Your action will delete all of this event detail and cannot be undone.</p>
                                </div>
                            </div>

                            <div className='row m-0 mt-3'>
                                <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                    <button className='me-2 red_cancel'>Cancel</button>
                                    <button className='ms-2 red_yes' data-bs-dismiss="modal">Yes</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
        </>
    )
}

export default manageHuddle;
