import React from 'react';
import '../../css/leaderboard.css'
import SideNav from '../../component/staticPages/SideNav';


import TimeExchangePocket from "../../assets/svg/TimeExchangePocket.svg";
import TimeExchangeEmoji from "../../assets/svg/TimeExchangeEmoji.svg";
import LeaderBoardBanner1_img from "../../assets/LeaderBoardBanner1_img.png";
import LeaderBoardBanner2_img from "../../assets/LeaderBoardBanner2_img.png";
import LeaderBoardBanner3_img from "../../assets/LeaderBoardBanner3_img.png";
import Relationships from '../../assets/Heart.png';
import Physical from '../../assets/Physical.png';
import Mental from '../../assets/Mental.png';
import Dimond from "../../assets/svg/Dimond.svg";

const Leaderboards = () => {
    return (
        <>
            <div className='row m-0 d-flex newsfeed_bg '>

                <div className='news_left p-0'>
                    <SideNav Journey={true} />
                </div>

                <div className='news_right p-5'>
                    <div className='row m-0'>
                        <div className='col-12 p-0'>

                            <div className='row m-0'>

                                <div className='col-8 p-0'>
                                    <p className='mb-0 notification_heading'>Leaderboards</p>
                                    <p className='mb-0 mt-3 journey_bredcrum '>Journey > Your Quest > <span className='quest_border'> Leaderboards</span></p>

                                </div>
                                <div className='col-4 p-0 d-flex align-items-center justify-content-end'>
                                    <p className='mb-0 time_exchange_right_head text-right'> <img className='mb-1' src={TimeExchangePocket} alt=' ' /> 36H &nbsp; <span className='deivder_time_ex'>|</span> &nbsp; <img className='mb-1' src={TimeExchangeEmoji} alt=' ' /> 2400 WBX</p>
                                </div>

                            </div>


                            <div className='row m-0 mt-3  d-flex justify-content-between'>

                                <div className='col-8 p-0'>

                                    <div className='row m-0'>
                                        <div className='col-12 p-0 ps-4 pe-4 pt-3 pb-3 leaderboard_time '>
                                            <div className='row m-0'>
                                                <div className='col-4 p-0 d-flex align-items-center justify-content-start'>
                                                    <p className='mb-0 leaderboard_time_day leaderboard_active_day'>Today</p>

                                                </div>
                                                <div className='col-4 p-0  d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 leaderboard_time_day'>This Week</p>

                                                </div>
                                                <div className='col-4 p-0  d-flex align-items-center justify-content-end'>
                                                    <p className='mb-0 leaderboard_time_day'>All Time</p>

                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                    <div className='row m-0 mt-3'>
                                        <div className='col-12 p-0  d-flex align-items-center justify-content-center mt-4 ps-4 pe-4'>

                                            <div className='banner_box banner_box_one d-flex flex-column align-items-center justify-content-start me-3 pt-4'>

                                                <div className='banner_img_box_grey d-flex align-items-center justify-content-center'>
                                                    <img className='banner_box_img' src={LeaderBoardBanner1_img} alt='' />
                                                </div>

                                                <p className='mb-0 banner_position mt-1'>2ND</p>

                                                <div className='banner_person d-flex flex-column align-items-center justify-content-center mt-1'>
                                                    <p className='mb-0 banner_person_name'>Denise</p>
                                                    <p className='mb-0 banner_person_name'>1740 WB</p>

                                                </div>

                                            </div>

                                            <div className='banner_box banner_box_two d-flex flex-column align-items-center justify-content-start me-3 ms-3'>

                                                <div className='banner_img_box_yellow d-flex align-items-center justify-content-center'>
                                                    <img className='banner_box_img' src={LeaderBoardBanner2_img} alt='' />
                                                </div>

                                                <p className='mb-0 banner_position mt-3'>CHAMPION</p>

                                                <div className='banner_person d-flex flex-column align-items-center justify-content-center mt-3'>
                                                    <p className='mb-0 banner_person_name'>Theresa</p>
                                                    <p className='mb-0 banner_person_name'>2340 WB</p>

                                                </div>

                                            </div>


                                            <div className='banner_box banner_box_three  d-flex flex-column align-items-center justify-content-start ms-3 pt-4'>

                                                <div className='banner_img_box_orange  d-flex align-items-center justify-content-center'>
                                                    <img className='banner_box_img' src={LeaderBoardBanner3_img} alt='' />
                                                </div>
                                                <p className='mb-0 banner_position mt-1'>3RD</p>

                                                <div className='banner_person d-flex flex-column align-items-center justify-content-center mt-1'>
                                                    <p className='mb-0 banner_person_name'>Bill</p>
                                                    <p className='mb-0 banner_person_name'>1740 WB</p>

                                                </div>


                                            </div>

                                        </div>

                                    </div>

                                    <div className='row m-0'>
                                        <div className='col-12 p-0 leaderboard_details p-3'>


                                            <div className='row m-0'>
                                                <div className='col-12 p-0'>

                                                    <div className='row m-0 p-1'>
                                                        <div className='col-1 p-0'>

                                                        </div>

                                                        <div className='col-7 p-0  d-flex  align-items-center justify-content-start ps-2'>
                                                            <p className='mb-0 leaderboard_details_box_name leaderboard_details_box_deactive'>Name</p>
                                                        </div>

                                                        <div className='col-2 p-0  d-flex  align-items-center justify-content-center'>
                                                            <p className='mb-0 leaderboard_details_box_place leaderboard_details_box_deactive'>Place</p>
                                                        </div>

                                                        <div className='col-2 p-0  d-flex  align-items-center justify-content-end'>
                                                            <p className='mb-0 leaderboard_details_box_point leaderboard_details_box_deactive'>Point</p>
                                                        </div>

                                                    </div>


                                                    <div className='row m-0 btm_border p-1 mt-1'>
                                                        <div className='col-1 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                            <div className='leaderboard_details_img_box d-flex align-items-center justify-content-center'>
                                                                <img className='leaderboard_details_img' src={LeaderBoardBanner3_img} alt='' />
                                                            </div>

                                                        </div>

                                                        <div className='col-7 p-0  d-flex  align-items-center justify-content-start ps-2'>
                                                            <p className='mb-0 leaderboard_details_box_name'>Jason Lee</p>
                                                        </div>

                                                        <div className='col-2 p-0  d-flex  align-items-center justify-content-center'>
                                                            <p className='mb-0 leaderboard_details_box_place'>4th</p>
                                                        </div>

                                                        <div className='col-2 p-0  d-flex  align-items-center justify-content-end'>
                                                            <p className='mb-0 leaderboard_details_box_point'>1240 WB</p>
                                                        </div>

                                                    </div>


                                                    <div className='row m-0 btm_border mt-1  p-1'>
                                                        <div className='col-1 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                            <div className='leaderboard_details_img_box d-flex align-items-center justify-content-center'>
                                                                <img className='leaderboard_details_img' src={LeaderBoardBanner3_img} alt='' />
                                                            </div>

                                                        </div>

                                                        <div className='col-7 p-0  d-flex  align-items-center justify-content-start ps-2'>
                                                            <p className='mb-0 leaderboard_details_box_name'>Jason Lee</p>
                                                        </div>

                                                        <div className='col-2 p-0  d-flex  align-items-center justify-content-center'>
                                                            <p className='mb-0 leaderboard_details_box_place'>4th</p>
                                                        </div>

                                                        <div className='col-2 p-0  d-flex  align-items-center justify-content-end'>
                                                            <p className='mb-0 leaderboard_details_box_point'>1240 WB</p>
                                                        </div>

                                                    </div>


                                                    <div className='row m-0 btm_border mt-1  p-1'>
                                                        <div className='col-1 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                            <div className='leaderboard_details_img_box d-flex align-items-center justify-content-center'>
                                                                <img className='leaderboard_details_img' src={LeaderBoardBanner3_img} alt='' />
                                                            </div>

                                                        </div>

                                                        <div className='col-7 p-0  d-flex  align-items-center justify-content-start ps-2'>
                                                            <p className='mb-0 leaderboard_details_box_name'>Jason Lee</p>
                                                        </div>

                                                        <div className='col-2 p-0  d-flex  align-items-center justify-content-center'>
                                                            <p className='mb-0 leaderboard_details_box_place'>4th</p>
                                                        </div>

                                                        <div className='col-2 p-0  d-flex  align-items-center justify-content-end'>
                                                            <p className='mb-0 leaderboard_details_box_point'>1240 WB</p>
                                                        </div>

                                                    </div>


                                                    <div className='row m-0 btm_border mt-1 p-1'>
                                                        <div className='col-1 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                            <div className='leaderboard_details_img_box d-flex align-items-center justify-content-center'>
                                                                <img className='leaderboard_details_img' src={LeaderBoardBanner3_img} alt='' />
                                                            </div>

                                                        </div>

                                                        <div className='col-7 p-0  d-flex  align-items-center justify-content-start ps-2'>
                                                            <p className='mb-0 leaderboard_details_box_name'>Jason Lee</p>
                                                        </div>

                                                        <div className='col-2 p-0  d-flex  align-items-center justify-content-center'>
                                                            <p className='mb-0 leaderboard_details_box_place'>4th</p>
                                                        </div>

                                                        <div className='col-2 p-0  d-flex  align-items-center justify-content-end'>
                                                            <p className='mb-0 leaderboard_details_box_point'>1240 WB</p>
                                                        </div>

                                                    </div>


                                                </div>
                                            </div>


                                        </div>
                                    </div>


                                </div>


                                <div className='col-4 p-0 statistics'>

                                    <div className='row m-0'>
                                        <div className='col-12 pe-4 ps-3 pt-3 pb-3'>


                                            <div className='row m-0 mt-3'>
                                                <div className='col p-0'>
                                                    <p className='mb-0 your_statistcs'>Your Statistics</p>
                                                </div>
                                            </div>

                                            <div className='row m-0 mt-4'>
                                                <div className='col p-0'>
                                                    <p className='mb-0 leaderboard_points_heading'>Well-Being Points</p>
                                                    <p className='mb-0 leaderboard_points mt-2'>153,200 &nbsp;<img src={Dimond} alt='' /></p>
                                                </div>
                                            </div>

                                            <div className='row m-0  mt-4'>
                                                <div className='col p-0'>
                                                    <p className='mb-0 leaderboard_points_heading'>Total Hours Available</p>
                                                    <p className='mb-0 leaderboard_points mt-2'>200H</p>
                                                </div>
                                            </div>

                                            <div className='row m-0  mt-4'>
                                                <div className='col p-0'>
                                                    <p className='mb-0 leaderboard_points_heading'>Total Quests Completed</p>
                                                    <p className='mb-0 leaderboard_points mt-2'>20</p>
                                                </div>
                                            </div>

                                            <div className='row m-0  mt-4'>
                                                <div className='col p-0'>
                                                    <p className='mb-0 leaderboard_points_heading'>Percentage Completion (Journey)</p>
                                                    <p className='mb-0 leaderboard_points mt-2'>24%</p>
                                                </div>
                                            </div>

                                            <div className='row m-0  mt-4'>
                                                <div className='col p-0'>
                                                    <p className='mb-0 your_statistcs'>Levels</p>
                                                </div>
                                            </div>

                                            <div className='row m-0  mt-5'>
                                                <div className='col-12 p-0 statistcs_level pt-3 pb-3 ps-2 pe-2'>

                                                    <div className='row m-0'>

                                                        <div className='col-3 p-0  d-flex align-items-center justify-content-center'>

                                                            <div className='statistcs_img  d-flex align-items-center justify-content-center'>
                                                                <img src={Physical} alt='' />
                                                            </div>

                                                        </div>

                                                        <div className='col-9 p-0'>
                                                            <div className='row m-0'>

                                                                <div className='col-9 p-0'>
                                                                    <p className='mb-0 statistcs_level_heading'>Physical</p>
                                                                </div>

                                                                <div className='col-3 p-0'>
                                                                    <p className='mb-0 statistcs_level_heading'>Lv.12</p>
                                                                </div>
                                                            </div>

                                                            <div className='row m-0'>
                                                                <div className='col p-0'>
                                                                    <p className='mb-0 statistcs_level_pera'>12000 xp / 32000 xp</p>

                                                                </div>

                                                            </div>

                                                            <div className='row m-0 mt-2'>
                                                                <div className='col p-0'>

                                                                    <div class="progress statistcs_progress">
                                                                        <div class="progress-bar  statistcs_progress_bar " role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>


                                            <div className='row m-0  mt-4'>
                                                <div className='col-12 p-0 statistcs_level pt-3 pb-3 ps-2 pe-2'>

                                                    <div className='row m-0'>

                                                        <div className='col-3 p-0  d-flex align-items-center justify-content-center'>

                                                            <div className='statistcs_img  d-flex align-items-center justify-content-center'>
                                                                <img src={Relationships} alt='' />
                                                            </div>

                                                        </div>

                                                        <div className='col-9 p-0'>
                                                            <div className='row m-0'>

                                                                <div className='col-9 p-0'>
                                                                    <p className='mb-0 statistcs_level_heading'>Relationships</p>
                                                                </div>

                                                                <div className='col-3 p-0'>
                                                                    <p className='mb-0 statistcs_level_heading'>Lv.12</p>
                                                                </div>
                                                            </div>

                                                            <div className='row m-0'>
                                                                <div className='col p-0'>
                                                                    <p className='mb-0 statistcs_level_pera'>12000 xp / 32000 xp</p>

                                                                </div>

                                                            </div>

                                                            <div className='row m-0 mt-2'>
                                                                <div className='col p-0'>

                                                                    <div class="progress statistcs_progress">
                                                                        <div class="progress-bar  statistcs_progress_bar " role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                            <div className='row m-0  mt-4'>
                                                <div className='col-12 p-0 statistcs_level pt-3 pb-3 ps-2 pe-2'>

                                                    <div className='row m-0'>

                                                        <div className='col-3 p-0  d-flex align-items-center justify-content-center'>

                                                            <div className='statistcs_img  d-flex align-items-center justify-content-center'>
                                                                <img src={Mental} alt='' />
                                                            </div>

                                                        </div>

                                                        <div className='col-9 p-0'>
                                                            <div className='row m-0'>

                                                                <div className='col-9 p-0'>
                                                                    <p className='mb-0 statistcs_level_heading'>Mental</p>
                                                                </div>

                                                                <div className='col-3 p-0'>
                                                                    <p className='mb-0 statistcs_level_heading'>Lv.12</p>
                                                                </div>
                                                            </div>

                                                            <div className='row m-0'>
                                                                <div className='col p-0'>
                                                                    <p className='mb-0 statistcs_level_pera'>12000 xp / 32000 xp</p>

                                                                </div>

                                                            </div>

                                                            <div className='row m-0 mt-2'>
                                                                <div className='col p-0'>

                                                                    <div class="progress statistcs_progress">
                                                                        <div class="progress-bar  statistcs_progress_bar " role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Leaderboards;
