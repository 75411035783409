import React, { useState } from 'react';
import Back from "../../../assets/svg/Back.svg";
import Like from "../../../assets/svg/EventLike_one.svg";
import Comment from "../../../assets/svg/EventComment_one.svg";
import Share from "../../../assets/svg/EventShare_one.svg";
import Dot from "../../../assets/svg/DividerDot.svg";
import Photo_Video from "../../../assets/svg/PhotoVideo.svg";
import Close from "../../../assets/svg/Close.svg";
import NewsFeed_lg_Img from "../../../assets/NewsFeed_lg_Img.png";
import Crossbtn from '../../../assets/Crossbtn.png';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { PostClipboard } from "../../../utils/plainClipboard";

Quill.register("modules/clipboard", PostClipboard, true);


const NewsfeedTab = (props) => {

    const [comment, setComment] = useState("");
    const [subComment, setSubComment] = useState("");

    const navigate = useNavigate();

    return (
        <>
            <div className='row m-0'>
                <div className='col-12 p-0 newsfeed_tab_screen'>

                    <div className='row m-0 responsive_nav_bottom_border'>
                        <div className='col-12 p-0 ps-4 pe-4'>

                            <div className='row m-0 pt-3 pb-3 pt-md-4 pb-md-4'>

                                <div className='col-1 p-0 d-flex align-items-center'>
                                    <img className='responsive_nav_logo' src={Back} alt='' onClick={() => {
                                        navigate('/communitytab/feeling')
                                    }} />
                                </div>
                                <div className='col-4 p-0 d-flex align-items-start'>
                                    <p className='mb-0 alt_nav_heading'>Detail Event</p>
                                </div>

                            </div>

                        </div>
                    </div>


                    <div className='row m-0 mobile_post px-4'>
                        <div className='col-12 p-0'>

                            <div className="col-12 p-0 mt-3">
                                <p className="mb-0 newsfeed_event_heading_tab">
                                    {props.eventDetails.name}
                                </p>
                                <p className="mb-0 mt-3 newsfeed_event_info_tab">
                                    Live Event By{" "}
                                    <span className="cursor_pointer" onClick={() => navigate(`/friends/${props.eventDetails.createdByUser.id}`)}>
                                        {props.eventDetails.createdByUser.firstName} {props.eventDetails.createdByUser.lastName}
                                    </span>
                                </p>
                            </div>

                            <div className='row m-0 mt-3'>

                                <div className='col-7 p-0'>
                                    <div className='newwsfeed_tab_imgdiv'>
                                        <img className='newwsfeed_tab_img' src={
                                            props.eventDetails.image
                                                ? props.eventDetails.image
                                                : NewsFeed_lg_Img
                                        } alt='' />
                                    </div>
                                </div>


                                <div className='col-5 p-0 px-3'>

                                    <div className='row m-0'>
                                        <div className='col-12 p-0'>

                                            <p className='mb-0 newsfeed_tab_date'>Date and Time</p>
                                            {moment(props.eventDetails.startDate).format("D MM") === moment(props.eventDetails.endDate).format("D MM") ? <>


                                                <p className="mb-0 evt_date mt-2">
                                                    {moment(props.eventDetails.startDate).utc().format("D MMM YYYY")},{" "}
                                                    {moment(props.eventDetails.startDate).utc().format("dddd")}
                                                </p>
                                                <p className="mb-0 evt_time mt-1">
                                                    {moment(props.eventDetails.startDate).utc().format("hh:mm a")}
                                                    &nbsp;-&nbsp;
                                                    {moment(props.eventDetails.endDate).utc().format("hh:mm a")}
                                                </p>

                                            </> : <>

                                                <p className="mb-0 evt_date mt-2">
                                                    {moment(props.eventDetails.startDate).utc().format("D MMM YYYY")},{" "}
                                                    {moment(props.eventDetails.startDate).utc().format("dddd")}
                                                </p>
                                                <p className="mb-0 evt_time mt-1">To</p>
                                                {/* <p className='mb-0 evt_time mt-1'>8:00 PM - 9:30PM</p> */}
                                                <p className="mb-0 evt_time mt-1">
                                                    {moment(props.eventDetails.endDate).utc().format("D MMM YYYY")},{" "}
                                                    {moment(props.eventDetails.endDate).utc().format("dddd")}
                                                </p>
                                                <p className="mb-0 evt_time mt-1">
                                                    {moment(props.eventDetails.startDate).utc().format("hh:mm a")}
                                                    &nbsp;-&nbsp;
                                                    {moment(props.eventDetails.endDate).utc().format("hh:mm a")}
                                                </p>

                                            </>}

                                            <p className="mb-0 evt_type mt-4">Free</p>
                                            {props.userDetails.id !== props.eventDetails.createdByUserId && props.eventDetails.participants && !props.eventDetails.participants.map(v => v.userId).includes(props.userDetails.id) && <button className="evt_reg_btn mt-4" onClick={props.handleShow}>
                                                Register
                                            </button>}

                                            <p className="mb-0 evt_total_count_tab mt-4">
                                                {props.eventDetails.participants && props.eventDetails.participants.length} people have registered,
                                                <br /> including {props.eventDetails.participants && props.eventDetails.participants.length > 0 ? props.friendsList.length : 0} of your friends
                                            </p>

                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className='row m-0 mt-3'>
                                <div className='col-12 p-0'>
                                    <p className='mb-0 about_evt_tab'>About the event</p>
                                </div>
                            </div>

                            <div className='row m-0 mt-3'>
                                <div className='col-12 p-0'>
                                    <p className='mb-0 about_evt_info_tab'>{props.eventDetails.description}</p>
                                </div>
                            </div>


                            <div className='row m-0 mt-3'>
                                <div className='col-3 p-0 d-flex align-items-center'>
                                    <div className="evt_svg_circle d-flex align-items-center justify-content-center">
                                        <img
                                            src={Like}
                                            alt=""
                                            className="cursor_pointer"
                                            onClick={() => props.handleLike(props.eventDetails.id)}
                                        />
                                    </div>
                                    <p className="mb-0 evt_svg_circle_text active_evt_svg_circle_text ps-2">
                                        Liked &nbsp; <img src={Dot} alt="" /> &nbsp;{" "}
                                        <span>{props.eventDetails.reactionCount} Likes</span>
                                    </p>
                                </div>
                                <div className='col-4 p-0 d-flex align-items-center'>
                                    <div className="evt_svg_circle d-flex align-items-center justify-content-center">
                                        <img
                                            src={Comment}
                                            alt=""
                                            className="cursor_pointer"
                                            onClick={() => props.showCommentBox(true)}
                                        />
                                    </div>
                                    <p className="mb-0 ms-1 evt_svg_circle_text">
                                        Comment &nbsp; <img src={Dot} alt="" /> &nbsp;{" "}
                                        <span>{props.eventDetails.commentCount} Comments</span>
                                    </p>
                                </div>
                                <div className='col-4 p-0 d-flex align-items-center' onClick={() => props.setIsShowInviteFrndModal(true)}>
                                    <div className="evt_svg_circle d-flex align-items-center justify-content-center">
                                        <img src={Share} alt="" />
                                    </div>
                                    <p className="mb-0 ms-2 evt_svg_circle_text">Share</p>
                                </div>
                            </div>

                            {props.commentBox && (
                                <>
                                    <div className="row m-0 my-4">
                                        <div className="col-12 p-0 d-flex align-items-center">
                                            <div className="input_profile_img">
                                                <img src={props.userDetails.profilePic ? props.userDetails.profilePic : "/static/png/Male.png"} />
                                            </div>

                                            {/* <div class="input-group">
                                                <input
                                                    style={{ width: "85%" }}
                                                    type="text"
                                                    className="comment_input ms-3 ps-4 pb-1"
                                                    placeholder="Add a comment..."
                                                    value={props.comment}
                                                    onChange={(event) => {
                                                        props.setComment(event.target.value);
                                                    }}
                                                    onKeyDown={(event) => {
                                                        if (event.key === "Enter") {
                                                            props.submitComment(props.eventDetails, event.target.value);
                                                        }
                                                    }}
                                                />

                                                <span
                                                    className="input-group-text comment_input_capture d-flex align-items-center justify-content-around"
                                                    id="basic-addon1"
                                                >

                                                    <input type='file' id="file-input" className="d-none" multiple onChange={(e) => {
                                                        // submitComment("image", eventDetails, e.target.files[0]);
                                                        props.saveCommentImage(e.target.files[0]);
                                                    }} />
                                                    <label for="file-input">
                                                        <img className="ms-3" config={Photo_Video} />
                                                    </label>
                                                </span>
                                            </div> */}

                                            <div className="input-group input-group-post">
                                                <ReactQuill placeholder="Add a comment..." modules={{
                                                    toolbar: false,
                                                    clipboard: {
                                                        // toggle to add extra line breaks when pasting HTML:
                                                        matchVisual: false,
                                                    }
                                                }} theme="snow" value={comment} onChange={(event) => {
                                                    props.setShowImageComment(true);
                                                    setComment(event);
                                                    props.setComment(event);
                                                }} />

                                                <span
                                                    className="input-group-text comment_input_capture d-flex align-items-center justify-content-around"
                                                    id="basic-addon1"
                                                >

                                                    <input type='file' id="file-input" className="d-none" multiple onChange={(e) => {
                                                        props.saveCommentImage(e.target.files[0]);
                                                        props.setShowImageComment(true)
                                                        if (e.target.files[0]) {
                                                            const file = URL.createObjectURL(e.target.files[0]);
                                                            props.setImageUrl(file);
                                                        }
                                                        e.target.value = null;
                                                    }} />
                                                    <label for="file-input">
                                                        <img className="ms-3" src={Photo_Video} />
                                                    </label>
                                                </span>
                                            </div>
                                        </div>

                                        {props.showImageComment ? <div className="d-flex flex-column mt-3 image_preview_box">

                                            {props.imageUrl ? <div className="position-relative" style={{ width: '300px' }}>
                                                <img src={props.imageUrl} alt="" style={{ width: '300px' }} />
                                                <div className="remove_image" onClick={() => {
                                                    props.setImageUrl("");
                                                    props.saveImageData(null);
                                                }}><img src={Crossbtn} alt="" /></div>
                                            </div> : null}

                                            {props.comment.length ? <button class="mt-3 book_an_slot" data-bs-toggle="modal" data-bs-target="#slotBookModal"
                                                onClick={() => {
                                                    if (props.imageUrl) {
                                                        setComment("");
                                                        props.submitComment("image", props.eventDetails, props.imageData);
                                                    }
                                                    else {
                                                        setComment("");
                                                        props.submitComment("text", props.eventDetails, props.comment)
                                                    }
                                                }}>Post</button> : null}


                                        </div> : null}

                                    </div>
                                </>
                            )}

                            {/* <div className="row m-0 mt-4">
                                <div className="col-12 p-0 d-flex align-items-center">
                                    <p className="mb-0 evt_comment_heading">Comments</p>
                                    <p className="mb-0 evt_comment_info ms-4">
                                        {props.comments.length} comments
                                    </p>
                                </div>
                            </div> */}



                            {
                                (props.comments || []).map((v, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className="row m-0 pb-3 evt_bottom_border d-flex"
                                        >
                                            <div className="EventProfilePic_section p-0">
                                                {/* <div className="EventProfilePic">
                                                <img config={EventProfilePic} alt="" />
                                            </div> */}
                                                <div className='EventProfilePic'>
                                                    <img className='EventProfilePic_img' src={v.user.profilePic ? v.user.profilePic : "/static/png/Male.png"} alt='' />
                                                </div>
                                            </div>
                                            <div className="EventComments_section p-0 px-2">
                                                <p className='mb-0 user_commented'><b>{v.user.firstName} {v.user.lastName}</b></p>
                                                <p className='mb-0 user_commented' dangerouslySetInnerHTML={{ __html: v.comment }}></p>
                                                {v.attachment ? <img width="300px" height="150px" className="" src={v.attachment} alt="" /> : null}
                                                <p className="mb-0 mt-2 user_commented_like_cmnt">
                                                    <span
                                                        className="cursor_pointer"
                                                        onClick={() => props.handleLike(v.eventId, v.id)}
                                                    >
                                                        {v.reactionCount} Likes
                                                    </span>{" "}
                                                    &bull; <span className="cursor_pointer" onClick={() => {
                                                        props.setShowRepliedCommentInput(true);
                                                        props.setShowRepliedCommentId(v.id);
                                                    }}>{v.repliesCount} Replies</span>
                                                </p>
                                            </div>

                                            {props.showRepliedCommentId === v.id && props.showRepliedCommentInput && <>
                                                <div className='row m-0 mt-3'>
                                                    <div className='col-12 p-0 ps-4 d-flex align-items-center justify-content-end'>

                                                        <div className='input_profile_img'>
                                                            {/* <img config={DP} /> */}
                                                            <img src={props.userDetails.profilePic ? props.userDetails.profilePic : "/static/png/Male.png"} alt="" />
                                                        </div>

                                                        {/* <div class="d-flex" style={{ width: '90%' }}>
                                                            <input type='text' className='comment_input ms-3 ps-4 pb-1'
                                                                placeholder='Add a comment...' value={props.subComment}
                                                                onChange={(event) => {
                                                                    props.setSubComment(event.target.value);
                                                                }}
                                                                onKeyDown={(event) => {
                                                                    if (event.key === "Enter") {
                                                                        props.submitComment(props.eventDetails, event.target.value, v.id);
                                                                    }
                                                                }} />

                                                            <span
                                                                className="input-group-text comment_input_capture d-flex align-items-center justify-content-around"
                                                                id="basic-addon1"
                                                            >

                                                                <input type='file' id="file-input" className="d-none" multiple onChange={(e) => props.saveCommentImage(e.target.files[0])} />
                                                                <label for="file-input">
                                                                    <img className="ms-3" config={Photo_Video} />
                                                                </label>
                                                            </span>
                                                        </div> */}

                                                        <div class="d-flex" style={{ width: '90%' }}>
                                                            <div className="input-group input-group-post">
                                                                <ReactQuill placeholder="Add a comment..." modules={{
                                                                    toolbar: false,
                                                                    clipboard: {
                                                                        // toggle to add extra line breaks when pasting HTML:
                                                                        matchVisual: false,
                                                                    }
                                                                }} theme="snow" value={subComment} onChange={(event) => {
                                                                    // setShowImageComment(true);
                                                                    setSubComment(event);
                                                                    props.setSubComment(event);
                                                                }} />

                                                                <span
                                                                    className="input-group-text comment_input_capture d-flex align-items-center justify-content-around"
                                                                    id="basic-addon1"
                                                                >

                                                                    <input type='file' id="file-input" className="d-none" multiple onChange={(e) => {
                                                                        props.saveImageData(e.target.files[0]);
                                                                        props.setShowImageComment(true)
                                                                        if (e.target.files[0]) {
                                                                            const file = URL.createObjectURL(e.target.files[0]);
                                                                            props.setImageUrl(file);
                                                                        }
                                                                        e.target.value = null;
                                                                    }} />
                                                                    <label for="file-input">
                                                                        <img className="ms-3" src={Photo_Video} />
                                                                    </label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {<div className="d-flex flex-column mt-3 image_preview_box">

                                                        {props.imageUrl ? <div className="position-relative" style={{ width: '300px' }}>
                                                            <img src={props.imageUrl} alt="" style={{ width: '300px' }} />
                                                            <div className="remove_image" onClick={() => {
                                                                props.setImageUrl("");
                                                                props.saveImageData(null);
                                                            }}><img src={Crossbtn} alt="" /></div>
                                                        </div> : null}

                                                        {props.subComment ? <button class="mt-3 book_an_slot" data-bs-toggle="modal" data-bs-target="#slotBookModal"
                                                            onClick={() => {
                                                                if (props.imageUrl) {
                                                                    setSubComment("");
                                                                    props.submitComment("image", props.eventDetails, props.imageData, v.id)
                                                                }
                                                                else {
                                                                    setSubComment("");
                                                                    props.submitComment("text", props.eventDetails, props.subComment, v.id)
                                                                }
                                                            }}>Post</button> : null}


                                                    </div>}

                                                </div>
                                                <div className="d-flex justify-content-end align-items-end flex-column">
                                                    {v.replies.map((v, i) => {
                                                        return <div className="d-flex align-items-center" style={{ width: '94%' }} key={i}>
                                                            <div className='EventProfilePic_section py-3 p-0'>

                                                                <div className='EventProfilePic'>
                                                                    <img className='EventProfilePic_img' src={v.user.profilePic ? v.user.profilePic : "/static/png/Male.png"} alt="" />
                                                                </div>

                                                            </div>

                                                            <div className='EventComments_section py-3 p-0 px-2'>
                                                                <p className='mb-0 user_commented'><b>{v.user.firstName} {v.user.lastName}</b></p>
                                                                <p className='mb-0 user_commented' dangerouslySetInnerHTML={{ __html: v.comment }}></p>
                                                                {v.attachment ? <img width="300px" height="150px" className="" src={v.attachment} alt="" /> : null}
                                                                {/* <p className='mb-0 mt-2 user_commented_like_cmnt'><span className="cursor_pointer">{curr.reactionCount} Likes</span> &bull; <span className="cursor_pointer" onClick={() => {
                                                    setShowRepliedCommentInput(true);
                                                    setShowRepliedCommentId(curr.id);
                                                }}> {curr.repliesCount} Replies</span> </p> */}
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            </>}
                                        </div>
                                    );
                                })}

                            {/* modal-1 */}

                            <div
                                class={`modal fade ${props.show ? "show" : ""}`}
                                style={{
                                    display: props.show ? "block" : "none",
                                    background: props.show ? "rgba(0,0,0,0.5)" : "",
                                }}
                                id="exampleModalRegBtn"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabelReg"
                                aria-hidden="true"
                            >
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div className="row m-0 register_event_modal">
                                            <div className="col-12 py-3">
                                                <div className="row m-0">
                                                    <p className="text-center confirm_text mb-1">
                                                        Kaleidoscope Live registration
                                                    </p>
                                                </div>

                                                <div className="row m-0 mt-3">
                                                    <div className="col-4 p-0">
                                                        <img
                                                            className=""
                                                            width={"145px"}
                                                            height="90px"
                                                            src={
                                                                props.eventDetails.image
                                                                    ? props.eventDetails.image
                                                                    : props.NewsFeedlgImgModel
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="col-8 p-0">
                                                        <div className="row m-0">
                                                            <div className="col-12 p-0">
                                                                <p className="mb-0 title_text">
                                                                    {props.eventDetails.name}
                                                                </p>
                                                                {moment(props.eventDetails.startDate).format("D MM") === moment(props.eventDetails.endDate).format("D MM") ? <>


                                                                    <p className="mb-0 evt_reg_model_time_text mt-2">
                                                                        {moment(props.eventDetails.startDate).utc().format("D MMM YYYY")},{" "}
                                                                        {moment(props.eventDetails.startDate).utc().format("dddd")}
                                                                    </p>
                                                                    <p className="mb-0 evt_reg_model_time_text mt-1">
                                                                        {moment(props.eventDetails.startDate).utc().format("hh:mm a")}
                                                                        &nbsp;-&nbsp;
                                                                        {moment(props.eventDetails.endDate).utc().format("hh:mm a")}
                                                                    </p>

                                                                </> : <>

                                                                    <p className="mb-0 evt_reg_model_time_text mt-2">
                                                                        {moment(props.eventDetails.startDate).utc().format("D MMM YYYY")},{" "}
                                                                        {moment(props.eventDetails.startDate).utc().format("dddd")}
                                                                    </p>
                                                                    <p className="mb-0 evt_reg_model_time_text mt-1">To</p>
                                                                    {/* <p className='mb-0 evt_time mt-1'>8:00 PM - 9:30PM</p> */}
                                                                    <p className="mb-0 evt_reg_model_time_text mt-1">
                                                                        {moment(props.eventDetails.endDate).utc().format("D MMM YYYY")},{" "}
                                                                        {moment(props.eventDetails.endDate).utc().format("dddd")}
                                                                    </p>
                                                                    <p className="mb-0 evt_reg_model_time_text mt-1">
                                                                        {moment(props.eventDetails.startDate).utc().format("hh:mm a")}
                                                                        &nbsp;-&nbsp;
                                                                        {moment(props.eventDetails.endDate).utc().format("hh:mm a")}
                                                                    </p>

                                                                </>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row m-0">
                                                    <div className="col-12 p-0 px-4 py-4 d-flex align-items-center justify-content-start">
                                                        <p className=" mb-0 event_det_text">
                                                            You are registering for this Kaleidoscope Live event and will be listed as a participant.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="row m-0 justify-content-center align-items-center">
                                                    <div
                                                        className="event_cancel_btn me-4"
                                                        onClick={() => props.setShow(false)}
                                                    >
                                                        Cancel
                                                    </div>
                                                    <div
                                                        className="event_register_done_btn"
                                                        onClick={props.handleShowRegister}
                                                    >
                                                        Yes
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* <!--Modal-2--> */}
                            <div
                                class={`modal fade ${props.showRegister ? "show" : ""}`}
                                style={{
                                    display: props.showRegister ? "block" : "none",
                                    background: props.showRegister ? "rgba(0,0,0,0.5)" : "",
                                }}
                                id="exampleModalRegisterBtn"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabelReg2"
                                aria-hidden="true"
                            >
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div className="row m-0">
                                            <div className="col-12 ps-5 pe-5 pt-5">
                                                <div className="row m-0">
                                                    <div className="col-10 p-0 d-flex align-items-center justify-content-start">
                                                        <p className=" mb-0 evt_reg_model_info_text">
                                                            Registration for
                                                        </p>
                                                    </div>
                                                    <div className="col-2 p-0 d-flex align-items-center justify-content-end">
                                                        <img
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModalCloseBtn"
                                                            onClick={props.handleShow1}
                                                            className=""
                                                            src={Close}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row m-0">
                                                    <div className="col-12 p-0">
                                                        <p className="mb-0 mt-4 evt_reg_model_bold_text">
                                                            {props.eventDetails.name}
                                                        </p>
                                                        {moment(props.eventDetails.startDate).format("D MM") === moment(props.eventDetails.endDate).format("D MM") ? <>


                                                            <p className="mb-0 evt_reg_model_time_text mt-2">
                                                                {moment(props.eventDetails.startDate).utc().format("D MMM YYYY")},{" "}
                                                                {moment(props.eventDetails.startDate).utc().format("dddd")}
                                                            </p>
                                                            <p className="mb-0 evt_reg_model_time_text mt-1">
                                                                {moment(props.eventDetails.startDate).utc().format("hh:mm a")}
                                                                &nbsp;-&nbsp;
                                                                {moment(props.eventDetails.endDate).utc().format("hh:mm a")}
                                                            </p>

                                                        </> : <>

                                                            <p className="mb-0 evt_reg_model_time_text mt-2">
                                                                {moment(props.eventDetails.startDate).utc().format("D MMM YYYY")},{" "}
                                                                {moment(props.eventDetails.startDate).utc().format("dddd")}
                                                            </p>
                                                            <p className="mb-0 evt_reg_model_time_text mt-1">To</p>
                                                            {/* <p className='mb-0 evt_time mt-1'>8:00 PM - 9:30PM</p> */}
                                                            <p className="mb-0 evt_reg_model_time_text mt-1">
                                                                {moment(props.eventDetails.endDate).utc().format("D MMM YYYY")},{" "}
                                                                {moment(props.eventDetails.endDate).utc().format("dddd")}
                                                            </p>
                                                            <p className="mb-0 evt_reg_model_time_text mt-1">
                                                                {moment(props.eventDetails.startDate).utc().format("hh:mm a")}
                                                                &nbsp;-&nbsp;
                                                                {moment(props.eventDetails.endDate).utc().format("hh:mm a")}
                                                            </p>

                                                        </>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row m-0 mt-3">
                                            <div className="col-12 p-0">
                                                <img
                                                    className=""
                                                    width={"100%"}
                                                    src={
                                                        props.eventDetails.image
                                                            ? props.eventDetails.image
                                                            : props.NewsFeedlgImgModel
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                        </div>

                                        <div className="row m-0 mt-2">
                                            <div className="col-12 p-0 ps-5 pe-5 pt-4 pb-5">
                                                <p className="mb-0 model_reg_done_messege">
                                                    Thank you <b>{props.userDetails.firstName} {props.userDetails.lastName}</b> for registering for the
                                                    {props.eventDetails.name} <b>Live Event</b>.
                                                </p>
                                                <p className="mb-0 mt-2 model_reg_done_info_messege">
                                                    An email with the webinar link has been sent to your
                                                    email {props.userDetails.email}.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!--Modal-3--> */}

                            <div
                                class={`modal fade ${props.show1 ? "show" : ""}`}
                                style={{
                                    display: props.show1 ? "block" : "none",
                                    background: props.show1 ? "rgba(0,0,0,0.5)" : "",
                                }}
                                id="exampleModalCloseBtn"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabelReg3"
                                aria-hidden="true"
                            >
                                <div class="modal-dialog modal-dialog-centered modal-sm">
                                    <div class="modal-content">
                                        <div className="row m-0">
                                            <div className="col-12 p-0 ps-5 pe-5 pt-4 pb-4">
                                                <div className="row m-0">
                                                    <div className="col-12 p-0">
                                                        <p className="mb-0 model_promt_text">
                                                            Are you sure you want to go back? Registration is
                                                            not complete.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="row m-0 mt-3">
                                                    <div className="col-6 p-0 d-flex justify-content-start align-items-center">
                                                        <p
                                                            className="mb-0 model_cancel"
                                                            onClick={props.handleBackModal}
                                                        >
                                                            Cancel
                                                        </p>
                                                    </div>
                                                    <div className="col-6 p-0 d-flex justify-content-end align-items-center">
                                                        <p
                                                            className="mb-0 model_close_yes"
                                                            onClick={props.handleCloseModal}
                                                        >
                                                            Yes
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>

                </div>
            </div>



            {props.isShowInviteFrndModal && <props.InviteFriendModal setIsShowInviteFrndModal={() => props.setIsShowInviteFrndModal(false)} isShowInviteFrndModal={props.isShowInviteFrndModal} friendsList={props.friendsList} eventData={{ event: props.eventDetails }} />}

        </>
    )
}

export default NewsfeedTab;
