import React from 'react';

import FuncWorking from "../../assets/FuncWorking.png";
import FuncLearning from "../../assets/FuncLearning.png";
import FuncNotice from "../../assets/FuncNotice.png";
import FeelMeaning from "../../assets/FeelMeaning.png";
import FFCPageTemplate from "../../component/FFCPageTemplate";

export default function Functioning() {
    return <FFCPageTemplate
        title={"Functioning Well"}
        description={"Meaningful work and discovery, developing skills & talents, and finding purpose."}
        items={[
            {
                title: "Working",
                img: FuncWorking,
                text: [
                    "Promoting fulfilment in your",
                    "work and chosen careers through",
                    "activities that bring out your best",
                    "talents and skills",
                    "."
                ]
            },
            {
                title: "Learning",
                img: FuncLearning,
                text: [
                    "Boosting your abilities and",
                    "skills and opening the mind",
                    "to new ideas",
                    "and experiences to",
                    "benefit total well-being."
                ]
            },
            {
                title: "Noticing",
                img: FuncNotice,
                text: [
                    "Noticing, or becoming aware, of",
                    "your immediate surroundings",
                    "to get out of auto-pilot mode",
                    "and be present with",
                    "your senses."
                ]
            },
            {
                title: "Meaning",
                img: FeelMeaning,
                text: [
                    "Exploring and",
                    "finding meaning in",
                    "your life's guiding beliefs,",
                    "values and principles",
                    "as they align with your everyday.",
                ]
            }
        ]}
    />
}