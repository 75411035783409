import React, {useEffect, useState} from 'react'
import PictureVacter from '../../../assets/svg/Organization/PictureVacter.svg';
import {createProgram, editProgram} from '../../../utils/apiUrlsSetup'
import {useNavigate} from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from 'react-select';


const CreateProgramModal = (props) => {
  const navigate = useNavigate();

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const [inviteCodeErrMsg, setInviteCodeErrMsg] = useState("");

  const [selectedOrg, setOrg] = useState({value: -1, label: 'Select Community'})

  const [options, setOptions] = useState([{value: -1, label: 'Select Community'}])

  const [preRequisites, setPreRequisites] = useState("")
  const [description, setDescription] = useState("")
  const [userLearning, setUserLearnings] = useState("")

  const [details, setDetails] = useState({
    title: "",
    image: ""
  })


  const [imgUrl, setImgUrl] = useState()

  const handleChange = (event) => {
    const name = event.target.name
    const value = name === 'image' ? event.target.files[0] : event.target.value
    if (name === 'image') {
      setImgUrl(URL.createObjectURL(event.target.files[0]))
    }
    const data = {...details}
    data[name] = value
    setDetails(data)
  }


  const createOrganization = async () => {
    const formData = new FormData()
    formData.append('title', details.title)
    formData.append('description', description)
    formData.append('image', details.image)
    formData.append('userLearnings', userLearning)
    formData.append('preRequisites', preRequisites)
    formData.append('orgId', selectedOrg.value !== -1 ? selectedOrg.value : null)
    formData.append('userId', userDetails.id)
    formData.append('createdBy', `${userDetails.firstName} ${userDetails.lastName}`)
    if (props.program) {
      const response = await editProgram(formData, props.program.id)
      if (response.status === 200) {
        await props.setOpenModal(false)
        navigate(`/programmes`)
      }
    } else {
      const response = await createProgram(formData)
      if (response.status === 200) {
        await props.setOpenModal(false)
        navigate(`/programme/${response.data.data.id}`)
      }
    }


  }

  const handleSelect = (opt) => {
    if (opt.value !== -1) {
      setOrg({...opt})
    }
  }

  useEffect(() => {
    const data = []
    if (props.userOrganisation.length) {
      props.userOrganisation.map(o => data.push({value: parseInt(o.id), label: o.name}))
      setOptions([...options, ...data])
    }

  }, [props.userOrganisation])


  useEffect(() => {
    if (props.program) {
      const data = {
        title: props.program.title,
        image: props.program.image
      }

      setDescription(props.program.description)
      setPreRequisites(props.program.preRequisites)
      setUserLearnings(props.program.userLearnings)
      setDetails({...data})
      setImgUrl(props.program.image)
      setOrg({label: props.program.organisation.name, value: props.program.organisation.id})
    }
  }, [props.programs])

  return (
    <div className={`modal fade ps-lg-4 ps-md-4 ${props.openModal ? 'show' : ''}`}
         style={{display: props.openModal ? 'block' : 'none', background: props.openModal ? 'rgba(0,0,0,0.5)' : ''}}
         id="createOrg" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-xl modal_width_tab_view">
        <div className="modal-content">
          <div className="modal-header py-4">
            <h5 className="modal-title"
                id="exampleModalLabel">{props.program ? 'Edit Programme' : 'Create New Programme'}</h5>
            <button type="button" className="btn-close" onClick={() => {
              setImgUrl("")
              props.setOpenModal(false)
            }}></button>
          </div>
          <div class="modal-body p-0">

            <div className='createOrgCover d-flex flex-column align-items-center justify-content-center'>
              {/* <img config={PictureVacter} alt='' /> */}

              <label className={imgUrl ? "cover_photo_label_imgUrl" : "cover_photo_label"}>
                <img className={imgUrl ? "cover_photo_imgUrl" : "cover_photo"} src={imgUrl ? imgUrl : PictureVacter}
                     alt=''></img>
                <input type="file" name="image" style={{display: "none"}} onChange={handleChange}/>
              </label>

              {/* For Cover Image: */}
              {/* <img className='coverPhoto' config='' alt='' /> */}

              <p className='mb-0 upload_info mt-3'>Upload a cover photo here (919 x 197px)*</p>
            </div>
            {/* <p className={`image_validation_Error pt-2 text-center ${imgUrl ? 'd-none' : 'd-block'}`} >*Please Upload cover Photo</p> */}

            <div className='p-lg-5 p-md-5 p-3'>

              <div className='row m-0'>
                <div className='col-12 p-0'>

                  <p className='mb-0 mdl_org_name_heading_'>Programme Title*</p>

                  <div className="input-group neworg_searchbar neworg_searchbar_mdl mt-2">
                    <input value={details.title} type="text" class="form-control"
                           placeholder="Write the title of your program here" aria-label="Username"
                           aria-describedby="basic-addon1" name='title' onChange={handleChange}/>
                  </div>
                </div>
              </div>

              <div className='row m-0 mt-5'>
                <div className='col-12 p-0'>

                  <p className='mb-0 mdl_org_name_heading_'>Community*</p>
                  <div className="neworg_searchbar neworg_searchbar_mdl mt-2">
                    <Select
                      value={selectedOrg}
                      onChange={handleSelect}
                      options={options}
                    />
                  </div>

                  {/* <div className="input-group neworg_searchbar neworg_searchbar_mdl mt-2">
                                        <input type="text" value={props.userOrganisation.name} disabled class="form-control" placeholder="Program Community" aria-label="Username" aria-describedby="basic-addon1" name='community' onChange={handleChange} />
                                    </div> */}
                </div>
              </div>

              {/* <div className='row m-0 mt-5'>
                                <div className='col-12 p-0'>
                                    <p className='mb-0 mdl_org_name_heading_'>Is this program free of cost?</p>
                                </div>
                            </div>

                            <div className='row m-0 mt-4'>
                                <div className='col-12 col-lg-3 col-md-3 p-0'>

                                    <div class="form-check mdl_create_org_check">
                                        <input class="form-check-input" name="isFreeProgram" type="radio" value={true} id="flexCheckChecked" onChange={handleChange} />
                                        <label class="form-check-label" for="flexCheckChecked">Yes</label>
                                    </div>

                                </div>
                                <div className='col-12 col-lg-3 col-md-3 p-0'>

                                    <div class="form-check mdl_create_org_check">
                                        <input class="form-check-input" name="isFreeProgram" type="radio" value={false} id="flexCheckChecked" onChange={handleChange} />
                                        <label class="form-check-label" for="flexCheckChecked">No</label>
                                    </div>

                                </div>
                            </div> */}


              <div className='row m-0 mt-5'>
                <div className='col-12 p-0'>
                  <p className='mb-0 mdl_org_name_heading_'>Description*</p>
                  <ReactQuill theme="snow" value={description} onChange={setDescription}/>
                  {/* <textarea class="form-control text_area mt-3" id="exampleFormControlTextarea1" placeholder='Write the description of your program' rows="3" name='description' onChange={handleChange}></textarea> */}
                </div>
              </div>

              <div className='row m-0 mt-5'>
                <div className='col-12 p-0'>

                  <p className='mb-0 mdl_org_name_heading_'>What will user's take away? *</p>

                  <ReactQuill theme="snow" value={userLearning} onChange={setUserLearnings}/>

                  <p className='mb-0 mt-2' style={{color: 'red'}}>{inviteCodeErrMsg}</p>
                </div>
              </div>

              <div className='row m-0 mt-5'>
                <div className='col-12 p-0'>

                  <p className='mb-0 mdl_org_name_heading_'>Who is the programme designed for? *</p>
                  <ReactQuill theme="snow" value={preRequisites} onChange={setPreRequisites}/>
                  {/* <textarea class="form-control text_area mt-3" id="exampleFormControlTextarea1" placeholder={`Write pre requisite's for this program`} rows="3" name='preRequisites' onChange={handleTextArea}></textarea> */}

                  <p className='mb-0 mt-2' style={{color: 'red'}}>{inviteCodeErrMsg}</p>
                </div>
              </div>


              <div className='row m-0 mt-5'>
                <div
                  className='col-12 p-0 d-flex align-items-center justify-content-lg-end justify-content-md-end justify-content-center'>
                  <button className='mdl_cancel_btn me-2' onClick={() => {
                    setImgUrl("");
                    props.setOpenModal(false);
                  }}>Cancel
                  </button>

                  <div className="mdl_create_btn">
                    <button className='mdl_create_btn mdl_create_btn_button ms-2'

                            onClick={() => {
                              createOrganization();
                              setImgUrl("");
                            }} disabled={
                      ((details.name && details.description && details.image && details.privacy && details.inviteCode) === "" || inviteCodeErrMsg.length) ? true : false
                    }

                    >{props.program ? 'Save' : 'Create'}</button>
                    {
                      !imgUrl ?
                        <div className={`tooltip_create_btn bg-dark p-2 py-1 shadow ms-auto rounded`}>Please upload
                          cover photo</div> : null
                    }

                  </div>

                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateProgramModal
