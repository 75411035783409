import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import './friends.css';
import dwnldArrow from "../../assets/dwnld_arrow.png"
import view_icon from "../../assets/view_icon.png"
import {useParams} from "react-router";
import Photo_Video from "../../assets/svg/PhotoVideo.svg";
import Like from '../../assets/svg/Friends/Like.svg';
import OutlinedLike from '../../assets/svg/Friends/OutlinedLike.svg';
import Logo from '../../assets/Logo.png'
import OutlinedComment from '../../assets/svg/Friends/OutlinedComment.svg';
import {
  blockFriend,
  cancelFriendRequest,
  createChat,
  createPostLike,
  deletePostLike,
  getPostLike,
  getUserInformation,
  getUserInformationById,
  updatePost
} from '../../utils/apiUrlsSetup';
import {
  createPostComments,
  deleteFriend,
  getPostComments,
  getPosts,
  respondToFriendRequest,
  sendFriendRequest
} from '../../utils/apiUrlsSetup';
import FriendDashboardCover from '../../assets/FriendDashboardCover.png';
import moment from 'moment';
import More from "../../assets/MoreIcon.png"
import ResponsiveNav from './../../component/staticPages/responsiveNavBar/responsiveNav';
import LikeProfileModal from './../../component/modals/likeProfileModal/likeProfileModal';
import Linkify from 'react-linkify';
import LoggedInLayout from "../../component/layout/LoggedInLayout.tsx";

const Friends = (props) => {
  const navigate = useNavigate();
  let userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const {id} = useParams();

  const [userData, setUserData] = useState({});
  const [postData, setPostData] = useState([]);
  const [comment, setComment] = useState("");
  const [index, setIndex] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [showComment, setShowComment] = useState(false)
  const [isOpenBlock, setIsOpenBlock] = useState(false);
  const [postId, setPostId] = useState()
  const [commentsArr, setCommentsArr] = useState([])
  const [showRepliedCommentInput, setShowRepliedCommentInput] = useState(false);
  const [showRepliedCommentId, setShowRepliedCommentId] = useState("");
  const [subComment, setSubComment] = useState('')

  const [showUserLikedList, setShowUserLikedList] = useState(false);

  const [listOfLikeUsers, setListOfLikedUsers] = useState([]);

  useEffect(() => {
    if (userDetails.id === Number(id)) {
      navigate('/profile');
    }

    if (id) {
      getUserInformationbyId();
    } else {
      getUserInformationbyUser();
    }

    getPostList();
  }, []);

  const getPostList = async () => {
    const responseData = await getPosts();
    if (responseData.status === 200) {
      let data = responseData.data.data.postArray;
      setPostData(data);
    }
  }

  const getUserInformationbyUser = async () => {
    const responseData = await getUserInformation();
    if (responseData.status === 200) {
      setUserData(responseData.data.data)
    }

  }

  const getUserInformationbyId = async () => {
    const data = await getUserInformationById(id);
    if (data.status === 200) {
      setUserData(data.data.data)
    }
  }

  const addFriend = async () => {
    let obj = {userId: id};
    const data = await sendFriendRequest(obj);
    if (data.status === 200) {
      getUserInformationbyId();
    }
  }

  const cancelFriendReq = async () => {
    let obj = {userId: id};
    const data = await cancelFriendRequest(obj);
    if (data.status === 200) {
      getUserInformationbyId();
    }
  }

  const respondToFriendReq = async (value) => {
    if (value === "accept_req") {
      let obj = {userId: id, status: "accepted"};
      const data = await respondToFriendRequest(obj);
      if (data.status === 200) {
        let obj = {
          membersIdArray: [id]
        }
        await createChat(obj);
        getUserInformationbyId();
      }
    } else {
      let obj = {userId: id, status: "rejected"};
      const data = await respondToFriendRequest(obj);
      if (data.status === 200) {
        getUserInformationbyId();
      }
    }
  }

  const unFriend = async () => {
    let obj = {userId: id};
    const data = await deleteFriend(obj);
    if (data.status === 200) {
      getUserInformationbyId();
      setIsOpen(false);
    }
  }

  const blockFrnd = async () => {
    let obj = {userId: id};
    const data = await blockFriend(obj);
    if (data.status === 200) {
      getUserInformationbyId();
      setIsOpenBlock(false);
    }
  }

  const respondToFriendReqAccepted = async (value) => {
    if (value === "delete_frnd") {
      setIsOpen(true);
    } else if (value === "block_frnd") {
      setIsOpenBlock(true);
    }
  }

  const getFileType = (url) => {
    if (url) {
      let splitUrl = url.split(".");
      let findType = splitUrl[splitUrl.length - 1];
      return findType.toUpperCase();
    }
  }

  const getFileName = (url) => {
    if (url) {
      let splitUrl = url.split("/");
      let findType = splitUrl[splitUrl.length - 1];
      return findType;
    }
  }

  const submitComment = async (type, data, value, cmmtId) => {
    const formData = new FormData();
    if (type === "text") {
      formData.append("postId", data.id);
      formData.append("comment", value);
    } else {
      formData.append("postId", data.id);
      formData.append("attachment", value);
      formData.append("comment", comment);
    }
    if (cmmtId) {
      formData.append("commentId", cmmtId);
    }
    const responseData = await createPostComments(formData);
    if (responseData.status === 200) {
      setComment("");
      setSubComment("");
      const responseData = await getPostComments(data.id);
      if (responseData.status === 200) {
        setShowComment(true)
        setCommentsArr(responseData.data.data)

      }
    }
    getPostList()
  }

  const handleLike = async (id, commentId, post) => {

    const responseData = commentId ? await getPostLike(`commentId=${commentId}`) : await getPostLike(`postId=${post.id}`);
    let data = responseData.data.data.map(v => v.userId)
    let filterData = data.includes(userDetails.id);
    if (filterData) {
      if (commentId) {
        const response = await deletePostLike({commentId: commentId});
        if (response.status === 200) {
          handleShowComment(id);
          getPostList();
        }
      } else {
        const response = await deletePostLike({postId: id});
        if (response.status === 200) {
          getPostList();
        }

      }
    } else {
      if (commentId) {
        const response = await createPostLike({commentId: commentId});
        if (response.status === 200) {
          handleShowComment(id);
          getPostList();
        }
      } else {
        const response = await createPostLike({postId: id});
        if (response.status === 200) {
          getPostList();
        }

      }
    }

  }

  const deletePost = async (id) => {
    let obj = {
      isDeleted: true
    }
    let response = await updatePost(id, obj);
    if (response.status === 200) {
      getPostList();
    }
  }

  const handleShowComment = async (postId) => {
    setPostId(postId);
    const responseData = await getPostComments(postId);
    if (responseData.status === 200) {
      setShowComment(true)
      setCommentsArr(responseData.data.data)
    }
    // if (commentClicked === false) {
    //     setCommentClicked(true)
    // }
    // else{
    //     setCommentClicked(false)
    // }

  }

  const findSharePostUserDetails = (id) => {
    let filterUser = (postData || []).filter(val => val.type !== "event").filter(v => v.id === id);
    return filterUser.length ? filterUser[0] : {
      user: {},
      organisation: {},
      event: {}
    }
  }

  const getListOfLikedUsers = async (post) => {
    let response = await getPostLike(`postId=${post.id}`)
    if (response.status === 200) {
      if (response.data.data.length) {
        setShowUserLikedList(true)
      }
      setListOfLikedUsers(response.data.data);
    }
  }

  return (
    <LoggedInLayout title={"Friends"}>
      <div className='row m-0 justify-content-lg-start justify-content-center'>
        <div className='col-12 col-md-6'>

          <div className='friends_profile_dashboard'>

            <div className='row m-0 frnd_prof_dash_cover'
                 style={{backgroundImage: `url(${userData.backgroundImage ? userData.backgroundImage : FriendDashboardCover})`}}>
            </div>

            <div className='row m-0'>
              <div className='col-12 p-0 ps-4'>

                <div className='frinds_profile_pic'>
                  <img src={userData.profilePic ? userData.profilePic : "/static/png/Male.png"} alt=''/>
                </div>

              </div>
            </div>

            <div className='row m-0 px-4 py-3 frnd_brd_btm'>
              <div className='col-12 p-0 ps-1'>
                <p className='mb-0 frinds_profile_name'>{userData.firstName} {userData.lastName}</p>
                <p
                  className='mb-0 frinds_profile_desc'>{userData.job_title && userData.organisation ? `${userData.job_title} at ${userData.organisation}` : ''}</p>
              </div>
            </div>

            {/* <div className='row m-0 px-4 py-3 frnd_brd_btm'>
                                    <div className='col-12 p-0 ps-1'>
                                        <p className='mb-0 frinds_profile_edu'>Education</p>
                                        <p className='mb-0 mt-1 frinds_profile_edu_uni'>NUS</p>
                                        <p className='mb-0 mt-1 frinds_profile_edu_sub'>MSC, Psychology</p>
                                        <p className='mb-0 mt-1 frinds_profile_edu_year'>2014-2018</p>
                                    </div>
                                </div> */}

            <div className='row m-0 px-4 py-3 frnd_brd_btm'>
              <div className='col-12 p-0 ps-1'>
                {/* <p className='mb-0 frinds_profile_edu'>Email</p>
                                        <p className='mb-0 mt-1 frinds_profile_edu_sub'>{userData.email}</p> */}

                {/* <p className='mb-0 mt-3 frinds_profile_edu'>Phone Number</p>
                                        <p className='mb-0 mt-1 frinds_profile_edu_sub'>{userData.mobile || "**** ****"}</p> */}

                <p className='mb-0 mt-3 frinds_profile_edu'>Bio</p>
                <p className='mb-0 mt-1 frinds_profile_edu_sub'
                   dangerouslySetInnerHTML={{__html: userData.bio}}></p>
              </div>
            </div>

            <div className='row m-0 px-4 py-4 frnd_brd_btm'>
              <div className='col-12 p-0 ps-1'>
                {
                  id && !userData.friendInfo ?
                    <button onClick={() => addFriend()} className='p-0 add_frnd_btn'
                            data-bs-toggle="modal">Add Friend</button> : null
                }
                {
                  id && userData.friendInfo && (userData.friendInfo.status === "unfriended" || userData.friendInfo.status === "rejected") ?
                    <button onClick={() => addFriend()} className='p-0 add_frnd_btn'
                            data-bs-toggle="modal">Add Friend</button> : null
                }
                {id && userData.friendInfo && userDetails.id !== userData.friendInfo.userFriendId && userData.friendInfo.status !== "accepted" && userData.friendInfo.status !== "unfriended" && userData.friendInfo.status !== "rejected" &&
                  <div className='col-12 p-0 ps-1'>

                    <div className=' d-flex flex-column justify-content-center'>

                      <div className='row m-0'>

                        <select className="on_frnd_req_sent"
                                onChange={(e) => cancelFriendReq(e.target.value)}>
                          <option className="options" value="volvo" selected>Friend
                            Request Sent
                          </option>
                          <option className="options" value="cancel_req">Cancel Friend
                            Request
                          </option>
                        </select>
                      </div>

                    </div>

                  </div>}

                {id && userData.friendInfo && userDetails.id === userData.friendInfo.userFriendId && userData.friendInfo.status !== "accepted" && userData.friendInfo.status !== "rejected" && userData.friendInfo.status !== "unfriended" &&
                  <div className='col-12 p-0 ps-1'>

                    <div className=' d-flex flex-column justify-content-center'>

                      <div className='row m-0'>
                        {/* <div className='col-11 p-0 d-flex align-items-center justify-content-center'>
                                                        <p className='mb-0 frnd_req_sent_text'>Friend Request Sent</p>
                                                    </div>
                                                    <div className='col-1 p-0 d-flex align-items-center'>
                                                        <img config={DownArrow} alt='' />
                                                    </div> */}
                        <select className="on_frnd_req_sent"
                                onChange={(e) => respondToFriendReq(e.target.value)}>
                          <option className="options" value="volvo" selected>Friend
                            Request
                          </option>
                          <option className="options" value="accept_req">Accept Friend
                            Request
                          </option>
                          <option className="options" value="reject_req">Reject Friend
                            Request
                          </option>
                        </select>
                      </div>

                    </div>

                  </div>}

                {id && userData.friendInfo && userData.friendInfo.status !== "unfriended" && userData.friendInfo.status === "accepted" &&
                  <div className='col-12 p-0 ps-1'>

                    <div className=' d-flex flex-column justify-content-center'>

                      <div className='row m-0'>
                        {/* <div className='col-11 p-0 d-flex align-items-center justify-content-center'>
                                                        <p className='mb-0 frnd_req_sent_text'>Friend Request Sent</p>
                                                    </div>
                                                    <div className='col-1 p-0 d-flex align-items-center'>
                                                        <img config={DownArrow} alt='' />
                                                    </div> */}
                        <select className="on_frnd_req_sent"
                                onChange={(e) => respondToFriendReqAccepted(e.target.value)}>
                          <option className="options" value="volvo" selected>Friends
                          </option>
                          <option className="options" value="delete_frnd">Delete
                            Friend
                          </option>
                          <option className="options" value="block_frnd">Block
                            Friend
                          </option>
                        </select>
                      </div>

                    </div>

                  </div>}


                {!id && <button className='p-0 add_frnd_btn' onClick={() => {
                  navigate('/editprofile')
                }}>Edit Profile</button>}
              </div>
            </div>


            {/* <div className='row m-0 px-4 py-3 frnd_brd_btm'>
                                    <div className='col-12 p-0 ps-1'>
                                        <p className='mb-0 org_name_heading'>Organisations</p>

                                        <div className='org_img_box mt-3'>
                                            <img config={KScop} alt='' />
                                        </div>

                                        <p className='mb-0 org_name'>Kaleidoscope</p>
                                    </div>
                                </div> */}

          </div>

        </div>
        <div className='col-12 col-md-6'>

          <div className='row m-0'>
            <div className='col-12 p-0 ps-3 rec_post_box d-flex align-items-center'>
              <p className='mb-0 rec_post_heading'>Recent Posts</p>
            </div>
          </div>

          {!postData.filter(val => val.type !== "event").length &&
            <p className="text-center mt-4">No Posts Found</p>}

          {(postData || []).filter(val => val.type !== "event").filter(val => val.userId === Number(id)).slice(0, 5).map((v, i) => {
            return <div className='row m-0 mt-4' key={i}>
              <div className='col-12 p-0 post_cards_on_frnd_dash'>

                <div className='row m-0'>
                  <div className='col-12 p-0 px-4 py-2 post_cards_top_one'>

                    <div className='row m-0'>
                      <div className='col-2 p-0 d-flex align-items-center'>

                        <div className='post_cards_pro_img'>
                          <img src={v.user.profilePic ? v.user.profilePic : "/static/png/Male.png"}
                               alt=''/>
                        </div>

                      </div>
                      <div
                        className='col-8 p-0 d-flex flex-column justify-content-center'>

                        <p className='mb-0 post_cards_pro_name'>{v.user.firstName} {v.user.lastName}</p>
                        <p className='mb-0 post_cards_pro_post_date'>{moment(v.createdAt).fromNow()}</p>

                      </div>
                      <div
                        className='col-2 p-0 pt-2 d-flex align-items-start justify-content-end'>

                        {userDetails.role === "super-admin" ? <><img src={More}
                                                                     id="settings_friend_btn_drodwn cursor_pointer"
                                                                     data-bs-toggle="dropdown"
                                                                     aria-expanded="false"/>

                          <ul className="dropdown-menu"
                              aria-labelledby="settings_friend_btn_drodwn">
                            <li className="dropdown-item settings_friend_btn_ul_li"
                                onClick={() => deletePost(v.id)}>Delete
                            </li>
                          </ul>
                        </> : null}
                      </div>
                    </div>
                    <div className='row m-0 mt-1'>
                      <div className='col-12 p-0'>
                        <p className='mb-0 post_cards_post_text'>
                          <Linkify
                            componentDecorator={(decoratedHref, decoratedText, key) => (
                              <a
                                className={"text-primary"}
                                target="blank"
                                href={decoratedHref}
                                key={key}>
                                {decoratedText}
                              </a>
                            )}>{v.description}</Linkify></p>
                      </div>
                    </div>

                    {v.linkedPostId ? <>
                      <div className="row m-0 mt-3">
                        <div className="col-11">
                          <div className="row m-0">
                            <div
                              className="col-1 p-0 d-flex justify-content-center align-items-center">
                              <img alt="user" className="newsfeed_logo"
                                   src={findSharePostUserDetails(v.linkedPostId).user.profilePic ? findSharePostUserDetails(v.linkedPostId).user.profilePic : Logo}/>
                            </div>
                            <div
                              className="col-11 p-0 d-flex align-items-center ps-3">
                              <p className="mb-0 newsfeed_web_name d-flex"
                                 onClick={(e) => {
                                   e.stopPropagation();
                                   if (findSharePostUserDetails(v.linkedPostId).organisationId) {
                                     navigate(`/organisation/${findSharePostUserDetails(v.linkedPostId).organisationId}`)
                                   } else {
                                     navigate(`/friends/${findSharePostUserDetails(v.linkedPostId).user.id}`)
                                   }
                                 }}>{findSharePostUserDetails(v.linkedPostId).organisationId ? <>
                                {/* {findSharePostUserDetails(v.linkedPostId).organisation.name} */}
                                {findSharePostUserDetails(v.linkedPostId).user.firstName} {findSharePostUserDetails(v.linkedPostId).user.lastName}
                              </> : <> {findSharePostUserDetails(v.linkedPostId).user.firstName} {findSharePostUserDetails(v.linkedPostId).user.lastName}</>}

                                {/* {v.linkedPostId ? <p className="mb-0">&nbsp;&nbsp;<span>shared a</span>&nbsp;&nbsp;post</p> : null} */}
                              </p>
                              <div className="d-flex align-items-center">
                                {/* <p className="mb-0 newsfeed_followers">36,000 followers</p> */}
                                <p
                                  className="mb-0 newsfeed_time ms-4">{moment(findSharePostUserDetails(v.linkedPostId).createdAt).fromNow()}</p>
                                {findSharePostUserDetails(v.linkedPostId).subCategory &&
                                  <div className="category_badge">
                                    {findSharePostUserDetails(v.linkedPostId).subCategory[0]}
                                  </div>}

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row m-0 ">
                        <div className="col-12 p-0 pe-2">
                          <p className="mb-0 mt-3 post_details"><Linkify
                            componentDecorator={(decoratedHref, decoratedText, key) => (
                              <a target="blank" className={"text-primary"}
                                 href={decoratedHref} key={key}>
                                {decoratedText}
                              </a>
                            )}>{findSharePostUserDetails(v.linkedPostId).description}</Linkify>
                          </p>
                        </div>
                      </div>

                      {
                        findSharePostUserDetails(v.linkedPostId).type === "image" ? (
                          <div className="row m-0 mt-2 ">
                            <div className="col-12 p-0">
                              {findSharePostUserDetails(v.linkedPostId).attachment.length > 1 ?
                                <div id="demo"
                                     className="carousel slide d-flex justify-content-center"
                                     data-bs-ride="carousel"
                                     style={{background: 'black'}}>
                                  <div className="carousel-inner w-50">
                                    {findSharePostUserDetails(v.linkedPostId).attachment.map((v, i) => {
                                      return <div
                                        className={`carousel-item ${i === 0 ? "active" : ""}`}
                                        key={i}>
                                        <img src={v} alt="Los Angeles"
                                             className="d-block"
                                             width="100%"/>
                                        <div
                                          className="overlay_div_image">
                                          <div
                                            className="d-flex justify-content-end align-items-center px-4 pb-2 h-100">
                                            <div>
                                              <img
                                                src={dwnldArrow}
                                                alt="Los Angeles"
                                                className="cursor_pointer"
                                                onClick={() => {
                                                  window.open(v)
                                                }}/>
                                              <img src={view_icon}
                                                   alt="Los Angeles"
                                                   className="ms-3 cursor_pointer"
                                                   onClick={() => {
                                                     window.open(v)
                                                   }}/>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    })}
                                    {/* <div className="carousel-item active">
                                            <img config={v.attachment[0]} alt="Los Angeles" className="d-block" width="100%" />
                                        </div>
                                        <div className="carousel-item">
                                            <img config={v.attachment[0]} alt="Chicago" className="d-block" width="100%" />
                                        </div>
                                        <div className="carousel-item">
                                            <img config={v.attachment[0]} alt="New York" className="d-block" width="100%" />
                                        </div> */}
                                  </div>


                                  <button className="carousel-control-prev"
                                          type="button" data-bs-target="#demo"
                                          data-bs-slide="prev">
                                                                                <span
                                                                                  className="carousel-control-prev-icon"></span>
                                  </button>
                                  <button className="carousel-control-next"
                                          type="button" data-bs-target="#demo"
                                          data-bs-slide="next">
                                                                                <span
                                                                                  className="carousel-control-next-icon"></span>
                                  </button>
                                </div> : <img className="post_img"
                                              src={findSharePostUserDetails(v.linkedPostId).attachment[0]}/>}


                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                      {findSharePostUserDetails(v.linkedPostId).type === "document" ? (
                        <div className='row m-0 mt-3 post_heading_bg'>
                          <div className='col-12 p-0 px-4 py-4 cursor_pointer'
                               onClick={() => window.open(findSharePostUserDetails(v.linkedPostId).attachment[0])}>
                            <div className="file_back_div">
                              <div className="row m-0">
                                <div className="col-2 p-0 file_image">

                                </div>
                                <div
                                  className="col-7 p-0 ps-4 d-flex align-items-center justify-content-between">
                                  <div><p
                                    className="mb-0 file_name">{getFileName(findSharePostUserDetails(v.linkedPostId).attachment[0])}</p>
                                    <span
                                      className="pages_count">{findSharePostUserDetails(v.linkedPostId).count} pages</span>
                                    <p
                                      className="file_type">{getFileType(findSharePostUserDetails(v.linkedPostId).attachment[0])}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {findSharePostUserDetails(v.linkedPostId).type === "video" ? (
                        <div className='row m-0 mt-3 post_heading_bg'>
                          <div className='col-12 p-0 px-4 py-4'>
                            <video width="100%" height="240" controls>
                              <source
                                src={findSharePostUserDetails(v.linkedPostId).attachment && findSharePostUserDetails(v.linkedPostId).attachment[0]}
                                type="video/mp4" preload="metadata"/>
                            </video>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}


                    </> : null}

                    {v.type === "image" ? (
                      <div className="row m-0 mt-2 ">
                        <div className="col-12 p-0">
                          {v.attachment.length > 1 ? <div id="demo"
                                                          className="carousel slide d-flex justify-content-center"
                                                          data-bs-ride="carousel"
                                                          style={{background: 'black'}}>
                            <div className="carousel-inner w-50">
                              {v.attachment.map((v, i) => {
                                return <div
                                  className={`carousel-item ${i === 0 ? "active" : ""}`}
                                  key={i}>
                                  <img src={v} alt="Los Angeles"
                                       className="d-block" width="100%"/>
                                  <div className="overlay_div_image">
                                    <div
                                      className="d-flex justify-content-end align-items-center px-4 pb-2 h-100">
                                      <div>
                                        <img src={dwnldArrow}
                                             alt="Los Angeles"
                                             className="cursor_pointer"
                                             onClick={() => {
                                               window.open(v)
                                             }}/>
                                        <img src={view_icon}
                                             alt="Los Angeles"
                                             className="ms-3 cursor_pointer"
                                             onClick={() => {
                                               window.open(v)
                                             }}/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              })}
                            </div>


                            <button className="carousel-control-prev" type="button"
                                    data-bs-target="#demo" data-bs-slide="prev">
                              <span className="carousel-control-prev-icon"></span>
                            </button>
                            <button className="carousel-control-next" type="button"
                                    data-bs-target="#demo" data-bs-slide="next">
                              <span className="carousel-control-next-icon"></span>
                            </button>
                          </div> : <img className="post_img" src={v.attachment[0]}/>}


                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {v.type === "document" ? (
                      <div className='row m-0 mt-3 post_heading_bg'>
                        <div className='col-12 p-0 px-4 py-4'>
                          <div className="file_back_div">
                            <div className="row m-0">
                              <div className="col-2 p-0 file_image">

                              </div>
                              <div
                                className="col-10 p-0 ps-4 d-flex align-items-center justify-content-between">
                                <div><p
                                  className="mb-0 file_name">{getFileName(v.attachment[0])}</p>
                                  <span className="pages_count">5 pages</span>
                                  <p className="file_type">{getFileType(v.attachment[0])}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {v.type === "video" ? (
                      <div className='row m-0 mt-3 post_heading_bg'>
                        <div className='col-12 p-0 px-4 py-4'>
                          <video width="100%" height="240" controls>
                            <source src={v.attachment && v.attachment[0]}
                                    type="video/mp4" preload="metadata"/>
                          </video>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                  </div>
                </div>

                <div className='row m-0'>
                  <div className='col-12 p-0 px-4 py-2 d-flex align-items-center'>

                    <div
                      className='lcs_box like_box me-2 d-flex align-items-center justify-content-center cursor_pointer'
                      onClick={() => getListOfLikedUsers(v)}>
                      <img src={Like} alt=""/>
                    </div>
                    {/* <div className='lcs_box comment_box me-2 d-flex align-items-center justify-content-center'>
                                                    <img config={HandHeart} alt="" />
                                                </div>
                                                <div className='lcs_box share_box me-3 d-flex align-items-center justify-content-center'>
                                                    <img config={ReactOnPost} alt="" />
                                                </div> */}

                    <p className='mb-0 like_cmt_counts cursor_pointer'>{v.reactionCount}
                      <span>&bull;</span>
                      <span className="cursor_pointer" onClick={() => {
                        handleShowComment(v.id)
                      }}>
                                                        {v.commentCount} comments
                                                    </span>
                    </p>

                  </div>
                </div>

                <div className='row m-0'>
                  <div
                    className='col-12 p-0 px-4 py-3 d-flex align-items-center justify-content-between'>

                    <div className='post_cards_pro_img_on_cmnt'>
                      <img src={userDetails.profilePic ? userDetails.profilePic : "/static/png/Male.png"}
                           alt=''/>
                    </div>

                    <div
                      className='cmnt_box_input_with_lcs ps-3 d-flex flex-column justify-content-center'>

                      <div className='row m-0'>

                        {<div className='col-8 p-0 d-flex align-items-center'>
                          <input className='write_a_cmnt_input' type="text"
                                 placeholder='Add a comment...'
                            // value={comment}
                            // onChange={(event) => {
                            //     setComment(event.target.value);
                            // }}
                                 value={index === i ? comment : ""}
                                 onChange={(event) => {
                                   setIndex(i);
                                   setComment(event.target.value);
                                 }}
                                 onKeyDown={(event) => {
                                   if (event.key === "Enter") {
                                     submitComment("text", v, event.target.value, v.id);
                                   }
                                 }}
                            //onChange={() => onChangeHandler(v.id)}
                          />
                        </div>
                        }

                        <div
                          className='col-4 p-0 px-2 d-flex align-items-center justify-content-end'>
                          <div
                            className='lcs_circle d-flex align-items-center justify-content-center'>

                            <img src={OutlinedLike} className="cursor_pointer"
                                 onClick={() => handleLike(v.id, '', v)} alt=""/>
                          </div>
                          <div
                            className='lcs_circle d-flex align-items-center justify-content-center ms-2'>
                            <img src={OutlinedComment} className="cursor_pointer"
                                 alt=""
                                 onClick={() => submitComment("text", v, comment)}/>
                          </div>
                          {/* <div className='lcs_circle d-flex align-items-center justify-content-center'>
                                                                <img config={OutlinedShare} alt="" />
                                                            </div> */}
                        </div>

                      </div>


                    </div>

                  </div>
                </div>

                {
                  postId === v.id && commentsArr.commentArray && commentsArr.commentArray.map((curr, i) => {
                    return <div className={`row m-0 px-3 pb-3`} key={i}>
                      <div className='EventProfilePic_section py-3 p-0 evt_bottom_border'>

                        <div className='EventProfilePic'>
                          <img className='EventProfilePic_img'
                               src={curr.user.profilePic ? curr.user.profilePic : "/static/png/Male.png"}
                               alt=""/>
                        </div>

                      </div>

                      <div className='EventComments_post py-3 p-0 px-2 evt_bottom_border'>
                        <p className='mb-0 user_commented'
                           dangerouslySetInnerHTML={{__html: curr.comment}}></p>

                        {
                          curr.attachment ?
                            <img width="300px" height="150px" className="my-2"
                                 src={curr.attachment} alt=""/> : null
                        }
                        <p className='mb-0 mt-2 user_commented_like_cmnt'>
                                                            <span className="cursor_pointer"
                                                                  onClick={() => handleLike(v.id, curr.id, v)}>{curr.reactionCount} Likes</span> &bull;
                          <span className="cursor_pointer" onClick={() => {
                            setShowRepliedCommentInput(true);
                            setShowRepliedCommentId(curr.id);
                          }}> {curr.repliesCount} Replies</span></p>
                      </div>

                      {showRepliedCommentId === curr.id && showRepliedCommentInput && <>
                        <div className='row m-0 mt-3'>
                          <div
                            className='col-12 p-0 ps-4 d-flex align-items-center justify-content-end'>

                            <div className="input_profile_img">
                              <img
                                src={userDetails && userDetails.profilePic ? userDetails.profilePic : "/static/png/Male.png"}
                                alt=""/>
                            </div>

                            <div className="d-flex" style={{width: '90%'}}>
                              <input type='text'
                                     className='comment_input ms-3 ps-4 pb-1'
                                     placeholder='Add a comment...'
                                     value={subComment}
                                     onChange={(event) => {
                                       setSubComment(event.target.value);
                                     }}
                                     onKeyDown={(event) => {
                                       if (event.key === "Enter") {
                                         submitComment("text", v, event.target.value, curr.id);
                                       }
                                     }}/>

                              <span
                                className="input-group-text comment_input_capture d-flex align-items-center justify-content-around"
                                id="basic-addon1"
                              >
                                                                        {/* <img className="" config={Smile} /> */}
                                <input type='file' id="file-input"
                                       className="d-none" multiple
                                       onChange={(e) => submitComment("image", v, e.target.files[0])}/>
                                                                        <label htmlFor="file-input">
                                                                            <img className="ms-3" src={Photo_Video}/>
                                                                        </label>
                                                                    </span>
                            </div>

                          </div>
                        </div>
                        <div
                          className="d-flex justify-content-end align-items-end flex-column">
                          {curr.replies.map((v, i) => {

                            return <div className="d-flex align-items-center"
                                        style={{width: '93%'}} key={i}>
                              <div className='EventProfilePic_section py-3 p-0'>

                                <div className='EventProfilePic'>
                                  <img className='EventProfilePic_img'
                                       src={v.user.profilePic ? v.user.profilePic : "/static/png/Male.png"}
                                       alt=""/>
                                </div>


                              </div>

                              <div className='EventComments_post py-3 p-0 px-2'>
                                <p className='mb-0 user_commented'
                                   dangerouslySetInnerHTML={{__html: v.comment}}></p>
                              </div>
                            </div>
                          })}
                        </div>
                      </>}

                    </div>
                  })

                }
              </div>
            </div>
          })}
        </div>
      </div>

      <div className={`modal fade ${isOpen ? 'show' : ''}`}
           style={{display: isOpen ? 'block' : 'none', background: isOpen ? 'rgba(0,0,0,0.5)' : ''}}
           id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
           aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">

            <div className='row m-0'>
              <div className='col-12 p-0 px-5 py-4'>

                <div className='row m-0'>
                  <div className='col-12 p-0 px-4'>
                    <p className='mb-0 sup_adm_modal_heading'>Confirm deletion?</p>
                    <p className='mb-0 mt-3 sup_adm_modal_text'>This friend will be removed from
                      your Friends list and will no longer receive updates from you.</p>
                  </div>
                </div>

                <div className='row m-0 mt-4'>
                  <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                    <button className='friendReqActionModal_confirm p-0 me-3' data-bs-toggle="modal"
                            data-bs-target="#friendReqBlockModal" onClick={() => unFriend()}>
                      Confirm
                    </button>
                    <button className='friendReqActionModal_cancel ms-3' data-bs-dismiss="modal"
                            onClick={() => setIsOpen(false)}>Cancel
                    </button>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

      <div className={`modal fade ${isOpenBlock ? 'show' : ''}`}
           style={{display: isOpenBlock ? 'block' : 'none', background: isOpenBlock ? 'rgba(0,0,0,0.5)' : ''}}
           id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
           aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">

            <div className='row m-0'>
              <div className='col-12 p-0 px-5 py-4'>

                <div className='row m-0'>
                  <div className='col-12 p-0 px-4'>
                    <p className='mb-0 sup_adm_modal_heading'>Confirm block?</p>
                    <p className='mb-0 mt-3 sup_adm_modal_text'>This friend will be blocked from
                      your Friends list and will no longer receive updates from you.</p>
                  </div>
                </div>

                <div className='row m-0 mt-4'>
                  <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                    <button className='friendReqActionModal_confirm p-0 me-3'
                            data-bs-dismiss="modal" onClick={() => blockFrnd()}>Confirm
                    </button>
                    <button className='friendReqActionModal_cancel ms-3' data-bs-dismiss="modal"
                            onClick={() => setIsOpenBlock(false)}>Cancel
                    </button>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

      {/* Modal's */}

      {/* mobile */}

      <div className='row m-0 edit_profile_mobile'>
        <div className='col-12 p-0'>

          <ResponsiveNav heading="Profile"/>

          {/* <div className='row m-0 responsive_nav_bottom_border'>
                        <div className='col-12 p-0 ps-4 pe-4'>

                            <div className='row m-0 pt-3 pb-3 pt-md-4 pb-md-4'>
                                <div className='col-4 p-0 d-flex align-items-center'>
                                    <img className='responsive_nav_logo' config={LogoTab} alt='' />
                                </div>

                                <div className='col-4 p-0 d-flex align-items-center justify-content-center'>
                                    <p className='mb-0 responsive_page_name'>Your Profile</p>
                                </div>

                                <div className='col-4 p-0 d-flex align-items-center justify-content-end'>
                                    <img className='me-4 responsive_nav_notification_bell' config={NotificationBellResponsive} alt='' />
                                    <img className='responsive_nav_hamburger' config={HamburgerResponsive} alt='' />
                                </div>
                            </div>

                        </div>
                    </div> */}

          <div className='row m-0 edit_profile'>
            <div className='col-12 p-3'>

              <div className='row m-0 edit_profile_form_box'>
                <div className='col-12 p-0'>

                  <div className='row m-0'>
                    <div className='col-12 p-3 d-flex align-items-center justify-content-center'>
                      <div className='prof_edit_pic'>
                        <img src={userData.profilePic ? userData.profilePic : "/static/png/Male.png"} alt=''/>
                      </div>
                    </div>
                  </div>

                  <div className='row m-0 px-2 py-3 frnd_brd_btm'>
                    <div className='col-12 p-0 ps-1'>
                      <p className='mb-0 frinds_profile_name'>{userData.firstName} {userData.lastName}</p>
                      <p
                        className='mb-0 frinds_profile_desc'>{userData.job_title && userData.organisation ? `${userData.job_title} at ${userData.organisation}` : ''}</p>
                    </div>
                  </div>

                  {/* <div className='row m-0'>
                                        <div className='col-12 p-0 d-flex flex-column align-items-center justify-content-center'>
                                            <p className='mb-0 frinds_profile_name'>{userData.firstName} {userData.lastName}</p>

                                            {
                                                userDetails.role === "instructor" && <p className='mb-0 user_type_btn_text_exe'>{jobInformation.jobTitle} at {jobInformation.company}</p>
                                            }
                                        </div>
                                    </div> */}

                  {/* <div className='row m-0'>
                                        <div className='col-12 p-0 px-4'>
                                            <hr />
                                        </div>
                                    </div>
                                    {
                                        userDetails.role === "instructor" && <div className='row m-0'>
                                            <div className='col-12 p-3 brd_btm'>

                                                <div className='row m-0'>
                                                    <div className='col-12 p-0'>
                                                        <p className='mb-0 profile_basic_labal'>Education</p>
                                                        <p className='mb-0 mt-2 profile_basic_info profil_univercity'>{educationInformation.school}</p>
                                                        <p className='mb-0 profile_basic_info profil_uni_edu_sub'>{educationInformation.degreeType} {educationInformation.fieldOfStudy}</p>
                                                        <p className='mb-0 profile_basic_info profil_uni_edu_yr'>{educationInformation.startYear}-{educationInformation.endYear}</p>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    } */}
                  <div className="row m-0">
                    <div className='col-12 p-3 brd_btm'>


                      <div className='row m-0'>
                        <div className='col-12 p-0'>
                          <p className='mb-0 mt-3 frinds_profile_edu'>Bio</p>
                          <p className='mb-0 mt-1 frinds_profile_edu_sub'
                             dangerouslySetInnerHTML={{__html: userData.bio}}></p>
                        </div>
                      </div>

                      {/* <div className='row m-0'>
                                                <div className='col-12 p-0'>
                                                    <p className='mb-0 profile_basic_labal'>Email</p>
                                                    <p className='mb-0 profile_basic_info'>{userData.email}</p>
                                                </div>
                                            </div> */}

                      {/* <div className='row m-0 mt-2'>
                                                <div className='col-12 p-0'>
                                                    <p className='mb-0 profile_basic_labal'>Phone</p>
                                                    <p className='mb-0 profile_basic_info'>{userData.mobile}</p>
                                                </div>
                                            </div> */}

                    </div>
                  </div>

                  <div className='row m-0 px-2 py-4 frnd_brd_btm'>
                    <div className='col-12 p-0 ps-1'>
                      {
                        id && !userData.friendInfo ?
                          <button onClick={() => addFriend()} className='p-0 add_frnd_btn'
                                  data-bs-toggle="modal">Add Friend</button> : null
                      }
                      {
                        id && userData.friendInfo && (userData.friendInfo.status === "unfriended" || userData.friendInfo.status === "rejected") ?
                          <button onClick={() => addFriend()} className='p-0 add_frnd_btn'
                                  data-bs-toggle="modal">Add Friend</button> : null
                      }
                      {id && userData.friendInfo && userDetails.id !== userData.friendInfo.userFriendId && userData.friendInfo.status !== "accepted" && userData.friendInfo.status !== "unfriended" && userData.friendInfo.status !== "rejected" &&
                        <div className='col-12 p-0 ps-1'>

                          <div className=' d-flex flex-column justify-content-center'>

                            <div className='row m-0'>

                              <select className="on_frnd_req_sent"
                                      onChange={(e) => cancelFriendReq(e.target.value)}>
                                <option className="options" value="volvo"
                                        selected>Friend Request Sent
                                </option>
                                <option className="options" value="cancel_req">Cancel
                                  Friend Request
                                </option>
                              </select>
                            </div>

                          </div>

                        </div>}

                      {id && userData.friendInfo && userDetails.id === userData.friendInfo.userFriendId && userData.friendInfo.status !== "accepted" && userData.friendInfo.status !== "rejected" && userData.friendInfo.status !== "unfriended" &&
                        <div className='col-12 p-0 ps-1'>

                          <div className=' d-flex flex-column justify-content-center'>

                            <div className='row m-0'>
                              {/* <div className='col-11 p-0 d-flex align-items-center justify-content-center'>
                                                        <p className='mb-0 frnd_req_sent_text'>Friend Request Sent</p>
                                                    </div>
                                                    <div className='col-1 p-0 d-flex align-items-center'>
                                                        <img config={DownArrow} alt='' />
                                                    </div> */}
                              <select className="on_frnd_req_sent"
                                      onChange={(e) => respondToFriendReq(e.target.value)}>
                                <option className="options" value="volvo"
                                        selected>Friend Request
                                </option>
                                <option className="options" value="accept_req">Accept
                                  Friend Request
                                </option>
                                <option className="options" value="reject_req">Reject
                                  Friend Request
                                </option>
                              </select>
                            </div>

                          </div>

                        </div>}

                      {id && userData.friendInfo && userData.friendInfo.status !== "unfriended" && userData.friendInfo.status === "accepted" &&
                        <div className='col-12 p-0 ps-1'>

                          <div className=' d-flex flex-column justify-content-center'>

                            <div className='row m-0'>
                              {/* <div className='col-11 p-0 d-flex align-items-center justify-content-center'>
                                                        <p className='mb-0 frnd_req_sent_text'>Friend Request Sent</p>
                                                    </div>
                                                    <div className='col-1 p-0 d-flex align-items-center'>
                                                        <img config={DownArrow} alt='' />
                                                    </div> */}
                              <select className="on_frnd_req_sent"
                                      onChange={(e) => respondToFriendReqAccepted(e.target.value)}>
                                <option className="options" value="volvo"
                                        selected>Friends
                                </option>
                                <option className="options" value="delete_frnd">Delete
                                  Friend
                                </option>
                                <option className="options" value="block_frnd">Block
                                  Friend
                                </option>
                              </select>
                            </div>

                          </div>

                        </div>}


                      {!id && <button className='p-0 add_frnd_btn' onClick={() => {
                        navigate('/editprofile')
                      }}>Edit Profile</button>}
                    </div>
                  </div>

                  {/* <div className="row m-0">
                                        <div className='col-12 p-3 brd_btm'>

                                            <div className='row m-0'>
                                                <div className='col-12 p-0'>
                                                    <p className='mb-0 resp_profile_org_name'>Organisation</p>

                                                    <div className='resp_org_img mt-2 mb-1'>
                                                        <img config={Logo} alt="" />
                                                    </div>

                                                    <p className='mb-0 resp_org_name'>Kaleidoscope</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div> */}

                </div>
              </div>

            </div>

            <div className='col-12 p-0 p-3'>

              <div className='row m-0'>
                <div className='col-12 p-0 ps-3 rec_post_box d-flex align-items-center'>
                  <p className='mb-0 rec_post_heading'>Recent Posts</p>
                </div>
              </div>

              {!postData.filter(val => val.type !== "event").length &&
                <p className="text-center mt-4">No Posts Found</p>}


              {(postData || []).filter(val => val.type !== "event").filter(val => val.userId === Number(id)).slice(0, 5).map((v, i) => {
                return <div className='row m-0 mt-4' key={i}>
                  <div className='col-12 p-0 post_cards_on_frnd_dash'>

                    <div className='row m-0'>
                      <div className='col-12 p-0 px-2 py-2 post_cards_top_one'>

                        <div className='row m-0'>
                          <div className='col-2 p-0 d-flex align-items-center'>

                            <div className='post_cards_pro_img'>
                              <img src={v.user.profilePic ? v.user.profilePic : "/static/png/Male.png"}
                                   alt=''/>
                            </div>

                          </div>
                          <div
                            className='col-8 p-0 d-flex flex-column justify-content-center'>

                            <p className='mb-0 post_cards_pro_name'>{v.user.firstName} {v.user.lastName}</p>
                            <p className='mb-0 post_cards_pro_post_date'>{moment(v.createdAt).fromNow()}</p>

                          </div>
                          <div
                            className='col-2 p-0 pt-2 d-flex align-items-start justify-content-end'>

                            {userDetails.role === "super-admin" ? <><img src={More}
                                                                         id="settings_friend_btn_drodwn cursor_pointer"
                                                                         data-bs-toggle="dropdown"
                                                                         aria-expanded="false"/>

                              <ul className="dropdown-menu"
                                  aria-labelledby="settings_friend_btn_drodwn">
                                <li className="dropdown-item settings_friend_btn_ul_li"
                                    onClick={() => deletePost(v.id)}>Delete
                                </li>
                              </ul>
                            </> : null}

                          </div>
                        </div>

                        <div className='row m-0 mt-1'>
                          <div className='col-12 p-0'>
                            <p className='mb-0 post_cards_post_text'><Linkify
                              componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="blank" className={"text-primary"}
                                   href={decoratedHref} key={key}>
                                  {decoratedText}
                                </a>
                              )}>{v.description}</Linkify></p>
                          </div>
                        </div>

                        {v.linkedPostId ? <>
                          <div className="row m-0 mt-3">
                            <div className="col-12">
                              <div className="row m-0">
                                <div
                                  className="col-1 p-0 d-flex justify-content-center align-items-center">
                                  <img alt="user" className="newsfeed_logo"
                                       src={findSharePostUserDetails(v.linkedPostId).user.profilePic ? findSharePostUserDetails(v.linkedPostId).user.profilePic : Logo}/>
                                </div>
                                <div
                                  className="col-11 p-0 d-flex align-items-center ps-3">
                                  <p className="mb-0 newsfeed_web_name d-flex"
                                     onClick={(e) => {
                                       e.stopPropagation();
                                       if (findSharePostUserDetails(v.linkedPostId).organisationId) {
                                         navigate(`/organisation/${findSharePostUserDetails(v.linkedPostId).organisationId}`)
                                       } else {
                                         navigate(`/friends/${findSharePostUserDetails(v.linkedPostId).user.id}`)
                                       }
                                     }}>{findSharePostUserDetails(v.linkedPostId).organisationId ? <>
                                    {/* {findSharePostUserDetails(v.linkedPostId).organisation.name} */}
                                    {findSharePostUserDetails(v.linkedPostId).user.firstName} {findSharePostUserDetails(v.linkedPostId).user.lastName}
                                  </> : <> {findSharePostUserDetails(v.linkedPostId).user.firstName} {findSharePostUserDetails(v.linkedPostId).user.lastName}</>}

                                    {/* {v.linkedPostId ? <p className="mb-0">&nbsp;&nbsp;<span>shared a</span>&nbsp;&nbsp;post</p> : null} */}
                                  </p>
                                  <div className="d-flex align-items-center">
                                    {/* <p className="mb-0 newsfeed_followers">36,000 followers</p> */}
                                    <p
                                      className="mb-0 newsfeed_time ms-4">{moment(findSharePostUserDetails(v.linkedPostId).createdAt).fromNow()}</p>
                                    {findSharePostUserDetails(v.linkedPostId).subCategory &&
                                      <div className="category_badge">
                                        {findSharePostUserDetails(v.linkedPostId).subCategory[0]}
                                      </div>}

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row m-0 ">
                            <div className="col-12 p-0 pe-2">
                              <p className="mb-0 mt-3 post_details"><Linkify
                                componentDecorator={(decoratedHref, decoratedText, key) => (
                                  <a target="blank" className={"text-primary"}
                                     href={decoratedHref} key={key}>
                                    {decoratedText}
                                  </a>
                                )}>{findSharePostUserDetails(v.linkedPostId).description}</Linkify>
                              </p>
                            </div>
                          </div>

                          {
                            findSharePostUserDetails(v.linkedPostId).type === "image" ? (
                              <div className="row m-0 mt-2 ">
                                <div className="col-12 p-0">
                                  {findSharePostUserDetails(v.linkedPostId).attachment.length > 1 ?
                                    <div id="demo"
                                         className="carousel slide d-flex justify-content-center"
                                         data-bs-ride="carousel"
                                         style={{background: 'black'}}>
                                      <div className="carousel-inner w-50">
                                        {findSharePostUserDetails(v.linkedPostId).attachment.map((v, i) => {
                                          return <div
                                            className={`carousel-item ${i === 0 ? "active" : ""}`}
                                            key={i}>
                                            <img src={v} alt="Los Angeles"
                                                 className="d-block"
                                                 width="100%"/>
                                            <div
                                              className="overlay_div_image">
                                              <div
                                                className="d-flex justify-content-end align-items-center px-4 pb-2 h-100">
                                                <div>
                                                  <img
                                                    src={dwnldArrow}
                                                    alt="Los Angeles"
                                                    className="cursor_pointer"
                                                    onClick={() => {
                                                      window.open(v)
                                                    }}/>
                                                  <img src={view_icon}
                                                       alt="Los Angeles"
                                                       className="ms-3 cursor_pointer"
                                                       onClick={() => {
                                                         window.open(v)
                                                       }}/>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        })}
                                        {/* <div className="carousel-item active">
                                            <img config={v.attachment[0]} alt="Los Angeles" className="d-block" width="100%" />
                                        </div>
                                        <div className="carousel-item">
                                            <img config={v.attachment[0]} alt="Chicago" className="d-block" width="100%" />
                                        </div>
                                        <div className="carousel-item">
                                            <img config={v.attachment[0]} alt="New York" className="d-block" width="100%" />
                                        </div> */}
                                      </div>


                                      <button className="carousel-control-prev"
                                              type="button" data-bs-target="#demo"
                                              data-bs-slide="prev">
                                                                                <span
                                                                                  className="carousel-control-prev-icon"></span>
                                      </button>
                                      <button className="carousel-control-next"
                                              type="button" data-bs-target="#demo"
                                              data-bs-slide="next">
                                                                                <span
                                                                                  className="carousel-control-next-icon"></span>
                                      </button>
                                    </div> : <img className="post_img"
                                                  src={findSharePostUserDetails(v.linkedPostId).attachment[0]}/>}


                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                          {findSharePostUserDetails(v.linkedPostId).type === "document" ? (
                            <div className='row m-0 mt-3 post_heading_bg'>
                              <div className='col-12 p-0 px-4 py-4 cursor_pointer'
                                   onClick={() => window.open(findSharePostUserDetails(v.linkedPostId).attachment[0])}>
                                <div className="file_back_div">
                                  <div className="row m-0">
                                    <div className="col-2 p-0 file_image">

                                    </div>
                                    <div
                                      className="col-7 p-0 ps-4 d-flex align-items-center justify-content-between">
                                      <div><p
                                        className="mb-0 file_name">{getFileName(findSharePostUserDetails(v.linkedPostId).attachment[0])}</p>
                                        <span
                                          className="pages_count">{findSharePostUserDetails(v.linkedPostId).count} pages</span>
                                        <p
                                          className="file_type">{getFileType(findSharePostUserDetails(v.linkedPostId).attachment[0])}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {findSharePostUserDetails(v.linkedPostId).type === "video" ? (
                            <div className='row m-0 mt-3 post_heading_bg'>
                              <div className='col-12 p-0 px-4 py-4'>
                                <video width="100%" height="240" controls>
                                  <source
                                    src={findSharePostUserDetails(v.linkedPostId).attachment && findSharePostUserDetails(v.linkedPostId).attachment[0]}
                                    type="video/mp4" preload="metadata"/>
                                </video>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}


                        </> : null}

                        {v.type === "image" ? (
                          <div className="row m-0 mt-2 ">
                            <div className="col-12 p-0">
                              {v.attachment.length > 1 ? <div id="demo"
                                                              className="carousel slide d-flex justify-content-center"
                                                              data-bs-ride="carousel"
                                                              style={{background: 'black'}}>
                                <div className="carousel-inner w-50">
                                  {v.attachment.map((v, i) => {
                                    return <div
                                      className={`carousel-item ${i === 0 ? "active" : ""}`}
                                      key={i}>
                                      <img src={v} alt="Los Angeles"
                                           className="d-block" width="100%"/>
                                      <div className="overlay_div_image">
                                        <div
                                          className="d-flex justify-content-end align-items-center px-4 pb-2 h-100">
                                          <div>
                                            <img src={dwnldArrow}
                                                 alt="Los Angeles"
                                                 className="cursor_pointer"
                                                 onClick={() => {
                                                   window.open(v)
                                                 }}/>
                                            <img src={view_icon}
                                                 alt="Los Angeles"
                                                 className="ms-3 cursor_pointer"
                                                 onClick={() => {
                                                   window.open(v)
                                                 }}/>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  })}
                                </div>


                                <button className="carousel-control-prev" type="button"
                                        data-bs-target="#demo" data-bs-slide="prev">
                                  <span className="carousel-control-prev-icon"></span>
                                </button>
                                <button className="carousel-control-next" type="button"
                                        data-bs-target="#demo" data-bs-slide="next">
                                  <span className="carousel-control-next-icon"></span>
                                </button>
                              </div> : <img className="post_img" src={v.attachment[0]}/>}


                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {v.type === "document" ? (
                          <div className='row m-0 mt-3 post_heading_bg'>
                            <div className='col-12 p-0 px-4 py-4'>
                              <div className="file_back_div">
                                <div className="row m-0">
                                  <div className="col-2 p-0 file_image">

                                  </div>
                                  <div
                                    className="col-10 p-0 ps-4 d-flex align-items-center justify-content-between">
                                    <div><p
                                      className="mb-0 file_name">{getFileName(v.attachment[0])}</p>
                                      <span className="pages_count">5 pages</span>
                                      <p className="file_type">{getFileType(v.attachment[0])}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {v.type === "video" ? (
                          <div className='row m-0 mt-3 post_heading_bg'>
                            <div className='col-12 p-0 px-4 py-4'>
                              <video width="100%" height="240" controls>
                                <source src={v.attachment && v.attachment[0]}
                                        type="video/mp4" preload="metadata"/>
                              </video>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                      </div>
                    </div>

                    <div className='row m-0'>
                      <div className='col-12 p-0 px-4 py-2 d-flex align-items-center'>

                        <div
                          className='lcs_box like_box me-2 d-flex align-items-center justify-content-center cursor_pointer'
                          onClick={() => getListOfLikedUsers(v)}>
                          <img src={Like} alt=""/>
                        </div>
                        {/* <div className='lcs_box comment_box me-2 d-flex align-items-center justify-content-center'>
                                                    <img config={HandHeart} alt="" />
                                                </div>
                                                <div className='lcs_box share_box me-3 d-flex align-items-center justify-content-center'>
                                                    <img config={ReactOnPost} alt="" />
                                                </div> */}

                        <p className='mb-0 like_cmt_counts cursor_pointer'>{v.reactionCount}
                          <span>&bull;</span> <span onClick={() => {
                            handleShowComment(v.id)
                          }}>{v.commentCount} comments</span></p>

                      </div>
                    </div>

                    <div className='row m-0'>
                      <div
                        className='col-12 p-0 px-4 py-3 d-flex align-items-center justify-content-between'>

                        <div className='post_cards_pro_img_on_cmnt'>
                          <img src={userDetails.profilePic ? userDetails.profilePic : "/static/png/Male.png"}
                               alt=''/>
                        </div>

                        <div
                          className='cmnt_box_input_with_lcs ps-3 d-flex flex-column justify-content-center'>

                          <div className='row m-0'>

                            {<div className='col-8 p-0 d-flex align-items-center'>
                              <input className='write_a_cmnt_input' type="text"
                                     placeholder='Add a comment...'
                                // value={comment}
                                // onChange={(event) => {
                                //     setComment(event.target.value);
                                // }}
                                     value={index === i ? comment : ""}
                                     onChange={(event) => {
                                       setIndex(i);
                                       setComment(event.target.value);
                                     }}
                                     onKeyDown={(event) => {
                                       if (event.key === "Enter") {
                                         submitComment("text", v, event.target.value, v.id);
                                       }
                                     }}
                                //onChange={() => onChangeHandler(v.id)}
                              />
                            </div>
                            }

                            <div
                              className='col-4 p-0 px-2 d-flex align-items-center justify-content-between'>
                              <div
                                className='lcs_circle d-flex align-items-center justify-content-center'>
                                <img src={OutlinedLike} className="cursor_pointer"
                                     onClick={() => handleLike(v)} alt=""/>
                              </div>
                              <div
                                className='lcs_circle d-flex align-items-center justify-content-center'>
                                <img src={OutlinedComment} className="cursor_pointer"
                                     alt=""
                                     onClick={() => submitComment("text", v, comment)}/>
                              </div>
                              {/* <div className='lcs_circle d-flex align-items-center justify-content-center'>
                                                                <img config={OutlinedShare} alt="" />
                                                            </div> */}
                            </div>

                          </div>


                        </div>

                      </div>
                    </div>

                  </div>
                  {
                    postId === v.id && commentsArr.commentArray && commentsArr.commentArray.map((curr, i) => {
                      {/* ${commentClicked === true ? "d-flex" : "d-none"} */
                      }
                      return <div className={`row m-0 px-3 pb-3 bg-white`} key={i}>
                        <div className='EventProfilePic_section py-3 p-0 evt_bottom_border'>

                          <div className='EventProfilePic'>
                            <img className='EventProfilePic_img'
                                 src={curr.user.profilePic ? curr.user.profilePic : "/static/png/Male.png"}
                                 alt=""/>
                          </div>

                        </div>

                        <div className='EventComments_post py-3 p-0 px-2 evt_bottom_border'>
                          <p className='mb-0 user_commented'
                             dangerouslySetInnerHTML={{__html: curr.comment}}></p>

                          {
                            curr.attachment ? <img width="300px" height="150px" className="my-2"
                                                   src={curr.attachment} alt=""/> : null
                          }
                          <p className='mb-0 mt-2 user_commented_like_cmnt'>
                                                        <span className="cursor_pointer"
                                                              onClick={() => handleLike(v.id, curr.id, v)}>{curr.reactionCount} Likes</span> &bull;
                            <span className="cursor_pointer" onClick={() => {
                              setShowRepliedCommentInput(true);
                              setShowRepliedCommentId(curr.id);
                            }}> {curr.repliesCount} Replies</span></p>
                        </div>

                        {showRepliedCommentId === curr.id && showRepliedCommentInput && <>
                          <div className='row m-0 mt-3'>
                            <div
                              className='col-12 p-0 ps-4 d-flex align-items-center justify-content-end'>

                              <div className="input_profile_img">
                                <img
                                  src={userDetails && userDetails.profilePic ? userDetails.profilePic : "/static/png/Male.png"}
                                  alt=""/>
                              </div>

                              <div className="d-flex" style={{width: '90%'}}>
                                <input type='text' className='comment_input ms-3 ps-4 pb-1'
                                       placeholder='Add a comment...'
                                       value={subComment}
                                       onChange={(event) => {
                                         setSubComment(event.target.value);
                                       }}
                                       onKeyDown={(event) => {
                                         if (event.key === "Enter") {
                                           submitComment("text", v, event.target.value, curr.id);
                                         }
                                       }}/>

                                <span
                                  className="input-group-text comment_input_capture d-flex align-items-center justify-content-around"
                                  id="basic-addon1"
                                >
                                                                    {/* <img className="" config={Smile} /> */}
                                  <input type='file' id="file-input" className="d-none"
                                         multiple
                                         onChange={(e) => submitComment("image", v, e.target.files[0])}/>
                                                                    <label htmlFor="file-input">
                                                                        <img className="ms-3" src={Photo_Video}/>
                                                                    </label>
                                                                </span>
                              </div>

                            </div>
                          </div>
                          <div className="d-flex justify-content-end align-items-end flex-column">
                            {curr.replies.map((v, i) => {

                              return <div className="d-flex align-items-center"
                                          style={{width: '93%'}} key={i}>
                                <div className='EventProfilePic_section py-3 p-0'>

                                  <div className='EventProfilePic'>
                                    <img className='EventProfilePic_img'
                                         src={v.user.profilePic ? v.user.profilePic : "/static/png/Male.png"}
                                         alt=""/>
                                  </div>


                                </div>

                                <div className='EventComments_post py-3 p-0 px-2'>
                                  <p className='mb-0 user_commented'
                                     dangerouslySetInnerHTML={{__html: v.comment}}></p>
                                </div>
                              </div>
                            })}
                          </div>
                        </>}

                      </div>
                    })

                  }
                </div>
              })}

            </div>
          </div>

        </div>
      </div>

      <LikeProfileModal isOpen={showUserLikedList} setShowUserLikedList={() => setShowUserLikedList(false)}
                        listOfLikeUsers={listOfLikeUsers}/>
    </LoggedInLayout>
  );
};

export default Friends;
