import { ADD } from './../../types/types';

export const userDetails = (state = [], action) => {
    switch (action.type) {
        case ADD:
            return {
                ...state,
                userDetails: action.payload
            }
        default: return state
    }

}