import React from 'react';
import '../../css/yourQuest.css';
import { useNavigate } from "react-router-dom";
import SideNav from '../../component/staticPages/SideNav';

import TimeExchangePocket from "../../assets/svg/TimeExchangePocket.svg";
import TimeExchangeEmoji from "../../assets/svg/TimeExchangeEmoji.svg";
import Dimond from "../../assets/svg/Dimond.svg";
import Journey_goal from "../../assets/svg/journey_goal.svg";
import Compass from "../../assets/compass.png";
import BalanceEmoji from "../../assets/BalanceEmoji.png";
import Leaderboard from "../../assets/Leaderboard.png";
import Invitation from "../../assets/Invitation.png";

const YourQuest = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className='row m-0 d-flex newsfeed_bg'>

                <div className='news_left p-0'>
                    <SideNav Journey={true} />
                </div>

                <div className='news_right p-5'>
                    <div className='row m-0'>
                        <div className='col p-0'>

                            <div className='row m-0'>

                                <div className='col-8 p-0'>
                                    <p className='mb-0 notification_heading'>Your Quests</p>
                                    <p className='mb-0 mt-3 journey_bredcrum '>Journey  <span className='quest_border'>Your Quest</span></p>

                                </div>
                                <div className='col-4 p-0 d-flex align-items-center justify-content-end'>
                                    <p className='mb-0 time_exchange_right_head text-right'> <img className='mb-1' src={TimeExchangePocket} alt=' ' /> 36H &nbsp; <span className='deivder_time_ex'>|</span> &nbsp; <img className='mb-1' src={TimeExchangeEmoji} alt=' ' /> 2400 WBX</p>
                                </div>

                            </div>

                            <div className='row m-0 mt-3'>
                                <div className='col p-0'>

                                    <select class="form-select Quests_select p-0" aria-label="Default select example">
                                        <option className='quest_option'>Quests</option>
                                        <option selected value="1" className='quest_option'>Ongoing Quests</option>
                                        <option value="2" className='quest_option'>Completed Quests</option>
                                        <option value="3" className='quest_option'>Liked Quests</option>
                                    </select>

                                </div>
                            </div>

                            <div className='row m-0 mt-3'>

                                <div className='col-5 p-0'>

                                    <div className='row m-0'>
                                        <div className='col-12 p-0'>

                                            <div className='ongoing_quest_big_box pt-3 pb-3'onClick={() => { navigate('/journey/quest') }}>

                                                <div className='row m-0'>
                                                    <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                        <div className='ong_que_end_line d-flex align-items-center justify-content-center'>
                                                            <p className='mb-0 ong_que_end_line_text'>Ends in 2 days</p>
                                                        </div>
                                                        <div className='ong_que_img_box'>

                                                        </div>

                                                    </div>
                                                    <div className='col-9 p-0 pt-2 pe-2'>

                                                        <div className='row m-0'>
                                                            <div className='col-9 p-0'>
                                                                <p className='mb-0 on_quest_big_box_heading'>Improve Yourself</p>
                                                            </div>
                                                            <div className='col-3 p-0 d-flex align-itmes-center justify-content-center'>
                                                                <p className='mb-0 on_quest_big_box_right'>500 &nbsp; <img src={Dimond} alt='' /></p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0'>
                                                            <div className='col-12 p-0 pe-4'>
                                                                <p className='mb-0 mt-2 on_quest_big_box_info_text'>This quest will help you to upgrade your well-being by having a conversation with friends and coaches. </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='row m-0 mt-2 ps-3 pe-3'>
                                                    <div className='col-12 p-0'>

                                                        <div className='row m-0'>
                                                            <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                                                <p className='mb-0 on_quest_big_box_percentage'>68% Complete</p>
                                                            </div>
                                                        </div>

                                                        <div class="progress on_quest_progress mt-2">
                                                            <div class="progress-bar on_quest_progress_bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row m-0'>
                                                <div className='col-12 p-0'>

                                                    <div className='ong_perent_sml_box  d-flex flex-column align-items-end justify-content-end'>

                                                        <div className='ongoing_quest_sml_box p-2 mt-2'>

                                                            <div className='row m-0'>
                                                                <div className='col-10 p-0 d-flex'>
                                                                    <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Spend 3 hours of 1-1 sessions</p>
                                                                </div>
                                                                <div className='col-2 p-0 d-flex align-items-center justify-content-end'>
                                                                    <p className='mb-0 journey_session_details'>0/3</p>

                                                                </div>
                                                            </div>

                                                        </div>


                                                        <div className='ongoing_quest_sml_box p-2 mt-2'>

                                                            <div className='row m-0'>
                                                                <div className='col-10 p-0 d-flex'>
                                                                    <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Attend 1 live events</p>
                                                                </div>
                                                                <div className='col-2 p-0 d-flex align-items-center justify-content-end'>
                                                                    <p className='mb-0 journey_session_details'>2/3</p>

                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='ongoing_quest_sml_box ongoing_quest_sml_box_deactive p-2 mt-2'>

                                                            <div className='row m-0'>
                                                                <div className='col-10 p-0 d-flex'>
                                                                    <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Messages 3 of your friends</p>
                                                                </div>
                                                                <div className='col-2 p-0 d-flex align-items-center justify-content-end'>
                                                                    <p className='mb-0 journey_session_details'>1/1</p>

                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className='ongoing_quest_sml_box ongoing_quest_sml_box_deactive p-2 mt-2'>

                                                            <div className='row m-0'>
                                                                <div className='col-10 p-0 d-flex'>
                                                                    <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Shares 3 events to your friends</p>
                                                                </div>
                                                                <div className='col-2 p-0 d-flex align-items-center justify-content-end'>
                                                                    <p className='mb-0 journey_session_details'>3/3</p>

                                                                </div>
                                                            </div>

                                                        </div>


                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                    <div className='row m-0 mt-4'>
                                        <div className='col-12 p-0'>

                                            <div className='ongoing_quest_big_box pt-3 pb-3'>

                                                <div className='row m-0'>
                                                    <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                        <div className='ong_que_end_line d-flex align-items-center justify-content-center'>
                                                            <p className='mb-0 ong_que_end_line_text'>Ends in 2 days</p>
                                                        </div>
                                                        <div className='ong_que_img_box'>

                                                        </div>

                                                    </div>
                                                    <div className='col-9 p-0 pt-2 pe-2'>

                                                        <div className='row m-0'>
                                                            <div className='col-9 p-0'>
                                                                <p className='mb-0 on_quest_big_box_heading'>Improve Yourself</p>
                                                            </div>
                                                            <div className='col-3 p-0 d-flex align-itmes-center justify-content-center'>
                                                                <p className='mb-0 on_quest_big_box_right'>1200 &nbsp; <img src={Dimond} alt='' /></p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0'>
                                                            <div className='col-12 p-0 pe-4'>
                                                                <p className='mb-0 mt-2 on_quest_big_box_info_text'>This quest will help you to upgrade your well-being by having a conversation with friends and coaches. </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='row m-0 mt-2 ps-3 pe-3'>
                                                    <div className='col-12 p-0'>

                                                        <div className='row m-0'>
                                                            <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                                                <p className='mb-0 on_quest_big_box_percentage'>68% Complete</p>
                                                            </div>
                                                        </div>

                                                        <div class="progress on_quest_progress mt-2">
                                                            <div class="progress-bar on_quest_progress_bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row m-0'>
                                                <div className='col-12 p-0'>

                                                    <div className='ong_perent_sml_box  d-flex flex-column align-items-end justify-content-end'>

                                                        <div className='ongoing_quest_sml_box p-2 mt-2'>

                                                            <div className='row m-0'>
                                                                <div className='col-10 p-0 d-flex'>
                                                                    <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Spend 3 hours of 1-1 sessions</p>
                                                                </div>
                                                                <div className='col-2 p-0 d-flex align-items-center justify-content-end'>
                                                                    <p className='mb-0 journey_session_details'>0/3</p>

                                                                </div>
                                                            </div>

                                                        </div>


                                                        <div className='ongoing_quest_sml_box p-2 mt-2'>

                                                            <div className='row m-0'>
                                                                <div className='col-10 p-0 d-flex'>
                                                                    <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Attend 1 live events</p>
                                                                </div>
                                                                <div className='col-2 p-0 d-flex align-items-center justify-content-end'>
                                                                    <p className='mb-0 journey_session_details'>2/3</p>

                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='ongoing_quest_sml_box ongoing_quest_sml_box_deactive p-2 mt-2'>

                                                            <div className='row m-0'>
                                                                <div className='col-10 p-0 d-flex'>
                                                                    <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Messages 3 of your friends</p>
                                                                </div>
                                                                <div className='col-2 p-0 d-flex align-items-center justify-content-end'>
                                                                    <p className='mb-0 journey_session_details'>1/1</p>

                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className='ongoing_quest_sml_box ongoing_quest_sml_box_deactive p-2 mt-2'>

                                                            <div className='row m-0'>
                                                                <div className='col-10 p-0 d-flex'>
                                                                    <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Shares 3 events to your friends</p>
                                                                </div>
                                                                <div className='col-2 p-0 d-flex align-items-center justify-content-end'>
                                                                    <p className='mb-0 journey_session_details'>3/3</p>

                                                                </div>
                                                            </div>

                                                        </div>


                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div className='col-7 p-0'>
                                    <div className='row m-0'>

                                        <div className='col-6 p-0'>

                                            <div className='journey_explore' onClick={() => { navigate('/journey/quests/explorequest') }}>

                                                <div className='row m-0 p-3'>
                                                    <div className='col-8 p-0  d-flex align-items-center'>
                                                        <p className='mb-0 journey_explore_detail'><img src={Compass} alt='' /> &nbsp; &nbsp; Explore</p>
                                                    </div>
                                                    <div className='col-4 p-0 d-flex align-items-center justify-content-center'>
                                                        <div className='journey_number_cunot d-flex align-items-center justify-content-center'>120</div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className='journey_explore mt-2'>

                                                <div className='row m-0 p-3'>
                                                    <div className='col-8 p-0  d-flex align-items-center'>
                                                        <p className='mb-0 journey_explore_detail'><img src={Invitation} alt='' /> &nbsp; &nbsp; Invitations</p>
                                                    </div>
                                                    <div className='col-4 p-0 d-flex align-items-center justify-content-center'>
                                                        <div className='journey_number_cunot d-flex align-items-center justify-content-center'>4</div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='journey_explore mt-2'  onClick={() => { navigate('/journey/leaderboard')}}>

                                                <div className='row m-0 p-3'>
                                                    <div className='col-8 p-0 d-flex align-items-center'>
                                                        <p className='mb-0 journey_explore_detail'><img src={Leaderboard} alt='' /> &nbsp; &nbsp; Leaderboards</p>
                                                    </div>
                                                    {/* <div className='col-4 p-0 d-flex align-items-center justify-content-center'>
                                                        <div className='journey_number_cunot d-flex align-items-center justify-content-center'>120</div>
                                                    </div> */}
                                                </div>

                                            </div>

                                        </div>

                                        <div className='col-6 p-0'>
                                            <div className='balance_details'>
                                                <div className='row m-0 pt-5'>
                                                    <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                                        <img src={BalanceEmoji} alt='' />
                                                    </div>
                                                </div>

                                                <div className='row m-0 pt-3 pb-3'>
                                                    <div className='col-12 p-0 d-flex flex-column align-items-center justify-content-center'>
                                                        <p className='mb-0 current_balance_details'>Your current balance is:</p>
                                                        <p className='mb-0 current_balance pt-1'>2,400 <span className='current_balance_light'> WBX </span></p>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>


                                    <div className='row m-0 mt-4'>
                                        <div className='col-12 p-0'>

                                            <div className='recent_complete'onClick={() => { navigate('/journey/leaderboard')}}>

                                                <div className='row m-0'>
                                                    <div className='col-12 p-0 p-3'>
                                                        <p className='recently_details'>Recently Completed</p>
                                                    </div>
                                                </div>


                                                <div className='ongoing_quest_big_box pt-2 pb-2'>

                                                    <div className='row m-0'>
                                                        <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                            <div className='ong_que_end_line ong_que_end_line_green d-flex align-items-center justify-content-center'>
                                                                <p className='mb-0 ong_que_end_line_text'>new</p>
                                                            </div>
                                                            <div className='ong_que_img_box'>

                                                            </div>

                                                        </div>
                                                        <div className='col-9 p-0 pt-2 pe-2'>

                                                            <div className='row m-0'>
                                                                <div className='col-9 p-0'>
                                                                    <p className='mb-0 on_quest_big_box_heading'>Improve Yourself</p>
                                                                </div>
                                                                <div className='col-3 p-0 d-flex align-itmes-center justify-content-center'>
                                                                    <p className='mb-0 on_quest_big_box_right'>500 &nbsp; <img src={TimeExchangeEmoji} alt='' /></p>
                                                                </div>
                                                            </div>

                                                            <div className='row m-0'>
                                                                <div className='col-12 p-0 pe-4'>
                                                                    <p className='mb-0 mt-2 on_quest_big_box_info_text'>This quest will help you to upgrade your well-being by having a conversation with friends and coaches. </p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className='row m-0 mt-2 ps-3 pe-3'>
                                                        <div className='col-12 p-0'>

                                                            <div className='row m-0'>
                                                                <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                                                    <p className='mb-0 on_quest_big_box_percentage'>68% Complete</p>
                                                                </div>
                                                            </div>

                                                            <div class="progress on_quest_progress mt-2">
                                                                <div class="progress-bar on_quest_progress_bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>



                                                <div className='ongoing_quest_big_box pt-2 pb-2 mt-2'>

                                                    <div className='row m-0'>
                                                        <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                            <div className='ong_que_end_line ong_que_end_line_blue d-flex align-items-center justify-content-center'>
                                                                <p className='mb-0 ong_que_end_line_text'>Completed</p>
                                                            </div>
                                                            <div className='ong_que_img_box'>

                                                            </div>

                                                        </div>
                                                        <div className='col-9 p-0 pt-2 pe-2'>

                                                            <div className='row m-0'>
                                                                <div className='col-9 p-0'>
                                                                    <p className='mb-0 on_quest_big_box_heading'>Improve Yourself</p>
                                                                </div>
                                                                <div className='col-3 p-0 d-flex align-itmes-center justify-content-center'>
                                                                    <p className='mb-0 on_quest_big_box_right'>500 &nbsp; <img src={TimeExchangeEmoji} alt='' /></p>
                                                                </div>
                                                            </div>

                                                            <div className='row m-0'>
                                                                <div className='col-12 p-0 pe-4'>
                                                                    <p className='mb-0 mt-2 on_quest_big_box_info_text'>This quest will help you to upgrade your well-being by having a conversation with friends and coaches. </p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className='row m-0 mt-2 ps-3 pe-3'>
                                                        <div className='col-12 p-0'>

                                                            <div className='row m-0'>
                                                                <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                                                    <p className='mb-0 on_quest_big_box_percentage'>68% Complete</p>
                                                                </div>
                                                            </div>

                                                            <div class="progress on_quest_progress mt-2">
                                                                <div class="progress-bar on_quest_progress_bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>




                                            </div>


                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default YourQuest;