import React, {useState} from 'react'
import SOSICON from '../../assets/sos_new_icon.png'
import {useSelector} from 'react-redux';
import _ from 'lodash'
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const SOS = () => {
  const [isOpen, setIsOpen] = useState(false)
  const handleModal = () => setIsOpen(!isOpen)
  const userDetailsFromState = useSelector(state => state.userDetails);
  return (
    (userDetailsFromState && !_.isEmpty(userDetailsFromState.userDetails)) ? (
      <div onClick={handleModal} style={{position: 'fixed', right: '2vw', bottom: '2vh'}}>
        <img src={SOSICON}/>

        <Modal isOpen={isOpen} toggle={handleModal} centered>
          <ModalHeader toggle={handleModal} className={"align-items-start border-0 pb-0"}>
            <h1 className={"tw-text-xl tw-font-medium"}>Are you in crisis?</h1>
          </ModalHeader>
          <ModalBody>
            <p className="text-center mb-3">
              If you are currently experiencing acute or volatile emotions, abuse,
              trauma please do dial the following helplines for immediate
              assistance:
            </p>
            <p className="text-center">
              Emergency Services: 995 <br/>
              Institute of Mental Health (24 HRS): +65 6389 2222 <br/> SOS (24
              HRS): 1-767
            </p>
          </ModalBody>
        </Modal>
      </div>
    ) : null

  )
}

export default SOS