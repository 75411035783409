/**
 *
 * @param {string} text
 * @param {(hashtag: string) => ReactNode} componentDecorator
 * @return {ReactNode}
 */
export function convertHashtagsToLinks(text, componentDecorator) {
  if (text.split(" ").length === 1) {
    if (text.startsWith("#")) {
      return componentDecorator(text)
    }
    return text;
  }

  return text.split(/#(\w+)/g).map((part, index) => {
    if (part.trim() && !part.trim().includes(' ')) {
      return componentDecorator(part);
    }
    return part;
  });
}