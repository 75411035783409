import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";

import SideNav from "../../component/staticPages/SideNav";
import _ from "lodash";
import "../../css/expert.css";
import Search from "../../assets/svg/Search.svg";
import FilterAtoZ from "../../assets/svg/FilterAtoZ.svg";
import ExpertsFilter from "../../assets/svg/ExpertsFilter.svg";
import GoldStar from "../../assets/svg/GoldStar.svg";
import Chat from "../../assets/svg/SideNav/Chat.svg";
import {bookSession, createChat, getBids, getExpertExTime, getListOfExperUsers,} from "../../utils/apiUrlsSetup";
import ResponsiveNav from "./../../component/staticPages/responsiveNavBar/responsiveNav";
import ExpertBookModal from "./expertBookModal";
import moment from "moment";
import Points from './../timeExchange/component/point';
import {useSelector} from 'react-redux';

function Expert1to1() {
  const [point, setPoints] = useState({});
  const date = new Date();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState([]);
  const [bids, setBids] = useState([]);
  const [selectedExpert, setSelectedExpert] = useState("all");

  const [responsiveView, inResponsiveView] = useState(false);

  const [selectedExpertData, setSelectedExpertData] = useState({});

  const [timeExchnageSlots, setTimeExchnageSlots] = useState([]);

  const [showBookForm, setShowBookForm] = useState(false);

  const [activeSlotTab, setActiveSlotTab] = useState("morning");

  const [activeTabMobile, seActiveTabMobile] = useState("browse_session");

  const [selectedSlotTime, setSelectedSlotTime] = useState("");

  const [value, onChange] = useState(new Date());

  const [showConfirmBookedModal, setShowConfirmBookedModal] = useState(false);

  const [showFilter, setShowFilter] = useState(false);

  const [slotFilterName, setSlotFilterName] = useState([]);

  const [exchangeDate, setExchangeDate] = useState(new Date());
  const [valueDate, setValueDate] = useState(
    `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(
      -2
    )}-${date.getDate()}`
  );

  const data = useSelector(state => state);

  useEffect(() => {
    if (_.isEmpty(data.points)) {
      setPoints({});
    } else {
      setPoints(data.points.points);
    }
  }, [data.points]);

  useEffect(() => {
    getListOfUsers(exchangeDate.toISOString());
  }, [exchangeDate]);

  const getListOfUsers = async (date) => {
    const result = await getListOfExperUsers(date);
    if (result.status === 200) {
      setUserData(result.data.data);
      const response = await getBids({userId: userDetails.id});
      if (response.status === 200) {
        let removeOfferIdData = response.data.data.oneToOneSessionArray.filter(
          (v) => !v.offerId
        );
        setBids(removeOfferIdData);
      }
    }
  };

  const getBookSessionDetails = async (data) => {
    const response = await getExpertExTime(data.user.id);
    if (response.status === 200) {
      setTimeExchnageSlots(response.data.data.totalAvailableSessions);
      setSelectedExpertData(data);
      setShowBookForm(true);
    }
  };

  const checkActiveSlotTab = (value) => {
    if (value === "morning") {
      return 0;
    } else if (value === "afternoon") {
      return 1;
    } else if (value === "evening") {
      return 2;
    } else {
      return 0;
    }
  };

  const confirmBookSession = async () => {

    let todayDate = new Date();
    let selectedDate = new Date(value);
    let isoDate = new Date(value).toISOString();

    let date = new Date(isoDate);

    // if (todayDate.getDate() !== selectedDate.getDate()) {
    //     date.setDate(date.getDate() + parseInt(1));
    // }

    let splitDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
    let finalStartDate = `${splitDate}T${('0' + selectedSlotTime).slice(-2)}:00:00.000Z`

    let obj = {
      instructorId: selectedExpertData.user.id,
      startDate: finalStartDate,
      timeSlot: selectedSlotTime,
      noOfHours: 1
    };
    let response = await bookSession(obj);
    if (response.status === 200) {
      setShowConfirmBookedModal(true);
    }
  };

  const closeConfirmBookedModal = async () => {
    setShowConfirmBookedModal(false);
    setShowBookForm(false);
    getListOfUsers(exchangeDate.toISOString());
  };

  function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM ' : ' PM '; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  const getAllSlots = (values) => {
    let morningSlots = [];
    let afternoonSlots = [];
    let eveningSlots = [];

    if (slotFilterName.length) {
      if (slotFilterName.includes("Morning")) {
        morningSlots = values[0] ? values[0].morning : [];
      } else {
        morningSlots = []
      }

      if (slotFilterName.includes("Afternoon")) {
        afternoonSlots = values[1] ? values[1].afternoon : [];
      } else {
        afternoonSlots = []
      }

      if (slotFilterName.includes("Night")) {
        eveningSlots = values[2] ? values[2].evening : [];
      } else {
        eveningSlots = []
      }
    } else {
      morningSlots = values[0] ? values[0].morning : [];
      afternoonSlots = values[1] ? values[1].afternoon : [];
      eveningSlots = values[2] ? values[2].evening : [];
    }


    let combineArr = [...morningSlots, ...afternoonSlots, ...eveningSlots];

    let newArr = combineArr.map(v => tConvert(`${('0' + v).slice(-2)}:00`))
    return newArr;
  };

  const handleFilter = (val) => {
    if (val.target.checked) {
      let arr = [...slotFilterName, val.target.value];
      setSlotFilterName(arr);
    } else {
      let arr = [...slotFilterName];
      let dataArray = arr.filter(v => v !== val.target.value);
      setSlotFilterName(dataArray);
    }
  }

  const checkExpertiseFilter = (value) => {
    if (value.expertiseHistory) {
      return value.expertiseHistory.expertise;
    }
  }

  const rediretToChatPage = async (id) => {
    let obj = {
      membersIdArray: [id],
    };
    let res = await createChat(obj);
    if (res.status === 200) {
      navigate("/chat", {state: id});
    }
  };

  return (
    <>
      <div className="row m-0 d-flex newsfeed_bg experts_bg_full_height expert_web_view">
        <div className="news_left p-0 d-none d-lg-block">
          <SideNav expert={true}/>
        </div>

        <div className="d-block d-lg-none p-0">
          <ResponsiveNav headerName="Expert" heading="1-to-1 Sessions"/>
        </div>

        <div className="news_right time_exchange_auto_height p-0">
          <div className="row m-0">
            <div className="col-12 p-5">
              <div className="row m-0 d-flex align-items-center d-none d-lg-flex">
                <div className="col-8 p-0">
                  <p className="mb-0 notification_heading">1-to-1 Sessions</p>
                </div>
                <Points point={point}/>
              </div>

              <div className="row m-0 mt-2 d-none d-lg-block">
                <div className="col-12 p-0 ps-1 d-flex">
                  <p className="mb-0 ulr_path_line new_ulr_path_line">
                                        <span
                                          className="cursor_pointer"
                                          onClick={() => navigate("/supporters")}
                                        >
                                            Supporters
                                        </span>{" "}>{" "}
                    <u>1-to-1 Session</u>
                  </p>
                </div>
              </div>

              <div className="row m-0 mt-4">
                <div className="col-lg-5 col-md-12  p-0">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control all_exp_page_lg_input"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search supporters"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                    <span
                      class="input-group-text all_exp_page_lg_span"
                      id="basic-addon2"
                    >
                                            <img src={Search} alt=""/>
                                        </span>
                  </div>
                </div>
              </div>

              <div className="row m-0 mt-4">
                <div className="col-9 col-lg-9 col-md-12 p-0">
                  <div className="row m-0">
                    <div className="col-11 p-0 ps-2 pt-2 pb-2 d-flex align-itmes-center experts_event_by_overflow">
                      <button
                        className={`ps-3 pe-3 pt-2 pb-2 me-2 experts_filter_by_event ${selectedExpert === "all"
                          ? "active_experts_filter_by_event"
                          : ""
                        }`}
                        onClick={() => setSelectedExpert("all")}
                      >
                        All
                      </button>
                      <button
                        className={`ps-3 pe-3 pt-2 pb-2 me-2 experts_filter_by_event ${selectedExpert === "Coach"
                          ? "active_experts_filter_by_event"
                          : ""
                        }`}
                        onClick={() => setSelectedExpert("Coach")}
                      >
                        {" "}
                        Coach
                      </button>
                      <button
                        className={`ps-3 pe-3 pt-2 pb-2 me-2 experts_filter_by_event ${selectedExpert === "Counsellor"
                          ? "active_experts_filter_by_event"
                          : ""
                        }`}
                        onClick={() => setSelectedExpert("Counsellor")}
                      >
                        {" "}
                        Counsellor
                      </button>
                      <button
                        className={`ps-3 pe-3 pt-2 pb-2 me-2 experts_filter_by_event ${selectedExpert === "Peer Supporter"
                          ? "active_experts_filter_by_event"
                          : ""
                        }`}
                        onClick={() => setSelectedExpert("Peer Supporter")}
                      >
                        {" "}
                        Peer Supporter
                      </button>
                      <button
                        className={`ps-3 pe-3 pt-2 pb-2 me-2 experts_filter_by_event ${selectedExpert === "Therapist"
                          ? "active_experts_filter_by_event"
                          : ""
                        }`}
                        onClick={() => setSelectedExpert("Therapist")}
                      >
                        {" "}
                        Therapist
                      </button>
                      <button
                        className={`ps-3 pe-3 pt-2 pb-2 me-2 experts_filter_by_event ${selectedExpert === "Trainer"
                          ? "active_experts_filter_by_event"
                          : ""
                        }`}
                        onClick={() => setSelectedExpert("Trainer")}
                      >
                        Trainer
                      </button>
                      {/* <button
                                                className={`ps-3 pe-3 pt-2 pb-2 me-2 experts_filter_by_event ${selectedExpert === "Psychologist"
                                                    ? "active_experts_filter_by_event"
                                                    : ""
                                                    }`}
                                                onClick={() => setSelectedExpert("Psychologist")}
                                            >
                                                Psychologist
                                            </button> */}
                    </div>
                  </div>

                  <div className="row m-0 mt-4">
                    <div className="col-11 p-0">
                      <div className="row m-0 d-flex aline-items-center justify-content-between">
                        <div className="col-2 p-0">
                          <p className="mb-0 experts_filtered_result">
                            {userData.filter((val) => selectedExpert === "all" ? val : selectedExpert === checkExpertiseFilter(val)).length} Results
                          </p>
                        </div>
                        <div className="col-2 p-0 d-flex align-items-center justify-content-end">
                          <button
                            className="experts_filter_az_btns me-2 d-flex align-items-center justify-content-center">
                            {" "}
                            <img src={FilterAtoZ} alt=""/>{" "}
                          </button>

                          <div class="dropdown">
                            <button
                              className="all_exe_sort_filter ps-2 pe-2 d-flex align-items-center justify-content-between dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Filter{" "}
                              <img
                                className="ms-2"
                                src={ExpertsFilter}
                                alt=""
                              />{" "}
                            </button>
                            <div
                              className={`filter_dropdown filter_dropdown_exchange dropdown-menu p-4 ${showFilter ? "show" : ""
                              }`}
                              aria-labelledby=""
                            >
                              <div className="row m-0">
                                <div className="col-12 p-0">
                                  <p className="mb-0 dropdown_menu_heading">
                                    Date
                                  </p>
                                </div>
                              </div>

                              <input
                                type="date"
                                onChange={(e) => {
                                  setValueDate(e.target.value);
                                  let date = new Date(e.target.value);
                                  setExchangeDate(date);
                                }}
                                value={valueDate}
                              />

                              <div className="row m-0 mt-2">
                                <div className="col-12 p-0">
                                  <p className="mb-0 dropdown_menu_heading">
                                    Available Hours
                                  </p>
                                </div>
                              </div>

                              <div className="checkboxes mt-1 ">
                                <div className="row m-0">
                                  {["Morning", "Afternoon", "Night"].map(
                                    (availability, i) => {
                                      return (
                                        <div className="col-4 p-0" key={i}>
                                          <div class="form-check d-flex align-items-center">
                                            <input
                                              class="form-check-input filter_sub_check_box me-1"
                                              type="checkbox"
                                              value={availability}
                                              id="flexCheckDefault"
                                              onClick={(event) => handleFilter(event, 'availability')}
                                              checked={slotFilterName.includes(availability)}
                                            />
                                            <label
                                              class="form-check-label filter_sub_check_text mt-1"
                                              for="flexCheckDefault"
                                            >
                                              {availability}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {!userData
                    .filter((u) =>
                      search
                        ? (u.user.firstName + " " + u.user.lastName)
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) >= 0
                        : true
                    )
                    .filter((val) =>
                      selectedExpert === "all"
                        ? val
                        : selectedExpert === checkExpertiseFilter(val)
                    ).length ? (
                    <p className="text-center mt-5">No result found</p>
                  ) : null}

                  <div className="row m-0 mt-4">
                    {(userData || [])
                      .filter((val) =>
                        selectedExpert === "all"
                          ? val
                          : selectedExpert === checkExpertiseFilter(val)
                      )
                      .filter((u) =>
                        search
                          ? (u.user.firstName + " " + u.user.lastName)
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) >= 0
                          : true
                      )
                      .map((v, i) => {
                        return (
                          <div
                            className="experts_flow_card me-5 p-4 mb-4"
                            key={i}
                          >
                            <div className="row m-0">
                              <div className="col-3 p-0">
                                <div className="experts_flow_card_profile_img">
                                  <img
                                    src={
                                      v.user.profilePic
                                        ? v.user.profilePic
                                        : "/static/png/Male.png"
                                    }
                                    alt=""
                                  />
                                </div>


                              </div>

                              <div className="col-6 p-0">
                                <div className="row m-0">
                                  <div className="col-12 p-0">
                                    <p
                                      className="mb-0 expert_flow_user_name cursor_pointer"
                                      onClick={() => {
                                        navigate('/supporters/supporter-search', {
                                          state: {
                                            userDetails: v,
                                            expertSession: true
                                          }
                                        })
                                      }}
                                    >
                                      {v.user.firstName} {v.user.lastName}
                                    </p>
                                  </div>
                                </div>

                                <div className="row m-0 mt-1">
                                  <div className="col-4 p-0 brd_left">
                                    <p className="mb-0 expert_flow_light_user_name">
                                      {checkExpertiseFilter(v)}
                                    </p>
                                  </div>
                                  <div className="col-3 p-0 brd_left d-flex align-items-center justify-content-center">
                                    <p className="mb-0 expert_flow_ratings">
                                      {" "}
                                      <img src={GoldStar} className="me-1" alt=""/>{v.user.avgRatingOfSessions}
                                    </p>
                                  </div>
                                  <div className="col-5 p-0 d-flex align-items-center justify-content-center">
                                    <p className="mb-0 expert_flow_sessions">
                                      {v.totalSessionCount} sessions
                                    </p>
                                  </div>
                                </div>

                                {/* <div className="row m-0">
                                                            <div className="col-12 p-0">
                                                                <button className="experts_flox_inside_eve me-2">Learning</button>
                                                                <button className="experts_flox_inside_eve experts_flox_inside_eve_notice">Noticing</button>
                                                            </div>
                                                        </div> */}
                              </div>

                              {v.user.id !== userDetails.id ? <div className="col-3 p-0 d-flex justify-content-end">
                                <button
                                  className="book_an_slot"
                                  data-bs-toggle="modal"
                                  data-bs-target="#slotBookModal"
                                  onClick={() => {
                                    inResponsiveView(false);
                                    getBookSessionDetails(v);
                                  }}
                                >
                                  Book
                                </button>
                              </div> : null}

                            </div>

                            <div className="">
                              <p className="mb-0 mt-2 experts_flow_day_count">
                                {moment(exchangeDate).format("dddd, MMMM DD")}
                              </p>
                            </div>

                            <div className="row m-0 mt-1">
                              <div className="col-12 p-0 overflow_auto_slots">
                                {getAllSlots(v.slots).map((v, i) => {
                                  return <button key={i} className="experts_flox_time_sched me-2">
                                    {v}
                                  </button>
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      })}

                    {/* <div className="experts_flow_card_section p-0 d-flex">

                                            <div className="experts_flow_card me-5 p-4">

                                               <div className="row m-0">

                                                    <div className="col-3 p-0">

                                                        <div className="experts_flow_card_profile_img" >
                                                            <img config={ExpertCardPic} alt="" />
                                                        </div>

                                                        <div className="" >
                                                            <p className="mb-0 mt-2 experts_flow_day_count">Mon - Fri</p>
                                                        </div>

                                                    </div>

                                                    <div className="col-5 p-0">

                                                        <div className="row m-0">
                                                            <div className="col-12 p-0">
                                                                <p className="mb-0 expert_flow_user_name">Syafiqah Salim</p>
                                                            </div>
                                                        </div>

                                                        <div className="row m-0 mt-1">
                                                            <div className="col-4 p-0 brd_left">
                                                                <p className="mb-0 expert_flow_light_user_name">Therapist</p>
                                                            </div>
                                                            <div className="col-3 p-0 brd_left d-flex align-items-center justify-content-center">
                                                                <p className="mb-0 expert_flow_ratings"> <img config={GoldStar} alt="" /> 4.8</p>
                                                            </div>
                                                            <div className="col-5 p-0 d-flex align-items-center justify-content-center">
                                                                <p className="mb-0 expert_flow_sessions">40 sessions</p>
                                                            </div>
                                                        </div>

                                                        <div className="row m-0">
                                                            <div className="col-12 p-0">
                                                                <button className="experts_flox_inside_eve me-2">Learning</button>
                                                                <button className="experts_flox_inside_eve experts_flox_inside_eve_notice">Noticing</button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="col-4 p-0 d-flex justify-content-end">
                                                        <button className="book_an_slot" data-bs-toggle="modal" data-bs-target="#slotBookModal">Book</button>
                                                    </div>

                                                </div>

                                                <div className="row m-0 mt-1">
                                                    <div className="col-12 p-0">
                                                        <button className="experts_flox_time_sched me-2">09:00 AM</button>
                                                        <button className="experts_flox_time_sched me-2">10:00 AM</button>
                                                        <button className="experts_flox_time_sched me-2">02:00 AM</button>
                                                        <button className="experts_flox_time_sched me-2">04:00 AM</button>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="experts_flow_card p-4">

                                                <div className="row m-0">

                                                    <div className="col-3 p-0">

                                                        <div className="experts_flow_card_profile_img" >
                                                            <img config={ExpertCardPic} alt="" />
                                                        </div>

                                                        <div className="" >
                                                            <p className="mb-0 mt-2 experts_flow_day_count">Mon - Fri</p>
                                                        </div>

                                                    </div>

                                                    <div className="col-5 p-0">

                                                        <div className="row m-0">
                                                            <div className="col-12 p-0">
                                                                <p className="mb-0 expert_flow_user_name">Syafiqah Salim</p>
                                                            </div>
                                                        </div>

                                                        <div className="row m-0 mt-1">
                                                            <div className="col-4 p-0 brd_left">
                                                                <p className="mb-0 expert_flow_light_user_name">Therapist</p>
                                                            </div>
                                                            <div className="col-3 p-0 brd_left d-flex align-items-center justify-content-center">
                                                                <p className="mb-0 expert_flow_ratings"> <img config={GoldStar} alt="" /> 4.8</p>
                                                            </div>
                                                            <div className="col-5 p-0 d-flex align-items-center justify-content-center">
                                                                <p className="mb-0 expert_flow_sessions">40 sessions</p>
                                                            </div>
                                                        </div>

                                                        <div className="row m-0">
                                                            <div className="col-12 p-0">
                                                                <button className="experts_flox_inside_eve me-2">Learning</button>
                                                                <button className="experts_flox_inside_eve experts_flox_inside_eve_notice">Noticing</button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="col-4 p-0 d-flex justify-content-end">
                                                        <button className="book_an_slot">Book</button>
                                                    </div>

                                                </div>

                                                <div className="row m-0 mt-1">
                                                    <div className="col-12 p-0">
                                                        <button className="experts_flox_time_sched me-2">09:00 AM</button>
                                                        <button className="experts_flox_time_sched me-2">10:00 AM</button>
                                                        <button className="experts_flox_time_sched me-2">02:00 AM</button>
                                                        <button className="experts_flox_time_sched me-2">04:00 AM</button>
                                                    </div>
                                                </div>

                                            </div>

                                        </div> */}
                  </div>
                </div>

                <div className="col-3 p-0 ps-2 pe-2 expert_scrollable d-none d-lg-block">
                  <div className="row m-0">
                    <div className="col-10 p-0">
                      <p className="mb-0 session_req_status_bold">Rejected</p>
                    </div>

                    <div className="col-2 p-0 d-flex align-items-center justify-content-end">
                      <div className="slot_req_status_box d-flex align-items-center justify-content-center p-1">
                        <p className="mb-0 slote_activity_count">
                          {
                            (bids || []).filter((v) => v.status === "rejected")
                              .length
                          }
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 mt-4">
                    <div className="col-12 p-0">
                      {!bids.filter((v) => v.status === "rejected").length ? (
                        <p className="text-center my-4">No Result Found</p>
                      ) : null}

                      {(bids || [])
                        .filter((v) => v.status === "rejected")
                        .map((v, i) => {
                          return (
                            <div className="flow_right_cards p-2 mb-3" key={i}>
                              <div className="row m-0 align-items-center">
                                <div className="col-10 m-0">
                                  <p className="mb-0 flow_right_cards_bold_text">
                                    {v.user.firstName} {v.user.lastName}
                                  </p>
                                  <p className="mb-0 mt-1 flow_right_cards_light_text">

                                    {v.timeSlot}:00 {v.timeSlot < 12 ? 'AM' : 'PM'} - {v.timeSlot + 1}:00 {(v.timeSlot + 1) < 12 ? 'AM' : 'PM'}
                                  </p>
                                  {/* <button className="p-0 flow_right_cards_btn"> <img config={ESMBrain} alt="" /> {v.offer.category}</button> */}
                                </div>
                                <div className="col-2 m-0 d-flex align-items-center justify-content-center">
                                  {/* <img config={SloteSchedule} alt="" /> */}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <div className="row m-0 mt-5">
                    <div className="col-10 p-0">
                      <p className="mb-0 session_req_status_bold">Pending</p>
                    </div>

                    <div className="col-2 p-0 d-flex align-items-center justify-content-end">
                      <div className="slot_req_status_box d-flex align-items-center justify-content-center p-1">
                        <p className="mb-0 slote_activity_count">
                          {
                            (bids || []).filter((v) => v.status === "pending")
                              .length
                          }
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 mt-4">
                    <div className="col-12 p-0">
                      {!bids.filter((v) => v.status === "pending").length ? (
                        <p className="text-center my-4">No Result Found</p>
                      ) : null}

                      {(bids || [])
                        .filter((v) => v.status === "pending")
                        .map((v, i) => {
                          return (
                            <div className="flow_right_cards p-2 mb-3" key={i}>
                              <div className="row m-0 align-items-center">
                                <div className="col-10 m-0">
                                  <p className="mb-0 flow_right_cards_bold_text">
                                    {v.user.firstName} {v.user.lastName}
                                  </p>
                                  <p className="mb-0 mt-1 flow_right_cards_light_text">
                                    {v.timeSlot}:00 {v.timeSlot < 12 ? 'AM' : 'PM'} - {v.timeSlot + 1}:00 {(v.timeSlot + 1) < 12 ? 'AM' : 'PM'}
                                  </p>
                                  {/* <button className="p-0 flow_right_cards_btn"> <img config={ESMBrain} alt="" /> {v.offer.category}</button> */}
                                </div>
                                <div className="col-2 m-0 d-flex align-items-center justify-content-center">
                                  <img className="cursor_pointer" src={Chat} onClick={() =>
                                    rediretToChatPage(v.userId)
                                  } alt=""/>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <div className="row m-0 mt-5">
                    <div className="col-10 p-0">
                      <p className="mb-0 session_req_status_bold">Confirmed</p>
                    </div>

                    <div className="col-2 p-0 d-flex align-items-center justify-content-end">
                      <div className="slot_req_status_box d-flex align-items-center justify-content-center p-1">
                        <p className="mb-0 slote_activity_count">
                          {
                            (bids || []).filter((v) => v.status === "confirmed")
                              .length
                          }
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 mt-4">
                    <div className="col-12 p-0">
                      {!bids.filter((v) => v.status === "confirmed").length ? (
                        <p className="text-center my-4">No Result Found</p>
                      ) : null}
                      {(bids || [])
                        .filter((v) => v.status === "confirmed")
                        .map((v, i) => {
                          return (
                            <div className="flow_right_cards p-2 mb-3" key={i}>
                              <div className="row m-0 align-items-center">
                                <div className="col-10 m-0">
                                  <p className="mb-0 flow_right_cards_bold_text">
                                    {v.user.firstName} {v.user.lastName}
                                  </p>
                                  <p className="mb-0 mt-1 flow_right_cards_light_text">
                                    {v.timeSlot}:00 {v.timeSlot < 12 ? 'AM' : 'PM'} - {v.timeSlot + 1}:00 {(v.timeSlot + 1) < 12 ? 'AM' : 'PM'}
                                  </p>
                                  {/* <button className="p-0 flow_right_cards_btn"> <img config={ESMBrain} alt="" /> {v.offer.category}</button> */}
                                </div>
                                <div className="col-2 m-0 d-flex align-items-center justify-content-center">
                                  <img className="cursor_pointer" src={Chat} onClick={() =>
                                    rediretToChatPage(v.userId)
                                  } alt=""/>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        <div className="row m-0 experts_mobile_view">
          <div className="col-12 p-0 expert_background">
            {/* Expert Home */}

            {/* Expert Home */}

            {/* Expert 1-1 Session */}

            <ResponsiveNav headerName="Expert" heading="1-to-1 Sessions"/>

            <div className="row m-0">
              <div className="col-12 p-4">
                <div className="row m-0">
                  <div className="col-md-2 col-0"></div>
                  <div className="col-12 col-md-8 p-0 d-flex align-items-center justify-content-center">
                    <div className="expert_sesion_slider mb-3 p-1">
                      <button
                        className={`expert_sesion_slider_btn ${activeTabMobile === "browse_session" ? "active" : ""
                        }`}
                        onClick={() => seActiveTabMobile("browse_session")}
                      >
                        Browse Sessions
                      </button>
                      <button
                        className={`expert_sesion_slider_btn ${activeTabMobile === "your_session" ? "active" : ""
                        }`}
                        onClick={() => seActiveTabMobile("your_session")}
                      >
                        Your Sessions
                      </button>
                    </div>
                  </div>
                  <div className="col-md-2 col-0"></div>
                </div>

                {activeTabMobile === "browse_session" ? (
                  <>
                    <div className="row m-0 mt-2">
                      <div className="col-12 p-0">
                        <div class="input-group huddle_top_input">
                          <input
                            type="text"
                            class="form-control all_exp_page_lg_input"
                            placeholder="Search supporters"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <span
                            class="input-group-text all_exp_page_lg_span"
                            id="basic-addon2"
                          >
                                                        <img src={Search} alt=""/>
                                                    </span>
                        </div>
                      </div>
                    </div>

                    <div className="row m-0 mt-2">
                      <div className="col-12 p-0 ps-2 pt-2 pb-1 d-flex align-itmes-center experts_event_by_overflow">
                        <button
                          className={`ps-3 pe-3 pt-2 pb-2 me-2 experts_filter_by_event ${selectedExpert === "all"
                            ? "active_experts_filter_by_event"
                            : ""
                          }`}
                          onClick={() => setSelectedExpert("all")}
                        >
                          All
                        </button>
                        <button
                          className={`ps-3 pe-3 pt-2 pb-2 me-2 experts_filter_by_event ${selectedExpert === "Coach"
                            ? "active_experts_filter_by_event"
                            : ""
                          }`}
                          onClick={() => setSelectedExpert("Coach")}
                        >
                          {" "}
                          Coach
                        </button>
                        <button
                          className={`ps-3 pe-3 pt-2 pb-2 me-2 experts_filter_by_event ${selectedExpert === "Counsellor"
                            ? "active_experts_filter_by_event"
                            : ""
                          }`}
                          onClick={() => setSelectedExpert("Counsellor")}
                        >
                          {" "}
                          Counsellor
                        </button>
                        <button
                          className={`ps-3 pe-3 pt-2 pb-2 me-2 experts_filter_by_event ${selectedExpert === "Peer Supporter"
                            ? "active_experts_filter_by_event"
                            : ""
                          }`}
                          onClick={() => setSelectedExpert("Peer Supporter")}
                        >
                          {" "}
                          Peer Supporter
                        </button>
                        <button
                          className={`ps-3 pe-3 pt-2 pb-2 me-2 experts_filter_by_event ${selectedExpert === "Therapist"
                            ? "active_experts_filter_by_event"
                            : ""
                          }`}
                          onClick={() => setSelectedExpert("Therapist")}
                        >
                          {" "}
                          Therapist
                        </button>
                        <button
                          className={`ps-3 pe-3 pt-2 pb-2 me-2 experts_filter_by_event ${selectedExpert === "Trainer"
                            ? "active_experts_filter_by_event"
                            : ""
                          }`}
                          onClick={() => setSelectedExpert("Trainer")}
                        >
                          Trainer
                        </button>
                        {/* <button
                                                    className={`ps-3 pe-3 pt-2 pb-2 me-2 experts_filter_by_event ${selectedExpert === "Psychologist"
                                                        ? "active_experts_filter_by_event"
                                                        : ""
                                                        }`}
                                                    onClick={() => setSelectedExpert("Psychologist")}
                                                >
                                                    Psychologist
                                                </button> */}
                      </div>
                    </div>

                    <div className="row m-0 justify-content-end mb-3">
                      <div class="dropdown p-0">
                        <button
                          className="all_exe_sort_filter ps-2 pe-2 d-flex align-items-center justify-content-between dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Filter{" "}
                          <img
                            className="ms-2"
                            src={ExpertsFilter}
                            alt=""
                          />{" "}
                        </button>
                        <div
                          className={`filter_dropdown filter_dropdown_exchange dropdown-menu p-4 ${showFilter ? "show" : ""
                          }`}
                          aria-labelledby=""
                        >
                          <div className="row m-0">
                            <div className="col-12 p-0">
                              <p className="mb-0 dropdown_menu_heading">
                                Date
                              </p>
                            </div>
                          </div>

                          <input
                            type="date"
                            onChange={(e) => {
                              setValueDate(e.target.value);
                              let date = new Date(e.target.value);
                              setExchangeDate(date);
                            }}
                            value={valueDate}
                          />

                          <div className="row m-0 mt-2">
                            <div className="col-12 p-0">
                              <p className="mb-0 dropdown_menu_heading">
                                Available Hours
                              </p>
                            </div>
                          </div>

                          <div className="checkboxes mt-1 ">
                            <div className="row m-0">
                              {["Morning", "Afternoon", "Night"].map(
                                (availability, i) => {
                                  return (
                                    <div className="col-4 p-0" key={i}>
                                      <div class="form-check d-flex align-items-center">
                                        <input
                                          class="form-check-input filter_sub_check_box me-1"
                                          type="checkbox"
                                          value={availability}
                                          id="flexCheckDefault"
                                          onClick={(event) => handleFilter(event, 'availability')}
                                          checked={slotFilterName.includes(availability)}
                                        />
                                        <label
                                          class="form-check-label filter_sub_check_text mt-1"
                                          for="flexCheckDefault"
                                        >
                                          {availability}
                                        </label>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {!userData
                      .filter((u) =>
                        search
                          ? (u.user.firstName + " " + u.user.lastName)
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) >= 0
                          : true
                      )
                      .filter((val) =>
                        selectedExpert === "all"
                          ? val
                          : selectedExpert === checkExpertiseFilter(val)
                      ).length ? (
                      <p className="text-center mt-5">No result found</p>
                    ) : null}

                    <div className="row m-0 card_test_row">
                      <div className="col-12 p-0">
                        <div className="row m-0 d-flex">
                          <div className="col-12 col-md-6 tab_card_parent">
                            {(userData || [])
                              .filter((val) =>
                                selectedExpert === "all"
                                  ? val
                                  : selectedExpert === checkExpertiseFilter(val)
                              )
                              .filter((u) =>
                                search
                                  ? (u.user.firstName + " " + u.user.lastName)
                                  .toLowerCase()
                                  .indexOf(search.toLowerCase()) >= 0
                                  : true
                              )
                              .map((v, i) => {
                                return (


                                  <div className="experts_flow_card me-md-2 p-4 mb-4" key={i}>
                                    <div className="row m-0">
                                      <div className="col-4 p-0">
                                        <div className="experts_flow_card_profile_img">
                                          <img
                                            src={
                                              v.user.profilePic
                                                ? v.user.profilePic
                                                : "/static/png/Male.png"
                                            }
                                            alt=""
                                          />
                                        </div>

                                        <div className="">
                                          <p className="mb-0 mt-2 experts_flow_day_count">
                                            {moment(exchangeDate).format("dddd, MMMM DD")}
                                          </p>
                                        </div>
                                      </div>

                                      <div className="col-6 p-0 d-flex flex-column">
                                        <div className="row m-0">
                                          <div className="col-12 p-0">
                                            <p className="mb-0 expert_flow_user_name" onClick={() => {
                                              navigate('/supporters/supporter-search', {
                                                state: {
                                                  userDetails: v,
                                                  expertSession: true
                                                }
                                              })
                                            }}>
                                              {v.user.firstName} {v.user.lastName}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="row m-0 mt-1 d-none d-md-flex">
                                          <div className="col-4 p-0 brd_left">
                                            <p className="mb-0 expert_flow_light_user_name">
                                              {checkExpertiseFilter(v)}
                                            </p>
                                          </div>
                                          <div
                                            className="col-3 p-0 brd_left d-flex align-items-center justify-content-center">
                                            <p className="mb-0 expert_flow_ratings">
                                              {" "}
                                              <img src={GoldStar} alt=""/> 4.8
                                            </p>
                                          </div>
                                          <div className="col-5 p-0 d-flex align-items-center justify-content-center">
                                            <p className="mb-0 expert_flow_sessions">
                                              {v.totalSessionCount} sessions
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      {v.user.id !== userDetails.id ?
                                        <div className="col-2 p-0 d-flex justify-content-end">
                                          <button
                                            className="book_an_slot"
                                            data-bs-toggle="modal"
                                            data-bs-target="#slotBookModal"
                                            onClick={() => {
                                              inResponsiveView(true);
                                              getBookSessionDetails(v);
                                            }}
                                          >
                                            Book
                                          </button>
                                        </div> : null}


                                    </div>

                                    {/* <div className="row m-0">
                                                        <div className="col-12 p-0">
                                                            <button className="experts_flox_inside_eve me-2">Learning</button>
                                                            <button className="experts_flox_inside_eve experts_flox_inside_eve_notice">Noticing</button>
                                                        </div>
                                                    </div> */}
                                    <div className="row m-0 mt-1 d-md-none">
                                      <div className="col-4 p-0 brd_left">
                                        <p className="mb-0 expert_flow_light_user_name">
                                          {checkExpertiseFilter(v)}
                                        </p>
                                      </div>
                                      <div
                                        className="col-3 p-0 brd_left d-flex align-items-center justify-content-center">
                                        <p className="mb-0 expert_flow_ratings">
                                          {" "}
                                          <img src={GoldStar} alt=""/> 4.8
                                        </p>
                                      </div>
                                      <div className="col-5 p-0 d-flex align-items-center justify-content-center">
                                        <p className="mb-0 expert_flow_sessions">
                                          {v.totalSessionCount} sessions
                                        </p>
                                      </div>
                                    </div>

                                    <div className="row m-0 mt-1">
                                      <div className="col-12 p-0 overflow_auto_slots">
                                        {getAllSlots(v.slots).map((v, i) => {
                                          return <button key={i} className="experts_flox_time_sched me-2">
                                            {v}
                                          </button>
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row m-0">
                      <div className="col-12">
                        <div className="row m-0 mt-4 mb-3">
                          <div className="col-12 p-0">
                            <div className="row m-0 mb-2">
                              <div className="col-10 p-0">
                                <p className="mb-0 session_req_status_bold">
                                  Rejected
                                </p>
                              </div>

                              <div className="col-2 p-0 pe-2 d-flex align-items-center justify-content-end">
                                <div
                                  className="slot_req_status_box d-flex align-items-center justify-content-center p-1 px-2">
                                  <p className="mb-0 slote_activity_count">
                                    {
                                      (bids || []).filter(
                                        (v) => v.status === "rejected"
                                      ).length
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>

                            {!bids.filter((v) => v.status === "rejected")
                              .length ? (
                              <p className="text-center my-4">
                                No Result Found
                              </p>
                            ) : null}

                            {(bids || [])
                              .filter((v) => v.status === "rejected")
                              .map((v, i) => {
                                return (
                                  <div className="flow_right_cards p-0 py-3 px-2 mb-3" key={i}>
                                    <div className="row m-0">
                                      {/* <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>
                                                                    <img config={Oneto1Brain} alt="" />
                                                                    <button className="p-0 mt-1 flow_right_cards_btn">{v.offer.category}</button>
                                                                </div> */}

                                      <div className="col-6 p-0 ps-2 d-flex flex-column justify-content-center">
                                        <p className="mb-0 flow_right_cards_bold_text">
                                          {v.user.firstName} {v.user.lastName}
                                        </p>
                                        <p className="mb-0 mt-1 flow_right_cards_light_text">
                                          {v.timeSlot}:00 {v.timeSlot < 12 ? 'AM' : 'PM'} - {v.timeSlot + 1}:00 {(v.timeSlot + 1) < 12 ? 'AM' : 'PM'}
                                        </p>
                                      </div>

                                      <div className="col-6 p-0 d-flex align-items-center justify-content-end">
                                        {/* <img
                                                                                    className="slot_sch"
                                                                                    config={SloteSchedule}
                                                                                    alt=""
                                                                                /> */}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}

                            <div className="row m-0 mt-4 mb-3">
                              <div className="col-10 p-0">
                                <p className="mb-0 session_req_status_bold">
                                  Pending
                                </p>
                              </div>

                              <div className="col-2 p-0 pe-2 d-flex align-items-center justify-content-end">
                                <div
                                  className="slot_req_status_box d-flex align-items-center justify-content-center p-1 px-2">
                                  <p className="mb-0 slote_activity_count">
                                    {
                                      (bids || []).filter(
                                        (v) => v.status === "pending"
                                      ).length
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>

                            {!bids.filter((v) => v.status === "pending")
                              .length ? (
                              <p className="text-center my-4">
                                No Result Found
                              </p>
                            ) : null}

                            {(bids || [])
                              .filter((v) => v.status === "pending")
                              .map((v, i) => {
                                return (
                                  <div className="flow_right_cards p-0 py-3 px-2 mb-3" key={i}>
                                    <div className="row m-0">
                                      {/* <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>
                                                                    <img config={Oneto1Brain} alt="" />
                                                                    <button className="p-0 mt-1 flow_right_cards_btn">{v.offer.category}</button>
                                                                </div> */}

                                      <div className="col-6 p-0 ps-2 d-flex flex-column justify-content-center">
                                        <p className="mb-0 flow_right_cards_bold_text">
                                          {v.user.firstName} {v.user.lastName}
                                        </p>
                                        <p className="mb-0 mt-1 flow_right_cards_light_text">
                                          {v.timeSlot}:00 {v.timeSlot < 12 ? 'AM' : 'PM'} - {v.timeSlot + 1}:00 {(v.timeSlot + 1) < 12 ? 'AM' : 'PM'}
                                        </p>
                                      </div>

                                      <div className="col-6 p-0 d-flex align-items-center justify-content-end">
                                        <img
                                          className="slot_sch cursor_pointer"
                                          src={Chat}
                                          alt=""
                                          onClick={() =>
                                            rediretToChatPage(v.userId)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}

                            <div className="row m-0 mt-4 mb-3">
                              <div className="col-10 p-0">
                                <p className="mb-0 session_req_status_bold">
                                  Confirmed
                                </p>
                              </div>

                              <div className="col-2 p-0 pe-2 d-flex align-items-center justify-content-end">
                                <div
                                  className="slot_req_status_box d-flex align-items-center justify-content-center p-1 px-2">
                                  <p className="mb-0 slote_activity_count">
                                    {
                                      (bids || []).filter(
                                        (v) => v.status === "confirmed"
                                      ).length
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>

                            {!bids.filter((v) => v.status === "confirmed")
                              .length ? (
                              <p className="text-center my-4">
                                No Result Found
                              </p>
                            ) : null}

                            {(bids || [])
                              .filter((v) => v.status === "confirmed")
                              .map((v, i) => {
                                return (
                                  <div className="flow_right_cards p-0 py-3 px-2 mt-2" key={i}>
                                    <div className="row m-0">
                                      {/* <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>
                                                                    <img config={Oneto1Brain} alt="" />
                                                                    <button className="p-0 mt-1 flow_right_cards_btn">  {v.offer.category}</button>
                                                                </div> */}

                                      <div className="col-6 p-0 ps-2 d-flex flex-column justify-content-center">
                                        <p className="mb-0 flow_right_cards_bold_text">
                                          {v.user.firstName} {v.user.lastName}
                                        </p>
                                        <p className="mb-0 mt-1 flow_right_cards_light_text">
                                          {v.timeSlot}:00 {v.timeSlot < 12 ? 'AM' : 'PM'} - {v.timeSlot + 1}:00 {(v.timeSlot + 1) < 12 ? 'AM' : 'PM'}
                                        </p>
                                      </div>

                                      <div className="col-6 p-0 d-flex align-items-center justify-content-end">
                                        <img
                                          className="slot_sch cursor_pointer"
                                          src={Chat}
                                          alt=""
                                          onClick={() =>
                                            rediretToChatPage(v.userId)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* Expert 1-1 Session */}
          </div>
        </div>
      </>

      {showBookForm ? (
        <ExpertBookModal
          showBookForm={showBookForm}
          selectedExpertData={selectedExpertData}
          onChange={onChange}
          value={value}
          activeSlotTab={activeSlotTab}
          setActiveSlotTab={setActiveSlotTab}
          timeExchnageSlots={timeExchnageSlots}
          checkActiveSlotTab={checkActiveSlotTab}
          selectedSlotTime={selectedSlotTime}
          setSelectedSlotTime={setSelectedSlotTime}
          setShowBookForm={setShowBookForm}
          confirmBookSession={confirmBookSession}
          closeConfirmBookedModal={closeConfirmBookedModal}
          showConfirmBookedModal={showConfirmBookedModal}
          showResponsive={responsiveView}
        />
      ) : null}
    </>
  );
}

export default Expert1to1;
