import React, { useState } from 'react';
import ResponsiveNav from "../../../component/staticPages/responsiveNavBar/responsiveNav";
import AlternateNav from "../../../component/staticPages/responsiveNavBar/alternateNav";
import { useNavigate } from "react-router";

import TimeExchangePocket from "../../../assets/svg/TimeExchangePocket.svg";
import TimeExchangeEmoji from "../../../assets/svg/TimeExchangeEmoji.svg";

import Search from "../../../assets/svg/Search.svg";
import SmFilter from "../../../assets/svg/Filter.svg";
import Post_Profile_img from "../../../assets/post_profile_img.png";
import CalPublic from "../../../assets/svg/CalPublic.svg";
import EmotionEmoji from "../../../assets/svg/EmotionsEmoji.svg";
import accArrow from '../../../assets/svg/AccordianArrow.svg';
import HuddleVedio from "../../../assets/svg/HuddleVideoCall.svg";
import Like from "../../../assets/svg/Like.svg";
import Comment from "../../../assets/svg/Comment.svg";
import CalVidCam from "../../../assets/svg/CalVidCam.svg";
import Share from "../../../assets/svg/Share.svg";
import Post2_img from "../../../assets/post2_img.png";
import CalHome from "../../../assets/svg/CalHome.svg";
import CalSliderLeft from "../../../assets/svg/CalSliderLeft.svg";
import CalSliderRight from "../../../assets/svg/CalSliderRight.svg";
import Calendar from "react-calendar";
import InfiniteScroll from "react-infinite-scroll-component";
import UpcomingCalender from "../../../assets/svg/UpcomingCalender.svg";
import Back_Mobile from '../../../assets/Back_Mobile.png';
import moment from 'moment';


const CommnunityMobile = (props) => {

    const [isShowInviteFrndModal, setIsShowInviteFrndModal] = useState(false);

    const [showEvent, setShowEvent] = useState("1")

    const navigate = useNavigate();

    const byCategory = (data) => {
        let categories = props.checkCommunityTypeForFilters(props.communityType);
        let filterData = data.category.map(a => a.charAt(0).toLowerCase() + a.substr(1)).map(v => categories.includes(v));
        if (filterData.includes(true)) {
            return data;
        }
    }

    return (
        <div>

            <div className="row m-0 pb-5 pb-md-0 pb-lg-0 mob_tab_responsive_view responsive_background">
                <div className="col-12 p-0 pb-5">
                    {
                        showEvent === "1" && <ResponsiveNav />
                    }

                    {
                        showEvent === "2" && <div className='row m-0 responsive_nav_bottom_border'>
                            <div className='col-12 p-0 ps-4 pe-4'>

                                <div className='row m-0 pt-3 pb-3 pt-md-4 pb-md-4'>

                                    <div className='col-1 p-0 d-flex align-items-center'>
                                        <img className='' src={Back_Mobile} alt='' onClick={() => setShowEvent("1")} />
                                    </div>
                                    <div className='col-4 p-0 d-flex align-items-center'>
                                        <p className='mb-0 alt_nav_heading'>My Events</p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    }

                    {

                        showEvent === "1" && <div className="row m-0 mt-0 mt-md-4 mt-lg-0">
                            <div className="col-12 p-0 ps-4 pe-4 mt-3">
                                <div className="row m-0">
                                    <div className="col-10 p-0">
                                        <p className=" mb-0 upcomming_option_pera">
                                            Upcoming For You
                                        </p>
                                        {/*<option className="select_options" value="1">
                                            One
                                        </option>
                                        <option className="select_options" value="2">
                                            Two
                                        </option>
                                        <option className="select_options" value="3">
                                            Three
                                        </option> */}
                                    </div>

                                    <div className="col-2 p-0 d-flex align-items-center justify-content-end">
                                        <img src={UpcomingCalender} alt="" onClick={() => { setShowEvent("2") }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        showEvent === "1" && <div className="row m-0">
                            <div className="col-12 p-0 ps-4 pe-4">
                                <div className="huddle_big_box">
                                    <div className="row m-0 mt-3">
                                        <div className="col-12 col-md-12 col-lg-7 p-0">
                                            <div class="input-group huddle_top_input">
                                                <input
                                                    type="text"
                                                    class="form-control all_exp_page_lg_input"
                                                    placeholder="Search keyword"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    onChange={(e) => props.setSearch(e.target.value)}
                                                />
                                                <span
                                                    class="input-group-text all_exp_page_lg_span"
                                                    id="basic-addon2"
                                                >
                                                    <img src={Search} alt="" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row m-0 mt-3">
                                        <div className="col-12 p-0 ps-2 pt-2 pb-2 d-flex align-itmes-center justify-content-between experts_event_by_overflow">
                                            {/* <button className="ps-3 pe-3 pt-2 pb-2 me-4 experts_filter_by_event active_experts_filter_by_event">
                                        All
                                    </button>
                                    <button className="ps-3 pe-3 pt-2 pb-2 me-4 experts_filter_by_event d-flex align-items-center">
                                        {" "}
                                        <img className="me-3" config={SmEmotions} alt="" /> Emotions
                                    </button>
                                    <button className="ps-3 pe-3 pt-2 pb-2 me-4 experts_filter_by_event d-flex align-items-center">
                                        {" "}
                                        <img className="me-3" config={SmBelonging} alt="" />{" "}
                                        Belonging
                                    </button>
                                    <button className="ps-3 pe-3 pt-2 pb-2 me-4 experts_filter_by_event d-flex align-items-center">
                                        {" "}
                                        <img className="me-3" config={SmBreathing} alt="" />{" "}
                                        Breathing
                                    </button>
                                    <button className="ps-3 pe-3 pt-2 pb-2 me-4 experts_filter_by_event d-flex align-items-center">
                                        {" "}
                                        <img className="me-3" config={SmMoving} alt="" /> Moving
                                    </button>
                                    <button className="ps-3 pe-3 pt-2 pb-2 me-4 experts_filter_by_event d-flex align-items-center">
                                        {" "}
                                        <img className="me-3" config={SmMoving} alt="" /> Moving
                                    </button>
                                    <button className="ps-3 pe-3 pt-2 pb-2 me-4 experts_filter_by_event d-flex align-items-center">
                                        {" "}
                                        <img className="me-3" config={SmMoving} alt="" /> Moving
                                    </button> */}

                                            <button onClick={() => props.setFilterName("All")} className={`ps-3 pe-3 pt-2 pb-2 me-4 experts_filter_by_event ${props.filterName === "All" ? "active_experts_filter_by_event" : ""}`}>
                                                All
                                            </button>
                                            {props.checkCommunityTypeForFilters(props.communityType).map((v, i) => {
                                                return <button onClick={() => props.setFilterName(v.charAt(0).toUpperCase() + v.slice(1))} className={`${props.filterName === v.charAt(0).toUpperCase() + v.slice(1) ? "active_experts_filter_by_event" : ""} ps-3 pe-3 pt-2 pb-2 me-4 experts_filter_by_event d-flex align-items-center`} key={i}>
                                                    {" "}
                                                    <img className="me-3" src={props.getFilterImages(v)} alt="" />{" "}
                                                    {v.charAt(0).toUpperCase() + v.slice(1)}
                                                </button>
                                            })}

                                        </div>
                                    </div>
                                    <div className="row m-0 mt-3">

                                        {!props.events.filter(v => moment(v.startDate).isAfter(`${moment().toISOString()}`)).filter(v => byCategory(v)).filter(v => props.filterName === "All" ? v : v.category.includes(props.filterName)).filter((curr) =>
                                            props.search
                                                ? (curr.category.join(', ').charAt(0).toUpperCase() + curr.category.join(', ').slice(1) + curr.name)
                                                    .toLowerCase()
                                                    .indexOf(props.search.toLowerCase()) >= 0
                                                : true
                                        ).length && <p className="text-center mt-4">No Events Found</p>}

                                        <InfiniteScroll
                                            dataLength={props.events.length} //This is important field to render the next data
                                            next={props.CalHomefetchMoreData}
                                            hasMore={true}
                                            loader={<h4 className="text-center my-4">
                                                {/* <div class="spinner-border text-primary" role="status">
                                                    <span class="sr-only"></span>
                                                </div> */}
                                            </h4>}
                                            endMessage={
                                                <p style={{ textAlign: 'center' }}>
                                                    <b>Yay! You have seen it all</b>
                                                </p>
                                            }
                                            className="row"
                                        >

                                            {(props.events || []).filter(v => props.filterName === "All" ? v : v.category.includes(props.filterName)).filter((curr) =>
                                                props.search
                                                    ? (curr.category.join(', ').charAt(0).toUpperCase() + curr.category.join(', ').slice(1) + curr.name)
                                                        .toLowerCase()
                                                        .indexOf(props.search.toLowerCase()) >= 0
                                                    : true
                                            ).filter(v => moment(v.startDate).isAfter(`${moment().toISOString()}`)).filter(v => byCategory(v)).map((curr, index) => {

                                                return <div className="col-12 p-0 mb-4" key={index}>
                                                    <div className="huddle_upcoming_event">
                                                        <div
                                                            class="accordion post_accordion event_accordion"
                                                            id="accordionExample1"
                                                        >
                                                            <div class="accordion-item event_box_feed">
                                                                <h2 class="accordion-header " id="headingOne">
                                                                    <button
                                                                        class="accordion-button post_accordion_btn d-flex justify-content-between"
                                                                        type="button"
                                                                        data-bs-toggle="collapse"

                                                                        aria-expanded="true"
                                                                        aria-controls="collapseOne"
                                                                    >
                                                                        <div className="m-0  d-flex align-items-center">
                                                                            <div className="post_profile_img me-2">
                                                                                <img src={Post_Profile_img} />
                                                                            </div>

                                                                            <div className="p-0 ">
                                                                                <p className="mb-0 event_name ms-1">
                                                                                    {curr.createdByUser.firstName} {curr.createdByUser.lastName}  <span className='event-name_span'> shared</span> an Event
                                                                                </p>
                                                                                <p className="mb-0 event_date ms-1 mt-2">
                                                                                    {moment(curr.createdAt).format(' Do MMM YYYY')}  AT  {moment(curr.createdAt).format('h:mm A')}
                                                                                </p>
                                                                            </div>
                                                                        </div>

                                                                        <div className='m-0  d-flex align-items-center'>

                                                                            <div onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                if (props.currentId.includes(curr.id)) {
                                                                                    let arr = [...props.currentId];
                                                                                    let spliceArr = arr.filter((v, i) => v !== curr.id);
                                                                                    props.setcurrentId(spliceArr);
                                                                                }
                                                                                else {
                                                                                    props.setcurrentId([...props.currentId, curr.id]);
                                                                                }
                                                                            }} class={`${props.currentId.includes(curr.id) ? 'rotate_icon' : ''}`}>
                                                                                <img src={accArrow} />
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                </h2>

                                                                <div
                                                                    id="collapseOne"
                                                                    class={`accordion-collapse collapse ${props.currentId.includes(curr.id) ? 'show' : ''}`}
                                                                    aria-labelledby="headingOne"
                                                                    data-bs-parent="#accordionExample1"
                                                                >
                                                                    <div class="accordion-body accord_body">
                                                                        <div className="row m-0 ">
                                                                            <div className="col-12 p-0">
                                                                                <img className='post_img post_img_mob cursor_pointer' onClick={() => navigate(`/event/${curr.id}`)} src={curr.image ? curr.image : Post2_img} />
                                                                            </div>
                                                                        </div>

                                                                        {/* <div className="row m-0 mb-5">
                                                                            <div className="col-12 p-0 pos_rel">
                                                                                <div className="date_card pos_abs d-flex flex-column justify-content-center align-items-center">
                                                                                    <p className="mb-0 date_card_date">
                                                                                        {moment(curr.startDate).format('D')}
                                                                                    </p>
                                                                                    <p className="mb-0 date_card_month mt-1">
                                                                                        {moment(curr.startDate).format('MMM')}
                                                                                    </p>
                                                                                </div>

                                                                                <div className="huddle_crad_pos d-flex flex-column justify-content-center align-items-center">
                                                                                    <img config={EmotionEmoji} alt="" />
                                                                                </div>
                                                                            </div>
                                                                        </div> */}

                                                                        <div className="row m-0 mb-3">
                                                                            <div className="col-12 p-0 px-4 resp_minus_marg d-flex align-items-center justify-content-between resp_data_hdl_cards">

                                                                                <div className="date_card_rsep d-flex flex-column justify-content-center align-items-center">
                                                                                    <p className="mb-0 date_card_date">
                                                                                        {moment(curr.startDate).utc().format('D')}
                                                                                    </p>
                                                                                    <p className="mb-0 date_card_month mt-1">
                                                                                        {moment(curr.startDate).utc().format('MMM')}
                                                                                    </p>
                                                                                </div>

                                                                                <div className="huddle_crad_resp d-flex flex-column justify-content-center align-items-center">
                                                                                    <img src={EmotionEmoji} alt="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row m-0 ps-3">
                                                                            <div className="col-12 p-0 postacc_dtlsinfo_section">
                                                                                <p className="mb-0 event_heading ms-1 cursor_pointer" onClick={() => navigate(`/event/${curr.id}`)}>
                                                                                    {curr.name}
                                                                                </p>
                                                                                <p className="mb-0 ms-1 mt-2 upcomimg_huddle_categoryname">
                                                                                    Category:{" "}
                                                                                    <span className="upcomimg_huddle_categorynamespan">
                                                                                        {curr.category.join(', ').charAt(0).toUpperCase() + curr.category.join(', ').slice(1)}
                                                                                    </span>
                                                                                </p>
                                                                                <p className="mb-0 event_date ms-1 mt-2">
                                                                                {moment(curr.startDate).utc().format(' Do MMM YYYY')}  AT  {moment(curr.startDate).utc().format('h:mm A')}
                                                                                </p>
                                                                                <p className="mb-0 ms-1 mt-2 upcoming_huddle_status d-flex align-items-center">
                                                                                    <img
                                                                                        className=""
                                                                                        src={HuddleVedio}
                                                                                        alt=""
                                                                                    />{" "}
                                                                                    &nbsp; {curr.type}
                                                                                </p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row m-0 ps-3 pe-3 pb-2 post_border mt-2">
                                                                            <div className="col-6 p-0 d-flex align-items-center justify-content-start">
                                                                                <span
                                                                                    class="input-group-text post_avatar_img "
                                                                                    id="basic-addon2"
                                                                                >
                                                                                    {curr.participants.map((v, i) => {
                                                                                        return <span class="avatar_post" key={i}>
                                                                                            <img
                                                                                                class="avatar_post_img"
                                                                                                src={v.user.profilePic ? v.user.profilePic : "/static/png/Male.png"}
                                                                                                alt=""
                                                                                                width="28px"
                                                                                                height="28px"
                                                                                            />
                                                                                        </span>
                                                                                    })}
                                                                                </span>
                                                                            </div>

                                                                            {props.userDetails && props.userDetails.id ? <div className="col-6 p-0 d-flex align-items-center justify-content-end">
                                                                                {curr.createdByUserId !== props.userDetails.id ? <button className="event_btn" onClick={() => {
                                                                                    let ids = curr.participants.map(v => v.userId);
                                                                                    if (ids.includes(props.userDetails.id)) {
                                                                                        window.open(`${curr.link}`)
                                                                                    }
                                                                                    else {
                                                                                        navigate(`/event/${curr.id}`)
                                                                                    }
                                                                                }}>

                                                                                    {curr.participants.map(v => v.userId).includes(props.userDetails.id) ? "Attend Event" : "Register"}
                                                                                </button> : null}

                                                                            </div> : null}
                                                                        </div>

                                                                        <div className="row m-0 mt-3 pb-3 px-3">
                                                                            <div className="col-lg-4 col-md-2 col-4 p-0 d-flex justify-content-start align-items-center">
                                                                                <div className="event_action_img_huddle cursor_pointer  d-flex justify-content-center align-items-center " onClick={() => props.handleLike(curr.id, null, curr)}>
                                                                                    <img className="" src={Like} alt="" />
                                                                                </div>
                                                                                <p className="mb-0 ms-2 event_action_like_count" onClick={() => props.setShowUserLikedList(curr)}>
                                                                                    {curr.reactionCount}
                                                                                </p>
                                                                            </div>

                                                                            <div className="col-lg-4 col-md-2 col-4 p-0 d-flex align-items-center justify-content-start">
                                                                                <div className="event_action_img_huddle cursor_pointer d-flex justify-content-center align-items-center ">
                                                                                    <img
                                                                                        className=""
                                                                                        src={Comment}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                <p className="mb-0 ms-2 event_action_cmnt_count">
                                                                                    {curr.commentCount}
                                                                                </p>
                                                                            </div>

                                                                            <div className="col-lg-4 col-md-8 col-4 p-0 d-flex justify-content-end align-items-center">
                                                                                <div onClick={() => props.showInviteUserPopup(curr)} className="event_action_img_huddle cursor_pointer d-flex justify-content-center align-items-center ">
                                                                                    <img
                                                                                        className=""
                                                                                        src={Share}
                                                                                        alt=""
                                                                                        data-bs-toggle="modal"
                                                                                        data-bs-target="#communityModals1"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })}

                                        </InfiniteScroll>

                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        showEvent === "2" && <div className="col-12 p-0 mt-5">
                            <div className="big_huddle_box_2">
                                <div className="row m-0"   >
                                    <div className="col-12 p-0 d-flex align-items-center justify-content-center">

                                        <Calendar
                                            className="huddle_calender_mobile"
                                            onChange={props.onChange}
                                            onActiveStartDateChange={(e) => props.onChange(e.activeStartDate)}
                                            value={props.value}
                                            next2Label={false}
                                            prev2Label={false}
                                            prevLabel={false}
                                            nextLabel={false}
                                            minDate={new Date()}
                                            formatLongDate={(locale, date) => (date, 'dd')}
                                        />
                                    </div>
                                </div>

                                <div className="row m-0 mt-5 ms-4">
                                    <div className="col-12 p-0">
                                        <p className="mb-0 huddle_meeting_schedule">
                                            Events in this month
                                        </p>
                                    </div>
                                </div>


                                {!props.allEvents.filter(v => (moment(v.startDate).format('M') === moment(props.value).format('M'))).length && <p className="text-center mt-3 no_event_pera">No events available</p>}

                                <div className={`row m-0 ${((props.allEvents || []).filter(v => (moment(v.startDate).format('M') === moment(props.value).format('M'))).length > 4) && "event_data_area_exe_one event_data_area_exe_two event_data_area"}`}>
                                    {(props.allEvents || []).filter(v => (moment(v.startDate).format('M') === moment(props.value).format('M'))).map((curr, i) => {
                                        return <div className="zoom_meeting_box p-2 mt-3 d-flex flex-column justify-content-center" onClick={() => navigate(`/event/${curr.id}`)} key={i}>

                                            <div className="row m-0">

                                                <div className="col-2 p-0">
                                                    <div className="meeting_date_huddle_box d-flex align-items-center justify-content-center">
                                                        <p className="mb-0 meeting_date_huddle d-flex align-items-center justify-content-center">
                                                            {moment(curr.startDate).utc().format('D')}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-10 p-0 ps-2">
                                                    <p className="mb-0 meeting_heading_huddle">
                                                        {curr.name}
                                                    </p>
                                                    <p className="mb-0 mt-2 meeting_time_huddle">
                                                        {moment(curr.startDate).utc().format('h:mm A')} - {moment(curr.endDate).utc().format('h:mm A')}
                                                    </p>
                                                    {/* <p className="mb-0 mt-2 meeting_attend_huddle mt-3">
                                                        10 attendees
                                                    </p> */}
                                                </div>

                                            </div>

                                        </div>
                                    })}

                                </div>
                            </div>
                        </div>

                    }

                    {/* Responsive View Huddle Section */}

                    {/* Responsive View Calander Section */}

                    {/* <AlternateNav />

                  <div className='row m-0'>
                      <div className='col-12 p-4'>

                          <div className='row m-0' onClick={() => handleModal(true)}>
                              <div className='col-12 p-0'>
                                  <Calendar
                                      className="huddle_calender"
                                      onChange={onChange}
                                      value={value}
                                      next2Label={false}
                                      prev2Label={false}
                                      prevLabel={false}
                                      nextLabel={false}
                                  />
                              </div>
                          </div>

                          <div className='row m-0 mt-4'>
                              <div className='col-12 p-0'>

                                  <div className='row m-0'>
                                      <div className='col-12 p-0'>
                                          <p className='mb-0 huddle_meeting_schedule'>Huddles in this month</p>
                                      </div>
                                  </div>

                                  <div className='row m-0 mt-3'>
                                      <div className='col-12 p-0'>

                                          <div className='zoom_meeting_box d-flex p-2'>

                                              <div className='col-2 p-0 d-flex justify-content-center align-items-center'>

                                                  <div className='meeting_date_huddle_box d-flex justify-content-center align-items-center'>
                                                      <p className='mb-0 meeting_date_huddle d-flex justify-content-center align-items-center'>8</p>
                                                  </div>

                                              </div>

                                              <div className='col-8 p-0 ps-2 d-flex flex-column justify-content-center'>
                                                  <p className='mb-0 meeting_heading_huddle'>Mental Health For Executives </p>
                                                  <p className='mb-0 meeting_time_huddle'>10.00 AM - 12.00 PM</p>

                                                  <p className='mb-0 mt-3 meeting_attend_huddle'>10 attendees</p>

                                                  <p className='mb-0 mt-3 meeting_cant_join'>Can’t join? <span>Cancel</span></p>
                                              </div>

                                              <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                                  <img className='video_call_svg' config={VideoCallActive} alt='' />
                                              </div>

                                          </div>

                                      </div>
                                  </div>

                                  <div className='row m-0 mt-2'>
                                      <div className='col-12 p-0'>

                                          <div className='zoom_meeting_box d-flex p-2'>

                                              <div className='col-2 p-0 d-flex justify-content-center align-items-center'>

                                                  <div className='meeting_date_huddle_box d-flex justify-content-center align-items-center'>
                                                      <p className='mb-0 meeting_date_huddle d-flex justify-content-center align-items-center'>16</p>
                                                  </div>

                                              </div>

                                              <div className='col-8 p-0 ps-2 d-flex flex-column justify-content-center'>
                                                  <p className='mb-0 meeting_heading_huddle'>Mental Health For Executives </p>
                                                  <p className='mb-0 meeting_time_huddle'>10.00 AM - 12.00 PM</p>

                                                  <p className='mb-0 mt-3 meeting_attend_huddle'>10 attendees</p>

                                                  <p className='mb-0 mt-3 meeting_cant_join'>Can’t join? <span>Cancel</span></p>
                                              </div>

                                              <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                                  <img className='video_call_svg' config={VideoCall} alt='' />
                                              </div>

                                          </div>

                                      </div>
                                  </div>

                                  <div className='row m-0 mt-2'>
                                      <div className='col-12 p-0'>

                                          <div className='zoom_meeting_box d-flex p-2'>

                                              <div className='col-2 p-0 d-flex justify-content-center align-items-center'>

                                                  <div className='meeting_date_huddle_box d-flex justify-content-center align-items-center'>
                                                      <p className='mb-0 meeting_date_huddle d-flex justify-content-center align-items-center'>21</p>
                                                  </div>

                                              </div>

                                              <div className='col-8 p-0 ps-2 d-flex flex-column justify-content-center'>
                                                  <p className='mb-0 meeting_heading_huddle'>Mental Health For Executives </p>
                                                  <p className='mb-0 meeting_time_huddle'>10.00 AM - 12.00 PM</p>

                                                  <p className='mb-0 mt-3 meeting_attend_huddle'>10 attendees</p>

                                                  <p className='mb-0 mt-3 meeting_cant_join'>Can’t join? <span>Cancel</span></p>
                                              </div>

                                              <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                                  <img className='video_call_svg' config={VideoCall} alt='' />
                                              </div>

                                          </div>

                                      </div>
                                  </div>

                              </div>
                          </div>

                      </div>
                  </div> */}

                    {/* Responsive View Calander Section */}

                </div>
            </div >


        </div >
    )
}

export default CommnunityMobile;