import {getCommentRepliesByCommentId, getPostLike, updateComment} from "../../../../utils/apiUrlsSetup";
import {useZStore} from "../../../../utils/zStore";
import React, {useEffect, useState} from "react";
import moment from "moment";
import CommentLikeButton from "./CommentLikeButton";
import useSelectedObject from "../../../../utils/hooks/useSelectedObject";
import Swal from "sweetalert2";
import PostText from "../../../../component/PostText";
import EditCommentBox from "./EditCommentBox";
import {Tooltip as ReactTooltip} from "react-tooltip";
import ReplyBox from "./ReplyBox";
import CommentReportButton from "./CommentReportButton";

/**
 * @param {{
 *   currentDepth: number
 *   comment: PostComment,
 *   afterChange: VoidFunction,
 *   post: Post,
 *   mutate: VoidFunction,
 *   setIsEditingComment: Dispatch<SetStateAction<number | undefined>>
 *   isEditingComment: number | undefined
 * }} props
 * @return {JSX.Element}
 * @constructor
 */
export default function CommentCard(props) {
  const store = useZStore()
  const userDetails = store.userDetails;
  const [isReplying, setIsReplying] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [comment, setComment] = useState(props.comment);

  useEffect(() => {
    setComment(props.comment)
  }, [props.comment]);

  /**
   * @type {[
   *      PostComment[] | null,
   *      React.Dispatch<React.SetStateAction<PostComment[] | null>>,
   *      (boolean) => Promise<PostComment[]>
   * ]}
   */
  const repliesState = useSelectedObject(
    async () => {
      const responseData = await getCommentRepliesByCommentId(props.comment.id);

      // Map it to list of strings
      return responseData.data.data
    },
    false
  );

  const replies = repliesState[0]
  const mutateThis = repliesState[2]

  useEffect(() => {
    mutateThis();
  }, []);

  /**
   * @type {[
   *      {userId: string, type: string}[] | null,
   *      React.Dispatch<React.SetStateAction<{userId: string, type: string}[] | null>>,
   *      (boolean) => Promise<{userId: string, type: string}[]>
   * ]}
   */
  const reactorState = useSelectedObject(
    async () => {
      // Fetch all who like this post
      const responseData = await getPostLike(`commentId=${props.comment.id}`);

      // Map it to list of strings
      return responseData.data.data
    },
    false
  );

  /**
   * @returns {Promise<void>}
   */
  const _deleteComment = async () => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async e => {
      if (e.isConfirmed) {
        await updateComment(props.comment.id, {isDeleted: true});
        await props.mutate();
        setIsDeleted(true)
      }
    })
  }

  if (isDeleted) return <></>

  if (props.isEditingComment === props.comment.id) {
    return (
      <EditCommentBox
        comment={comment}
        localSetter={setComment}
        afterSubmit={() => {
          props.setIsEditingComment(null);
          props.mutate()
        }}
        cancel={() => {
          props.setIsEditingComment(null)
        }}
      />
    )
  }

  const distinctReaction = [...new Set((reactorState[0] ?? []).map(e => e.type))]
  return (
    <div>
      <div className="d-flex tw-gap-1 lg:tw-gap-2">
        <div className="tw-w-[24px] lg:tw-w-[36px] tw-aspect-square tw-rounded-3xl flex-shrink-0">
          <img src={props.comment.user.profilePic} alt="Avatar" className={"tw-rounded-3xl"}/>
        </div>
        <div>
          <div className={"tw-w-full tw-bg-[#efefff] border tw-border-[#efefff] tw-rounded-xl tw-px-2.5 tw-py-1"}>
            <div className="d-flex tw-items-center gap-1">
              <p className={"tw-font-semibold tw-text-sm"}>
                {props.comment.user.firstName} {props.comment.user.lastName}
              </p>
              {props.comment.user.id === props.post.user.id && (
                <>
                  <i className="bi bi-dot"/>
                  <div className="rounded-pill bg-primary-subtle px-2 tw-text-xs tw-font-medium">
                    Author
                  </div>
                </>
              )}
            </div>
            <PostText content={props.comment.comment}/>
          </div>
          {comment.attachment && (
            <img
              src={comment.attachment}
              className={"rounded tw-cursor-pointer tw-max-w-[150px] tw-max-h-[150px] tw-mt-2"}
              onClick={() => {
                store.setFullscreenImgSrc(props.comment.attachment)
              }}
              alt={"Attachment"}
            />
          )}
          <div className="d-flex justify-content-between tw-items-center tw-px-2.5 tw-gap-3">
            <div className={"tw-text-sm d-flex tw-justify-start tw-items-center tw-gap-2.5"}>
              <div className={"tw-text-nowrap"}>{moment(props.comment.createdAt).fromNow()}</div>
              <CommentLikeButton reactorState={reactorState} commentId={props.comment.id}/>
              {props.comment.createdAt !== props.comment.updatedAt && (
                <>
                  <div className={"tw-opacity-80 tw-cursor-default hover:tw-underline"}
                       data-tooltip-id={`edited-${props.comment.id}`}
                       data-tooltip-content={`Last edited ${moment(props.comment.updatedAt).fromNow()}`}
                  >
                    Edited
                  </div>

                  <ReactTooltip
                    id={`edited-${props.comment.id}`}
                    place={"right"}
                  />

                </>
              )}
              <div className={"tw-cursor-pointer"} onClick={() => setIsReplying(true)}>
                Reply
              </div>
            </div>
            <div>
              <div
                className="montserrat tw-text-[#7e5fc5] tw-text-sm tw-font-medium d-flex align-items-center tw-justify-between">
                <div className="d-flex align-items-center cursor_pointer data-[hidden='true']:tw-invisible">
                  <div className={"tw-h-[25px]"} />
                  {distinctReaction.map((reaction, rIdx) => {
                    return (
                      <img
                        key={reaction}
                        src={`/static/r_${reaction}.png`}
                        alt={`Reaction ${reaction}`}
                        style={{
                          zIndex: distinctReaction.length - rIdx
                        }}
                        className={`tw-w-[25px] bg-white tw-h-[25px] rounded-circle ${rIdx > 0 ? "-tw-ms-2" : ""}`}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className={"tw-w-[25px] tw-h-[25px] d-flex tw-justify-center tw-items-center rounded-circle hover:tw-bg-gray-200 tw-cursor-pointer"}>
            <i className="bi bi-three-dots"
               data-bs-toggle="dropdown" aria-expanded="false"/>

            <ul className="dropdown-menu">
              {userDetails.id === props.comment.user.id && (
                <li
                  className="dropdown-item montserrat tw-cursor-pointer tw-font-medium tw-text-sm tw-min-w-[200px]"
                  onClick={() => {
                    props.setIsEditingComment(props.comment.id)
                  }}>
                  <i className="bi bi-pen me-1"/> Edit Comment
                </li>
              )}

              {(userDetails.role === "super-admin" || userDetails.id === props.comment.user.id) &&
                <>
                  {/* Delete Post */}
                  <li
                    className="dropdown-item montserrat tw-cursor-pointer tw-font-medium tw-text-sm tw-min-w-[200px]"
                    onClick={() => _deleteComment()}>
                    <i className="bi bi-trash-fill me-1"/> Delete Comment
                  </li>
                </>
              }

              <CommentReportButton
                afterSubmit={() => {
                  props.mutate()
                  setIsDeleted(true)
                }}
                commentId={props.comment.id}
              />
            </ul>
          </div>
        </div>
      </div>
      {(replies ?? []).filter(e => e.reportedBy !== userDetails.id).length > 0 && (
        <div className={"d-flex gap-2 mt-3 tw-w-full"}>
          <div data-depth={props.currentDepth} className={"data-[depth=2]:block hidden tw-w-[24px] lg:tw-w-[36px] flex-shrink-0"}/>
          <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full">
            {replies.filter(e => e.reportedBy !== userDetails.id).map(reply => {
              return (
                <CommentCard
                  key={reply.id}
                  currentDepth={props.currentDepth + 1}
                  comment={reply}
                  afterChange={props.afterChange}
                  post={props.post}
                  mutate={props.mutate}
                  setIsEditingComment={props.setIsEditingComment}
                  isEditingComment={props.isEditingComment}
                />
              )
            })}
          </div>
        </div>
      )}

      {isReplying && (
        <div className={"d-flex gap-2 mt-3"}>
          <div className={"tw-w-[24px] lg:tw-w-[36px] flex-shrink-0"}/>
          <ReplyBox comment={props.comment} afterSubmit={async () => {
            await props.mutate()
            await mutateThis()
            setIsReplying(false)
          }}/>
        </div>
      )}
    </div>
  )
}