import {useEffect, useState} from 'react';

/**
 * Custom hook for managing and fetching a selected object.
 *
 * @template T - The type of the object to manage.
 * @param {() => Promise<T>} fetchingFunction - The fetchingFunction the data from.
 * @param {boolean} [initialFetch=true] - Whether to perform the initial fetch when the hook is used.
 * @returns {[T | null, React.Dispatch<React.SetStateAction<T | null>>, (boolean) => Promise<T>]} - The state of the object, the setter function, and a mutate function to refetch the data.
 */
export default function useSelectedObject(fetchingFunction, initialFetch = true) {
  const [state, setState] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (!initialFetch) return;

    if (state) return;
    setIsUpdating(true);
    fetchingFunction()
      .then(response => {
        setState(response.data);
        setIsUpdating(false);
      });
  }, [initialFetch, state, fetchingFunction]);

  /**
   * Function to refetch the data and optionally reset the state.
   *
   * @param {boolean} [withReset=false] - Whether to reset the state before refetching the data.
   * @returns {Promise<T>} - A promise that resolves to the fetched data.
   */
  const mutate = async (withReset = false) => {
    if (isUpdating) return;

    setIsUpdating(true);
    if (withReset) {
      setState(null);
    }

    const response = await fetchingFunction()

    setState(response);
    setIsUpdating(false);
    return response;
  };

  return [state, setState, mutate];
}
