import React, { useState } from 'react';

import Search from "../../assets/svg/Search.svg";
import CommunityModalIllustration from "../../assets/svg/CommunityModalIllustration.svg";
import Delete from "../../assets/svg/Delete.svg";
import Close from "../../assets/svg/Close.svg";

import CommunityModalImage from "../../assets/CommunityModalImage.png";
import AssociatedPhoto from "../../assets/AssociatedPhoto.png";
import moment from 'moment'
import CloseBtn from '../../assets/Crossbtn.png';
import { sendEventParticipants } from '../../utils/apiUrlsSetup';

const InviteFriendModal = ({ isShowInviteFrndModal, friendsList, eventData, setIsShowInviteFrndModal }) => {
    let frndsData = [...friendsList];
    const [selectedFrnds, setselectedFrnds] = useState([]);
    const [showInvitationSent, setShowInvitationSent] = useState(false);

    const [changeToinviteNewFriends, setChangeToinviteNewFriends] = useState(false);

    const [search, setSearch] = useState("");

    const [invitedUserList, setInvitedUserList] = useState([""]);

    const sendEventParticipantsToUsers = async () => {
        let obj = {
            userIdArray: selectedFrnds.map(v => v.id)
        }
        const response = await sendEventParticipants(eventData.event.id, obj);
        if (response.status === 200) {
            setShowInvitationSent(true);
        }
    }

    return <>
        <div class={`modal fade ${isShowInviteFrndModal ? 'show' : ''}`} style={{ display: isShowInviteFrndModal ? 'block' : 'none', background: isShowInviteFrndModal ? 'rgba(0,0,0,0.5)' : '' }} id="communityModals1" tabIndex="-1" aria-labelledby="communityModals1" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered communityModals1">
                <div class="modal-content">

                    <div className='row m-0'>
                        <div className='col-12 p-0 ps-lg-5 pe-lg-5 px-3 pt-4 pb-4'>

                            <div className='row m-0 align-items-start mb-4'>
                                <div className='col-11 p-0 ps-lg-5 pe-lg-5 pt-0'>
                                    <p className='mb-0 community_modal_heading text-center'> Invite friends to this event.</p>
                                </div>

                                <div className='col-1 p-0 d-flex justify-content-end align-items-start mt-2'>
                                    <img className='news_feed_modal_closebtn' src={CloseBtn} alt='' onClick={() => setIsShowInviteFrndModal(false)} />
                                </div>
                            </div>

                            <div className='row m-0'>
                                <div className='col-4 p-0'>
                                    <img className='cummunnityModals1_img' width="100%" src={
                                        eventData.event.image
                                            ? eventData.event.image
                                            : CommunityModalImage
                                    } alt="" />
                                </div>
                                <div className='col-8 p-0 ps-3 d-flex flex-column justify-content-center'>
                                    <p className='mb-0 community_modal_mid_heading'>{eventData.event.name}</p>
                                    <p className='mb-0 mt-3 community_modal_light_text'>{moment(eventData.event.startDate).utc().format("D MMM YYYY")}</p>
                                    <p className='mb-0 mt-2 community_modal_light_text'>{moment(eventData.event.startDate).utc().format("hh:mm a")} -{" "}
                                        {moment(eventData.event.endDate).utc().format("hh:mm a")}</p>
                                </div>
                            </div>

                            {changeToinviteNewFriends ? <>
                                <div className='row m-0 mt-4'>
                                    <div className='col-8 p-0'>
                                        <p className='mb-0 invite_new_user_heading'>Get 2H free from inviting new user!</p>
                                    </div>
                                    <div className='col-4 p-0 d-flex align-items-center justify-content-end'>
                                        <button className='add_new_user_to_invite_btn add_new_user_to_invite_btn_unactive' onClick={() => {
                                            setInvitedUserList([...invitedUserList, ""]);
                                        }}>Add email</button>
                                    </div>
                                </div>

                                <div className="invite_frnds_list">
                                    <div className="me-3">
                                        {invitedUserList.map((v, i) => {
                                            return <div className='row m-0 mt-3' key={i}>
                                                <div className='col-11 p-0'>
                                                    {/* <input className='ps-4 pt-2 pb-2 add_new_user_to_invite_input' type="text" placeholder='samuel.hez@gmail.com' /> */}
                                                    <input onChange={(e) => {
                                                        let values = [...invitedUserList];
                                                        values[i] = e.target.value;
                                                        setInvitedUserList(values);
                                                    }} value={v} type="email" class="form-control add_new_user_to_invite_input" id="exampleFormControlInput1" placeholder="samuel.hez@gmail.com"></input>
                                                </div>
                                                <div className='col-1 p-0 d-flex align-items-center justify-content-end'>
                                                    <img className='cursor_pointer' src={Delete} alt='' onClick={() => {
                                                        let values = [...invitedUserList];
                                                        let remaingItems = values.filter((v, idx) => idx !== i);
                                                        setInvitedUserList(remaingItems);
                                                    }} />
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>

                                <div className='row m-0 mt-4'>
                                    <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                        <button className='community_modal_btn_to_invite community_modal_btn_active_invite' data-bs-toggle="modal" data-bs-target="#inviteSent">Invite</button>
                                    </div>
                                </div>
                            </> : <>

                                <div className='row m-0 mt-4'>
                                    <div className='col-12 p-0'>
                                        <p className='mb-0 community_modal_sm_heading'>Friends ({friendsList.length})</p>
                                        {/* {selectedFrnds.length ? <p className='mb-0 ' data-bs-toggle="modal" data-bs-target="#inviteNewUsersModal">
                                            Inviting {selectedFrnds.length} friends
                                        </p> : <p className='mb-0 community_modal_sm_invite' data-bs-toggle="modal" data-bs-target="#inviteNewUsersModal" onClick={() => setChangeToinviteNewFriends(true)}>
                                            Inviting new user?
                                        </p>} */}
                                        {/* <p className='mb-0 community_modal_sm_salected_invite'>Inviting 2 friends</p> */}
                                    </div>
                                </div>

                                <div className='row m-0 mt-2'>
                                    <div className='col-12 p-0'>

                                        <div class="input-group huddle_top_input position_relative">
                                            <input value={search} onChange={(e) => setSearch(e.target.value)} type="text" class="form-control all_exp_page_lg_input" placeholder="Search people" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                            <span class="input-group-text all_exp_page_lg_span" id="basic-addon2"><img src={Search} alt='' /></span>
                                        </div>
                                    </div>
                                </div>

                                {!invitedUserList.length ? <p className="text-center my-4">No users available</p> : null}

                                <div className="mt-3">
                                    <div className="user_pill_background">
                                        {selectedFrnds.map((v, i) => {
                                            return <div class="user_pill d-flex d-flex align-items-center" key={i}>
                                                <img width="20px" src={v.profilePic ? v.profilePic : AssociatedPhoto} alt="" />
                                                <p className='mb-0 community_users_list_names ps-2'>{v.firstName} {v.lastName}</p>
                                                <img className="cursor_pointer ps-2" onClick={() => {
                                                    let copySelectedFrnds = [...selectedFrnds];
                                                    let filterItems = copySelectedFrnds.filter(val => val.id !== v.id);
                                                    setselectedFrnds(filterItems);
                                                }} src={CloseBtn} width="20px" alt="" />
                                            </div>
                                        })}
                                    </div>
                                </div>

                                <div className="invite_frnds_list">
                                    <div className="me-3">
                                        {frndsData.filter(el => {
                                            return selectedFrnds.indexOf(el) === -1;
                                        }).filter((u) =>
                                            search
                                                ? (u.firstName + " " + u.lastName)
                                                    .toLowerCase()
                                                    .indexOf(search.toLowerCase()) >= 0
                                                : true
                                        ).map((v, i) => {
                                            return <div className='row m-0 mt-2' key={i}>
                                                <div className='col-12 p-0 community_users_list community_top_border' onClick={() => {
                                                    setselectedFrnds([...selectedFrnds, v])
                                                }}>

                                                    <div className='row m-0 pt-2 pb-2'>

                                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                                            <div className='community_users_list_img_box'>
                                                                <img src={v.profilePic ? v.profilePic : AssociatedPhoto} alt="" />
                                                            </div>
                                                        </div>

                                                        <div className='col-10 p-0 d-flex align-items-center'>
                                                            <p className='mb-0 community_users_list_names'>{v.firstName} {v.lastName}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>

                                <div className='row m-0 mt-2'>
                                    <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                        <button onClick={() => sendEventParticipantsToUsers()} className='community_modal_btn_to_invite community_modal_btn_active_invite' data-bs-toggle="modal" data-bs-target="#inviteSent" disabled={selectedFrnds.length ? false : true}>Invite</button>
                                    </div>
                                </div>

                            </>}



                        </div>
                    </div>

                </div>
            </div>
        </div>
        {/* Modal-1 */}

        {/* Modal-2 */}
        <div class={`modal fade ${showInvitationSent ? 'show' : ''}`} style={{ display: showInvitationSent ? 'block' : 'none', background: showInvitationSent ? 'rgba(0,0,0,0.5)' : '' }} id="inviteSent" tabIndex="-1" aria-labelledby="inviteSent" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">

                    <div className='row m-0'>
                        <div className='col-12 p-0 ps-5 pe-5 pt-4 pb-4'>

                            <div className='row m-0'>
                                <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                    <div className='community_confirmation_modal_img d-flex align-items-center justify-content-center'>
                                        <img src={CommunityModalIllustration} alt='' />
                                    </div>
                                </div>
                            </div>

                            <div className='row m-0'>
                                <div className='col-12 p-0'>
                                    <p className='mb-0 community_invite_sent text-center'>Invitation sent!</p>
                                    <p className='mb-0 mt-1 community_invite_sent_info text-center'>Your friends will be notified that you have invited them to a event.</p>
                                </div>
                            </div>

                            {/* <div className='row m-0'>
                            <div className='col-12 p-0'>
                                <p className='mb-0 community_invite_sent text-center'>Invitations sent!</p>
                                <p className='mb-0 community_invite_sent_info_blue text-center'>2H has been added to your time balance</p>
                                <p className='mb-0 mt-1 community_invite_sent_info text-center'>Your friends will be notified that you have invited them to the huddle</p>
                            </div>
                        </div> */}

                            <div className='row m-0 mt-4'>
                                <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                    <button className='community_modal_ok_btn' onClick={() => {
                                        setShowInvitationSent(false);
                                        setIsShowInviteFrndModal(false);
                                    }}>OK</button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        {/* Modal-2 */}

        {/* Modal-3 */}
        <div class="modal fade" id="inviteNewUsersModal" tabIndex="-1" aria-labelledby="inviteNewUsersModal" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered communityModals1">
                <div class="modal-content">

                    <div className='row m-0'>
                        <div className='col-12 p-0 ps-5 pe-5 pt-4 pb-4'>

                            <div className='row m-0'>
                                <div className='col-12 p-0 ps-5 pe-5 pt-0 pb-4'>
                                    <p className='mb-0 community_modal_heading text-center'>Invite friends to this Kaleidoscope Live event.</p>
                                </div>
                            </div>

                            <div className='row m-0'>
                                <div className='col-4 p-0'>
                                    <img src={CommunityModalImage} alt="" />
                                </div>
                                <div className='col-8 p-0 ps-3 d-flex flex-column justify-content-center'>
                                    <p className='mb-0 community_modal_mid_heading'>Mental Health for Executives</p>
                                    <p className='mb-0 mt-3 community_modal_light_text'>Wed, Sep, 8</p>
                                    <p className='mb-0 mt-2 community_modal_light_text'>10.00 AM - 12.00 PM</p>
                                </div>
                            </div>

                            <div className='row m-0 mt-4'>
                                <div className='col-8 p-0'>
                                    <p className='mb-0 invite_new_user_heading'>Get 2H free from inviting new user!</p>
                                </div>
                                <div className='col-4 p-0 d-flex align-items-center justify-content-end'>
                                    <button className='add_new_user_to_invite_btn add_new_user_to_invite_btn_unactive'>Add email</button>
                                </div>
                            </div>

                            <div className='row m-0 mt-3'>
                                <div className='col-11 p-0'>
                                    {/* <input className='ps-4 pt-2 pb-2 add_new_user_to_invite_input' type="text" placeholder='samuel.hez@gmail.com' /> */}
                                    <input type="email" class="form-control add_new_user_to_invite_input" id="exampleFormControlInput1" placeholder="samuel.hez@gmail.com"></input>
                                </div>
                                <div className='col-1 p-0 d-flex align-items-center justify-content-end'>
                                    <img className='cursor_pointer' src={Delete} alt='' />
                                </div>
                            </div>

                            <div className='row m-0 mt-2'>
                                <div className='col-11 p-0'>
                                    {/* <input className='ps-4 pt-2 pb-2 add_new_user_to_invite_input' type="text" placeholder='samuel.hez@gmail.com' /> */}
                                    <input type="email" class="form-control add_new_user_to_invite_input" id="exampleFormControlInput1" placeholder="samuel.hez@gmail.com"></input>
                                </div>
                                <div className='col-1 p-0 d-flex align-items-center justify-content-end'>
                                    <img className='cursor_pointer' src={Delete} alt='' />
                                </div>
                            </div>

                            <div className='row m-0 mt-4'>
                                <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                    <button className='community_modal_btn_to_invite community_modal_btn_active_invite' data-bs-toggle="modal" data-bs-target="#inviteSent">Invite</button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        {/* Modal-3 */}

        {/* Modal-4 */}
        <div class="modal fade" id="calenderDeclineModal" tabIndex="-1" aria-labelledby="calenderDeclineModal" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered communityModals1">
                <div class="modal-content">

                    <div className='row m-0'>
                        <div className='col-12 p-0 ps-5 pe-5 pt-4 pb-4'>

                            <div className='row m-0'>
                                <div className='col-12 p-0'>
                                    <p className='mb-0 community_modal_decline_heading text-center'>Not coming?</p>
                                </div>
                            </div>

                            <div className='row m-0 mt-2'>
                                <div className='col-12 p-0'>
                                    <p className='mb-0 community_decline_modal_info'>This event will be deleted from your calendar. Your name will be canceled from the list of attendees.</p>
                                </div>
                            </div>

                            <div className='row m-0 mt-4'>
                                <div className='col-4 p-0'>
                                    <img src={CommunityModalImage} alt="" />
                                </div>
                                <div className='col-8 p-0 ps-3 d-flex flex-column justify-content-center'>
                                    <p className='mb-0 community_modal_mid_heading'>Mental Health for Executives</p>
                                    <p className='mb-0 mt-3 community_modal_light_text'>Wed, Sep, 8</p>
                                    <p className='mb-0 mt-2 community_modal_light_text'>10.00 AM - 12.00 PM</p>
                                </div>
                            </div>

                            <div className='row m-0 mt-4'>
                                <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                    <button className='decline_modal_cancel_btn me-4'>Cancel</button>
                                    <button className='decline_modal_yes_btn ms-4'>Yes</button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        {/* Modal-4 */}

        {/* Modal-4 */}
        <div class="modal fade" id="chatExpModal" tabIndex="-1" aria-labelledby="calenderDeclineModal" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered communityModals1">
                <div class="modal-content">

                    <div className='row m-0'>
                        <div className='col-12 p-0 ps-5 pe-5 pt-4 pb-4'>

                            <div className='row m-0'>
                                <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                    <img className='cursor_pointer' src={Close} alt='' data-bs-dismiss="modal" />
                                </div>
                            </div>

                            <div className='row m-0'>
                                <div className='col-12 p-0'>
                                    <p className='mb-0 community_modal_decline_heading text-center'>How is the event?</p>
                                    <p className='mb-0 ms-2 mt-4 chat_screen_modal_info_text text-left'>Tell us what you experienced from the event.</p>
                                </div>
                            </div>

                            <div className='row m-0 mt-2'>
                                <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                    <textarea class="form-control chat_screen_textarea p-4" id="exampleFormControlTextarea1" rows="3" placeholder='Tell us what you feel...'></textarea>
                                </div>
                            </div>

                            <div className='row m-0 mt-4'>
                                <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                    <button className='community_modal_btn_to_invite community_modal_btn_active_invite me-4'>Submit</button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        {/* Modal-4 */}
    </>
}

export default InviteFriendModal;
