import React from 'react';

import SideNav from '../../component/staticPages/SideNav';

import "./organization.css";

import Search from "../../assets/search1.png";

import CoverPhotoCamera from '../../assets/svg/CoverPhotoCamera.svg'
import minus from "../../assets/svg/minus.svg"
import Plus from "../../assets/svg/Plus.svg"
import ExpertCardPic from "../../assets/ExpertCardPic.png"

const members = () => {
    return (
        <>
            <div className='row m-0 background'>
                <div className='col-12 p-0'>

                    <div className='row m-0 d-flex newsfeed_bg time_exchange_bg_full_height'>

                        <div className='news_left p-0'>

                            <SideNav timeExchange={true} />

                        </div>

                        <div className='news_right time_exchange_auto_height p-4'>

                            <div className='row m-0'>
                                <div className='col-12 p-0 header'>


                                    <div className='row m-0'>
                                        <div className='col-12 p-0'>

                                            <div className="input-group neworg_searchbar">
                                                <span class="input-group-text px-4" id="basic-addon1"> <img src={Search} alt='' /> </span>
                                                <input type="text" class="form-control p-0" placeholder="Search for a group" aria-label="Username" aria-describedby="basic-addon1" />
                                            </div>

                                        </div>
                                    </div>

                                    <div className='row m-0 mt-4'>
                                        <div className='col-12 p-0'>

                                            <div className='org_cover_img d-flex align-items-center justify-content-center'>

                                                <img src={CoverPhotoCamera} alt='' />

                                                {/* For Cover Image: */}

                                                {/* <img className='coverPhoto' config='' alt='' /> */}

                                            </div>

                                        </div>
                                    </div>

                                    <div className='row m-0 mt-4 pe-5'>
                                        <div className='col-10 p-0 py-3 brd_bottom'>
                                            <p className='mb-0 new_org_heading new_org_heading_act'>The Yoga Collective</p>
                                            <p className='mb-0 mt-3 new_org_head_dtls new_org_head_dtls_act'>Public Group &bull; 1,500 Members</p>
                                        </div>
                                    </div>

                                    <div className='row m-0 mt-3 pe-5'>

                                        <div className='col-10 p-0'>

                                            <div className='row m-0'>

                                                <div className='col-6 p-0'>
                                                    <button className='p-2 me-4 new_org_header_btns'>About</button>
                                                    <button className='p-2 me-4 new_org_header_btns'>Posts</button>
                                                    <button className='p-2 me-4 new_org_header_btns act_new_org_header_btns'>Members</button>
                                                </div>

                                                <div className='col-6 p-0 d-flex align-items-start justify-content-end'>
                                                    <button className='create_new_group'>Create New Community</button>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    <div className='row m-0 mt-3'>

                                        <div className='col-12 p-0 payment_method org_members_list pb-5'>

                                            <div className='user_list_border'>

                                                <div className='row m-0 d-flex p-3'>

                                                    <div className='div-1 d-flex justify-content-center align-items-center'>

                                                        <div class="form-check mt-1">
                                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                        </div>

                                                    </div>
                                                    <div className='div-2 div-comm p-0 d-flex justify-content-start align-items-center ps-1'>
                                                        <p className='mb-0 admin_userlist_name'>Name</p>
                                                    </div>
                                                    <div className='div-3 div-comm p-0 d-flex justify-content-start align-items-center'>
                                                        <p className='mb-0 admin_userlist_name'>Email</p>
                                                    </div>
                                                    <div className='div-4 p-0 d-flex justify-content-start align-items-center'>
                                                        <p className='mb-0 admin_userlist_name'>Role</p>
                                                    </div>
                                                    {/* <div className='div-5 p-0 d-flex justify-content-start align-items-center'>
                                                        <p className='mb-0 admin_userlist_name'>Tags</p>
                                                    </div> */}
                                                    <div className='div-6 div-6A p-0 d-flex justify-content-start align-items-center'>
                                                        <p className='mb-0 admin_userlist_name'>Date Joined</p>
                                                    </div>
                                                    <div className='div-7 div-7A p-0 d-flex justify-content-end align-items-center'>
                                                        <p className='mb-0 admin_userlist_name'>Actions</p>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className='user_list_border'>

                                                <div className='row m-0 d-flex p-3'>

                                                    <div className='div-1 d-flex justify-content-center align-items-center'>

                                                        <div class="form-check mt-1">
                                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                        </div>

                                                    </div>
                                                    <div className='div-2 div-comm p-0 d-flex justify-content-start align-items-center ps-1'>
                                                        <div className='admin_user_profile'>
                                                            <img src={ExpertCardPic} alt='' />
                                                        </div>
                                                        <p className='mb-0 ms-2 admin_userlist_profile_name' >Muhammad Deferdauz</p>
                                                    </div>
                                                    <div className='div-3 div-comm p-0 d-flex justify-content-start align-items-center'>
                                                        <p className='mb-0 admin_userlist_name'>deferdauz@gmail.com</p>
                                                    </div>
                                                    <div className='div-4 p-0 d-flex justify-content-start align-items-center'>
                                                        <p className='mb-0 admin_userlist_name'>User</p>
                                                    </div>
                                                    {/* <div className='div-5 p-0 d-flex justify-content-start align-items-center'>
                                                        <p className='mb-0 admin_userlist_name'>Public</p>
                                                    </div> */}
                                                    <div className='div-6 div-6A p-0 d-flex justify-content-start align-items-center'>
                                                        <p className='mb-0 admin_userlist_name'>24/02/2022</p>
                                                    </div>
                                                    <div className='div-7 div-7A p-0 d-flex justify-content-end align-items-center'>

                                                        <button className='user_accept_btn'><img src={Plus} /> &nbsp; Accept</button>

                                                    </div>
                                                </div>

                                            </div>

                                            <div className='user_list_border'>

                                                <div className='row m-0 d-flex p-3'>

                                                    <div className='div-1 d-flex justify-content-center align-items-center'>

                                                        <div class="form-check mt-1">
                                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                        </div>

                                                    </div>
                                                    <div className='div-2 div-comm p-0 d-flex justify-content-start align-items-center ps-1'>
                                                        <div className='admin_user_profile'>
                                                            <img src={ExpertCardPic} alt='' />
                                                        </div>
                                                        <p className='mb-0 ms-2 admin_userlist_profile_name'>Rebecca Yip</p>
                                                    </div>
                                                    <div className='div-3 div-comm p-0 d-flex justify-content-start align-items-center'>
                                                        <p className='mb-0 admin_userlist_name'>rebecca@gmail.com</p>
                                                    </div>
                                                    <div className='div-4 p-0 d-flex justify-content-start align-items-center'>
                                                        <p className='mb-0 admin_userlist_name'>Superadmin</p>
                                                    </div>
                                                    {/* <div className='div-5 p-0 d-flex justify-content-start align-items-center'>
                                                        <p className='mb-0 admin_userlist_name'>Public</p>
                                                    </div> */}
                                                    <div className='div-6 div-6A p-0 d-flex justify-content-start align-items-center'>
                                                        <p className='mb-0 admin_userlist_name'>24/02/2022</p>
                                                    </div>
                                                    <div className='div-7 div-7A p-0 d-flex justify-content-end align-items-center'>

                                                        {/* <button className='user_delete_btn'><img config={minus} />&nbsp;Delete</button> */}

                                                    </div>
                                                </div>

                                            </div>

                                            <div className='user_list_border'>

                                                <div className='row m-0 d-flex p-3'>

                                                    <div className='div-1 d-flex justify-content-center align-items-center'>

                                                        <div class="form-check mt-1">
                                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                        </div>

                                                    </div>
                                                    <div className='div-2 div-comm p-0 d-flex justify-content-start align-items-center ps-1'>
                                                        <div className='admin_user_profile'>
                                                            <img src={ExpertCardPic} alt='' />
                                                        </div>
                                                        <p className='mb-0 ms-2 admin_userlist_profile_name' >Georgie Porgie</p>
                                                    </div>
                                                    <div className='div-3 div-comm p-0 d-flex justify-content-start align-items-center'>
                                                        <p className='mb-0 admin_userlist_name'>georgie.p@gmail.com</p>
                                                    </div>
                                                    <div className='div-4 p-0 d-flex justify-content-start align-items-center'>
                                                        <p className='mb-0 admin_userlist_name'>Admin</p>
                                                    </div>
                                                    {/* <div className='div-5 p-0 d-flex justify-content-start align-items-center'>
                                                        <p className='mb-0 admin_userlist_name'>Public</p>
                                                    </div> */}
                                                    <div className='div-6 div-6A p-0 d-flex justify-content-start align-items-center'>
                                                        <p className='mb-0 admin_userlist_name'>24/02/2022</p>
                                                    </div>
                                                    <div className='div-7 div-7A p-0 d-flex justify-content-end align-items-center'>

                                                        <button className='user_delete_btn'><img src={minus} /> &nbsp; Delete</button>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default members