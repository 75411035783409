import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import Joyride from "react-joyride";
import {getMetaData, updateMetaData} from "../utils/apiUrlsSetup";
import {saveTourData} from "../redux/actions/action";

export default function Tutorial() {
    let dispatch = useDispatch();

    const tourDataFromState = useSelector(state => state.tourData);
    const [tourData, setTourData] = useState({});

    useEffect(() => {
        if (!_.isEmpty(tourDataFromState)) {
            setTourData(tourDataFromState.tourData);
        }
    }, [tourDataFromState])

    if (_.isEmpty(tourData) || tourData.newsFeedTour) {
        return <></>
    }

    const handleJoyrideCallback = data => {
        const {action, status, type} = data;

        if ((status === "finished" && type === "tour:end") || action === "skip") {
            updateUserMetaData();
        }
    };

    const updateUserMetaData = async () => {
        let platformTour = {
            newsFeedTour: true,
            timeExchangeTour: tourData.timeExchangeTour,
            communityTour: tourData.communityTour,
            journeyTour: tourData.journeyTour,
            expertTour: tourData.expertTour,
        };

        let response = await updateMetaData(platformTour);
        if (response.status === 200) {
            const responseData = await getMetaData();
            if (responseData.status === 200) {
                dispatch(saveTourData(responseData.data.data));
            }
        }
    }

    return (
        <Joyride
            continuous
            showProgress
            showSkipButton
            run={true}
            callback={handleJoyrideCallback}
            steps={[
                {
                    content: (
                        <React.Fragment>
                            <h2>Feed</h2>
                            <p>Create a post and share your well-being story with attachments like images or
                                videos.</p>
                        </React.Fragment>
                    ),
                    target: ".input_newsfeed_all",
                    placement: 'auto',
                },
                {
                    content: (
                        <React.Fragment>
                            <h2>Feed Category</h2>
                            <p>Find a post categorised by its Well-being Experience Centre.</p>
                        </React.Fragment>
                    ),
                    target: ".newsfeeds_category",
                    placement: 'auto',
                },
                {
                    content: (
                        <React.Fragment>
                            <h2>Events Shortcut</h2>
                            <p>You can directly attend recurring events.</p>
                        </React.Fragment>
                    ),
                    target: ".event_box_feed_joyride",
                    placement: 'left',

                }
            ]}
            styles={{
                buttonNext: {
                    backgroundColor: "#5458F7",
                    borderRadius: 4,
                    color: '#fff',
                },
                buttonBack: {
                    color: "#8D8D8D",
                    marginLeft: 'auto',
                    marginRight: 5,
                },
            }}
        />
    )
}