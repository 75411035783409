import React, { useEffect, useState } from 'react'
// import Search from "../../../../assets/svg/Search.svg";
import Search from "../../../../assets/svg/Search.svg";
import AssociatedPhoto from "../../../../assets/Male.png";
import { inviteMembersToOrg, searchUserProfile } from '../../../../utils/apiUrlsSetup';
import { useNavigate } from 'react-router-dom';
import CommunityModalIllustration from "../../../../assets/svg/CommunityModalIllustration.svg";
import CloseBtn from '../../../../assets/Crossbtn.png';

const AddFriendModal = (props) => {

    const [search, setSearch] = useState("");
    const [listData, setListData] = useState([]);
    const [selectedFrnds, setselectedFrnds] = useState([]);
    const [showInvitationSent, setShowInvitationSent] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (search.length > 0) {
            getFriendsSearch();
        }
        else {
            setListData([]);
        }
    }, [search]);

    const getFriendsSearch = async () => {
        let responseData = await searchUserProfile(search);
        setListData(responseData.data.data)
    }

    const inviteMembers = async () => {
        let obj = {
            userIdArray: selectedFrnds.map(v => v.id)
        }
        let responseData = await inviteMembersToOrg(props.orgId, obj);
        if (responseData.status === 200) {
            setShowInvitationSent(true);
        }
    }


    return (
        <>
            <div class={`modal fade ${props.addfriendModal ? 'show' : ''}`} style={{ display: props.addfriendModal ? 'block' : 'none', background: props.addfriendModal ? 'rgba(0,0,0,0.5)' : '' }} id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered communityModals1">
                    <div class="modal-content model_border_radius">

                        <div class="modal-body p-4">

                            <div className='d-flex align-items-center mb-4'>


                                <div className='col-11 p-0 pt-0'>
                                    <p className="model_bold_text text-center mb-0" style={{ color: '#5458F7' }}>You are {props.organisation ? "inviting" : "adding"}</p>
                                </div>

                                {(props.organisation || props.showCancelBtn) ? <div className='col-1 p-0 d-flex justify-content-end align-items-center'>
                                    <img className='news_feed_modal_closebtn' src={CloseBtn} alt='' onClick={() => {
                                        setselectedFrnds([]);
                                        setSearch("");
                                        props.closeModal(false);
                                    }} />
                                </div> : null}


                            </div>

                            <div className='row m-0'>
                                <div className='col-12 p-0'>
                                    <div class="input-group huddle_top_input position_relative">
                                        <input type="text" class="form-control all_exp_page_lg_input" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search people" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                        <span class="input-group-text all_exp_page_lg_span" id="basic-addon2"><img src={Search} alt='' /></span>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-3">
                                <div className="user_pill_background">
                                    {selectedFrnds.map((v, i) => {
                                        return <div class="user_pill d-flex d-flex align-items-center" key={i}>
                                            <img width="20px" src={v.profilePic ? v.profilePic : AssociatedPhoto} alt="" />
                                            <p className='mb-0 community_users_list_names ps-2'>{v.firstName} {v.lastName}</p>
                                            <img className="cursor_pointer ps-2" onClick={() => {
                                                let copySelectedFrnds = [...selectedFrnds];
                                                let filterItems = copySelectedFrnds.filter(val => val.id !== v.id);
                                                setselectedFrnds(filterItems);
                                            }} src={CloseBtn} width="20px" alt="" />
                                        </div>
                                    })}
                                </div>
                            </div>

                            <div className="invite_frnds_list">
                                <div className="me-3">
                                    {listData.filter(el => {
                                        return selectedFrnds.indexOf(el) === -1;
                                    }).map((v, i) => {
                                        return <div className='row m-0 mt-2' key={i}>
                                            <div className='col-12 p-0 community_top_border'>

                                                <div className='row m-0 pt-2 pb-2'>

                                                    <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                                        <div className='community_users_list_img_box'>
                                                            <img src={v.profilePic ? v.profilePic : AssociatedPhoto} alt="" />
                                                        </div>
                                                    </div>

                                                    <div className='col-7 p-0 d-flex align-items-center cursor_pointer' onClick={() => {
                                                        if (props.organisation) {
                                                            setselectedFrnds([...selectedFrnds, v])
                                                        }
                                                    }}>
                                                        <p className='mb-0 community_users_list_names'>{v.firstName} {v.lastName}</p>
                                                    </div>

                                                    {!props.organisation ? <div className='col-3 p-0 d-flex align-items-center justify-content-center'>
                                                        <button className="visit_profile_btn" onClick={() => navigate(`/friends/${v.id}`)}>Visit Profile</button>
                                                    </div> : null}



                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>

                            {props.organisation ? <div className='row m-0 mt-4'>
                                <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                    <button className='community_modal_btn_to_invite community_modal_btn_active_invite' data-bs-toggle="modal" data-bs-target="#inviteSent" onClick={() => inviteMembers()}>Invite</button>
                                </div>
                            </div> : null}
                        </div>



                    </div>
                </div>
            </div>

            <div class={`modal fade ${showInvitationSent ? 'show' : ''}`} style={{ display: showInvitationSent ? 'block' : 'none', background: showInvitationSent ? 'rgba(0,0,0,0.5)' : '' }} id="inviteSent" tabIndex="-1" aria-labelledby="inviteSent" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">

                        <div className='row m-0'>
                            <div className='col-12 p-0 ps-5 pe-5 pt-4 pb-4'>

                                <div className='row m-0'>
                                    <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                        <div className='community_confirmation_modal_img d-flex align-items-center justify-content-center'>
                                            <img src={CommunityModalIllustration} alt='' />
                                        </div>
                                    </div>
                                </div>

                                <div className='row m-0'>
                                    <div className='col-12 p-0'>
                                        <p className='mb-0 community_invite_sent text-center'>Invitations sent!</p>
                                        <p className='mb-0 mt-1 community_invite_sent_info text-center'>You can wait for your newest members to confirm their invites.</p>
                                    </div>
                                </div>

                                {/* <div className='row m-0'>
                            <div className='col-12 p-0'>
                                <p className='mb-0 community_invite_sent text-center'>Invitations sent!</p>
                                <p className='mb-0 community_invite_sent_info_blue text-center'>2H has been added to your time balance</p>
                                <p className='mb-0 mt-1 community_invite_sent_info text-center'>Your friends will be notified that you have invited them to the huddle</p>
                            </div>
                        </div> */}

                                <div className='row m-0 mt-4'>
                                    <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                        <button className='community_modal_ok_btn' onClick={() => {
                                            setShowInvitationSent(false);
                                            props.setIsShowInviteFrndModal(false);
                                            setselectedFrnds([]);
                                            setSearch("");
                                        }}>OK</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddFriendModal
