import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";

import SideNav from "../../component/staticPages/SideNav";

import "../../css/expert.css";
import backArrow from "../../assets/CalenderPrevarrow.png";
import ExpertGrayHeart from "../../assets/svg/ExpertGrayHeart.svg";
import SMStar from "../../assets/svg/Experts/SMStar.svg";
import ExpertRedHeart from "../../assets/svg/ExpertRedHeart.svg";
import ExpertRatings from "../../assets/svg/ExpertRatings.svg";
import rating_gray from "../../assets/rating_gray.png";
import Search from "../../assets/svg/Search.svg";
import Back from "../../assets/svg/Back.svg";
import Heart from "../../assets/svg/Heart.svg";
import GrayHeart from "../../assets/svg/GrayHeart.svg";

import BlueLeft from "../../assets/svg/BlueLeft.svg";
import OpenInNew from "../../assets/svg/OpenInNew.svg";
import FrameLocation from "../../assets/svg/FrameLocation.svg";
import MoreButton from "../../assets/svg/MoreButton.svg";
import LikeButton from "../../assets/svg/LikeButton.svg";
import _ from "lodash";
import thankupgImg from "../../assets/thumbsUpRating.png";
import expert_icon_empty from '../../assets/expert_icon_empty.png';


import {
  bookSession,
  createChat,
  getExpertExTime,
  getListOfExperUsers,
  getOneToOneSessions,
  getReview,
  getUserInformation,
  makeFavourite,
  postReview,
} from "../../utils/apiUrlsSetup";
import ResponsiveNav from "./../../component/staticPages/responsiveNavBar/responsiveNav";
import ExpertBookModal from "./expertBookModal";
import {useLocation} from "react-router-dom";
import Rating from "react-rating";
import CloseBtn from "../../assets/Crossbtn.png";
import moment from "moment-timezone";
import Points from "../timeExchange/component/point";
import {useSelector} from 'react-redux';

function ExpertsSearch() {
    const navigate = useNavigate();

    let userDetails = JSON.parse(localStorage.getItem('userDetails'));

    const [userData, setUserData] = useState([]);
    const [isClicked, setIsClicked] = useState(false);
    const [thankUpg, setThankUpg] = useState(false);
    const [showSlider, setshowSlider] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [favArr, setFavArr] = useState([]);
    const [search, setSearch] = useState("");
    const [selectedFilterCategory, setSelectedFilterCategory] = useState("all");
    const [selectedTab, setSelectedTab] = useState("myCoach");

    const [showExpertProfile, setShowExpertProfile] = useState(false);

    const [responsiveMobileProfileTab, setResponsiveMobileProfileTab] =
        useState("Biography");

    const [timeExchnageSlots, setTimeExchnageSlots] = useState([]);

    const [showBookForm, setShowBookForm] = useState(false);

    const [activeSlotTab, setActiveSlotTab] = useState("morning");

    const [activeTabMobile, seActiveTabMobile] = useState("browse_session");

    const [selectedSlotTime, setSelectedSlotTime] = useState("");

    const [responsiveView, inResponsiveView] = useState(false);
    const [showConfirmBookedModal, setShowConfirmBookedModal] = useState(false);
    const [value, onChange] = useState(new Date());

    const [review, showReview] = useState(false);

    const location = useLocation();

    const {state} = useLocation();

    const [allReviewsData, setAllReviewsData] = useState([]);
    const [onetoOneSessions, setOnetoOneSessions] = useState([]);

    const [selectedOneToOneSessionId, setSelectedOneToOneSessionId] =
        useState("");

    const [raingCount, setRaingCount] = useState(0);

    const [reviewDescription, setReviewDescription] = useState("");

    const [viewMore, setViewMore] = useState(false);

    const [point, setPoints] = useState({});

    const data = useSelector(state => state);

    useEffect(() => {
        if (_.isEmpty(data.points)) {
            setPoints({});
        } else {
            setPoints(data.points.points);
        }
    }, [data.points]);

    useEffect(() => {
        getUserInformationFunc();

        if (location.state) {
            setSelectedFilterCategory(location.state);
        }
    }, []);

    useEffect(() => {
        if (state && state.userDetails) {
            openSideBox(state.userDetails);
            setSelectedFilterCategory("all");
        }
    }, [state])

    const getUserInformationFunc = async () => {
        const result = await getUserInformation();
        if (result.status === 200) {
            setFavArr(
                result.data.data.favouritesByUserId
                    ? result.data.data.favouritesByUserId
                    : []
            );
            getListOfUsers(false);
        }
    };

    const getListOfUsers = async (val) => {
        const result = await getListOfExperUsers(null, val);
        if (result.status === 200) {
            let resultData = result.data.data;
            setUserData(resultData);
        }
    };

    const setSelectedTabFunc = (value) => {
        setSelectedTab(value);
        if (value === "myCoach") {
            getListOfUsers(true);
        } else {
            getListOfUsers(false);
        }
    }

    const makeFavouriteCoach = async (id) => {
        let favouriteId = [...favArr];

        if (favArr.includes(id)) {
            favouriteId = favouriteId.filter((v) => v !== id);
        } else {
            favouriteId = [...favouriteId, id];
        }

        let result = await makeFavourite({favouriteId});
        if (result.status === 200) {
            getUserInformationFunc();
        }
    };

    const getSavedData = (data) => {
        const newData = data.filter((f) => favArr.includes(f.user.id));
        return newData;
    };

    const getBookSessionDetails = async (data) => {
        const response = await getExpertExTime(data.user.id);
        if (response.status === 200) {
            setTimeExchnageSlots(response.data.data.totalAvailableSessions);
            setShowBookForm(true);
        }
    };

    const checkActiveSlotTab = (value) => {
        if (value === "morning") {
            return 0;
        } else if (value === "afternoon") {
            return 1;
        } else if (value === "evening") {
            return 2;
        } else {
            return 0;
        }
    };

    const confirmBookSession = async () => {

        let todayDate = new Date();
        let selectedDate = new Date(value);
        let isoDate = new Date(value).toISOString();

        let date = new Date(isoDate);

        // if (todayDate.getDate() !== selectedDate.getDate()) {
        //   date.setDate(date.getDate() + parseInt(1));
        // }

        let splitDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
        let finalStartDate = `${splitDate}T${('0' + selectedSlotTime).slice(-2)}:00:00.000Z`
        let obj = {
            instructorId: selectedData.user.id,
            startDate: finalStartDate,
            timeSlot: selectedSlotTime,
            noOfHours: 1,
        };
        let response = await bookSession(obj);
        if (response.status === 200) {
            setShowConfirmBookedModal(true);
        }
    };

    const closeConfirmBookedModal = async () => {
        setShowConfirmBookedModal(false);
        setShowBookForm(false);
        getListOfUsers(false);
    };

    const rediretToChatPage = async (id) => {
        let obj = {
            membersIdArray: [id],
        };
        let res = await createChat(obj);
        if (res.status === 200) {
            navigate("/chat", {state: id});
        }
    };

    const checkExpertiseFilter = (value) => {
        if (value.expertiseHistory) {
            return value.expertiseHistory.expertise;
        }
    };

    const addReview = () => {
        setIsClicked(true);
    };

    const expertsSearchData = () => {
    };

    const SubmitMobileClicked = () => {
        setIsClicked(false);
        expertsSearchData();
        // setThankUpg(true)
    };


    const openSideBox = async (v) => {
        setSelectedData(v);
        getAllReviews(v);
        getAllSesssions(v);
        setshowSlider(true);
        setShowExpertProfile(true);
    };

    const formatOneToOneSessionDate = (val) => {
        let startDate = moment(val.startDate).utc();
        let endDate = moment(val.endDate).utc().format("hha");
        let formatDate = `${startDate.format("DD MMM YYYY")} - ${startDate.format("hha"
        )} to ${endDate}`;
        return formatDate;
    };

    const getAllSesssions = async (v) => {
        const data = await getOneToOneSessions(v.user.id);
        if (data.status === 200 && typeof (data.data.data) !== "string") {
            setOnetoOneSessions(data.data.data.resultData);
        } else {
            setOnetoOneSessions([]);
        }
    };

    const getAllReviews = async (v) => {
        const getReviewData = await getReview(v.user.id);
        if (getReviewData.status === 200) {
            setAllReviewsData(getReviewData.data.data.reviewArray);
        }
    };

    const submitReview = async () => {
        const obj = {
            rating: raingCount,
            review: reviewDescription,
            sessionId: selectedOneToOneSessionId,
        };
        const data = await postReview(obj);
        if (data.status === 200) {
            setRaingCount(0);
            getAllSesssions(selectedData);
            getAllReviews(selectedData);
            setIsClicked(true);
        }
    };

    const browseSession = () => {
        showReview(false);
        setIsClicked(false);
        setshowSlider(false);
    };

    return (
        <>
            <div className="row m-0 d-flex newsfeed_bg experts_bg_full_height expert_web_view">
                <div className="news_left p-0">
                    <SideNav expert={true}/>
                </div>

                <div className="news_right time_exchange_auto_height p-0">
                    <div className="row m-0">
                        <div className="col-12 p-5">
                            <div className="row m-0 d-flex align-items-center">
                                <div className="col-8 p-0">
                                    <p className="mb-0 notification_heading">All Supporters</p>
                                </div>
                                <Points point={point}/>
                            </div>

                            <div className="row m-0 mt-2">
                                <div className="col-12 p-0 ps-1 d-flex">
                                    <p className="mb-0 ulr_path_line new_ulr_path_line">
                    <span
                        className="cursor_pointer"
                        onClick={() => navigate("/supporters")}
                    >
                      Community
                    </span>
                                        &nbsp; > &nbsp;&nbsp;
                                        <u>All Supporters</u>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row m-0">
                        <div className="col-12 p-0 ps-5 pe-5">
                            <div className="row m-0">
                                <div className="col-2 p-0">
                                    <p
                                        className={`mb-0 category_heading category_filter ${selectedFilterCategory === "all" ? "active" : ""
                                        }`}
                                        onClick={() => setSelectedFilterCategory("all")}
                                    >
                                        All
                                    </p>
                                    <p
                                        className={`mb-0 mt-3 category_lists category_filter ${selectedFilterCategory === "Coach" ? "active" : ""
                                        }`}
                                        onClick={() => setSelectedFilterCategory("Coach")}
                                    >
                                        Coach
                                    </p>
                                    <p
                                        className={`mb-0 mt-3 category_lists category_filter ${selectedFilterCategory === "Counsellor" ? "active" : ""
                                        }`}
                                        onClick={() => setSelectedFilterCategory("Counsellor")}
                                    >
                                        Counsellor
                                    </p>
                                    <p
                                        className={`mb-0 mt-3 category_lists category_filter ${selectedFilterCategory === "Peer Supporter"
                                            ? "active"
                                            : ""
                                        }`}
                                        onClick={() => setSelectedFilterCategory("Peer Supporter")}
                                    >
                                        Peer Supporter
                                    </p>
                                    <p
                                        className={`mb-0 mt-3 category_lists category_filter ${selectedFilterCategory === "Therapist" ? "active" : ""
                                        }`}
                                        onClick={() => setSelectedFilterCategory("Therapist")}
                                    >
                                        Therapist
                                    </p>
                                    <p
                                        className={`mb-0 mt-3 category_lists category_filter ${selectedFilterCategory === "Trainer" ? "active" : ""
                                        }`}
                                        onClick={() => setSelectedFilterCategory("Trainer")}
                                    >
                                        Trainer
                                    </p>
                                    {/* <p className={`mb-0 mt-3 category_lists category_filter ${selectedFilterCategory === "Psychologist" ? "active" : ""}`} onClick={() => setSelectedFilterCategory("Psychologist")}>Psychologists</p> */}
                                </div>

                                <div className="col-10 p-0">
                                    <div className="experts_search_white_box mb-5">
                                        <div className="row m-0 pt-3">
                                            <div className="col-12 p-0 ps-5 experts_search_brd_bottom">
                                                <button
                                                    className={`experts_search_tabs ms-5 pt-2 pb-2 ${selectedTab === "search"
                                                        ? "active_experts_search_tabs"
                                                        : ""
                                                    }`}
                                                    onClick={() => {
                                                        setSelectedTabFunc("search")
                                                    }}
                                                >
                                                    Search
                                                </button>
                                                <button
                                                    className={`experts_search_tabs ms-5 pt-2 pb-2 ${selectedTab === "myCoach"
                                                        ? "active_experts_search_tabs"
                                                        : ""
                                                    }`}
                                                    onClick={() => {
                                                        setSelectedTabFunc("myCoach");
                                                    }}
                                                >
                                                    My Supporters
                                                </button>
                                                <button
                                                    className={`experts_search_tabs ms-5 pt-2 pb-2 ${selectedTab === "saved"
                                                        ? "active_experts_search_tabs"
                                                        : ""
                                                    }`}
                                                    onClick={() => setSelectedTab("saved")}
                                                >
                                                    Saved
                                                </button>
                                            </div>
                                        </div>

                                        <div className="row m-0">
                                            <div className="col-12 p-0 ps-5 pe-5 pt-4 pb-4 experts_search_brd_bottom">
                                                <div class="input-group">
                                                    <input
                                                        type="text"
                                                        class="form-control all_exp_page_lg_input"
                                                        value={search}
                                                        onChange={(e) => setSearch(e.target.value)}
                                                        placeholder="Search keyword"
                                                        aria-label="Recipient's username"
                                                        aria-describedby="basic-addon2"
                                                    />
                                                    <span
                                                        class="input-group-text all_exp_page_lg_span"
                                                        id="basic-addon2"
                                                    >
                            <img src={Search} alt=""/>
                          </span>
                                                </div>
                                            </div>
                                        </div>

                                        {!(
                                            selectedTab === "saved"
                                                ? getSavedData(userData)
                                                : userData
                                        ).filter((u) =>
                                            search
                                                ? ((u.user.firstName + " " + u.user.lastName).toLowerCase().indexOf(search.toLowerCase()) >= 0) || (u.user.bio && u.user.bio.toLowerCase().search(search.toLowerCase()) != "-1" ? true : false)
                                                : true
                                        )
                                            .filter((val) =>
                                                selectedFilterCategory === "all"
                                                    ? val
                                                    : selectedFilterCategory === checkExpertiseFilter(val)
                                            ).length ? (
                                            <div
                                                className="d-flex flex-column justify-content-center align-items-center">
                                                <img src={expert_icon_empty} alt=""/>
                                                <h4 className="mb-3 no_result_text">No Results</h4>
                                                <p className="text-center no_result_text_desc mb-3 w-50">
                                                    {selectedTab === "search" ? "There are no supporters related to your search. Try to find another keyword."
                                                        : selectedTab === "myCoach" ? "You have not booked any supporter for now. Try to book a 1-to-1 Session with a coach."
                                                            : "You have not saved any  supporters for now. Click ❤️ while you search supporters."}
                                                </p>
                                            </div>
                                        ) : null}

                                        {(selectedTab === "saved"
                                                ? getSavedData(userData)
                                                : userData
                                        )
                                            .filter((v) =>
                                                selectedFilterCategory === "all"
                                                    ? v
                                                    : selectedFilterCategory === checkExpertiseFilter(v)
                                            )
                                            .filter((u) =>
                                                search
                                                    ? ((u.user.firstName + " " + u.user.lastName).toLowerCase().indexOf(search.toLowerCase()) >= 0) || (u.user.bio && u.user.bio.toLowerCase().search(search.toLowerCase()) != "-1" ? true : false)
                                                    : true
                                            )
                                            .map((v, i) => {
                                                return (
                                                    <div className="row m-0" key={i}>
                                                        <div
                                                            className="col-12 experts_search_brd_bottom p-0 ps-5 pe-5 pt-3 pb-3">
                                                            <div className="row m-0">
                                                                <div className="col-1 p-0">
                                                                    <div className="experts_flow_card_profile_img">
                                                                        <img
                                                                            src={
                                                                                v.user.profilePic
                                                                                    ? v.user.profilePic
                                                                                    : "/static/png/Male.png"
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-8 p-0">
                                                                    <p
                                                                        className="mb-0 experts_search_username"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#modalSlide"
                                                                        onClick={() => {
                                                                            openSideBox(v);
                                                                        }}
                                                                    >
                                                                        {v.user.firstName} {v.user.lastName}
                                                                    </p>
                                                                    <p className="mb-0 experts_search_user_info">
                                                                        {checkExpertiseFilter(v)}
                                                                    </p>
                                                                    <p className="mb-0 experts_search_user_location">
                                                                        {v.user.currentCountry
                                                                            ? v.user.currentCountry
                                                                            : ""}
                                                                    </p>
                                                                </div>

                                                                {v.user.id !== userDetails.id ? <div
                                                                    className="col-3 p-0 d-flex align-items-center justify-content-end">
                                                                    <img
                                                                        className="cursor_pointer"
                                                                        src={
                                                                            favArr.includes(v.user.id)
                                                                                ? Heart
                                                                                : GrayHeart
                                                                        }
                                                                        alt=""

                                                                        onClick={() => makeFavouriteCoach(v.user.id)}
                                                                    />
                                                                    <button
                                                                        className="experts_search_chat_btn ms-3 cursor_pointer"
                                                                        onClick={() => rediretToChatPage(v.user.id)}
                                                                    >
                                                                        Chat Now
                                                                    </button>
                                                                </div> : null}


                                                            </div>

                                                            <div className="row m-0 mt-2 align-items-center">
                                                                <div
                                                                    className="col-3 p-0 d-flex align-itmes-center justify-content-start">
                                                                    <Rating
                                                                        initialRating={
                                                                            v.user
                                                                                .avgRatingOfSessions
                                                                                ? v.user
                                                                                    .avgRatingOfSessions
                                                                                : 0
                                                                        }
                                                                        readonly={true}
                                                                        onChange={(e) => setRaingCount(e)}
                                                                        emptySymbol={
                                                                            <img
                                                                                className="icon"
                                                                                src={rating_gray}
                                                                                alt=""
                                                                                width="20px"
                                                                            />
                                                                        }
                                                                        fullSymbol={
                                                                            <img
                                                                                className="icon"
                                                                                src={ExpertRatings}
                                                                                alt=""
                                                                                width="20px"
                                                                            />
                                                                        }
                                                                    />
                                                                    {/* <div className="col-1 p-0 ps-2"> */}
                                                                    <p className="mb-0 expert_reviews mt-2 ms-2">
                                                                        {v.user
                                                                            .avgRatingOfSessions
                                                                            ? v.user
                                                                                .avgRatingOfSessions
                                                                            : 0} Reviews
                                                                    </p>
                                                                    {/* </div> */}
                                                                </div>
                                                                <div className="col-9 p-0 mt-1">
                                                                    <p className="mb-0 spent_h_on_kscope">
                                                                        Supported {v.totalNoOfHours || 0}H on
                                                                        Kaleidoscope
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="row m-0 mt-2">
                                                                <div className="col-12 p-0">
                                                                    <p className="mb-0 experts_search_info_text"
                                                                       dangerouslySetInnerHTML={{
                                                                           __html: v.user.bio ? v.user.bio : "--"
                                                                       }}></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Modal-3 */}

                    {showSlider ? (
                        <div
                            class={`modal fade ${showSlider ? "show" : ""}`}
                            style={{
                                display: showSlider ? "block" : "none",
                                background: showSlider ? "rgba(0,0,0,0.5)" : "",
                            }}
                            id="modalSlide"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="exampleModalLabel2"
                            aria-hidden="true"
                        >
                            <div
                                class="modal-dialog modal-dialog-slideout sliding_model_dialog"
                                role="document"
                            >
                                <div class="modal-content">
                                    <>
                                        <div className="row m-0">
                                            <div className="col-12 p-0 pt-3 pb-3 frame_header">
                                                <div className="row m-0">
                                                    <div
                                                        className="col-1 p-0 d-flex align-items-center justify-content-center">
                                                        <img
                                                            className="close_btn cursor_pointer"
                                                            src={BlueLeft}
                                                            width="14px"
                                                            alt=""
                                                            data-bs-dismiss="modal"
                                                            onClick={() => {
                                                                if (state && state.userDetails && state.expertSession) {
                                                                    navigate("/supporters/sessions")
                                                                } else if (state && state.userDetails) {
                                                                    navigate("/supporters");
                                                                } else {
                                                                    showReview(false);
                                                                    setViewMore(false);
                                                                    setIsClicked(false);
                                                                    setshowSlider(false);
                                                                }

                                                                // navigate("/supporters")
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="col-7 p-0"></div>

                                                    <div
                                                        className="col-4 p-0 ps-4 d-flex align-items-center justify-content-start">
                                                        <p className="mb-0 open_in_new_window cursor_pointer"
                                                           onClick={() => {
                                                               // navigate(`/friends/${selectedData.user.id}`);
                                                               var strWindowFeatures = "location=yes,height=1080,width=1440,scrollbars=yes,status=yes";
                                                               window.open(`/friends/${selectedData.user.id}`, "_blank", strWindowFeatures);
                                                           }}>
                                                            Open profile in a new window{" "}
                                                            <img src={OpenInNew} alt=""/>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row m-0">
                                            <div className="col-12 p-5 frame_background_color">
                                                <div className="row m-0">
                                                    <div className="col-12 p-0 frame_box">
                                                        {review ? (
                                                            !isClicked ? (
                                                                <>
                                                                    <div className="review_part">
                                                                        <div
                                                                            className="back_btn_review cursor_pointer"
                                                                            onClick={() => showReview(false)}
                                                                        >
                                                                            <img
                                                                                className="me-2"
                                                                                src={backArrow}
                                                                                width="15px"
                                                                                height="15px"
                                                                                alt=""
                                                                            />
                                                                            Back
                                                                        </div>

                                                                        <div className="row m-0 p-0 mt-4">
                                                                            <div
                                                                                className="row m-0 p-0 align-items-center">
                                                                                <div className="col-1 p-0">
                                                                                    <div className="profile_review_pic">
                                                                                        <img
                                                                                            width="55px"
                                                                                            height="55px"
                                                                                            style={{borderRadius: "50%"}}
                                                                                            src={
                                                                                                selectedData.user.profilePic
                                                                                                    ? selectedData.user.profilePic
                                                                                                    : "/static/png/Male.png"
                                                                                            }
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-11 p-0">
                                                                                    <div className="reviewer_name">
                                                                                        {selectedData.user.firstName}{" "}
                                                                                        {selectedData.user.lastName}
                                                                                    </div>
                                                                                    <div className="reviewer_expertise">
                                                                                        {checkExpertiseFilter(selectedData)}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="row m-0 p-0 align-items-center">
                                                                                <div className="col-1 p-0"></div>

                                                                                <div className="col-11 p-0">
                                                                                    <div className="">
                                                                                        <Rating
                                                                                            initialRating={raingCount}
                                                                                            onChange={(e) => setRaingCount(e)}
                                                                                            emptySymbol={
                                                                                                <img
                                                                                                    className="rating_stars icon"
                                                                                                    src={rating_gray}
                                                                                                    alt=""
                                                                                                />
                                                                                            }
                                                                                            fullSymbol={
                                                                                                <img
                                                                                                    className="rating_stars icon"
                                                                                                    src={ExpertRatings}
                                                                                                    alt=""
                                                                                                />
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="row m-0 p-0 align-items-center mt-3">
                                                                                <div className="col-1 p-0"></div>

                                                                                <div className="col-11 p-0">
                                                                                    <div className="">
                                                                                        <p className="reviewer_date_time_label">
                                                                                            {" "}
                                                                                            Date/Time
                                                                                        </p>
                                                                                        <select
                                                                                            className="form-select reviewer_date_time_select"
                                                                                            aria-label="Default select example"
                                                                                            onChange={(e) =>
                                                                                                setSelectedOneToOneSessionId(
                                                                                                    e.target.value
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <option>Select</option>
                                                                                            {onetoOneSessions.map((v, i) => {
                                                                                                return (
                                                                                                    <option key={i}
                                                                                                            value={v.id}>
                                                                                                        {formatOneToOneSessionDate(
                                                                                                            v
                                                                                                        )}
                                                                                                    </option>
                                                                                                );
                                                                                            })}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="row m-0 p-0 align-items-center mt-3">
                                                                                <div className="col-1 p-0"></div>

                                                                                <div className="col-11 p-0">
                                                                                    <div className="">
                                                                                        <p className="reviewer_date_time_label">
                                                                                            How is your experience with
                                                                                            this
                                                                                            Supporter?
                                                                                        </p>
                                                                                        <textarea
                                                                                            placeholder="Tell us about your experience with this Supporter"
                                                                                            name=""
                                                                                            onChange={(e) =>
                                                                                                setReviewDescription(
                                                                                                    e.target.value
                                                                                                )
                                                                                            }
                                                                                            id=""
                                                                                            cols="20"
                                                                                            rows="6"
                                                                                        ></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className="row m-0 p-0 align-items-center mt-4">
                                                                                <div className="col-12 p-0">
                                                                                    <div
                                                                                        className="d-flex align-items-center justify-content-end">
                                                                                        <button
                                                                                            className="cancel_review">
                                                                                            Cancel
                                                                                        </button>
                                                                                        <button
                                                                                            className="submit_review ms-3"
                                                                                            onClick={submitReview}
                                                                                        >
                                                                                            Submit
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div>
                                                                    <div className="row m-0 review_part">
                                                                        <div
                                                                            className="back_btn_review cursor_pointer"
                                                                            onClick={() => showReview(false)}
                                                                        >
                                                                            <img
                                                                                className="me-2"
                                                                                src={backArrow}
                                                                                width="15px"
                                                                                height="15px"
                                                                                alt=""
                                                                            />
                                                                            Back
                                                                        </div>

                                                                        <div className="row m-0 text-center">
                                                                            <div className="col-3 p-0"></div>

                                                                            <div className="col-6 p-0">
                                                                                <img
                                                                                    width="70%"
                                                                                    src={thankupgImg}
                                                                                    alt=""
                                                                                />

                                                                                <h3 className="mt-4">Thank You</h3>
                                                                                <p>Your review has been posted.</p>

                                                                                <button
                                                                                    className="browse_seesion"
                                                                                    onClick={() => browseSession()}
                                                                                >
                                                                                    Browse Sessions
                                                                                </button>
                                                                            </div>

                                                                            <div className="col-3 p-0"></div>
                                                                        </div>
                                                                    </div>
                                                                    {" "}
                                                                </div>
                                                            )
                                                        ) : (
                                                            <>
                                                                <div className="row m-0 p-4 frame_bottom_border">
                                                                    <div
                                                                        className="col-2 p-0 d-flex align-items-center">
                                                                        <div className="frame_profile_pic">
                                                                            <img
                                                                                src={
                                                                                    selectedData.user.profilePic
                                                                                        ? selectedData.user.profilePic
                                                                                        : "/static/png/Male.png"
                                                                                }
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className="col-5 p-0 d-flex flex-column justify-content-center ">
                                                                        <p className="mb-0 frame_user_name">
                                                                            {selectedData.user.firstName}{" "}
                                                                            {selectedData.user.lastName}
                                                                        </p>
                                                                        <p className="mb-0 mt-2 frame_user_location">
                                                                            {" "}
                                                                            <img src={FrameLocation} alt=""/>{" "}
                                                                            {moment.tz.guess()} &bull;
                                                                            {moment().tz(moment.tz.guess()).format("hh:mm a")} local
                                                                            time
                                                                        </p>
                                                                    </div>

                                                                    {selectedData.user.id !== userDetails.id ? <div
                                                                        className="col-5 p-0 d-flex align-items-start justify-content-around">
                                                                        {/* <img className="cr_pointer" config={MoreButton} alt=" " /> */}
                                                                        <button
                                                                            className="frame_btn active_frame_btn"
                                                                            onClick={() => {
                                                                                inResponsiveView(false);
                                                                                getBookSessionDetails(selectedData);
                                                                            }}
                                                                        >
                                                                            Book
                                                                        </button>
                                                                        <button
                                                                            className="frame_btn"
                                                                            onClick={() =>
                                                                                rediretToChatPage(selectedData.user.id)
                                                                            }
                                                                        >
                                                                            Chat
                                                                        </button>
                                                                        <img
                                                                            className={`cr_pointer ${favArr.includes(selectedData.user.id)
                                                                                ? "red_circle"
                                                                                : ""
                                                                            }`}
                                                                            src={
                                                                                favArr.includes(selectedData.user.id)
                                                                                    ? ExpertRedHeart
                                                                                    : LikeButton
                                                                            }
                                                                            alt=" "
                                                                            onClick={() =>
                                                                                makeFavouriteCoach(selectedData.user.id)
                                                                            }
                                                                        />
                                                                    </div> : null}


                                                                </div>

                                                                <div className="row m-0">
                                                                    <div className="col-4 p-0 frame_right_border">
                                                                        <div
                                                                            className="row m-0 p-4 frame_bottom_border">
                                                                            <div className="col-8 p-0">
                                                                                <p className="mb-0 frame_total_hours_count">
                                                                                    {selectedData.totalNoOfHours}+
                                                                                </p>
                                                                                <p className="mb-0 frame_total_hours_head">
                                                                                    Total Hours
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-4 p-0 ps-4">
                                                                                <p className="mb-0 frame_total_hours_count">
                                                                                    {selectedData.totalSessionCount}
                                                                                </p>
                                                                                <p className="mb-0 frame_total_hours_head">
                                                                                    Sessions
                                                                                </p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row m-0">
                                                                            <div className="col-12 p-4">
                                                                                <p className="mb-0 frame_desc_head">
                                                                                    Bio
                                                                                </p>
                                                                                <p className="mb-0 mt-3 frame_desc_info"
                                                                                   dangerouslySetInnerHTML={{
                                                                                       __html: selectedData.user.bio
                                                                                           ? selectedData.user.bio
                                                                                           : "--"
                                                                                   }}></p>

                                                                                {/* <p className="mb-0 mt-5 frame_desc_head">
                                          Education
                                        </p> */}
                                                                                {/* <p className="mb-0 mt-3 frame_desc_mid_head">{selectedData.educationsHistoryyData.school}</p>
                                                                    <p className="mb-0 frame_desc_info">{selectedData.educationsHistoryyData.fieldOfStudy}</p> */}

                                                                                {/* <p className="mb-0 mt-4 frame_desc_head">NUS</p>
                                                                    <p className="mb-0 mt-3 frame_desc_info">Bachelor’s of Arts <br /> Majoring in Psychology</p> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-8 p-0">
                                                                        <div
                                                                            className="row m-0 p-4 frame_bottom_border">
                                                                            <div className="col-12 p-0">
                                                                                <p className="mb-0 frame_extra_bold_text">
                                                                                    {checkExpertiseFilter(selectedData)}
                                                                                </p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row m-0 p-4">
                                                                            <div className="col-12 p-0">
                                                                                <div
                                                                                    className="row m-0 align-items-center">
                                                                                    <div className="col-3 p-0">
                                                                                        <p className="mb-0 frame_review_head">
                                                                                            Reviews
                                                                                            ({allReviewsData.length})
                                                                                            {/* {selectedData.user
                                                .avgRatingOfSessions
                                                ? selectedData.user
                                                    .avgRatingOfSessions
                                                : "0"} */}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div
                                                                                        className="mb-2 col-2 p-0 d-flex align-itmes-center justify-content-between">
                                                                                        <Rating
                                                                                            readonly
                                                                                            initialRating={
                                                                                                selectedData.user
                                                                                                    .avgRatingOfSessions
                                                                                                    ? selectedData.user
                                                                                                        .avgRatingOfSessions
                                                                                                    : 0
                                                                                            }
                                                                                            emptySymbol={
                                                                                                <img
                                                                                                    className="rating_stars icon"
                                                                                                    src={rating_gray}
                                                                                                    alt=""
                                                                                                />
                                                                                            }
                                                                                            fullSymbol={
                                                                                                <img
                                                                                                    className="rating_stars icon"
                                                                                                    src={ExpertRatings}
                                                                                                    alt=""
                                                                                                />
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                {!allReviewsData.length ? (
                                                                                    <p className="text-center pt-4">
                                                                                        No ratings found
                                                                                    </p>
                                                                                ) : null}

                                                                                <div className="all_review_list">
                                                                                    {(allReviewsData || [])
                                                                                        .slice(
                                                                                            0,
                                                                                            viewMore
                                                                                                ? allReviewsData.length
                                                                                                : 2
                                                                                        )
                                                                                        .map((val, i) => {
                                                                                            return (
                                                                                                <div
                                                                                                    className="row m-0 mt-3"
                                                                                                    key={i}
                                                                                                >
                                                                                                    <div
                                                                                                        className="col-12 p-0">
                                                                                                        <div
                                                                                                            className="row m-0">
                                                                                                            <div
                                                                                                                className="col-2 p-0">
                                                                                                                <div
                                                                                                                    className="frame_review_user_pic">
                                                                                                                    <img
                                                                                                                        width="40px"
                                                                                                                        height="40px"
                                                                                                                        style={{
                                                                                                                            borderRadius:
                                                                                                                                "50%",
                                                                                                                        }}
                                                                                                                        src={
                                                                                                                            val.user
                                                                                                                                .profilePic
                                                                                                                                ? val.user
                                                                                                                                    .profilePic
                                                                                                                                : ""
                                                                                                                        }
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-7 p-0 ps-1 d-flex align-items-center">
                                                                                                                <p className="mb-0 review_user_name">
                                                                                                                    {val.user.firstName}{" "}
                                                                                                                    {val.user.lastName}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-3 p-0 d-flex align-items-center justify-content-between">
                                                                                                                <Rating
                                                                                                                    readonly
                                                                                                                    initialRating={
                                                                                                                        val.rating
                                                                                                                    }
                                                                                                                    emptySymbol={
                                                                                                                        <img
                                                                                                                            className="rating_stars icon"
                                                                                                                            src={rating_gray}
                                                                                                                            alt=""
                                                                                                                        />
                                                                                                                    }
                                                                                                                    fullSymbol={
                                                                                                                        <img
                                                                                                                            className="rating_stars icon"
                                                                                                                            src={
                                                                                                                                ExpertRatings
                                                                                                                            }
                                                                                                                            alt=""
                                                                                                                        />
                                                                                                                    }
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div
                                                                                                            className="row m-0 mt-2">
                                                                                                            <div
                                                                                                                className="col-12 p-0">
                                                                                                                <p className="mb-0 frame_review">
                                                                                                                    {val.review}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                </div>

                                                                                <div className="row m-0 mt-5 mb-5">
                                                                                    <div
                                                                                        className="col-8 p-0 pe-5 d-flex align-items-center justify-content-end">
                                                                                        {allReviewsData.length > 2 &&
                                                                                        !viewMore ? (
                                                                                            <p
                                                                                                className="mb-0 view_more_reviews cr_pointer w-auto"
                                                                                                onClick={() =>
                                                                                                    setViewMore(true)
                                                                                                }
                                                                                            >
                                                                                                View more reviews
                                                                                            </p>
                                                                                        ) : null}
                                                                                    </div>
                                                                                    {onetoOneSessions.length ? (
                                                                                        <div
                                                                                            className="col-4 p-0 d-flex align-items-center justify-content-end">
                                                                                            <button
                                                                                                className="add_a_review_btn"
                                                                                                onClick={() => showReview(true)}
                                                                                            >
                                                                                                Add A Review
                                                                                            </button>
                                                                                        </div>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {/* Modal-3 */}
                </div>
            </div>


            {/* tab */}
            <div className="d-none d-md-block">
                <div className="row m-0 experts_mobile_view">
                    {showExpertProfile ? (
                        <div className="expert_background p-0">
                            <div className="row m-0 expert_navbar border-bottom border-1 border-secondary py-1">
                                <div className="col-12 p-0 ps-4 pe-4">
                                    <div className="row m-0 pt-3 pb-3 pt-md-4 pb-md-4">
                                        <div
                                            className="col-2 p-0 d-flex align-items-center"
                                            onClick={() => {
                                                if (state && state.userDetails && state.expertSession) {
                                                    navigate("/supporters/sessions")
                                                } else if (state && state.userDetails) {
                                                    navigate("/supporters");
                                                } else {
                                                    showReview(false);
                                                    setViewMore(false);
                                                    setIsClicked(false);
                                                    setshowSlider(false);
                                                }

                                                // navigate("/supporters")
                                            }}
                                        >
                                            <img className="cursor_pointer" src={Back} alt=""/>
                                        </div>
                                        <div className="col-10 p-0 d-flex align-items-center">
                                            <p className="mb-0 alt_nav_heading">Supporter Profile</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {review ? (
                                <>
                                    {!isClicked ? (
                                        <div className="review_part p-0 pt-2 rounded_top">
                                            <div
                                                className="row m-0 p-0 mt-4 pb-4 bg-white w-100 rounded_top border-secondary border-2 round_all">
                                                <div
                                                    className="back_btn_review cursor_pointer py-3 rounded_top"
                                                    onClick={() => showReview(false)}
                                                >
                                                    <img
                                                        className="me-2 ms-3"
                                                        src={CloseBtn}
                                                        width="15px"
                                                        height="15px"
                                                        alt=""
                                                        onClick={() => {
                                                            setIsClicked(false);
                                                        }}
                                                    />
                                                </div>
                                                <div className="row m-0 p-0 align-items-center justify-content-center">
                                                    <div className="col-1"></div>
                                                    <div className="col-10 p-0">
                                                        <div className="row m-0 px-2 py-3  border rounded_profile_top">
                                                            <div className="col-2 p-0 me-2">
                                                                <div className="profile_review_pic">
                                                                    <img
                                                                        src={"/static/png/Male.png"}
                                                                        className="w-100 d-block"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-9">
                                                                <div className="reviewer_name text-dark">
                                                                    {selectedData.user.firstName}{" "}
                                                                    {selectedData.user.lastName}
                                                                </div>
                                                                <div className="reviewer_expertise">
                                                                    {checkExpertiseFilter(selectedData)}
                                                                </div>
                                                                <div className="">
                                                                    <Rating
                                                                        initialRating={raingCount}
                                                                        onChange={(e) => setRaingCount(e)}
                                                                        emptySymbol={
                                                                            <img
                                                                                className="rating_stars me-2"
                                                                                src={rating_gray}
                                                                                alt=""
                                                                            />
                                                                        }
                                                                        fullSymbol={
                                                                            <img
                                                                                className="rating_stars"
                                                                                src={ExpertRatings}
                                                                                alt=""
                                                                            />
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-1"></div>
                                                </div>

                                                <div className="row m-0 p-0 align-items-center mt-3">
                                                    <div className="col-1 p-0"></div>

                                                    <div className="col-10 p-0">
                                                        <div className="">
                                                            <p className="reviewer_date_time_label">
                                                                {" "}
                                                                Date/Time
                                                            </p>

                                                            <select
                                                                className="form-select reviewer_date_time_select w-100 rounded_profile_top"
                                                                aria-label="Default select example"
                                                                onChange={(e) =>
                                                                    setSelectedOneToOneSessionId(e.target.value)
                                                                }
                                                            >
                                                                <option>Select</option>
                                                                {onetoOneSessions.map((v, i) => {
                                                                    return (
                                                                        <option key={i} value={v.id}>
                                                                            {formatOneToOneSessionDate(v)}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-1 p-0"></div>
                                                </div>

                                                <div className="row m-0 p-0 align-items-center mt-3">
                                                    <div className="col-1 p-0"></div>

                                                    <div className="col-10 p-0">
                                                        <div className="">
                                                            <p className="reviewer_date_time_label">
                                                                How is your experience with this Supporter?
                                                            </p>
                                                            <textarea
                                                                placeholder="Tell us about your experience with this Supporter"
                                                                name=""
                                                                onChange={(e) =>
                                                                    setReviewDescription(e.target.value)
                                                                }
                                                                id=""
                                                                cols="20"
                                                                rows="6"
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-1 p-0"></div>
                                                </div>
                                            </div>
                                            <div className="row m-0 p-0 align-items-center mt-3">
                                                <div className="col-11 p-0">
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <button
                                                            className="submit_review_mobile ms-3"
                                                            onClick={submitReview}
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="review_part p-0 pt-2 rounded_top">
                                                <div
                                                    className="row m-0 p-0 mt-4 pb-4 bg-white w-100 rounded_top border-secondary border-2 round_all">
                                                    <div
                                                        className="back_btn_review cursor_pointer py-3 rounded_top"
                                                        onClick={() => showReview(false)}
                                                    >
                                                        <img
                                                            className="me-2 ms-3"
                                                            src={CloseBtn}
                                                            width="15px"
                                                            height="15px"
                                                            alt=""
                                                            onClick={() => {
                                                                setIsClicked(false);
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className="row m-0 p-0 align-items-center justify-content-center">
                                                        <div className="col-1"></div>
                                                        <div
                                                            className="col-10 text-center d-flex justify-content-center align-item-center flex-column">
                                                            <img src={thankupgImg}/>

                                                            <h3 className="mt-4">Thank You</h3>
                                                            <p>Your review has been posted.</p>

                                                            <button
                                                                className="browse_seesion"
                                                                onClick={() => browseSession()}
                                                            >
                                                                Browse Sessions
                                                            </button>
                                                        </div>
                                                        <div className="col-1"></div>
                                                    </div>

                                                    <div className="row m-0 p-0 align-items-center mt-3">
                                                        <div className="col-11 p-0">
                                                            <div
                                                                className="d-flex align-items-center justify-content-end"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : (
                                <div className="row m-0">
                                    <div className="col-12 p-4">
                                        <div className="row m-0">
                                            <div className="col-12 p-0">
                                                <div className="searched_cards p-4 mt-2">
                                                    <div className="row m-0">
                                                        <div className="col-1 p-0 d-flex align-items-center pe-2">
                                                            <div className="experts_flow_card_profile_img">
                                                                <img
                                                                    src={
                                                                        selectedData.user.profilePic
                                                                            ? selectedData.user.profilePic
                                                                            : "/static/png/Male.png"
                                                                    }
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-7 p-0">
                                                            <div className="row m-0">
                                                                <div className="col-12 p-0 ps-2">
                                                                    <p className="mb-0 searched_experts_name">
                                                                        {selectedData.user.firstName}{" "}
                                                                        {selectedData.user.lastName}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="row m-0">
                                                                <div className="col-12 p-0">
                                                                    <p className="mb-0 mt-1 searched_experts_accup ps-2">
                                                                        {moment.tz.guess()}
                                                                    </p>
                                                                    <p className="mb-0 mt-1 searched_experts_session_time ps-2">
                                                                        {moment().tz(moment.tz.guess()).format("hh:mm a")} local
                                                                        time
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {selectedData.user.id !== userDetails.id ? <div
                                                            className="col-3 p-0 d-flex align-items-center justify-content-end">
                                                            <img
                                                                width="32px"
                                                                className={`cr_pointer ${favArr.includes(selectedData.user.id)
                                                                    ? "red_circle"
                                                                    : ""
                                                                }`}
                                                                src={
                                                                    favArr.includes(selectedData.user.id)
                                                                        ? ExpertRedHeart
                                                                        : LikeButton
                                                                }
                                                                alt=" "
                                                                onClick={() =>
                                                                    makeFavouriteCoach(selectedData.user.id)
                                                                }
                                                            />
                                                        </div> : null}

                                                        <div className="col-1">
                                                            <img
                                                                className="cr_pointer exe_frame_sz mt-3"
                                                                src={MoreButton}
                                                                alt=" "
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row m-0 mt-3">
                                                        <div className="col-6">
                                                            <div className="row m-0">
                                                                <div className="col-3 p-0">
                                                                    <p className="mb-0 resp_frame_counts">
                                                                        {selectedData.totalNoOfHours}+
                                                                    </p>
                                                                    <p className="mb-0 resp_frame_accu_achive">
                                                                        Total Hours
                                                                    </p>
                                                                </div>
                                                                <div className="col-3 p-0 ps-3">
                                                                    <p className="mb-0 resp_frame_counts">
                                                                        {selectedData.totalSessionCount}
                                                                    </p>
                                                                    <p className="mb-0 resp_frame_accu_achive">
                                                                        Session
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {selectedData.user.id !== userDetails.id ?
                                                            <div className="col-6 pe-0">
                                                                <div
                                                                    className="row m-0 d-flex justify-content-center align-item-center">
                                                                    <div className="col-3">
                                                                        <button
                                                                            className="frame_btn"
                                                                            onClick={() =>
                                                                                rediretToChatPage(selectedData.user.id)
                                                                            }
                                                                        >
                                                                            Chat
                                                                        </button>
                                                                    </div>

                                                                    <div className="col-3 ps-5 me-5">
                                                                        <button
                                                                            className="resp_frame_btn active_resp_frame_btn"
                                                                            onClick={() => {
                                                                                inResponsiveView(true);
                                                                                getBookSessionDetails(selectedData);
                                                                            }}
                                                                        >
                                                                            Book
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div> : null}

                                                    </div>
                                                </div>

                                                <div className="row m-0 expert_tab_bio_parent my-4">
                                                    <p className="m-0 expert_tab_bio my-2 pb-2">Biography</p>
                                                    {/* <div className="col-5 education_bio p-3">
                            <p className="m-0">Education</p>
                            <span className="fs-6 fw-bold">King’s College, London, UK</span><br />
                            <span className="expert_edu_dis">Master’s in Psychology Speech Impediments</span> <br />
                            <span className="fs-6 fw-bold my-1">NUS</span> <br />
                            <span className="expert_edu_dis">Bachelor’s of Arts</span>
                            <span className="expert_edu_dis">Majoring in Psychology</span>
                          </div> */}
                                                    <div className="col-7 p-3 education_bio border-0">
                                                        <p className="m-0">
                                                            {selectedData.user.name
                                                                ? selectedData.user.name
                                                                : "-Speech Therapist"}</p>

                                                        <span className="expert_edu_dis" dangerouslySetInnerHTML={{
                                                            __html: selectedData.user.bio
                                                                ? selectedData.user.bio
                                                                : "--"
                                                        }}></span>


                                                        {/* <span className="expert_edu_dis">I’m a speech therapist with over 10 years’ of experience helping my clients overcome speech impediments. Like the movie The King’s Speech, I help top professionals become more confident in themselves.</span> <br />
                            <span className="expert_edu_dis mt-1">Well-being is also tied to self-confidence, and as a therapist, I have seen my clients grow from hestitant individuals to highly creative and expressive professionals through my programme.</span> */}
                                                    </div>
                                                </div>

                                                {/* <div className="row m-0">
                          <div className="col-12 p-0">
                            <div className="searched_cards p-3 mt-2">
                              <div className="row m-0">
                                <div className="col-12 p-0">
                                  <p className="mb-0 frame_profile_heading">
                                    {checkExpertiseFilter(selectedData)}
                                  </p>
                                  <p className="mb-0 mt-2 frame_profile_info">
                                    {selectedData.user.bio
                                      ? selectedData.user.bio
                                      : "--"}
                                  </p>
                                </div>
                              </div>

                              <div className="row m-0 mt-3">
                                <div className="col-12 p-0">
                                  <p className="mb-0 frame_profile_heading">
                                    Education
                                  </p>
                                  <p className='mb-0 mt-2 frame_profile_univercity'>{selectedData.educationsHistoryyData.school}</p>
                                                      <p className='mb-0 mt-1 frame_profile_degree'>{selectedData.educationsHistoryyData.fieldOfStudy}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}


                                                <div className="row m-0 mt-3">
                                                    <div className="col-12 p-0">
                                                        <div className="searched_cards p-4">
                                                            <div className="row m-0">
                                                                <div className="col-2 p-0 mb-2">
                                                                    <p className="m-0 expert_tab_bio py-2 ">Rating</p>
                                                                </div>
                                                                <div className="col-7">
                                                                </div>
                                                                <div className="col-3 p-0">
                                                                    {onetoOneSessions.length ? (
                                                                        <button
                                                                            className="resp_add_review_btn"
                                                                            onClick={() => showReview(true)}
                                                                        >
                                                                            Add a Review
                                                                        </button>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className="row m-0">
                                                                <div className="col-9 p-0">
                                                                    <p className="mb-0 frame_review_head">
                                                                        Reviews ({allReviewsData.length})
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    className="col-3 p-0 d-flex align-itmes-center justify-content-end">
                                                                    <p className="mb-0 resp_ratings">
                                                                        <img
                                                                            className="rating_stars"
                                                                            src={ExpertRatings}
                                                                            alt=""
                                                                        />{" "}
                                                                        <span>
                                      {selectedData.user.avgRatingOfSessions
                                          ? selectedData.user.avgRatingOfSessions
                                          : "0"}
                                    </span>
                                                                        /5
                                                                    </p>
                                                                </div>
                                                            </div>


                                                            {(allReviewsData || []).map((val, i) => {
                                                                return (
                                                                    <div className="row m-0 mt-3" key={i}>
                                                                        <div className="col-12 p-0">
                                                                            <div className="row m-0">
                                                                                <div className="col-1 p-0">
                                                                                    <div
                                                                                        className="frame_review_user_pic">
                                                                                        <img
                                                                                            width="40px"
                                                                                            height="40px"
                                                                                            style={{borderRadius: "50%"}}
                                                                                            src={
                                                                                                val.user.profilePic
                                                                                                    ? val.user.profilePic
                                                                                                    : ""
                                                                                            }
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="col-8 p-0 ps-1 d-flex align-items-center">
                                                                                    <p className="mb-0 review_user_name">
                                                                                        {val.user.firstName}{" "}
                                                                                        {val.user.lastName}
                                                                                    </p>
                                                                                </div>
                                                                                <div
                                                                                    className="col-3 p-0 d-flex align-items-center justify-content-end">
                                                                                    <Rating
                                                                                        readonly
                                                                                        initialRating={val.rating}
                                                                                        emptySymbol={
                                                                                            <img
                                                                                                className="rating_stars icon"
                                                                                                src={rating_gray}
                                                                                                alt=""
                                                                                            />
                                                                                        }
                                                                                        fullSymbol={
                                                                                            <img
                                                                                                className="rating_stars icon"
                                                                                                src={ExpertRatings}
                                                                                                alt=""
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="row m-0 mt-2">
                                                                                <div className="col-12 p-0">
                                                                                    <p className="mb-0 frame_review">
                                                                                        {val.review}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            <ResponsiveNav headerName="Expert" heading="1-to-1 Sessions"/>
                            <div className="col-12 p-4 expert_background p-0">
                                <div className="row m-0">
                                    <div className="col-12 p-0">
                                        <div class="input-group huddle_top_input">
                                            <input
                                                type="text"
                                                class="form-control all_exp_page_lg_input"
                                                placeholder="Search keyword"
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                            />
                                            <span
                                                class="input-group-text all_exp_page_lg_span"
                                                id="basic-addon2"
                                            >
                        <img src={Search} alt=""/>
                      </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row m-0 mt-3">
                                    <div className="col-12 p-2 d-flex align-items-center mob_search_top_btn_overflow">
                                        <button
                                            className={`mob_search_top_btn px-3 py-2 me-2 ${selectedFilterCategory === "all"
                                                ? "mob_search_top_all_btn mob_search_top_btn_act"
                                                : ""
                                            }`}
                                            onClick={() => setSelectedFilterCategory("all")}
                                        >
                                            All
                                        </button>
                                        <button
                                            className={`mob_search_top_btn px-3 py-2 me-2 ${selectedFilterCategory === "Coach"
                                                ? "mob_search_top_all_btn mob_search_top_btn_act"
                                                : ""
                                            }`}
                                            onClick={() => setSelectedFilterCategory("Coach")}
                                        >
                                            Coach
                                        </button>
                                        <button
                                            className={`mob_search_top_btn px-3 py-2 me-2 ${selectedFilterCategory === "Counsellor"
                                                ? "mob_search_top_all_btn mob_search_top_btn_act"
                                                : ""
                                            }`}
                                            onClick={() => setSelectedFilterCategory("Counsellor")}
                                        >
                                            Counsellor
                                        </button>
                                        <button
                                            className={`mob_search_top_btn px-3 py-2 me-2 ${selectedFilterCategory === "Peer Supporter"
                                                ? "mob_search_top_all_btn mob_search_top_btn_act"
                                                : ""
                                            }`}
                                            onClick={() => setSelectedFilterCategory("Peer Supporter")}
                                        >
                                            Peer Supporter
                                        </button>
                                        <button
                                            className={`mob_search_top_btn px-3 py-2 me-2 ${selectedFilterCategory === "Therapist"
                                                ? "mob_search_top_all_btn mob_search_top_btn_act"
                                                : ""
                                            }`}
                                            onClick={() => setSelectedFilterCategory("Social Worker")}
                                        >
                                            Therapist
                                        </button>
                                        <button
                                            className={`mob_search_top_btn px-3 py-2 me-2 ${selectedFilterCategory === "Trainer"
                                                ? "mob_search_top_all_btn mob_search_top_btn_act"
                                                : ""
                                            }`}
                                            onClick={() => setSelectedFilterCategory("Trainer")}
                                        >
                                            Trainer
                                        </button>
                                    </div>
                                </div>

                                <div className="row m-0">
                                    <div className="col-12 p-0">
                                        <div
                                            className="px-2 expert_search_slider d-flex align-items-center justify-content-between">
                                            <button
                                                className={`exe_search_slider_btn ${selectedTab === "search"
                                                    ? "exe_search_slider_btn_act"
                                                    : ""
                                                }`}
                                                onClick={() => setSelectedTabFunc("search")}
                                            >
                                                Search
                                            </button>
                                            <button
                                                className={`exe_search_slider_btn ${selectedTab === "myCoach"
                                                    ? "exe_search_slider_btn_act"
                                                    : ""
                                                }`}
                                                onClick={() => setSelectedTabFunc("myCoach")}
                                            >
                                                My Supporters
                                            </button>
                                            <button
                                                className={`exe_search_slider_btn ${selectedTab === "saved"
                                                    ? "exe_search_slider_btn_act"
                                                    : ""
                                                }`}
                                                onClick={() => setSelectedTab("saved")}
                                            >
                                                Saved
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {!(selectedTab === "saved" ? getSavedData(userData) : userData)
                                    .filter((u) =>
                                        search
                                            ? ((u.user.firstName + " " + u.user.lastName).toLowerCase().indexOf(search.toLowerCase()) >= 0) || (u.user.bio && u.user.bio.toLowerCase().search(search.toLowerCase()) != "-1" ? true : false)
                                            : true
                                    )
                                    .filter((val) =>
                                        selectedFilterCategory === "all"
                                            ? val
                                            : selectedFilterCategory === checkExpertiseFilter(val)
                                    ).length ? (
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <img src={expert_icon_empty} alt=""/>
                                        <h4 className="mb-3 no_result_text">No Results</h4>
                                        <p className="text-center no_result_text_desc mb-3 w-50">
                                            {selectedTab === "search" ? "There are no supporters related to your search. Try to find another keyword."
                                                : selectedTab === "myCoach" ? "You have not booked any supporters for now. Try to book a 1-to-1 Session with a coach."
                                                    : "You have not saved any supporters for now. Click ❤️ while you search supporters."}
                                        </p>
                                    </div>
                                ) : null}

                                {(selectedTab === "saved" ? getSavedData(userData) : userData)
                                    .filter((v) =>
                                        selectedFilterCategory === "all"
                                            ? v
                                            : selectedFilterCategory === checkExpertiseFilter(v)
                                    )
                                    .filter((u) =>
                                        search
                                            ? ((u.user.firstName + " " + u.user.lastName).toLowerCase().indexOf(search.toLowerCase()) >= 0) || (u.user.bio && u.user.bio.toLowerCase().search(search.toLowerCase()) != "-1" ? true : false)
                                            : true
                                    )
                                    .map((v, i) => {
                                        return (
                                            <div className="row m-0" key={i}>
                                                <div className="col-12 p-0">
                                                    <div className="searched_cards p-3 px-4 mt-3">
                                                        <div className="row m-0">
                                                            <div
                                                                className="col-md-1 col-3 p-0 d-flex align-items-center">
                                                                <div className="experts_flow_card_profile_img">
                                                                    <img
                                                                        src={
                                                                            v.user.profilePic
                                                                                ? v.user.profilePic
                                                                                : "/static/png/Male.png"
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-9 col-9 p-0 ps-md-2 p-0">
                                                                <div className="row m-0">
                                                                    <div className="col-10 p-0">
                                                                        <p
                                                                            className="mb-0 searched_experts_name cursor_pointer"
                                                                            onClick={() => {
                                                                                openSideBox(v);
                                                                            }}
                                                                        >
                                                                            {v.user.firstName} {v.user.lastName}
                                                                        </p>
                                                                    </div>
                                                                    {v.user.id !== userDetails.id ? <div
                                                                        className="col-2  p-0 d-flex justify-content-end mt-1">
                                                                        <img
                                                                            src={
                                                                                favArr.includes(v.user.id)
                                                                                    ? ExpertRedHeart
                                                                                    : ExpertGrayHeart
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </div> : null}

                                                                </div>

                                                                <div className="row m-0">
                                                                    <div className="col-12 p-0">
                                                                        <p className="mb-0 mt-1 searched_experts_accup">
                                                                            {checkExpertiseFilter(v)}
                                                                        </p>
                                                                        {/* <p className="mb-0 mt-1 searched_experts_accup">
                                      Singapore
                                    </p> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {v.user.id !== userDetails.id ?
                                                                <div className="col-md-2 pe-0 d-none d-md-flex">
                                                                    <button
                                                                        className="chat_now_btn"
                                                                        onClick={() => rediretToChatPage(v.user.id)}
                                                                    >
                                                                        Chat Now
                                                                    </button>
                                                                </div> : null}

                                                        </div>

                                                        <div className="row m-0 mt-2">
                                                            <div className="col-12 p-0">
                                                                <p className="mb-0 exe_searched_ratings">
                                                                    {" "}
                                                                    <img src={SMStar} alt=""/> {v.user
                                                                    .avgRatingOfSessions
                                                                    ? v.user
                                                                        .avgRatingOfSessions
                                                                    : 0} &nbsp;{" "}
                                                                    {/* <span className="reviews">80 Reviews</span>{" "} */}
                                                                    &nbsp;{" "}
                                                                    <span className="highlights">
                                    Supported {v.totalNoOfHours || 0}H on Kaleidoscope
                                  </span>
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="row m-0 mt-2">
                                                            <div className="col-12 p-0">
                                                                <p className="mb-0 experts_qouts"
                                                                   dangerouslySetInnerHTML={{__html: v.user.bio ? v.user.bio : "--"}}></p>
                                                            </div>
                                                        </div>

                                                        <div className="row m-0 mt-2 d-block d-md-none">
                                                            <div className="col-12 p-0">
                                                                <button
                                                                    className="chat_now_btn"
                                                                    onClick={() => rediretToChatPage(v.user.id)}
                                                                >
                                                                    Chat Now
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* mobile */}
            <div className="d-block d-md-none">
                <div className="row m-0 experts_mobile_view">
                    {showExpertProfile ? (
                        <div className="expert_background p-0">
                            <div className="row m-0 expert_navbar border-bottom border-1 border-secondary py-1">
                                <div className="col-12 p-0 ps-4 pe-4">
                                    <div className="row m-0 pt-3 pb-3 pt-md-4 pb-md-4">
                                        <div
                                            className="col-2 p-0 d-flex align-items-center"
                                            onClick={() => {
                                                if (state && state.userDetails && state.expertSession) {
                                                    navigate("/supporters/sessions")
                                                } else if (state && state.userDetails) {
                                                    navigate("/supporters");
                                                } else {
                                                    showReview(false);
                                                    setViewMore(false);
                                                    setIsClicked(false);
                                                    setshowSlider(false);
                                                    setShowExpertProfile(false);
                                                }

                                                // navigate("/supporters")
                                            }}
                                        >
                                            <img className="cursor_pointer" src={Back} alt=""/>
                                        </div>
                                        <div className="col-10 p-0 d-flex align-items-center">
                                            <p className="mb-0 alt_nav_heading">Supporter Profile</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {review ? (
                                <>
                                    {!isClicked ? (
                                        <div className="review_part p-0 pt-2 rounded_top">
                                            <div
                                                className="row m-0 p-0 mt-4 pb-4 bg-white w-100 rounded_top border-secondary border-2 round_all">
                                                <div
                                                    className="back_btn_review cursor_pointer py-3 rounded_top"
                                                    onClick={() => showReview(false)}
                                                >
                                                    <img
                                                        className="me-2 ms-3"
                                                        src={CloseBtn}
                                                        width="15px"
                                                        height="15px"
                                                        alt=""
                                                        onClick={() => {
                                                            setIsClicked(false);
                                                        }}
                                                    />
                                                </div>
                                                <div className="row m-0 p-0 align-items-center justify-content-center">
                                                    <div className="col-1"></div>
                                                    <div className="col-10 p-0">
                                                        <div className="row m-0 px-2 py-3  border rounded_profile_top">
                                                            <div className="col-2 p-0 me-2">
                                                                <div className="profile_review_pic">
                                                                    <img
                                                                        src={"/static/png/Male.png"}
                                                                        className="w-100 d-block"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-9">
                                                                <div className="reviewer_name text-dark">
                                                                    {selectedData.user.firstName}{" "}
                                                                    {selectedData.user.lastName}
                                                                </div>
                                                                <div className="reviewer_expertise">
                                                                    {checkExpertiseFilter(selectedData)}
                                                                </div>
                                                                <div className="">
                                                                    <Rating
                                                                        initialRating={raingCount}
                                                                        onChange={(e) => setRaingCount(e)}
                                                                        emptySymbol={
                                                                            <img
                                                                                className="rating_stars me-2"
                                                                                src={rating_gray}
                                                                                alt=""
                                                                            />
                                                                        }
                                                                        fullSymbol={
                                                                            <img
                                                                                className="rating_stars"
                                                                                src={ExpertRatings}
                                                                                alt=""
                                                                            />
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-1"></div>
                                                </div>

                                                <div className="row m-0 p-0 align-items-center mt-3">
                                                    <div className="col-1 p-0"></div>

                                                    <div className="col-10 p-0">
                                                        <div className="">
                                                            <p className="reviewer_date_time_label">
                                                                {" "}
                                                                Date/Time
                                                            </p>

                                                            <select
                                                                className="form-select reviewer_date_time_select w-100 rounded_profile_top"
                                                                aria-label="Default select example"
                                                                onChange={(e) =>
                                                                    setSelectedOneToOneSessionId(e.target.value)
                                                                }
                                                            >
                                                                <option>Select</option>
                                                                {onetoOneSessions.map((v, i) => {
                                                                    return (
                                                                        <option key={i} value={v.id}>
                                                                            {formatOneToOneSessionDate(v)}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-1 p-0"></div>
                                                </div>

                                                <div className="row m-0 p-0 align-items-center mt-3">
                                                    <div className="col-1 p-0"></div>

                                                    <div className="col-10 p-0">
                                                        <div className="">
                                                            <p className="reviewer_date_time_label">
                                                                How is your experience with this Supporter?
                                                            </p>
                                                            <textarea
                                                                placeholder="Tell us about your experience with this Supporter"
                                                                name=""
                                                                onChange={(e) =>
                                                                    setReviewDescription(e.target.value)
                                                                }
                                                                id=""
                                                                cols="20"
                                                                rows="6"
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-1 p-0"></div>
                                                </div>
                                            </div>
                                            <div className="row m-0 p-0 align-items-center mt-3">
                                                <div className="col-11 p-0">
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <button
                                                            className="submit_review_mobile ms-3"
                                                            onClick={submitReview}
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="review_part p-0 pt-2 rounded_top">
                                                <div
                                                    className="row m-0 p-0 mt-4 pb-4 bg-white w-100 rounded_top border-secondary border-2 round_all">
                                                    <div
                                                        className="back_btn_review cursor_pointer py-3 rounded_top"
                                                        onClick={() => showReview(false)}
                                                    >
                                                        <img
                                                            className="me-2 ms-3"
                                                            src={CloseBtn}
                                                            width="15px"
                                                            height="15px"
                                                            alt=""
                                                            onClick={() => {
                                                                setIsClicked(false);
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className="row m-0 p-0 align-items-center justify-content-center">
                                                        <div className="col-1"></div>
                                                        <div
                                                            className="col-10 text-center d-flex justify-content-center align-item-center flex-column">
                                                            <img src={thankupgImg}/>

                                                            <h3 className="mt-4">Thank You</h3>
                                                            <p>Your review has been posted.</p>

                                                            <button
                                                                className="browse_seesion"
                                                                onClick={() => browseSession()}
                                                            >
                                                                Browse Sessions
                                                            </button>
                                                        </div>
                                                        <div className="col-1"></div>
                                                    </div>

                                                    <div className="row m-0 p-0 align-items-center mt-3">
                                                        <div className="col-11 p-0">
                                                            <div
                                                                className="d-flex align-items-center justify-content-end"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : (
                                <div className="row m-0">
                                    <div className="col-12 p-4">
                                        <div className="row m-0">
                                            <div className="col-12 p-0">
                                                <div className="searched_cards p-3 mt-2">
                                                    <div className="row m-0">
                                                        <div className="col-3 p-0 d-flex align-items-center">
                                                            <div className="experts_flow_card_profile_img">
                                                                <img
                                                                    src={
                                                                        selectedData.user.profilePic
                                                                            ? selectedData.user.profilePic
                                                                            : "/static/png/Male.png"
                                                                    }
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-7 p-0">
                                                            <div className="row m-0">
                                                                <div className="col-12 p-0">
                                                                    <p className="mb-0 searched_experts_name">
                                                                        {selectedData.user.firstName}{" "}
                                                                        {selectedData.user.lastName}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="row m-0">
                                                                <div className="col-12 p-0">
                                                                    <p className="mb-0 mt-1 searched_experts_accup">
                                                                        {moment.tz.guess()}
                                                                    </p>
                                                                    <p className="mb-0 mt-1 searched_experts_session_time">
                                                                        {moment().tz(moment.tz.guess()).format("hh:mm a")} local
                                                                        time
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {selectedData.user.id !== userDetails.id ? <div
                                                            className="col-2 p-0 d-flex align-items-center justify-content-end">
                                                            <img
                                                                width="32px"
                                                                className={`cr_pointer ${favArr.includes(selectedData.user.id)
                                                                    ? "red_circle"
                                                                    : ""
                                                                }`}
                                                                src={
                                                                    favArr.includes(selectedData.user.id)
                                                                        ? ExpertRedHeart
                                                                        : LikeButton
                                                                }
                                                                alt=" "
                                                                onClick={() =>
                                                                    makeFavouriteCoach(selectedData.user.id)
                                                                }
                                                            />
                                                        </div> : null}


                                                    </div>

                                                    {selectedData.user.id !== userDetails.id ?
                                                        <div className="row m-0 mt-3">
                                                            <div
                                                                className="col-12 p-0 d-flex align-items-center justify-content-between">
                                                                <button
                                                                    className="resp_frame_btn active_resp_frame_btn"
                                                                    onClick={() => {
                                                                        inResponsiveView(true);
                                                                        getBookSessionDetails(selectedData);
                                                                    }}
                                                                >
                                                                    Book
                                                                </button>
                                                                <button
                                                                    className="frame_btn"
                                                                    onClick={() =>
                                                                        rediretToChatPage(selectedData.user.id)
                                                                    }
                                                                >
                                                                    Chat
                                                                </button>
                                                                <img
                                                                    className="cr_pointer exe_frame_sz"
                                                                    src={MoreButton}
                                                                    alt=" "
                                                                />
                                                            </div>
                                                        </div> : null}


                                                    <div className="row m-0 mt-3">
                                                        <div className="col-6 p-0">
                                                            <p className="mb-0 resp_frame_counts">
                                                                {selectedData.totalNoOfHours}+
                                                            </p>
                                                            <p className="mb-0 resp_frame_accu_achive">
                                                                Total Hours
                                                            </p>
                                                        </div>
                                                        <div className="col-6 p-0">
                                                            <p className="mb-0 resp_frame_counts">
                                                                {selectedData.totalSessionCount}
                                                            </p>
                                                            <p className="mb-0 resp_frame_accu_achive">
                                                                Session
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row m-0 mt-3">
                                                    <div
                                                        className="col-12 p-0 d-flex align-items-center justify-content-center">
                                                        <div className="frame_slider p-1">
                                                            <button
                                                                className={`frame_slid_btn_one ${responsiveMobileProfileTab === "Biography"
                                                                    ? "act_frame_slid_btn"
                                                                    : ""
                                                                }`}
                                                                onClick={() =>
                                                                    setResponsiveMobileProfileTab("Biography")
                                                                }
                                                            >
                                                                Biography
                                                            </button>
                                                            <button
                                                                className={`frame_slid_btn_two ${responsiveMobileProfileTab === "Rating"
                                                                    ? "act_frame_slid_btn"
                                                                    : ""
                                                                }`}
                                                                onClick={() =>
                                                                    setResponsiveMobileProfileTab("Rating")
                                                                }
                                                            >
                                                                Rating
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                {responsiveMobileProfileTab === "Rating" ? (
                                                    <div className="row m-0 mt-3">
                                                        <div className="col-12 p-0">
                                                            <div className="searched_cards p-3">
                                                                <div className="row m-0">
                                                                    <div className="col-9 p-0">
                                                                        <p className="mb-0 frame_review_head">
                                                                            Reviews ({allReviewsData.length})
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className="col-3 p-0 d-flex align-itmes-center justify-content-end">
                                                                        <p className="mb-0 resp_ratings">
                                                                            <img
                                                                                className="rating_stars"
                                                                                src={ExpertRatings}
                                                                                alt=""
                                                                            />{" "}
                                                                            <span>
                                        {selectedData.user.avgRatingOfSessions
                                            ? selectedData.user.avgRatingOfSessions
                                            : "0"}
                                      </span>
                                                                            /5
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="row m-0 mt-3">
                                                                    <div className="col-12 p-0">
                                                                        {onetoOneSessions.length ? (
                                                                            <button
                                                                                className="resp_add_review_btn"
                                                                                onClick={() => showReview(true)}
                                                                            >
                                                                                Add a Review
                                                                            </button>
                                                                        ) : null}
                                                                    </div>
                                                                </div>

                                                                {(allReviewsData || []).map((val, i) => {
                                                                    return (
                                                                        <div className="row m-0 mt-3" key={i}>
                                                                            <div className="col-12 p-0">
                                                                                <div className="row m-0">
                                                                                    <div className="col-2 p-0">
                                                                                        <div
                                                                                            className="frame_review_user_pic">
                                                                                            <img
                                                                                                width="40px"
                                                                                                height="40px"
                                                                                                style={{borderRadius: "50%"}}
                                                                                                src={
                                                                                                    val.user.profilePic
                                                                                                        ? val.user.profilePic
                                                                                                        : ""
                                                                                                }
                                                                                                alt=""
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-6 p-0 ps-1 d-flex align-items-center">
                                                                                        <p className="mb-0 review_user_name">
                                                                                            {val.user.firstName}{" "}
                                                                                            {val.user.lastName}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-4 p-0 d-flex align-items-center justify-content-end">
                                                                                        <Rating
                                                                                            readonly
                                                                                            initialRating={val.rating}
                                                                                            emptySymbol={
                                                                                                <img
                                                                                                    className="rating_stars icon"
                                                                                                    src={rating_gray}
                                                                                                    alt=""
                                                                                                />
                                                                                            }
                                                                                            fullSymbol={
                                                                                                <img
                                                                                                    className="rating_stars icon"
                                                                                                    src={ExpertRatings}
                                                                                                    alt=""
                                                                                                />
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row m-0 mt-2">
                                                                                    <div className="col-12 p-0">
                                                                                        <p className="mb-0 frame_review">
                                                                                            {val.review}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="row m-0 mt-3">
                                                        <div className="col-12 p-0">
                                                            <div className="searched_cards p-3">
                                                                <div className="row m-0">
                                                                    <div className="col-12 p-0">
                                                                        <p className="mb-0 frame_profile_heading">
                                                                            {checkExpertiseFilter(selectedData)}
                                                                        </p>

                                                                        <p className="mb-0 mt-2 frame_profile_info"
                                                                           dangerouslySetInnerHTML={{
                                                                               __html: selectedData.user.bio
                                                                                   ? selectedData.user.bio
                                                                                   : "--"
                                                                           }}></p>
                                                                    </div>
                                                                </div>

                                                                <div className="row m-0 mt-3">
                                                                    <div className="col-12 p-0">
                                                                        {/* <p className="mb-0 frame_profile_heading">
                                      Education
                                    </p> */}
                                                                        {/* <p className='mb-0 mt-2 frame_profile_univercity'>{selectedData.educationsHistoryyData.school}</p>
                                                        <p className='mb-0 mt-1 frame_profile_degree'>{selectedData.educationsHistoryyData.fieldOfStudy}</p> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            <ResponsiveNav headerName="Expert" heading="All Supporters"/>
                            <div className="col-12 p-4 expert_background p-0">
                                <div className="row m-0">
                                    <div className="col-12 p-0">
                                        <div class="input-group huddle_top_input">
                                            <input
                                                type="text"
                                                class="form-control all_exp_page_lg_input"
                                                placeholder="Search keyword"
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                            />
                                            <span
                                                class="input-group-text all_exp_page_lg_span"
                                                id="basic-addon2"
                                            >
                        <img src={Search} alt=""/>
                      </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row m-0 mt-3">
                                    <div className="col-12 p-2 d-flex align-items-center mob_search_top_btn_overflow">
                                        <button
                                            className={`mob_search_top_btn px-3 py-2 me-2 ${selectedFilterCategory === "all"
                                                ? "mob_search_top_all_btn mob_search_top_btn_act"
                                                : ""
                                            }`}
                                            onClick={() => setSelectedFilterCategory("all")}
                                        >
                                            All
                                        </button>
                                        <button
                                            className={`mob_search_top_btn px-3 py-2 me-2 ${selectedFilterCategory === "Coach"
                                                ? "mob_search_top_all_btn mob_search_top_btn_act"
                                                : ""
                                            }`}
                                            onClick={() => setSelectedFilterCategory("Coach")}
                                        >
                                            Coach
                                        </button>
                                        <button
                                            className={`mob_search_top_btn px-3 py-2 me-2 ${selectedFilterCategory === "Counsellor"
                                                ? "mob_search_top_all_btn mob_search_top_btn_act"
                                                : ""
                                            }`}
                                            onClick={() => setSelectedFilterCategory("Counsellor")}
                                        >
                                            Counsellor
                                        </button>
                                        <button
                                            className={`mob_search_top_btn px-3 py-2 me-2 ${selectedFilterCategory === "Peer Supporter"
                                                ? "mob_search_top_all_btn mob_search_top_btn_act"
                                                : ""
                                            }`}
                                            onClick={() => setSelectedFilterCategory("Peer Supporter")}
                                        >
                                            Peer Supporter
                                        </button>
                                        <button
                                            className={`mob_search_top_btn px-3 py-2 me-2 ${selectedFilterCategory === "Therapist"
                                                ? "mob_search_top_all_btn mob_search_top_btn_act"
                                                : ""
                                            }`}
                                            onClick={() => setSelectedFilterCategory("Therapist")}
                                        >
                                            Therapist
                                        </button>
                                        <button
                                            className={`mob_search_top_btn px-3 py-2 me-2 ${selectedFilterCategory === "Trainer"
                                                ? "mob_search_top_all_btn mob_search_top_btn_act"
                                                : ""
                                            }`}
                                            onClick={() => setSelectedFilterCategory("Trainer")}
                                        >
                                            Trainer
                                        </button>
                                    </div>
                                </div>

                                <div className="row m-0">
                                    <div className="col-12 p-0">
                                        <div
                                            className="px-2 expert_search_slider d-flex align-items-center justify-content-between">
                                            <button
                                                className={`exe_search_slider_btn ${selectedTab === "search"
                                                    ? "exe_search_slider_btn_act"
                                                    : ""
                                                }`}
                                                onClick={() => setSelectedTabFunc("search")}
                                            >
                                                Search
                                            </button>
                                            <button
                                                className={`exe_search_slider_btn ${selectedTab === "myCoach"
                                                    ? "exe_search_slider_btn_act"
                                                    : ""
                                                }`}
                                                onClick={() => setSelectedTabFunc("myCoach")}
                                            >
                                                My Supporters
                                            </button>
                                            <button
                                                className={`exe_search_slider_btn ${selectedTab === "saved"
                                                    ? "exe_search_slider_btn_act"
                                                    : ""
                                                }`}
                                                onClick={() => setSelectedTab("saved")}
                                            >
                                                Saved
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {!(selectedTab === "saved" ? getSavedData(userData) : userData)
                                    .filter((u) =>
                                        search
                                            ? ((u.user.firstName + " " + u.user.lastName).toLowerCase().indexOf(search.toLowerCase()) >= 0) || (u.user.bio && u.user.bio.toLowerCase().search(search.toLowerCase()) != "-1" ? true : false)
                                            : true
                                    )
                                    .filter((val) =>
                                        selectedFilterCategory === "all"
                                            ? val
                                            : selectedFilterCategory === checkExpertiseFilter(val)
                                    ).length ? (
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <img src={expert_icon_empty} alt=""/>
                                        <h4 className="mb-3 no_result_text">No Results</h4>
                                        <p className="text-center no_result_text_desc mb-3 w-100">
                                            {selectedTab === "search" ? "There are no supporters related to your search. Try to find another keyword."
                                                : selectedTab === "myCoach" ? "You have not booked any supporter for now. Try to book a 1-to-1 Session with a coach."
                                                    : "You have not saved any supporters for now. Click ❤️ while you search supporters."}
                                        </p>
                                    </div>
                                ) : null}

                                {(selectedTab === "saved" ? getSavedData(userData) : userData)
                                    .filter((v) =>
                                        selectedFilterCategory === "all"
                                            ? v
                                            : selectedFilterCategory === checkExpertiseFilter(v)
                                    )
                                    .filter((u) =>
                                        search
                                            ? ((u.user.firstName + " " + u.user.lastName).toLowerCase().indexOf(search.toLowerCase()) >= 0) || (u.user.bio && u.user.bio.toLowerCase().search(search.toLowerCase()) != "-1" ? true : false)
                                            : true
                                    )
                                    .map((v, i) => {
                                        return (
                                            <div className="row m-0" key={i}>
                                                <div className="col-12 p-0">
                                                    <div className="searched_cards p-3 px-4 mt-3">
                                                        <div className="row m-0">
                                                            <div
                                                                className="col-md-1 col-3 p-0 d-flex align-items-center">
                                                                <div className="experts_flow_card_profile_img">
                                                                    <img
                                                                        src={
                                                                            v.user.profilePic
                                                                                ? v.user.profilePic
                                                                                : "/static/png/Male.png"
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-9 col-9 p-0 ps-md-2 p-0">
                                                                <div className="row m-0">
                                                                    <div className="col-10 p-0">
                                                                        <p
                                                                            className="mb-0 searched_experts_name cursor_pointer"
                                                                            onClick={() => {
                                                                                openSideBox(v);
                                                                            }}
                                                                        >
                                                                            {v.user.firstName} {v.user.lastName}
                                                                        </p>
                                                                    </div>

                                                                    {v.user.id !== userDetails.id ? <div
                                                                        className="col-2  p-0 d-flex justify-content-end mt-1">
                                                                        <img
                                                                            src={
                                                                                favArr.includes(v.user.id)
                                                                                    ? ExpertRedHeart
                                                                                    : ExpertGrayHeart
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </div> : null}

                                                                </div>

                                                                <div className="row m-0">
                                                                    <div className="col-12 p-0">
                                                                        <p className="mb-0 mt-1 searched_experts_accup">
                                                                            {checkExpertiseFilter(v)}
                                                                        </p>
                                                                        {/* <p className="mb-0 mt-1 searched_experts_accup">
                                      Singapore
                                    </p> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2 pe-0 d-none d-md-flex">
                                                                <button
                                                                    className="chat_now_btn"
                                                                    onClick={() => rediretToChatPage(v.user.id)}
                                                                >
                                                                    Chat Now
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="row m-0 mt-2">
                                                            <div className="col-12 p-0">
                                                                <p className="mb-0 exe_searched_ratings">
                                                                    {" "}
                                                                    <img src={SMStar} alt=""/> {v.user
                                                                    .avgRatingOfSessions
                                                                    ? v.user
                                                                        .avgRatingOfSessions
                                                                    : 0} &nbsp;{" "}
                                                                    {/* <span className="reviews">80 Reviews</span>{" "} */}
                                                                    &nbsp;{" "}
                                                                    <span className="highlights">
                                    Supported {v.totalNoOfHours || 0}H on Kaleidoscope
                                  </span>
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="row m-0 mt-2">
                                                            <div className="col-12 p-0">
                                                                <p className="mb-0 experts_qouts"
                                                                   dangerouslySetInnerHTML={{__html: v.user.bio ? v.user.bio : "--"}}></p>
                                                            </div>
                                                        </div>


                                                        {v.user.id !== userDetails.id ?
                                                            <div className="row m-0 mt-2 d-block d-md-none">
                                                                <div className="col-12 p-0">
                                                                    <button
                                                                        className="chat_now_btn"
                                                                        onClick={() => rediretToChatPage(v.user.id)}
                                                                    >
                                                                        Chat Now
                                                                    </button>
                                                                </div>
                                                            </div> : null}

                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </>
                    )}
                </div>
            </div>

            {showBookForm ? (
                <ExpertBookModal
                    showBookForm={showBookForm}
                    selectedExpertData={selectedData}
                    onChange={onChange}
                    value={value}
                    activeSlotTab={activeSlotTab}
                    setActiveSlotTab={setActiveSlotTab}
                    timeExchnageSlots={timeExchnageSlots}
                    checkActiveSlotTab={checkActiveSlotTab}
                    selectedSlotTime={selectedSlotTime}
                    setSelectedSlotTime={setSelectedSlotTime}
                    setShowBookForm={setShowBookForm}
                    confirmBookSession={confirmBookSession}
                    closeConfirmBookedModal={closeConfirmBookedModal}
                    showConfirmBookedModal={showConfirmBookedModal}
                    showResponsive={responsiveView}
                />
            ) : null}
        </>
    );
}

export default ExpertsSearch;
