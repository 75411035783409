import {Link} from "react-router-dom";
import moment from "moment/moment";
import ImagePostCarousel from "./ImagePostCarousel";
import DocumentPostView from "./DocumentPostView";
import CommentBlack from "../../../../assets/svg/CommentBlack.svg";
import ShareBlack from "../../../../assets/svg/ShareBlack.svg";
import React, {useEffect, useRef, useState} from "react";
import {getPostComments, getPostLike, updatePost} from "../../../../utils/apiUrlsSetup";
import {useZStore} from "../../../../utils/zStore";
import 'react-quill/dist/quill.snow.css';
import {Tooltip as ReactTooltip} from "react-tooltip";
import PostText from "../../../../component/PostText";
import {PostCardPreview} from "./PostCardPreview";
import CommentCard from "./CommentCard";
import useSelectedObject from "../../../../utils/hooks/useSelectedObject";
import PostCardCreateCommentBox from "./PostCardCreateCommentBox";
import LikeButton from "./LikeButton";
import Swal from "sweetalert2";
import ReportButton from "./ReportButton";

/**
 * @param {{
 *   post: Post,
 *   meta: {
 *     commentCount: number,
 *     reactionCount: number
 *   },
 *   setShowUserLikedList: Function,
 *   sharePost: VoidFunction,
 *   onEditButtonClicked: VoidFunction
 * }} props
 * @returns {Element}
 * @constructor
 */
export function PostCard(props) {
  const {post} = props;

  const store = useZStore()
  const userDetails = store.userDetails;

  /**
   * @type {[
   *      {userId: string, type: string}[] | null,
   *      React.Dispatch<React.SetStateAction<{userId: string, type: string}[] | null>>,
   *      (boolean) => Promise<{userId: string, type: string}[]>
   * ]}
   */
  const reactorState = useSelectedObject(
    async () => {
      // Fetch all who like this post
      const responseData = await getPostLike(`postId=${post.id}`);

      // Map it to list of strings
      return responseData.data.data
    },
    false
  );

  const postReactions = reactorState[0]
  const mutatePostReactions = reactorState[2]

  /**
   * @type {[PostComment[] | null, React.Dispatch<React.SetStateAction<PostComment[] | null>>, Function]}
   */
  const commentsState = useSelectedObject(
    async () => {
      const responseData = await getPostComments(post.id);
      return responseData.data.data.commentArray;
    },
    false
  );

  const comments = commentsState[0]
  const mutateComments = commentsState[2]

  useEffect(() => {
    if (!postReactions) {
      mutatePostReactions()
    }

    if (!comments) {
      mutateComments()
    }
  }, []);

  const [isCommentingPost, setIsCommentingPost] = useState(false)
  const [isEditingComment, setIsEditingComment] = useState()
  const fieldRef = useRef()

  /**
   * @param {number} id
   * @returns {Promise<void>}
   */
  const deletePost = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async e => {
      if (e.isConfirmed) {
        await updatePost(id, {isDeleted: true});
        await props.mutate();
      }
    })
  }

  if (post.reportedBy === userDetails.id) {
    return <></>
  }

  const distinctReaction = [...new Set((postReactions ?? []).map(e => e.type))]

  return (
    <div className={"bg-white p-3 pb-1 lg:tw-rounded-2xl d-flex flex-column gap-3"}>

      {/* First row containing author and action menu */}
      <div className={"d-flex justify-content-between"}>

        {/* Author */}
        <div className={"d-flex align-items-center gap-2"}>

          {/* Avatar */}
          <img src={post.user.profilePic ? post.user.profilePic : "/static/png/Male.png"}
               className={"tw-w-[38px] lg:tw-w-[48px] tw-aspect-square flex-shrink-0 tw-rounded-3xl object-fit-cover tw-object-center"}
               alt={"Profile Pic"}
          />

          {/* Author and Post Information. Can be organization or user. */}
          <div className={"d-flex flex-column"}>
            <div className="d-flex align-items-center gap-1">
              <Link
                to={post.organisationId ? `/organisation/${post.organisationId}` : `/friends/${post.user.id}`}
                className="d-flex tw-text-[#18214d] fw-bold tw-text-sm"
              >
                {/* Author's First Name and Last Name */}
                {post.user.firstName} {post.user.lastName}
              </Link>

              {/* Add info if this post is a repost */}
              {post.linkedPostId &&
                <div className={"tw-text-sm text-secondary"}>
                  <small>shared a post</small>
                </div>
              }
            </div>
            <div className={"d-flex gap-1 align-items-center"}>
              {/* How many days ago since posted*/}
              <div
                className="mb-0 montserrat text-secondary opacity-50 ls-near tw-text-xs tw-font-medium">
                {moment(post.publishedAt ?? post.createdAt).fromNow()}
              </div>

              {/* Separate with a dot */}
              <i className="bi bi-dot"></i>

              <div className="mb-0 tw-text-xs text-secondary">
                {post.organisation?.name ?
                  <span
                    className={"tw-cursor-default"}
                    data-tooltip-id={`visibility-${post.id}`}
                    data-tooltip-content={`Visible to ${post.organisation.name}`}
                  >
                    {post.organisation.name}

                    <i className={"bi bi-people ms-1"}/>
                  </span>
                  :
                  <>
                    {post.visible === "everyone" && (
                      <i className={"bi bi-globe"}
                         data-tooltip-id={`visibility-${post.id}`}
                         data-tooltip-content={`Visible to ${post.visible[0].toUpperCase()}${post.visible.slice(1)}`}
                      />
                    )}
                    {post.visible === "friends-only" && (
                      <i className={"bi bi-people"}
                         data-tooltip-id={`visibility-${post.id}`}
                         data-tooltip-content={`Visible to ${post.visible[0].toUpperCase()}${post.visible.slice(1)}`}
                      />
                    )}
                    {post.visible === "only-me" && (
                      <i className={"bi bi-lock"}
                         data-tooltip-id={`visibility-${post.id}`}
                         data-tooltip-content={`Visible to ${post.visible[0].toUpperCase()}${post.visible.slice(1)}`}
                      />
                    )}
                  </>
                }

                <ReactTooltip
                  id={`visibility-${post.id}`}
                  place={"right"}
                />
              </div>

              {/* Mobile: Category Tag / Badge if any */}
              {((post.subCategory ?? []).length > 0 && post.subCategory[0]) && (
                <>
                  {/* Separate with a dot */}
                  <i className="bi bi-dot"></i>

                  <div className={"badge tw-bg-[#b6d7f2] tw-font-[500] text-dark"}>
                    {post.subCategory[0]}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Action menu. Will only appear if user have logged in */}
        {/* There will be 3 variant: When viewing as Super Admin, as Author, and as General */}

        {userDetails.id &&
          <div>
            <i className="bi bi-three-dots fs-4 mb-1 tw-cursor-pointer"
               data-bs-toggle="dropdown" aria-expanded="false"/>

            <ul className="dropdown-menu dropdown-menu-end">
              {userDetails.id === post.user.id && (
                <li
                  className="dropdown-item montserrat tw-cursor-pointer tw-font-medium tw-text-sm py-2 tw-min-w-[200px]"
                  onClick={() => props.onEditButtonClicked()}>
                  <i className="bi bi-pen me-1"/> Edit Post
                </li>
              )}

              {(userDetails.role === "super-admin" || userDetails.id === post.user.id) &&
                <>
                  {/* Delete Post */}
                  <li
                    className="dropdown-item montserrat tw-cursor-pointer tw-font-medium tw-text-sm py-2 tw-min-w-[200px]"
                    onClick={() => deletePost(post.id)}>
                    <i className="bi bi-trash-fill me-1"/> Delete Post
                  </li>
                </>
              }

              <ReportButton
                afterSubmit={props.mutate}
                postId={post.id}
              />
            </ul>
          </div>
        }

      </div>

      {/* Fourth row, post's attachments. Each type have different view */}
      {post.type === "image" && post.attachment && (
        <div>
          {/* Carousel view for lot-of-images */}
          {post.attachment.length > 1 && <ImagePostCarousel images={post.attachment}/>}
          {post.attachment.length === 1 &&
            <img className="tw-w-full tw-cursor-pointer" onClick={() => {
              store.setFullscreenImgSrc(post.attachment[0])
            }} alt={"Post"} src={post.attachment[0]}/>
          }
        </div>
      )}

      {/* Fourth row, post's attachments. This one for document attachment */}
      {post.type === "document" && (
        <DocumentPostView
          url={post.attachment[0]}
          count={post.count}
        />
      )}

      {/* Fourth row, post's attachments. This one for video attachment */}
      {post.type === "video" && (
        <div>
          <video controls className={"tw-max-h-[500px] mx-auto"}>
            <source src={post.attachment[0]} type="video/mp4"/>
          </video>
        </div>
      )}

      {/* Second row containing post's content, linkified */}
      <div className={"inter fw-normal text-dark tw-whitespace-pre-wrap tw-break-words"}>
        <PostText content={post.description}/>
      </div>

      {post.linkedPostId &&
        <>
          <PostCardPreview id={post.linkedPostId} showFullAttachments/>
        </>
      }

      {/* Fifth row, statistics. Total likes and comments */}
      {(postReactions ?? []).length + (comments ?? []).length > 0 && (
        <div
          className="montserrat tw-text-[#7e5fc5] tw-text-sm tw-font-medium d-flex align-items-center tw-justify-between">
          <div className="d-flex align-items-center cursor_pointer data-[hidden='true']:tw-invisible"
               onClick={() => props.setShowUserLikedList(post)} data-hidden={post.reactionCount === 0}>
            {distinctReaction.map((reaction, rIdx) => {
              return (
                <img
                  key={reaction}
                  src={`/static/r_${reaction}.png`} style={{
                  zIndex: distinctReaction.length - rIdx
                }} className={`tw-w-[30px] bg-white tw-h-[30px] rounded-circle ${rIdx > 0 ? "-tw-ms-2" : ""}`}/>
              )
            })}
            <span className="ms-1">
              {(postReactions ?? []).length}
            </span>
          </div>

          <div
            className="tw-cursor-pointer data-[hidden='true']:tw-invisible"
            onClick={() => mutateComments()}
            data-hidden={(comments ?? []).length === 0}>
            {(comments ?? []).length}{" comments"}
          </div>
        </div>
      )}

      <div>
        <hr className={"mb-1 pb-0"}/>

        {/* Sixth row, statistics. Reaction Buttons. */}
        <div className="d-flex align-items-center gap-1">
          <LikeButton
            reactorState={reactorState}
            postId={post.id}
          />

          <button
            onClick={() => {
              if (isCommentingPost) {
                if (fieldRef.current) {
                  fieldRef.current.focus()
                }
              } else {
                setIsCommentingPost(true)
              }
            }}
            className={"d-flex tw-items-center tw-justify-center tw-text-[#565656] tw-gap-1.5 tw-w-full hover:tw-bg-[#ccecff] tw-rounded-lg tw-py-0.5"}
          >
            <img alt="comment button" src={CommentBlack} className={"tw-w-[18px] tw-h-[18px]"}/> <span
            className="tw-mb-1 tw-font-medium">Comment</span>
          </button>

          {(post.userId !== userDetails.id && !post.linkedPostId) &&
            <button
              onClick={props.sharePost}
              className={"d-flex tw-items-center tw-justify-center tw-text-[#565656] tw-gap-1.5 tw-w-full hover:tw-bg-[#ccecff] tw-rounded-lg tw-py-1"}
            >
              <img alt="share button" src={ShareBlack} className={"tw-w-[18px] tw-h-[18px]"}/>
              <span className="tw-mb-1 tw-font-medium">Share</span>
            </button>
          }
        </div>

        {((comments ?? []).length > 0 || isCommentingPost) && (
          <hr className={"mt-1 pb-0"}/>
        )}
      </div>

      {
        (comments ?? []).filter(e => e.reportedBy !== userDetails.id).map((curr, i) => {
          return (
            <CommentCard
              key={curr.id}
              post={post}
              currentDepth={1}
              comment={curr}
              mutate={async () => {
                await mutateComments()
              }}
              afterChange={() => {
                props.mutate()
              }}
              setIsEditingComment={setIsEditingComment}
              isEditingComment={isEditingComment}
            />
          )
        })
      }

      {!isEditingComment && (
        <>
          {
            (isCommentingPost || (comments ?? []).length > 0) && (
              <PostCardCreateCommentBox
                fieldRef={fieldRef}
                postId={post.id}
                afterSubmit={() => {
                  mutateComments()

                  setIsCommentingPost(false)
                }}
              />
            )
          }
        </>
      )}

    </div>
  )
}