import React, {useEffect, useRef, useState} from "react";
import {usePubNub} from "pubnub-react";
import "../../css/chat.css";
import profilePic from "../../assets/Male.png";
import File from "../../assets/svg/File.svg";

import {getAllChats} from "../../utils/apiUrlsSetup";
import {useLocation, useNavigate} from 'react-router-dom';
import moment from "moment";
import LoggedInLayout from "../../component/layout/LoggedInLayout.tsx";

function Chat() {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const date = new Date();
    const pubnub = usePubNub();
    const [channels, setChannel] = useState([""]);
    const [messages, addMessage] = useState([]);
    const [message, setMessage] = useState("");

    const [allChannels, setAllChannels] = useState([]);

    const [activeUser, setActiveUser] = useState(false);

    const [showMessageTyping, setshowMessageTyping] = useState(false);

    const [chatDetails, setChatDetails] = useState([]);
    const navigate = useNavigate();

    const {state} = useLocation();

    const handleMessage = (event) => {
        const message = event.message;
        if (typeof message === "string" || message.hasOwnProperty("text")) {
            const text = message.text || message;
            addMessage((messages) => [...messages, text]);
        }
    };

    const sendMessage = (message) => {
        if (message) {
            pubnub.publish({channel: channels[0], message}).then(() => {
                setMessage("");
            });
        }
    };

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth"})
    }

    pubnub.addListener({
        message: function (m) {
            let obj = {
                message: m.message,
                uuid: m.publisher,
                timetoken: date.getTime() * 10000
            };
            addMessage([...messages, obj]);
        },
        status: function (s) {
            setAllChannels(s.subscribedChannels);
        },
        file: function (f) {
            let obj = {
                message: f.file.url,
                uuid: f.publisher,
                isImage: true,
            };
            if (obj) {
                addMessage([...messages, obj]);
            }
        },
    });

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        getAllFriendsList();
    }, [state]);

    useEffect(() => {
        if (!messages.length) {
            GetAllMessages(channels[0]);
        }
    }, [channels]);

    const getAllFriendsList = async () => {
        let responseChat = await getAllChats(userDetails.id);
        setChatDetails(responseChat.data.data.chatsArray);
        let chnnaleDetails = responseChat.data.data.chatsArray.map(v => v.channelId);
        if (state) {
            let filterActiveChannelId = responseChat.data.data.chatsArray.filter(v => v.membersIdArray.includes(state)).map(v => v.channelId);
            setChannel([filterActiveChannelId[0]]);
        } else {
            setChannel([chnnaleDetails[0]]);
        }
        pubnub.subscribe({channels: chnnaleDetails});
    }

    const GetAllMessages = async (e) => {
        pubnub.fetchMessages(
            {
                channels: [e],
                count: 100,
                stringifiedTimeToken: true,
                includeMeta: true,
                includeMessageActions: true,
            },
            (status, response) => {
                let data = response;
                if (data && data.channels[e]) {
                    let arrOfMsg = data.channels[e].map((v) => {
                        if (typeof v.message === "object") {
                            const result = pubnub.getFileUrl({
                                channel: channels[0],
                                id: v.message.file.id,
                                name: v.message.file.name,
                            });
                            v.message = result;
                            v.isImage = true;
                        }
                        return v;
                    });
                    addMessage([...arrOfMsg]);
                } else {
                    addMessage([]);
                }
                // handle response
            }
        );
    };

    const onChangeHandler = (e) => {
        GetAllMessages(e);
        setChannel([e]);
        setActiveUser(false);
    };

    const onUploadImage = async (e) => {
        const result = await pubnub.sendFile({
            channel: channels[0],
            file: e.target.files[0],
        });
    };

    pubnub.hereNow(
        {
            channels: channels,
            includeUUIDs: true,
            includeState: true,
        },
        (status, response) => {
            if (response && response.totalOccupancy > 1) {
                setActiveUser(true);
            } else {
                setActiveUser(false);
            }
            // handle status, response
        }
    );

    const checkType = (val) => {
        let a = val.split("?");
        let b = a[0].split("/");
        let c = b[b.length - 1];
        let d = c.split(".");
        return d[1];
    }

    const getFileName = (val) => {
        let a = val.split("?");
        let b = a[0].split("/");
        let c = b[b.length - 1];
        let d = c.split(".");
        if (d.length > 1) {
            return d[0] + "." + d[1];
        } else {
            return d;
        }
    }

    const filterNameFunc = (v) => {
        let data = chatDetails.filter(val => val.channelId === v);
        let filterMemberDetails = data[0] ? data[0].membersArray.filter(v => v.id !== userDetails.id) : [];
        return filterMemberDetails[0] ? `${filterMemberDetails[0].firstName} ${filterMemberDetails[0].lastName}` : "";
    }

    const filterIdFunc = (v) => {
        let data = chatDetails.filter(val => val.channelId === v);
        let filterMemberDetails = data[0] ? data[0].membersArray.filter(v => v.id !== userDetails.id) : [];
        return filterMemberDetails[0] ? `${filterMemberDetails[0].id}` : "";
    }

    const filterImageFunc = (v) => {
        let data = chatDetails.filter(val => val.channelId === v);
        let filterMemberDetails = data[0] ? data[0].membersArray.filter(v => v.id !== userDetails.id) : [];
        return filterMemberDetails[0] && filterMemberDetails[0].profilePic ? filterMemberDetails[0].profilePic : "";
    }

    const getTime = (value) => {
        if (value) {
            let date = new Date(value / 10000).toISOString();
            return moment(date).format("DD MMM hh:mm a");
        } else {
            return "";
        }
    }

    return (
        <LoggedInLayout title={"Chat"}>
            <div className="row chat_parent_area">
                <div className="col-lg-4 d-none d-lg-block h-100">
                    <div className={"memebers_list"}>
                        {allChannels.map((v, i) => {
                            return (
                                <>
                                    <div
                                        className={` ${channels[0] === v ? "active" : ""
                                        } d-flex user_list align-items-center justify-content-between px-3 py-3`}
                                        key={i}
                                    >
                                        <div className="d-flex align-items-center justify-content-between">
                                            <img width="30px" height="30px" style={{borderRadius: '50px'}}
                                                 src={filterImageFunc(v) ? filterImageFunc(v) : profilePic} alt=""
                                                 onClick={() => navigate(`/friends/${filterIdFunc(v)}`)}/>
                                            <p
                                                className={`mb-0 user_name ps-3 ${channels[0] === v ? "active" : ""
                                                }`}
                                                onClick={() => onChangeHandler(v)}
                                            >
                                                {filterNameFunc(v)}
                                            </p>
                                        </div>
                                        {/* <p className="mb-0 chat_date">30 Dec 2021, 12:34</p> */}
                                    </div>
                                    <hr className="m-0 p-0"/>
                                </>
                            );
                        })}
                    </div>
                </div>
                <div className="col-lg-8 col-12">
                    <div className="bg-white chat_details_area">
                        <div className="chat_header py-3 px-4">
                            <p className="mb-0 user_name d-flex align-items-center cursor_pointer"
                               onClick={() => navigate(`/friends/${filterIdFunc(channels[0])}`)}>
                                {filterNameFunc(channels[0])} {activeUser ?
                                <div className="active_icon_dot ms-2"></div> : ""}
                            </p>
                        </div>
                        <hr className="m-0 p-0"/>

                        <div className="position-relative messages_area_parent">
                            <div className="p-3 messages_area">

                                {messages.map((v, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className={`d-flex ${v.uuid === `user${userDetails.id}` ? "justify-content-end" : ""
                                            }`}
                                        >
                                            <div
                                                className={`message_box ${v.uuid !== `user${userDetails.id}` ? "blue" : "white"
                                                } mb-4`}
                                            >
                                                {!v.isImage ? (
                                                    v.message
                                                ) : (
                                                    <>

                                                        {(checkType(v.message) === "png") || (checkType(v.message) === "gif") || (checkType(v.message) === "jpeg") || (checkType(v.message) === "jpg") ?
                                                            <img
                                                                src={v.message}
                                                                alt="Click to view"
                                                                width="100%"
                                                                onClick={() => window.open(v.message)}
                                                                style={{cursor: "pointer"}}
                                                            /> :

                                                            <p className="mb-0"
                                                               onClick={() => window.open(v.message)}
                                                               style={{cursor: "pointer"}}>{getFileName(v.message)}</p>

                                                        }


                                                    </>
                                                )}
                                                <p>{getTime(v.timetoken)}</p>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div ref={messagesEndRef}/>
                            </div>

                            {message.length > 0 && showMessageTyping && (
                                <p className="mb-0 typing_message_indicator">
                                    {userDetails.firstName} <span>Typing a message...</span>
                                </p>
                            )}
                        </div>
                        <hr className="m-0 p-0"/>
                        <div className="d-flex align-items-center">
                            <input
                                className="chat_msg_input"
                                type="text"
                                placeholder="Type your message"
                                value={message}
                                onKeyPress={(e) => {
                                    setshowMessageTyping(true);
                                    if (e.key !== "Enter") return;
                                    sendMessage(message);
                                }}
                                onBlur={() => {
                                    // setshowMessageTyping(false);
                                }}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                            <div>
                                <label for="upload-photo">
                                    <img src={File} alt=""/>
                                </label>
                                <input
                                    type="file"
                                    name="photo"
                                    id="upload-photo"
                                    onChange={(e) => onUploadImage(e)}
                                    style={{display: "none"}}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </LoggedInLayout>
    );
}

export default Chat;