import React from 'react';
import { useNavigate } from "react-router-dom";

import SideNav from '../../component/staticPages/SideNav';

import DataSort from '../../assets/svg/SuperAdmin/DataSort.svg';
import RedInfo from '../../assets/svg/SuperAdmin/RedInfo.svg';
import Attechment from '../../assets/svg/SuperAdmin/Attechment.svg';
import Mail from '../../assets/svg/SuperAdmin/Mail.svg';
import Accept from '../../assets/svg/SuperAdmin/Accept.svg';
import Reject from '../../assets/svg/SuperAdmin/Reject.svg';
import Delete from '../../assets/svg/SuperAdmin/Delete.svg';

import "./superAdmin.css";

const SuperAdmin = () => {

    const navigate = useNavigate();

    const handleCoachDetails = () => {
        navigate('/admin/coach-profile');
    }

    const handleUsersDetails = () => {
        navigate('/admin/user-profile');
    }

    return (
        <>
            <div className='row m-0 d-flex newsfeed_bg time_exchange_bg_full_height'>

                <div className='news_left p-0'>

                    <SideNav />

                </div>

                <div className='news_right time_exchange_auto_height sup_adm_full_height p-0'>

                    {/* Condition-1 */}

                    <div className='row m-0'>
                        <div className='col-12 p-5'>

                            <div className='row m-0'>
                                <div className='col-12 p-0'>
                                    <p className='mb-0 sup_adm_heading'>Account List</p>
                                </div>
                            </div>

                            <div className='row m-0 mt-4'>
                                <div className='col-10 p-0'>
                                    <button className='px-3 py-1 me-3 sup_adm_toggle_sorts sup_adm_toggle_sorts_active'>All</button>
                                    <button className='px-3 py-1 me-3 sup_adm_toggle_sorts'>Theraphist</button>
                                    <button className='px-3 py-1 me-3 sup_adm_toggle_sorts'>Doctors</button>
                                    <button className='px-3 py-1 me-3 sup_adm_toggle_sorts'>Life Coaches</button>
                                    <button className='px-3 py-1 me-3 sup_adm_toggle_sorts'>Social Workers</button>
                                    <button className='px-3 py-1 me-3 sup_adm_toggle_sorts'>Counsellors</button>
                                    <button className='px-3 py-1 sup_adm_toggle_sorts'>Psychologists</button>
                                </div>
                                <div className='col-2 p-0 d-flex align-items-center justify-content-end'>
                                    <div className='sup_swap_btns'>
                                        <button className='super_adm_user_btn'>User</button>
                                        <button className='super_adm_coaches_btn super_adm_coaches_btn_act'>Coaches</button>
                                    </div>
                                </div>
                            </div>

                            <div className='row m-0 mt-4'>
                                <div className='col-12 p-0'>

                                    <div className='row m-0 sup_adm_tbl_head d-flex align-items-center'>
                                        <div className='col-3 p-0 ps-5'>
                                            <p className='mb-0 ms-1 sup_adm_tblhd_heading'>Name &nbsp; <img src={DataSort} alt='' /> </p>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <p className='mb-0 sup_adm_tblhd_heading'>Current Job &nbsp; <img src={DataSort} alt='' /></p>
                                        </div>
                                        <div className='col-1 p-0'>
                                            <p className='mb-0 sup_adm_tblhd_heading'>Expertise &nbsp; <img src={DataSort} alt='' /></p>
                                        </div>
                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 sup_adm_tblhd_heading'>Status &nbsp; <img src={DataSort} alt='' /></p>
                                        </div>
                                        <div className='col-1 p-0 d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 sup_adm_tblhd_heading'>Certificate &nbsp; <img src={DataSort} alt='' /></p>
                                        </div>
                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 sup_adm_tblhd_heading'>Action &nbsp; <img src={DataSort} alt='' /></p>
                                        </div>
                                    </div>

                                    <div className='row m-0 sup_adm_tbl_head sup_adm_tbl_rows d-flex align-items-center'>
                                        <div className='col-3 p-0 ps-4 d-flex align-items-center'>
                                            <div className="sup_red_info_img">
                                                <img src={RedInfo} alt='' />
                                            </div>
                                            <p className='mb-0 ms-2 sup_adm_tbl_name' onClick={handleCoachDetails}>Theresa Lim</p>
                                        </div>
                                        <div className='col-3 p-0 d-flex align-items-center'>
                                            <p className='mb-0 sup_adm_tbl_curr_job'>Consultant at Company X</p>
                                        </div>
                                        <div className='col-1 p-0 d-flex align-items-center'>
                                            <button className='sup_adm_exp_btn p-0 m-0'>Theraphist</button>
                                        </div>
                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 sup_adm_tbl_status'>Rejected</p>
                                        </div>
                                        <div className='col-1 p-0 d-flex align-items-center justify-content-center'>
                                            <img src={Attechment} alt='' />
                                        </div>
                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                            <img src={Mail} alt='' />
                                        </div>
                                    </div>

                                    <div className='row m-0 sup_adm_tbl_head sup_adm_tbl_rows d-flex align-items-center'>
                                        <div className='col-3 p-0 ps-4 d-flex align-items-center'>
                                            <div className="sup_red_info_img">
                                                <img src="{RedInfo}" alt='' />
                                            </div>
                                            <p className='mb-0 ms-2 sup_adm_tbl_name' onClick={handleUsersDetails}>Theresa Lim</p>
                                        </div>
                                        <div className='col-3 p-0 d-flex align-items-center'>
                                            <p className='mb-0 sup_adm_tbl_curr_job'>Consultant at Company X</p>
                                        </div>
                                        <div className='col-1 p-0 d-flex align-items-center'>
                                            <button className='sup_adm_exp_btn p-0 m-0'>Theraphist</button>
                                        </div>
                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 sup_adm_tbl_status'>Waiting Approval</p>
                                        </div>
                                        <div className='col-1 p-0 d-flex align-items-center justify-content-center'>
                                            <img src={Attechment} alt='' />
                                        </div>
                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                            <img className='me-1' src={Reject} alt='' />
                                            <img className='ms-1' src={Accept} alt='' />
                                        </div>
                                    </div>

                                    <div className='row m-0 sup_adm_tbl_head sup_adm_tbl_rows d-flex align-items-center'>
                                        <div className='col-3 p-0 ps-4 d-flex align-items-center'>
                                            <div className="sup_red_info_img">
                                                <img src="{RedInfo}" alt='' />
                                            </div>
                                            <p className='mb-0 ms-2 sup_adm_tbl_name'>Theresa Lim</p>
                                        </div>
                                        <div className='col-3 p-0 d-flex align-items-center'>
                                            <p className='mb-0 sup_adm_tbl_curr_job'>Consultant at Company X</p>
                                        </div>
                                        <div className='col-1 p-0 d-flex align-items-center'>
                                            <button className='sup_adm_exp_btn p-0 m-0'>Theraphist</button>
                                        </div>
                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 sup_adm_tbl_status'>Accepted</p>
                                        </div>
                                        <div className='col-1 p-0 d-flex align-items-center justify-content-center'>
                                            <img src={Attechment} alt='' />
                                        </div>
                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                            <img src={Delete} alt='' />
                                        </div>
                                    </div>

                                    <div className='row m-0 sup_adm_tbl_head sup_adm_tbl_rows d-flex align-items-center'>
                                        <div className='col-3 p-0 ps-4 d-flex align-items-center'>
                                            <div className="sup_red_info_img">
                                                <img src="{RedInfo}" alt='' />
                                            </div>
                                            <p className='mb-0 ms-2 sup_adm_tbl_name'>Theresa Lim</p>
                                        </div>
                                        <div className='col-3 p-0 d-flex align-items-center'>
                                            <p className='mb-0 sup_adm_tbl_curr_job'>Consultant at Company X</p>
                                        </div>
                                        <div className='col-1 p-0 d-flex align-items-center'>
                                            <button className='sup_adm_exp_btn p-0 m-0'>Theraphist</button>
                                        </div>
                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 sup_adm_tbl_status'>Accepted</p>
                                        </div>
                                        <div className='col-1 p-0 d-flex align-items-center justify-content-center'>
                                            <img src={Attechment} alt='' />
                                        </div>
                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                            <img src={Delete} alt='' />
                                        </div>
                                    </div>

                                    <div className='row m-0 sup_adm_tbl_head sup_adm_tbl_rows d-flex align-items-center'>
                                        <div className='col-3 p-0 ps-4 d-flex align-items-center'>
                                            <div className="sup_red_info_img">
                                                <img src="{RedInfo}" alt='' />
                                            </div>
                                            <p className='mb-0 ms-2 sup_adm_tbl_name'>Theresa Lim</p>
                                        </div>
                                        <div className='col-3 p-0 d-flex align-items-center'>
                                            <p className='mb-0 sup_adm_tbl_curr_job'>Consultant at Company X</p>
                                        </div>
                                        <div className='col-1 p-0 d-flex align-items-center'>
                                            <button className='sup_adm_exp_btn p-0 m-0'>Theraphist</button>
                                        </div>
                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 sup_adm_tbl_status'>Waiting Approval</p>
                                        </div>
                                        <div className='col-1 p-0 d-flex align-items-center justify-content-center'>
                                            <img src={Attechment} alt='' />
                                        </div>
                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                            <img className='me-1' src={Reject} alt='' />
                                            <img className='ms-1' src={Accept} alt='' />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    {/* Condition-1 */}

                    {/* Condition-2 */}

                    {/* <div className='row m-0'>
                        <div className='col-12 p-5'>

                            <div className='row m-0'>
                                <div className='col-12 p-0'>
                                    <p className='mb-0 sup_adm_heading'>Account List</p>
                                </div>
                            </div>

                            <div className='row m-0 mt-4'>
                                <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                    <div className='sup_swap_btns'>
                                        <button className='super_adm_user_btn super_adm_user_btn_act'>User</button>
                                        <button className='super_adm_coaches_btn'>Coaches</button>
                                    </div>
                                </div>
                            </div>

                            <div className='row m-0 mt-4'>
                                <div className='col-12 p-0'>

                                    <div className='row m-0 sup_adm_tbl_head d-flex align-items-center'>
                                        <div className='col-3 p-0 ps-5'>
                                            <p className='mb-0 ms-1 sup_adm_tblhd_heading'>Name &nbsp; <img config={DataSort} alt='' /> </p>
                                        </div>
                                        <div className='col-4 p-0'>
                                            <p className='mb-0 sup_adm_tblhd_heading'>Email &nbsp; <img config={DataSort} alt='' /></p>
                                        </div>
                                        <div className='col-3 p-0'>
                                            <p className='mb-0 sup_adm_tblhd_heading'>Joined Date &nbsp; <img config={DataSort} alt='' /></p>
                                        </div>
                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 sup_adm_tblhd_heading'>Action</p>
                                        </div>
                                    </div>

                                    <div className='row m-0 sup_adm_tbl_head sup_adm_tbl_rows d-flex align-items-center'>
                                        <div className='col-3 p-0 ps-4 d-flex align-items-center'>
                                            <div className="sup_red_info_img">
                                                <img config="{RedInfo}" alt='' />
                                            </div>
                                            <p className='mb-0 ms-2 sup_adm_tbl_name'>Theresa Lim</p>
                                        </div>
                                        <div className='col-4 p-0 d-flex align-items-center'>
                                            <p className='mb-0 sup_adm_tbl_curr_job'>theresalim@gmail.com</p>
                                        </div>
                                        <div className='col-3 p-0 d-flex align-items-center'>
                                            <p className='mb-0 sup_adm_tbl_status'>Dec 17, 2020</p>
                                        </div>
                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                            <img className='me-1' config={Delete} alt='' />
                                        </div>
                                    </div>

                                    <div className='row m-0 sup_adm_tbl_head sup_adm_tbl_rows d-flex align-items-center'>
                                        <div className='col-3 p-0 ps-4 d-flex align-items-center'>
                                            <div className="sup_red_info_img">
                                                <img config="{RedInfo}" alt='' />
                                            </div>
                                            <p className='mb-0 ms-2 sup_adm_tbl_name'>Theresa Lim</p>
                                        </div>
                                        <div className='col-4 p-0 d-flex align-items-center'>
                                            <p className='mb-0 sup_adm_tbl_curr_job'>theresalim@gmail.com</p>
                                        </div>
                                        <div className='col-3 p-0 d-flex align-items-center'>
                                            <p className='mb-0 sup_adm_tbl_status'>Dec 17, 2020</p>
                                        </div>
                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                            <img className='me-1' config={Delete} alt='' />
                                        </div>
                                    </div>

                                    <div className='row m-0 sup_adm_tbl_head sup_adm_tbl_rows d-flex align-items-center'>
                                        <div className='col-3 p-0 ps-4 d-flex align-items-center'>
                                            <div className="sup_red_info_img">
                                                <img config="{RedInfo}" alt='' />
                                            </div>
                                            <p className='mb-0 ms-2 sup_adm_tbl_name'>Theresa Lim</p>
                                        </div>
                                        <div className='col-4 p-0 d-flex align-items-center'>
                                            <p className='mb-0 sup_adm_tbl_curr_job'>theresalim@gmail.com</p>
                                        </div>
                                        <div className='col-3 p-0 d-flex align-items-center'>
                                            <p className='mb-0 sup_adm_tbl_status'>Dec 17, 2020</p>
                                        </div>
                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                            <img className='me-1' config={Delete} alt='' />
                                        </div>
                                    </div>

                                    <div className='row m-0 sup_adm_tbl_head sup_adm_tbl_rows d-flex align-items-center'>
                                        <div className='col-3 p-0 ps-4 d-flex align-items-center'>
                                            <div className="sup_red_info_img">
                                                <img config="{RedInfo}" alt='' />
                                            </div>
                                            <p className='mb-0 ms-2 sup_adm_tbl_name'>Theresa Lim</p>
                                        </div>
                                        <div className='col-4 p-0 d-flex align-items-center'>
                                            <p className='mb-0 sup_adm_tbl_curr_job'>theresalim@gmail.com</p>
                                        </div>
                                        <div className='col-3 p-0 d-flex align-items-center'>
                                            <p className='mb-0 sup_adm_tbl_status'>Dec 17, 2020</p>
                                        </div>
                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                            <img className='me-1' config={Delete} alt='' />
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div> */}

                    {/* Condition-2 */}

                </div>
            </div>
        </>
    );
};

export default SuperAdmin;