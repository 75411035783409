import React from "react";
import "./Home.css";

import Header from "../component/staticPages/header";
import Footer from "../component/staticPages/footer";
import Login from "../container/staticPages/Login";

const DATA = {
  "hero": {
    "image": "/static/svg/SocialContent.svg",
    headline: "Singapore's largest network for Workplace Mental Health and Well-being."
  },
  "sponsors": [
    {
      "label": "Supported by",
      "items": [
        {
          href: "https://www.tal.sg",
          img: "/static/png/AppointedBy.png"
        },
        {
          href: "https://www.tal.sg/wshc",
          img: "/static/png/WSHCLogo.png"
        },
      ]
    },
    {
      "label": "Tripartite Alliance Partners",
      "items": [
        {
          href: "https://mom.gov.sg",
          img: "/static/png/SupportedBy1.png"
        },
        {
          href: "https://www.ntuc.org.sg/uportal/",
          img: "/static/png/SupportedBy2.png"
        },
        {
          href: "https://snef.org.sg",
          img: "/static/png/SupportedBy3.png"
        },
      ]
    }
  ]
}

const Home = () => (
  <>
    <div className="bg-login xl:tw-min-h-screen">
      <div className="container-xl d-flex flex-column">
        <Header/>
        <div className="row align-items-center pb-5 pb-xl-0 pt-0 pt-xl-3 justify-content-center">
          <div className="col-md-3 d-none d-md-flex align-items-center justify-content-center">
            <img
              className="w-100 tw-max-w-[400px] -tw-scale-x-100 object-fit-contain"
              src={DATA.hero.image}
              alt=""
            />
          </div>
          <div className="col-md-5 col-12">
            <div className={"mx-auto tw-max-w-[500px]"}>
              <h1
                className="mb-0 mt-4 tw-font-[500] mt-md-0 montserrat text-darkblue fs-2 text-md-start ls-near lh-base">
                {DATA.hero.headline}
              </h1>
            </div>
          </div>
          <div className="col-md-4 col-12 pt-md-0 pt-5">
            <div className={"mx-auto tw-max-w-[400px]"}>
              <Login/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-white">
      <div className="container-lg py-4">
        <div className="d-flex justify-content-lg-center flex-wrap gap-5">
          {DATA.sponsors.map(sponsor => {
            return (
              <div>
                <p className="mb-1 fw-bold inter">{sponsor.label}</p>

                <div className="d-flex gap-3">
                  {sponsor.items.map(item => {
                    return (
                      <a target="_blank" href={item.href} rel="noreferrer"
                         className={"tw-h-[100px] tw-w-[100px] d-flex justify-content-center align-items-center"}>
                        <img
                          className="m-0 object-fit-contain tw-object-center"
                          alt={item.href}
                          src={item.img}/>
                      </a>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>

      </div>
    </div>
    <Footer/>
  </>
)

export default Home;
