"use strict"

import {ReactNode, useEffect, useMemo, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {createChat, getBids, getOffers} from '../../utils/apiUrlsSetup'

// @ts-expect-error still combined
import ExchangeTimeModal from './component/ExchangeTimeModal.tsx'

import ExchangeReportModal from './component/exchange-report-modal';
// @ts-expect-error still combined
import Points from './component/Points.tsx'
// @ts-expect-error still combined
import LoggedInLayout from "../../component/layout/LoggedInLayout.tsx";
import {useZStore} from "../../utils/zStore";
import useTimeExPoints from "../../utils/hooks/useTimeExPoints";
import PostText from "../../component/PostText";
// @ts-expect-error still combined
import RequestCard from "./component/RequestCard.tsx";
import {BidObject, BidResponse, OfferObject, OfferResponse} from "../../utils/ApiUrlsSetupTypes";

const Exchange = () => {
  const queryParams = new URLSearchParams(useLocation().search)
  const timeExPointsSWR = useTimeExPoints()
  const navigate = useNavigate();
  const zStore = useZStore()

  const offerId = queryParams.get("offerId")
  const bidId = queryParams.get("bidId") || null

  const [offerResponse, setOfferResponse] = useState<OfferResponse>();
  const [bidsData, setBidsData] = useState<BidResponse>();
  const [otherOffer, setOtherOffer] = useState<OfferObject[]>([]);
  const [exchangeModalOpen, handleExchangeModal] = useState(false)
  const [exchangeReportModal, setExchangeReportModal] = useState(false)

  useEffect(() => {
    if (offerId) {
      // Get offering object
      getOffers(offerId).then(_getOffersResponse => {
        const _offerResponse = _getOffersResponse.data.data;
        setOfferResponse(_offerResponse)
      })
    }
  }, [offerId])

  // Sync get all bidder for this offer
  useEffect(() => {
    if (offerId) {
      getBids({offerId: offerId}).then(_getBidsResponse => {
        const _bidsResponse = _getBidsResponse.data.data;
        setBidsData(_bidsResponse);
      })
    }
  }, [offerId]);

  // Sync get offer's author profile
  useEffect(() => {
    if (offerResponse?.offer?.userId) {
      getAllOffers(offerResponse.offer.userId, offerId)
    }
  }, [offerResponse]);

  const getAllOffers = async (userId, exclusionId) => {
    const response = await getOffers(null, 1000)
    const response_data = response.data.data.offerArray;

    setOtherOffer(
      response_data
        .filter(e => e.userId === userId)
        .filter(e => e.id !== exclusionId)
        .filter(offer => !offer.isClosed)
        .filter(e => e.id !== Number(offerId))
    )
  }

  // TODO: Check if current account is one of the offer's bidder
  const focusedBid: BidObject | null = useMemo(() => {
    if (!bidsData) return null;
    if (!zStore.userDetails) return null;
    if (!offerResponse) return null;

    let _myBid;
    if (bidId) {
      _myBid = bidsData.oneToOneSessionArray.find(e => Number(e.id) === Number(bidId))
    } else if (offerResponse.offer.offerType === "gift") {
      _myBid = bidsData.oneToOneSessionArray.find(e => e.userId === zStore.userDetails.id)
    } else if (offerResponse.offer.offerType === "request") {
      _myBid = bidsData.oneToOneSessionArray.find(e => e.instructorId === zStore.userDetails.id)
    }

    if (!_myBid) return null;
    if (_myBid.status?.toLowerCase() === "cancelled") return null;

    return _myBid;
  }, [bidsData, zStore.userDetails])

  if (!offerResponse) return <>Loading...</>

  const redirectToChatPage = async (id) => {
    let obj = {
      membersIdArray: [id]
    }
    let res = await createChat(obj);
    if (res.status === 200) {
      navigate("/chat", {state: id});
    }
  }

  let statusText: ReactNode = "Available"

  if (offerResponse?.offer.isClosed) {
    statusText = "Closed"
  } else if (offerResponse?.offer.unpublishedAt && new Date(offerResponse?.offer.unpublishedAt) < new Date()) {
    statusText = <>
      Expired since{" "}
      {
        Intl.DateTimeFormat("En-SG", {
          hour: "2-digit",
          minute: "2-digit",
        }).format(new Date(offerResponse?.offer.unpublishedAt))
      }
    </>
  }

  let view: "OWNER" | "BIDDER" | "GUEST";
  if (offerResponse.offer.userId === zStore.userDetails.id) {
    view = 'OWNER'
  } else if (focusedBid === null) {
    view = 'GUEST';
  } else if (
    offerResponse.offer.offerType === "gift" && focusedBid.userId === zStore.userDetails.id
    ||
    offerResponse.offer.offerType === "request" && focusedBid.instructorId === zStore.userDetails.id
  ) {
    view = 'BIDDER';
  }

  console.log({view})

  return (
    <LoggedInLayout title={"Gift"}>
      <div className="container">
        <div className='tw-w-full tw-max-w-[600px] tw-mx-auto d-flex flex-column gap-3'>
          <div>
            <div className="tw-flex tw-justify-between tw-items-center">
              <Link
                to={"/time-exchange"}
                className={"tw-text-[#410db7] btn btn-sm border tw-border-[#410db7] !tw-bg-gray-300 tw-font-medium mb-1"}>
                <i className="bi bi-arrow-left"/> Giving
              </Link>
              {timeExPointsSWR.data && (
                <Points wbPoints={timeExPointsSWR.data.wbPoints} wbTime={timeExPointsSWR.data.wbTime}/>
              )}
            </div>

            {/* Title */}
            <div
              className="tw-relative tw-flex tw-flex-col tw-items-center tw-justify-center bg-primary tw-min-h-[200px] rounded">
              <h1 className={"tw-text-white montserrat fw-bold tw-text-2xl m-0"}>
                {offerResponse?.offer.title}
              </h1>

              <div>
                <div
                  data-disabled={offerResponse?.offer.isClosed || (offerResponse?.offer.unpublishedAt && new Date(offerResponse?.offer.unpublishedAt) < new Date())}
                  className={`tw-px-5 tw-text-xs tw-rounded-xl tw-pb-1 tw-py-0.5 text-white tw-font-medium tw-bg-[#28a745] data-[disabled]:tw-bg-red-500`}>
                  {statusText}
                </div>
              </div>

              <div
                className={"tw-block tw-absolute tw-bottom-0 tw-start-0 tw-m-2 tw-font-medium tw-pb-1 tw-rounded bg-white !tw-text-sm tw-px-2 tw-pt-0.5"}>
                Total Exchange <span
                className={"tw-text-[#e138c2]"}>{offerResponse.offer.noOfHours} Hour{offerResponse.offer.noOfHours > 1 ? "s" : ""}</span>
              </div>

              <div
                className={"tw-block tw-text-[#e138c2] tw-font-semibold tw-absolute tw-top-0 tw-end-0 tw-m-2 tw-pb-1 tw-rounded bg-white !tw-text-sm tw-px-2 tw-pt-0.5"}>
                {offerResponse.offer.offerType[0].toUpperCase()}{offerResponse.offer.offerType.slice(1)}
              </div>
            </div>
          </div>

          <div className="d-flex tw-items-center tw-flex-wrap tw-gap-x-5 tw-gap-y-3 tw-justify-between">
            <div className={"d-flex tw-items-center gap-2 tw-flex-shrink-0"}>
              <div className={"tw-flex-shrink-0"}>
                <Link to={`/friends/${offerResponse.offer.userId}`}>
                  <img
                    src={offerResponse.offer.user.profilePic || "/static/png/Male.png"}
                    alt=""
                    className='rounded-circle border tw-object-center tw-w-[64px] tw-h-[64px]'
                  />
                </Link>
              </div>
              <div>
                <div
                  className="d-flex flex-column justify-content-center">
                  <div className="tw-relative">
                    <p
                      className={"mb-0 title_exe_prifile_name"}>{offerResponse.offer.user.firstName} {offerResponse.offer.user.lastName}</p>

                    {(view !== 'OWNER') &&
                      <button
                        type={"button"}
                        className='tw-absolute tw-start-full tw-top-0 -tw-translate-y-1/2 tw-translate-x-0.5'
                        onClick={() => {
                          redirectToChatPage(offerResponse.offer.userId)
                        }}>
                        <i className="bi bi-chat-dots-fill tw-text-[#7e5fc5]"/>
                      </button>
                    }
                  </div>
                  <p className="mb-0 title_exe_prifile_offbid title_exe_prifile_offerbid">
                    {offerResponse.offerCount} gift{offerResponse.offerCount > 1 ? "s" : ""}
                    {" | "}
                    {offerResponse.bidCount} accepted
                  </p>
                </div>
              </div>
            </div>
          </div>

          <hr/>

          <div className={"inter fw-normal text-dark tw-whitespace-pre-wrap tw-break-words"}>
            <PostText content={offerResponse.offer.description} defaultExpanded/>
          </div>

          <div>
            {offerResponse.offer.category.map((v) => `#${v}`).join(" ")}
          </div>

          <p className="tw-cursor-pointer tw-text-[#410db7] text-end">
            <u onClick={() => setExchangeReportModal(true)}>Report</u>
          </p>

          {(view === "GUEST") && (
            <>
              <div className="title_exe_right_box">
                <div className="p-3">
                  <button
                    className="title_exe_button tw-w-full"
                    onClick={() => handleExchangeModal(true)}
                  >
                    Exchange Time
                  </button>
                </div>
              </div>
            </>
          )}

          {(view === "BIDDER" && focusedBid) && <RequestCard view={view} offer={offerResponse.offer} bid={focusedBid}/>}

          {(otherOffer.length > 0 && view !== "OWNER") && (
            <>
              <hr/>

              <h1 className={"tw-text-[#410db7] montserrat fw-bold tw-text-lg m-0 mt-3"}>
                Other Gift Offer by This Community Member
              </h1>

              <div>
                <div
                  className={"tw-grid md:tw-grid-cols-2 tw-gap-3"}
                >
                  {
                    otherOffer
                      .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                      .map((offer, index) => (
                        <div
                          onClick={() => window.location.href = `/time-exchange/exchange?offerId=${offer.id}`}
                          className='p-0 tw-cursor-pointer card hover:!tw-shadow-lg border-1 tw-border-transparent hover:!tw-border-[#410db7] tw-transition-all'
                          key={index}>
                          <div className="card-header bg-transparent d-flex align-items-center tw-gap-2">
                            <div>
                              <img
                                className='rounded-circle tw-w-[36px] tw-h-[36px] tw-object-cover shadow-sm tw-object-center'
                                src={offer.user.profilePic ? offer.user.profilePic : "/static/png/Male.png"}
                                alt=''/>
                            </div>

                            <Link
                              to={zStore.userDetails.id === offer.userId ? "/profile" : `/friends/${offer.userId}`}
                              className='mb-0 montserrat tw-font-medium'>
                              {offer.user.firstName} {offer.user.lastName}
                            </Link>
                          </div>

                          <div
                            className={"bg-primary tw-relative tw-w-full tw-h-[175px] p-3 d-flex justify-content-center text-center tw-leading-5 align-items-center tw-text-white tw-text-xl"}>
                            {offer.title}

                            <div
                              className={"tw-absolute tw-bottom-0 tw-left-0 m-2 px-2 tw-py-0.5 tw-bg-white tw-rounded tw-shadow"}>
                              <p className='mb-0 tw-text-sm tw-text-black'>
                                Total exchange
                                <span className="tw-font-semibold tw-text-[#e138bc]"> {offer.noOfHours} Hour</span>
                              </p>
                            </div>

                          </div>

                          <div className="card-body tw-whitespace-pre-wrap tw-break-words">
                            <PostText content={offer.description}/>
                          </div>
                          <div className="card-footer bg-transparent border-0">
                            <div className="text-end">
                              <button type={"button"} className={"btn ps-4 pe-3 py-1 border tw-font-semibold"}>
                                Learn More <i className="bi bi-chevron-right"/>
                              </button>
                            </div>
                            <PostText content={offer.category.map((v) => `#${v}`).join(" ")}/>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </>
          )}

          {(view === "OWNER" && bidsData?.oneToOneSessionArray.length > 0) && (
            <>
              {focusedBid && (
                <>
                  <hr/>

                  <h1 className={"tw-text-[#410db7] montserrat fw-bold tw-text-lg m-0"}>
                    Focused Requests
                  </h1>

                  {focusedBid && <RequestCard view={view} offer={offerResponse.offer} bid={focusedBid}/>}
                </>
              )}

              {
                bidsData?.oneToOneSessionArray
                  .filter(e => e.id !== focusedBid?.id)
                  .filter(e => !(['rejected', 'confirmed', 'cancelled'].includes(e.status)))
                  .length > 0 && (
                  <>
                    <hr/>

                    <h1 className={"tw-text-[#410db7] montserrat fw-bold tw-text-lg m-0"}>
                      Other Active {offerResponse?.offer?.offerType === "gift" ? "Requests" : "Invitations"}
                    </h1>

                    {
                      bidsData?.oneToOneSessionArray
                        .filter(e => e.id !== focusedBid?.id)
                        .filter(e => !(['rejected', 'confirmed', 'cancelled'].includes(e.status)))
                        .map(_focusedBid => (
                          <RequestCard view={view} offer={offerResponse.offer} bid={_focusedBid} key={_focusedBid.id}/>
                        ))
                    }
                  </>
                )
              }

              {
                bidsData?.oneToOneSessionArray
                  .filter(e => (['rejected', 'confirmed', 'cancelled'].includes(e.status)))
                  .length > 0 && (
                  <>
                    <hr/>

                    <h1 className={"tw-text-[#410db7] montserrat fw-bold tw-text-lg m-0"}>
                      Request History
                    </h1>

                    {
                      bidsData.oneToOneSessionArray
                        .filter(e => (['rejected', 'confirmed', 'cancelled'].includes(e.status)))
                        .map(_focusedBid => (
                            <RequestCard
                              offer={offerResponse.offer}
                              bid={_focusedBid}
                              key={_focusedBid.id}
                              view={view}
                            />
                          )
                        )
                    }
                  </>
                )
              }
            </>
          )}
        </div>
      </div>

      <ExchangeTimeModal
        offer={offerResponse.offer}
        exchangeModalOpen={exchangeModalOpen}
        handleExchangeModal={handleExchangeModal}
      />

      <ExchangeReportModal
        exchangeReportModal={exchangeReportModal}
        bidId={focusedBid?.id || 0}
        offerId={offerId || 0}
        handleClose={() => setExchangeReportModal(false)}
      />

    </LoggedInLayout>
  );
}

export default Exchange;
