import GoldStar from "../../assets/svg/GoldStar.svg";
import Calender from "../../assets/svg/Calender.svg";
import Clock from "../../assets/svg/Clock.svg"
import Back from "../../assets/svg/Back.svg";
import { Calendar } from 'react-calendar';
import moment from 'moment';
import { useEffect } from 'react';

const minDate = new Date().toISOString().split('T')[0];

const ExpertBookModal = (props) => {

    const checkExpertiseFilter = (value) => {
        if (value.expertiseHistory) {
            return value.expertiseHistory.expertise;
        }
    }

    useEffect(() => {

        if (!checkDateFunc(props.value)) {
            props.setActiveSlotTab("morning");
        }
        else {
            const currentTime = new Date().getHours();
            if (currentTime < 12) {
                props.setActiveSlotTab("morning");
            }
            else if (currentTime < 16) {
                props.setActiveSlotTab("afternoon");
            }
            else {
                props.setActiveSlotTab("evening");
            }
        }


    }, [props.value])

    const checkDateFunc = (d) => {
        let currDate = new Date();
        let date = new Date(d);

        if (currDate.getDate() === date.getDate()) {
            return true;
        }
        else {
            return false;
        }
    }

    const currentTime = new Date().getHours();

    return <>

        {!props.showResponsive ? <div class={`modal fade ${props.showBookForm ? 'show' : ''}`} style={{ zIndex: 3000, display: props.showBookForm ? 'block' : 'none', background: props.showBookForm ? 'rgba(0,0,0,0.5)' : '' }} id="slotBookModal" tabIndex="-1" aria-labelledby="slotBookModalLabel" aria-hidden="true">

            <div class="modal-dialog modal-dialog-centered modal-lg expert_book_modal_width">

                <div class="modal-content">

                    <div className="row m-0">
                        <div className="col-12 p-5">

                            <div className="row m-0">
                                <div className="col-12 p-0">
                                    <p className="mb-0 slotBookModal_heading">You are going to spend &nbsp; <span> {1 || 0} hour </span> &nbsp; of Well-being Time for attending this session.</p>
                                </div>
                            </div>

                            <div className="row m-0 mt-4">

                                <div className="col-6 p-0 ps-5 pe-5">

                                    <div className="row m-0">

                                        <div className="col-4 p-0">

                                            <div className="book_slote_model_img">
                                                <img src={props.selectedExpertData.user.profilePic ? props.selectedExpertData.user.profilePic : "/static/png/Male.png"} alt="" />
                                            </div>

                                        </div>

                                        <div className="col-8 p-0 d-flex flex-column  justify-content-center">

                                            <div className="row m-0">
                                                <div className="col-12 p-0">
                                                    <p className="mb-0 book_slotemodel_user_name">{props.selectedExpertData.user.firstName} {props.selectedExpertData.user.lastName}</p>
                                                </div>
                                            </div>

                                            <div className="row m-0 mt-1">
                                                <div className="col-3 p-0 brd_left">
                                                    <p className="mb-0 book_slotemodel_light_user_name">{checkExpertiseFilter(props.selectedExpertData)}</p>
                                                </div>
                                                <div className="col-3 p-0 brd_left d-flex align-items-center justify-content-center">
                                                    <p className="mb-0 book_slote_model_ratings"> <img src={GoldStar} alt="" /> {props.selectedExpertData.user.avgRatingOfSessions}</p>
                                                </div>
                                                <div className="col-4 p-0 d-flex align-items-center justify-content-center">
                                                    <p className="mb-0 book_slote_model_sessions">{props.selectedExpertData.totalSessionCount} sessions</p>
                                                </div>
                                                <div className="col-2 p-0"></div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                {/* <div className="col-6 p-0 d-flex flex-column justify-content-center">

                        <div className="row m-0">
                            <div className="col-12 p-0">
                                <p className="mb-0 choose_category">Choose Category:</p>
                            </div>
                        </div>

                        <div className="row m-0">
                            <div className="col-12 p-0">
                                <button className="slotebood_modal_mini_btn active_slotebood_modal_mini_btn me-1">Learning</button>
                                <button className="slotebood_modal_mini_btn">Noticing</button>
                            </div>
                        </div>
                    </div> */}

                            </div>

                            <div className="row m-0 mt-3">

                                <div className="col-6 p-0">
                                    {/* <img config={FullCalender} alt="" /> */}
                                    <Calendar
                                        onChange={props.onChange}
                                        onActiveStartDateChange={(e) => props.onChange(e.activeStartDate)}
                                        value={props.value}
                                        next2Label={false}
                                        prev2Label={false}
                                        prevLabel={false}
                                        nextLabel={false}
                                        minDate={new Date()}
                                    />
                                </div>

                                <div className="col-6 p-0">

                                    {!checkDateFunc(props.value) ? <div className="row m-0">
                                        <div className="col-12 p-0">
                                            <button className={`slot_book_lg_btn_am ps-4 ${props.activeSlotTab === "morning" ? "active" : ""}`} onClick={() => props.setActiveSlotTab("morning")}>Morning</button>
                                        </div>
                                    </div> : currentTime < 12 ? <div className="row m-0">
                                        <div className="col-12 p-0">
                                            <button className={`slot_book_lg_btn_am ps-4 ${props.activeSlotTab === "morning" ? "active" : ""}`} onClick={() => props.setActiveSlotTab("morning")}>Morning</button>
                                        </div>
                                    </div> : null}

                                    {!checkDateFunc(props.value) ? <div className="row m-0 mt-2">
                                        <div className="col-12 p-0">
                                            <button className={`slot_book_lg_btn_am ps-4 ${props.activeSlotTab === "afternoon" ? "active" : ""}`} onClick={() => props.setActiveSlotTab("afternoon")}>Afternoon</button>
                                        </div>
                                    </div> : currentTime < 16 ? <div className="row m-0 mt-2">
                                        <div className="col-12 p-0">
                                            <button className={`slot_book_lg_btn_am ps-4 ${props.activeSlotTab === "afternoon" ? "active" : ""}`} onClick={() => props.setActiveSlotTab("afternoon")}>Afternoon</button>
                                        </div>
                                    </div> : null}

                                    {!checkDateFunc(props.value) ? <div className="row m-0 mt-2">
                                        <div className="col-12 p-0">
                                            <button className={`slot_book_lg_btn_am ps-4 ${props.activeSlotTab === "evening" ? "active" : ""}`} onClick={() => props.setActiveSlotTab("evening")}>Evening</button>
                                        </div>
                                    </div> : currentTime < 23 ? <div className="row m-0 mt-2">
                                        <div className="col-12 p-0">
                                            <button className={`slot_book_lg_btn_am ps-4 ${props.activeSlotTab === "evening" ? "active" : ""}`} onClick={() => props.setActiveSlotTab("evening")}>Evening</button>
                                        </div>
                                    </div> : null}


                                    <div className="row m-0 mt-2">
                                        <div className="col-12 p-0 ps-4">
                                            {/* <button className="book_slote_time me-1">01:00</button>
                                            <button className="book_slote_time active_book_slote_time me-1">02:00</button>
                                            <button className="book_slote_time me-1">03:00</button> */}
                                            {props.timeExchnageSlots[props.checkActiveSlotTab(props.activeSlotTab)][props.activeSlotTab].filter(v => checkDateFunc(props.value) ? v >= (currentTime) : v).map((v, i) => {
                                                return <button className={`book_slote_time me-1 ${props.selectedSlotTime === v ? "active_book_slote_time" : ""}`} key={i} onClick={() => props.setSelectedSlotTime(v)}>{v}:00</button>
                                            })}
                                        </div>
                                    </div>

                                    <div className="row m-0 mt-5">
                                        <div className="col-12 p-0 d-flex align-items-center justify-content-end">
                                            <button className="book_slot_cancle me-2" data-bs-dismiss="modal" onClick={() => props.setShowBookForm(false)}>Cancel</button>
                                            <button className="book_slot_confirm" data-bs-toggle="modal" data-bs-target="#slotBookModalConfirmed" onClick={() => props.confirmBookSession()}>Confirm</button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div> : <div className="p-0 expert_book_screen">

            <div className='row m-0 expert_navbar'>
                <div className='col-12 p-0 ps-4 pe-4'>

                    <div className='row m-0 pt-3 pb-3 pt-md-4 pb-md-4'>

                        <div className='col-2 p-0 d-flex align-items-center cursor_pointer' onClick={() => props.setShowBookForm(false)}>
                            <img className='' src={Back} alt='' />
                        </div>
                        <div className='col-10 p-0 d-flex align-items-center'>
                            <p className='mb-0 alt_nav_heading'>Book a Session</p>
                        </div>

                    </div>

                </div>
            </div>

            <div className='row m-0'>
                <div className='col-12 p-4 book_section_bk book_section_bk_tab'>

                    <p className='book_section_heading'>You will spend <span> {1 || 0} H </span> of your time balance to book a session with this coach.</p>

                    <div className="row m-0 mt-4 align-items-start">
                        <div className="col-3 col-md-1 p-0">
                            <img width="70px" height="70px" style={{ borderRadius: '50%' }} src={props.selectedExpertData.user.profilePic ? props.selectedExpertData.user.profilePic : "/static/png/Male.png"} alt="" />
                        </div>
                        <div className="col-7 col-md-9 ps-md-4 p-0">
                            <p className="mb-0 book_section_name mt-md-1">{props.selectedExpertData.user.firstName} {props.selectedExpertData.user.lastName}</p>
                            <div className="d-flex align-items-center">
                                <p className="mb-0 mt-1 book_section_name_exp">{checkExpertiseFilter(props.selectedExpertData)}</p>&nbsp; | &nbsp;
                                <p className="mb-0 mt-1 book_section_name_session">{props.selectedExpertData.totalSessionCount} sessions</p>
                            </div>
                        </div>

                        <div className="col-2 p-0 d-flex align-items-center justify-content-center">
                            <p className="mb-0 book_section_ratings"> <img width="14px" src={GoldStar} alt="" /> {props.selectedExpertData.user.avgRatingOfSessions}</p>
                        </div>
                    </div>

                    <div className="row m-0 mt-4">
                        <p className="book_section_input_label p-0 mb-2">
                            {/* props.onChange(e.activeStartDate) */}
                            Pick Date
                        </p>
                        <input type="date" className="input_date" min={minDate} id="birthday" name="birthday" onChange={(e) => {
                            let date = new Date(e.target.value);
                            props.onChange(date);
                        }} />
                    </div>

                    <div className="row m-0 mt-3">
                        <p className="book_section_input_label p-0 mb-2">
                            Pick Time
                        </p>
                        <div className="col-12 p-0">


                            <div className="row m-0">
                                <div className="col-12 p-0">
                                    <button className={`slot_book_lg_btn_am ps-4 ${props.activeSlotTab === "morning" ? "active" : ""}`} onClick={() => props.setActiveSlotTab("morning")}>Morning</button>
                                </div>
                            </div>

                            <div className="row m-0 mt-2">
                                <div className="col-12 p-0">
                                    <button className={`slot_book_lg_btn_am ps-4 ${props.activeSlotTab === "afternoon" ? "active" : ""}`} onClick={() => props.setActiveSlotTab("afternoon")}>Afternoon</button>
                                </div>
                            </div>

                            <div className="row m-0 mt-2">
                                <div className="col-12 p-0">
                                    <button className={`slot_book_lg_btn_am ps-4 ${props.activeSlotTab === "evening" ? "active" : ""}`} onClick={() => props.setActiveSlotTab("evening")}>Evening</button>
                                </div>
                            </div>

                            <div className="row m-0 mt-2">
                                <div className="col-12 p-0">
                                    {props.timeExchnageSlots[props.checkActiveSlotTab(props.activeSlotTab)][props.activeSlotTab].map((v, i) => {
                                        return <button className={`book_slote_time me-1 ${props.selectedSlotTime === v ? "active_book_slote_time" : ""}`} key={i} onClick={() => props.setSelectedSlotTime(v)}>{v}:00</button>
                                    })}
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div className="row m-0 book_button_section">
                    <div className="col-12 p-0 book_button_section_div py-4">
                        <button className="book_slot_confirm" data-bs-toggle="modal" data-bs-target="#slotBookModalConfirmed" onClick={() => props.confirmBookSession()}>Book</button>
                    </div>
                </div>
            </div>

        </div>}


        {props.showConfirmBookedModal ? <div class={`modal fade ${props.showConfirmBookedModal ? 'show' : ''}`} style={{ zIndex: 3100, display: props.showConfirmBookedModal ? 'block' : 'none', background: props.showConfirmBookedModal ? 'rgba(0,0,0,0.5)' : '' }} id="slotBookModalConfirmed" tabIndex="-1" aria-labelledby="slotBookModalConfirmedLabel" aria-hidden="true">

            <div class="modal-dialog modal-dialog-centered">

                <div class="modal-content">

                    <div className="row m-0">
                        <div className="col-12 ps-4 pe-4 pt-4 pb-4">

                            <div className="row m-0">
                                <div className="col-12 p-0">
                                    <p className="mb-0 confirmation_model_heading text-center">Congratulations!</p>
                                    <p className="mb-0 confirmation_model_info_text text-center mt-3">You have booked a session with this Well-being Supporter.</p>
                                </div>
                            </div>

                            <div className="row m-0 mt-3">

                                <div className="col-lg-2 p-0">

                                </div>

                                <div className="col-lg-8 col-12 p-0">

                                    <div className="row m-0">

                                        <div className="col-4 p-0 d-flex align-items-center justify-content-center">

                                            <div className="book_slote_model_img">
                                                <img src={props.selectedExpertData.user.profilePic ? props.selectedExpertData.user.profilePic : "/static/png/Male.png"} alt="" />
                                            </div>

                                        </div>

                                        <div className="col-8 p-0">

                                            <div className="row m-0">
                                                <div className="col-12 p-0">
                                                    <p className="mb-0 book_slotemodel_user_name">{props.selectedExpertData.user.firstName} {props.selectedExpertData.user.lastName}</p>
                                                </div>
                                            </div>

                                            <div className="row m-0 mt-1">
                                                <div className="col-3 p-0 brd_left">
                                                    <p className="mb-0 book_slotemodel_light_user_name">{checkExpertiseFilter(props.selectedExpertData)}</p>
                                                </div>
                                                <div className="col-3 p-0 brd_left d-flex align-items-center justify-content-center">
                                                    <p className="mb-0 book_slote_model_ratings"> <img src={GoldStar} alt="" /> {props.selectedExpertData.user.avgRatingOfSessions}</p>
                                                </div>
                                                <div className="col-4 p-0 d-flex align-items-center justify-content-center">
                                                    <p className="mb-0 book_slote_model_sessions">{props.selectedExpertData.totalSessionCount} sessions</p>
                                                </div>
                                                <div className="col-2 p-0"></div>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="row m-0 mt-4 ps-4">
                                        <div className="col-1 p-0 d-flex align-items-center justify-content-center">
                                            <img src={Calender} alt="" />
                                        </div>
                                        <div className="col-11 p-0 ps-2">
                                            <p className="mb-0 confirmed_model_datetime">
                                                {moment(props.value).format("YYYY, MMMM, DD")}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="row m-0 mt-2 ps-4">
                                        <div className="col-1 p-0 d-flex align-items-center justify-content-center">
                                            <img src={Clock} alt="" />
                                        </div>
                                        <div className="col-11 p-0 ps-2">
                                            <p className="mb-0 confirmed_model_datetime">
                                                {props.selectedSlotTime}:00 {props.selectedSlotTime < 12 ? 'AM' : 'PM'} - {props.selectedSlotTime + 1}:00 {(props.selectedSlotTime + 1) < 12 ? 'AM' : 'PM'}
                                                {/* {(parseInt(props.selectedSlotTime) + parseInt(props.selectedExpertData.totalNoOfHours))}:00 {(parseInt(props.selectedSlotTime) + parseInt(props.selectedExpertData.totalNoOfHours)) < 12 ? 'AM' : 'PM'} */}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="row m-0 mt-5">
                                        <div className="col-12 p-0 d-flex align-items-center justify-content-center">
                                            <button className="model_confirmed_btn" data-bs-dismiss="modal" onClick={() => {
                                                props.closeConfirmBookedModal();
                                            }}>OK</button>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-2 p-0">

                                </div>

                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div> : null}



    </>
}

export default ExpertBookModal;
