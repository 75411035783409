import React from 'react';
import Search from '../assets/search1.png';


/**
 * @param {{
 *   title: string,
 *   searchValue: string,
 *   searchPlaceholder: string,
 *   searchOnChange: (e: ChangeEvent<HTMLInputElement>) => void,
 *   onCreateButtonTap?: (e: MouseEvent<HTMLButtonElement>) => void,
 *   topRightButton?: ReactNode
 *   columns: {
 *     label: string,
 *     showOnMobile: boolean,
 *     showOnDesktop: boolean,
 *     centered?: boolean
 *   }[],
 *   data: T[]
 *   rows: {
 *     render: (T) => ReactNode
 *   }[],
 *   footer?: ReactNode
 * }} props
 * @return {Element}
 * @constructor
 */
const FullWidthTable = (props) => {
  return (
    <>
      <div className="card border-0 shadow-sm tw-rounded-2xl">
        <div className="card-header bg-transparent border-0 pt-3">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className={"tw-text-[#410db7] montserrat fw-bold tw-text-lg m-0 lh-base"}>{props.title}</h1>
            <div className={"d-flex gap-3 align-items-center"}>
              <div className="input-group d-none d-lg-flex">
                <span className="input-group-text admin_search_span bg-white border-end-0 tw-rounded-l-3xl">
                    <img src={Search} alt=''/>
                </span>
                <input
                  type="text"
                  className="form-control admin_search_input tw-rounded-r-3xl"
                  placeholder={props.searchPlaceholder}
                  value={props.searchValue}
                  onChange={props.searchOnChange}
                />
              </div>

              {props.onCreateButtonTap && (
                <button
                  className='btn btn-outline-primary rounded-pill d-flex align-items-center justify-content-center tw-shrink-0 tw-h-[32px]'
                  type={"button"}
                  onClick={props.onCreateButtonTap}>

                  <i className="bi bi-plus-lg me-1"/> <span className={"tw-text-sm"}>Create</span>

                </button>
              )}
              {props.topRightButton}
            </div>
          </div>

          <div className="input-group d-flex d-lg-none mt-2">
              <span className="input-group-text admin_search_span bg-white border-end-0 tw-rounded-l-3xl">
                  <img src={Search} alt=''/>
              </span>
            <input
              type="text"
              className="form-control admin_search_input tw-rounded-r-3xl"
              placeholder={props.searchPlaceholder}
              value={props.searchValue}
              onChange={props.searchOnChange}
            />
          </div>
        </div>
        <div className="card-body py-0">
          <div className={"table-responsive"}>
            <table className="table vertical-middle">
              <thead>
              <tr>
                {props.columns.map(column => {
                  return (
                    <th className={`
                      ${column.showOnDesktop ? "d-lg-table-cell" : "d-lg-none"}
                      ${column.showOnMobile ? "d-table-cell" : "d-none"}
                      ${column.centered ? "text-center" : ""}
                      fw-semibold tw-text-xs !tw-text-opacity-50 !tw-text-[#334d6e] montserrat
                     `}>
                      {column.label}
                    </th>
                  )
                })}
              </tr>
              </thead>
              <tbody>
              {
                !props.data.length && (
                  <tr>
                    <td className="text-center" colSpan={props.columns.length}>No search found</td>
                  </tr>
                )
              }

              {
                props.data.map((datum) => {
                  return (
                    <tr key={datum.id} className={"tw-text-sm"}>
                      {
                        props.rows.map((row, index) => {
                          return (
                            <td key={row.id} className={`
                              ${props.columns[index].showOnDesktop ? "d-lg-table-cell" : "d-lg-none"}
                              ${props.columns[index].showOnMobile ? "d-table-cell" : "d-none"}
                            `}>
                              {row.render(datum)}
                            </td>
                          )
                        })
                      }
                    </tr>
                  )
                })
              }
              </tbody>
            </table>
          </div>
        </div>
        {props.footer && (
          <div className={"card-footer bg-transparent border-0 pb-3"}>
            {props.footer}
          </div>
        )}
      </div>
    </>
  )
}

export default FullWidthTable;