import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";

import "./superAdmin.css";
import {getUserInformationById, userStatus} from '../../utils/apiUrlsSetup';
import {Link, useNavigate} from 'react-router-dom';
import {roles} from '../../utils/constant';
import LoggedInLayout from "../../component/layout/LoggedInLayout.tsx";

const UserProfile = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const [userInfo, saveUserInfo] = useState({})

    const getUsersInformation = async () => {
        let responseData = await getUserInformationById(id);
        if (responseData.status === 200) {
            saveUserInfo(responseData.data.data)
        }

    }

    useEffect(() => {
        getUsersInformation();
    }, [])

    const handleDelete = async (id) => {
        let objData = {}
        objData.userId = id
        objData.status = "deleted"
        let responseData = await userStatus(objData);
        if (responseData.status === 200) {
            getUsersInformation();
        }
    }

    const handleActive = async (id) => {
        let objData = {}
        objData.userId = id
        objData.status = "active"
        let responseData = await userStatus(objData);
        if (responseData.status === 200) {
            getUsersInformation();
        }
    }

    const changeCreateOrgStatus = async (status) => {
        let objData = {}
        objData.userId = id
        objData.status = userInfo.status;
        objData.canCreateOrganisation = status;
        let responseData = await userStatus(objData);
        if (responseData.status === 200) {
            getUsersInformation();
        }
    }

    return (
        <LoggedInLayout title="User Profile">
            <div className={"d-flex align-items-center gap-3"}>
                <Link to={"/userlist"}>
                    <i className="bi bi-chevron-left text-primary"/>
                </Link>
                <div className="tw-text-2xl fw-semibold montserrat tw-text-[#54bfed]">
                    Profile
                </div>
            </div>

            <div className='coach_profile_update_box p-5 my-3'>
                <div className='row g-3'>
                    <div className='col-lg-2 col-12'>
                        <div className='sup_adm_user_pro_pic'>
                            <img src={userInfo.profilePic ? userInfo.profilePic : "/static/png/Male.png"} alt=''/>
                        </div>
                    </div>

                    <div className='col-lg-8 col-12'>
                        <div className='row m-0'>
                            <div className='col-12 p-0'>
                                <p className='mb-0 title_of_the_field'>Name</p>
                                <p className='mb-0 mt-2 value_of_the_field'>{userInfo.firstName} {userInfo.lastName}</p>
                            </div>
                        </div>

                        <div className='row m-0 mt-3'>
                            <div className='col-12 p-0'>
                                <p className='mb-0 title_of_the_field'>Email</p>
                                <p className='mb-0 mt-2 value_of_the_field'>{userInfo.email}</p>
                            </div>
                        </div>


                        <div className='row m-0 mt-3'>
                            <div className='col-12 p-0'>
                                <p className='mb-0 title_of_the_field'>Role</p>
                                <p className='mb-0 mt-2 value_of_the_field'>
                                    {userInfo.userType ? roles[userInfo.userType] : 'User profile'}
                                </p>
                            </div>
                        </div>

                    </div>

                    {userInfo.status !== "deleted" ?
                        <div className='col-lg-2 col-12 d-flex justify-content-end'>
                            <button className='sup_adm_del_acc_btn'
                                    onClick={() => handleDelete(id)}>Deactivate Account
                            </button>
                        </div> : <div className='col-2 p-0 d-flex justify-content-end'>
                            <button className='sup_adm_del_acc_btn sup_adm_act_acc_btn'
                                    onClick={() => handleActive(id)}>Reactivate Account
                            </button>
                        </div>}

                </div>

                {userInfo.status !== "deleted" ? <div className='row m-0'>
                    <div className='col-12 p-0 d-flex justify-content-end'>
                        {userInfo.canCreateOrganisation ?
                            <button className='sup_adm_del_acc_btn mt-3'
                                    onClick={() => changeCreateOrgStatus(false)}>Remove from
                                Community Admin</button>
                            :
                            <button
                                className='sup_adm_del_acc_btn sup_adm_del_acc_btn_blue mt-3'
                                onClick={() => changeCreateOrgStatus(true)}>Assign as Community
                                Admin</button>}
                    </div>
                </div> : null}


            </div>
        </LoggedInLayout>
    );
};

export default UserProfile;