import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {getUserInformation, LoginApi, verifyEmail} from "../../utils/apiUrlsSetup";

import '../../css/loginAndSignup.css';

import {useDispatch} from 'react-redux';
import {add} from '../../redux/actions/action';
import {HOME} from "../../config/ROUTES";
import {useZStore} from "../../utils/zStore";
import Swal from "sweetalert2";

const Login = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState(null)
  const [ispwd, setIspwd] = useState(false);

  const dispatch = useDispatch();

  const [btndisable, setBtnDisable] = useState(true);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  })

  const zStore = useZStore();

  const queryParams = new URLSearchParams(useLocation().search)

  if (queryParams && queryParams.get('token')) {
    if (!token) {
      setToken(queryParams.get('token'))
    }
  }

  useEffect(() => {
    if (token) {
      verifyEmail(token).then(response => {
        const _apiMsg = response.data.message;
        let header;
        let body;
        let icon;
        if (_apiMsg === "Account inactive" || _apiMsg === "Email not found") {
          header = 'Account Not Found'
        } else if (_apiMsg) {
          header = 'Message';
        } else if (response.status === 200) {
          header = 'Account Activated!'
          navigate("/")
        } else {
          header = 'Account not activated!';
        }

        if (_apiMsg === 'Email not verified') {
          body = "Your email is not verified yet. Please check your email to verify your account."
          icon = "warning"
        } else if (_apiMsg === "Email verified successfully") {
          body = "Your email has been verified successfully"
          icon = "success"
        } else if (_apiMsg) {
          body = _apiMsg
        } else if (response.status !== 200) {
          body = "Your account is pending approval! Please wait for your account to be activated."
          icon = "info"
        } else {
          body = "Your account could not be activated due to invalid activation link. Try requesting for a new link."
          icon = "error"
        }

        Swal.fire(header, body, icon)
      })
    }
  }, [])

  const [emailValidationError, setEmailValidationError] = useState(null)
  const [passwordValidationError, setPasswordValidationError] = useState(null)

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLoginData({
      ...loginData, [name]: value
    })
  }

  function ValidateEmail(email) {
    const mailformat = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/g;
    return !!email.match(mailformat);
  }

  useEffect(() => {
    if (loginData.email && loginData.password) {
      setBtnDisable(false)
    } else if (loginData.email && loginData.password) {
      setBtnDisable(true)
    }
  }, [loginData])


  const handleSubmit = async () => {
    let isFail
    if (loginData.email === "") {
      isFail = true
      setEmailValidationError('Email is required')
    }

    if (loginData.email && !ValidateEmail(loginData.email)) {
      isFail = true
      setEmailValidationError('Email is invalid')
    }


    if (!isFail) {
      let objData = {}
      objData.email = loginData.email
      objData.password = loginData.password
      objData.deviceToken = "1234"
      objData.deviceType = "web"

      let responseData = await LoginApi(objData);

      if (responseData.statusCode === 200) {
        localStorage.setItem("userDetails", JSON.stringify(responseData.data));
        localStorage.setItem("accessToken", responseData.data.accessToken);

        const userDetails = (await getUserInformation()).data.data
        zStore.setUserDetails(userDetails)

        await dispatch(add(responseData.data));
        if (responseData.data.firstTime) {
          navigate('/onboarding');
        } else {
          const link = localStorage.getItem('link')
          if (link) {
            navigate(link)
            return
          }
          navigate(HOME);
        }
      } else {
        Swal.fire({
          title: "Failed",
          text: responseData.message,
          icon: "error"
        })
      }
    }
  }

  return (
    <>
      <div className='p-3' style={{
        background: '#FFCC4D',
        borderRadius: '6px',
        padding: '0px 30px',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
      }}>
        <div className="row m-0">
          <div className="col-12 p-0">
            <input
              className="input_filds home_page_login_form"
              type="text"
              name="email"
              value={loginData.email}
              id="email"
              placeholder="Business Email"
              onChange={onChangeHandler}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && loginData.email && loginData.password) {
                  handleSubmit()
                }
              }}
            />
          </div>
          {
            emailValidationError ?
              <span className='validation mt-2'>{emailValidationError}</span> : null
          }
        </div>

        <div className="row m-0 mt-2">
          <div className="col-12 p-0">
            <div className="input-group home_page_login_form">
              <input
                className="form-control input_filds mt-2"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                type={ispwd ? 'text' : 'password'}
                name="password"
                id="password"
                value={loginData.password}
                placeholder="Password"
                onChange={onChangeHandler}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && loginData.email && loginData.password) {
                    handleSubmit()
                  }
                }}
              />
              <span className="input-group-text pass_show_hide" id="basic-addon2"
                    onClick={() => setIspwd(prevState => !prevState)}>{ispwd ? 'Hide' : 'Show'}</span>
            </div>
            {
              passwordValidationError ?
                <span className='validation mt-3 ms-3'>{passwordValidationError}</span> : null
            }

          </div>
        </div>

        <div className="row m-0 mt-3">
          <div className="p-0 d-flex align-items-center justify-content-center">
            <button className={`login_btn active_btn home_page_login_btn login_btn_web`}
                    disabled={btndisable} onClick={handleSubmit}>Login
            </button>
          </div>
        </div>
        <p className="forgot_pwd my-3 text-center" onClick={() => {
          navigate('/forget-password')
        }}>
          Forgot password?
        </p>
        <hr/>

        <div className="row m-0 mt-3">
          <div className="d-flex align-items-center justify-content-center">
            <button className={`home_page_signup_btn home_page_signup_btn_2 login_btn`}
                    onClick={() => navigate('/signup')}>Create An Account
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login;
