/**
 * Capitalize each words
 *
 * @param {string} str Raw string that you want to capitalized
 * @return {string} Capitalized string
 */
export function ucwords(str) {
    return str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
    });
}
