import {createPostLike, deletePostLike} from "../../../../utils/apiUrlsSetup";
import {useZStore} from "../../../../utils/zStore";
import LikeBlack from "../../../../assets/svg/LikeBlack.svg";
import Like from "../../../../assets/svg/Like.svg";
import React, {useEffect, useRef} from "react";

/**
 * @param {{
 *   postId: number,
 *   reactorState: [
 *      {userId: string, type: string}[] | null,
 *      React.Dispatch<React.SetStateAction<{userId: string, type: string}[] | null>>,
 *      (boolean) => Promise<{userId: string, type: string}[]>
 *   ]
 * }} props
 * @constructor
 */
export default function LikeButton(props) {
  const store = useZStore()
  const userDetails = store.userDetails;
  const inTimerRef = useRef()
  const outTimerRef = useRef()
  const touchTimeoutRef = useRef(null);
  const hasHeldRef = useRef(false);

  const reactorState = props.reactorState;
  const postReactions = reactorState[0]
  const mutatePostReactions = reactorState[2]

  const sendReaction = async (type) => {
    store.setShowingReactionTopId(``)
    await createPostLike({postId: props.postId, type: type}).then(async () => {
      await mutatePostReactions()
    })
  }

  // Handle touch start (start detecting tap or hold)
  const handleTouchStart = (e) => {
    hasHeldRef.current = false;  // Reset hold state
    // Set a timeout for 1 second to determine "Holded"
    touchTimeoutRef.current = setTimeout(() => {
      hasHeldRef.current = true; // Mark that it has been held
      store.setShowingReactionTopId(`like-${props.postId}`)
    }, 500);  // 1 second threshold for "Holded"
  };

  // Handle touch end (stop detecting and decide between tap and hold)
  const handleTouchEnd = (e) => {
    try {
      e.preventDefault()
      e.stopPropagation()
    } catch {
    }

    // Clear the timeout if touch ends before 1 second
    clearTimeout(touchTimeoutRef.current);

    // If it was not held for more than 1 second, treat as "Tapped"
    if (!hasHeldRef.current) {
      console.log("By Touch, Main Button Clicked")
      onMainButtonClicked(e);
    }
  };

  const handleMouseEnter = (e) => {
    try {
      e.preventDefault()
      e.stopPropagation()
    } catch {
    }

    if (outTimerRef.current) {
      clearTimeout(outTimerRef.current);
      outTimerRef.current = null;
    }

    inTimerRef.current = setTimeout(() => {
      store.setShowingReactionTopId(`like-${props.postId}`)
    }, 400);
  };

  const handleMouseLeave = (e) => {
    try {
      e.preventDefault()
      e.stopPropagation()
    } catch {
    }

    if (inTimerRef.current) {
      clearTimeout(inTimerRef.current);
      inTimerRef.current = null;
    }

    outTimerRef.current = setTimeout(() => {
      store.setShowingReactionTopId(``)
    }, 400);
  };

  // Cancel onMouseLeave if mouse re-enters
  useEffect(() => {
    return () => {
      if (inTimerRef.current) {
        clearTimeout(inTimerRef.current);
      }
      if (outTimerRef.current) {
        clearTimeout(outTimerRef.current);
      }
    };
  }, []);

  const onMainButtonClicked = async (e) => {
    try {
      e.preventDefault()
      e.stopPropagation()
    } catch {
    }
    store.setShowingReactionTopId(``)

    const existingReaction = (postReactions ?? []).filter(e => {
      return e.userId === userDetails.id
    })
    if (existingReaction.length > 0) {
      await deletePostLike({postId: props.postId}).then(async () => {
        await mutatePostReactions()
      })
      return;
    }

    await createPostLike({postId: props.postId}).then(async () => {
      await mutatePostReactions()
    })
  }

  let currentReactionId = (postReactions ?? []).find(e => e.userId === userDetails.id)?.type || "like"
  let currentReaction = currentReactionId[0].toUpperCase() + currentReactionId.slice(1)

  let color = "#ffd212"
  if (currentReactionId === "angry") {
    color = "#f16b1f"
  } else if (currentReactionId === "love") {
    color = "#f2506c"
  } else if (currentReactionId === "like") {
    color = "#54bfed"
  }

  return (
    <div className={"position-relative tw-w-full"}>
      <button
        onClick={onMainButtonClicked}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={"d-flex tw-items-center tw-justify-center tw-w-full hover:tw-bg-[#ccecff] tw-rounded-lg tw-py-0.5"}
      >
        {postReactions && (
          <>
            {postReactions.map(e => e.userId).includes(userDetails.id) ?
              <>
                {currentReactionId === "like" ?
                  <img alt="like" src={Like} className={"tw-w-[18px] tw-h-[18px] tw-me-1.5"}/>
                  :
                  <img alt="like" src={`/static/r_${currentReactionId}.png`}
                       className={"tw-w-[28px] tw-h-[28px] tw-me-1"}/>
                }
              </>
              :
              <img alt="like" src={LikeBlack} className={"tw-w-[18px] tw-h-[18px] tw-me-1.5"}/>
            }
            <span
              data-active={postReactions.map(e => e.userId).includes(userDetails.id)}
              className={`tw-mb-0.5 tw-font-medium`} style={{
              color: postReactions.map(e => e.userId).includes(userDetails.id) ? color : "#565656"
            }}>
              {currentReaction}
            </span>
          </>
        )}
      </button>

      <div
        className={`position-absolute ${store.showingReactionTopId === `like-${props.postId}` ? '' : 'd-none'} tw-z-[998] bottom-100 text-dark bg-white shadow-sm border tw-px-1 rounded-pill d-flex reactionShowTopup`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {[
          "like",
          "love",
          "hug",
          "happy",
          "wow",
          "sad",
          "angry",
        ].map(e => {
          return (
            <div
              key={e}
              onClick={() => sendReaction(e)}
              className={"tw-cursor-pointer reactionShowTopup d-flex tw-w-[40px] tw-h-[40px] justify-content-center align-items-center text-primary rounded-circle"}>
              <img alt={e} src={`/static/r_${e}.png`}
                   className={"tw-w-[40px] reactionShowTopup tw-h-[40px] hover:tw-scale-125 tw-transition-all"}/>
            </div>
          )
        })}
      </div>
    </div>
  )
}