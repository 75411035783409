import React from 'react';
import SideNav from '../../component/staticPages/SideNav';
import { useNavigate } from 'react-router';

import "../../css/journey.css";

import Tuesday from "../../assets/svg/Tuesday.svg";
import Wednesday from "../../assets/svg/Wednesday.svg";
import X2 from "../../assets/svg/X2.svg";
import JourneyLeft from "../../assets/svg/JourneyLeft.svg";
import JourneyRight from "../../assets/svg/JourneyRight.svg";
import Emoji1 from "../../assets/svg/Emoji1.svg";
import Emoji2 from "../../assets/svg/Emoji2.svg";
import Emoji3 from "../../assets/svg/Emoji3.svg";
import Emoji4 from "../../assets/svg/Emoji4.svg";
import Emoji5 from "../../assets/svg/Emoji5.svg";
import Trophy from "../../assets/Trophy.svg";
import Award from "../../assets/Award.svg";

import YourPathProfileImg from "../../assets/YourPathProfileImg.png";
import AssociatedPhoto from "../../assets/AssociatedPhoto.png";
import AssociatedPhoto1 from "../../assets/AssociatedPhoto1.png";
import AssociatedPhoto2 from "../../assets/AssociatedPhoto2.png";
import AssociatedPhoto3 from "../../assets/AssociatedPhoto3.png";

function Journey() {
    const navigate = useNavigate()
    return (
        <>
            <div className='row m-0 d-flex newsfeed_bg'>

                <div className='news_left p-0'>

                    <SideNav journey={true} />

                </div>

                <div className='news_right p-0'>

                    <div className='row m-0'>
                        <div className='col-12 p-5'>

                            <div className='row m-0'>
                                <div className='col-12 p-0'>
                                    <p className='mb-0 notification_heading'>Journey</p>

                                    <p className='mb-0 mt-3 journey_quest'>Journey > Quest</p>

                                    <p className='mb-0 mt-3 journey_quest_text'>Complete courses everyday to build <br /> your streak and get featured.</p>
                                </div>
                            </div>

                            <div className='row m-0 mt-4'onClick={(e) => { 

                                e.stopPropagation();
                                // navigate('/journey/quests/explorequestinvitation')

                                 }}>

                                <div className='col-12 p-0'>

                                    <div className='day_progress_card active_day_progress_card p-2 d-flex align-items-center justify-content-around'>

                                        {/* <img config={Monday} alt='' />
                                        <img config={Tuesday} alt='' />
                                        <img config={Wednesday} alt='' />
                                        <img config={Thusday} alt='' />
                                        <img config={Friday} alt='' />
                                        <img config={Saterday} alt='' />
                                        <img config={Sunday} alt='' />
                                        <img config={X2} alt='' /> */}

                                        <div className='card_circle monday_circle d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 card_days'>M</p>
                                        </div>

                                        <img src={Tuesday} alt='' />

                                        <img src={Wednesday} alt='' />

                                        <div className='card_circle blue_day_circle d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 card_days blue_days'>T</p>
                                        </div>

                                        <div className='card_circle blue_day_circle d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 card_days blue_days'>F</p>
                                        </div>

                                        <div className='card_circle blue_day_circle d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 card_days blue_days'>S</p>
                                        </div>

                                        <div className='card_circle blue_day_circle d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 card_days blue_days'>S</p>
                                        </div>

                                        <img src={X2} alt='' />

                                    </div>

                                    <div className='row m-0 mt-4'>
                                        <div className='col-12 p-0 ps-4 your_path_section active_your_path_section d-flex align-items-center justify-content-start'>

                                            <p className='mb-0 me-3 your_path_heading'>Your Path</p>

                                            <div className='your_path_day_count pe-1 ps-3 me-3 d-flex align-items-center justify-content-around'>
                                                <p className='mb-0 bold_path_heading'>1.</p>

                                                <div className='your_path_circle circle_type_one  d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 your_path_circle_inner_text'>1</p>
                                                </div>

                                                <div className='your_path_circle circle_type_one  d-flex align-items-center justify-content-center'>
                                                    <img src={YourPathProfileImg} alt='' />
                                                </div>

                                                <div className='your_path_circle circle_type_one circle_type_two  d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 your_path_circle_inner_text'>3</p>
                                                </div>

                                                <div className='your_path_circle circle_type_one circle_type_tree  d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 your_path_circle_inner_text'>4</p>
                                                </div>
                                            </div>

                                            <div className='your_path_day_count your_path_day_count_one pe-1 ps-3 me-3 d-flex align-items-center justify-content-around'>
                                                <p className='mb-0 bold_path_heading'>2.</p>

                                                <div className='your_path_circle circle_type_one circle_type_tree  d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 your_path_circle_inner_text'>1</p>
                                                </div>

                                                <div className='your_path_circle circle_type_one circle_type_tree  d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 your_path_circle_inner_text'>2</p>
                                                </div>

                                                <div className='your_path_circle circle_type_one circle_type_tree  d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 your_path_circle_inner_text'>3</p>
                                                </div>

                                                <div className='your_path_circle circle_type_one circle_type_tree  d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 your_path_circle_inner_text'>4</p>
                                                </div>
                                            </div>

                                            <div className='your_path_day_count your_path_day_count_one pe-1 ps-3 me-3 d-flex align-items-center justify-content-around'>
                                                <p className='mb-0 bold_path_heading'>3.</p>

                                                <div className='your_path_circle circle_type_one circle_type_tree  d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 your_path_circle_inner_text'>1</p>
                                                </div>

                                                <div className='your_path_circle circle_type_one circle_type_tree  d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 your_path_circle_inner_text'>2</p>
                                                </div>

                                                <div className='your_path_circle circle_type_one circle_type_tree  d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 your_path_circle_inner_text'>3</p>
                                                </div>

                                                <div className='your_path_circle circle_type_one circle_type_tree  d-flex align-items-center justify-content-center'>
                                                    <p className='mb-0 your_path_circle_inner_text'>4</p>
                                                </div>
                                            </div>

                                            <div className='row m-0'>
                                                <div className='col p-0'>
                                                    <p className='mb-0 beginer_text'>BEGINNER</p>
                                                    <p className='mb-0 mt-2 initiated_progress'>Zen Initiate (10%)</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='row m-0 mt-4'>
                                        <div className='col-12 p-4 your_quest_section active_your_quest_section' onClick={(e) => { 
                                            e.stopPropagation();
                                            navigate('/journey/quests') 
                                            }}>

                                            <div className='row m-0'>

                                                <div className='col-12 p-0'>

                                                    <div className='row m-0'>
                                                        <div className='col-4 p-0'>
                                                            <p className='mb-0 your_quests_heading'>Your Quests</p>
                                                        </div>

                                                        <div className='col-4 p-0 ps-2 d-flex align-items-center justify-content-center'>
                                                            <img className='me-2' src={JourneyLeft} alt='' />
                                                            <img src={JourneyRight} alt='' />
                                                        </div>

                                                        <div className='col-4 p-0 pe-5 d-flex align-items-center justify-content-end'>
                                                            <img className='me-2' src={JourneyLeft} alt='' />
                                                            <img src={JourneyRight} alt='' />
                                                        </div>
                                                    </div>

                                                    <div className='row m-0 mt-2'>
                                                        <div className='col-12 p-0 d-flex align-items-center'>

                                                            <div className='quests_cards me-3'>

                                                                <div className='row m-0 p-2 mt-2'>
                                                                    <div className='col-6 p-0'>
                                                                        <p className='mb-0 quests_cards_count'>0</p>
                                                                    </div>
                                                                    <div className='col-6 p-0 d-flex justify-content-end'>
                                                                        <img src={Emoji1} alt='' />
                                                                    </div>
                                                                </div>

                                                                <div className='row m-0 p-2 mt-1'>
                                                                    <div className='col-12 p-0'>
                                                                        <p className='mb-0 quests_card_inside_text'>Spend 15min on Guided Meditation</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='quests_cards me-3'>

                                                                <div className='row m-0 p-2 mt-2'>
                                                                    <div className='col-6 p-0'>
                                                                        <p className='mb-0 quests_cards_count'>30</p>
                                                                    </div>
                                                                    <div className='col-6 p-0 d-flex justify-content-end'>
                                                                        <img src={Emoji2} alt='' />
                                                                    </div>
                                                                </div>

                                                                <div className='row m-0 p-2 mt-1'>
                                                                    <div className='col-12 p-0'>
                                                                        <p className='mb-0 quests_card_inside_text'>Spend a total of 60min on Group Class (Well-Being)</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='quests_cards me-3'>

                                                                <div className='row m-0 p-2 mt-2'>
                                                                    <div className='col-6 p-0'>
                                                                        <p className='mb-0 quests_cards_count'>10</p>
                                                                    </div>
                                                                    <div className='col-6 p-0 d-flex justify-content-end'>
                                                                        <img src={Emoji3} alt='' />
                                                                    </div>
                                                                </div>

                                                                <div className='row m-0 p-2 mt-1'>
                                                                    <div className='col-12 p-0'>
                                                                        <p className='mb-0 quests_card_inside_text'>Spend a total of 60min on one-on-one sessions (free trial)</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='quests_cards me-3'>

                                                                <div className='row m-0 p-2 mt-2'>
                                                                    <div className='col-6 p-0'>
                                                                        <p className='mb-0 quests_cards_count'>100</p>
                                                                    </div>
                                                                    <div className='col-6 p-0 d-flex justify-content-end'>
                                                                        <img src={Emoji4} alt='' />
                                                                    </div>
                                                                </div>

                                                                <div className='row m-0 p-2 mt-1'>
                                                                    <div className='col-12 p-0'>
                                                                        <p className='mb-0 quests_card_inside_text'>Spend a total of 120min on one-on-one sessions (premium)</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='line me-3'>

                                                            </div>

                                                            <div className='quests_cards me-3'>

                                                                <div className='row m-0 p-2 mt-1'>
                                                                    <div className='col-8 p-0'>
                                                                        <p className='mb-0 quests_cards_make_activity'>Register</p>
                                                                    </div>
                                                                    <div className='col-4 p-0 d-flex justify-content-end'>
                                                                        <img src={Emoji5} alt='' />
                                                                    </div>
                                                                </div>

                                                                <div className='row m-0 p-2 mt-3'>
                                                                    <div className='col-12 p-0'>
                                                                        <p className='mb-0 quests_card_inside_text'>Register a free account with Kaleidoscope.</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='quests_cards me-3'>

                                                                <div className='row m-0 p-2 mt-1'>
                                                                    <div className='col-8 p-0'>
                                                                        <p className='mb-0 quests_cards_make_activity'>Link Email</p>
                                                                    </div>
                                                                    <div className='col-4 p-0'>
                                                                        <img src={Emoji5} alt='' />
                                                                    </div>
                                                                </div>

                                                                <div className='row m-0 p-2'>
                                                                    <div className='col-12 p-0'>
                                                                        <p className='mb-0 quests_card_inside_text'>Confirm your account by clicking on the confirmation email.</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='quests_cards me-3'>

                                                                <div className='row m-0 p-2 mt-1'>
                                                                    <div className='col-8 p-0'>
                                                                        <p className='mb-0 quests_cards_make_activity'>Enter Live Chat</p>
                                                                    </div>
                                                                    <div className='col-4 p-0'>
                                                                        <img src={Emoji5} alt='' />
                                                                    </div>
                                                                </div>

                                                                <div className='row m-0 p-2 mt-1'>
                                                                    <div className='col-12 p-0'>
                                                                        <p className='mb-0 quests_card_inside_text'>Enter a live chat room to see what’s going on.</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='row m-0 mt-4'>
                                        <div className='col-12 p-4 community_progress active_community_progress' onClick={() => { navigate('/journey/leaderboard')}}>

                                            <div className='row m-0'>
                                                <div className='col-6 p-0 pe-3 d-flex align-items-center justify-content-between'>

                                                    <div className='inside_community_progress'>
                                                        <div className='row m-0 d-flex align-items-center'>

                                                            <div className='col-6 p-0'>
                                                                <p className='mb-0 community_progress_heading'>Community Progress (Level 1)</p>
                                                            </div>

                                                            <div className='col-6 p-0'>

                                                                <div className='row m-0 d-flex justify-content-end'>

                                                                    <div className='col-5 p-0 ps-3 d-flex flex-column align-items-center justify-content-center'>
                                                                        <img src={Trophy} alt="" />
                                                                        <p className='mb-0 community_table_heading'>CHALLENGES</p>
                                                                    </div>
                                                                    <div className='col-4 p-0 d-flex flex-column align-items-center justify-content-center'>
                                                                        <img src={Award} alt="" />
                                                                        <p className='mb-0 community_table_heading'>QUESTS</p>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div className='row m-0'>
                                                            <div className='col-12 p-0'>

                                                                <div className='community_progress_bar active_community_progress_bar mt-2 pt-1 pb-1'>

                                                                    <div className='row m-0'>
                                                                        <div className='col-2 p-0 d-flex align-items-center justify-content-around'>
                                                                            <p className='mb-0 community_ranking active_community_ranking'>6</p>
                                                                            <img src={AssociatedPhoto} alt='' />
                                                                        </div>
                                                                        <div className='col-6 p-0'>

                                                                            <div className='row m-0'>
                                                                                <div className='col-12 p-0'>
                                                                                    <p className='mb-0 community_users_name'>Theresa Lim (You)</p>
                                                                                    <div className='progressBar'>
                                                                                        <div className='inside_progress_bar ipb_45 pe-2'>
                                                                                            <p className='mb-0 percent_data'>45%</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                                                            <p className='mb-0 community_row_data'>6</p>
                                                                        </div>
                                                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                                                            <p className='mb-0 community_row_data'>4</p>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div className='community_progress_bar mt-2'>

                                                                    <div className='row m-0'>
                                                                        <div className='col-2 p-0 d-flex align-items-center justify-content-around'>
                                                                            <p className='mb-0 community_ranking'>1</p>
                                                                            <img src={AssociatedPhoto1} alt='' />
                                                                        </div>
                                                                        <div className='col-6 p-0'>

                                                                            <div className='row m-0'>
                                                                                <div className='col-12 p-0'>
                                                                                    <p className='mb-0 community_users_name'>Debbie Tan</p>
                                                                                    <div className='progressBar'>
                                                                                        <div className='inside_progress_bar ipb_70 pe-2'>
                                                                                            <p className='mb-0 percent_data'>70%</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                                                            <p className='mb-0 community_row_data'>12</p>
                                                                        </div>
                                                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                                                            <p className='mb-0 community_row_data'>10</p>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div className='community_progress_bar mt-2'>

                                                                    <div className='row m-0'>
                                                                        <div className='col-2 p-0 d-flex align-items-center justify-content-around'>
                                                                            <p className='mb-0 community_ranking'>2</p>
                                                                            <img src={AssociatedPhoto2} alt='' />
                                                                        </div>
                                                                        <div className='col-6 p-0'>

                                                                            <div className='row m-0'>
                                                                                <div className='col-12 p-0'>
                                                                                    <p className='mb-0 community_users_name'>Parvesh Kumar</p>
                                                                                    <div className='progressBar'>
                                                                                        <div className='inside_progress_bar ipb_65 pe-2'>
                                                                                            <p className='mb-0 percent_data'>65%</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                                                            <p className='mb-0 community_row_data'>12</p>
                                                                        </div>
                                                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                                                            <p className='mb-0 community_row_data'>10</p>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div className='community_progress_bar mt-1'>

                                                                    <div className='row m-0'>
                                                                        <div className='col-2 p-0 d-flex align-items-center justify-content-around'>
                                                                            <p className='mb-0 community_ranking'>3</p>
                                                                            <img src={AssociatedPhoto3} alt='' />
                                                                        </div>
                                                                        <div className='col-6 p-0'>

                                                                            <div className='row m-0'>
                                                                                <div className='col-12 p-0'>
                                                                                    <p className='mb-0 community_users_name'>Audrey Kum</p>
                                                                                    <div className='progressBar'>
                                                                                        <div className='inside_progress_bar ipb_55 pe-2'>
                                                                                            <p className='mb-0 percent_data'>55%</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                                                            <p className='mb-0 community_row_data'>12</p>
                                                                        </div>
                                                                        <div className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                                                            <p className='mb-0 community_row_data'>10</p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='pt-3'>
                                                        <div className='devider'>

                                                        </div>
                                                    </div>

                                                </div>

                                                <div className='col-6 p-0 pt-2 ps-2'>
                                                    <p className='mb-0 community_progress_heading'>People Who Have Completed Similar Pathways</p>
                                                    <p className='mb-0 mt-3 pathway_text'><span className='pathway_bold'>First Group Activity:</span> 40% of Level 1 users similarly chose <span className='hyperlinks'>Relationships</span> for their first Group <br /> Activity like you.</p>
                                                    <p className='mb-0 mt-2 pathway_text'><span className='pathway_bold'>One-on-one sessions:</span> 60% of Level 1 users similarly booked a well-being coach for <br /> <span className='hyperlinks'>Financial Well-Being.</span> </p>
                                                    <p className='mb-0 mt-2 pathway_text'><span className='pathway_bold'>Token Airdrop:</span> 50% of Level 1 users like you opened their <span className='hyperlinks'>First Airdrop (50WBT).</span> </p>
                                                    <p className='mb-0 mt-2 pathway_text'><span className='pathway_bold'>Explorer:</span> 90% of Level 1 users like you explored ALL of Kaleidoscope’s features.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Journey;