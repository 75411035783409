import React, { useEffect, useState } from "react";
import SideNav from '../../../component/staticPages/SideNav';

import TimeExchangePocket from "../../../assets/svg/TimeExchangePocket.svg";
import TimeExchangeEmoji from "../../../assets/svg/TimeExchangeEmoji.svg";

import Feeling from '../feeling/feeling';
import Connecting from '../connecting/connecting';
import Functioning from '../functioning/functioning';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import Event from '../component/event';
import Points from './../../timeExchange/component/point';
import { getMetaData, updateMetaData } from "../../../utils/apiUrlsSetup";
import Joyride from 'react-joyride';
import { saveTourData } from './../../../redux/actions/action';

const CommunityTabWeb = (props) => {

    let dispatch = useDispatch();
    const data = useSelector(state => state.tourData);

    const [tourData, setTourData] = useState({});

    useEffect(() => {
        if (!_.isEmpty(data)) {
            setTourData(data.tourData);
        }
    }, [data])


    const handleJoyrideCallback = data => {
        const { action, index, status, type } = data;

        if ((status === "finished" && type === "tour:end") || action === "skip") {
            updateUserMetaData();
        }
    };

    const updateUserMetaData = async () => {
        let platformTour = {
            newsFeedTour: tourData.newsFeedTour,
            timeExchangeTour: tourData.timeExchangeTour,
            communityTour: true,
            journeyTour: tourData.journeyTour,
            expertTour: tourData.expertTour,
        };

        let response = await updateMetaData(platformTour);
        if (response.status === 200) {
            const responseData = await getMetaData();
            if (responseData.status === 200) {
                dispatch(saveTourData(responseData.data.data));
            }
        }
    }

    return (
        <>

            <div className="d-none d-lg-block">
                <div className='row m-0 d-flex newsfeed_bg web_view'>

                    <div className='news_left p-0'>
                        <SideNav community={true} />
                    </div>

                    <div className='news_right p-5'>
                        <div className='row m-0'>
                            <div className='col-12 p-0'>

                                <div className='row m-0'>

                                    <div className='col-8 p-0'>

                                        <p className='mb-0 notification_heading'>Experiences</p>

                                    </div>
                                    <Points point={props.point} />

                                </div>


                                <div className='row m-0 mt-5'>

                                    <div className='col-6 p-0'>

                                        <div className='community_destination_box community_destination_box_1'>

                                            <div className='row m-0'>
                                                <div className='col-12 p-0 ps-4 pe-4 pt-3 pb-3 leaderboard_time '>
                                                    <div className='row m-0'>
                                                        <div className='col-4 p-0 d-flex align-items-center justify-content-start'>
                                                            <p className={`mb-0 leaderboard_time_day ${props.communityPage === 1 ? 'leaderboard_active_day' : ''}`} onClick={() => { props.handleClick(1) }}>Feeling</p>
                                                        </div>
                                                        <div className='col-4 p-0  d-flex align-items-center justify-content-center'>
                                                            <p className={`mb-0 leaderboard_time_day ${props.communityPage === 2 ? 'leaderboard_active_day' : ''}`} onClick={() => { props.handleClick(2) }}>Functioning</p>
                                                        </div>
                                                        <div className='col-4 p-0  d-flex align-items-center justify-content-end'>
                                                            <p className={`mb-0 leaderboard_time_day ${props.communityPage === 3 ? 'leaderboard_active_day' : ''}`} onClick={() => { props.handleClick(3) }}>Connecting</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className='row m-0'>
                                                <div className='col-12 p-0'>

                                                    {
                                                        props.communityPage === 1 && <Feeling />
                                                    }

                                                    {
                                                        props.communityPage === 2 && <Functioning />}

                                                    {
                                                        props.communityPage === 3 && <Connecting />
                                                    }

                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <div className='col-6 p-0 d-flex justify-content-end'>

                                        <div className='community_destination_box '>

                                            <Event />

                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


            </div>

            {!_.isEmpty(tourData) && !tourData.communityTour ? <Joyride
                continuous
                showProgress
                showSkipButton
                run={true}
                callback={handleJoyrideCallback}
                steps={[
                    {
                        content: (
                            <React.Fragment>
                                <h2>Events</h2>
                                <p>Here you can find Kaleidoscope Live events hosted by Well-being Supporters by each experience centre.</p>
                            </React.Fragment>
                        ),
                        target: ".leaderboard_time",
                        placement: 'right',
                    },
                    {
                        content: (
                            <React.Fragment>
                                <h2>For You</h2>
                                <p>Here you can find Kaleidoscope Live events related to your interest by date.</p>
                            </React.Fragment>
                        ),
                        target: ".upcoming_for_you",
                        placement: 'left',
                    },
                    {
                        content: (
                            <React.Fragment>
                                <h2>Event Shortcut</h2>
                                <p>You can search Kaleidoscope Live events based on each category within an experience centre.</p>
                            </React.Fragment>
                        ),
                        target: ".community_destination_box_1",
                        placement: 'right',

                    }
                ]}
                styles={{
                    buttonNext: {
                        backgroundColor: "#5458F7",
                        borderRadius: 4,
                        color: '#fff',
                    },
                    buttonBack: {
                        color: "#8D8D8D",
                        marginLeft: 'auto',
                        marginRight: 5,
                    },
                }}
            />
                : null}


        </>
    )
}

export default CommunityTabWeb;