import React, {useEffect, useMemo, useState} from 'react';
import ExpertCardPic from "../../assets/ExpertCardPic.png";
import {getOrgs, orgStatus} from '../../utils/apiUrlsSetup';
import CreateOrganizationModal from './../organization/components/create-organization-modal';
import LoggedInLayout from "../../component/layout/LoggedInLayout.tsx";
import moment from "moment/moment";
import FullWidthTable from "../../component/FullWidthTable";


const Organisation = () => {
  const [organizations, setOrganizations] = useState([]);

  const [openModal, setOpenModal] = useState(false);

  const [search, setSearch] = useState("");

  useEffect(() => {
    getAllOrgs(search);
  }, [search])

  const getAllOrgs = async (search) => {
    const response = await getOrgs(null, search);
    if (response.status === 200) {
      setOrganizations(response.data.data.organisationArray)
    }
  }

  const handleDeactivate = async (id) => {
    let objData = {}
    objData.status = "deleted"
    let responseData = await orgStatus(id, objData);
    if (responseData.status === 200) {
      getAllOrgs();
    }
  }

  const handleReactivate = async (id) => {
    let objData = {}
    objData.status = "approved"
    let responseData = await orgStatus(id, objData);
    if (responseData.status === 200) {
      getAllOrgs();
    }
  }

  const findAdmins = (members) => {
    let membersArr = members.filter(v => v.isAdmin);
    return membersArr.length ? membersArr[0].user.firstName + " " + membersArr[0].user.lastName : "Theresa Lim";
  }

  const searchResult = useMemo(() => {
    let result = organizations;

    if (search) {
      result = result
        .filter((u) => {
          return u.name
            .toLowerCase()
            .indexOf(search.toLowerCase()) >= 0
        })
    }

    return result.sort((a, b) => a.name.localeCompare(b.name))
  }, [search, organizations])

  return (
    <LoggedInLayout title={"Community"}>
      <FullWidthTable
        title={"Manage Community"}
        searchValue={search}
        searchPlaceholder={"Search for organisation"}
        searchOnChange={(e) => setSearch(e.target.value)}
        onCreateButtonTap={() => setOpenModal(true)}
        columns={[
          {
            label: "Organisation Name",
            showOnDesktop: true,
            showOnMobile: true
          },
          {
            label: "Admin Name",
            showOnDesktop: true,
            showOnMobile: false
          },
          {
            label: "Privacy",
            showOnDesktop: true,
            showOnMobile: false
          },
          {
            label: "Tags",
            showOnDesktop: true,
            showOnMobile: false
          },
          {
            label: "Date Joined",
            showOnDesktop: true,
            showOnMobile: false
          },
          {
            label: "Action",
            showOnDesktop: true,
            showOnMobile: false,
            centered: true
          },
        ]}
        data={searchResult}
        rows={[
          {
            render: (org) => {
              return (
                <>
                  <div
                    className='d-flex align-items-center gap-2 py-1'>
                    <img
                      className={"tw-object-cover rounded-circle tw-h-[30px] tw-w-[30px]"}
                      src={org.image ? org.image : ExpertCardPic} alt=''/>

                    <a className='admin_userlist_profile_name text-primary tw-text-sm'
                       href={`/organisation-profile/${org.id}`}>{org.name}</a>
                  </div>
                  <div className={"d-flex flex-column gap-1 d-lg-none"}>
                    <div className={"row tw-text-xs text-secondary w-100"}>
                      <div className="col-3">Admin Name</div>
                      <div className="col-9">{findAdmins(org.members)}</div>
                    </div>
                    <div className={"row tw-text-xs text-secondary w-100"}>
                      <div className="col-3">Privacy</div>
                      <div className="col-9">{org.privacy}</div>
                    </div>
                    <div className={"row tw-text-xs text-secondary w-100"}>
                      <div className="col-3">Tags</div>
                      <div className="col-9">Public</div>
                    </div>
                    <div className={"mb-1"}>
                      {
                        org.status === "approved" &&
                        <button className='user_deactive_btn'
                                onClick={() => handleDeactivate(org.id)}>Deactivate</button>
                      }
                      {
                        org.status === "rejected" &&
                        <button className='user_reactive_btn'
                                onClick={() => handleReactivate(org.id)}>Reactivate</button>
                      }
                      {
                        org.status === "deleted" &&
                        <button className='user_reactive_btn'
                                onClick={() => handleReactivate(org.id)}>Reactivate</button>
                      }
                    </div>
                  </div>
                </>
              )
            }
          },
          {
            render: (org) => {
              return (
                <div className={"!tw-text-opacity-50 !tw-text-[#334d6e]"}>{findAdmins(org.members)}</div>
              )
            }
          },
          {
            render: (org) => {
              return (
                <div className={"!tw-text-opacity-50 !tw-text-[#334d6e]"}>{org.privacy}</div>
              )
            }
          },
          {
            render: () => {
              return (
                <div className={"!tw-text-opacity-50 !tw-text-[#334d6e]"}>Public</div>
              )
            }
          },
          {
            render: (org) => {
              return (
                <div className={"!tw-text-opacity-50 !tw-text-[#334d6e]"}>{moment(org.createdAt).format('L')}</div>
              )
            }
          },
          {
            render: (org) => {
              return (
                <>
                  {
                    org.status === "approved" &&
                    <button className='user_deactive_btn'
                            onClick={() => handleDeactivate(org.id)}>Deactivate</button>
                  }
                  {
                    org.status === "rejected" &&
                    <button className='user_reactive_btn'
                            onClick={() => handleReactivate(org.id)}>Reactivate</button>
                  }
                  {
                    org.status === "deleted" &&
                    <button className='user_reactive_btn'
                            onClick={() => handleReactivate(org.id)}>Reactivate</button>
                  }
                </>
              )
            }
          },
        ]}
      />

      <CreateOrganizationModal
        setOpenModal={(value) => {
          setSearch("")
          setOpenModal(value);
        }}
        openModal={openModal}
      />

    </LoggedInLayout>
  )
}

export default Organisation;