import React from 'react';
import '../../../css/community_tab.css'
import { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import CommunityCoachesWeb from './communityCoaches-web';
import CommunityCoachesMobile from './communityCoaches-mobile';
import { getBids, getEventSchedule, updateEventInvitation } from '../../../utils/apiUrlsSetup';
import { updateBid } from "../../../utils/apiUrlsSetupV2.ts"

moment.locale('ko', {
    week: {
        dow: 1,
        doy: 1,
    },
});

const localizer = momentLocalizer(moment);


const CommunityTab = () => {

    const [activeTab, setActiveTab] = useState("List");

    const userDetails = JSON.parse(localStorage.getItem('userDetails'))

    const [oneToOneSessions, setOneToOneSessions] = useState([])

    const [eventData, setEventData] = useState([])

    const getSessionData = async () => {
        var response = await getBids({ userId: userDetails.id })
        if (response && response.status === 200) {
            setOneToOneSessions(response.data.data.oneToOneSessionArray)
        }
    }

    const getEventData = async () => {
        var response = await getEventSchedule()
        if (response && response.status === 200) {
            setEventData(response.data.data)
        }
    }

    useEffect(() => {
        getSessionData()
        getEventData()
    }, [])

    return (
        <>
            <CommunityCoachesWeb
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                localizer={localizer}
                Calendar={Calendar}
                sessions={oneToOneSessions}
                userDetails={userDetails}
                eventData={eventData}
                updateBid={updateBid}
                getSessionData={getSessionData}
                updateEventInvitation={updateEventInvitation}
                getEventData={getEventData}
            />

            {/* mobile */}
            <CommunityCoachesMobile
                sessions={oneToOneSessions}
                userDetails={userDetails}
                eventData={eventData}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                localizer={localizer}
                Calendar={Calendar}
                updateBid={updateBid}
                getSessionData={getSessionData}
                updateEventInvitation={updateEventInvitation}
                getEventData={getEventData}
            />

        </>
    )
}

export default CommunityTab;
