import React from 'react';
import { useNavigate } from "react-router-dom";

import '../community.css';

import Working from "../../../assets/Working.png";
import Learning from "../../../assets/Learning.png";
import Notice from "../../../assets/Notice.png";
import MeaningEmoji from "../../../assets/MeaningEmoji.png";

const Functioning = () => {

    const navigate = useNavigate();

    return (
        <>
            <div className='row m-0 mt-4'>
                <div className='col-12 p-0'>
                    <p className=' mb-0 feeling_destination_heading resp_sub_heading'>Functioning Well</p>
                </div>
            </div>

            <div className='row m-0 mt-2'>
                <div className='col-12 p-0 pe-5'>
                    <p className='mb-0 feeling_destination_pera resp_sub_info'>Meaningful work and constant discovery by honing skills and talents, exploring ideas, being present, and finding one’s purpose and truth.</p>
                </div>
            </div>

            <div className='row m-0 mt-4'>
                <div className='col p-0'>

                    <div className='row m-0'>
                        <div className='col p-0'>
                            
                            <div class="accordion feeling_destination_accor" id="accordionExample">
                                <div class="accordion-item feeling_destination_accor_item">
                                    
                                    <h2 class="accordion-header feeling_destination_accor_header" id="headingOne">
                                        <button class="accordion-button feeling_destination_accor_btn ps-3 ps-md-4 ps-lg-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <div className='d-flex align-items-center justify-content-srtat'>
                                                <div className='wbd_accordian_img d-flex align-items-center justify-content-center' >
                                                    <img src={Working} alt='' />
                                                </div>
                                                <p className='mb-0 ms-4 feeling_destination_accor_name'>Working</p>
                                            </div>
                                        </button>
                                    </h2>
                                    
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body feeling_destination_accor_body ps-4 pe-4 pt-3 pb-3">
                                            <div className='row m-0'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera'>Promoting fulfilment in your work and chosen careers through activities that bring out your best talents and skills.</p>
                                                </div>
                                            </div>
                                            {/* <div className='row m-0 mt-2'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera_2'><span className='feeling_destination_accor_pera_2_span'>Interests:</span> Work-life Balance, Time Management, Team-work, Occupational health, Mentoring</p>
                                                </div>
                                            </div> */}
                                            <div className='row m-0 mt-3'>
                                                <div className='col-12 d-flex align-items-center justify-content-center'>
                                                    <button className='feeling_destination_accor_button'onClick={() => { navigate('/communitytab/functioning') }}>Find me experiences like these</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='row m-0 mt-1'>
                        <div className='col p-0'>
                            
                            <div class="accordion feeling_destination_accor" id="accordionExample1">
                                <div class="accordion-item feeling_destination_accor_item">
                                    
                                    <h2 class="accordion-header feeling_destination_accor_header" id="headingOne1">
                                        <button class="accordion-button feeling_destination_accor_btn ps-3 ps-md-4 ps-lg-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne">
                                            <div className='d-flex align-items-center justify-content-srtat'>
                                                <div className='wbd_accordian_img d-flex align-items-center justify-content-center' >
                                                    <img src={Learning} alt='' />
                                                </div>
                                                <p className='mb-0 ms-4 feeling_destination_accor_name'>Learning</p>
                                            </div>
                                        </button>
                                    </h2>
                                    
                                    <div id="collapseOne1" class="accordion-collapse collapse" aria-labelledby="headingOne1" data-bs-parent="#accordionExample1">
                                        <div class="accordion-body feeling_destination_accor_body ps-4 pe-4 pt-3 pb-3">
                                            <div className='row m-0'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera'> Boosting your abilities and skills and opening the mind to new ideas and experiences to benefit total well-being.</p>
                                                </div>
                                            </div>
                                            {/* <div className='row m-0 mt-2'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera_2'><span className='feeling_destination_accor_pera_2_span'>Interests:</span> Work-life Balance, Time Management, Team-work, Occupational health, Mentoring</p>
                                                </div>
                                            </div> */}
                                            <div className='row m-0 mt-3'>
                                                <div className='col-12 d-flex align-items-center justify-content-center'>
                                                    <button className='feeling_destination_accor_button' onClick={() => { navigate('/communitytab/functioning') }}>Find me experiences like these</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='row m-0 mt-1'>
                        <div className='col p-0'>
                           
                            <div class="accordion feeling_destination_accor" id="accordionExample2">
                                <div class="accordion-item feeling_destination_accor_item">
                                    
                                    <h2 class="accordion-header feeling_destination_accor_header" id="headingOne2">
                                        <button class="accordion-button feeling_destination_accor_btn ps-3 ps-md-4 ps-lg-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2" aria-expanded="true" aria-controls="collapseOne">
                                            <div className='d-flex align-items-center justify-content-srtat'>
                                                <div className='wbd_accordian_img d-flex align-items-center justify-content-center' >
                                                    <img src={Notice} alt='' />
                                                </div>
                                                <p className='mb-0 ms-4 feeling_destination_accor_name'>Noticing</p>
                                            </div>
                                        </button>
                                    </h2>
                                    
                                    <div id="collapseOne2" class="accordion-collapse collapse" aria-labelledby="headingOne2" data-bs-parent="#accordionExample2">
                                        <div class="accordion-body feeling_destination_accor_body ps-4 pe-4 pt-3 pb-3">
                                            <div className='row m-0'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera'>
                                                    Noticing, or becoming aware, of your immediate surroundings to get out of auto-pilot mode and be present with your senses.
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <div className='row m-0 mt-2'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera_2'><span className='feeling_destination_accor_pera_2_span'>Interests:</span> Work-life Balance, Time Management, Team-work, Occupational health, Mentoring</p>
                                                </div>
                                            </div> */}
                                            <div className='row m-0 mt-3'>
                                                <div className='col-12 d-flex align-items-center justify-content-center'>
                                                    <button className='feeling_destination_accor_button' onClick={() => { navigate('/communitytab/functioning') }}>Find me experiences like these</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='row m-0 mt-1'>
                        <div className='col p-0'>
                            
                            <div class="accordion feeling_destination_accor" id="accordionExample3">
                                <div class="accordion-item feeling_destination_accor_item">
                                    
                                    <h2 class="accordion-header feeling_destination_accor_header" id="headingOne3">
                                        <button class="accordion-button feeling_destination_accor_btn ps-3 ps-md-4 ps-lg-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne3" aria-expanded="true" aria-controls="collapseOne">
                                            <div className='d-flex align-items-center justify-content-srtat'>
                                                <div className='wbd_accordian_img d-flex align-items-center justify-content-center' >
                                                    <img src={MeaningEmoji} alt='' />
                                                </div>
                                                <p className='mb-0 ms-4 feeling_destination_accor_name'>Meaning</p>
                                            </div>
                                        </button>
                                    </h2>
                                    
                                    <div id="collapseOne3" class="accordion-collapse collapse" aria-labelledby="headingOne3" data-bs-parent="#accordionExample3">
                                        <div class="accordion-body feeling_destination_accor_body ps-4 pe-4 pt-3 pb-3">
                                            <div className='row m-0'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera'>Exploring and finding meaning in your life's guiding beliefs, values and principles as they align with your everyday.</p>
                                                </div>
                                            </div>
                                            {/* <div className='row m-0 mt-2'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera_2'><span className='feeling_destination_accor_pera_2_span'>Interests:</span> Work-life Balance, Time Management, Team-work, Occupational health, Mentoring</p>
                                                </div>
                                            </div> */}
                                            <div className='row m-0 mt-3'>
                                                <div className='col-12 d-flex align-items-center justify-content-center'>
                                                    <button className='feeling_destination_accor_button' onClick={() => { navigate('/communitytab/functioning') }}>Find me experiences like these</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Functioning;