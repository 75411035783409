import React, {useEffect, useState} from 'react';
import SideNav from "../../../component/staticPages/SideNav";
import Like from "../../../assets/svg/Like.svg";
import Comment from "../../../assets/comment_gray.png";
import Share from "../../../assets/share_gray.png";
import Dot from "../../../assets/svg/DividerDot.svg";
import Close from "../../../assets/svg/Close.svg";
import Photo_Video from "../../../assets/svg/PhotoVideo.svg";
import NewsFeed_lg_Img from "../../../assets/NewsFeed_lg_Img.png";
import Crossbtn from '../../../assets/Crossbtn.png';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {PostClipboard} from "../../../utils/plainClipboard";
import Linkify from "react-linkify";

Quill.register("modules/clipboard", PostClipboard, true);

const NewsfeedWeb = (props) => {

  const [comment, setComment] = useState("");
  const [subComment, setSubComment] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      if (localStorage.getItem('link')) {
        localStorage.removeItem('link')
      }
    }
  }, [])

  return (
    <div>
      <div className="row m-0 d-flex newsfeeds_web"
           style={{background: 'linear-gradient(119.23deg, #E9DFFF 4.63%, #EDFAFF 53.65%)'}}>
        <div className="news_left p-0">
          <SideNav experiencesUser={true}/>
        </div>
        <div className="news_right news_right_events p-5">
          <div className="row m-0 mb-3">
            {/* <p className="event_heading newsfeed_event_heading p-0">
                            Kaleidoscope Live details
                        </p> */}
            <div className="col-12 p-0">
              <p className="mb-0 newsfeed_event_heading font_600">
                {props.eventDetails.name}
              </p>
              <p className="mb-0 mt-3 newsfeed_event_info">
                Event By{" "}
                <span className="cursor_pointer"
                      onClick={() => navigate(`/friends/${props.eventDetails.createdByUser.id}`)}>
                                    {props.eventDetails.createdByUser.firstName} {props.eventDetails.createdByUser.lastName}
                                </span>
              </p>
            </div>
          </div>

          <div className="row m-0">
            <div className="col-7 p-0">
              <div className="row m-0">
                <div className="col-12 p-0">
                  <img
                    width="98%"
                    height="350px"
                    style={{objectFit: 'contain'}}
                    src={
                      props.eventDetails.image
                        ? props.eventDetails.image
                        : NewsFeed_lg_Img
                    }
                    alt=""
                  />
                </div>
              </div>

              <div className="row m-0 mt-4">
                <div className="col-12 p-0 pe-3">
                  <p className="mb-0 about_evt">About the event</p>
                  <p className="mb-0 about_evt_info mt-3">
                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a target="blank" className={"text-primary"} href={decoratedHref} key={key}>
                        {decoratedText}
                      </a>
                    )}>{props.eventDetails.description}</Linkify>
                  </p>
                </div>
              </div>

              <div className="row m-0 mt-4">
                <div className="col-12 p-0 d-flex align-items-center justify-content-between">
                  <div className="col-3 d-flex align-items-center">
                    <div className="evt_svg_circle d-flex align-items-center justify-content-center">
                      <img
                        src={Like}
                        alt=""
                        className="cursor_pointer"
                        onClick={() => props.handleLike(props.eventDetails.id)}
                      />
                    </div>
                    <p className="mb-0 evt_svg_circle_text active_evt_svg_circle_text ps-2 cursor_pointer"
                       onClick={() => props.setShowUserLikedList(props.eventDetails)}>
                      Liked &nbsp; <img src={Dot} alt=""/> &nbsp;{" "}
                      <span>{props.eventDetails.reactionCount} Likes</span>
                    </p>
                  </div>

                  <div className="col-4 d-flex align-items-center justify-content-between">
                    <div
                      className="evt_svg_circle evt_svg_circle_gray d-flex align-items-center justify-content-center">
                      <img
                        src={Comment}
                        alt=""
                        className="cursor_pointer"
                        onClick={() => props.showCommentBox(true)}
                      />
                    </div>
                    <p className="mb-0 evt_svg_circle_text">
                      Comment &nbsp; <img src={Dot} alt=""/> &nbsp;{" "}
                      <span>{props.eventDetails.commentCount} Comments</span>
                    </p>
                  </div>

                  <div className="col-4 d-flex align-items-center">
                    <div className="d-flex align-items-center cursor_pointer"
                         onClick={() => props.setIsShowInviteFrndModal(true)}>
                      <div
                        className="evt_svg_circle evt_svg_circle_gray d-flex align-items-center justify-content-center">
                        <img src={Share} alt=""/>
                      </div>
                      <p className="mb-0 ms-2 evt_svg_circle_text">Share</p>
                    </div>
                  </div>
                </div>
              </div>

              {props.commentBox && (
                <>
                  <div className="row m-0 mt-4">

                    <div className="col-12 p-0 d-flex align-items-center">
                      <div className="input_profile_img">
                        <img alt=""
                             src={props.userDetails.profilePic ? props.userDetails.profilePic : "/static/png/Male.png"}/>
                      </div>

                      <div class="input-group  input-group-post">

                        <ReactQuill placeholder="Add a comment..." modules={{
                          toolbar: false,
                          clipboard: {
                            // toggle to add extra line breaks when pasting HTML:
                            matchVisual: false,
                          }
                        }} theme="snow" value={comment} onChange={(event) => {
                          props.setShowImageComment(true);
                          setComment(event);
                          props.setComment(event);
                        }}/>

                        <span
                          className="input-group-text comment_input_capture d-flex align-items-center justify-content-around"
                          id="basic-addon1"
                        >

                                                    <input type='file' id="file-input" className="d-none" multiple
                                                           onChange={(e) => {
                                                             props.setShowImageComment(true)
                                                             props.saveCommentImage(e.target.files[0]);
                                                             if (e.target.files[0]) {
                                                               const file = URL.createObjectURL(e.target.files[0]);
                                                               props.setImageUrl(file);
                                                             }
                                                             e.target.value = null;
                                                           }}/>
                                                    <label for="file-input">
                                                        <img className="ms-3" src={Photo_Video}/>
                                                    </label>
                                                </span>
                      </div>
                    </div>

                    {props.showImageComment ? <div className="d-flex flex-column mt-3 image_preview_box p-0">

                      {props.imageUrl ? <div className="position-relative" style={{width: '300px'}}>
                        <img src={props.imageUrl} alt="" style={{width: '300px'}}/>
                        <div className="remove_image" onClick={() => {
                          props.setImageUrl("");
                          props.saveCommentImage(null);
                        }}><img src={Crossbtn} alt=""/></div>
                      </div> : null}

                      {props.comment.length ?
                        <button class="mt-3 book_an_slot" data-bs-toggle="modal" data-bs-target="#slotBookModal"
                                onClick={() => {
                                  if (props.imageUrl) {
                                    setComment("");
                                    props.submitComment("image", props.eventDetails, props.commentImage)
                                  } else {
                                    setComment("");
                                    props.submitComment("text", props.eventDetails, props.comment)
                                  }
                                }}>Post</button> : null}


                    </div> : null}
                  </div>
                </>
              )}

              {/* <div className="row m-0 mt-4">
                                <div className="col-12 p-0 d-flex align-items-center">
                                    <p className="mb-0 evt_comment_heading">Comments</p>
                                    <p className="mb-0 evt_comment_info ms-4">
                                        {props.comments.length} comments
                                    </p>
                                </div>
                            </div> */}

              {(props.comments || []).map((v, i) => {
                return (
                  <div
                    key={i}
                    className="row m-0 pt-3 pb-3 evt_bottom_border d-flex"
                  >
                    <div className="EventProfilePic_section p-0">
                      {/* <div className="EventProfilePic">
                                                <img config={EventProfilePic} alt="" />
                                            </div> */}
                      <div className='EventProfilePic'>
                        <img className='EventProfilePic_img'
                             src={v.user.profilePic ? v.user.profilePic : "/static/png/Male.png"} alt=''/>
                      </div>
                    </div>
                    <div className="EventComments_section p-0 px-2">
                      <p className='mb-0 user_commented'><b>{v.user.firstName} {v.user.lastName}</b></p>
                      <p className='mb-0 user_commented' dangerouslySetInnerHTML={{__html: v.comment}}></p>
                      {v.attachment ?
                        <img width="300px" height="150px" className="my-2" src={v.attachment} alt=""/> : null}
                      <p className="mb-0 mt-2 user_commented_like_cmnt">
                                                <span
                                                  className="cursor_pointer"
                                                  onClick={() => props.handleLike(v.eventId, v.id)}
                                                >
                                                    {v.reactionCount} Likes
                                                </span>{" "}
                        &bull; <span className="cursor_pointer" onClick={() => {
                        props.setShowRepliedCommentInput(true);
                        props.setShowRepliedCommentId(v.id);
                      }}>{v.repliesCount} Replies</span>
                      </p>
                    </div>

                    {props.showRepliedCommentId === v.id && props.showRepliedCommentInput && <>
                      <div className='row m-0 mt-3'>
                        <div className='col-12 p-0 ps-4 d-flex align-items-center justify-content-end'>

                          <div className='input_profile_img'>
                            {/* <img config={DP} /> */}
                            <img
                              src={props.userDetails.profilePic ? props.userDetails.profilePic : "/static/png/Male.png"}
                              alt=""/>
                          </div>

                          <div class="input-group-post" style={{width: '90%'}}>
                            {/* <input type='text' className='comment_input ms-3 ps-4 pb-1'
                                                            placeholder='Add a comment...' value={props.subComment}
                                                            onChange={(event) => {
                                                                props.setSubComment(event.target.value);
                                                            }}
                                                            onKeyDown={(event) => {
                                                                if (event.key === "Enter") {
                                                                    props.submitComment(props.eventDetails, event.target.value, v.id);
                                                                }
                                                            }} />

                                                        <span
                                                            className="input-group-text comment_input_capture d-flex align-items-center justify-content-around"
                                                            id="basic-addon1"
                                                        >

                                                            <input type='file' id="file-input" className="d-none" multiple onChange={(e) => props.saveCommentImage(e.target.files[0])} />
                                                            <label for="file-input">
                                                                <img className="ms-3" config={Photo_Video} />
                                                            </label>
                                                        </span> */}

                            <ReactQuill placeholder="Add a comment..." modules={{
                              toolbar: false,
                              clipboard: {
                                // toggle to add extra line breaks when pasting HTML:
                                matchVisual: false,
                              }
                            }} theme="snow" value={subComment} onChange={(event) => {
                              props.setShowImageComment(true);
                              setSubComment(event);
                              props.setSubComment(event);
                            }}/>

                            <span
                              className="input-group-text comment_input_capture d-flex align-items-center justify-content-around"
                              id="basic-addon1"
                            >

                                                            <input type='file' id="file-input" className="d-none"
                                                                   multiple onChange={(e) => {
                                                              props.setShowImageComment(true)
                                                              props.saveCommentImage(e.target.files[0]);
                                                              if (e.target.files[0]) {
                                                                const file = URL.createObjectURL(e.target.files[0]);
                                                                props.setImageUrl(file);
                                                              }
                                                              e.target.value = null;
                                                            }}/>
                                                            <label for="file-input">
                                                                <img className="ms-3" src={Photo_Video}/>
                                                            </label>
                                                        </span>
                          </div>


                        </div>
                        {<div className="d-flex flex-column mt-3 image_preview_box">

                          {props.imageUrl ? <div className="position-relative" style={{width: '300px'}}>
                            <img src={props.imageUrl} alt="" style={{width: '300px'}}/>
                            <div className="remove_image" onClick={() => {
                              props.setImageUrl("");
                              props.saveImageData(null);
                            }}><img src={Crossbtn} alt=""/></div>
                          </div> : null}

                          {props.subComment.length ?
                            <button class="mt-3 book_an_slot" data-bs-toggle="modal" data-bs-target="#slotBookModal"
                                    onClick={() => {
                                      if (props.imageUrl) {
                                        setSubComment("");
                                        props.submitComment("image", props.eventDetails, props.commentImage, v.id)
                                      } else {
                                        setSubComment("");
                                        props.submitComment("text", props.eventDetails, props.subComment, v.id)
                                      }
                                    }}>Post</button> : null}


                        </div>}

                      </div>
                      <div className="d-flex justify-content-end align-items-end flex-column">
                        {v.replies.map((v, i) => {
                          return <div className="d-flex align-items-center" style={{width: '94%'}} key={i}>
                            <div className='EventProfilePic_section py-3 p-0'>

                              <div className='EventProfilePic'>
                                <img className='EventProfilePic_img'
                                     src={v.user.profilePic ? v.user.profilePic : "/static/png/Male.png"} alt=""/>
                              </div>

                            </div>

                            <div className='EventComments_section py-3 p-0 px-2'>
                              <p className='mb-0 user_commented'><b>{v.user.firstName} {v.user.lastName}</b></p>
                              <p className='mb-0 user_commented' dangerouslySetInnerHTML={{__html: v.comment}}></p>
                              {v.attachment ?
                                <img width="300px" height="150px" className="my-2" src={v.attachment} alt=""/> : null}
                              {/* <p className='mb-0 mt-2 user_commented_like_cmnt'><span className="cursor_pointer">{curr.reactionCount} Likes</span> &bull; <span className="cursor_pointer" onClick={() => {
                                                    setShowRepliedCommentInput(true);
                                                    setShowRepliedCommentId(curr.id);
                                                }}> {curr.repliesCount} Replies</span> </p> */}
                            </div>
                          </div>
                        })}
                      </div>
                    </>}
                  </div>
                );
              })}
            </div>

            <div className="col-3 p-0 ps-2">

              {props.adminDataEvent ? <>

                <button className="take_down_btn" onClick={() => props.setShowEventDelete(true)}>Take-down Event
                </button>
                <p className="evt_total_count take_down_text my-3">
                  Take-down event will lead to <br/> deletion of a event.
                </p>

              </> : null}


              <p className="mb-0 evt_date_time">Date and Time</p>

              {moment(props.eventDetails.startDate).format("D MM") === moment(props.eventDetails.endDate).format("D MM") ? <>


                <p className="mb-0 evt_date mt-2">
                  {moment(props.eventDetails.startDate).utc().format("D MMM YYYY")},{" "}
                  {moment(props.eventDetails.startDate).utc().format("dddd")}
                </p>
                <p className="mb-0 evt_time mt-1">
                  {moment(props.eventDetails.startDate).utc().format("hh:mm a")}
                  &nbsp;-&nbsp;
                  {moment(props.eventDetails.endDate).utc().format("hh:mm a")}
                </p>

              </> : <>

                <p className="mb-0 evt_date mt-2">
                  {moment(props.eventDetails.startDate).utc().format("D MMM YYYY")},{" "}
                  {moment(props.eventDetails.startDate).utc().format("dddd")}
                </p>
                <p className="mb-0 evt_time mt-1">To</p>
                {/* <p className='mb-0 evt_time mt-1'>8:00 PM - 9:30PM</p> */}
                <p className="mb-0 evt_time mt-1">
                  {moment(props.eventDetails.endDate).utc().format("D MMM YYYY")},{" "}
                  {moment(props.eventDetails.endDate).utc().format("dddd")}
                </p>
                <p className="mb-0 evt_time mt-1">
                  {moment(props.eventDetails.startDate).utc().format("hh:mm a")}
                  &nbsp;-&nbsp;
                  {moment(props.eventDetails.endDate).utc().format("hh:mm a")}
                </p>

              </>}


              <p className="mb-0 evt_type mt-4">Free</p>

              {props.userDetails.id !== props.eventDetails.createdByUserId && !props.adminDataEvent && props.eventDetails.participants && !props.eventDetails.participants.map(v => v.userId).includes(props.userDetails.id) &&
                <button className="evt_reg_btn mt-4" onClick={props.handleShow}>
                  Register
                </button>}

              {props.userDetails.id !== props.eventDetails.createdByUserId && !props.adminDataEvent && props.eventDetails.participants && props.eventDetails.participants.map(v => v.userId).includes(props.userDetails.id) &&
                <button className="evt_reg_btn mt-4"
                        onClick={() => window.open(`${props.eventDetails.link}`)}>
                  Attend Event
                </button>}
              <p className="mb-0 evt_total_count mt-4">
                {props.eventDetails.participants && props.eventDetails.participants.length} people have registered,
                <br/> <a href="#" style={{color: '#410db7'}}
                         onClick={() => props.setParticipantsList(props.eventDetails.participants)}>click here</a> to
                see details
              </p>

              <span
                class="input-group-text post_avatar_img mt-2"
                id="basic-addon2"
              >
                                {(props.eventDetails.participants && props.eventDetails.participants.slice(0, 3).map((v, i) => {
                                  return <span class="avatar_post" key={i}>
                                        <img class="avatar_post_img"
                                             src={v.user.profilePic ? v.user.profilePic : "/static/png/Male.png"}
                                             alt=""/>
                                    </span>
                                }))}
                {/* <span class="avatar_post">
                                    <img class="avatar_post_img" config={Ellipse4} alt="" />
                                </span>
                                <span class="avatar_post">
                                    <img class="avatar_post_img" config={Ellipse5} alt="" />
                                </span>
                                <span class="avatar_post">
                                    <img class="avatar_post_img" config={Ellipse6} alt="" />
                                </span> */}
                            </span>

              {/* <!--Modal-1--> */}


              <div
                class={`modal fade ${props.show ? "show" : ""}`}
                style={{
                  display: props.show ? "block" : "none",
                  background: props.show ? "rgba(0,0,0,0.5)" : "",
                }}
                id="exampleModalRegBtn"
                tabIndex="-1"
                aria-labelledby="exampleModalLabelReg"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div className="row m-0 register_event_modal">
                      <div className="col-12 py-3">
                        <div className="row m-0">
                          <p className="text-center confirm_text mb-1">
                            Event registration
                          </p>
                        </div>

                        <div className="row m-0 mt-3">
                          <div className="col-4 p-0">
                            <img
                              className=""
                              width={"145px"}
                              height="90px"
                              src={
                                props.eventDetails.image
                                  ? props.eventDetails.image
                                  : props.NewsFeedlgImgModel
                              }
                              alt=""
                            />
                          </div>
                          <div className="col-8 p-0">
                            <div className="row m-0">
                              <div className="col-12 p-0">
                                <p className="mb-0 title_text">
                                  {props.eventDetails.name}
                                </p>

                                {moment(props.eventDetails.startDate).format("D MM") === moment(props.eventDetails.endDate).format("D MM") ? <>


                                  <p className="mb-0 evt_reg_model_time_text mt-2">
                                    {moment(props.eventDetails.startDate).utc().format("D MMM YYYY")},{" "}
                                    {moment(props.eventDetails.startDate).utc().format("dddd")}
                                  </p>
                                  <p className="mb-0 evt_reg_model_time_text mt-1">
                                    {moment(props.eventDetails.startDate).utc().format("hh:mm a")}
                                    &nbsp;-&nbsp;
                                    {moment(props.eventDetails.endDate).utc().format("hh:mm a")}
                                  </p>

                                </> : <>

                                  <p className="mb-0 evt_reg_model_time_text mt-2">
                                    {moment(props.eventDetails.startDate).utc().format("D MMM YYYY")},{" "}
                                    {moment(props.eventDetails.startDate).utc().format("dddd")}
                                  </p>
                                  <p className="mb-0 evt_reg_model_time_text mt-1">To</p>
                                  {/* <p className='mb-0 evt_time mt-1'>8:00 PM - 9:30PM</p> */}
                                  <p className="mb-0 evt_reg_model_time_text mt-1">
                                    {moment(props.eventDetails.endDate).utc().format("D MMM YYYY")},{" "}
                                    {moment(props.eventDetails.endDate).utc().format("dddd")}
                                  </p>
                                  <p className="mb-0 evt_reg_model_time_text mt-1">
                                    {moment(props.eventDetails.startDate).utc().format("hh:mm a")}
                                    &nbsp;-&nbsp;
                                    {moment(props.eventDetails.endDate).utc().format("hh:mm a")}
                                  </p>

                                </>}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row m-0">
                          <div className="col-12 p-0 px-4 py-4 d-flex align-items-center justify-content-start">
                            <p className=" mb-0 event_det_text">
                              You are registering for this event and will be listed as a participant.
                            </p>
                          </div>
                        </div>

                        <div className="row m-0 justify-content-center align-items-center">
                          <div
                            className="event_cancel_btn me-4"
                            onClick={() => props.setShow(false)}
                          >
                            Cancel
                          </div>
                          <div
                            className="event_register_done_btn"
                            onClick={props.handleShowRegister}
                          >
                            Yes
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>


              {/* <!--Modal-2--> */}
              <div
                class={`modal fade ${props.showRegister ? "show" : ""}`}
                style={{
                  display: props.showRegister ? "block" : "none",
                  background: props.showRegister ? "rgba(0,0,0,0.5)" : "",
                }}
                id="exampleModalRegisterBtn"
                tabIndex="-1"
                aria-labelledby="exampleModalLabelReg2"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div className="row m-0">
                      <div className="col-12 ps-5 pe-5 pt-5">
                        <div className="row m-0">
                          <div className="col-10 p-0 d-flex align-items-center justify-content-start">
                            <p className=" mb-0 evt_reg_model_info_text">
                              Registration for
                            </p>
                          </div>
                          <div className="col-2 p-0 d-flex align-items-center justify-content-end">
                            <img
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalCloseBtn"
                              onClick={props.handleShow1}
                              className=""
                              src={Close}
                              alt=""
                            />
                          </div>
                        </div>

                        <div className="row m-0">
                          <div className="col-12 p-0">
                            <p className="mb-0 mt-4 evt_reg_model_bold_text">
                              {props.eventDetails.name}
                            </p>
                            {moment(props.eventDetails.startDate).format("D MM") === moment(props.eventDetails.endDate).format("D MM") ? <>


                              <p className="mb-0 evt_reg_model_time_text mt-2">
                                {moment(props.eventDetails.startDate).utc().format("D MMM YYYY")},{" "}
                                {moment(props.eventDetails.startDate).utc().format("dddd")}
                              </p>
                              <p className="mb-0 evt_reg_model_time_text mt-1">
                                {moment(props.eventDetails.startDate).utc().format("hh:mm a")}
                                &nbsp;-&nbsp;
                                {moment(props.eventDetails.endDate).utc().format("hh:mm a")}
                              </p>

                            </> : <>

                              <p className="mb-0 evt_reg_model_time_text mt-2">
                                {moment(props.eventDetails.startDate).utc().format("D MMM YYYY")},{" "}
                                {moment(props.eventDetails.startDate).utc().format("dddd")}
                              </p>
                              <p className="mb-0 evt_reg_model_time_text mt-1">To</p>
                              {/* <p className='mb-0 evt_time mt-1'>8:00 PM - 9:30PM</p> */}
                              <p className="mb-0 evt_reg_model_time_text mt-1">
                                {moment(props.eventDetails.endDate).utc().format("D MMM YYYY")},{" "}
                                {moment(props.eventDetails.endDate).utc().format("dddd")}
                              </p>
                              <p className="mb-0 evt_reg_model_time_text mt-1">
                                {moment(props.eventDetails.startDate).utc().format("hh:mm a")}
                                &nbsp;-&nbsp;
                                {moment(props.eventDetails.endDate).utc().format("hh:mm a")}
                              </p>

                            </>}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row m-0 mt-3">
                      <div className="col-12 p-0">
                        <img
                          className=""
                          width={"100%"}
                          src={
                            props.eventDetails.image
                              ? props.eventDetails.image
                              : props.NewsFeedlgImgModel
                          }
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="row m-0 mt-2">
                      <div className="col-12 p-0 ps-5 pe-5 pt-4 pb-5">
                        <p className="mb-0 model_reg_done_messege">
                          Thank you <b>{props.userDetails.firstName} {props.userDetails.lastName}</b> for registering
                          for the
                          {" "}{props.eventDetails.name}
                        </p>
                        <p className="mb-0 mt-2 model_reg_done_info_messege">
                          A confirmation has been sent to your
                          email {props.userDetails.email}.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!--Modal-3--> */}

              <div
                class={`modal fade ${props.show1 ? "show" : ""}`}
                style={{
                  display: props.show1 ? "block" : "none",
                  background: props.show1 ? "rgba(0,0,0,0.5)" : "",
                }}
                id="exampleModalCloseBtn"
                tabIndex="-1"
                aria-labelledby="exampleModalLabelReg3"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered modal-sm">
                  <div class="modal-content">
                    <div className="row m-0">
                      <div className="col-12 p-0 ps-5 pe-5 pt-4 pb-4">
                        <div className="row m-0">
                          <div className="col-12 p-0">
                            <p className="mb-0 model_promt_text">
                              Are you sure you want to go back? Registration is
                              not complete.
                            </p>
                          </div>
                        </div>

                        <div className="row m-0 mt-3">
                          <div className="col-6 p-0 d-flex justify-content-start align-items-center">
                            <p
                              className="mb-0 model_cancel"
                              onClick={props.handleBackModal}
                            >
                              Cancel
                            </p>
                          </div>
                          <div className="col-6 p-0 d-flex justify-content-end align-items-center">
                            <p
                              className="mb-0 model_close_yes"
                              onClick={props.handleCloseModal}
                            >
                              Yes
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class={`modal fade ${props.showEventDelete ? "show" : ""}`}
                style={{
                  display: props.showEventDelete ? "block" : "none",
                  background: props.showEventDelete ? "rgba(0,0,0,0.5)" : "",
                }}
                id="exampleModalCloseBtn"
                tabIndex="-1"
                aria-labelledby="exampleModalLabelReg3"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered modal-md">
                  <div class="modal-content">
                    <div className="row m-0">
                      <div className="col-12 p-0 ps-5 pe-5 pt-4 pb-4">
                        <div className="row m-0">
                          <div className="col-12 p-0">
                            <h4 className="mb-0 text-center">
                              Are you sure?
                            </h4>
                          </div>
                        </div>
                        <div className="row m-0 my-4">
                          <div className="col-12 p-0">
                            <p className="mb-0 model_promt_text text-center">
                              Your action will delete all of this event <br/>
                              detail and cannot be undone.
                            </p>
                          </div>
                        </div>

                        <div className="row m-0 mt-3">
                          <div className="col-2 p-0"></div>
                          <div className="col-4 p-0 d-flex justify-content-start align-items-center pe-2">
                            <button className="take_down_btn take_down_btn_outline"
                                    onClick={() => props.setShowEventDelete(false)}>Cancel
                            </button>
                          </div>
                          <div className="col-4 p-0 d-flex justify-content-end align-items-center ps-2">
                            <button className="take_down_btn"
                                    onClick={() => props.deleteEvent(props.eventDetails.id)}>Yes
                            </button>
                          </div>
                          <div className="col-2 p-0"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


      {props.isShowInviteFrndModal &&
        <props.InviteFriendModal setIsShowInviteFrndModal={() => props.setIsShowInviteFrndModal(false)}
                                 isShowInviteFrndModal={props.isShowInviteFrndModal} friendsList={props.friendsList}
                                 eventData={{event: props.eventDetails}}/>}

    </div>
  )
}

export default NewsfeedWeb;
