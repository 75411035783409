import dwnldArrow from "../../../../assets/dwnld_arrow.png";
import view_icon from "../../../../assets/view_icon.png";
import React from "react";
import {generateUUID} from "pubnub";

/**
 *
 * @param {string[]} images
 * @returns {Element}
 * @constructor
 */
export default function ImagePostCarousel({images}) {
    const carousel_id = generateUUID()

    return (
        <div id={carousel_id} className="carousel slide d-flex justify-content-center bg-dark"
             data-bs-ride="carousel">
            <div className="carousel-inner">
                {images.map((v, i) => {
                    return <div
                        className={`carousel-item ${i === 0 ? "active" : ""} position-relative`}
                        key={i}>
                        <img src={v} alt={"image"} className="w-100 d-block object-fit-cover tw-object-center lg:tw-h-[400px] tw-h-[min(400px,30vh)]"/>

                        <div
                            onClick={() => window.open(v)}
                            className="tw-cursor-pointer position-absolute bottom-0 start-0 w-100 py-2 tw-bg-[rgba(0,0,0,0.3)] container gap-3 d-flex justify-content-end align-items-center">
                            <img src={dwnldArrow} alt="Download Arrow"
                                 className="tw-cursor-pointer !tw-w-[10px] z-3"/>
                            <img src={view_icon} alt="View Icon"
                                 className="tw-cursor-pointer !tw-w-[10px] z-3"/>
                        </div>
                    </div>
                })}
            </div>

            <button className="carousel-control-prev" type="button"
                    data-bs-target={"#" + carousel_id} data-bs-slide="prev">
                <span className="carousel-control-prev-icon"></span>
            </button>
            <button className="carousel-control-next" type="button"
                    data-bs-target={"#" + carousel_id} data-bs-slide="next">
                <span className="carousel-control-next-icon"></span>
            </button>
        </div>
    )
}