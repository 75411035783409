import React from 'react'
import './editProfile.css';

const EditProfileModal = (props) => {

    return (
        <>
            <div className={`modal fade ${props.isOpen ? 'show' : ''}`} style={{ display: props.isOpen ? 'block' : 'none', background: props.isOpen ? 'rgba(0,0,0,0.5)' : '' }} id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">

                    <div className="modal-content model_border_radius">

                        <div className="modal-body p-4 d-flex flex-column align-items-center justify-content-center">
                            <p className="model_bold_text text-center">Save changes?</p>
                            <p className="model_sm_text text-center mt-2">Your profile will be updated according to<br /> the changes you made.</p>
                            <div className='row m-0'>
                                <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                    <button className="model_login_button_cancle mt-2 me-3" onClick={()=>props.setIsOpen(false)}>Cancel</button>
                                    <button className="model_login_button mt-2 ms-3" onClick={
                                        props.modalType === "changepassword" ? props.handlepassword : props.handleUpdateProfile}>Ok</button>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default EditProfileModal;
