import React, {useState} from 'react';
import {report} from '../../../utils/apiUrlsSetup'
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Swal from "sweetalert2";


const ExchangeReportModal = (props) => {
  const [reasonId, setReasonId] = useState(1)
  const reasons = [
    {id: 1, reason: 'Wonderful exchange!'},
    {id: 2, reason: 'My well-being feels supported'},
    {id: 3, reason: 'Content/Duration matters'},
    {id: 4, reason: 'Feedback on community member'},
    {id: 5, reason: 'Care & Kindness concerns'},
    {id: 6, reason: 'Others'}
  ]

  const [reason, setReason] = useState("");

  const handleSubmit = async () => {
    const response = await report({
      offerId: props.offerId,
      sessionId: props.bidId,
      reason: reasonId === 6 ? reason : reasons.find(reason => reason.id === reasonId).reason
    })
    if (response.status === 200) {
      props.setExchangeReportModal(false)
      Swal.fire({
        icon: 'success',
        title: `Thanks for telling us!.`,
        text: "We will evaluate the experience and will tell you the update of this report"
      })
    }
  }

  return (
    <Modal isOpen={props.exchangeReportModal} toggle={props.handleClose} size={"lg"}>
      <ModalHeader toggle={props.toggle} className={"align-items-start border-0 pb-0"}>
        <p className="mb-0 model_bold_text text-center">Feedback</p>
      </ModalHeader>
      <ModalBody>
        {
          reasons.map(reason => {
            return (
              <div className="form-check mt-2" key={reason.id}>
                <input onClick={() => setReasonId(reason.id)} className="form-check-input exe_modal_check_input"
                       type="radio" name={`option-${reason.id}`} id={`option-${reason.id}`}/>
                <label className="form-check-label exe_modal_labal" htmlFor={`option-${reason.id}`}>
                  {reason.reason}
                </label>
              </div>
            )
          })
        }

        {reasonId === 6 ? <><input type="text" onChange={(e) => setReason(e.target.value)} className="w-75 mt-2 px-2"
                                   style={{
                                     border: '1px solid #5458f7',
                                     borderRadius: '8px',
                                     height: '36px'
                                   }}/></> : null}

      </ModalBody>
      <ModalFooter>
        <div className='p-0 d-flex align-items-center justify-content-center'>
          <button className="model_exe_cancle_btn mt-2 me-4" onClick={props.handleClose}>Cancel</button>
          <button className="model_exe_submit_btn model_login_button mt-2 ms-4" onClick={handleSubmit}>Submit</button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ExchangeReportModal
