import {useState} from "react";
// @ts-expect-error still combined
import RescheduleModal from "./RescheduleModal.tsx";
import Swal from "sweetalert2";
// @ts-expect-error still combined
import {updateBid} from "../../../utils/apiUrlsSetupV2.ts";
import {BidObject, OfferObject, User} from "../../../utils/ApiUrlsSetupTypes";

export default function RequestCard(props: {
  bid: BidObject,
  view: "OWNER" | "BIDDER" | "GUEST",
  offer: OfferObject
}) {
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);

  async function confirmCancellation() {
    Swal.fire({
      title: "Are you sure?",
      text: "You will cancel this gift request.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel!",
    }).then(async e => {
      if (e.isConfirmed) {
        const response = await updateBid({status: "cancelled", oneToOneSessionId: props.bid.id})
        if (response.status === 200) {
          await Swal.fire("Success", "Gift request canceled", "success")
          window.location.reload()
        }
      }
    })
  }

  async function confirmConfirm() {
    Swal.fire({
      title: props.offer.offerType === "gift" ? "Confirm Gift Request?" : "Accept Request?",
      html: "By confirming, you're good to exchange time <br /> with the requester at the pre-selected time for <br /> the allocated duration of hours.",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then(async e => {
      if (e.isConfirmed) {
        const response = await updateBid({status: "confirmed", oneToOneSessionId: props.bid.id})
        if (response.status === 200) {
          await Swal.fire("Success", "Gift request confirmed", "success")
          window.location.reload()
        }
      }
    })
  }

  let focusedUser: User | null = null;
  if (props.offer.offerType === "gift") {
    focusedUser = props.bid.user;
  } else {
    focusedUser = props.bid.instructor
  }

  return (
    <div className={"tw-rounded border-0 card card-body"}>
      <div className={"tw-flex tw-gap-2"}>
        <div className={"tw-w-[50px] tw-shrink-0 tw-h-[50px] tw-rounded-full"}>
          <img src={focusedUser.profilePic ?? "/static/png/Male.png"} alt="" className={"tw-rounded-full"} />
        </div>
        <div className={"tw-w-full"}>
          <div className={"tw-flex tw-flex-col tw-leading-5"}>
            <div className="tw-flex tw-gap-3 tw-items-center">
              <div className={"tw-font-semibold"}>
                {focusedUser.firstName} {focusedUser.lastName}
              </div>
              <div
                data-status={props.bid.status}
                className={`
                tw-px-5 tw-text-sm tw-pb-0.5
                data-[status='confirmed']:tw-bg-green-500
                data-[status='pending']:tw-bg-orange-400
                data-[status='cancelled']:tw-bg-gray-400
                data-[status='rejected']:tw-bg-red-400
                rounded-pill tw-text-white tw-font-semibold`}>
                {props.bid.status.split(" ").map(e => e[0].toUpperCase() + e.slice(1)).join(" ")}
              </div>
            </div>
            <div>
              {
                Intl.DateTimeFormat("En-SG", {
                  weekday: "long",
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                }).format(new Date(props.bid.startDate))
              }
              {" - "}
              {
                Intl.DateTimeFormat("En-SG", {
                  hour: "2-digit",
                  minute: "2-digit",
                }).format(new Date(props.bid.endDate))
              }
            </div>
          </div>

          {(props.view === "BIDDER" && props.bid.status === "pending") && (
            <div className="tw-flex tw-items-center tw-gap-3 tw-pt-3 tw-w-full">
              <>
                <button type={"button"}
                        disabled
                        className={"tw-font-medium tw-h-[40px] tw-flex tw-items-center tw-justify-center tw-rounded-full bg-secondary-subtle tw-w-full"}>
                  Waiting for Confirmation...
                </button>

                <div className="dropdown">
                  <button className={"tw-w-[40px] tw-h-[40px] tw-rounded-full tw-border-2 tw-bg-white"}
                          data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bi bi-three-dots"/>
                  </button>

                  <ul className="dropdown-menu">
                    <li
                      className="dropdown-item montserrat tw-cursor-pointer tw-font-medium tw-text-sm py-2 tw-min-w-[200px]"
                      onClick={() => setShowRescheduleModal(true)}>
                      <i className="bi bi-pen me-2"/> Request Reschedule
                    </li>

                    <li
                      className="dropdown-item montserrat text-danger tw-cursor-pointer tw-font-medium tw-text-sm py-2 tw-min-w-[200px]"
                      onClick={confirmCancellation}>
                      <i className="bi bi-escape me-2"/> Cancel Exchange
                    </li>
                  </ul>
                </div>
              </>
            </div>
          )}


          {(props.view === "OWNER" && props.bid.status === "pending") && (
            <div className="tw-flex tw-items-center tw-gap-3 tw-pt-3 tw-w-full">
              <>
                <button type={"button"}
                        className={`tw-font-medium tw-h-[40px] tw-flex tw-items-center tw-justify-center tw-rounded-full
                        tw-bg-[#28a745]
                        tw-text-white
                        tw-w-full`}
                        onClick={confirmConfirm}>
                  Confirm
                </button>

                <div className="dropdown">
                  <button className={"tw-w-[40px] tw-h-[40px] tw-rounded-full tw-border-2 tw-bg-white"}
                          data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bi bi-three-dots"/>
                  </button>

                  <ul className="dropdown-menu">
                    <li
                      className="dropdown-item montserrat tw-cursor-pointer tw-font-medium tw-text-sm py-2 tw-min-w-[200px]"
                      onClick={() => setShowRescheduleModal(true)}>
                      <i className="bi bi-pen me-2"/> Request Reschedule
                    </li>

                    <li
                      className="dropdown-item montserrat text-danger tw-cursor-pointer tw-font-medium tw-text-sm py-2 tw-min-w-[200px]"
                      onClick={confirmCancellation}>
                      <i className="bi bi-escape me-2"/> Cancel Exchange
                    </li>
                  </ul>
                </div>
              </>
            </div>
          )}
        </div>
      </div>

      <RescheduleModal
        offer={props.offer}
        exchangeModalOpen={showRescheduleModal}
        handleExchangeModal={setShowRescheduleModal}
        myBid={props.bid}
      />
    </div>
  )
}

