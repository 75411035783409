import Linkify from "react-linkify";
import {convertHashtagsToLinks} from "../utils/hashtags";
import {Link} from "react-router-dom";
import React from "react";

const LIMIT = 50

function extractYouTubeID(longString) {
  // Regular expression to match the YouTube video ID
  const youtubeRegex = /(?:v=|\/)([0-9A-Za-z_-]{11})(?:\?|&|\/|$)/;

  // Search for the pattern in the long string
  const match = longString.match(youtubeRegex);

  // If a match is found, return the first group (video ID)
  return match ? match[1] : null;
}

export default function PostText({content, defaultExpanded = false}) {
  const [isExpanded, setIsExpanded] = React.useState((content.split(" ").length <= LIMIT) || defaultExpanded);

  let text = content.trim();
  if (!isExpanded) {
    text = content.split(" ").slice(0, LIMIT).join(" ");
  }

  if (text.split(" ").length === 1) {
    if (text.startsWith("#")) {
      return (
        <Link key={content} to={`/newsfeeds/tags/${content}`} className={"text-primary"}>
          {content}
        </Link>
      )
    }
    return text;
  }

  const yt = extractYouTubeID(text)

  return (
    <>
      <div>
        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => {
          return (
            <a target="_blank" className={"text-primary"} href={decoratedHref} key={key} rel="noreferrer">
              {decoratedText}
            </a>
          );
        }}>
          {
            convertHashtagsToLinks(text.trim(), (tag) => (
              <Link key={tag} to={`/newsfeeds/tags/${tag}`} className={"text-primary"}>
                #{tag}
              </Link>
            ))
          }
        </Linkify>
        {!isExpanded && (
          <span className={"text-secondary tw-text-sm tw-cursor-pointer"}
                onClick={() => setIsExpanded(prev => !prev)}> ...more</span>
        )}
      </div>
      {yt && (
        <div className={"mt-2"}>
          <iframe className={"tw-w-full tw-aspect-video"} src={`https://www.youtube.com/embed/${yt}`}
                  title="How to Improve Yourself Right NOW (and Why) - Prof. Jordan Peterson" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
        </div>
      )}
    </>
  )
}