import React from 'react'
import TimeExchangePocket from "../../../assets/svg/TimeExchangePocket.svg";
import TimeExchangeEmoji from "../../../assets/svg/TimeExchangeEmoji.svg";

/**
 * @deprecated Please use Points.tsx instead
 */
const Points = (props) => (
    <div className='col-4 mr-0 p-0 d-flex align-items-end justify-content-end'>
        <p className='mb-0 time_exchange_right_head text-right'><img className='mb-1' src={TimeExchangePocket}
                                                                     alt=' '/> {props.point.wbTime}H &nbsp; <span
            className='deivder_time_ex'>|</span> &nbsp; <img className='mb-1' src={TimeExchangeEmoji}
                                                             alt=' '/> {props.point.wbPoints} WBX</p>
    </div>
)

export default Points