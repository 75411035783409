import React from "react";

/**
 * @param {{
 *     url: string,
 *     count?: number
 * }} props
 * @returns {Element}
 * @constructor
 */
export default function DocumentPostView(props) {

  const getFileType = (url) => {
    let splitUrl = url.split(".");
    let findType = splitUrl[splitUrl.length - 1];
    return findType.toUpperCase();
  }

  const getFileName = (url) => {
    let splitUrl = url.split("/");
    return splitUrl[splitUrl.length - 1];
  }

  return (
    <a href={props.url} target={"_blank"} rel={"noreferrer"}>
      <div className="rounded d-flex gap-2 p-2 bg-logged-in position-relative">
        <div className="tw-bg-[#ddceff] text-white ratio ratio-1x1 tw-max-w-[100px] rounded-1">
          <i className={`bi bi-paperclip tw-text-7xl m-0 d-flex justify-content-center align-items-center`} />
        </div>

        <div className={"tw-text-sm text-secondary"}>
          <p className="mb-0 file_name lh-base tw-font-medium">{getFileName(props.url)}</p>
          {props.count &&
            <span className="pages_count">{props.count} pages</span>
          }
          <p className="file_type">{getFileType(props.url)}</p>
        </div>
        <div className="position-absolute end-0 bottom-0 m-2">
          <div
            className={"ratio ratio-1x1 tw-w-[30px] bg-white rounded-circle shadow-sm"}>
            <i className="bi bi-download  d-flex justify-content-center align-items-center"></i>
          </div>
        </div>
      </div>
    </a>
  )
}