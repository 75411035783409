import React from 'react';
import SideNav from '../../component/staticPages/SideNav';

import TimeExchangePocket from "../../assets/svg/TimeExchangePocket.svg";
import TimeExchangeEmoji from "../../assets/svg/TimeExchangeEmoji.svg";
import Rocketman from "../../assets/svg/Rocketman.svg";

const yourExchange = () => {
    return (
        <>
            <div className='row m-0 d-flex newsfeed_bg auto_height'>

                <div className='news_left p-0'>

                    <SideNav timeExchange={true} />

                </div>

                <div className='news_right time_exchange_auto_height p-0'>

                    <div className='row m-0'>
                        <div className='col-12 p-5'>

                            <div className='row m-0 d-flex align-items-center'>
                                <div className='col-8 p-0'>
                                    <p className='mb-0 notification_heading'>Time Exchange</p>
                                </div>
                                <div className='col-4 p-0 d-flex align-items-center justify-content-end'>
                                    <p className='mb-0 time_exchange_right_head text-right'> <img className='mb-1' src={TimeExchangePocket} alt=' ' /> 36H &nbsp; <span className='deivder_time_ex'>|</span> &nbsp; <img className='mb-1' src={TimeExchangeEmoji} alt=' ' /> 2400 WBX</p>
                                </div>
                            </div>

                            <div className='row m-0 mt-2'>
                                <div className='col-12 p-0 ps-1 d-flex'>
                                    <p className='mb-0 ulr_path_line'>Time Exchange > Exchange</p>
                                </div>
                            </div>

                            <div className='row m-0 mt-4'>

                                <div className='col-3 p-0'>

                                    <div className='all_exchange_box d-flex flex-column'>
                                        <button className='all_exchange_btn ps-3'>All Gifts</button>
                                        <button className='all_exchange_btn active_all_exchange_btn ps-3'>My Requests</button>
                                        <button className='all_exchange_btn ps-3'>History</button>
                                    </div>

                                </div>

                                <div className='col-9 p-0'>

                                    <div className='row m-0'>
                                        <div className='col-12 p-0'>
                                            <p className='all_exp_heading'>My Requests</p>
                                        </div>
                                    </div>

                                    <div className='row m-0 mt-3'>
                                        <div className='col-12 p-0'>
                                            <button className='active_all_exp_sm_mid_btns p-2'>Bids And Offers</button>
                                            <button className='all_exp_sm_mid_btns p-2 ms-3'>Offers</button>
                                            <button className='all_exp_sm_mid_btns p-2 ms-3'>Bids</button>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className='row m-0 mt-4'>
                                <div className='col-3 p-0'></div>
                                <div className='col-6 p-0'>

                                    <div className='row m-0'>
                                        <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                            <img src={Rocketman} alt='' />
                                        </div>
                                    </div>

                                    <div className='row m-0'>
                                        <div className='col-12 p-0'>
                                            <p className='all_exp_heading text-center'>No Exchanges Currently</p>
                                            <p className='all_exp_heading_info_text text-center'>You don’t have any exchanges in progress. <br/> Try to create a new offer or accept bids for time.</p>
                                        </div>
                                    </div>

                                    <div className='row m-0'>
                                        <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                            <button className='yourExe_lg_btns_crt me-2'>Create New Offer</button>
                                            <button className='yourExe_lg_btns_brw ms-2'>Browse Experiences</button>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-3 p-0'> </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default yourExchange;