import React from 'react';
import {BrowserRouter} from "react-router-dom";
import Layout from './app/Layout';
import {Provider} from 'react-redux';
import store from './redux/store';
import {createRoot} from "react-dom/client";

const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(
    <Provider store={store}>
        <React.StrictMode>
            <BrowserRouter>
                <Layout/>
            </BrowserRouter>
        </React.StrictMode>
    </Provider>
)
