import CallApi from "./apiCallingSetup.tsx";

export const API_BASE_URL = "https://api.community.wellbeingchampions.sg";

export const signUp = async (data) => {
  const url = `/api/user/sign-up`;
  let response = await CallApi("POST", {url, data});
  return response.data;
};

export const enquiryNow = async (data) => {
  const url = `/api/enquiry`;
  let response = await CallApi("POST", {url, data});
  return response.data;
};

export const getExpertiseHistory = async (id) => {
  let url = `/api/expertise-history`;
  if (id) {
    url += `?userId=${id}`;
  }
  let response = await CallApi("GET", {url});
  return response.data;
}

export const addExpertiseHistory = async (data) => {
  const url = `/api/expertise-history`;
  let response = await CallApi("POST", {url, data});
  return response.data;
}

export const addSurvey = async (data) => {
  const url = `/api/survey`;
  let response = await CallApi("POST", {url, data});
  return response.data;
}

export const LoginApi = async (data) => {
  const url = `/api/user/login`;
  let response = await CallApi("POST", {url, data});
  return response.data;
};

export const ForgetPasswordApi = async (data) => {
  const url = `/api/user/reset-password`;
  let response = await CallApi("POST", {url, data});
  return response.data;
};

export const ChangePasswordApi = async (data) => {
  const url = `/api/user/change-password`;
  const {token} = data
  delete data.token
  let response = await CallApi("POST", {url, data, token});
  return response.data;
};

/*interest*/

/**
 * @return {Promise<AxiosResponse<{data: {
 *   interestArray: Interest[]
 * }}>>}
 */
export const fetchInterestAPI = async () => {
  const url = `/api/interest`;
  return await CallApi("GET", {url});
};

export const updateUser = async (data, token) => {
  const url = `/api/user`;
  let response = await CallApi("PUT", {url, data, token});
  return response.data;
};

export const updateEducationHistory = async (data) => {
  const url = `/api/instructor/education-history/${data.userEducationId}`;
  let response = await CallApi("PUT", {url, data});
  return response.data;
};

export const getEmailCheck = async (email) => {
  const url = `/api/user/check-email?email=${email.trim()}`;
  let response = await CallApi("GET", {url});
  return response.data;
};

export const fetchInstructorEducation = async () => {
  const url = `/api/instructor/education-history`;
  let response = await CallApi("GET", {url});
  return response.data;
};

export const fetchjobHistory = async () => {
  const url = `/api/instructor/job-hisotry`;
  let response = await CallApi("GET", {url});
  return response.data;
};

export const verifyEmail = async (token) => {
  const url = `/api/user/verify-email`;
  return await CallApi("GET", {url, token})
}


export const createPost = async (data) => {
  const url = `/api/post`;
  return await CallApi("POST", {url, data})
}

export const updatePost = async (postId, data) => {
  const url = `/api/post/${postId}`;
  return await CallApi("PUT", {url, data})
}

export const updateComment = async (commentId, data) => {
  const url = `/api/comment/${commentId}`;
  return await CallApi("PUT", {url, data}, {'Content-Type': 'multipart/form-data'})
}

/**
 * @deprecated use v2
 */
export const reportAnPost = async (postId, data) => {
  const url = `/api/reportPost/${postId}`;
  return await CallApi("PUT", {url, data})
}

export const reportAnPostV2 = async (data) => {
  const url = `/api/post/report`;
  return await CallApi("POST", {url, data})
}

/**
 * @deprecated use v2
 */
export const reportAnCommentApi = async (commentId, data) => {
  const url = `/api/reportComment/${commentId}`;
  return await CallApi("PUT", {url, data})
}

export const reportAnCommentApiV2 = async (data) => {
  const url = `/api/comment/report`;
  return await CallApi("POST", {url, data})
}

export const getReportedPostlist = async () => {
  const url = `/api/post?reported=${true}`;
  return await CallApi("GET", {url})
}

export const getReportedCommentlist = async () => {
  const url = `/api/getAllReportedComments`;
  return await CallApi("GET", {url})
}

/**
 * @param {number | null} id
 * @param {number} limit
 * @param {number | null} organisationId
 * @param {string | null} keyword
 * @param {string | null} subcategoryFilter
 * @return {Promise<AxiosResponse<{data: {postArray: Post[]}}>>}
 */
export const getPosts = async (id, limit = 200, organisationId = null, keyword = null, subcategoryFilter = null) => {
  let url;
  if (id) {
    url = `/api/post?visible=everyone&postId=${id}`;
  } else if (organisationId) {
    url = `/api/post?visible=everyone&organisationId=${organisationId}`;
  } else {
    url = `/api/user/news-feed?limit=${limit}&skip=0`;
  }

  if (keyword) {
    url += `&keyword=${encodeURIComponent(keyword)}`
  }

  if (subcategoryFilter) {
    url += `&subcategory=${encodeURIComponent(subcategoryFilter)}`
  }

  /**
   *  @var {AxiosResponse<{data: {postArray: Post[]}}>} response
   **/
  const response = await CallApi("GET", {url})

  return response;
}

export const getEventData = async (id, limit = 200) => {
  let url;
  if (id) {
    url = `/api/event?limit=10&skip=0&eventId=${id}`;
  } else {
    url = `/api/event?limit=${limit}&skip=0`
  }

  return await CallApi("GET", {url})
}

export const createEventData = async (data) => {
  const url = `/api/event`;
  return await CallApi("POST", {url, data}, {'Content-Type': 'multipart/form-data'})
}

export const getPostComments = async (postId, limit) => {
  const url = `/api/comment?postId=${postId}&limit=${limit ? limit : 200}`;
  return await CallApi("GET", {url})
}

export const getCommentRepliesByCommentId = async (commentId) => {
  const url = `/api/comment/replies/${commentId}`;
  return await CallApi("GET", {url})
}

export const getEventComments = async (eventId, limit) => {
  const url = `/api/comment?eventId=${eventId}&limit=${limit ? limit : 200}`;
  return await CallApi("GET", {url})
}

export const createPostComments = async (data) => {
  const url = `/api/comment`;
  return await CallApi("POST", {url, data}, {
    "Content-Type": "multipart/form-data"
  })
}

export const createPostLike = async (data) => {
  const url = `/api/reaction`;
  return await CallApi("POST", {url, data})
}

export const getPostLike = async (obj) => {
  const url = `/api/reaction?${obj}`;
  return await CallApi("GET", {url})
}


export const deletePostLike = async (data) => {
  const url = `/api/reaction`;
  return await CallApi("DELETE", {url, data})
}

/**
 * @deprecated use apiUrlsSetupV2.getTimeExPoints
 * @return {Promise<AxiosResponse<{data: GetTimeExPointsResponse}>>}
 */
export const getTimeExPoints = async () => {
  const url = `/api/point/balance`;
  return await CallApi("GET", {url})
}

export const getCheckInDetail = async () => {
  const url = `/api/user/check-in`;
  return await CallApi("GET", {url})
}

export const postCheckInDetail = async (data) => {
  const url = `/api/user/check-in`;
  return await CallApi("POST", {url, data})
}

export const getUserInformation = async () => {
  const url = `/api/user`;
  return await CallApi("GET", {url})
}

export const updateUserPassword = async (data) => {
  const url = `/api/user/update-user-password`;
  return await CallApi("PUT", {url, data})
}

export const updateUserprofile = async (data) => {
  const url = `/api/user`;
  return await CallApi("PUT", {url, data}, {
    'Accept': 'application/json, text/plain, */*',
  })
}

/**
 * @param id
 * @return {Promise<AxiosResponse<{data: UserInformationByIdResponse}>>}
 */
export const getUserInformationById = async (id) => {
  const url = `/api/user/profile?userId=${id}`;
  return await CallApi("GET", {url})
}

export const sendFriendRequest = async (data) => {
  const url = `/api/friend/request/send`;
  return await CallApi("POST", {url, data})
}

export const cancelFriendRequest = async (data) => {
  const url = `/api/friend/cancel`;
  return await CallApi("PUT", {url, data})
}

export const respondToFriendRequest = async (data) => {
  const url = `/api/friend/request`;
  return await CallApi("PUT", {url, data})
}

export const blockFriend = async (data) => {
  const url = `/api/friend/block`;
  return await CallApi("PUT", {url, data})
}

export const deleteFriend = async (data) => {
  const url = `/api/friend/unfriend`;
  return await CallApi("PUT", {url, data})
}

export const createOffer = async (data) => {
  const url = `/api/offer`;
  return await CallApi("POST", {url, data})
}

/**
 * @param id
 * @param limit
 * @return {Promise<AxiosResponse<{data: OfferResponse}>>}
 */
export const getOffers = async (id, limit = 200) => {
  const url = id ? `/api/offer/${id}` : `/api/offer?limit=${limit}`;
  return await CallApi("GET", {url})
}


/**
 *
 * @param {{
 *   offerId: number,
 *   instructorId: number,
 *   startDate: Date,
 *   endDate: Date,
 *   timeSlot: number,
 *   noOfHours: number,
 * }} data
 * @returns {Promise<*|AxiosResponse<*>>}
 */
export const bookSession = async (data) => {
  const url = `/api/one-to-one-session`;
  return await CallApi("POST", {url, data})
}


/**
 * @param {{
 *   oneToOneSessionId: number,
 *   startDate: Date,
 *   endDate: Date,
 *   timeSlot: number
 * }} data
 * @returns {Promise<*|AxiosResponse<*>>}
 */
export const rescheduleSession = async (data) => {
  const url = `/api/one-to-one-session/reschedule`;
  return await CallApi("PUT", {url, data})
}


/**
 *
 * @param data
 * @param limit
 * @return {Promise<AxiosResponse<{data: BidResponse}>>}
 */
export const getBids = async (data, limit = 200) => {
  let url = `/api/one-to-one-session?limit=${limit}&userId=${data.userId}`;
  if (data && data.offerId) {
    url = `/api/one-to-one-session?offerId=${data.offerId}`
  }
  return await CallApi("GET", {url})
}

export const registerForEvent = async (id) => {
  const url = `/api/event/${id}/event-participant`;
  return await CallApi('POST', {url})
}

export const updateEventInvitation = async (eventId, data) => {
  const url = `/api/event/${eventId}/event-participant`;
  return await CallApi("PUT", {url, data})
}

export const getEventById = async (eventId) => {
  const url = `/api/event?eventId=${eventId}`;
  return await CallApi("GET", {url})
}


export const getAllFriends = async () => {
  const url = `/api/friend/list`;
  return await CallApi("GET", {url})
}

export const report = async (data) => {
  const url = `/api/report`;
  return await CallApi("POST", {url, data})
}

export const sendEventParticipants = async (eventId, data) => {
  const url = `/api/event/${eventId}/event-participant/invite`;
  return await CallApi("POST", {url, data})
}

export const getAllChats = async (id) => {
  const url = `/api/chat?userId=${id}`;
  return await CallApi("GET", {url})
}

export const createChat = async (data) => {
  const url = `/api/chat`;
  return await CallApi("POST", {url, data})
}

export const searchUserProfile = async (value) => {
  const url = `/api/user/search/user/?keyword=${value}`;
  return await CallApi("GET", {url})
}

export const getUserlist = async () => {
  const url = `/api/admin/users`;
  return await CallApi("GET", {url})
}

export const userStatus = async (data) => {
  const url = `/api/admin/user/status`;
  return await CallApi("PUT", {url, data})
}

export const orgStatus = async (orgId, data) => {
  const url = `/api/admin/organisation/${orgId}`;
  return await CallApi("PUT", {url, data})
}

export const createOrg = async (data) => {
  const url = `/api/organisation`;
  return await CallApi("POST", {url, data}, {
    "Content-Type": "multipart/form-data"
  })
}

export const createProgram = async (data) => {
  const url = `/api/program`;
  return await CallApi("POST", {url, data})
}


export const editProgram = async (data, programId) => {
  const url = `/api/program/${programId}`;
  return await CallApi("PUT", {url, data})
}

export const checkOrgInviteCode = async (code) => {
  const url = `/api/organisation/inviteCode?inviteCode=${code}`;
  return await CallApi("GET", {
    url,
    hidePopup: true
  })
}

export const getOrgs = async (id, keyword) => {
  let url;
  if (!id) {
    url = `/api/organisation${keyword ? `?keyword=${keyword}` : ""}`;
  } else {
    url = `/api/organisation?organisationId=${id}`;
  }
  return await CallApi("GET", {url})
}

export const getOrgsForUser = async (status) => {
  const url = `/api/user/organisations?status=${status}`;
  return await CallApi("GET", {url})
}

export const getAllPrograms = async (limit, keyword, organisationId) => {
  let url = `/api/programs?limit=${limit}`;
  if (keyword) {
    url = `/api/programs?keyword=${keyword}`;
  } else if (organisationId) {
    url = `/api/programs?organisationId=${organisationId}&limit=${limit}`;
  }

  let response = await CallApi("GET", {url});
  return response
}

export const getProgramById = async (id) => {
  const url = `/api/program?id=${id}`;
  return await CallApi("GET", {url})
}

export const getAllExperiences = async (limit, keyword, organisationId) => {
  let url = `/api/experiences?limit=${limit}`;
  if (keyword) {
    url = `/api/experiences?keyword=${keyword}`;
  } else if (organisationId) {
    url = `/api/programs?organisationId=${organisationId}&limit=${limit}`;
  }

  let response = await CallApi("GET", {url});
  return response
}


export const validateOrg = async (code) => {
  let url = `/api/organisation/{inviteCode}?inviteCode=${code}`;

  return await CallApi("GET", {url})
}

export const inviteMembersToOrg = async (orgId, data) => {
  const url = `/api/organisation/${orgId}/members/invite`;
  return await CallApi("POST", {url, data})
}


export const getNotifications = async (limit = 500) => {
  const url = `/api/notification?limit=${limit}`;
  return await CallApi("GET", {url})
}


export const changeNotificationStatus = async (data, id) => {
  let url;
  if (id) {
    url = `/api/notification/${id}`;
  } else {
    url = `/api/notification`;
  }
  return await CallApi("PUT", {url, data})
}


export const getListOfExperUsers = async (exchangeDate, myCoachesBoolean) => {
  const url = `/api/expert/users/${myCoachesBoolean ? `?myCoaches=${myCoachesBoolean}` : ""}${exchangeDate ? `?exchangeDate=${exchangeDate}` : ""}`;
  return await CallApi("GET", {url})
}

export const getExpertExTime = async (id) => {
  const url = `/api/expert/fetch-exchange-time/?instructorId=${id}`;
  return await CallApi("GET", {url})
}


export const makeFavourite = async (data) => {
  const url = `/api/user/update-favourite-id`;
  return await CallApi("PUT", {url, data})
}


export const getEventSchedule = async () => {
  const url = `/api/event/schedule`;
  return await CallApi("GET", {url})
}

export const updateEvent = async (eventId) => {
  const url = `/api/event/${eventId}`;
  const formData = new FormData()
  formData.append('status', 'deleted')
  return await CallApi("PUT", {
    url,
    data: formData
  })
}

export const updateEventbyAdmin = async (eventId) => {
  const url = `/api/admin/event/${eventId}`;
  const formData = new FormData()
  formData.append('status', 'deleted')
  return await CallApi("PUT", {
    url,
    data: formData
  })
}

export const joinOrg = async (orgId) => {
  const url = `/api/organisation/${orgId}/join`;
  return await CallApi("POST", {
    url,
    data: orgId
  })
}

export const changeOrgInvitationStatus = async (memberId, orgId, data) => {
  const url = `/api/organisation/${orgId}/admin/members/${memberId}`;
  return await CallApi("PUT", {url, data})
}

export const leaveOrgStatus = async (orgId, data) => {
  const url = `/api/organisation/${orgId}/members/leave`;
  return await CallApi("PUT", {url, data})
}

export const respondToMembersOrg = async (orgId, data) => {
  const url = `/api/organisation/${orgId}/members/invite`;
  return await CallApi("PUT", {url, data})
}

export const getMetaData = async () => {
  const url = `/api/user/meta`;
  return await CallApi("GET", {url})
}

export const updateMetaData = async (data) => {
  const url = `/api/user/meta`;
  return await CallApi("PUT", {
    url,
    data: {platformTour: data}
  })
}

export const getOneToOneSessions = async (id) => {
  const url = `/api/one-to-one-session/no-review?instructorId=${id}`;
  return await CallApi("GET", {
    url,
    hidePopup: true
  })
}

export const postReview = async (data) => {
  const url = `/api/review`;
  return await CallApi("POST", {url, data})
}

export const getReview = async (id) => {
  const url = `/api/review?instructorId=${id}`;
  return await CallApi("GET", {url})
}

export const getTotalUsersCount = async () => {
  const url = `/api/health-check`;
  return await CallApi("GET", {url})
}
