import {create} from 'zustand'
import {createJSONStorage, persist} from 'zustand/middleware'

export const useZStore = create(
  persist(
    (set, get) => ({
      userDetails: null,
      setUserDetails: (userDetails) => set(() => ({userDetails: userDetails})),
      fullscreenImgSrc: null,
      setFullscreenImgSrc: (fullscreenImgSrc) => set(() => ({fullscreenImgSrc})),
      showingReactionTopId: "",
      setShowingReactionTopId: (showingReactionTopId) => set(() => ({showingReactionTopId})),
    }),
    {
      name: 'wbc-zstand', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    },
  ),
)