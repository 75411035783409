import React, {useEffect, useState} from "react";
import "./newsFeed.css";
import {useLocation, useParams} from "react-router";

import NewsFeedlgImgModel from "../../../assets/NewsFeed_lg_Img_Model.png";
import {
    createPostComments,
    createPostLike,
    deletePostLike,
    getAllFriends,
    getEventById,
    getEventComments,
    getPostComments,
    getPostLike,
    registerForEvent
} from "../../../utils/apiUrlsSetup";
import moment from "moment";
import InviteFriendModal from '../../../component/staticPages/inviteFriendModal';
import NewsFeedMobile from "./newsfeed-mobile";
import NewsfeedWeb from "./newsfeed-web";
import NewsfeedTab from "./newsfeed-tab";
import LikeProfileModal from "../../../component/modals/likeProfileModal/likeProfileModal";
import {updateEventbyAdmin} from '../../../utils/apiUrlsSetup';
import {useNavigate} from 'react-router-dom';


function NewsFeed() {
    const {state} = useLocation();


    const {id} = useParams();

    const [eventDetails, setEventDetails] = useState({createdByUser: {}});

    const [show, setShow] = useState(false);

    const [show1, setShow1] = useState(false);

    const [showRegister, setshowRegister] = useState(false);

    const [comments, setComments] = useState([]);

    const [commentBox, showCommentBox] = useState(false);
    const [comment, setComment] = useState("");
    const [commentImage, saveCommentImage] = useState(null);

    const [friendsList, setFriendsList] = useState([]);

    const [subComment, setSubComment] = useState("");
    const [showRepliedCommentInput, setShowRepliedCommentInput] = useState(false);
    const [showRepliedCommentId, setShowRepliedCommentId] = useState("");

    const [isShowInviteFrndModal, setIsShowInviteFrndModal] = useState(false);

    const [showEventDelete, setShowEventDelete] = useState(false);
    const [showUserLikedList, setShowUserLikedList] = useState(false)
    const [listOfLikedUsers, setListOfLikedUsers] = useState([])

    const [showComment, setShowComment] = useState(false)

    const [showImageComment, setShowImageComment] = useState(false);

    const [imageUrl, setImageUrl] = useState("");
    const [imageData, saveImageData] = useState(null);

    const [showParticipantsList, setShowParticipantsList] = useState(false)

    const [participantList, setEventParticipantList] = useState([])

    const setParticipantsList = (data) => {
        setEventParticipantList(data)
        setShowParticipantsList(!!data.length)
    }

    const navigate = useNavigate();
    // const [userDetails, setUserDetails] = useState({});
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    const handleShow = () => setShow(true);

    const deleteEvent = async (id) => {
        const response = await updateEventbyAdmin(id);
        if (response.status === 200) {
            setShowEventDelete(false);
            navigate('/admin/experiences')
        }
    }

    const handleShowRegister = async () => {
        const responseData = await registerForEvent(id);
        if (responseData.status === 200) {
            setShow(false);
            setshowRegister(true);
            getEventDetails(id);
        }
    };

    const handleShow1 = () => {
        setshowRegister(false);
        setShow1(false);
    };

    const handleCloseModal = () => {
        setshowRegister(false);
        setShow1(false);
        setShow(false);
    };

    const handleBackModal = () => {
        setShow1(false);
    };

    useEffect(() => {
        // setUserDetails(data.userDetails.userDetails)
        getAllFriendsList();
        getEventDetails(id);
    }, []);

    const getEventDetails = async (id) => {
        const responseData = await getEventById(id);
        if (responseData.status === 200) {
            setEventDetails(responseData.data.data.eventArray[0]);
            const data = await getEventComments(id, 200);
            if (data.status === 200) {
                setComments(data.data.data.commentArray);
            }
        }
    }

    const handleLike = async (id, commentId) => {
        const responseData = commentId ? await getPostLike(`commentId=${commentId}`) : await getPostLike(`eventId=${id}`);
        let data = responseData.data.data.map(v => v.userId)
        let filterData = data.includes(userDetails.id);
        if (data.length && filterData) {
            if (commentId) {
                const response = await deletePostLike({commentId: commentId});
                if (response.status === 200) {
                    getEventDetails(id);
                }
            } else {
                const response = await deletePostLike({eventId: id});
                if (response.status === 200) {
                    getEventDetails(id);
                }

            }
        } else {
            if (commentId) {
                const response = await createPostLike({commentId: commentId});
                if (response.status === 200) {
                    getEventDetails(id);
                }
            } else {
                const response = await createPostLike({eventId: id});
                if (response.status === 200) {
                    getEventDetails(id);
                }

            }
        }

    }

    const submitComment = async (type, data, value, cmmtId) => {
        const formData = new FormData();
        if (type === "text") {
            formData.append("eventId", data.id);
            formData.append("comment", value);
        } else {
            formData.append("eventId", data.id);
            formData.append("attachment", commentImage);
            formData.append("comment", cmmtId ? subComment : comment);
        }
        if (cmmtId) {
            formData.append("commentId", cmmtId);
        }

        const responseData = await createPostComments(formData);
        if (responseData.status === 200) {
            setComment("");
            setSubComment("");
            setImageUrl("");
            saveImageData(null);
            setShowImageComment(false);
            const responseData = await getPostComments(data.id);
            if (responseData.status === 200) {
                setComment("");
                setSubComment("");
                getEventDetails(id);

            }
        }
    }

    const getAllFriendsList = async () => {
        const responseData = await getAllFriends();
        if (responseData.status === 200) {
            setFriendsList(responseData.data.data);
        }
    }

    const getListOfLikedUsers = async (post) => {
        let response = await getPostLike(`eventId=${post.id}`)
        if (response.status === 200) {
            if (response.data.data.length) {
                setShowUserLikedList(true)
            }
            setListOfLikedUsers(response.data.data);
        }
    }

    return (
        <>
            <NewsfeedWeb
                eventDetails={eventDetails}
                userDetails={userDetails}
                handleLike={handleLike}
                showCommentBox={showCommentBox}
                setIsShowInviteFrndModal={setIsShowInviteFrndModal}
                commentBox={commentBox}
                comment={comment}
                setComment={setComment}
                submitComment={submitComment}
                saveCommentImage={saveCommentImage}
                comments={comments}
                setShowRepliedCommentInput={setShowRepliedCommentInput}
                setShowRepliedCommentId={setShowRepliedCommentId}
                showRepliedCommentId={showRepliedCommentId}
                showRepliedCommentInput={showRepliedCommentInput}
                subComment={subComment}
                setSubComment={setSubComment}
                handleShow={handleShow}
                show={show}
                setShow={setShow}
                handleShowRegister={handleShowRegister}
                showRegister={showRegister}
                handleShow1={handleShow1}
                NewsFeedlgImgModel={NewsFeedlgImgModel}
                show1={show1}
                handleBackModal={handleBackModal}
                handleCloseModal={handleCloseModal}
                isShowInviteFrndModal={isShowInviteFrndModal}
                InviteFriendModal={InviteFriendModal}
                friendsList={friendsList}
                adminDataEvent={state}
                showEventDelete={showEventDelete}
                setShowEventDelete={setShowEventDelete}
                deleteEvent={deleteEvent}
                showComment={showComment}
                setShowComment={setShowComment}
                setShowUserLikedList={(data) => getListOfLikedUsers(data)}
                setShowImageComment={setShowImageComment}
                showImageComment={showImageComment}
                setImageUrl={setImageUrl}
                imageUrl={imageUrl}
                saveImageData={saveImageData}
                imageData={imageData}
                setParticipantsList={(data) => setParticipantsList(data)}
                // handleShowComment={(data)=>{handleShowComment(data)}}
            />

            <NewsFeedMobile
                isShowInviteFrndModal={isShowInviteFrndModal}
                setIsShowInviteFrndModal={setIsShowInviteFrndModal}
                InviteFriendModal={InviteFriendModal}
                show={show}
                eventDetails={eventDetails}
                moment={moment}
                NewsFeedlgImgModel={NewsFeedlgImgModel}
                setShow={setShow}
                handleShowRegister={handleShowRegister}
                handleLike={handleLike}
                commentBox={commentBox}
                showCommentBox={showCommentBox}
                setComment={setComment}
                comment={comment}
                submitComment={submitComment}
                comments={comments}
                userDetails={userDetails}
                setShowRepliedCommentInput={setShowRepliedCommentInput}
                setShowRepliedCommentId={setShowRepliedCommentId}
                showRepliedCommentId={showRepliedCommentId}
                showRepliedCommentInput={showRepliedCommentInput}
                friendsList={friendsList}
                eventData={{event: eventDetails}}
                showRegister={showRegister}
                handleShow1={handleShow1}
                adminDataEvent={state}
                showEventDelete={showEventDelete}
                setShowEventDelete={setShowEventDelete}
                deleteEvent={deleteEvent}
                showComment={showComment}
                setShowComment={setShowComment}
                setShowUserLikedList={(data) => getListOfLikedUsers(data)}
                setShowImageComment={setShowImageComment}
                showImageComment={showImageComment}
                setImageUrl={setImageUrl}
                imageUrl={imageUrl}
                saveImageData={saveImageData}
                imageData={imageData}
                subComment={subComment}
                setSubComment={setSubComment}
                setParticipantsList={(data) => setParticipantsList(data)}
            />


            <NewsfeedTab
                show={show}
                eventDetails={eventDetails}
                moment={moment}
                NewsFeedlgImgModel={NewsFeedlgImgModel}
                setShow={setShow}
                handleShowRegister={handleShowRegister}
                handleLike={handleLike}
                commentBox={commentBox}
                showCommentBox={showCommentBox}
                setComment={setComment}
                comment={comment}
                submitComment={submitComment}
                comments={comments}
                userDetails={userDetails}
                setShowRepliedCommentInput={setShowRepliedCommentInput}
                setShowRepliedCommentId={setShowRepliedCommentId}
                showRepliedCommentId={showRepliedCommentId}
                showRepliedCommentInput={showRepliedCommentInput}
                friendsList={friendsList}
                eventData={{event: eventDetails}}
                isShowInviteFrndModal={isShowInviteFrndModal}
                setIsShowInviteFrndModal={setIsShowInviteFrndModal}
                InviteFriendModal={InviteFriendModal}
                handleShow={handleShow}
                showRegister={showRegister}
                handleShow1={handleShow1}
                show1={show1}
                showComment={showComment}
                setShowComment={setShowComment}
                setShowUserLikedList={(data) => getListOfLikedUsers(data)}
                setShowImageComment={setShowImageComment}
                showImageComment={showImageComment}
                setImageUrl={setImageUrl}
                imageUrl={imageUrl}
                saveImageData={saveImageData}
                imageData={imageData}
                subComment={subComment}
                setSubComment={setSubComment}
            />
            <LikeProfileModal isOpen={showUserLikedList || showParticipantsList}
                              setParticipantsList={setParticipantsList} setShowUserLikedList={setShowUserLikedList}
                              listOfLikeUsers={listOfLikedUsers} participantList={participantList}/>
        </>
    );
}

export default NewsFeed;