import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {createPostLike, deletePostLike} from '../../../utils/apiUrlsSetup';
import Post2_img from '../../../assets/post2_img.png';
import LikeCircle from '../../../assets/svg/EventLike.svg';
import CommentCircle from '../../../assets/svg/EventComment.svg';
import accArrow from '../../../assets/svg/AccordianArrow.svg';
import moment from 'moment';
import {getPostLike} from '../../../utils/apiUrlsSetup';

function NewsFeedEvents(props) {
  let userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [currentId, setcurrentId] = useState(0);
  const navigate = useNavigate();

  const HandleRegitration = (eventid) => {
    navigate(`/event/${eventid}`)
  };

  const handleLike = async (id) => {
    const responseData = await getPostLike(`postId=${id}`);
    let data = responseData.data.data.map(v => v.userId)
    let filterData = data.includes(userDetails.id);
    if (data.length && filterData) {
      const response = await deletePostLike({postId: id});
      if (response.status === 200) {
        const postMetaDataCopy = {...props.postMetaData}
        postMetaDataCopy[id]['reactionCount'] = postMetaDataCopy[id]['reactionCount'] - 1
        props.setPostMetaData({...postMetaDataCopy})
        props.setPosts([...props.posts])
      }
    } else {
      const response = await createPostLike({postId: id})
      if (response.status === 200) {
        const postMetaDataCopy = {...props.postMetaData}
        postMetaDataCopy[id]['reactionCount'] = postMetaDataCopy[id]['reactionCount'] + 1
        props.setPostMetaData({...postMetaDataCopy})
        props.setPosts([...props.huddleData])
      }
    }
  }
  return (
    <>
      {
        props.huddleData.filter(v => v.type === "event").filter(v => moment(v.event.startDate).isAfter(`${moment().toISOString()}`)).sort(
          (a, b) =>
            `-` +
            a.createdAt.localeCompare(b.createdAt)
        ).map((curr, index) => {
          return <div className={`row m-0 ${props.noTopMargin ? "mb-4" : "mt-5"}`} key={index}>
            <div className='col-12 p-0'>
              <div class="accordion post_accordion event_accordion" id="accordionExample1">
                <div class="accordion-item event_box_feed event_box_feed_joyride">
                  <h2 class="accordion-header " id={`${index}`}>
                    <button class="accordion-button post_accordion_btn d-flex justify-content-between" type="button"
                            data-bs-toggle="collapse" data-bs-target={`#${index}`} aria-expanded="false"
                            aria-controls={`${index}`}>

                      <div className='m-0  d-flex align-items-center'>

                        <div className='post_profile_img me-2 cursor_pointer' onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/friends/${curr.user.id}`)
                        }}>
                          {/* <img className='post_profile_img_img'config={curr.user.profilePic} /> */}
                          <img src={curr.user.profilePic ? curr.user.profilePic : "/static/png/Male.png"} alt=''/>
                        </div>

                        <div className='p-0 cursor_pointer' onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/friends/${curr.user.id}`)
                        }}>
                          <p className='mb-0 event_name ms-1'>{curr.user.firstName} {curr.user.lastName} <span
                            className='event-name_span'> shared</span> an Event</p>
                          <p
                            className='mb-0 event_date ms-1 mt-2'>{moment(curr.createdAt).utc().format(' Do MMM YYYY')} AT {moment(curr.createdAt).utc().format('h:mm A')}</p>
                        </div>

                      </div>

                      <div className='m-0  d-flex align-items-center'>

                        <div onClick={(e) => {
                          e.stopPropagation();
                          if (currentId === index) {
                            setcurrentId("");
                          } else {
                            setcurrentId(index);
                          }
                        }} class={`${index === currentId ? 'rotate_icon' : ''}`}>
                          <img src={accArrow}/>
                        </div>
                      </div>

                    </button>
                  </h2>
                  <div id={`${index}`} class={`accordion-collapse collapse ${index === currentId ? 'show' : ''}`}
                       aria-labelledby={`${index}`} data-bs-parent="#accordionExample1">
                    <div class="accordion-body accord_body">

                      <div className='row m-0 '>
                        <div className='col-12 p-0'>
                          <img style={{objectFit: 'contain', minHeight: '230px'}} className='w-100 cursor_pointer'
                               onClick={() => {
                                 HandleRegitration(curr.event.id)
                               }} src={curr.event.image ? curr.event.image : Post2_img}/>
                        </div>
                      </div>

                      <div className='row m-0 mb-5'>
                        <div className='col-12 p-0 pos_rel'>
                          <div
                            className='date_card pos_abs d-flex flex-column justify-content-center align-items-center'>
                            <p className='mb-0 date_card_date'>{moment(curr.event.startDate).utc().format('D')}</p>
                            <p
                              className='mb-0 date_card_month mt-1'>{moment(curr.event.startDate).utc().format('MMM')}</p>
                          </div>
                        </div>
                      </div>

                      <div className='row m-0 ps-3 align-items-center'>
                        <div className='col-6 p-0'>
                          <p className='mb-0 event_heading ms-1 cursor_pointer' onClick={() => {
                            HandleRegitration(curr.event.id)
                          }}>{curr.event.name}</p>
                          <p
                            className='mb-0 event_date ms-1'>{moment(curr.event.startDate).utc().format(' Do MMM YYYY')} AT {moment(curr.event.startDate).utc().format('h:mm A')}</p>
                        </div>

                        {userDetails.id !== curr.user.id &&
                          <div className='col-6 py-0 d-flex align-items-center justify-content-end'>
                            {/* <button className='event_btn' onClick={() => { navigate('/newsfeed') }}>Register</button> */}
                            <button className='event_btn' onClick={() => {
                              HandleRegitration(curr.event.id)
                            }}>Register
                            </button>
                          </div>}


                      </div>

                      <div className='row m-0 ps-3 pe-3 pb-2 post_border mt-2'>

                        {/* <div className='col-6 p-0 d-flex align-items-center justify-content-start'>

                                                    <span class="input-group-text post_avatar_img " id="basic-addon2">
                                                        <span class="avatar_post">
                                                            <img class="avatar_post_img" config={Ellipse3} alt='' />
                                                        </span>
                                                        <span class="avatar_post">
                                                            <img class="avatar_post_img" config={Ellipse4} alt='' />
                                                        </span>
                                                        <span class="avatar_post">
                                                            <img class="avatar_post_img" config={Ellipse5} alt='' />
                                                        </span>
                                                        <span class="avatar_post">
                                                            <img class="avatar_post_img" config={Ellipse6} alt='' />
                                                        </span>
                                                    </span>

                                                </div> */}


                      </div>

                      <div className='row m-0 mt-3 pb-3'>

                        <div className='col-4 p-0 d-flex justify-content-center align-items-center'>

                          <img className='event_action_img' src={LikeCircle} alt=''
                               onClick={() => handleLike(curr.id)}/>
                          <p className='mb-0 ms-2 event_action_like_count'>
                            {
                              (props.postMetaData[curr.id] && props.postMetaData[curr.id]['reactionCount'])
                            }
                          </p>

                        </div>

                        <div className='col-4 p-0 d-flex align-items-center'>

                          <div className='d-flex align-items-center' onClick={(e) => {
                            e.stopPropagation();
                            HandleRegitration(curr.event.id);
                          }}>
                            <img className='event_action_img' src={CommentCircle} alt=''/>
                            <p className='mb-0 ms-2 event_action_cmnt_count'>{curr.commentCount}</p>
                          </div>

                        </div>

                        <div className='col-4 p-0 pe-3 d-flex justify-content-end align-items-center'>
                          {/* <img className='event_action_img' config={ShareCircle} alt='' /> */}
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        })
      }

    </>
  )
}

export default NewsFeedEvents;
