import React from 'react';
import {useNavigate} from 'react-router';

import './contact.css';

import Header from '../../component/staticPages/header';
import Footer from '../../component/staticPages/footer';

import ContactMidMobile from '../../assets/contactmid_mobile.png';
import HeroBg from '../../assets/Contact.png'

const ContactUs = () => {

    const navigate = useNavigate();

    return (

        <main className={"min-vh-100 d-flex flex-column justify-content-between"}>
            <div className={"bg-logged-in"}>
                <div className="container-xl">
                    <Header/>
                </div>

                <div style={{
                    backgroundImage: `linear-gradient(275.33deg,rgba(0,0,0,.3) 3.49%,hsla(0,0%,100%,.3) 62.84%),url('${HeroBg}')`
                }} className={"tw-bg-cover tw-py-20 tw-bg-no-repeat tw-bg-left-bottom"}>
                    <div className="container-xl">
                        <div
                            className='d-flex justify-content-end align-items-center'>
                            <p className='contact_heading mt-lg-5 mt-md-5 mb-md-5'>
                                How can<br className='d-block d-lg-none'/>
                                {" we help"}<br className='d-none d-lg-block'/>
                                <br className='d-md-none d-block'/> you today?</p>
                        </div>
                    </div>
                </div>
            </div>

            <section className="py-5 my-5">
                <div className='row m-0 contact_mid_bg contact_mid_web'>

                    <div className='col-lg-1 col-12 p-0'></div>

                    <div className='col-lg-10 col-12 p-0 pb-5'>

                        <div className='row m-0'>

                            <div className='col-lg-6 col-md-6 p-0'>

                            </div>

                            <div className='col-lg-6 col-md-6 p-0 ps-lg-5 pe-lg-5 pt-3'>

                                <div className='row m-0 mt-5 hide_tab hide_mobile'>
                                    <div className='col-12 p-0 ps-lg-5'>
                                        <p className='form_info hide_tab hide_mobile ms-lg-5'>In order to us to organise
                                            an
                                            event for your company, we will need to ask you a few questions.</p>
                                    </div>
                                </div>

                                <div className='row m-0'>
                                    <div className='col-12 p-lg-4 p-md-0 ipad'>

                                        <div className='contact_form ms-lg-2 p-lg-5 p-md-3'>

                                            <div className='row m-0'>
                                                <div className='col-12 p-0'>
                                                    <label htmlFor="exampleFormControlInput1"
                                                           className="form-label contact_label">What
                                                        is your name?</label>
                                                    <input type="email" className="form-control contact_input mt-1"
                                                           id="exampleFormControlInput1" placeholder="Theresa Lim"/>
                                                </div>
                                            </div>

                                            <div className='row m-0 mt-4'>
                                                <div className='col-12 p-0'>
                                                    <label htmlFor="exampleFormControlInput1"
                                                           className="form-label contact_label">What
                                                        is your email address?</label>
                                                    <input type="email" className="form-control contact_input mt-1"
                                                           id="exampleFormControlInput1"
                                                           placeholder="theresa.lim@gmail.com"/>
                                                </div>
                                            </div>
                                            <div className='row m-0 mt-4'>
                                                <div className='col-12 p-0'>
                                                    <label htmlFor="exampleFormControlInput1"
                                                           className="form-label contact_label">What
                                                        is the size of your company?</label>
                                                    <select className="form-select contact_input"
                                                            aria-label="Default select example">
                                                        <option selected>Choose size</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='row m-0 mt-4'>
                                                <div className='col-12 p-0'>
                                                    <label htmlFor="exampleFormControlInput1"
                                                           className="form-label contact_label">What
                                                        kind of activities would you like your team to have within
                                                        Kaleidoscope?</label>
                                                    <select className="form-select contact_input"
                                                            aria-label="Default select example">
                                                        <option selected>Choose category</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='row m-0 mt-4'>
                                                <div className='col-12 p-0'>
                                                    <label htmlFor="exampleFormControlInput1"
                                                           className="form-label contact_label">What
                                                        is your budget for this event?</label>
                                                    <input type="email" className="form-control contact_input mt-1"
                                                           id="exampleFormControlInput1" placeholder="SGD 1000"/>
                                                </div>
                                            </div>
                                            <div className='row m-0 mt-4'>
                                                <div className='col-12 p-0'>
                                                    <label htmlFor="exampleFormControlInput1"
                                                           className="form-label contact_label">How
                                                        soon would you like to start organising this event?</label>
                                                    <select className="form-select contact_input"
                                                            aria-label="Default select example">
                                                        <option selected>Choose size</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='row m-0 mt-4'>
                                                <div className='col-12 p-0'>
                                                    <button className='contact_btn'>Submit</button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className='col-lg-1 col-12 p-0 '>

                        <div className='row m-0 mt-0 mt-md-5 mt-lg-0 pst_rel mr_top_web'>
                            <div className='col-12 p-0 contact_us_position d-flex justify-content-end'>
                                <button className='contactus_fixedbtn_contactus' onClick={() => {
                                    navigate('/contactus')
                                }}>CONTACT US
                                </button>
                            </div>
                        </div>

                    </div>

                </div>

                <div className='row m-0 contact_mid_mobile'>
                    <div className='col-12 p-0 ps-3 pe-3'>
                        <div className='row m-0 mt-5'>
                            <div className='col-12 p-0'>
                                <p className='form_info'>In order to us to organise an event for your company, we will
                                    need
                                    to ask you a few questions.</p>
                            </div>
                        </div>
                        <div className='row m-0 mt-3'>
                            <div className='col-12 p-0 d-flex align-items-center justify-content-center'>

                                <div className='contact_form p-3'>

                                    <div className='row m-0'>
                                        <div className='col-12 p-0'>
                                            <label htmlFor="exampleFormControlInput1"
                                                   className="form-label contact_label">What is
                                                your name?</label>
                                            <input type="email" className="form-control contact_input mt-1"
                                                   id="exampleFormControlInput1" placeholder="Theresa Lim"/>
                                        </div>
                                    </div>

                                    <div className='row m-0 mt-4'>
                                        <div className='col-12 p-0'>
                                            <label htmlFor="exampleFormControlInput1"
                                                   className="form-label contact_label">What is
                                                your email address?</label>
                                            <input type="email" className="form-control contact_input mt-1"
                                                   id="exampleFormControlInput1" placeholder="theresa.lim@gmail.com"/>
                                        </div>
                                    </div>
                                    <div className='row m-0 mt-4'>
                                        <div className='col-12 p-0'>
                                            <label htmlFor="exampleFormControlInput1"
                                                   className="form-label contact_label">What is
                                                the size of your company?</label>
                                            <select className="form-select contact_input contact_input_select"
                                                    aria-label="Default select example">
                                                <option selected>Choose size</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row m-0 mt-4'>
                                        <div className='col-12 p-0'>
                                            <label htmlFor="exampleFormControlInput1"
                                                   className="form-label contact_label">What kind
                                                of activities would you like your team to have within
                                                Kaleidoscope?</label>
                                            <select className="form-select contact_input contact_input_select"
                                                    aria-label="Default select example">
                                                <option selected>Choose category</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row m-0 mt-4'>
                                        <div className='col-12 p-0'>
                                            <label htmlFor="exampleFormControlInput1"
                                                   className="form-label contact_label">What is
                                                your budget for this event?</label>
                                            <input type="email" className="form-control contact_input mt-1"
                                                   id="exampleFormControlInput1" placeholder="SGD 1000"/>
                                        </div>
                                    </div>
                                    <div className='row m-0 mt-4'>
                                        <div className='col-12 p-0'>
                                            <label htmlFor="exampleFormControlInput1"
                                                   className="form-label contact_label">How soon
                                                would you like to start organising this event?</label>
                                            <select className="form-select contact_input contact_input_select"
                                                    aria-label="Default select example">
                                                <option selected>Choose size</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row m-0 mt-4'>
                                        <div className='col-12 p-0'>
                                            <button className='contact_btn'>Submit</button>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className='row m-0 contact_mid_mobile'>
                    <div className='col-12 p-0'>
                        <img src={ContactMidMobile} alt='ContactMidMobile'/>
                    </div>
                </div>
            </section>

            <section className={"py-5 my-5"}>
                <div className="container-xl">
                    <p className='text-center tw-text-4xl montserrat tw-font-extrabold mb-5'>Frequently Asked
                        Questions</p>

                    <div className={"d-flex flex-column gap-3 tw-max-w-[800px] mx-auto"}>
                        {[
                            {
                                q: "What kind of services does Kaleidoscope provide?",
                                a: "We currently provide one-on-one well-being coaching, group corporate (virtual activities), community bonding over well-being."
                            },
                            {
                                q: "How much do i need to pay to use Kaleidoscope?",
                                a: "For individual users, Kaleidoscope is currently free! Coaches, therapists can join us to look for students and individuals can look for coaches or therapists to work on their well-being."
                            },
                            {
                                q: "Why should I choose Kaleidoscope?",
                                a: "We are Singapore's first mental health startup that focuses on holistic well-being improvement over mental health diagnostics. We aim to raise awareness and develop individuals self-care and self-soothing capacity not only at an individual level but also at a community level, building self-aware and self-actualised communities one person at a time."
                            },
                        ].map((e, i) => {
                            return (
                                <div key={i}>
                                    <div className="accordion" id={`accordionExample${i}`}>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id={`heading${i}`}>
                                                <button className="accordion-button accordion_btn collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse" data-bs-target={`#collapse${i}`}
                                                        aria-expanded="true" aria-controls={`collapse${i}`}>
                                                    <p className={"tw-text-2xl"}>{e.q}</p>
                                                </button>
                                            </h2>
                                            <div id={`collapse${i}`} className="accordion-collapse collapse"
                                                 aria-labelledby={`heading${i}`}
                                                 data-bs-parent={`accordionExample${i}`}>
                                                <div className="accordion-body">
                                                    <p className="accordion_content">
                                                        {e.a}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <Footer/>
        </main>
    )
}

export default ContactUs
