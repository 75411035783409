import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {ForgetPasswordApi} from "../../../utils/apiUrlsSetup";
import Swal from "sweetalert2";

import '../../../css/loginAndSignup.css'

import Logo from "../../../assets/wcn_logo.svg";

function ForgetPassword() {
  const navigate = useNavigate();
  const [emailValidationError, setEmailValidationError] = useState(null)

  const [forgetpwd, setForgetpwd] = useState({
    email: "",
  })

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForgetpwd({...forgetpwd, [name]: value})
  }

  function ValidateEmail(email) {
    const mailformat = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/g;
    return !!email.match(mailformat);
  }

  const handleSubmit = async () => {
    let isFail = false;

    if (forgetpwd.email === "") {
      isFail = true
      setEmailValidationError('Email is required')
      return
    }
    if (!ValidateEmail(forgetpwd.email)) {
      isFail = true
      setEmailValidationError('Email is invalid')
      return
    }

    if (!isFail) {
      let objData = {}
      objData.email = forgetpwd.email
      let responseData = await ForgetPasswordApi(objData);
      if (responseData.statusCode === 200) {
        Swal.fire("Success", responseData.message, "success")
        navigate("/")
      } else {
        Swal.fire("Error", responseData.message, "error")
      }
    }
  }

  return (
    <>
      <div className="min-vh-100 bg-logged-in">
        {/* Left Side */}
        <div className={"d-lg-block d-none"}>
          <div
            className="tw-w-[300px] vh-100 position-fixed top-0 start-0 rounded-3 tw-rounded-br-lg tw-bg-[#f5f6fc] p-4 d-flex flex-column justify-content-between">
            <img src={Logo} alt='logo' className={"tw-max-w-[100px]"}/>

            <div className={"d-flex flex-column gap-3"}>
              <hr className="m-0"/>

              <div className={"text-center"}>
                <p className="tw-mb-2 tw-text-sm tw-leading-5">New to <br/>Well-being Champions Network?</p>
                <div>
                  <Link to={"/signup"}
                        className="inter tw-text-[#5458f7] tw-border-[#5458f7] tw-rounded-xl px-4 py-2 bg-white tw-border tw-text-xs fw-bold">
                    Sign Up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Top Side */}
        <div className={"d-lg-none d-block"}>
          <div className={"position-relative"}>
            <nav className={"shadow-sm montserrat position-relative"} style={{
              background: "linear-gradient(119.23deg, #e9dfff 4.63%, #edfaff 53.65%)",
              zIndex: 1001
            }}>
              <div className="container py-2">
                <div className="d-flex justify-content-between align-items-center">
                  <img src={"/static/svg/Logo.svg"} alt='logo' width={130 * .6} height={59 * .6}/>

                  <Link to={"/login"}>
                    <button type={"button"}>
                      Login
                    </button>
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div className={"lg:tw-ms-[300px] px-xl-5 px-lg-4 px-md-3 min-vh-100"}>
          <div className="container py-lg-4 py-3">

            <div className={"d-flex flex-column gap-4"}>
              <div className={"text-center"}>
                <p className="tw-text-3xl tw-text-[#54bfed] tw-font-semibold">Don’t worry...</p>
              </div>

              <hr className="m-0 p-0"/>

              <div className={"d-flex flex-column justify-content-center align-items-center"}>
                <p className="mb-2 tw-w-[600px] tw-max-w-[100%]">Please enter the email address you used when joined
                  Well-being
                  Champions Network. We’ll send you the link to reset your password to your email.</p>

                <div className={"d-flex justify-content-center align-items-center flex-column"}>
                  <input
                    className="input_filds signup_inputes tw-w-[600px] tw-max-w-[90vw]"
                    type="email"
                    placeholder={"Your business email...."}
                    id={`email`}
                    name={"email"}
                    value={forgetpwd.email}
                    onChange={() => {
                    }}
                    onChangeCapture={onChangeHandler}
                  />
                  {emailValidationError &&
                    <span className="validation mt-2">{emailValidationError}</span>
                  }
                </div>
              </div>

              <div className={"d-flex gap-3 justify-content-between align-items-center"}>
                <Link
                  to={"/login"}
                >
                  <button type={"button"}
                          className={"tw-text-[#5458f7] tw-font-medium"}
                  >
                    Back to Login
                  </button>
                </Link>

                <button
                  type={"button"}
                  className={`next_btn disabled:tw-bg-[#c4c4c4] tw-bg-[#5458f7]`}
                  disabled={forgetpwd.email === ""}
                  onClick={handleSubmit}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgetPassword;