import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {getOrgs, orgStatus} from '../../utils/apiUrlsSetup';
import RejectModal from './component/reject-modal';

import "./superAdmin.css";
import LoggedInLayout from "../../component/layout/LoggedInLayout.tsx";

const OrganisationProfile = () => {
  const {id} = useParams();
  const [isOpenModal, setIsOpenModal] = useState(false)

  const [userInfo, saveUserInfo] = useState({
    jobHistoryArray: [],
    educationHistoryArray: []
  })
  const [showStatus, setShowStatus] = useState("1")

  useEffect(() => {
    getCoachInformation();
  }, [])

  const getCoachInformation = async () => {
    let responseData = await getOrgs(id);
    if (responseData.status === 200) {
      saveUserInfo(responseData.data.data.organisationArray[0])
    }
  }

  const handleApproval = async (id) => {
    let objData = {}
    objData.status = "approved"
    let responseData = await orgStatus(id, objData);
    if (responseData.status === 200) {
      setShowStatus("2")
    }
  }

  const handleRejection = async (id) => {
    let objData = {}
    objData.status = "rejected"
    let responseData = await orgStatus(id, objData);
    if (responseData.status === 200) {
      setShowStatus("3")
      setIsOpenModal(false)
    }
  }

  const handleDeactivate = async (id) => {
    let objData = {}
    objData.status = "deleted"
    let responseData = await orgStatus(id, objData);
    if (responseData.status === 200) {
      setShowStatus("4")
    }
  }


  const findCommAdmin = (userInfo) => {
    if (userInfo.members) {
      let findMemberAdmin = userInfo.members.filter(v => v.isAdmin);
      return findMemberAdmin.length ? findMemberAdmin[0].user : {}
    } else {
      return {}
    }
  }

  return (
    <LoggedInLayout title={"Organisation Profile"}>
      {
        showStatus === "2" && <div className='row m-0'>
          <div className='col-12 p-0 ps-4 approval_lable_in_green d-flex align-items-center'>
            <p className='mb-0 approval_lable_text'>You apporved the
              organisation {userInfo.name}.</p>
          </div>
        </div>
      }
      {
        showStatus === "3" && <div className='row m-0'>
          <div
            className='col-12 p-0 ps-4 approval_lable_in_green reject_lable_in_red d-flex align-items-center'>
            <p className='mb-0 approval_lable_text'>You rejected the
              organisation {userInfo.name}.</p>
          </div>
        </div>
      }
      {
        showStatus === "4" && <div className='row m-0'>
          <div
            className='col-12 p-0 ps-4 approval_lable_in_green reject_lable_in_red d-flex align-items-center'>
            <p className='mb-0 approval_lable_text'>You de-activated the
              organisation {userInfo.name}.</p>
          </div>
        </div>
      }
      <p className='mb-0 sup_adm_heading'>Organisation Details</p>
      <p className='mb-0 sup_adm_url_text'>Organisations > organisation Details</p>

      <div className='coach_profile_update_box my-3 p-4'>
        <p className='profile_edit_heading'>Admin User Profile</p>

        <div className='row mt-2 mb-4'>

          <div className='col-4'>

            <p className='mb-0 title_of_the_field'>First Name</p>
            <p className='mb-0 value_of_the_field'>{findCommAdmin(userInfo).firstName || "--"}</p>

          </div>

          <div className='col-4'>

            <p className='mb-0 title_of_the_field'>Last Name</p>
            <p className='mb-0 value_of_the_field'>{findCommAdmin(userInfo).lastName || "--"}</p>

          </div>

          <div className='col-4'>

            <p className='mb-0 title_of_the_field'>Email</p>
            <p className='mb-0 value_of_the_field'>{findCommAdmin(userInfo).email || "--"}</p>

          </div>

        </div>


        <div className='row g-4'>
          <div className='col-6 d-flex flex-column gap-2'>

            <p className='profile_edit_heading mb-2'>About this community</p>

            <div>
              <p className='mb-0 title_of_the_field'>Title</p>
              <p className='mb-0 value_of_the_field'>{userInfo.name}</p>
            </div>

            <div>
              <p className='mb-0 title_of_the_field'>Description</p>
              <p className='mb-0 value_of_the_field'>{userInfo.description}</p>
            </div>


            <div>
              <p className='mb-0 title_of_the_field'>
                Upload Profile Image
              </p>
              <p className='sup_adm_exp_attch  p-0 m-0'
                 onClick={() => {
                 }}>
                Upload Here
              </p>
            </div>


            <div>
              <p className='mb-0 title_of_the_field'>Upload Cover Image</p>
              <p className='sup_adm_exp_attch  p-0 m-0' onClick={() => {
              }}>Upload Here</p>
            </div>

          </div>

          <div className='col-6 d-flex flex-column gap-2'>
            <p className='mb-0 profile_edit_heading mb-2'>Basic Settings</p>

            <div>
              <p className='mb-0 title_of_the_field'>Privacy
                Settings</p>
              <p
                className='mb-0 value_of_the_field'>{userInfo.privacy ? userInfo.privacy.charAt(0).toUpperCase() + userInfo.privacy.slice(1) : '--'}</p>
            </div>

            <div>
              <p className='mb-0 title_of_the_field'>Tags</p>
              <p
                className='mb-0 value_of_the_field'>{userInfo.tags ? userInfo.tags.join(", ") : "---"}</p>
            </div>

          </div>
        </div>

        {
          showStatus === "1" && <div className='mt-5'>
            <div
              className='d-flex align-items-center justify-content-end gap-2'>
              {
                userInfo.status === "pending" &&
                <button className='coach_edit_btn_reject'
                        onClick={() => setIsOpenModal(true)}>Reject</button>
              }
              {
                userInfo.status === "pending" &&
                <button className='coach_edit_btn_approve'
                        onClick={() => handleApproval(id)}>Approve</button>
              }
              {
                userInfo.status === "approved" &&
                <button className='user_deactive_btn'
                        onClick={() => handleDeactivate(id)}>Deactivate</button>
              }
              {
                (userInfo.status === "rejected" || userInfo.status === "deleted") &&
                <button className='coach_edit_btn_approve'
                        onClick={() => handleApproval(id)}>Approve</button>
              }

            </div>
          </div>
        }
      </div>


      {/* Modal's */}
      {
        isOpenModal && (
          <RejectModal
            setIsOpenModal={setIsOpenModal}
            isOpenModal={isOpenModal}
            id={id}
            handleRejection={handleRejection}
          />
        )
      }

    </LoggedInLayout>
  )
}

export default OrganisationProfile;
