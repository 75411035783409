import React from 'react';
import { useNavigate } from "react-router-dom";

import '../community.css';

import Giving from "../../../assets/Giving.png";
import Caring from "../../../assets/Moving.png";
import Relation from "../../../assets/Relation.png";
import Communication from "../../../assets/Communication.png";
const Connecting = () => {

    const navigate = useNavigate();

    return (
        <>
            <div className='row m-0 mt-4'>
                <div className='col-12 p-0'>
                    <p className=' mb-0 feeling_destination_heading resp_sub_heading'>Connecting Well</p>
                </div>
            </div>

            <div className='row m-0 mt-2'>
                <div className='col-12 p-0 pe-5'>
                    <p className='mb-0 feeling_destination_pera resp_sub_info'> Establishing valuable work-life relationships, listening and communicating well, and showing care for your community and environment.</p>
                </div>
            </div>

            <div className='row m-0 mt-4'>
                <div className='col p-0'>

                    <div className='row m-0'>
                        <div className='col p-0'>
                            
                            <div class="accordion feeling_destination_accor" id="accordionExample1">
                                <div class="accordion-item feeling_destination_accor_item">
                                    
                                    <h2 class="accordion-header feeling_destination_accor_header" id="headingOne1">
                                        <button class="accordion-button feeling_destination_accor_btn ps-3 ps-md-4 ps-lg-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                            
                                            <div className='d-flex align-items-center justify-content-start'>
                                                <div className='wbd_accordian_img d-flex align-items-center justify-content-center' >
                                                    <img src={Giving} alt='' />
                                                </div>
                                                <p className='mb-0 ms-4 feeling_destination_accor_name'>Giving</p>
                                            </div>

                                        </button>
                                    </h2>

                                    <div id="collapseOne1" class="accordion-collapse collapse" aria-labelledby="headingOne1" data-bs-parent="#accordionExample1">
                                        <div class="accordion-body feeling_destination_accor_body ps-4 pe-4 pt-3 pb-3">
                                            <div className='row m-0'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera'>Boosting total well-being by focusing on what you have to offer to others from small everyday acts to larger commitments.</p>
                                                </div>
                                            </div>
                                            {/* <div className='row m-0 mt-2'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera_2'><span className='feeling_destination_accor_pera_2_span'>Interests:</span> Social Acceptance, Shared beliefs and goals, Culture and traditions</p>
                                                </div>
                                            </div> */}
                                            <div className='row m-0 mt-3'>
                                                <div className='col-12 d-flex align-items-center justify-content-center'>
                                                    <button className='feeling_destination_accor_button' onClick={() => { navigate('/communitytab/connecting') }}>Find me experiences like these</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='row m-0 mt-1'>
                        <div className='col p-0'>
                            
                            <div class="accordion feeling_destination_accor" id="accordionExample2">
                                <div class="accordion-item feeling_destination_accor_item">
                                    
                                    <h2 class="accordion-header feeling_destination_accor_header" id="headingOne2">
                                        <button class="accordion-button feeling_destination_accor_btn ps-3 ps-md-4 ps-lg-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2" aria-expanded="true" aria-controls="collapseOne2">
                                            
                                            <div className='d-flex align-items-center justify-content-srtat'>
                                                <div className='wbd_accordian_img d-flex align-items-center justify-content-center' >
                                                    <img src={Caring} alt='' />
                                                </div>
                                                <p className='mb-0 ms-4 feeling_destination_accor_name'>Relating</p>
                                            </div>

                                        </button>
                                    </h2>

                                    <div id="collapseOne2" class="accordion-collapse collapse" aria-labelledby="headingOne2" data-bs-parent="#accordionExample2">
                                        <div class="accordion-body feeling_destination_accor_body ps-4 pe-4 pt-3 pb-3">
                                        <div className='row m-0'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera'>Adding value to life by building meaningful relationships with people at home, work and community.</p>
                                                </div>
                                            </div>
                                            {/* <div className='row m-0 mt-2'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera_2'><span className='feeling_destination_accor_pera_2_span'>Interests:</span> Social Acceptance, Shared beliefs and goals, Culture and traditions</p>
                                                </div>
                                            </div> */}
                                            <div className='row m-0 mt-3'>
                                                <div className='col-12 d-flex align-items-center justify-content-center'>
                                                    <button className='feeling_destination_accor_button' onClick={() => { navigate('/communitytab/functioning') }}>Find me experiences like these</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                  
                    <div className='row m-0 mt-1'>
                        <div className='col p-0'>
                            
                            <div class="accordion feeling_destination_accor" id="accordionExample3">
                                <div class="accordion-item feeling_destination_accor_item">
                                    
                                    <h2 class="accordion-header feeling_destination_accor_header" id="headingOne3">
                                        <button class="accordion-button feeling_destination_accor_btn ps-3 ps-md-4 ps-lg-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne3" aria-expanded="true" aria-controls="collapseOne3">
                                            <div className='d-flex align-items-center justify-content-srtat'>
                                                <div className='wbd_accordian_img d-flex align-items-center justify-content-center' >
                                                    <img src={Relation} alt='' />
                                                </div>
                                                <p className='mb-0 ms-4 feeling_destination_accor_name'>Caring</p>
                                            </div>
                                        </button>
                                    </h2>
                                    
                                    <div id="collapseOne3" class="accordion-collapse collapse" aria-labelledby="headingOne3" data-bs-parent="#accordionExample3">
                                        <div class="accordion-body feeling_destination_accor_body ps-4 pe-4 pt-3 pb-3">
                                        <div className='row m-0'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera'>Cultivating your well-being by building interconnections to your community and environment through care and compassion.</p>
                                                </div>
                                            </div>
                                            {/* <div className='row m-0 mt-2'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera_2'><span className='feeling_destination_accor_pera_2_span'>Interests:</span> Social Acceptance, Shared beliefs and goals, Culture and traditions</p>
                                                </div>
                                            </div> */}
                                            <div className='row m-0 mt-3'>
                                                <div className='col-12 d-flex align-items-center justify-content-center'>
                                                    <button className='feeling_destination_accor_button'onClick={() => { navigate('/communitytab/connecting') }}>Find me experiences like these</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                

                    <div className='row m-0 mt-1'>
                        <div className='col p-0'>
                            
                            <div class="accordion feeling_destination_accor" id="accordionExample4">
                                <div class="accordion-item feeling_destination_accor_item">
                                    
                                    <h2 class="accordion-header feeling_destination_accor_header" id="headingOne4">
                                        <button class="accordion-button feeling_destination_accor_btn ps-3 ps-md-4 ps-lg-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne4" aria-expanded="true" aria-controls="collapseOne4">
                                            <div className='d-flex align-items-center justify-content-srtat'>
                                                <div className='wbd_accordian_img d-flex align-items-center justify-content-center' >
                                                    <img src={Communication} alt='' />
                                                </div>
                                                <p className='mb-0 ms-4 feeling_destination_accor_name'>Communicating</p>
                                            </div>
                                        </button>
                                    </h2>

                                    <div id="collapseOne4" class="accordion-collapse collapse show" aria-labelledby="headingOne4" data-bs-parent="#accordionExample4">
                                        <div class="accordion-body feeling_destination_accor_body ps-4 pe-4 pt-3 pb-3">
                                        <div className='row m-0'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera'>Creating positive internal and external dialogues, better listening skills and an understanding of communication gaps.</p>
                                                </div>
                                            </div>
                                            {/* <div className='row m-0 mt-2'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera_2'><span className='feeling_destination_accor_pera_2_span'>Interests:</span> Social Acceptance, Shared beliefs and goals, Culture and traditions</p>
                                                </div>
                                            </div> */}
                                            <div className='row m-0 mt-3'>
                                                <div className='col-12 d-flex align-items-center justify-content-center'>
                                                    <button className='feeling_destination_accor_button'onClick={() => { navigate('/communitytab/connecting') }}>Find me experiences like these</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Connecting