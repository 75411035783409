import React, {useState} from 'react';
import './onboard.css'
import {useNavigate} from "react-router-dom";
import GrowCommunity from '../../../assets/GrowCommunity.png';
import SkipFirst from '../../../assets/SkipFirst.png';
import SkipSecond from '../../../assets/SkipSecond.png';
import SkipThird from '../../../assets/SkipThird.png';
import {updateUser} from '../../../utils/apiUrlsSetup';
import Slider from 'react-touch-drag-slider';
import _ from 'lodash';
import {HOME} from "../../../config/ROUTES";

const sliderData = [
    {
        iconName: GrowCommunity,
        title: "Grow with a community",
        subTitle: "Attend Live group sessions or 1-on-1 consults with a well-being supporter, and find people with whom you can share your story.",
    },
    {
        iconName: GrowCommunity,
        title: "Share your well-being gifts",
        subTitle: `Give an hour of your time to listen to, mentor or do something kind for others, or choose a Well-being Gift for yourself and watch your energy bank grow!`,
    },
    {
        iconName: GrowCommunity,
        title: "Explore your inner Well-being journey",
        subTitle: "Challenge yourself with daily tasks, build new habits, or embark on a quest for lifelong knowledge and skills in well-being.",
    }
]

const Onboard = () => {
    const navigate = useNavigate();
    const [index, setIndex] = useState(0);

    const userDetails = _.isEmpty(localStorage.getItem("userDetails")) ? {} : JSON.parse(localStorage.getItem("userDetails"));

    if (_.isEmpty(userDetails)) {
        navigate('/login');
    }

    const handleSubmit = () => {
        localStorage.setItem("accessToken", userDetails.accessToken);
        updateUser(
            {
                firstTime: false
            },
            userDetails.accessToken
        ).then(response => {
            if (response.statusCode === 200) {
                navigate(HOME)
            }
        })
    }

    const next = () => {
        if (index < sliderData.length - 1) setIndex(index + 1);
        if (index > 1) {
            handleSubmit();
        }
    }

    const previous = () => {
        setIndex(prev => Math.max(prev - 1, 0))
    }

    return <>
        <div className="m-0 slider_onboard onboard_screen vh-100 position-relative">
            <Slider
                onSlideComplete={(i) => {
                    setIndex(i);
                }}
                activeIndex={index}
                threshHold={100}
                transition={0.5}
                scaleOnDrag={false}
            >
                {sliderData.map((v, i) => {
                    return <div className='row m-0 pb-5' key={i}>
                        <div className='col-12 pb-5'>
                            <div className="container">
                                <div className='row m-0'>
                                    <div
                                        className='col-12 p-0 d-flex flex-column justify-content-center align-items-center tw-max-w-[700px]'>
                                        <img src={v.iconName}
                                             className={"object-fit-contain tw-object-center mx-auto my-3 my-md-0 ps-3"}
                                             alt=''/>

                                        <h1 className='my-4 fs-3 montserrat fw-bold text-center tw-text-[#54BFED]'>{v.title}</h1>
                                        <p className='mt-2 fs-6 tw-font-[500] montserrat lh-base tw-text-[#410DB7] text-center'>{v.subTitle}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </Slider>

            <div className={"position-absolute tw-bottom-[32px] w-100 start-0"}>
                <div className="container">
                    <div className='row'>
                        <div className='col-4 p-0 d-flex flex-column justify-content-center align-items-center'>
                            {
                                index === 0 ?
                                    <p className='text-secondary mb-0 fw-bold inter tw-cursor-pointer'
                                       onClick={handleSubmit}>
                                        Skip
                                    </p>
                                    :
                                    <button className='onboard_next_btn' onClick={previous}>Back</button>
                            }
                        </div>
                        <div className='col-4 p-0 d-flex flex-column justify-content-center align-items-center'>
                            {index === 0 &&
                                <img src={SkipFirst} alt=''/>
                            }
                            {index === 1 &&
                                <img src={SkipSecond} alt=''/>
                            }
                            {index === 2 &&
                                <img src={SkipThird} alt=''/>
                            }
                        </div>
                        <div className='col-4 p-0 d-flex flex-column justify-content-center align-items-center'>
                            <button className='onboard_next_btn' onClick={next}>Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
        ;
}

export default Onboard;
