import './AboutUsFounderCard.css'

/**
 *
 * @param {{
 *     className: string,
 *     backgroundImage: string | {},
 *     text: string
 * }} props
 * @returns {JSX.Element}
 * @constructor
 */
const AboutUsFounderCard = (props) => {
    return (
        <>
            <div className="flip-card founder_box tw-aspect-square w-100" tabIndex="0">
                <div className="flip-card-inner">
                    <div className={`flip-card-front ${props.className}`} style={{
                        backgroundImage: `url(${props.backgroundImage})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center center"
                    }}>

                    </div>
                    <div className="flip-card-back">
                        <div className="container">
                            <p className='inter tw-text-sm'>{props.text}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUsFounderCard