import {useNavigate} from 'react-router';

import "../../css/expert.css";

import BlueLeft from "../../assets/svg/BlueLeft.svg";
import OpenInNew from "../../assets/svg/OpenInNew.svg";
import FrameLocation from "../../assets/svg/FrameLocation.svg";
import LikeButton from "../../assets/svg/LikeButton.svg";
import ExpertRatings from "../../assets/svg/ExpertRatings.svg";
import FrameProfilePic from "../../assets/FrameProfilePic.png";
import moment from "moment";

function ExpertsFrame() {

  const navigate = useNavigate()

  return (
    <>
      <div className="row m-0">
        <div className="col-12 p-0 pt-3 pb-3 frame_header">

          <div className="row m-0">
            <div className="col-1 p-0 d-flex align-items-center justify-content-center">
              <img src={BlueLeft} alt=""/>
            </div>

            <div className="col-4 p-0"></div>

            <div className="col-3 p-0 pe-2 d-flex align-items-center justify-content-end">
              <p className="mb-0 open_in_new_window">Open profile in a new window <img src={OpenInNew} alt=""/></p>
            </div>
          </div>

        </div>
      </div>

      <div className="row m-0">
        <div className="col-12 p-5 frame_background_color">

          <div className="row m-0">
            <div className="col-12 p-0 frame_box">

              <div className="row m-0 p-4 frame_bottom_border">
                <div className="col-2 p-0 d-flex align-items-center">

                  <div className="frame_profile_pic">
                    <img src={FrameProfilePic} alt=""/>
                  </div>

                </div>

                <div className="col-5 p-0 d-flex flex-column justify-content-center ">
                  <p className="mb-0 frame_user_name">Syafiqah Salim</p>
                  <p className="mb-0 mt-2 frame_user_location"><img src={FrameLocation}
                                                                    alt=""/> Singapore &bull; {moment().hour()}:{moment().minutes()} local
                    time</p>
                </div>

                <div className="col-5 p-0 d-flex align-items-start justify-content-around">
                  {/* <img className="cr_pointer" config={MoreButton} alt=" " /> */}
                  <button className="frame_btn active_frame_btn" data-bs-toggle="modal"
                          data-bs-target="#slotBookModal">Book
                  </button>
                  <button className="frame_btn">Chat</button>
                  <img className="cr_pointer" src={LikeButton} alt=" "/>
                </div>
              </div>

              <div className="row m-0">
                <div className="col-4 p-0 frame_right_border">

                  <div className="row m-0 p-4 frame_bottom_border">
                    <div className="col-8 p-0">
                      <p className="mb-0 frame_total_hours_count">200+</p>
                      <p className="mb-0 frame_total_hours_head">Total Hours</p>
                    </div>
                    <div className="col-4 p-0 ps-4">
                      <p className="mb-0 frame_total_hours_count">40</p>
                      <p className="mb-0 frame_total_hours_head">Sessions</p>
                    </div>
                  </div>

                  <div className="row m-0">
                    <div className="col-12 p-4">
                      <p className="mb-0 frame_desc_head">Bio</p>
                      <p className="mb-0 mt-3 frame_desc_info">I’m a speech therapist with over 10 years’ of experience
                        helping my clients overcome speech impediments.</p>

                      <p className="mb-0 mt-5 frame_desc_head">Education</p>
                      <p className="mb-0 mt-3 frame_desc_mid_head">King’s College, London, UK</p>
                      <p className="mb-0 frame_desc_info">Master’s in Psychology <br/> Speech Impediments</p>

                      <p className="mb-0 mt-4 frame_desc_head">NUS</p>
                      <p className="mb-0 mt-3 frame_desc_info">Bachelor’s of Arts <br/> Majoring in Psychology</p>
                    </div>
                  </div>

                </div>

                <div className="col-8 p-0">

                  <div className="row m-0 p-4 frame_bottom_border">
                    <div className="col-12 p-0">
                      <p className="mb-0 frame_extra_bold_text">Speech Therapist</p>
                      <p className="mb-0 mt-4 frame_extra_light_text">I’m a speech therapist with over 10 years’ of
                        experience helping my clients overcome speech impediments. Like the movie The King’s Speech, I
                        help top professionals become more confident in themselves.</p>
                      <p className="mb-0 mt-2 frame_extra_light_text">Well-being is also tied to self-confidence, and as
                        a therapist, I have seen my clients grow from hestitant individuals to highly creative and
                        expressive professionals through my programme.</p>
                    </div>
                  </div>

                  <div className="row m-0 p-4">
                    <div className="col-12 p-0">

                      <div className="row m-0">
                        <div className="col-3 p-0">
                          <p className="mb-0 frame_review_head">Reviews (80)</p>
                        </div>
                        <div className='col-2 p-0 d-flex align-itmes-center justify-content-between'>
                          <img className="rating_stars" src={ExpertRatings} alt=''/>
                          <img className="rating_stars" src={ExpertRatings} alt=''/>
                          <img className="rating_stars" src={ExpertRatings} alt=''/>
                          <img className="rating_stars" src={ExpertRatings} alt=''/>
                          <img className="rating_stars" src={ExpertRatings} alt=''/>
                        </div>
                      </div>

                      <div className="row m-0 mt-4">
                        <div className="col-12 p-0">

                          <div className="row m-0">

                            <div className="col-1 p-0">

                              <div className="frame_review_user_pic">
                                <img src="" alt=""/>
                              </div>

                            </div>
                            <div className='col-2 p-0 ps-1 pe-4'>

                              <p className="mb-0 review_user_name">Brian Thio</p>

                              <div className="mt-1 d-flex align-itmes-center justify-content-between">
                                <img className="rating_stars" src={ExpertRatings} alt=''/>
                                <img className="rating_stars" src={ExpertRatings} alt=''/>
                                <img className="rating_stars" src={ExpertRatings} alt=''/>
                                <img className="rating_stars" src={ExpertRatings} alt=''/>
                              </div>

                            </div>

                          </div>

                          <div className="row m-0 mt-2">

                            <div className="col-12 p-0">
                              <p className="mb-0 frame_review">Syafiqah had a great attitude and was very friendly to
                                me, however, the therapy sessions might have been better structured...</p>
                            </div>

                          </div>

                        </div>

                      </div>

                      <div className="row m-0 mt-4">
                        <div className="col-12 p-0">

                          <div className="row m-0">

                            <div className="col-1 p-0">

                              <div className="frame_review_user_pic">
                                <img src="" alt=""/>
                              </div>

                            </div>
                            <div className='col-2 p-0 ps-1 pe-4'>

                              <p className="mb-0 review_user_name">Brian Thio</p>

                              <div className="mt-1 d-flex align-itmes-center justify-content-between">
                                <img className="rating_stars" src={ExpertRatings} alt=''/>
                                <img className="rating_stars" src={ExpertRatings} alt=''/>
                                <img className="rating_stars" src={ExpertRatings} alt=''/>
                                <img className="rating_stars" src={ExpertRatings} alt=''/>
                              </div>

                            </div>

                          </div>

                          <div className="row m-0 mt-2">

                            <div className="col-12 p-0">
                              <p className="mb-0 frame_review">Syafiqah had a great attitude and was very friendly to
                                me, however, the therapy sessions might have been better structured...</p>
                            </div>

                          </div>

                        </div>

                      </div>

                      <div className="row m-0 mt-5 mb-5">
                        <div className="col-8 p-0 pe-5 d-flex align-items-center justify-content-end">
                          <p className="mb-0 view_more_reviews cr_pointer">View more reviews</p>
                        </div>
                        <div className="col-4 p-0 d-flex align-items-center justify-content-end">
                          <button className="add_a_review_btn">Add A Review</button>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>


              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default ExpertsFrame;