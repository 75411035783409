import React from 'react';

import ConnectGiving from "../../assets/ConnectGiving.png";
import FuncCommunication from "../../assets/FuncCommunication.png";
import ConnectCaring from "../../assets/ConnectCaring.png";
import ConnectRelationship from "../../assets/ConnectRelationship.png";
import FFCPageTemplate from "../../component/FFCPageTemplate";

export default function Connecting() {
    return <FFCPageTemplate
        title={"Connecting Well"}
        description={"Establishing relationships and showing care for the community & environment."}
        items={[
            {
                title: "Giving",
                img: ConnectGiving,
                text: [
                    "Boosting total well-being by",
                    "focusing on what you have to",
                    "offer to others from small",
                    "everyday acts",
                    "to larger commitments."
                ]
            },
            {
                title: "Relating",
                img: ConnectRelationship,
                text: [
                    "Adding value to life by",
                    "building meaningful relationships",
                    "with people at home, work and community.",
                ]
            },
            {
                title: "Caring",
                img: ConnectCaring,
                text: [
                    "Cultivating your well-being by",
                    "building interconnections",
                    "to your community and",
                    "environment through",
                    "care and compassion."
                ]
            },
            {
                title: "Communicating",
                img: FuncCommunication,
                text: [
                    "Creating positive",
                    "internal and external",
                    "dialogues, better listening",
                    "skills and an understanding",
                    "of communication gaps.",
                ]
            }
        ]}
    />
}