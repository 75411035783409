import axios from "axios"
import {API_BASE_URL} from "./apiUrlsSetup";

/**
 * @param overidingToken
 * @param headers
 * @return {AxiosInstance}
 */
export function getAxiosInstance(overidingToken = null, headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
}) {
    if (overidingToken) {
        headers['Authorization'] = overidingToken;
    } else {
        headers['Authorization'] = `Bearer ${localStorage.getItem("accessToken") ?? null}`
    }

    return axios.create({
        headers: headers,
        baseURL: API_BASE_URL
    });
}