import { ADD_TOUR_DATA } from './../../types/types';

export const tourData = (state = {}, action) => {
    switch (action.type) {
        case ADD_TOUR_DATA:
            return {
                ...state,
                tourData: action.payload
            }
        default: return state
    }

}