import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import EmotionsEmoji from "../../assets/svg/EmotionsEmoji.svg";
import CommunityFilter from "../../assets/svg/CommunityFilter.svg";
import CommunityBan from "../../assets/svg/CommunityBan.svg";
import CommunityAddBtn from "../../assets/svg/CommunityAddBtn.svg";
import SmGiving from "../../assets/SmGiving.png";
import SmWorking from "../../assets/SmWorking.png";
import SmLearning from "../../assets/SmLearning.png";
import SmNoticing from "../../assets/SmNoticing.png";
import SmMeaning from "../../assets/SmMeaning.png";
import SmBreathing from "../../assets/svg/SmBreathing.svg";
import SmMoving from "../../assets/svg/SmMoving.svg";
import Belonging from "../../assets/Belonging.png";
import SmCommunicating from "../../assets/SmCommunicating.png";
import Relation from "../../assets/Relation.png";

import {getEventData, updateEventbyAdmin} from "../../utils/apiUrlsSetup";

import CreateEventModal from "../newsFeed/component/create_event_modal";

import moment from "moment";
import LoggedInLayout from "../../component/layout/LoggedInLayout.tsx";
import PageHeader from "../../component/PageHeader";

const Experiences = () => {
    const navigate = useNavigate();

    const [tab, setTab] = useState(1);

    const [events, setEvents] = useState([]);

    const [filterEvents, setFilterEvents] = useState([]);

    const [isOpenModalEvent, setIsOpenModalEvent] = useState(false);

    const [expCatFilter, setExpCatFilter] = useState("all");

    const [showEventDelete, setShowEventDelete] = useState(false);

    const [eventDetails, setEventDetails] = useState({});

    useEffect(() => {
        getEvents();
    }, []);

    const getEvents = async () => {
        const response = await getEventData();
        if (response && response.status === 200) {
            setEvents(response.data.data.eventArray);
            if (tab === 1) {
                var filteredEvents = response.data.data.eventArray.filter(
                    (event) =>
                        (new Date(event.startDate).toDateString() ===
                            new Date().toDateString() ||
                            new Date(event.startDate) < new Date()) &&
                        (new Date(event.endDate).toDateString() ===
                            new Date().toDateString() ||
                            new Date(event.endDate) > new Date())
                );
                setFilterEvents(filteredEvents);
            } else if (tab === 2) {
                const filteredEvents = response.data.data.eventArray.filter(
                    (event) => new Date(event.startDate) > new Date()
                );
                setFilterEvents(filteredEvents);
            } else {
                const filteredEvents = response.data.data.eventArray.filter(
                    (event) => new Date(event.startDate) < new Date()
                );
                setFilterEvents(filteredEvents);
            }
        }
    };

    const handleTab = (num) => {
        if (num === 1) {
            const filteredEvents = events.filter(
                (event) =>
                    (new Date(event.startDate).toDateString() ===
                        new Date().toDateString() ||
                        new Date(event.startDate) < new Date()) &&
                    (new Date(event.endDate).toDateString() ===
                        new Date().toDateString() ||
                        new Date(event.endDate) > new Date())
            );
            setFilterEvents(filteredEvents);
            setTab(num);
        } else if (num === 2) {
            const filteredEvents = events.filter(
                (event) => new Date(event.startDate) > new Date()
            );
            setFilterEvents(filteredEvents);
            setTab(num);
        } else {
            const filteredEvents = events.filter(
                (event) => new Date(event.startDate) < new Date()
            );
            setFilterEvents(filteredEvents);
            setTab(num);
        }
    };

    const getExpCatFilter = async (data) => {
        return [];
    };

    const deleteEvent = async (id) => {
        const response = await updateEventbyAdmin(id);
        if (response.status === 200) {
            setShowEventDelete(false);
            getEvents();
        }
    }

    return (
        <LoggedInLayout title={"Experiences"}>
            <img
                onClick={() => setIsOpenModalEvent(true)}
                className="position-fixed tw-right-[1vw] tw-bottom-[8vh] tw-cursor-pointer"
                src={CommunityAddBtn}
                alt=""
            />

            <CreateEventModal
                isOpenModal={isOpenModalEvent}
                handleModal={() => {
                    setIsOpenModalEvent(false);
                }}
                setEventData={(data) => {
                    getEvents();
                    setIsOpenModalEvent(false);
                }}
            />

            <div className="d-flex flex-column gap-3">
                <PageHeader title={"Experiences"}/>

                <div className="px-0 px-md-5">
                    <div className="bg-white w-100 row tw-h-[44px] tw-rounded-[44px]">
                        <button
                            onClick={() => handleTab(1)}
                            className={`col-4 tw-text-sm comm_btn_transparent ${tab === 1 ? "act_comm_btn_transparent" : ""
                            }`}
                        >
                            Happening now
                        </button>
                        <button
                            onClick={() => handleTab(2)}
                            className={`col-4 tw-text-sm comm_btn_transparent ${tab === 2 ? "act_comm_btn_transparent" : ""
                            }`}
                        >
                            On schedule
                        </button>
                        <button
                            onClick={() => handleTab(3)}
                            className={`col-4 tw-text-sm comm_btn_transparent ${tab === 3 ? "act_comm_btn_transparent" : ""
                            }`}
                        >
                            History
                        </button>
                    </div>
                </div>

                <div
                    className={"d-flex align-items-center flex-wrap justify-content-lg-start gap-2 gap-lg-3 justify-content-center"}>
                    {[
                        {
                            id: "all",
                            label: "all"
                        },
                        {
                            id: "breathing",
                            img: SmBreathing,
                            label: "Breathing"
                        },
                        {
                            id: "moving",
                            img: SmMoving,
                            label: "Moving"
                        },
                        {
                            id: "acknowledging",
                            img: EmotionsEmoji,
                            label: "Acknowledging"
                        },
                        {
                            id: "belonging",
                            img: Belonging,
                            label: "Belonging"
                        },
                        {
                            id: "working",
                            img: SmWorking,
                            label: "Working"
                        },
                        {
                            id: "learning",
                            img: SmLearning,
                            label: "Learning"
                        },
                        {
                            id: "noticing",
                            img: SmNoticing,
                            label: "Noticing"
                        },
                        {
                            id: "meaning",
                            img: SmMeaning,
                            label: "Meaning"
                        },
                        {
                            id: "giving",
                            img: SmGiving,
                            label: "Giving"
                        },
                        {
                            id: "relating",
                            img: SmMoving,
                            label: "Relating"
                        },
                        {
                            id: "caring",
                            img: Relation,
                            label: "Caring"
                        },
                        {
                            id: "communicating",
                            img: SmCommunicating,
                            label: "Communicating"
                        }
                    ].map((e, i) => {
                        return (
                            <button
                                key={i}
                                className={`flex-lg-grow-0 flex-grow-1 emoji_btns my-0 d-flex align-items-center gap-2 emoji_btns_all exp_category_btn px-3 ${expCatFilter === e.id ? "active" : ""}`}
                                onClick={() => setExpCatFilter(e.id)}
                            >
                                {e.img &&
                                    <img src={e.img} alt=""/>
                                }
                                {e.label}
                            </button>
                        )
                    })}
                </div>

                <div className={"d-none d-md-block"}>
                    <div
                        className={`community_super_admin_tbl d-flex align-items-center justify-content-start`}>
                        <div
                            className={`${tab !== 2 ? 'table_thead_width tbl_head' : 'tbl_head'} ps-lg-5 ps-4 py-3`}>
                            Event Name{" "}
                            <img className="ms-2" src={CommunityFilter} alt=""/>{" "}
                        </div>
                        <div
                            className={`${tab !== 2 ? 'table_thead_width tbl_head' : 'tbl_head'} ps-lg-5 ps-4 py-3`}>
                            Hosted by{" "}
                            <img className="ms-2" src={CommunityFilter} alt=""/>{" "}
                        </div>
                        <div
                            className={`${tab !== 2 ? 'table_thead_width tbl_head' : 'tbl_head'} ps-lg-5 ps-4 py-3`}>
                            Date <img className="ms-2" src={CommunityFilter} alt=""/>{" "}
                        </div>
                        <div
                            className={`${tab !== 2 ? 'table_thead_width tbl_head' : 'tbl_head'} ps-lg-5 ps-4 py-3`}>
                            Participants{" "}
                            <img className="ms-2" src={CommunityFilter} alt=""/>{" "}
                        </div>
                        <div
                            className={`${tab !== 2 ? 'table_thead_width tbl_head' : 'tbl_head'} tbl_head_55 tbl_head_55_ct ps-lg-5 ps-4 py-3`}>
                            Category <img className="ms-2" src={CommunityFilter} alt=""/>{" "}
                        </div>
                        {tab === 2 ? (
                            <div className="tbl_head tbl_head ps-lg-5 ps-4 py-3">Action</div>
                        ) : null}
                    </div>

                    {!filterEvents.filter((v) =>
                        expCatFilter === "all" ? v : v.category.includes(expCatFilter.charAt(0).toUpperCase() + expCatFilter.slice(1))
                    ).length ? (
                        <p className="text-center my-5">No record found</p>
                    ) : null}

                    {filterEvents
                        .filter((v) =>
                            tab === 1 ? moment(moment().toISOString()).isBetween(v.startDate, v.endDate) : v
                        )
                        .filter((v) =>
                            expCatFilter === "all" ? v : v.category.includes(expCatFilter.charAt(0).toUpperCase() + expCatFilter.slice(1))
                        )
                        .map((event) => {
                            return (
                                <div className="community_super_admin_tbl d-flex align-items-center">
                                    <div
                                        className={`${tab !== 2 ? 'table_row_cmn_width' : 'tbl_head_11'} tbl_head ps-lg-5 ps-4 event_nm py-3 cursor_pointer`}
                                        onClick={() =>
                                            navigate(`/event/${event.id}`, {state: event})
                                        }>
                                        {event.name}
                                    </div>
                                    <div
                                        className={`${tab !== 2 ? 'table_row_cmn_width' : 'tbl_head_11'} tbl_head tbl_head_22 ps-lg-5 ps-4 event_hst_by py-3`}>
                                        {`${event.createdByUser.firstName} ${event.createdByUser.lastName}`}
                                    </div>
                                    <div
                                        className={`${tab !== 2 ? 'table_row_cmn_width' : 'tbl_head_11'} tbl_head tbl_head_33 ps-lg-5 ps-4 event_dt py-3`}>
                                        {moment(event.startDate).utc().format("MMM DD, YYYY")}
                                    </div>
                                    <div className="tbl_head tbl_head_44 ps-lg-5 ps-4 event_prtc py-3">
                                        {event.participants.length > 1
                                            ? `${event.participants.length} participants`
                                            : `${event.participants.length} participant`}
                                    </div>
                                    <div
                                        className="tbl_head tbl_head_55 ps-lg-5 ps-4 py-3 event_arr_table tbl_head_55_ct">
                                        {" "}
                                        {event.category.map((v, i) => {
                                            return (
                                                <button key={i} className="event_cat me-1 mt-1">
                                                    {v.charAt(0).toUpperCase() + v.slice(1)}
                                                </button>
                                            );
                                        })}
                                    </div>
                                    {tab === 2 ? (
                                        <div
                                            className="tbl_head tbl_head_66 ps-lg-5 ps-4 py-3"
                                            onClick={() => {
                                                setEventDetails(event);
                                                setShowEventDelete(true);
                                            }
                                                // navigate(`/event/${event.id}`, { state: event })
                                            }
                                        >
                                            {" "}
                                            <img
                                                className="ms-2 cursor_pointer"
                                                src={CommunityBan}
                                                alt=""
                                            />{" "}
                                        </div>
                                    ) : null}
                                </div>
                            );
                        })}
                </div>

                <div className="container">
                    <div className="d-flex gap-3 flex-column d-md-none">
                        {(filterEvents || []).map((event, i) => {
                            return <div className="row" key={i}>
                                <div className="col-12 p-0 exp_card_mobile">
                                    <div className="row m-0 align-items-center p-2">
                                        <div
                                            className="col-9 p-0 date">{moment(event.startDate).utc().format("MMM DD, YYYY")}</div>
                                        <div className="col-3 p-0 d-flex justify-content-end">
                                            <button className="event_cat me-1 mt-1">Learning</button>
                                        </div>
                                    </div>

                                    <div className="row m-0 label px-2 my-2">
                                        {event.description}
                                    </div>

                                    <div className="row m-0 by_team px-2 my-2">
                                        By Kaleidoscope Team
                                    </div>

                                    <div className="d-flex m-0 by_team px-2 my-2 justify-content-between me-2">

                                        <div className="d-flex align-items-center">{event.participants.map((v, i) => {
                                            return <div className="profile_icon p-0 pe-2" key={i}>
                                                <img src={v.user.profilePic ? v.user.profilePic : ""} alt=""/>
                                            </div>
                                        })}</div>

                                        {tab === 2 ? (
                                            <div
                                                className="tbl_head tbl_head_66 ps-4 py-3"
                                                onClick={() =>
                                                    navigate(`/event/${event.id}`, {state: event})
                                                }
                                            >
                                                {" "}
                                                <img
                                                    className="ms-2 cursor_pointer"
                                                    src={CommunityBan}
                                                    alt=""
                                                />{" "}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>

            <div
                className={`modal fade ${showEventDelete ? "show" : ""}`}
                style={{
                    display: showEventDelete ? "block" : "none",
                    background: showEventDelete ? "rgba(0,0,0,0.5)" : "",
                }}
                id="exampleModalCloseBtn"
                tabIndex="-1"
                aria-labelledby="exampleModalLabelReg3"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-centered modal-md">
                    <div class="modal-content">
                        <div className="row m-0">
                            <div className="col-12 p-0 ps-5 pe-5 pt-4 pb-4">
                                <div className="row m-0">
                                    <div className="col-12 p-0">
                                        <h4 className="mb-0 text-center">
                                            Are you sure?
                                        </h4>
                                    </div>
                                </div>
                                <div className="row m-0 my-4">
                                    <div className="col-12 p-0">
                                        <p className="mb-0 model_promt_text text-center">
                                            Your action will delete all of this event <br/>
                                            detail and cannot be undone.
                                        </p>
                                    </div>
                                </div>

                                <div className="row m-0 mt-3">
                                    <div className="col-2 p-0"></div>
                                    <div className="col-4 p-0 d-flex justify-content-start align-items-center pe-2">
                                        <button className="take_down_btn take_down_btn_outline"
                                                onClick={() => setShowEventDelete(false)}>Cancel
                                        </button>
                                    </div>
                                    <div className="col-4 p-0 d-flex justify-content-end align-items-center ps-2">
                                        <button className="take_down_btn"
                                                onClick={() => deleteEvent(eventDetails.id)}>Yes
                                        </button>
                                    </div>
                                    <div className="col-2 p-0"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoggedInLayout>
    );
};

export default Experiences;
