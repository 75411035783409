import {Link} from "react-router-dom";
import {MouseEventHandler} from "react";
import {useZStore} from "../utils/zStore";

export default function CreatePostBar(props: {onClick: MouseEventHandler<HTMLDivElement>}) {
  const userDetails = useZStore(state => state.userDetails);

  return (
    <div className={"d-flex bg-white tw-rounded-none lg:tw-rounded-2xl tw-py-2 tw-px-4 align-items-center tw-gap-1 lg:tw-gap-3 tw-cursor-text"}
         onClick={props.onClick}>

      {/* Avatar */}
      <Link to={"/profile"} className={"tw-cursor-pointer tw-flex-shrink-0"}>
        <img src={userDetails?.profilePic ? userDetails?.profilePic : "/static/png/Male.png"}
             className={"tw-h-[38px] lg:tw-h-[50px] tw-aspect-square object-fit-cover tw-object-center rounded-circle"}
             alt={"Profile Pic"}
        />
      </Link>

      {/* Create post bar */}
      <div
        className={"tw-text-opacity-60 tw-cursor-pointer hover:tw-bg-[#f4f4f4] tw-transition-all montserrat fw-semibold tw-text-sm border rounded-pill tw-w-full tw-px-3 lg:tw-px-6 tw-py-2"}>
        Share your well-being story here
      </div>
    </div>
  )
}