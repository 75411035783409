import React, { useState, useEffect } from 'react';

import '../community.css';

import UpcomingCalender from "../../../assets/svg/UpcomingCalender.svg";
import BellGray from "../../../assets/svg/BellGray.svg";
import BellActive from "../../../assets/svg/BellActive.svg";

import DP from '../../../assets/profile_photo.png';
import DP2 from '../../../assets/profile_photo2.png';
import DP3 from '../../../assets/profile_photo3.png';
import DP4 from '../../../assets/profile_photo4.png';
import Emoji5 from '../../../assets/Emoji5.png';
import { getEventData } from '../../../utils/apiUrlsSetup';
import moment from 'moment';
import { useNavigate } from "react-router-dom";

const UpcomingForYou = () => {

    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {};

    const navigate = useNavigate();

    const [eventList, setEventList] = useState([]);

    useEffect(() => {
        getEventsList();
    }, []);

    const getEventsList = async () => {
        const result = await getEventData();
        if (result.status === 200) {
            let data = result.data.data.eventArray.map(v => {
                return {
                    ...v,
                    participantsId : v.participants.map(v => v.userId)
                }
            })
            let eventData = data.filter(v => v.participantsId.includes(userDetails.id)).filter(v => moment(v.startDate).isAfter(`${moment().toISOString()}`));
            setEventList(eventData);
        }
    }

    const handleEvent = (id) => {
        navigate(`/event/${id}`)
    }

    return (
        <>
            <div className='row m-0 upcoming_for_you'>
                <div className='col-12 p-0'>

                    <div className='row m-0'>

                        <div className='col-10 p-0'>
                            <p className='mb-0 upcoming_pera mt-md-3'>Upcoming For You</p>

                            {/* <select class="form-select upcoming_select ps-0" aria-label="Default select example">
                                <option className='select_options' selected >Upcoming For You</option>
                                <option className='select_options' value="1">One</option>
                                <option className='select_options' value="2">Two</option>
                                <option className='select_options' value="3">Three</option>
                            </select> */}

                        </div>

                        <div className='col-2 p-0 d-flex align-items-center justify-content-end'>
                            {/* <img config={UpcomingCalender} alt='' /> */}
                        </div>

                    </div>

                    <div className='row m-0 mt-3 upcoming_huddle_box p-0 p-md-0 p-lg-2'>
                        <div className='col-12 p-0 px-2'>

                            <div className=''>
                                <div className=''>

                                    {/* <div className='row m-0 web_view'>
                                        <div className='col-12 p-0'>
                                            <button className='upcoming_huddle_button'>TODAY</button>
                                        </div>
                                    </div> */}

                                    <div className='row m-0 mt-3'>
                                        <div className='col-12 p-0'>

                                            {!eventList.length ? <p className="text-center my-4">No Upcoming Events found</p> : null}

                                            {eventList.map((v, i) => {
                                                return <div className='upcoming_work_box p-3 p-md-4 p-lg-2 cursor_pointer' onClick={() => navigate(`/event/${v.id}`)} key={i}>

                                                    <div className='row m-0'>

                                                        <div className='col-10 p-0'>
                                                            <p className='mb-0 upcoming_huddle_time'>{moment(v.startDate).utc().format("hh:mm a")}</p>
                                                        </div>

                                                        <div className='col-2 p-0 d-flex align-items-center justify-content-end'>
                                                            <img src={BellGray} alt='' onClick={() => handleEvent(v.id)} />
                                                        </div>

                                                    </div>

                                                    <div className='row m-0 mt-3'>
                                                        <div className='col-12 p-0'>
                                                            <p className='mb-0 upcoming_huddle_head'>{v.name}<br />{v.description}</p>
                                                        </div>
                                                    </div>

                                                    {/* <div className='row m-0 mt-0 mt-md-2 mt-lg-1'>
                                                        <div className='col-12 p-0'>
                                                            <p className='mb-0 upcoming_huddle_profile_detail'>
                                                                By &nbsp;
                                                                {v.participants.map((v, i) => {
                                                                    return <>
                                                                        {v.user.firstName} {v.user.lastName}, &nbsp;
                                                                    </>
                                                                })}
                                                            </p>
                                                        </div>
                                                    </div> */}

                                                    <div className='row m-0 mt-0 mt-md-2 mt-lg-1'>
                                                        <div className='col-12 p-0'>
                                                            <p className='mb-0 upcoming_huddle_profile_detail'>
                                                                By {v.createdByUser.firstName} {v.createdByUser.lastName}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className='row m-0 mt-2 mt-md-2 mt-lg-2'>
                                                        <div className='col-12 p-0 huddle_users_dp_section d-flex align-items-center'>
                                                            {v.participants.map((v, i) => {
                                                                return <div key={i} className='input_profile_img me-2'>
                                                                    <img src={v.user.profilePic ? v.user.profilePic : DP} />
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div>

                                                </div>
                                            })}

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    {/* <div className='row m-0 mt-4'>
                        <div className='col-12 p-0'>
                            <p className='mb-0 upcoming_huddle_room'>Take me to a random room today!</p>
                        </div>
                    </div>

                    <div className='row m-0 mt-4'>
                        <div className='col-12 p-0'>
                            <button className='upcoming_huddle_room_btn'><img config={Emoji5} alt='' /> &nbsp; Get me to a random room!</button>
                        </div>
                    </div> */}

                </div>
            </div>
        </>
    )
}

export default UpcomingForYou;