import React, {useEffect, useState} from 'react';
import '../container/newsFeed/newsFeeds/newsFeeds.css';
import Tutorial from "./Tutorial"
import {getEventData, getPostLike, getPosts} from '../utils/apiUrlsSetup'
import LikeProfileModal from '../component/modals/likeProfileModal/likeProfileModal';
import moment from 'moment';
import ModalCreatePost from "./ModalCreatePost";
import {Link, useNavigate} from "react-router-dom";
import Banner from "../container/newsFeed/component/banner";
import InfiniteScroll from "react-infinite-scroll-component";
import {PostCard} from "../container/newsFeed/component/post/PostCard";
import CreatePostBar from "./CreatePostBar.tsx";
import {subCategories} from "../utils/constant";
import FilterModal from "./modals/likeProfileModal/FilterModal";
import ModalEditPost from "./ModalEditPost";

const BUTTONS = [
  {
    id: "all",
    label: "All Posts",
  },
  ...subCategories.map(subCategory => ({
    id: subCategory,
    label: subCategory,
  })),
]

/**
 * @param {{
 *   organisationId?: number,
 *   keyword?: string,
 *   postId?: number
 * }} props
 * @return {Element}
 * @constructor
 */
const FeedsSubPage = (props) => {
  const navigate = useNavigate();

  /**
   * If false, hide modal
   * If 0, then create modal
   * if other than that, then share modal
   * @var {[false | 0 | number, Dispatch<SetStateAction<false | 0 | number>>]} createSharePostReferenceIdState
   */
  const createSharePostReferenceIdState = useState(false)
  const [createSharePostReferenceId, setCreateSharePostReferenceId] = createSharePostReferenceIdState;

  /**
   * If undefined, hide modal
   * if other than that, then show edit post modal
   * @var {[undefined | Post, Dispatch<SetStateAction<undefined | Post>>]} editPostState
   */
  const editPostState = useState()
  const [editPost, setEditPost] = editPostState;

  const [isFilterModalOpened, setIsFilterModalOpened] = useState(false);

  const [posts, setPosts] = useState([]);

  const [postCounts, setPostsCount] = useState("");

  const [limit, setLimit] = useState(15);

  const [postActiveTab, setPostActiveTab] = useState("all");

  const [showUserLikedList, setShowUserLikedList] = useState(false);

  const [listOfLikeUsers, setListOfLikedUsers] = useState([]);

  const [latestEvents, setLatestEvents] = useState([]);

  useEffect(() => {
    getAllPosts(limit)
    getLatestEvents()
  }, [postActiveTab]);

  const isDateInCurrentWeek = (dateToCheck, endDate) => {
    // Convert the date to the specified timezone

    // Get the start of the current week in the specified timezone
    const startOfWeek = new Date(moment().startOf('week')).toISOString();
    const currentDate = new Date().toISOString()
    // Get the end of the current week in the specified timezone

    // Check if the date falls within the current week
    return dateToCheck >= startOfWeek && endDate >= currentDate;
  }

  const getLatestEvents = async () => {
    const response = await getEventData();
    if (response && response.status === 200) {
      const events = response.data.data.eventArray.filter(e => isDateInCurrentWeek(e.startDate, e.endDate))
      setLatestEvents(events)
    }
  }

  const getAllPosts = async (limit) => {
    let response;
    if (props.postId) {
      response = await getPosts(props.postId)
    } else {
      response = await getPosts(null, limit, props.organisationId, props.keyword, postActiveTab === "all" ? null : postActiveTab);
    }
    if (response.status === 200 && response.data.data.postArray.length) {
      setPostsCount(response.data.data.count);
      setPosts(
        response.data.data.postArray.map((v) => {
          if (v.type === "document") {
            v.count = v.attachment.length
          }
          return v;
        })
      );
    }
  }

  const fetchMoreData = () => {
    setLimit(limit + 5);
    setTimeout(() => {
      getAllPosts(limit + 5);
    }, 1500);
  };

  async function mutate() {
    await getAllPosts(limit);
  }

  const getListOfLikedUsers = async (post) => {
    let response = await getPostLike(`postId=${post.id}`)
    if (response.status === 200) {
      if (response.data.data.length) {
        setShowUserLikedList(true)
      }
      setListOfLikedUsers(response.data.data);
    }
  }

  const afterSubmit = async () => {
    getAllPosts(limit);
  }

  return (
    <>
      <div className='tw-w-full tw-max-w-[600px] tw-mx-auto d-flex flex-column gap-3'>

        {(!props.keyword && !props.postId) && (
          <CreatePostBar onClick={() => {
            setCreateSharePostReferenceId(0)
          }}/>
        )}

        {(props.keyword || props.postId) && (
          <div className={"d-flex bg-white rounded-4 py-2 px-3 align-items-center gap-2 tw-cursor-default"}>

            {/* Avatar */}
            <Link to={"/notification"} className={"tw-cursor-pointer tw-flex-shrink-0"}>
              <i className="bi bi-arrow-left"/>
            </Link>

            {/* Create post bar */}
            <div
              className={"tw-text-opacity-60] tw-transition-all tw-cursor-default montserrat fw-semibold tw-text-sm tw-w-full"}>
              {props.keyword ?? ""}
            </div>
          </div>
        )}

        {!props.postId && (
          <div className={"montserrat tw-text-sm d-flex tw-items-center tw-justify-between gap-1 tw-px-4 lg:tw-px-0"}>
            <div>
              Currently showing
              {
                postActiveTab === "all" ?
                  <span className={"fw-bold tw-text-[#653ac6]"}> All Posts</span>
                  :
                  <> posts in <span className={"fw-bold tw-text-[#653ac6]"}>{postActiveTab}</span> category</>
              }
            </div>

            <i className="bi bi-filter-circle-fill tw-text-[#653ac6] tw-cursor-pointer"
               onClick={() => setIsFilterModalOpened(true)}/>
          </div>
        )}

        {/* Event Banner if there's any */}
        {
          (latestEvents.length > 0 && !props.postId && !props.keyword) && (
            <div className='d-flex newsfeeds_category'>
              <Banner huddleData={latestEvents} navigate={navigate}/>
            </div>
          )
        }

        {/* Then the infinite scroll */}
        <InfiniteScroll
          className={"d-flex flex-column lg:tw-gap-8 tw-gap-4"}
          dataLength={posts.length} //This is important field to render the next data
          next={fetchMoreData}
          hasMore={posts.length < postCounts}
          loader={posts.length < postCounts ?
            <h4 className="text-center my-4">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only"/>
              </div>
            </h4>
            :
            <></>
          }
          endMessage={
            !props.postId && (
              <p className={"text-center"}>
                <b>Yay! You have seen it all</b>
              </p>
            )
          }
        >
          {posts
            .filter(value => value.type !== "event")
            .map((post, index) => {
              return (
                /* This is the post represented as a card */
                <PostCard
                  key={post.id}
                  post={post}
                  mutate={mutate}
                  onEditButtonClicked={() => {
                    setEditPost(post)
                  }}
                  setShowUserLikedList={() => getListOfLikedUsers(post)}
                  sharePost={() => {
                    setCreateSharePostReferenceId(post.id)
                  }}
                />
              );
            })}
        </InfiniteScroll>
      </div>

      <ModalCreatePost
        organisationId={props.organisationId}
        referenceId={createSharePostReferenceId}
        afterSubmit={afterSubmit}
        toggle={() => {
          setCreateSharePostReferenceId(false);
        }}
      />

      <ModalEditPost
        organisationId={props.organisationId}
        post={editPost}
        afterSubmit={afterSubmit}
        toggle={() => {
          setEditPost(undefined);
        }}
      />

      <LikeProfileModal isOpen={showUserLikedList} setShowUserLikedList={() => setShowUserLikedList(false)}
                        listOfLikeUsers={listOfLikeUsers}/>

      <FilterModal
        isOpen={isFilterModalOpened}
        onChanged={(e) => {
          setIsFilterModalOpened(false)
          setPostActiveTab(e)
        }}
        active={postActiveTab}
        options={BUTTONS}
        close={() => {
          setIsFilterModalOpened(false)
        }}
      />
      <Tutorial/>
    </>
  )
}

export default FeedsSubPage;


