import React, { useState, useEffect } from 'react';

import Feeling from '../feeling/feeling';
import Connecting from '../connecting/connecting';
import Functioning from '../functioning/functioning';

import '../community.css';

import CommunityTabWeb from './communityTab-web';
import CommunityTabMobile from './communityTab-mobile';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash'

const Community_2 = () => {

    const [point, setPoints] = useState({});
    const [communityPage, setCommunityPage] = useState(1);

    const dispatch = useDispatch();
    const data = useSelector(state => state);

    const handleClick = (val) => {
        setCommunityPage(val);
    }

    useEffect(() => {
        if(_.isEmpty(data.points)){
            setPoints({});
        }else{
            setPoints(data.points.points);
        }
    }, [data.points]);

    return (
        <>

            <CommunityTabWeb

                communityPage={communityPage}
                handleClick={handleClick}
                point={point}

            />

            <CommunityTabMobile
             communityPage={communityPage}
             handleClick={handleClick}

            />


           

        </>

    )
}

export default Community_2;
