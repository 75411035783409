import AssociatedPhoto from "../../../assets/Male.png";
import CloseBtn from '../../../assets/Crossbtn.png';

const LikeProfileModal = (props) => {
  const list = (props.participantList && props.participantList.length) ? props.participantList : props.listOfLikeUsers
  return (
    <div
      className={`modal fade ${props.isOpen ? "show" : ""}`}
      style={{
        display: props.isOpen ? "block" : "none",
        background: props.isOpen ? "rgba(0,0,0,0.5)" : "",
      }}
      id="staticBackdrop2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content model_border_radius">
          <div className="modal-body p-4 d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex justify-content-between align-items-center w-100">
              <p className="model_bold_text mb-4">{props.participantList ? 'Participants' : 'Reactions'}</p>
              <p className="model_bold_text mb-4">
                <img src={CloseBtn} className="cursor_pointer"
                     onClick={() => props.participantList && props.participantList.length ? props.setParticipantsList([]) : props.setShowUserLikedList(false)}
                     alt=""/>
              </p>
            </div>

            <table className="table table-hover">
              <tbody>
              {list
                .map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className={"d-flex gap-2 tw-items-center"}>
                        <img
                          alt={"Avatar"}
                          src={v.user?.profilePic || AssociatedPhoto}
                          className="rounded-circle tw-w-[36px] tw-h-[36px]"
                        />

                        <p className="mb-0 inter">
                          {v.user ? <>{v.user.firstName} {v.user.lastName}</> : null}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LikeProfileModal;
