import React,{ useState }  from 'react';
import '../../css/questDetails.css';
import SideNav from '../../component/staticPages/SideNav';
import { useNavigate } from "react-router-dom"


import TimeExchangePocket from "../../assets/svg/TimeExchangePocket.svg";
import TimeExchangeEmoji from "../../assets/svg/TimeExchangeEmoji.svg";
import Dimond from "../../assets/svg/Dimond.svg";
import JourneyPeople from "../../assets/svg/JourneyPeople.svg";
import JourneyShare from "../../assets/svg/JourneyShare.svg";
import JourneyLike from "../../assets/svg/JourneyLike.svg";
import Journey_goal from "../../assets/svg/journey_goal.svg";
import Quest_Detail_img from "../../assets/Quest_Detail_img.png";
import Relation from '../../assets/Relation.png';
import Invite_profile_img1 from '../../assets/Invite_profile_img1.png';
import Search from '../../assets/search.png';
import BalanceEmoji from "../../assets/BalanceEmoji.png";

const QuestDetails = () => {

    const navigate = useNavigate();

    const [isStartQuest, setIsStartQuest] = useState(1)

    const [creditClaim, setCreditClaim] = useState(false)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModal_2Open, setIsModal_2Open] = useState(false)
    const [isModal_3Open, setIsModal_3Open] = useState(false)

    const handleModal = (e) => {
        e.stopPropagation();
        setIsModalOpen(true)
    }

    const handleModal_2 = (e) => {
        e.stopPropagation();
        setIsModalOpen(false)
        setIsModal_2Open(true)
    }



    const handleClick = (val) => {
        setIsStartQuest(val)
        // setIsModal_3Open(val)
    }

    const handleClaimModal = (val) => {
        setIsModal_3Open(val)
        if(!val){
            setCreditClaim(true)
        }
    }


    return (
        <>
            <div className='row m-0 d-flex newsfeed_bg quest_detail_web'>

                <div className='news_left p-0'>
                    <SideNav Journey={true} />
                </div>

                <div className='news_right p-5'>
                    <div className='row m-0'>
                        <div className='col p-0'>

                            <div className='row m-0'>
                                <div className='col-8 p-0'>
                                    <p className='mb-0 notification_heading'>Quest Detail</p>
                                    <p className='mb-0 mt-3 journey_bredcrum '>Journey > Your Quest > <span className='quest_border'>Quest Details</span></p>

                                </div>
                                <div className='col-4 p-0 d-flex align-items-center justify-content-end'>
                                    <p className='mb-0 time_exchange_right_head text-right'> <img className='mb-1' src={TimeExchangePocket} alt=' ' /> 36H &nbsp; <span className='deivder_time_ex'>|</span> &nbsp; <img className='mb-1' src={TimeExchangeEmoji} alt=' ' /> 2400 WBX</p>
                                </div>

                            </div>

                            <div className='row m-0 mt-4'>

                                <div className='col-8 p-0'>
                                    <div className='quest_details_box p-4' onClick={(e) => {
                                        e.stopPropagation();
                                        handleClick(3)
                                    }
                                    }>

                                        <div className='row m-0'>

                                            <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                <div className={`quest_details_que_end_line  d-flex align-items-center justify-content-center ${isStartQuest === 3 ? 'quest_details_que_end_line_blue' : ''}`}>
                                                    <p className='mb-0 ong_que_end_line_text'>{isStartQuest === 3 ? 'Completed' : 'Ends in 2 days'}</p>
                                                </div>
                                                <div className='quest_details_img_box'>
                                                    <img className='quest_details_img' src={Quest_Detail_img} alt='' />

                                                </div>

                                            </div>

                                            <div className='col-6 p-0'>
                                                <p className='mb-0 quest_details_heading'>Relationship Power Up!</p>
                                                <p className='mb-0 quest_details_session mt-2'>1-to-1 Sessions</p>
                                                {
                                                    isStartQuest === 2 &&
                                                    <>
                                                        <div className='row m-0 mt-5'>
                                                            <div className='col-12 p-0'>

                                                                <div className='row m-0'>
                                                                    <div className='col-12 p-0 d-flex align-items-center justify-content-start'>
                                                                        <p className='mb-0 on_quest_big_box_percentage'>68% Complete</p>
                                                                    </div>
                                                                </div>

                                                                <div class="progress quest_progress mt-2">
                                                                    <div class="progress-bar quest_progress-bar " role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    isStartQuest === 1 && <button className='strat_quest_btn mt-5' onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleClick(2)
                                                    }}>Start Quest</button>
                                                }


                                                {
                                                    isStartQuest === 3 && <button className={`${creditClaim ? 'claim_deactive-btn mt-5' : 'claim_credits_btn  mt-5'}`} onClick={() => handleClaimModal(true)}> {creditClaim ? 'Claimed' : 'Claim Credits'} </button>
                                                }



                                            </div>

                                            <div className='col-3 p-0'>

                                                <div className='row m-0'>
                                                    <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                                        <p className='mb-0 on_quest_big_box_right'>500 &nbsp; <img src={Dimond} alt='' /></p>
                                                    </div>
                                                </div>

                                                <div className='row m-0 mt-4'>
                                                    <div className='col-12 p-0 d-flex align-items-center justify-content-end'>

                                                        <div className='quest_relation d-flex align-items-center justify-content-between ps-2 pe-2'>
                                                            <img className='quest_relation_img' src={Relation} alt='' />
                                                            <p className='mb-0 quest_relation_name'>Relationship </p>

                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='row m-0 mt-4 d-flex align-items-center justify-content-around'>

                                                    <div className='col-4 quest_relation_oprat d-flex align-items-center justify-content-center'>
                                                        <img src={JourneyLike} alt='' />
                                                    </div>

                                                    <div className='col-4 quest_relation_oprat d-flex align-items-center justify-content-center' onClick={handleModal}>
                                                        <img src={JourneyPeople} alt='' />
                                                    </div>

                                                    <div className='col-4 quest_relation_oprat d-flex align-items-center justify-content-center'>
                                                        <img src={JourneyShare} alt='' />
                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                        <div className='row m-0 mt-4'>
                                            <div className='col-12 p-0'>
                                                <p className={`mb-0 quest_description_heading ${isStartQuest === 1 || isStartQuest === 2 ? '' : 'quest_deactive'}`}>Description</p>
                                                <p className={`mb-0 mt-2 quest_description_details ${isStartQuest === 1 || isStartQuest === 2 ? '' : 'quest_deactive'}`}>Invite 1 friend to a 1-to-1 Session with you, and simply talk about your day!</p>
                                            </div>
                                        </div>

                                        <div className='row m-0 mt-5'>
                                            <div className='col-12 p-0 pt-4'>
                                                <p className={`mb-0 quest_description_heading ${isStartQuest === 1 || isStartQuest === 2 ? '' : 'quest_deactive'}`}>How does this improve your well-being?</p>
                                                <p className={`mb-0 mt-2 quest_description_details ${isStartQuest === 1 || isStartQuest === 2 ? '' : 'quest_deactive'}`}>You will gain insights from people you interact with, especially with the coaches. Make yourself more confident to communicate your feeling with friends.</p>
                                            </div>
                                        </div>


                                        {/* modal-1 */}
                                        <div class={`modal fade ${isModalOpen ? 'show' : ''}`} style={{ display: isModalOpen ? 'block' : 'none', background: isModalOpen ? 'rgba(0,0,0,0.5)' : '' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-lg invite_modal p-4">
                                                <div class="modal-content invite_modal_content">
                                                    <div class="modal-body invite_modal_body">

                                                        <div className='row m-0'>
                                                            <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                                                <p className='invite_heading'>You are inviting</p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0 invite_row pt-2 pb-4'>
                                                            <div className='col-12 p-0'>
                                                                <div class="input-group invite_input">
                                                                    <input type="text" class="form-control invite_input" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                                                                    <span class="input-group-text invite_input_span" id="basic-addon1"><img src={Search} alt='' /></span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0'>
                                                            <div className='col-12 p-0 '>

                                                                <div className='row m-0 invite_row pt-3 pb-2'>
                                                                    <div className='col-1 p-0 d-flex align-items-center justify-content-center'>
                                                                        <div className='invite_img_box'>
                                                                            <img className='invite_img' src={Invite_profile_img1} alt='' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-11 p-0 d-flex align-items-center justify-content-start'>
                                                                        <p className='mb-0 invite_profile_name'>Blessy</p>
                                                                    </div>
                                                                </div>

                                                                <div className='row m-0 invite_row pt-3 pb-2'>
                                                                    <div className='col-1 p-0 d-flex align-items-center justify-content-center'>
                                                                        <div className='invite_img_box'>
                                                                            <img className='invite_img' src={Invite_profile_img1} alt='' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-11 p-0 d-flex align-items-center justify-content-start'>
                                                                        <p className='mb-0 invite_profile_name'>Blessy</p>
                                                                    </div>
                                                                </div>

                                                                <div className='row m-0 invite_row pt-3 pb-2'>
                                                                    <div className='col-1 p-0 d-flex align-items-center justify-content-center'>
                                                                        <div className='invite_img_box'>
                                                                            <img className='invite_img' src={Invite_profile_img1} alt='' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-11 p-0 d-flex align-items-center justify-content-start'>
                                                                        <p className='mb-0 invite_profile_name'>Blessy</p>
                                                                    </div>
                                                                </div>

                                                                <div className='row m-0 invite_row pt-3 pb-2'>
                                                                    <div className='col-1 p-0 d-flex align-items-center justify-content-center'>
                                                                        <div className='invite_img_box'>
                                                                            <img className='invite_img' src={Invite_profile_img1} alt='' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-11 p-0 d-flex align-items-center justify-content-start'>
                                                                        <p className='mb-0 invite_profile_name'>Blessy</p>
                                                                    </div>
                                                                </div>

                                                                <div className='row m-0 invite_row pt-3 pb-2'>
                                                                    <div className='col-1 p-0 d-flex align-items-center justify-content-center'>
                                                                        <div className='invite_img_box'>
                                                                            <img className='invite_img' src={Invite_profile_img1} alt='' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-11 p-0 d-flex align-items-center justify-content-start'>
                                                                        <p className='mb-0 invite_profile_name'>Blessy</p>
                                                                    </div>
                                                                </div>

                                                            </div>


                                                            <div className='row m-0 mt-3'>
                                                                <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                                                    <button className='invite_btn' onClick={handleModal_2}>Invite</button>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* modal-2 */}

                                    <div class={`modal fade ${isModal_2Open ? 'show' : ''}`} style={{ display: isModal_2Open ? 'block' : 'none', background: isModal_2Open ? 'rgba(0,0,0,0.5)' : '' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-lg invite_modal_done p-4">
                                            <div class="modal-content invite_modal_content">
                                                <div class="modal-body invite_modal_body">

                                                    <div className='row m-0'>
                                                        <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                                            <div className='invite_circle'></div>
                                                        </div>
                                                    </div>

                                                    <div className='row m-0 mt-4'>
                                                        <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                                            <p className='mb-0 invite_heading'>Invitations sent!</p>
                                                        </div>
                                                    </div>

                                                    <div className='row m-0 mt-2 pe-5 ps-5'>
                                                        <div className='col-12 p-0 d-flex align-items-center justify-content-center ps-5 pe-5'>
                                                            <p className='mb-0 invite_done_msg text-center'>The quest will start immediately after your friends accept the invitations.</p>
                                                        </div>
                                                    </div>

                                                    <div className='row m-0 mt-2 pe-5 ps-5'>
                                                        <div className='col-12 p-0 d-flex align-items-center justify-content-center ps-5 pe-5'>
                                                            <button className='invite_done_btn' onClick={() => { navigate('/journey/quests/questinvitation') }}>Done</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Modal-3 */}


                                    <div class={`modal fade ${isModal_3Open ? 'show' : ''}`} style={{ display: isModal_3Open ? 'block' : 'none', background: isModal_3Open ? 'rgba(0,0,0,0.5)' : '' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-lg claim_modal p-4">
                                            <div class="modal-content claim_modal_content">
                                                <div class="modal-body invite_modal_body">

                                                    <div className='row m-0'>
                                                        <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                                            <img src={BalanceEmoji} alt='' />
                                                        </div>
                                                    </div>

                                                    <div className='row m-0 mt-4'>
                                                        <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                                            <p className='mb-0 invite_heading'>You did it!</p>
                                                        </div>
                                                    </div>

                                                    <div className='row m-0 mt-2'>
                                                        <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                                            <p className='mb-0 claim_pera'>You gained 100 WBX.</p>
                                                        </div>
                                                    </div>

                                                    <div className='row m-0 mt-2'>
                                                        <div className='col-12 p-0 d-flex align-items-center justify-content-center'>
                                                            <button className='claim_popup_btn' onClick={()=>handleClaimModal(false)}>Ok</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>


                                <div className='col-4 p-0'>

                                    <div className='row m-0'>
                                        <div className='col-12 p-0 ps-5 pb-3'>
                                            <p className=' mb-0 journey_active_list ms-2'>Activity List</p>

                                        </div>
                                    </div>

                                    <div className='row m-0'>
                                        <div className='col-12 p-0 d-flex flex-column  align-items-end justify-content-end'>

                                            <div className='ongoing_quest_sml_box p-2'>

                                                <div className='row m-0'>
                                                    <div className='col-10 p-0 d-flex'>
                                                        <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Spends 3 hours of 1-1 sessions</p>
                                                    </div>
                                                    <div className='col-2 p-0 d-flex align-items-center justify-content-end'>
                                                        <p className='mb-0 journey_session_details'>0/1</p>

                                                    </div>
                                                </div>

                                            </div>

                                            <div className='ongoing_quest_sml_box p-2 mt-3'>
                                                <div className='row m-0'>
                                                    <div className='col-10 p-0 d-flex'>
                                                        <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Attend 1 live events</p>
                                                    </div>
                                                    <div className='col-2 p-0 d-flex align-items-center justify-content-end'>
                                                        <p className='mb-0 journey_session_details'>0/3</p>

                                                    </div>
                                                </div>

                                            </div>


                                            <div className='ongoing_quest_sml_box p-2 mt-3'>
                                                <div className='row m-0'>
                                                    <div className='col-10 p-0 d-flex'>
                                                        <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Messages 3 of your friends</p>
                                                    </div>
                                                    <div className='col-2 p-0 d-flex align-items-center justify-content-end'>
                                                        <p className='mb-0 journey_session_details'>0/1</p>

                                                    </div>
                                                </div>

                                            </div>


                                            <div className='ongoing_quest_sml_box p-2 mt-3'>
                                                <div className='row m-0'>
                                                    <div className='col-10 p-0 d-flex'>
                                                        <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Shares 3 events to your friends</p>
                                                    </div>
                                                    <div className='col-2 p-0 d-flex align-items-center justify-content-end'>
                                                        <p className='mb-0 journey_session_details'>0/1</p>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>


                                    </div>

                                </div>
                            </div>



                        </div>
                    </div>


                </div>

            </div>

        </>
    )
}

export default QuestDetails
