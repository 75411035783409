import {toast} from "react-toastify";
import {getAxiosInstance} from "./kaleidoscopeAxios";
import {AxiosError, AxiosResponse} from "axios";

async function CallApi<T extends any>(
  method: "POST" | "GET" | "PUT" | "DELETE",
  options: {
    token?: string;
    successMsg?: string;
    errorMessage?: string;
    hidePopup?: boolean;
    url: string;
    data?: any;
  },
  headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  }
): Promise<AxiosResponse<T> | AxiosError<{message: string}, any> | AxiosResponse<{message: string}, any>> {
  const axios = getAxiosInstance(options.token, headers);

  try {
    const responseData = await axios<T>(Object.assign(options, {method: method}));
    if (options.successMsg) {
      toast.success(options.successMsg);
    }
    return responseData;
  } catch (error) {
    return genericErrorHandler(error, options.errorMessage, options.hidePopup)
  }
}

export function genericErrorHandler(
  error: AxiosError<{ message: string }>,
  customErrorMessage?: string,
  shouldHidePopup?: boolean
) {
  if (customErrorMessage) {
    toast.error(customErrorMessage);
  } else if (!shouldHidePopup) {
    toast.error(error?.response?.data?.message || "Something went wrong");
  }

  if (error.response) {
    return error.response
  } else {
    return error
  }
}

export default CallApi;
