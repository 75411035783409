export default function Points(props: {
  wbTime?: number;
  wbPoints?: number;
}) {
  return (
    <div className='d-flex align-items-center gap-3 flex-nowrap tw-text-[#7e5fc5] montserrat fw-bold tw-text-xl'>
      <div className="d-flex align-items-center gap-2">
        <i className={"bi bi-clock-fill"}/>
        <span>{props.wbTime ?? 0}H</span>
      </div>
    </div>
  )
}