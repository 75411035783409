import React from 'react';
import {Link} from "react-router-dom";

import Completed from '../../../../assets/Completed.png';
import Logo from '../../../../assets/wcn_logo.svg';

const SignUpLeftSection = (props) => {
    return (
        <div
            className="tw-w-[250px] vh-100 position-fixed top-0 start-0 rounded-3 tw-rounded-br-lg tw-bg-[#f5f6fc] p-4 d-flex flex-column justify-content-between">
            <img src={Logo} alt='logo' className={"tw-max-w-[100px]"}/>

            <div className={"d-flex flex-column gap-3"}>
                <div className="d-flex">
                    <div>
                        {
                            (props.step === 0 || props.step === 1 || props.step === 3 || props.step === 4) ? (
                                <div
                                    className={`circle p-0 m-0 d-flex align-content-center justify-content-center ${(props.step === 0 || props.step === 1 || props.step === 3 || props.step === 4) ? 'box_shadow' : ''}`}>
                                    <p className={`mb-0 ${(props.step === 0 || props.step === 1 || props.step === 3 || props.step === 4) ? 'steps_count active' : 'steps_count'}`}>1</p>
                                </div>
                            ) : (
                                <div
                                    className="circle green_circle p-0 m-0 d-flex align-items-center justify-content-center">
                                    < img height="15" src={Completed} alt=""/>
                                </div>
                            )
                        }
                    </div>
                    <div>
                        <p className={`mb-0 ms-3 ${(props.step === 0 || props.step === 1 || props.step === 3 || props.step === 4) ? ' steps_name active' : 'steps_name deactive'}`}>ACCOUNT</p>
                    </div>
                </div>

                <div className="d-flex">
                    <div
                        className={`circle p-0 m-0 d-flex align-content-center justify-content-center ${props.step === 2 ? 'box_shadow' : ''}`}>
                        <p className={`mb-0 ${props.step === 2 ? 'steps_count active' : 'steps_count'}`}>2</p>
                    </div>

                    <div className="col-8 p-0 d-flex align-items-center justify-content-start">
                        <p className={`mb-0 ms-3 ${props.step === 2 ? ' steps_name' : 'steps_name'}`}>SETUP</p>
                    </div>
                </div>
            </div>

            <div className={"d-flex flex-column gap-3"}>
                <div className={"tw-text-xs"}>
                    By setting up an account, you are confirming that you’re agreeing with our
                    <a className={"text-primary text-decoration-underline mx-1"}
                       href="https://kaleidoscope-public.s3.ap-southeast-1.amazonaws.com/kaleidoscope_privacy_policy.pdf"
                       target={"_blank"} rel={"noreferrer"}>
                        Privacy Policy
                    </a>
                    and
                    <a className={"text-primary text-decoration-underline mx-1"}
                       href="https://kaleidoscope-public.s3.ap-southeast-1.amazonaws.com/20231009.Disclaimer.pdf"
                       target={"_blank"} rel={"noreferrer"}>
                        Disclaimer.
                    </a>
                </div>

                <hr className="m-0"/>

                <div className={"text-center"}>
                    <p className="mb-1 small">Already have an account?</p>
                    <div>
                        <Link to={"/login"}
                              className="inter tw-text-[#5458f7] tw-border-[#5458f7] tw-rounded-xl px-4 py-2 bg-white tw-border tw-text-xs fw-bold">
                            Login
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUpLeftSection;