import React from 'react';


import LogoTab from "../../assets/Logo_Tab.png";
import NotificationBellResponsive from "../../assets/svg/NotificationBellResponsive.svg";
import HamburgerResponsive from "../../assets/svg/HamburgerResponsive.svg";
import Search from "../../assets/search1.png";
import accArrow from '../../assets/svg/AccordianArrow.svg';
import GrayLike from "../../assets/svg/Organization/GrayLike.svg"
import GrayComment from "../../assets/svg/Organization/GrayComment.svg"
import GrayShare from "../../assets/svg/Organization/GrayShare.svg"
import CoverPhotoCamera from '../../assets/svg/CoverPhotoCamera.svg'

const organisationAdminMob = () => {
  return (
    <>

      <div className='row m-0'>
        <div className='col-12 p-0'>

          <div className='row m-0 pb-5 pb-md-0 pb-lg-0 mob_tab_responsive_view responsive_background'>
            <div className='col-12 p-0 pb-5'>

              <div className='row m-0 p-0'>
                <div className='col-12 p-0 org_resp_top'>

                  <div className='row m-0 pt-3 pb-3 pt-md-4 pb-md-4 ps-4 pe-4'>
                    <div className='col-4 p-0 d-flex align-items-center'>
                      <img className='responsive_nav_logo' src={LogoTab} alt=''/>
                    </div>

                    <div className='col-4 p-0 d-flex align-items-center justify-content-center'>
                      <p className='mb-0 responsive_page_name'>Groups</p>
                    </div>

                    <div className='col-4 p-0 d-flex align-items-center justify-content-end'>
                      <img className='me-4 responsive_nav_notification_bell' src={NotificationBellResponsive} alt=''/>
                      <img className='responsive_nav_hamburger cursor_pointer' class="navbar-toggler" type="button"
                           data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText"
                           aria-expanded="false" aria-label="Toggle navigation" src={HamburgerResponsive} alt=''/>
                    </div>
                  </div>

                  <div className='row m-0'>
                    <div className='col-12 p-0 px-5 d-flex align-items-center justify-content-center'>

                      <div className="input-group neworg_searchbar mt-2">
                        <span class="input-group-text px-4" id="basic-addon1"> <img src={Search} alt=''/> </span>
                        <input type="text" class="form-control p-0" placeholder="Search for a group"
                               aria-label="Username" aria-describedby="basic-addon1"/>
                      </div>

                    </div>
                  </div>

                  <div className='row m-0 mt-4'>
                    <div className='col-12 p-0'>

                      <div className='org_cover_img d-flex align-items-center justify-content-center'>

                        <label className="">
                          <img width='36px' src={CoverPhotoCamera} alt=''></img>
                          {/* <input type="file" name="myfile" style={{ display: "none" }} /> */}
                        </label>


                      </div>

                    </div>
                  </div>

                  <div className='row m-0 mt-4 ps-4 pe-4'>
                    <div className='col-12 p-0 py-3 brd_bottom'>
                      <p className='mb-0 new_org_heading'>New Organisation</p>
                      <p className='mb-0 mt-2 new_org_head_dtls'>Public/Private &bull; Members</p>
                    </div>
                  </div>

                  <div className='row m-0 mt-3 ps-4 pe-4'>
                    <div className='col-12 p-0'>
                      <button className='resp_org_btn resp_org_btn_type_two'>Create New Organisation</button>
                    </div>
                  </div>

                  <div className='row m-0 mt-3 ps-4 pe-4'>
                    <div className='col-12 p-0 d-flex align-items-center justify-content-between'>
                      <button className="p-0 p-2 new_org_header_btns">About</button>
                      <button className="p-0 p-2 new_org_header_btns act_new_org_header_btns">Posts</button>
                      <button className="p-0 p-2 new_org_header_btns">Members</button>
                    </div>
                  </div>

                </div>
              </div>

              <div className='row m-0'>
                <div className='col-12 px-4 py-2'>

                  <div className='row m-0 mt-3'>
                    <div className='col-12 p-0'>

                      <div class="input-group">
                                                <span
                                                  class="input-group-text input_profile d-flex justify-content-center"
                                                  id="basic-addon1">
                                                    <div className='input_profile_img'>
                                                        <img src={"/static/png/Male.png"}/>
                                                    </div>
                                                </span>

                        <input className='form-control org_inp newsfeed_input pb-2' type='text'
                               placeholder='Share a post or create an event here...'/>
                      </div>

                    </div>
                  </div>

                  <div className='row m-0 mt-3'>
                    <div className='col-12 p-0'>
                      <p className='mb-0 resp_gray_text'>Your group feed is currently empty. Post something to keep your
                        members engaged!</p>
                    </div>
                  </div>

                  <div class="accordion post_accordion event_accordion mt-3" id="accordionExample1">
                    <div class="accordion-item event_box_feed">
                      <h2 class="accordion-header ">
                        <button class="accordion-button post_accordion_btn d-flex justify-content-between" type="button"
                                data-bs-toggle="collapse" data-bs-target="{`#${index}`}" aria-expanded="false"
                                aria-controls="{`${index}`}">

                          <div className='m-0 d-flex align-items-center'>

                            <div className='post_profile_img me-2'>
                              <img src={"/static/png/Male.png"} alt=''/>
                            </div>

                            <div className='p-0 '>
                              <p className='mb-0 event_name ms-1'>Create a new event here!</p>
                            </div>

                          </div>

                          <div className='m-0  d-flex align-items-center'>

                            <div class='rotate_icon'>
                              <img src={accArrow}/>
                            </div>

                          </div>

                        </button>
                      </h2>
                      <div class="accordion-collapse" aria-labelledby="{`${index}`} "
                           data-bs-parent="#accordionExample1">
                        <div class="accordion-body accord_body">

                          <div className='row m-0 '>
                            <div className='col-12 p-0 bg-gray post_img_section'>
                              {/* <img height="180px" className='post_img' config={Event} alt='' /> */}
                            </div>
                          </div>

                          <div className='row m-0 mt-4 ps-3 align-items-center'>
                            <div className='col-12 p-0'>
                              <p className='mb-0 event_heading ms-1'>Event Name</p>
                              <p className='mb-0 event_date ms-1'>Event Date</p>
                            </div>
                          </div>

                          <div className='row m-0 ps-3 pe-3 pb-2 post_border mt-2'>

                            <div className='col-6 p-0 ps-0 d-flex align-items-center justify-content-start'>

                                                            <span class="input-group-text post_avatar_img "
                                                                  id="basic-addon2">
                                                                <span class="avatar_post bg_gray">
                                                                </span>
                                                                <span class="avatar_post bg_gray">
                                                                </span>
                                                                <span class="avatar_post bg_gray">
                                                                </span>
                                                                <span class="avatar_post bg_gray">
                                                                </span>
                                                            </span>

                            </div>

                            <div className='col-6 p-0 d-flex align-items-center justify-content-end'>
                              <button className='event_btn event_btn_unactive'>Register</button>
                            </div>

                          </div>

                          <div className='row m-0 mt-3 pb-3 px-3'>

                            <div className='col-4 p-0 d-flex justify-content-start align-items-center'>

                              <img className='event_action_img' src={GrayLike} alt=''/>

                            </div>

                            <div className='col-4 p-0 d-flex align-items-center'>

                              <div className='d-flex align-items-center'>
                                <img className='event_action_img' src={GrayComment} alt=''/>
                              </div>

                            </div>

                            <div className='col-4 p-0 d-flex justify-content-end align-items-center'>
                              <img className='event_action_img' src={GrayShare} alt=''/>
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>

            </div>
          </div>

          {/* Condition-3 */}

        </div>
      </div>

    </>
  )
}

export default organisationAdminMob;