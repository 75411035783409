import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";

import '../../css/sideNav.css';
import LogOut from '../../assets/signout_new_icon.png';
import {getMetaData, getNotifications, getTimeExPoints, getUserInformation} from '../../utils/apiUrlsSetup';
import _ from 'lodash'
import {add, savePoints, saveTourData} from '../../redux/actions/action';
import {useDispatch, useSelector} from 'react-redux';
import {onMessageListener} from '../../firebase/Firebase';
import {usePubNub} from 'pubnub-react';
import {greeting} from "../../utils/constant";
import {useLocation} from "react-router";
import {MENU} from "./MENU";

const SideNav = (props) => {
  const loggedInUserDetails = JSON.parse(localStorage.getItem("userDetails"));

  const [userDetails, setUserDetails] = useState({});
  const [notificationsCount, setnotificationsCount] = useState(sessionStorage.getItem("notificationsCount") || 0);
  const pubnub = usePubNub();

  const dispatch = useDispatch();
  const data = useSelector(state => state);

  const [messagedReveived, setMessageReveived] = useState(false);

  const logOutFunc = () => {
    dispatch(add({}));
    dispatch(savePoints({}));
    dispatch(saveTourData({}));
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userDetails");
    localStorage.removeItem("notificationsCount");
    navigate('/')
  }

  useEffect(() => {
    GetAllNotificationsCountFunc();
    return () => {
      if (props.notification) {
        setnotificationsCount(0);
        sessionStorage.removeItem("notificationsCount");
      }
    }
  }, []);


  const GetAllNotificationsCountFunc = async () => {
    let responseData = await getNotifications();
    if (responseData.status === 200) {
      sessionStorage.setItem("notificationsCount", responseData.data.data.unreadCount);
      setnotificationsCount(responseData.data.data.unreadCount);
    }
  }

  useEffect(() => {
    if (!Object.keys(data.userDetails).length) {
      getUserDetails();
    } else {
      setUserDetails(data.userDetails.userDetails)
    }
    // getAllUnreadChatCounts();
  }, []);

  const getUserDetails = async () => {
    let responseData = await getUserInformation();
    if (responseData.status === 200) {
      if (responseData.data.data.status === "deleted") {
        logOutFunc();
      } else {
        dispatch(add(responseData.data.data));
        setUserDetails(responseData.data.data)
      }
    }
  }

  onMessageListener()
    .then(() => {
      GetAllNotificationsCountFunc();
    })
    .catch((err) => console.error("failed: ", err));

  const navigate = useNavigate();
  const location = useLocation()

  const role = userDetails.role

  useEffect(() => {
    if (loggedInUserDetails.role !== "super-admin") {
      if (_.isEmpty(data.points.points)) {
        getTimeExchangePoints();
      }
    }
    if (_.isEmpty(data.tourData.tourData)) {
      getTourData();
    }
  }, []);

  const getTimeExchangePoints = async () => {
    const responseData = await getTimeExPoints();
    if (responseData.status === 200) {
      dispatch(savePoints(responseData.data.data));
    }
  }

  const getTourData = async () => {
    const responseData = await getMetaData();
    if (responseData.status === 200) {
      dispatch(saveTourData(responseData.data.data));
    }
  }

  if (!props.chat) {
    pubnub.addListener({
      message: function () {
        setMessageReveived(true);
      },
    });
  }

  return (
    <>
      <aside
        className={"tw-w-[250px] vh-100 position-fixed top-0 start-0 rounded-3 tw-rounded-br-lg tw-bg-[#f9fdff] px-4 py-3 d-flex flex-column justify-content-between"}
        style={{
          boxShadow: "6px 0 18px rgba(0, 0, 0, 0.06)",
        }}>

        <div className={"flex-1"}>
          <Link to={"/newsfeeds"}>
            <img src={"/static/svg/Logo.svg"} alt='logo' className={"!tw-max-w-[100px]"}/>
          </Link>

          <div className={"mt-3 d-flex flex-column gap-3"}>
            {MENU
              .filter(menu => menu.rules.whitelist ? menu.rules.whitelist.includes(role) : true)
              .filter(menu => menu.rules.blacklist ? !menu.rules.blacklist.includes(role) : true)
              .map((menu, i) => {
                return (
                  <Link to={menu.href} className={`d-flex gap-3 align-items-center`} key={i}>
                    <img src={menu.img} alt='' width={"20px"} height={"20px"}/>
                    <span
                      className={`tw-text-xs montserrat tw-font-[500] tw-text-[#410db7] lh-base ${location.pathname.startsWith(menu.href) ? "fw-bold" : ""} ${menu.href === "#" ? "text-secondary" : ""}`}
                    >
                        {menu.label}
                    </span>

                    {(menu.href === "/notification" && notificationsCount > 0) && (
                      <div className="flex-grow-1 d-flex justify-content-end align-items-center">
                        <div
                          className="tw-text-xs tw-w-[18px] tw-h-[18px] bg-danger rounded-circle montserrat text-white ls-near d-flex justify-content-center align-items-center">
                          {notificationsCount}
                        </div>
                      </div>
                    )}

                    {(menu.href === "/chat" && messagedReveived) && (
                      <div className="flex-grow-1 d-flex justify-content-end align-items-center">
                        <div
                          className="tw-w-[10px] tw-h-[10px] bg-danger rounded-circle">
                        </div>
                      </div>
                    )}
                  </Link>
                )
              })}

            <hr className={"m-0 p-0 border-2 tw-opacity-100 tw-border-[rgba(84,191,237,.5)!important]"}/>

            <div className='d-flex gap-3 align-items-center'>
              <img src={LogOut} alt='' width={"20px"} height={"20px"}/>
              <span
                className="tw-cursor-pointer tw-text-xs montserrat tw-font-[500] tw-text-[#410db7] lh-base"
                onClick={logOutFunc}>
                  Sign out
              </span>
            </div>
          </div>
        </div>

        <div>
          <div className='d-flex gap-2 align-items-center'>
            <Link to={"/profile"} className={"flex-shrink-0"}>
              <img className='tw-cursor-pointer rounded-4 tw-w-[46px] tw-h-[46px]'
                   src={userDetails.profilePic || "/static/png/Male.png"}
                   alt=''/>
            </Link>
            <div className='small'>
              <p className='mb-0 montserrat small tw-font-[500] lh-1'>{greeting()},</p>
              <Link className='mb-0 montserrat fw-bold tw-text-[#410db7] lh-sm d-block tw-text-xs'
                    to={"/profile"}>
                {userDetails.firstName + " " + userDetails.lastName}
              </Link>
            </div>
          </div>
        </div>
      </aside>
    </>
  )
}

export default SideNav;
