import React from 'react';
import Header from '../../component/staticPages/header';
import Footer from '../../component/staticPages/footer';
import AboutUsFounderCard from '../../component/AboutUsFounderCard'
import Joseph from "../../assets/Josephslide.png";
import Deniel from "../../assets/Denielslide.png"

const FoundingTeam = () => {
    const founderSlideData = [
        {
            fimage: Joseph,
            fname: 'Joseph Kalai',
            fwork: 'Strategy & Product',
            fdescription: 'A true versatilist, Joseph has played a significant part in some of Singapore’s biggest projects in both the Services, Manufacturing and Education sectors. 25+ years of growth marketing, digital product development and large project management.'
        },
        {
            fimage: Deniel,
            fname: 'Daniel Lim',
            fwork: 'Business Development & Partnerships',
            fdescription: 'Daniel is a Social Enterprise Co-Founder who champions caregivers well-being and quality of life for persons with dementia including end of life care. Daniel has partnered a local university to conduct Singapore’s first national survey on caregivers for those with mental health issues.'
        },
    ]

    return (
        <main className={"bg-logged-in min-vh-100 d-flex flex-column justify-content-between"}>
            <div>
                <div className="container-xl">
                    <Header/>
                </div>

                <div className="container-xl d-flex flex-column gap-5 pb-5">
                    <h1 className='tw-text-4xl montserrat fw-bold text-center'>Founding Team</h1>

                    <div className="row text-center justify-content-center gy-5">
                        {founderSlideData.map(founder => {
                            return (
                                <div
                                    className="col-md-6 col-12 d-flex justify-content-center align-items-center flex-column">
                                    <div
                                        className="flex-shrink-0 mb-2 w-100 lg:tw-max-w-[400px] tw-max-w-[250px] d-none d-md-block">
                                        <AboutUsFounderCard
                                            backgroundImage={founder.fimage}
                                            className="tw-bg-center tw-bg-contain"
                                            text={founder.fdescription}
                                        />
                                    </div>
                                    <div
                                        className="flex-shrink-0 mb-2 w-100 lg:tw-max-w-[400px] tw-max-w-[250px] d-block d-md-none">
                                        <img src={founder.fimage} alt={founder.fname}
                                             className={"w-100 tw-aspect-square"}/>
                                    </div>

                                    <p className='inter fw-bold tw-text-[#18214D] tw-text-3xl'>{founder.fname}</p>
                                    <p className='inter tw-text-[#18214D] tw-text-xl mb-2'>{founder.fwork}</p>
                                    <p className='inter tw-text-[#18214D] tw-text-md d-block d-md-none'>{founder.fdescription}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <Footer/>
        </main>
    )
}

export default FoundingTeam;