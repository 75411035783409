import {useEffect, useState} from 'react'
import CloseBtn from '../../../assets/Crossbtn.png';
import {enquiryNow} from '../../../utils/apiUrlsSetup';
import './enquiryModal.css'
import Swal from "sweetalert2";

const EnquiryModal = (props) => {

  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")

  const handleSubmit = async () => {
    const response = await enquiryNow({subject: subject, message: message})
    if (response && response.statusCode === 200) {
      props.setIsOpen(false)
      Swal.fire("Success!", response.data.message, "success")
    }
  }

  useEffect(() => {
    setSubject(props.title)
  }, [props.title])


  return (
    <div
      className={`modal fade ${props.isOpen ? "show" : ""} enquiry-zoom`}
      style={{
        display: props.isOpen ? "block" : "none",
        background: props.isOpen ? "rgba(0,0,0,0.5)" : "",
      }}
      id="staticBackdrop2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content model_border_radius">
          <div class="modal-body p-4 d-flex flex-column align-items-center justify-content-center">
            <div class="d-flex justify-content-between align-items-center w-100">
              <p className="model_bold_text mb-4 enquiry-heading">Enquire</p>
              <p className="model_bold_text mb-4">
                <img src={CloseBtn} className="cursor_pointer" onClick={() => props.setIsOpen(false)} alt=""/>
              </p>
            </div>
            <div className="invite_frnds_list w-100" style={{height: 300}}>
              <div className="">
                <div className='row m-0'>
                  <div className='col-12 p-0'>
                    <label for="exampleFormControlInput1" className="form-label contact_label">Subject</label>
                    <input value={subject} onChange={(e) => setSubject(e.target.value)} type="email"
                           className="form-control contact_input mt-1" id="exampleFormControlInput1" placeholder=""/>
                  </div>
                </div>
                <div className="row m-0 mt-4">
                  <div className="col-12 p-0">
                    <label for="exampleFormControlInput1" className="form-label contact_label">Message</label>
                    {/* <p className="mb-0 input_header" style={{fontSize: '15px'}}>Subject<span style={{color: 'red', fontSize: '15px'}}> *</span></p> */}
                    <textarea className="form-control contact_input mt-1 enquiry-textarea" id="message"
                              name="message"
                              placeholder="" onChange={(e) => setMessage(e.target.value)}></textarea>


                  </div>
                </div>
                <div className="row m-0 mt-4">
                  <div className="col-9 p-0">
                  </div>
                  <div className="col-3 p-0">
                    <button disabled={!subject || !message}
                            className={`post_btn ${!subject || !message ? "deactive-btn " : "active_btn"}`}
                            onClick={handleSubmit}>
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnquiryModal;
