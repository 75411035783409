import React, {useEffect, useMemo, useState} from 'react';
import {Link} from "react-router-dom";
import {getUserlist, userStatus} from '../../utils/apiUrlsSetup';
import {roles} from '../../utils/constant';
import CsvDownloadButton from 'react-json-to-csv'
import LoggedInLayout from "../../component/layout/LoggedInLayout.tsx";
import FullWidthTable from "../../component/FullWidthTable";


const UserList = () => {

  const [userList, setUserList] = useState([]);

  const [filteredUserList, setFilteredUserList] = useState([]);

  const [search, setSearch] = useState("");

  const [currentPage, setCurrentPage] = useState(1)
  const LIMIT = 10;
  const TOTAL_PAGE = Math.ceil(userList.length / LIMIT)
  const hasNextPage = currentPage < TOTAL_PAGE;
  const hasPrevPage = currentPage > 1;

  function nextPage() {
    if (currentPage < TOTAL_PAGE) {
      setCurrentPage(prev => prev + 1);
    }
  }

  function prevPage() {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1)
    }
  }

  const getUserslist = async () => {
    let responseData = await getUserlist();
    if (responseData.status === 200) {
      setUserList(responseData.data.data.usersArray)
      setFilteredUserList(responseData.data.data.usersArray)
    }

  }

  useEffect(() => {
    getUserslist();
  }, [])

  const handleApproval = async (id, arr) => {
    if (arr) {
      arr.forEach(async (v) => {
        let objData = {}
        objData.userId = v
        objData.status = "active"
        await userStatus(objData);
      })
      getUserslist();
    } else {
      let objData = {}
      objData.userId = id
      objData.status = "active"
      let responseData = await userStatus(objData);
      if (responseData.status === 200) {
        getUserslist();
      }
    }
  }

  const handleDelete = async (id) => {
    let objData = {}
    objData.userId = id
    objData.status = "deleted"
    let responseData = await userStatus(objData);
    if (responseData.status === 200) {
      getUserslist();
    }
  }

  const handleReactive = async (id) => {
    let objData = {}
    objData.userId = id
    objData.status = "active"
    let responseData = await userStatus(objData);
    if (responseData.status === 200) {
      getUserslist();
    }
  }

  useEffect(() => {
    const list = userList.filter((u) =>
      search
        ? (u.firstName + " " + u.lastName)
        .toLowerCase()
        .indexOf(search.toLowerCase()) >= 0 || (u.email)
        .toLowerCase()
        .indexOf(search.toLowerCase()) >= 0 || ((u.organisation) && (u.organisation)
        .toLowerCase()
        .indexOf(search.toLowerCase()) >= 0)
        : true
    )

    setFilteredUserList(list)
  }, [search, userList])

  const searchResult = useMemo(() => {
    let result = userList;

    if (search) {
      result = result
        .filter((u) => {
          const fullName = u.firstName + " " + u.lastName;
          return fullName
            .toLowerCase()
            .indexOf(search.toLowerCase()) >= 0 || (u.email)
            .toLowerCase()
            .indexOf(search.toLowerCase()) >= 0 || ((u.organisation) && (u.organisation)
            .toLowerCase()
            .indexOf(search.toLowerCase()) >= 0)
        })
    }

    return result
      .sort((a, b) => {
        const statusOrder = {
          "pending": 1,
          "active": 2,
        };

        const aStatusOrder = statusOrder[a.status] || 3;
        const bStatusOrder = statusOrder[b.status] || 3;

        if (aStatusOrder !== bStatusOrder) {
          return aStatusOrder - bStatusOrder;
        }

        return a.firstName.localeCompare(b.firstName);
      })
      .slice(LIMIT * (currentPage - 1), LIMIT * currentPage)
  }, [currentPage, search, userList])

  return (
    <LoggedInLayout title={"User List"}>

      <FullWidthTable
        title={"Manage User"}
        searchValue={search}
        searchPlaceholder={"Search for a user"}
        searchOnChange={(e) => setSearch(e.target.value)}
        topRightButton={
          <CsvDownloadButton
            fileName={`user_list-${new Date()}`}
            className='tw-w-[100px] mx-auto btn btn-sm tw-text-xs d-flex justify-content-center align-items-center gap-1 tw-text-[#5458f7] hover:tw-text-white hover:tw-bg-[#5458f7] tw-border tw-border-[#5458f7]'
            data={filteredUserList}
            delimiter={","}>
            <i className="bi bi-download"/> Export
          </CsvDownloadButton>
        }
        columns={[
          {
            label: "User",
            showOnDesktop: true,
            showOnMobile: true
          },
          {
            label: "Organisation",
            showOnDesktop: true,
            showOnMobile: false
          },
          {
            label: "Role",
            showOnDesktop: true,
            showOnMobile: false
          },
          {
            label: "Action",
            showOnDesktop: true,
            showOnMobile: false,
            centered: true
          },
        ]}
        data={searchResult}
        rows={[
          {
            render: (val) => {
              return (
                <div className="d-flex flex-column gap-2 py-3 py-lg-1">
                  <div
                    className='d-flex align-items-center gap-2'>
                    <img
                      className={"tw-object-cover rounded-circle tw-h-[30px] tw-w-[30px]"}
                      src={val.profilePic ? val.profilePic : "/static/png/Male.png"} alt=''/>

                    <div className={"lh-1 mb-1"}>
                      <Link
                        to={val.role === "user" ? `/admin/user-profile/${val.id}` : `/admin/coach-profile/${val.id}`}
                        className='montserrat fw-semibold text-primary'>
                        {val.firstName} {val.lastName}
                      </Link>

                      <div
                        className="d-none d-lg-block tw-text-xs text-secondary tw-font-medium">
                        {val.email}
                      </div>

                      <div
                        className="d-block d-lg-none tw-text-xs text-secondary tw-font-medium">
                        {roles[val.userType]}
                      </div>
                    </div>
                  </div>

                  <div className={"d-flex d-lg-none flex-column gap-1 mb-2"}>
                    <div>
                      <span className="fw-semibold">Email:</span>
                      <p>{val.email}</p>
                    </div>
                    <div>
                      <span className="fw-semibold">Organisation:</span>
                      <p>{val.organisation}</p>
                    </div>
                  </div>

                  <div className={"d-block d-lg-none"}>
                    {
                      val.status === "pending" && (
                        <button
                          className='montserrat fw-semibold btn btn-outline-success tw-text-xs lh-1 btn-sm px-4 py-2'
                          type={"button"}
                          onClick={() => handleApproval(val.id)}>
                          <i className="bi bi-check"/> Confirm
                        </button>
                      )
                    }
                    {
                      (val.status === "active") &&
                      <button
                        className='montserrat fw-semibold btn btn-outline-danger tw-text-xs lh-1 btn-sm px-4 py-2'
                        type={"button"}
                        onClick={() => handleDelete(val.id)}>
                        <i className="bi bi-trash"/> Deactivate
                      </button>
                    }
                    {
                      (val.status === "rejected" || val.status === "deleted") &&
                      <button className='user_reactive_btn'
                              onClick={() => handleReactive(val.id, val.role)}>Reactivate</button>
                    }
                  </div>
                </div>
              )
            },
          },
          {
            render: (val) => {
              return (
                <div className={"!tw-text-opacity-50 !tw-text-[#334d6e] tw-text-nowrap"}>{val.organisation}</div>
              )
            }
          },
          {
            render: (val) => {
              return (
                <div className={"!tw-text-opacity-50 !tw-text-[#334d6e] tw-text-nowrap"}>{roles[val.userType]}</div>
              )
            }
          },
          {
            render: (val) => {
              return (
                <div className="d-flex justify-content-center align-items-center gap-2">
                  {
                    val.status === "pending" && (
                      <button
                        className={"btn btn-success btn-sm tw-text-xs d-flex justify-content-center align-items-center gap-1 text-nowrap"}
                        type={"button"}
                        onClick={() => handleApproval(val.id)}>
                        <i className="bi bi-plus-lg"/> Confirm Registration
                      </button>
                    )
                  }
                  {
                    (val.status === "active") &&
                    <button
                      className={"tw-w-[100px] btn btn-outline-danger btn-sm tw-text-xs d-flex justify-content-center align-items-center gap-1"}
                      type={"button"}
                      onClick={() => handleDelete(val.id)} title={"Deactivate"}>
                      <i className="bi bi-trash"/> Deactivate
                    </button>
                  }
                  {
                    (val.status === "rejected" || val.status === "deleted") &&
                    <button className='user_reactive_btn'
                            onClick={() => handleReactive(val.id, val.role)}>Reactivate</button>
                  }
                </div>
              )
            }
          }
        ]}
        footer={
          <div className={"w-100 tw-flex tw-items-center tw-justify-between"}>
            <div className="d-flex gap-lg-2 align-items-lg-center flex-column flex-lg-row">
              <div className={"tw-font-medium"}>Page {currentPage}</div>
              <div className="d-none d-lg-block text-secondary fw-semibold">|</div>
              Showing {LIMIT * (currentPage - 1) + 1} - {Math.min(LIMIT * currentPage, userList.length)} of
              total {userList.length} items
            </div>

            <div className={"d-flex align-items-center justify-content-center gap-3"}>
              <button
                type={"button"}
                disabled={!hasPrevPage}
                onClick={hasPrevPage ? prevPage : () => {
                }}
                className={"btn p-0 tw-w-[30px] tw-h-[30px] d-flex justify-content-center align-items-center bg-white border"}>
                <i className="bi bi-chevron-left"></i>
              </button>
              <button
                disabled={!hasNextPage}
                onClick={hasNextPage ? nextPage : () => {
                }}
                type={"button"}
                className={"btn p-0 tw-w-[30px] tw-h-[30px] d-flex justify-content-center align-items-center bg-white border"}>
                <i className="bi bi-chevron-right"></i>
              </button>
            </div>
          </div>
        }
      />
    </LoggedInLayout>
  )
}

export default UserList