import React, {useEffect, useState} from 'react';

import "./organization.css";
import {getPosts} from '../../utils/apiUrlsSetup';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import NewsFeedEvents from './../newsFeed/component/newsFeedEvents';
import FeedsSubPage from "../../component/FeedsSubPage";

/**
 *
 * @param {{
 *   posts: Post[]
 * }} props
 * @returns {Element}
 * @constructor
 */
const PostsOrg = (props) => {
  const {id} = useParams();
  const [posts, setPosts] = useState([]);

  const [postMetaData, setPostMetaData] = useState({});
  const [userDetails, setUserDetails] = useState({});

  const [activePostTab, setActivePostTab] = useState("1");

  const data = useSelector(state => state);

  useEffect(() => {
    getDetails();
  }, [])

  const getDetails = () => {
    setUserDetails(data.userDetails.userDetails)
    getAllPosts(15)
  }

  const checkIsUserAdmin = (members) => {
    let memebersId = members.filter(v => v.userId === userDetails.id);
    return !!(memebersId.length && memebersId[0].isAdmin);
  }

  const getAllPosts = async (limit) => {
    const response = await getPosts(null, limit, id);
    if (response.status === 200 && response.data.data.postArray.length) {
      setPosts(response.data.data.postArray);
      const postData = {}
      response.data.data.postArray.forEach(post => {
        postData[post.id] = {commentCount: post.commentCount, reactionCount: post.reactionCount}
      })
      setPostMetaData(postData)
    }
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        <div
          className={"d-flex align-items-center justify-content-center bg-white rounded-pill tw-flex-shrink-0 tw-cursor-pointer"}>
          <div
            className={`${activePostTab === "1" ? "text-white tw-bg-[#5457f8]" : "text-black"} tw-font-semibold tw-px-12 tw-py-1 rounded-pill`}
            onClick={() => setActivePostTab("1")}>Posts
          </div>
          <div
            className={`${activePostTab === "2" ? "text-white tw-bg-[#5457f8]" : "text-black"} tw-font-semibold tw-px-12 tw-py-1 rounded-pill`}
            onClick={() => setActivePostTab("2")}>Events
          </div>
        </div>
      </div>
      {activePostTab === "1" && (
        <FeedsSubPage organisationId={Number(id)}/>
      )}
      {activePostTab === "2" && (
        <div>
          {props.orgHasDetails.length && checkIsUserAdmin(props.members) &&
            <NewsFeedEvents
              posts={posts}
              huddleData={posts}
              postMetaData={postMetaData}
              setPostMetaData={setPostMetaData}
              setPosts={setPosts}
              noTopMargin
            />
          }
        </div>
      )}
    </>
  )
}

export default PostsOrg;