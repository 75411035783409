import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {initializeApp} from "firebase/app";

const VAPID_KEY =
    "BMOIrsj-HzDD2RKvvmMrL29WZmBTbUnE2JDEyYNZyZhytqRDsNo3ZeIlAgqI6LKf3JVPFybOMhC2ALhX1bpjXlU";

const firebaseConfig = {
    apiKey: "AIzaSyCIpKLSZE2GC79ZT1eUCaT5cFF5YkV1n-M",
    authDomain: "kaleidoscope-e73ac.firebaseapp.com",
    projectId: "kaleidoscope-e73ac",
    storageBucket: "kaleidoscope-e73ac.appspot.com",
    messagingSenderId: "1018777942030",
    appId: "1:1018777942030:web:6984af1a0b5c1b8b76e45f",
    measurementId: "G-G3PCX730HT"
};
initializeApp(firebaseConfig);

const messaging = getMessaging();

export const FirebaseToken = async () => {
    const messaging = getMessaging();
    try {
        return await getToken(messaging, {
            vapidKey: VAPID_KEY,
        });
    } catch (err) {
        return err.response;
    }
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
            // ...
        });
    });