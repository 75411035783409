import {Link} from "react-router-dom";

const NotFound = () => {
    return (
        <div className="bg-logged-in min-vh-100 d-flex justify-content-center align-items-center flex-column gap-2">
            <h1 className={"tw-text-8xl fw-semibold"}>404</h1>
            <h4 className={"tw-text-2xl fw-semibold"}>Page not found</h4>

            <Link to={"/"} className="mt-4 tw-bg-[#5458f7] inter rounded-pill border-0 fw-bold px-4 py-1 text-white">Back
                to home</Link>
        </div>
    )
}

export default NotFound;