import {Dispatch, SetStateAction, useState} from 'react';
import {bookSession} from '../../../utils/apiUrlsSetup'

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import "../../../css/timeExchange.css";

import Swal from "sweetalert2";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {OfferObject} from "../../../utils/ApiUrlsSetupTypes";
import { useZStore } from '../../../utils/zStore';

const availability_data_template: { Afternoon: number[]; Night: number[]; Morning: number[]; } = {
  Morning: [7, 8, 9, 10, 11],
  Afternoon: [12, 13, 14, 15, 16],
  Night: [17, 18, 19, 20, 21]
}

const DayMapper = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
}

const ExchangeTimeModal = (props: {
  offer: OfferObject,
  exchangeModalOpen: boolean,
  handleExchangeModal: Dispatch<SetStateAction<boolean>>,
}) => {
  const store = useZStore()
  const [selectedDatetime, setSelectedDatetime] = useState(new Date())

  const handleTimeSlot = (time: number) => {
    setSelectedDatetime(prev => {
      const copy = new Date(prev);
      copy.setHours(time)
      return copy
    })
  }

  const handleSubmit = async () => {
    await bookSession({
      offerId: props.offer.id,
      instructorId: props.offer.offerType === "gift" ? props.offer.userId : store.userDetails?.id,
      startDate: selectedDatetime,
      endDate: (() => {
        const copy = new Date(selectedDatetime)
        copy.setHours(selectedDatetime.getHours() + 1)
        return copy
      })(),
      timeSlot: selectedDatetime.getHours(),
      noOfHours: 1,
    })
    await Swal.fire("Congratulations!", "You have booked a session", "success")
    window.location.reload()
  }

  return (
    <Modal centered isOpen={props.exchangeModalOpen} toggle={() => props.handleExchangeModal(false)} size={"lg"}>
      <ModalHeader toggle={() => props.handleExchangeModal(false)} className={"align-items-start border-0 pb-0"}>
        <p className='mb-0 tw-font-medium tw-text-lg tw-text-center'>
          {props.offer.offerType === "gift" && (
            <>
              You are going to spend <span
              className={"tw-text-[#e138bc]"}>{props.offer.noOfHours} hour{props.offer.noOfHours > 1 ? 's' : ''}</span> of
              Well-being Time for accepting
              this gift
            </>
          )}
          {props.offer.offerType === "request" && (
            <>
              You are going to gain <span
              className={"tw-text-[#e138bc]"}>{props.offer.noOfHours} hour{props.offer.noOfHours > 1 ? 's' : ''}</span> of
              Well-being Time for accepting
              this requests
            </>
          )}
        </p>
      </ModalHeader>
      <ModalBody className={"tw-flex justify-content-center align-items-center tw-w-full flex-column gap-2"}>

        <Calendar
          onChange={(e: Date) => {
            setSelectedDatetime(e)
          }}
          value={selectedDatetime}
          next2Label={false}
          prev2Label={false}
          prevLabel={false}
          nextLabel={false}
          minDate={new Date()}
        />

        <div className="tw-grid md:tw-grid-cols-3 tw-w-full tw-gap-4 mt-3">
          {
            Object.keys(availability_data_template).map((ele, index) => {
              return (
                <div className='flex-grow-1 d-flex flex-column gap-2' key={index}>
                  <div
                    className='tw-bg-[#5458f7] tw-w-full tw-px-3 tw-pt-0.5 tw-pb-1 tw-text-white rounded tw-text-center'>
                    {ele}
                  </div>

                  <div className='tw-grid lg:tw-grid-cols-3 tw-gap-1'>
                    {props.offer.availability.includes(`${DayMapper[selectedDatetime.getDay()]}_${ele}`) ? (
                      <>
                        {
                          availability_data_template[ele].map((time, index) => {
                            return (
                              <button key={index}
                                      className={`btn ${time === selectedDatetime.getHours() ? 'btn-primary' : 'btn-outline-secondary'} rounded-pill btn-sm px-3`}
                                      onClick={() => handleTimeSlot(time)}>
                                {time}:00
                              </button>
                            )
                          })
                        }
                      </>
                    ) : (
                      <div className={"tw-col-span-3 text-secondary text-center"}>Not Available</div>
                    )}
                  </div>

                </div>
              )
            })
          }
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex align-items-center justify-content-center">
          <button type={"button"} className='white_box_cancel_one me-2' data-bs-dismiss="modal"
                  onClick={() => props.handleExchangeModal(false)}>Cancel
          </button>
          <button type={"button"} className='white_box_confirm_one ms-2' disabled={selectedDatetime.getHours() === 0}
                  onClick={handleSubmit}>Confirm
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ExchangeTimeModal
