import SideNav from "../../../component/staticPages/SideNav";
import Search from "../../../assets/search1.png";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {getOrgs, getOrgsForUser, joinOrg} from "../../../utils/apiUrlsSetup";
import CreateOrganizationModal from "../components/create-organization-modal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ResponsiveNav from "../../../component/staticPages/responsiveNavBar/responsiveNav";
import Swal from "sweetalert2";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 4000, min: 3000},
    items: 3,
  },
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 3,
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 2,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1,
  },
};

const NewOrganisation = () => {
  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [orgSearch, setOrgSearch] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [organizationsPublic, setOrganizationsPublic] = useState([]);
  const [organizationsBySearch, setOrganizationsBySearch] = useState([]);

  const [openModal, setOpenModal] = useState(false);

  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    getAllOrgs();
    getAllPublicOrgs();
    if (orgSearch) {
      getOrgBySearch(orgSearch);
    }
  }, [orgSearch]);

  const naviagetToOrgById = (v) => {
    setOrgSearch("");
    navigate(`/organisation/${v.id}`);
  };

  const getAllOrgs = async () => {
    const response = await getOrgsForUser("accepted");
    if (response.status === 200) {
      setOrganizations(response.data.data.organisationArray.organisationArray.filter(v => v.status !== "deleted"));
    }
  };

  const getAllPublicOrgs = async (id) => {
    const response = await getOrgs(id);
    if (response.status === 200) {
      setOrganizationsPublic(
        response.data.data.organisationArray.filter(
          (v) => v.privacy === "public" && v.status === "approved"
        )
      );
      setIsPageLoaded(true);
    }
  };

  const getOrgBySearch = async (orgSearch) => {
    const response = await getOrgs(null, orgSearch);
    if (response.status === 200) {
      let privateOrg = response.data.data.organisationArray ? response.data.data.organisationArray.filter(v => v.privacy === "private") : [];
      let findIsMember = privateOrg.map(v => v.members.filter(a => a.userId === userDetails.id && a.isAdmin).length ? [v] : []).filter(el => el.length);
      let filterPrivateOrg = response.data.data.organisationArray.filter(v => v.privacy !== "private");
      if (findIsMember.length) {
        setOrganizationsBySearch([...filterPrivateOrg, ...findIsMember[0]]);
      } else {
        setOrganizationsBySearch(filterPrivateOrg);
      }
    }
  };

  const findOrgUserName = (members) => {
    let findMembersAdmin = members.filter((v) => v.isAdmin);
    if (findMembersAdmin.length) {
      return `${findMembersAdmin[0].user.firstName} ${findMembersAdmin[0].user.lastName}`;
    } else {
      return "";
    }
  };

  const checkYouAreMemeberofGrp = (members) => {
    let memebersId = members
      .filter((v) => v.status !== "left")
      .filter((v) => v.userId === userDetails.id);
    if (!memebersId.length) {
      return true;
    } else {
      return false;
    }
  };

  const joinGroupFunc = async (id) => {
    let res = await joinOrg(id);
    if (res.status === 200) {
      Swal.fire("Pending approval", "You will receive a notification email once your membership is approved.", "info")
      getAllPublicOrgs();
    }
  };

  const checkUserIsPresent = () => {
    let i;
    for (i = 0; i < organizationsPublic.length; i++) {
      let members = organizationsPublic[i].members;
      let findUserPresent = members.filter(v => v.userId === userDetails.id).filter(v => v.isAdmin);
      if (findUserPresent.length) {
        return true;
      } else {
        if (organizations.length) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  return (
    <>
      {/* <div className="row m-0 w-100 d-lg-none d-none d-md-block  top_chat_input_tab_parent">
                <ResponsiveNav headerName="Community" heading="Community" organisations={true} organizationsArr={organizations} setOrdId={(id) => {
                    getAllOrgs(id)
                }} />
            </div> */}
      <div className="row m-0 background orgnization_web_view d-none d-lg-flex">
        <div className="col-12 p-0">
          <div className="row m-0 d-flex newsfeed_bg time_exchange_bg_full_height">
            <div className="news_left p-0 d-none d-lg-block">
              <SideNav organisations={true} organizationsArr={organizations} setOrdId={() => {
                getAllOrgs()
              }}/>
            </div>

            <div className="news_right time_exchange_auto_height px-5 mb-5">
              <div className="col-12 p-0 header">
                <div className="row m-0">
                  <div className="col-12 p-0">
                    <div className="input-group neworg_searchbar mt-4 mt-lg-0">
                                            <span class="input-group-text px-4" id="basic-addon1">
                                                {" "}
                                              <img src={Search} alt=""/>{" "}
                                            </span>
                      <input
                        type="text"
                        class="form-control p-0"
                        value={orgSearch}
                        onChange={(e) => setOrgSearch(e.target.value)}
                        placeholder="Search for a Community"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />

                      {isPageLoaded && userDetails.canCreateOrganisation ? <button
                        className="create_new_group create_new_org_btn"
                        onClick={() => setOpenModal(true)}
                      >
                        Create New Community
                      </button> : null}

                      {orgSearch.length && organizationsBySearch.length ? (
                        <div className="org_search_list">
                          {organizationsBySearch.map((v, i) => {
                            return (
                              <div
                                key={i}
                                className="parent_div row m-0"
                                onClick={() => naviagetToOrgById(v)}
                              >
                                <div className="col-1 p-0">
                                  <img
                                    src={v.image ? v.image : ""}
                                    width="60px"
                                    height="60px"
                                    alt=""
                                  />
                                </div>
                                <div className="col-10">
                                  <div className="col-12 p-0 org_naming mb-2">
                                    {v.name}
                                  </div>
                                  <div className="col-12 p-0 d-flex align-items-center sub_names">
                                    <p className="mb-0">
                                      {findOrgUserName(v.members)}
                                    </p>
                                    <p className="mb-0 ps-2">
                                      {moment(v.createdAt).format(
                                        "DD MMM YYYY"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row m-0 my_community_carousel_cards">
                <div className="col-lg-8 col-md-12 p-0">
                  <p className="new_community_heading mt-4">My Communities</p>
                  {organizations.length ? <div className="row m-0 align-items-center">
                    <div className="col-6 p-0 new_community_sub_heading">
                      Communities you are in
                    </div>
                    <div
                      className="col-6 p-0 community_see_all_link d-flex justify-content-end cursor_pointer"
                      onClick={() =>
                        navigate(`/all-organization`, {state: "my_org"})
                      }
                    >
                      See all
                    </div>
                  </div> : null}

                  {!organizations.length ? <p className="text-center my-5">No record found</p> : null}


                  <div className="row m-0 mt-4">
                    <div className="col-12 p-0">
                      <Carousel
                        responsive={responsive}
                        swipeable={true}
                        draggable={true}
                        keyBoardControl={true}
                      >
                        {organizations.map((val) => {
                          return (
                            <div
                              className="new_community_card cursor_pointer"
                              key={val.id}
                              onClick={() =>
                                navigate(`/organisation/${val.id}`)
                              }
                            >
                              <img src={val.image} alt=""/>
                              <p>{val.name}</p>
                              <span>{val.members ? val.members.filter(v => v.status === "accepted").length : 0} members | {val.avgPostCount} + posts a day</span>
                            </div>
                          );
                        })}
                      </Carousel>
                    </div>
                  </div>

                  <hr className="my-4"/>

                  <p className="new_community_heading mt-4">
                    Public Communities
                  </p>
                  {organizationsPublic.length ? <div className="row m-0 align-items-center">
                    <div className="col-6 p-0 new_community_sub_heading">
                      Other communities you can join
                    </div>
                    <div
                      className="col-6 p-0 community_see_all_link d-flex justify-content-end cursor_pointer"
                      onClick={() =>
                        navigate(`/all-organization`, {state: "public_org"})
                      }
                    >
                      See all
                    </div>
                  </div> : null}

                  {!organizationsPublic.length ? <p className="text-center my-5">No record found</p> : null}


                  <div className="row m-0 mt-4">
                    <div className="col-12 p-0">
                      <Carousel
                        responsive={responsive}
                        swipeable={true}
                        draggable={true}
                        keyBoardControl={true}
                      >
                        {organizationsPublic.map((val) => {

                          return (
                            <div
                              className="new_community_card cursor_pointer"
                              key={val.id}
                              onClick={() =>
                                navigate(`/organisation/${val.id}`)
                              }
                            >
                              <img src={val.image} alt=""/>
                              <p>{val.name}</p>
                              <span>{val.members ? val.members.filter(v => v.status === "accepted").length : 0} members | {val.avgPostCount} + posts a day</span>
                              {checkYouAreMemeberofGrp(val.members) ? (
                                <div className="community_join_grp_btn_body">
                                  <button
                                    className="community_join_grp_btn"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      joinGroupFunc(val.id);
                                    }}
                                  >
                                    Join Community
                                  </button>
                                </div>
                              ) : (
                                <div className="community_join_grp_btn_body">
                                  <button
                                    className="community_join_grp_btn"
                                    disabled
                                  >
                                    Already Joined
                                  </button>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mob_tab_responsive_view responsive_background">
        <div className="row m-0 pb-3 pb-md-4">
          <ResponsiveNav heading="Communities" headerName="Organisations" organizationsArr={organizations}
                         setOrdId={(id) => {
                           getAllOrgs()
                         }}/>
        </div>

        <div className="row m-0">
          <div className="col-12 p-0 px-lg-5 px-3 d-flex align-items-center justify-content-center">
            <div className="input-group neworg_searchbar mt-2">
                            <span class="input-group-text px-4" id="basic-addon1">
                                {" "}
                              <img src={Search} alt=""/>{" "}
                            </span>
              <input
                type="text"
                class="form-control p-0"
                value={orgSearch}
                onChange={(e) => setOrgSearch(e.target.value)}
                placeholder="Search for a Community"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />

              {orgSearch.length && organizationsBySearch.length ? (
                <div className="org_search_list">
                  {organizationsBySearch.map((v, index) => {
                    return (
                      <div
                        key={index}
                        className="parent_div row m-0"
                        onClick={() => naviagetToOrgById(v)}
                      >
                        <div className="col-2 p-0">
                          <img
                            src={v.image ? v.image : ""}
                            width="60px"
                            height="60px"
                            alt=""
                          />
                        </div>
                        <div className="col-10">
                          <div className="col-12 p-0 org_naming mb-2">
                            {v.name}
                          </div>
                          <div className="col-12 p-0 d-flex align-items-center sub_names">
                            <p className="mb-0">{findOrgUserName(v.members)}</p>
                            <p className="mb-0 ps-2">
                              {moment(v.createdAt).format("DD MMM YYYY")}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {userDetails.canCreateOrganisation ? <div className="row m-0 mt-3">
          <div className="col-12 p-0 px-3 mt-4">
            <button
              className="create_new_group w-100"
              onClick={() => setOpenModal(true)}
            >
              Create New Community
            </button>
          </div>
        </div> : null}


        <div className="row m-0 px-3">
          <div className="col-12 p-0">
            <p className="new_community_heading mt-4">My Communities</p>

            {organizations.length ?
              <div className="row m-0 align-items-center">
                <div className="col-12 p-0 new_community_sub_heading">
                  Communities you are in
                </div>
              </div> : null}


            <div className="row m-0 mt-4">
              <div className="col-12 p-0">
                <div className="row m-0">
                  {organizations.slice(0, 3).map((val) => {
                    return (
                      <div className="col-12 p-0 mb-4">
                        <div
                          className="new_community_card w-100 cursor_pointer"
                          key={val.id}
                          onClick={() => navigate(`/organisation/${val.id}`)}
                        >
                          <img src={val.image} alt=""/>
                          <p>{val.name}</p>
                          <span>{val.members ? val.members.filter(v => v.status === "accepted").length : 0} members | {val.avgPostCount} + posts a day</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {organizations.length ?
              <div
                className="col-12 p-0 community_see_all_link d-flex justify-content-center cursor_pointer"
                onClick={() => navigate(`/all-organization`, {state: "my_org"})}
              >
                See all
              </div> : null}
          </div>
        </div>

        <hr/>

        <div className="row m-0 px-3 pb-5">
          <div className="col-12 p-0">
            <p className="new_community_heading mt-4">Public Communities</p>
            <div className="row m-0 align-items-center">
              <div className="col-12 p-0 new_community_sub_heading">
                Other communities you can join
              </div>
            </div>

            <div className="row m-0 mt-4">
              <div className="col-12 p-0">
                <div className="row m-0">
                  {organizationsPublic.slice(0, 3).map((val) => {
                    return (
                      <div className="col-12 p-0 mb-4">
                        <div
                          className="new_community_card w-100 cursor_pointer"
                          key={val.id}
                          onClick={() => navigate(`/organisation/${val.id}`)}
                        >
                          <img src={val.image} alt=""/>
                          <p>{val.name}</p>
                          <span>{val.members ? val.members.filter(v => v.status === "accepted").length : 0} members | {val.avgPostCount} + posts a day</span>
                          <div className="community_join_grp_btn_body">
                            <button
                              className="community_join_grp_btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                joinGroupFunc(val.id);
                              }}
                            >
                              Join Community
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div
              className="col-12 p-0 community_see_all_link d-flex justify-content-center cursor_pointer"
              onClick={() => navigate(`/all-organization`, {state: "public_org"})}
            >
              See all
            </div>
          </div>
        </div>

      </div>

      {openModal ? (
        <CreateOrganizationModal
          setOpenModal={(value) => {
            getAllOrgs();
            setOpenModal(value);
          }}
          openModal={openModal}
        />
      ) : null}
    </>
  );
};

export default NewOrganisation;
