import React from 'react';

import Search from "../../assets/search1.png";
import CoverImg from "../../assets/svg/Organization/CoverImg.png";

import LogoTab from "../../assets/Logo_Tab.png";
import NotificationBellResponsive from "../../assets/svg/NotificationBellResponsive.svg";
import HamburgerResponsive from "../../assets/svg/HamburgerResponsive.svg";

const organisationMob = () => {
  return (
    <>
      {/* Condition-4 */}

      <div className='row m-0'>
        <div className='col-12 p-0'>

          <div className='row m-0 pb-5 pb-md-0 pb-lg-0 mob_tab_responsive_view responsive_background'>
            <div className='col-12 p-0 pb-5'>

              <div className='row m-0 p-0'>
                <div className='col-12 p-0 org_resp_top'>

                  <div className='row m-0 pt-3 pb-3 pt-md-4 pb-md-4 ps-4 pe-4'>
                    <div className='col-4 p-0 d-flex align-items-center'>
                      <img className='responsive_nav_logo' src={LogoTab} alt=''/>
                    </div>

                    <div className='col-4 p-0 d-flex align-items-center justify-content-center'>
                      <p className='mb-0 responsive_page_name'>Groups</p>
                    </div>

                    <div className='col-4 p-0 d-flex align-items-center justify-content-end'>
                      <img className='me-4 responsive_nav_notification_bell' src={NotificationBellResponsive} alt=''/>
                      <img className='responsive_nav_hamburger cursor_pointer' class="navbar-toggler" type="button"
                           data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText"
                           aria-expanded="false" aria-label="Toggle navigation" src={HamburgerResponsive} alt=''/>
                    </div>
                  </div>

                  <div className='row m-0'>
                    <div className='col-12 p-0 px-5 d-flex align-items-center justify-content-center'>

                      <div className="input-group neworg_searchbar mt-2">
                        <span class="input-group-text px-4" id="basic-addon1"> <img src={Search} alt=''/> </span>
                        <input type="text" class="form-control p-0" placeholder="Search for a Community"
                               aria-label="Username" aria-describedby="basic-addon1"/>
                      </div>

                    </div>
                  </div>

                  <div className='row m-0 mt-3'>
                    <div className='col-12 p-0'>

                      <img style={{width: "100%"}} src={CoverImg} alt=''/>
                    </div>
                  </div>

                  <div className='row m-0 mt-4 ps-4 pe-4'>
                    <div className='col-12 p-0 py-1 brd_bottom'>
                      <p className='mb-0 rep_org_heading'>The Yoga Collective</p>
                      <p className='mb-0 rep_org_info'>Public Group &bull; 1,500 members</p>
                    </div>
                  </div>

                  <div className='row m-0 mt-3 ps-4 pe-4'>
                    <div className='col-12 p-0'>
                      <button className='resp_org_btn resp_org_btn_type_two'>Joined Group</button>
                    </div>
                  </div>

                  <div className='row m-0 mt-3 ps-4 pe-4'>
                    <div className='col-12 p-0 d-flex align-items-center justify-content-between'>
                      <button className="p-0 p-2 new_org_header_btns">About</button>
                      <button className="p-0 p-2 new_org_header_btns">Posts</button>
                      <button className="p-0 p-2 new_org_header_btns act_new_org_header_btns">Members</button>
                    </div>
                  </div>

                </div>
              </div>

              <div className='row m-0 mt-1'>
                <div className='col-12 p-0'>

                  <div className='responsove_tbl'>

                    <div className='user_list_border'>

                      <div className='row m-0 d-flex p-3 brd_bottom'>
                        <div className='col-10 p-0 d-flex justify-content-start align-items-center ps-1'>
                          <div className='admin_user_profile'>
                            <img src={"/static/png/Male.png"} alt=''/>
                          </div>
                          <p className='mb-0 ms-2 admin_userlist_profile_name'>Muhammad Deferdauz</p>
                        </div>
                        <div className='col-2 p-0 d-flex justify-content-center align-items-center'>
                          <p className='mb-0 admin_userlist_name'>User</p>
                        </div>
                      </div>
                      <div className='row m-0 d-flex p-3 brd_bottom'>
                        <div className='col-10 p-0 d-flex justify-content-start align-items-center ps-1'>
                          <div className='admin_user_profile'>
                            <img src={"/static/png/Male.png"} alt=''/>
                          </div>
                          <p className='mb-0 ms-2 admin_userlist_profile_name'>Muhammad Deferdauz</p>
                        </div>
                        <div className='col-2 p-0 d-flex justify-content-center align-items-center'>
                          <p className='mb-0 admin_userlist_name'>User</p>
                        </div>
                      </div>
                      <div className='row m-0 d-flex p-3 brd_bottom'>
                        <div className='col-10 p-0 d-flex justify-content-start align-items-center ps-1'>
                          <div className='admin_user_profile'>
                            <img src={"/static/png/Male.png"} alt=''/>
                          </div>
                          <p className='mb-0 ms-2 admin_userlist_profile_name'>Muhammad Deferdauz</p>
                        </div>
                        <div className='col-2 p-0 d-flex justify-content-center align-items-center'>
                          <p className='mb-0 admin_userlist_name'>User</p>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

    </>
  )
}

export default organisationMob