import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
    updateComment,
    getReportedCommentlist,
    getReportedPostlist,
    getUserlist,
    reportAnCommentApi,
    reportAnPost,
    updatePost
} from '../../utils/apiUrlsSetup';
import LoggedInLayout from "../../component/layout/LoggedInLayout.tsx";

const Reports = () => {

    const ref = useRef()

    const navigate = useNavigate();

    const [postList, setPostList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [filterValue, setFilterValue] = useState("Posts");

    const [filterShow, setFilterShow] = useState(false)
    const [rank, setRank] = useState(false)

    window.click = () => {
        setFilterShow(false)
    }

    const getUserslist = async () => {
        let responseData = await getUserlist();
        if (responseData.status === 200) {
            setUserList(responseData.data.data.usersArray)
        }
    }

    const fetchReportedPosts = async () => {
        let response = await getReportedPostlist();
        if (response && response.status == 200) {
            setPostList(response.data.data.postArray)
        }
    }

    const fetchReportedComments = async () => {
        let response = await getReportedCommentlist();
        if (response && response.status == 200) {
            setPostList(response.data.data.commentArray)
        }
    }

    useEffect(() => {
        (async () => {
            await Promise.all([
                getUserslist(),
                fetchReportedPosts(),
                fetchReportedComments()
            ])
        })();
    }, [])

    const handleProfile = (id, role) => {
        if (role === "user") {
            navigate(`/admin/user-profile/${id}`)
        } else {
            navigate(`/admin/coach-profile/${id}`)
        }
    }

    const handleDelete = async (id) => {
        let obj = {
            isDeleted: true
        }

        if (filterValue === "Posts") {
            let response = await updatePost(id, obj);
            if (response && response.status === 200) {
                fetchReportedPosts()
            }
        } else if (filterValue === "Comments") {
            let response = await updateComment(id, obj);
            if (response && response.status === 200) {
                fetchReportedComments()
            }
        }
    }

    const handleUnReport = async (id) => {
        let obj = {
            isReported: false
        }

        if (filterValue === "Posts") {
            let response = await reportAnPost(id, obj);
            if (response && response.status === 200) {
                fetchReportedPosts()
            }
        } else if (filterValue === "Comments") {
            let response = await reportAnCommentApi(id, obj);
            if (response && response.status === 200) {
                fetchReportedComments()
            }
        }
    }

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (filterShow && ref.current && !ref.current.contains(e.target) || rank && ref.current && !ref.current.contains(e.target)) {
                setFilterShow(false)
                setRank(false)
            }
        }

        document.addEventListener("click", checkIfClickedOutside)

        return () => {
            document.removeEventListener("click", checkIfClickedOutside)
        }
    }, [filterShow, rank])

    return (
        <LoggedInLayout title={"Reports"}>

            <div className={"table-responsive"}>
                <table className="table vertical-middle">
                    <thead>
                    <tr>
                        <th className={"fw-semibold tw-text-xs !tw-text-opacity-50 !tw-text-[#334d6e] montserrat "}>
                            Name
                        </th>
                        <th className={"d-none d-lg-table-cell fw-semibold tw-text-xs !tw-text-opacity-50 !tw-text-[#334d6e] montserrat "}>
                            Description
                        </th>
                        <th className={"d-none d-lg-table-cell fw-semibold tw-text-xs !tw-text-opacity-50 !tw-text-[#334d6e] montserrat "}>
                            Attachment
                        </th>
                        <th className={'d-none d-lg-table-cell fw-semibold tw-text-xs !tw-text-opacity-50 !tw-text-[#334d6e] montserrat text-center'}>
                            Action
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        !postList.length && (
                            <tr>
                                <td className="text-center" colSpan={4}>No Reports</td>
                            </tr>
                        )
                    }

                    {
                        postList.map((val) => {
                            return (
                                <tr key={val.id} className={"tw-text-sm"}>
                                    <td>
                                        <div
                                            className='d-flex align-items-center gap-2 py-1'>
                                            <img
                                                className={"tw-object-cover rounded-circle tw-h-[30px] tw-w-[30px]"}
                                                src={val.user.profilePic ? val.user.profilePic : "/static/png/Male.png"} alt=''/>

                                            <p className='admin_userlist_profile_name tw-text-sm '
                                               onClick={() => handleProfile(val.id, val.role)}>{val.firstName} {val.lastName}</p>
                                        </div>
                                        <div className={"d-flex flex-column gap-1 d-lg-none"}>
                                            <div className={"row tw-text-xs text-secondary w-100"}>
                                                <div className="col-3">Description</div>
                                                <div className="col-9">
                                                    {filterValue === "Posts" ? <><p
                                                        className='mb-0 admin_userlist_name'>{val.description}</p></> : <>
                                                        <p
                                                            className='mb-0 admin_userlist_name'
                                                            dangerouslySetInnerHTML={{__html: val.comment}}></p></>}
                                                </div>
                                            </div>
                                            <div className={"row tw-text-xs text-secondary w-100"}>
                                                <div className="col-3">Attachment</div>
                                                <div className="col-9">

                                                    {
                                                        <p className='mb-0 admin_userlist_name'>{val.attachment ? val.attachment.map(a => <>
                                                            <a target="_blank" href={a}
                                                               rel="noreferrer">{a}</a><br/></>) : null}</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className={"row tw-text-xs text-secondary w-100"}>
                                                <div className="col-3">Tags</div>
                                                <div className="col-9">Public</div>
                                            </div>
                                            <div className={"mb-1"}>
                                                <button className='user_reactive_btn'
                                                        onClick={() => handleUnReport(val.id)}>Unreport
                                                </button>

                                                <button className='user_reactive_btn me-1'
                                                        onClick={() => handleDelete(val.id)}>Delete
                                                </button>
                                            </div>
                                        </div>
                                    </td>

                                    <td className={"d-none d-lg-table-cell !tw-text-opacity-50 !tw-text-[#334d6e]"}>
                                        {filterValue === "Posts" ? <><p
                                            className='mb-0 admin_userlist_name'>{val.description}</p></> : <><p
                                            className='mb-0 admin_userlist_name'
                                            dangerouslySetInnerHTML={{__html: val.comment}}></p></>}
                                    </td>
                                    <td className={"d-none d-lg-table-cell !tw-text-opacity-50 !tw-text-[#334d6e]"}>
                                        {
                                            <p className='mb-0 admin_userlist_name'>{val.attachment ? val.attachment.map(a => <>
                                                <a target="_blank" href={a}
                                                   rel="noreferrer">{a}</a><br/></>) : null}</p>
                                        }
                                    </td>
                                    <td className={"d-none d-lg-table-cell"}>
                                        <button className='user_reactive_btn'
                                                onClick={() => handleUnReport(val.id)}>Unreport
                                        </button>

                                        <button className='user_reactive_btn me-1'
                                                onClick={() => handleDelete(val.id)}>Delete
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>

            <div className='row'>
                <div className='col-12 p-0 payment_method pb-4'>
                    {
                        postList.map((val) => {
                            return <div className='user_list_border'>

                                <div className='row m-0 d-flex p-3'>
                                    <div className='div-2 p-0 d-flex justify-content-start align-items-center ps-1'>
                                        <div className='admin_user_profile'>
                                            <img src={val.user.profilePic ? val.user.profilePic : "/static/png/Male.png"} alt=''/>
                                        </div>
                                        <p className='mb-0 ms-2 admin_userlist_profile_name'
                                           onClick={() => handleProfile(val.id, val.role)}>{val.user.firstName} {val.user.lastName}</p>
                                    </div>
                                    <div className='div-3 p-0 d-flex justify-content-start align-items-center'>
                                        {filterValue === "Posts" ? <><p
                                            className='mb-0 admin_userlist_name'>{val.description}</p></> : <><p
                                            className='mb-0 admin_userlist_name'
                                            dangerouslySetInnerHTML={{__html: val.comment}}></p></>}
                                    </div>
                                    <div className='div-3 p-0 d-flex justify-content-start align-items-center'>
                                        {
                                            <p className='mb-0 admin_userlist_name'>{val.attachment ? val.attachment.map(a => <>
                                                <a target="_blank" href={a}
                                                   rel="noreferrer">{a}</a><br/></>) : null}</p>
                                        }

                                    </div>

                                    <div className='div-7 p-0 d-flex justify-content-end align-items-center'>

                                        <button className='user_reactive_btn'
                                                onClick={() => handleUnReport(val.id)}>Unreport
                                        </button>
                                    </div>
                                    <div className='div-7 p-0 d-flex justify-content-start align-items-center'
                                         style={{marginLeft: '10px'}}>
                                        <button className='user_reactive_btn'
                                                onClick={() => handleDelete(val.id)}>Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </LoggedInLayout>
    )
}

export default Reports