import React, { useState, useEffect } from "react";
import Logo_Mobile from "../../../assets/Logo_Mobile.png";
import Hamburger from "../../../assets/Hamburger.png";
import mobileNotification from "../../../assets/mobile-notification.png";
import List_alt_mobile from "../../../assets/list_alt_mobile.png";
import Calendar_mobile from "../../../assets/calendar_mobile.png";
import Greenright from "../../../assets/greenright.png";
import Redcross from "../../../assets/redcross.png";
import Left_arrow from "../../../assets/svg/LeftVector.svg";
import Right_arrow from "../../../assets/svg/RightVector.svg";
import Chat from "../../../assets/svg/SideNav/Chat.svg";
import Seacrh from "../../../assets/svg/WhiteSearch.svg";
import Orange from "../../../assets/orange_rectangle.png";
import ResponsiveNav from "./../../../component/staticPages/responsiveNavBar/responsiveNav";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import comm_eve_1 from "../../../assets/comm_eve_1.png";
import comm_eve_2 from "../../../assets/comm_eve_2.png";
import comm_eve_3 from "../../../assets/comm_eve_3.png";
import BackCross from "../../../assets/BackCross.png";
import Back_Mobile from "../../../assets/Back_Mobile.png";
import chevrondown from "../../../assets/chevron-down.png";
import Video from '../../../assets/videocam_comm.png';
import { createChat } from "../../../utils/apiUrlsSetup";

const CommunityCoachesMobile = (props) => {
  const [events, setEvents] = useState([]);

  const navigateToLink = useNavigate();

  const [filteredSession, setFilteredSession] = useState([]);

  const [currentMonth, setCurrentMonth] = useState(
    moment().format("MMMM YYYY")
  );

  const [currentMonthMoment, setCurrentMonthMoment] = useState(moment());

  const [duration, setDuration] = useState("Monthly");

  const [firstName, setFirstName] = useState("");

  const [filteredEvents, setFilteredEvents] = useState([]);

  const [showEventModal, setShowEventModal] = useState(false);
  const [currentEventDetails, setCurrentEventDetails] = useState({});

  const [isOpen, setIsOpen] = useState(false);
  const [moreEvents, setMoreEvents] = useState([]);


  const [showSessionModal, setShowSessionModal] = useState(false);
  const [currentSessionDetails, setCurrentSessionDetails] = useState({});

  const [currentWeekMoment, setCurrentWeekMoment] = useState(moment())

  const [currentWeek, setCurrentWeek] = useState(Math.ceil(moment().date() / 7));

  const calendarStyle = (event) => {
    return {
      style: {
        backgroundColor: 'white',
        color: "black", //but why doesn't this work?
        borderLeft: `3px solid ${event.status === "pending" ? "yellow" : event.status === "rejected" ? "red" : "green"}`,
        borderRadius: '0px'
      },
    };
  };

  useEffect(() => {
    var data = [...props.sessions];
    if (data.length) {
      var filteredData = data.filter(
        (d) =>
          moment(d.startDate).utc().format("MMMM YYYY") ===
          currentMonthMoment.format("MMMM YYYY")
      );
      var evts = filteredData.map((data, i) => {
        return {
          end: data.endDate,
          start: data.startDate,
          title: "1-1 Session",
          backgroundColor: "#ff0000",
          key: i
        };
      });
      setEvents(evts);
      setFilteredSession(filteredData);
    }
  }, [props.sessions]);

  useEffect(() => {
    var data = [...props.eventData];
    if (data.length) {
      var filteredData = data.filter(
        (d) =>
          moment(d.event.startDate).utc().format("MMMM YYYY") ===
          currentMonthMoment.format("MMMM YYYY")
      );
      setFilteredEvents(filteredData);
    }
  }, [props.eventData]);

  useEffect(() => {
    if (props.activeTab !== "List") {
      var data = [...props.sessions];
      var evts = data.map((value) => {
        return {
          end: value.endDate.split("T")[0],
          start: value.startDate.split("T")[0],
          title: "1-1 Session",
          backgroundColor: "#ff0000",
          status: value.status,
          data: value,
          type: "session"
        };
      });
      data = [...props.eventData];
      data.forEach((ele) => {
        evts.push({
          end: ele.event.endDate.split("T")[0],
          start: ele.event.startDate.split("T")[0],
          title: `${ele.event.name}`,
          backgroundColor: "#ff0000",
          status: "",
          data: ele.event,
          type: "event"
        });
      });
      setEvents(evts);
    }
  }, [props.activeTab]);

  const handleDuration = (event) => {
    const value = event.target.value;
    setDuration(value);
  };

  const changeWeek = (direction) => {
    var mondayDate
    var sundayDate
    var data = [...props.sessions]
    if (direction === 'right') {
      if (currentWeek < 4) {
        setCurrentWeek(currentWeek + 1)
        var nextWeek = moment(currentWeekMoment._d).add(1, 'week')
        setCurrentWeekMoment(nextWeek)
        mondayDate = moment(nextWeek._d).clone().weekday(1)
        sundayDate = moment(nextWeek._d).clone().weekday(7)
      }
    } else {
      if (currentWeek > 1) {
        setCurrentWeek(currentWeek - 1)
        var prevWeek = moment(currentWeekMoment._d).subtract(1, 'week')
        setCurrentWeekMoment(prevWeek)
        mondayDate = moment(prevWeek._d).clone().weekday(1)
        sundayDate = moment(prevWeek._d).clone().weekday(7)
      }
    }

    if (currentWeek < 5 && currentWeek > 1) {
      var filteredData = data.filter(d => moment(d.startDate)._d >= mondayDate._d && moment(d.startDate)._d <= sundayDate._d)
      data = [...props.eventData]
      var filteredEvents = data.filter(d => moment(d.event.startDate)._d >= mondayDate._d && moment(d.event.startDate)._d <= sundayDate._d)
      setFilteredEvents(filteredEvents)
      setFilteredSession(filteredData)
    }
  }

  const changeMonth = (direction) => {
    var date;
    var data = [...props.sessions];
    if (direction === "right") {
      date = currentMonthMoment.add(1, "month");
    } else {
      date = currentMonthMoment.subtract(1, "month");
    }

    var filteredData = data.filter(
      (d) =>
        moment(d.startDate).utc().format("MMMM YYYY") === date.format("MMMM YYYY")
    );
    data = [...props.eventData];
    var filteredEvents = data.filter(
      (d) =>
        moment(d.event.startDate).utc().format("MMMM YYYY") ===
        date.format("MMMM YYYY")
    );
    setFilteredEvents(filteredEvents);
    setFilteredSession(filteredData);
    setCurrentMonthMoment(date);
    setCurrentMonth(date.format("MMMM YYYY"));
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    setFirstName(capitalizeFirstLetter(props.userDetails.firstName));
  }, []);

  const handleRequest = async (session, flag) => {
    var status
    if (session.userId === props.userDetails.id) {
      status = 'cancelled'
    } else if (flag === 'left' && session.instructorId === props.userDetails.id) {
      status = 'rejected'
    } else if (flag === 'right' && session.instructorId === props.userDetails.id) {
      status = 'confirmed'
    }

    const response = await props.updateBid({ status: status, oneToOneSessionId: session.id })

    if (response && response.status === 200) {
      setShowSessionModal(false);
      await props.getSessionData()
    }
  }

  const rediretToChatPage = async (id) => {
    let obj = {
      membersIdArray: [id],
    };
    let res = await createChat(obj);
    if (res.status === 200) {
      navigateToLink("/chat", { state: id });
    }
  };

  const updateEventInvite = async (status, event) => {
    const data = { status: status }
    const response = await props.updateEventInvitation(event.eventId, data)
    if (response && response.status === 200) {
      await props.getEventData();
      setShowEventModal(false);
    }
  }

  return (
    <div>
      <div className="row m-0 newsfeed_mobile comminty_coaches_mobile ">
        <div className="col-12 p-0">
          <ResponsiveNav heading="Experiences" />

          <div className="row m-0 mobile_post">
            <div className="col-12 p-0 p-3">
              <div className="row m-0">
                <div className="col-12 p-0 d-flex justify-content-center align-items-center">
                  <div className="list_calender_mobile d-flex justify-content-start align-items-center ps-1 pe-1">
                    <div className={`${props.activeTab === "List" ? "active" : ""} cursor_pointer List_alt_mobile d-flex justify-content-center align-items-center`} onClick={() => props.setActiveTab("List")}>

                      <p className="mb-0 ms-2">List </p>
                    </div>
                    <div className={`${props.activeTab === "Calendar" ? "active" : ""} cursor_pointer List_alt_mobile d-flex justify-content-center align-items-center`} onClick={() => props.setActiveTab("Calendar")}>

                      <p className="mb-0 mx-2">Calendar</p>
                    </div>
                  </div>
                </div>
              </div>



              <div className="row m-0 mt-3">
                <div className="col-12 p-0 d-flex justify-content-between align-items-center">
                  <div className="">
                    <p className="mb-0 comm_month_mobile ">{currentMonth}</p>
                  </div>
                  <div className="">
                    <p className=" mb-0 comm_week_mobile">
                      <img className="" src={Left_arrow} onClick={() => duration === 'Monthly' ? changeMonth('left') : changeWeek('left')} /> {duration === 'Monthly' ? currentMonth : `Week ${currentWeek} of 5`}{" "}
                      <img className="" src={Right_arrow} onClick={() => duration === 'Monthly' ? changeMonth('right') : changeWeek('right')} />
                    </p>
                  </div>
                  <div className="">
                    <div class="btn-group pt-1" style={{ backgroundColor: '#fff' }}>
                      <select value={duration} name="duration" className="form-select admin_select_userdetails admin_select_userdetails_md admin_sel" aria-label="Default select example" onChange={handleDuration}>
                        <option value="Weekly">Weekly</option>
                        <option value="Monthly">Monthly</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row m-0 mt-3'>
                <div className='col-12 p-0'>
                  <p className=' mb-0 session'>1-1 Sessions</p>
                </div>
              </div>


              {props.activeTab === "List" ? <>
                <div className="row m-0 mt-3">
                  <div className="col-12 p-0">
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item accor_item">
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            className="accordion-button comm_accordion_btn_mobile orange_btn justify-content-between"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <p className="session_btn_mobile px-3 pt-3">{`PENDING REQUEST (${filteredSession.filter(
                              (session) => session.status === "pending"
                            ).length
                              })`}</p>
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          class="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            {filteredSession.filter(
                              (session) => session.status === "pending"
                            ).length > 0 ? (
                              filteredSession.map((session, index) => {
                                if (session.status === "pending") {
                                  return (
                                    <div className="row m-0" key={index}>
                                      <div className="col-12 p-0">
                                        <div className="row m-0">
                                          <div className="col-12 p-0 d-flex justify-content-start align-items-center">
                                            <img className="me-2" src={Orange} />
                                            <p className="mb-0 session_detail ms-2">{`Sessions with ${session.instructor.id !== props.userDetails.id ? session.instructor.firstName : session.user.firstName}`}</p>
                                          </div>
                                        </div>

                                        <div className="row m-0 d-flex">
                                          <div className="col-6 p-0">
                                            <p className="mb-0 session_date_mobile ms-4">
                                              {moment(session.startDate).utc().format(
                                                "DD-MM-YYYY"
                                              )}
                                            </p>

                                            <div className='ms-4 my-2'>
                                              <button className='mental'>Learning</button>
                                            </div>
                                          </div>



                                          <div className="col-6 p-0 d-flex flex-column ">
                                            {
                                              session.userId !== props.userDetails.id ?
                                                (<p onClick={() => handleRequest(session, 'right')} className="mb-0 session_date_mobile green d-flex justify-content-end align-items-center cursor_pointer">
                                                  <img
                                                    className="pe-2"
                                                    src={Greenright}
                                                    alt="Greenright"
                                                  />
                                                  Accept
                                                </p>) : null
                                            }

                                            <p onClick={() => handleRequest(session, 'left')} className="cursor_pointer mb-0 session_date_mobile red d-flex justify-content-end align-items-center mt-2">
                                              <img
                                                className="pe-2"
                                                src={Redcross}
                                                alt="Redcross"
                                              />
                                              Reject
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              })
                            ) : (
                              <div className="row m-0">
                                <div className="col-12 p-0">
                                  No Pending Request
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0 mt-3">
                  <div className="col-12 p-0">
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item accor_item">
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            className="accordion-button collapsed comm_accordion_btn_mobile green_btn justify-content-between"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="true"
                            aria-controls="collapseTwo"
                          >
                            <p className="session_btn_mobile px-3 pt-3">{`CONFIRMED (${filteredSession.filter(
                              (session) => session.status === "confirmed"
                            ).length
                              })`}</p>
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            {filteredSession.filter(session => session.status === 'confirmed').length > 0 ? (
                              filteredSession.map((session, index) => {
                                if (session.status === "confirmed") {
                                  return (
                                    <div className="row m-0" key={index}>
                                      <div className="col-12 p-0">
                                        <div className="row m-0">
                                          <div className="col-12 p-0 d-flex justify-content-start align-items-center">
                                            <img className="me-2" src={Orange} />
                                            <p className="mb-0 session_detail ms-2">{`Sessions with ${session.instructor.id !== props.userDetails.id ? session.instructor.firstName : session.user.firstName}`}</p>
                                          </div>
                                        </div>

                                        <div className="row m-0 d-flex align-items-center">
                                          <div className="col-6 p-0">
                                            <p className="mb-0 session_date_mobile ms-4">
                                              {moment(session.startDate).utc().format(
                                                "DD-MM-YYYY"
                                              )}
                                            </p>
                                            <div className=""></div>
                                          </div>

                                          <div className='col-6 d-flex justify-content-end'>
                                            <div className='ms-5 d-flex justify-content-center align-items-center' >
                                              <img className='' src={Chat} onClick={() =>
                                                rediretToChatPage(session.userId)
                                              } alt="" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              })
                            ) : (
                              <div className="row m-0">
                                <div className="col-12 p-0">
                                  No Confirmed Request
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0 mt-3">
                  <div className="col-12 p-0">
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item accor_item">
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            className="accordion-button collapsed comm_accordion_btn_mobile red_btn justify-content-between"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="true"
                            aria-controls="collapseThree"
                          >
                            <p className="session_btn_mobile px-3 pt-3">{`REJECTED (${filteredSession.filter(
                              (session) => session.status === "rejected"
                            ).length
                              })`}</p>
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            {filteredSession.filter(
                              (session) => session.status === "rejected"
                            ).length > 0 ? (
                              filteredSession.map((session, index) => {
                                if (session.status === "rejected") {
                                  return (
                                    <div className="row m-0" key={index}>
                                      <div className="col-12 p-0">
                                        <div className="row m-0">
                                          <div className="col-12 p-0 d-flex justify-content-start align-items-center">
                                            <img className="me-2" src={Orange} />
                                            <p className="mb-0 session_detail ms-2">{`Sessions with ${session.instructor.id !== props.userDetails.id ? session.instructor.firstName : session.user.firstName}`}</p>
                                          </div>
                                        </div>

                                        <div className="row m-0 d-flex">
                                          <div className="col-6 p-0">
                                            <p className="mb-0 session_date_mobile ms-4">
                                              {moment(session.startDate).utc().format(
                                                "DD-MM-YYYY"
                                              )}
                                            </p>
                                            <div className=""></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              })
                            ) : (
                              <div className="row m-0">
                                <div className="col-12 p-0">
                                  No Rejected Request
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row m-0 mt-3'>
                  <div className='col-12 p-0'>
                    <p className=' mb-0 session'>Events</p>
                  </div>
                </div>

                <div className="row m-0 mt-3">
                  <div className="col-12 p-0">
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item accor_item">
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            className="accordion-button collapsed comm_accordion_btn_mobile orange_btn justify-content-between"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="true"
                            aria-controls="collapseFour"
                          >
                            <p className="session_btn_mobile px-3 pt-3">{`INVITATIONS (${filteredEvents.filter(
                              (session) => session.status === "invited"
                            ).length
                              })`}</p>
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            {filteredEvents.filter(
                              (session) => session.status === "invited"
                            ).length > 0 ? (
                              filteredEvents.map((value, index) => {
                                if (value.status === "invited") {
                                  return (
                                    <div className="row m-0" key={index}>
                                      <div className="col-12 p-0">
                                        <div className="row m-0">
                                          <div className="col-12 p-0 d-flex justify-content-start align-items-center">
                                            <img className="me-2" src={Orange} />
                                            <p className="mb-0 session_detail ms-2">{`Sessions with ${value.event.name}`}</p>
                                          </div>
                                        </div>

                                        <div className="row m-0 d-flex">
                                          <div className="col-6 p-0">
                                            <p className="mb-0 session_date_mobile ms-4">
                                              {moment(value.event.startDate).utc().format(
                                                "DD-MM-YYYY"
                                              )}
                                            </p>
                                            <div className=""></div>
                                          </div>

                                          <div className="col-6 p-0 d-flex flex-column ">
                                            <p className="mb-0 session_date_mobile green d-flex justify-content-end align-items-center">
                                              <img
                                                className="pe-2"
                                                src={Greenright}
                                                alt="Greenright"
                                              />
                                              Accept
                                            </p>
                                            <p className="mb-0 session_date_mobile red d-flex justify-content-end align-items-center mt-2">
                                              <img
                                                className="pe-2"
                                                src={Redcross}
                                                alt="Redcross"
                                              />
                                              Reject
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              })
                            ) : (
                              <div className="row m-0">
                                <div className="col-12 p-0">
                                  No Invitation For Event
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row m-0 mt-3">
                  <div className="col-12 p-0">
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item accor_item">
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            className="accordion-button collapsed comm_accordion_btn_mobile green_btn justify-content-between"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="true"
                            aria-controls="collapseFive"
                          >
                            <p className="session_btn_mobile px-3 pt-3">{`GOING (${filteredEvents.filter(
                              (session) => session.status === "attending"
                            ).length
                              })`}</p>
                          </button>
                        </h2>
                        <div
                          id="collapseFive"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            {filteredEvents.filter(
                              (event) => event.status === "attending"
                            ).length > 0 ? (
                              filteredEvents.map((value, index) => {
                                if (value.status === "attending") {
                                  return (
                                    <div className="row m-0" key={index}>
                                      <div className="col-12 p-0">
                                        <div className="row m-0">
                                          <div className="col-12 p-0 d-flex justify-content-start align-items-center">
                                            <img className="me-2" src={Orange} />
                                            <p className="mb-0 session_detail ms-2">{`Sessions with ${value.event.name}`}</p>
                                          </div>
                                        </div>

                                        <div className="row m-0 d-flex align-items-center">
                                          <div className="col-6 p-0">
                                            <p className="mb-0 session_date_mobile ms-4">
                                              {moment(value.event.startDate).utc().format(
                                                "DD-MM-YYYY"
                                              )}
                                            </p>
                                            <div className=""></div>
                                          </div>

                                          <div className='col-6 d-flex justify-content-end'>
                                            <div className='ms-5 vedio d-flex justify-content-center align-items-center' >
                                              <img className='' src={Video} onClick={() => window.open(`${value.event.link}`)} />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              })
                            ) : (
                              <div className="row m-0">
                                <div className="col-12 p-0">
                                  No Going Event
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </> : <div className="mt-3">

                <props.Calendar
                  localizer={props.localizer}
                  defaultDate={new Date()}
                  defaultView="month"
                  events={events}
                  views={["month"]}
                  navigate={["NEXT"]}
                  style={{ height: "100vh" }}
                  eventPropGetter={calendarStyle}
                  onSelectEvent={(evt) => {
                    if (evt.type === "event") {
                      setShowEventModal(true);
                      setCurrentEventDetails(evt)
                    }
                    else {
                      setShowSessionModal(true)
                      setCurrentSessionDetails(evt);
                    }
                  }}
                  onShowMore={(events, date) => {
                    setMoreEvents(events);
                    setIsOpen(true);
                  }}
                  slotGroupPropGetter={5}
                />

              </div>}


            </div>
          </div>
        </div>
      </div>

      {showEventModal ? <div class={`modal fade ${showEventModal ? 'show' : ''}`} style={{ display: showEventModal ? 'block' : 'none', background: showEventModal ? 'rgba(0,0,0,0.5)' : '' }} id="communityModals1" tabIndex="-1" aria-labelledby="communityModals1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered event_modal_community" role="document">
          <div class="modal-content">
            <div class="modal-header d-flex justify-content-end">
              <img src={BackCross} alt="" className='cursor_pointer' onClick={() => setShowEventModal(false)} />
            </div>
            <div class="modal-body p-4">
              <h5>{currentEventDetails.data.name}</h5>
              <p>{moment(currentEventDetails.data.startDate).utc().format("ddd, MMM, DD")} - {moment(currentEventDetails.data.startDate).utc().format("hh:mm a")} - {moment(currentEventDetails.data.endDate).utc().format("hh:mm a")}</p>

              <div className="row m-0 align-items-center">
                <div className="col-1 p-0">
                  <img src={comm_eve_1} width="20px" alt="" />
                </div>
                <div className="col-9 p-0">
                  <p className="mb-0">Live in Kaleidoscope</p>
                </div>
                <div className="col-2 p-0 d-flex align-items-center justify-content-end">
                  <button className="go_btn">Go</button>
                </div>
              </div>

              <div className="row m-0 mt-3">
                <div className="col-1 p-0">
                  <img src={comm_eve_2} width="20px" alt="" />
                </div>
                <div className="col-9 p-0">
                  <p className="mb-0">50 Attendees</p>
                  <span>10 of your friends attending this huddle</span>
                </div>
              </div>

              <div className="row m-0 mt-3">
                <div className="col-1 p-0">
                  <img src={comm_eve_3} width="20px" alt="" />
                </div>
                <div className="col-9 p-0">
                  <p>Event by {currentEventDetails && currentEventDetails.data.createdByUser.firstName}</p>
                </div>
              </div>

              <div className="row m-0 require_approval_box">
                <div className="col-6 p-0">
                  <span>Requires your approval</span>
                </div>
                <div className="col-6 p-0 d-flex align-items-center justify-content-end">
                  <p className="reject_text mb-0" onClick={() => updateEventInvite('not-attending', { eventId: currentEventDetails.data.id })}>Reject</p>
                  <button className="accept_btn ms-3" onClick={() => updateEventInvite('attending', { eventId: currentEventDetails.data.id })}>Accept</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : null}

      {showSessionModal ? <div class={`modal fade ${showSessionModal ? 'show' : ''}`} style={{ display: showSessionModal ? 'block' : 'none', background: showSessionModal ? 'rgba(0,0,0,0.5)' : '' }} id="communityModals1" tabIndex="-1" aria-labelledby="communityModals1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered event_modal_community" role="document">
          <div class="modal-content">
            <div class="modal-header d-flex justify-content-end">
              <img src={BackCross} alt="" className='cursor_pointer' onClick={() => setShowSessionModal(false)} />
            </div>
            <div class="modal-body p-4">
              <h5>{currentSessionDetails.title}</h5>
              <p>{moment(currentSessionDetails.start).format('ddd, MMM, YYYY')} - {currentSessionDetails.data.timeSlot}:00 {currentSessionDetails.data.timeSlot < 12 ? 'AM' : 'PM'} to {currentSessionDetails.data.timeSlot + 1}:00 {(currentSessionDetails.data.timeSlot + 1) < 12 ? 'AM' : 'PM'}</p>

              <div className="row m-0 align-items-center">
                <div className="col-1 p-0">
                  <img src={comm_eve_1} width="20px" alt="" />
                </div>
                <div className="col-9 p-0 d-flex align-items-center">
                  {currentSessionDetails.status === "confirmed" ? <button className=" go_btn go_btn_blue">Go to Room</button> : <button className="go_btn">Go to Room</button>}
                </div>
              </div>

              <div className="row m-0 mt-3">
                <div className="col-1 p-0">
                  <img src={currentSessionDetails.data.user.profilePic ? currentSessionDetails.data.user.profilePic : comm_eve_2} style={{ borderRadius: '50%' }} width="20px" height="20px" alt="" />
                </div>
                <div className="col-9 p-0">
                  <p className="mb-0">{`${currentSessionDetails.data.user.firstName} ${currentSessionDetails.data.user.lastName}`}</p>
                </div>
              </div>

              <div className="row m-0 require_approval_box mt-4">
                <div className="col-6 p-0">
                  <span>Requires your approval</span>
                </div>
                <div className="col-6 p-0 d-flex align-items-center justify-content-end">
                  <p className="reject_text mb-0" onClick={() => handleRequest(currentSessionDetails.data, 'left')}>Reject</p>
                  <button className="accept_btn ms-3" onClick={() => handleRequest(currentSessionDetails.data, 'right')}>Accept</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : null}

      <div class={`modal fade ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none', background: isOpen ? 'rgba(0,0,0,0.5)' : '' }} id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content model_border_radius">

            <div class="modal-body p-4 d-flex flex-column align-items-center justify-content-center">
              <p className="model_bold_text text-center mb-3">More Session/Events</p>
              {moreEvents.map((v, i) => {
                return <>
                  <p className="w-100 d-flex align-items-center mb-2 cursor_pointer" onClick={() => {
                    setIsOpen(false);
                    if (v.type === "event") {
                      setCurrentEventDetails(v)
                      setShowEventModal(true)
                    }
                    else {
                      setCurrentSessionDetails(v);
                      setShowSessionModal(true)
                    }
                  }}>
                    <p className="mb-0 text-left" style={{ fontWeight: 600 }} >{v.title}</p>
                    &nbsp;
                    {v.type === "session" ? <p className="mb-0">with {v.data.user.firstName} {v.data.user.lastName}</p> :
                      <p className="mb-0">created by {v.data.createdByUser.firstName} {v.data.createdByUser.lastName}</p>
                    }
                  </p>
                  <p className="w-100 text-left"> {v.type === "session" ? <p className="mb-0"><p>{moment(v.start).format('ddd, MMM, YYYY')} - {v.data.timeSlot}:00 {v.data.timeSlot < 12 ? 'AM' : 'PM'} to {v.data.timeSlot + 1}:00 {(v.data.timeSlot + 1) < 12 ? 'AM' : 'PM'}</p></p> :
                    <p className="mb-0">{moment(v.data.startDate).utc().format("ddd, MMM, DD")} - {moment(v.data.startDate).utc().format("hh:mm a")} - {moment(v.data.endDate).utc().format("hh:mm a")}</p>
                  }</p>
                </>
              })}
              <div className="col-lg-12 col-3 p-0 d-flex justify-content-center">
                <button className="model_login_button mt-2" onClick={() => setIsOpen(false)}>Close</button>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  );
};

export default CommunityCoachesMobile;
