import React from 'react';
import SideNav from '../../component/staticPages/SideNav';
import '../../css/exploreQuest.css';

import TimeExchangePocket from "../../assets/svg/TimeExchangePocket.svg";
import TimeExchangeEmoji from "../../assets/svg/TimeExchangeEmoji.svg";
import Dimond from "../../assets/svg/Dimond.svg";
import FilterLike from "../../assets/svg/FilterLike.svg";
import FilterPeople from "../../assets/svg/FilterPeople.svg";
import Fillter_img from "../../assets/Fillter_img.png";


const ExploreQuests = () => {

    

    return (
        <>
            <div className='row m-0 d-flex newsfeed_bg'>

                <div className='news_left p-0'>
                    <SideNav Journey={true} />
                </div>

                <div className='news_right p-5'>
                    <div className='row m-0'>
                        <div className='col p-0'>

                            <div className='row m-0'>

                                <div className='col-8 p-0'>
                                    <p className='mb-0 notification_heading'>Explore Quests</p>
                                    <p className='mb-0 mt-3 journey_bredcrum '>Journey > Quest > <span className='quest_border'>Explore Quests</span></p>

                                </div>
                                <div className='col-4 p-0 d-flex align-items-center justify-content-end'>
                                    <p className='mb-0 time_exchange_right_head text-right'> <img className='mb-1' src={TimeExchangePocket} alt=' ' /> 36H &nbsp; <span className='deivder_time_ex'>|</span> &nbsp; <img className='mb-1' src={TimeExchangeEmoji} alt=' ' /> 2400 WBX</p>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className='row m-0 mt-4'>
                        <div className='col-2 p-0'>

                            <div className='row m-0'>
                                <div className='col-12 p-0'>
                                    <p className='mb-0 filter_heading'>Filter</p>
                                </div>
                            </div>

                            <div className='row m-0 mt-3'>
                                <div className='col-12 p-0'>
                                    <p className='mb-0 filter_type'>Well-being Touchpoints</p>
                                </div>
                            </div>

                            <div className='row m-0 mt-2'>
                                <div className='col-12 p-0'>

                                    <div class="form-check filter_check">
                                        <input class="form-check-input filter_check_box" type="checkbox" value="" id="flexCheckDefault" />
                                        <label class="form-check-label filter_check_box_name d-flex align-items-center" for="flexCheckDefault">
                                            WB Touchpoints
                                        </label>
                                    </div>

                                    <div class="form-check filter_check">
                                        <input class="form-check-input filter_check_box" type="checkbox" value="" id="flexCheckDefault" />
                                        <label class="form-check-label filter_check_box_name d-flex align-items-center" for="flexCheckDefault">
                                            WB Touchpoints
                                        </label>
                                    </div>

                                    <div class="form-check filter_check">
                                        <input class="form-check-input filter_check_box" type="checkbox" value="" id="flexCheckDefault" />
                                        <label class="form-check-label filter_check_box_name d-flex align-items-center" for="flexCheckDefault">
                                            WB Touchpoints
                                        </label>
                                    </div>

                                    <div class="form-check filter_check">
                                        <input class="form-check-input filter_check_box" type="checkbox" value="" id="flexCheckDefault" />
                                        <label class="form-check-label filter_check_box_name d-flex align-items-center" for="flexCheckDefault">
                                            WB Touchpoints
                                        </label>
                                    </div>

                                    <div class="form-check filter_check">
                                        <input class="form-check-input filter_check_box" type="checkbox" value="" id="flexCheckDefault" />
                                        <label class="form-check-label filter_check_box_name d-flex align-items-center" for="flexCheckDefault">
                                            WB Touchpoints
                                        </label>
                                    </div>

                                </div>
                            </div>

                            <div className='row m-0 mt-4'>
                                <div className='col-12 p-0'>
                                    <p className='mb-0 filter_type'>Sort by</p>
                                </div>
                            </div>

                            <div className='row m-0 mt-2'>
                                <div className='col-12 p-0'>

                                    <div class="form-check filter_check">
                                        <input class="form-check-input filter_check_box" type="checkbox" value="" id="flexCheckDefault" />
                                        <label class="form-check-label filter_check_box_name d-flex align-items-center" for="flexCheckDefault">
                                            Most likes
                                        </label>
                                    </div>

                                    <div class="form-check filter_check">
                                        <input class="form-check-input filter_check_box" type="checkbox" value="" id="flexCheckDefault" />
                                        <label class="form-check-label filter_check_box_name d-flex align-items-center" for="flexCheckDefault">
                                            Most attendees
                                        </label>
                                    </div>

                                    <div class="form-check filter_check">
                                        <input class="form-check-input filter_check_box" type="checkbox" value="" id="flexCheckDefault" />
                                        <label class="form-check-label filter_check_box_name d-flex align-items-center" for="flexCheckDefault">
                                            Most WB Points
                                        </label>
                                    </div>

                                    <div class="form-check filter_check">
                                        <input class="form-check-input filter_check_box" type="checkbox" value="" id="flexCheckDefault" />
                                        <label class="form-check-label filter_check_box_name d-flex align-items-center" for="flexCheckDefault">
                                            Time-trials only
                                        </label>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className='col-10 p-0'>

                            <div className='row m-0'>
                                <div className='col-12 p-0'>

                                    <div className='row m-0'>
                                        <div className='col-12 p-0'>
                                            <p className='mb-0 filter_result_heading'>54 Results</p>
                                        </div>
                                    </div>


                                    <div className='row m-0 mt-2'>
                                        <div className='col-6 p-0'>

                                            <div className='filter_big_box pt-3 pb-3'>

                                                <div className='row m-0'>
                                                    <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                        <div className='filter_que_line d-flex align-items-center justify-content-center'>
                                                            <p className='mb-0 ong_que_end_line_text'>2 days</p>
                                                        </div>

                                                        <div className='filter_que_img_box'>
                                                            <img src={Fillter_img} alt='' />
                                                        </div>

                                                    </div>
                                                    <div className='col-9 p-0 pt-2 pe-2'>

                                                        <div className='row m-0'>
                                                            <div className='col-9 p-0'>
                                                                <p className='mb-0 on_quest_big_box_heading'>Improve Yourself</p>
                                                            </div>
                                                            <div className='col-3 p-0 d-flex align-itmes-center justify-content-center'>
                                                                <p className='mb-0 on_quest_big_box_right'>500 &nbsp; <img src={Dimond} alt='' /></p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-1'>
                                                            <div className='col-12 p-0 pe-4'>
                                                                <p className='mb-0 filter_quest_big_box_heading_text'>Well-being Touchpoint </p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-1'>
                                                            <div className='col-12 p-0 pe-3'>
                                                                <p className='mb-0 filter_quest_big_box_info_text'>This quest will help you to upgrade your well-being by having a conversation with friends ... </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='row m-0 mt-2 ps-3 pe-3'>
                                                    <div className='col-12 p-0'>

                                                        <div className='row m-0'>

                                                            <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                                                <p className='mb-0 filter_big_box_like me-4'><img src={FilterLike} alt='' /> &nbsp; 124</p>
                                                                <p className='mb-0 filter_big_box_like'><img src={FilterPeople} alt='' /> &nbsp; 40k</p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className='filter_big_box pt-3 pb-3 mt-2'>

                                                <div className='row m-0'>
                                                    <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                        <div className='filter_que_line filter_que_line_green d-flex align-items-center justify-content-center'>
                                                            <p className='mb-0 ong_que_end_line_text'>2 days</p>
                                                        </div>

                                                        <div className='filter_que_img_box'>
                                                            <img src={Fillter_img} alt='' />
                                                        </div>

                                                    </div>
                                                    <div className='col-9 p-0 pt-2 pe-2'>

                                                        <div className='row m-0'>
                                                            <div className='col-9 p-0'>
                                                                <p className='mb-0 on_quest_big_box_heading'>Improve Yourself</p>
                                                            </div>
                                                            <div className='col-3 p-0 d-flex align-itmes-center justify-content-center'>
                                                                <p className='mb-0 on_quest_big_box_right'>500 &nbsp; <img src={Dimond} alt='' /></p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-1'>
                                                            <div className='col-12 p-0 pe-4'>
                                                                <p className='mb-0 filter_quest_big_box_heading_text'>Well-being Touchpoint </p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-1'>
                                                            <div className='col-12 p-0 pe-3'>
                                                                <p className='mb-0 filter_quest_big_box_info_text'>This quest will help you to upgrade your well-being by having a conversation with friends ... </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='row m-0 mt-2 ps-3 pe-3'>
                                                    <div className='col-12 p-0'>

                                                        <div className='row m-0'>

                                                            <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                                                <p className='mb-0 filter_big_box_like me-4'><img src={FilterLike} alt='' /> &nbsp; 124</p>
                                                                <p className='mb-0 filter_big_box_like'><img src={FilterPeople} alt='' /> &nbsp; 40k</p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className='filter_big_box pt-3 pb-3 mt-2'>

                                                <div className='row m-0'>
                                                    <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                        <div className='filter_que_line filter_que_line_green d-flex align-items-center justify-content-center'>
                                                            <p className='mb-0 ong_que_end_line_text'>2 days</p>
                                                        </div>

                                                        <div className='filter_que_img_box'>
                                                            <img src={Fillter_img} alt='' />
                                                        </div>

                                                    </div>
                                                    <div className='col-9 p-0 pt-2 pe-2'>

                                                        <div className='row m-0'>
                                                            <div className='col-9 p-0'>
                                                                <p className='mb-0 on_quest_big_box_heading'>Improve Yourself</p>
                                                            </div>
                                                            <div className='col-3 p-0 d-flex align-itmes-center justify-content-center'>
                                                                <p className='mb-0 on_quest_big_box_right'>500 &nbsp; <img src={Dimond} alt='' /></p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-1'>
                                                            <div className='col-12 p-0 pe-4'>
                                                                <p className='mb-0 filter_quest_big_box_heading_text'>Well-being Touchpoint </p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-1'>
                                                            <div className='col-12 p-0 pe-3'>
                                                                <p className='mb-0 filter_quest_big_box_info_text'>This quest will help you to upgrade your well-being by having a conversation with friends ... </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='row m-0 mt-2 ps-3 pe-3'>
                                                    <div className='col-12 p-0'>

                                                        <div className='row m-0'>

                                                            <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                                                <p className='mb-0 filter_big_box_like me-4'><img src={FilterLike} alt='' /> &nbsp; 124</p>
                                                                <p className='mb-0 filter_big_box_like'><img src={FilterPeople} alt='' /> &nbsp; 40k</p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className='filter_big_box pt-3 pb-3 mt-2'>

                                                <div className='row m-0'>
                                                    <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                        <div className='filter_que_line d-flex align-items-center justify-content-center'>
                                                            <p className='mb-0 ong_que_end_line_text'>2 days</p>
                                                        </div>

                                                        <div className='filter_que_img_box'>
                                                            <img src={Fillter_img} alt='' />
                                                        </div>

                                                    </div>
                                                    <div className='col-9 p-0 pt-2 pe-2'>

                                                        <div className='row m-0'>
                                                            <div className='col-9 p-0'>
                                                                <p className='mb-0 on_quest_big_box_heading'>Improve Yourself</p>
                                                            </div>
                                                            <div className='col-3 p-0 d-flex align-itmes-center justify-content-center'>
                                                                <p className='mb-0 on_quest_big_box_right'>500 &nbsp; <img src={Dimond} alt='' /></p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-1'>
                                                            <div className='col-12 p-0 pe-4'>
                                                                <p className='mb-0 filter_quest_big_box_heading_text'>Well-being Touchpoint </p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-1'>
                                                            <div className='col-12 p-0 pe-3'>
                                                                <p className='mb-0 filter_quest_big_box_info_text'>This quest will help you to upgrade your well-being by having a conversation with friends ... </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='row m-0 mt-2 ps-3 pe-3'>
                                                    <div className='col-12 p-0'>

                                                        <div className='row m-0'>

                                                            <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                                                <p className='mb-0 filter_big_box_like me-4'><img src={FilterLike} alt='' /> &nbsp; 124</p>
                                                                <p className='mb-0 filter_big_box_like'><img src={FilterPeople} alt='' /> &nbsp; 40k</p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                        </div>



                                        <div className='col-6 p-0 d-flex flex-column align-items-end'>

                                            <div className='filter_big_box pt-3 pb-3'>

                                                <div className='row m-0'>
                                                    <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                        <div className='filter_que_line d-flex align-items-center justify-content-center'>
                                                            <p className='mb-0 ong_que_end_line_text'>2 days</p>
                                                        </div>

                                                        <div className='filter_que_img_box'>
                                                            <img src={Fillter_img} alt='' />
                                                        </div>

                                                    </div>
                                                    <div className='col-9 p-0 pt-2 pe-2'>

                                                        <div className='row m-0'>
                                                            <div className='col-9 p-0'>
                                                                <p className='mb-0 on_quest_big_box_heading'>Improve Yourself</p>
                                                            </div>
                                                            <div className='col-3 p-0 d-flex align-itmes-center justify-content-center'>
                                                                <p className='mb-0 on_quest_big_box_right'>500 &nbsp; <img src={Dimond} alt='' /></p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-1'>
                                                            <div className='col-12 p-0 pe-4'>
                                                                <p className='mb-0 filter_quest_big_box_heading_text'>Well-being Touchpoint </p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-1'>
                                                            <div className='col-12 p-0 pe-3'>
                                                                <p className='mb-0 filter_quest_big_box_info_text'>This quest will help you to upgrade your well-being by having a conversation with friends ... </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='row m-0 mt-2 ps-3 pe-3'>
                                                    <div className='col-12 p-0'>

                                                        <div className='row m-0'>

                                                            <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                                                <p className='mb-0 filter_big_box_like me-4'><img src={FilterLike} alt='' /> &nbsp; 124</p>
                                                                <p className='mb-0 filter_big_box_like'><img src={FilterPeople} alt='' /> &nbsp; 40k</p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>



                                            <div className='filter_big_box pt-3 pb-3 mt-2'>

                                                <div className='row m-0'>
                                                    <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                        <div className='filter_que_line filter_que_line_green d-flex align-items-center justify-content-center'>
                                                            <p className='mb-0 ong_que_end_line_text'>2 days</p>
                                                        </div>

                                                        <div className='filter_que_img_box'>
                                                            <img src={Fillter_img} alt='' />
                                                        </div>

                                                    </div>
                                                    <div className='col-9 p-0 pt-2 pe-2'>

                                                        <div className='row m-0'>
                                                            <div className='col-9 p-0'>
                                                                <p className='mb-0 on_quest_big_box_heading'>Improve Yourself</p>
                                                            </div>
                                                            <div className='col-3 p-0 d-flex align-itmes-center justify-content-center'>
                                                                <p className='mb-0 on_quest_big_box_right'>500 &nbsp; <img src={Dimond} alt='' /></p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-1'>
                                                            <div className='col-12 p-0 pe-4'>
                                                                <p className='mb-0 filter_quest_big_box_heading_text'>Well-being Touchpoint </p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-1'>
                                                            <div className='col-12 p-0 pe-3'>
                                                                <p className='mb-0 filter_quest_big_box_info_text'>This quest will help you to upgrade your well-being by having a conversation with friends ... </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='row m-0 mt-2 ps-3 pe-3'>
                                                    <div className='col-12 p-0'>

                                                        <div className='row m-0'>

                                                            <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                                                <p className='mb-0 filter_big_box_like me-4'><img src={FilterLike} alt='' /> &nbsp; 124</p>
                                                                <p className='mb-0 filter_big_box_like'><img src={FilterPeople} alt='' /> &nbsp; 40k</p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className='filter_big_box pt-3 pb-3 mt-2'>

                                                <div className='row m-0'>
                                                    <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                        <div className='filter_que_line filter_que_line_green d-flex align-items-center justify-content-center'>
                                                            <p className='mb-0 ong_que_end_line_text'>2 days</p>
                                                        </div>

                                                        <div className='filter_que_img_box'>
                                                            <img src={Fillter_img} alt='' />
                                                        </div>

                                                    </div>
                                                    <div className='col-9 p-0 pt-2 pe-2'>

                                                        <div className='row m-0'>
                                                            <div className='col-9 p-0'>
                                                                <p className='mb-0 on_quest_big_box_heading'>Improve Yourself</p>
                                                            </div>
                                                            <div className='col-3 p-0 d-flex align-itmes-center justify-content-center'>
                                                                <p className='mb-0 on_quest_big_box_right'>500 &nbsp; <img src={Dimond} alt='' /></p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-1'>
                                                            <div className='col-12 p-0 pe-4'>
                                                                <p className='mb-0 filter_quest_big_box_heading_text'>Well-being Touchpoint </p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-1'>
                                                            <div className='col-12 p-0 pe-3'>
                                                                <p className='mb-0 filter_quest_big_box_info_text'>This quest will help you to upgrade your well-being by having a conversation with friends ... </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='row m-0 mt-2 ps-3 pe-3'>
                                                    <div className='col-12 p-0'>

                                                        <div className='row m-0'>

                                                            <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                                                <p className='mb-0 filter_big_box_like me-4'><img src={FilterLike} alt='' /> &nbsp; 124</p>
                                                                <p className='mb-0 filter_big_box_like'><img src={FilterPeople} alt='' /> &nbsp; 40k</p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className='filter_big_box pt-3 pb-3 mt-2'>

                                                <div className='row m-0'>
                                                    <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                        <div className='filter_que_line d-flex align-items-center justify-content-center'>
                                                            <p className='mb-0 ong_que_end_line_text'>2 days</p>
                                                        </div>

                                                        <div className='filter_que_img_box'>
                                                            <img src={Fillter_img} alt='' />
                                                        </div>

                                                    </div>
                                                    <div className='col-9 p-0 pt-2 pe-2'>

                                                        <div className='row m-0'>
                                                            <div className='col-9 p-0'>
                                                                <p className='mb-0 on_quest_big_box_heading'>Improve Yourself</p>
                                                            </div>
                                                            <div className='col-3 p-0 d-flex align-itmes-center justify-content-center'>
                                                                <p className='mb-0 on_quest_big_box_right'>500 &nbsp; <img src={Dimond} alt='' /></p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-1'>
                                                            <div className='col-12 p-0 pe-4'>
                                                                <p className='mb-0 filter_quest_big_box_heading_text'>Well-being Touchpoint </p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-1'>
                                                            <div className='col-12 p-0 pe-3'>
                                                                <p className='mb-0 filter_quest_big_box_info_text'>This quest will help you to upgrade your well-being by having a conversation with friends ... </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='row m-0 mt-2 ps-3 pe-3'>
                                                    <div className='col-12 p-0'>

                                                        <div className='row m-0'>

                                                            <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                                                <p className='mb-0 filter_big_box_like me-4'><img src={FilterLike} alt='' /> &nbsp; 124</p>
                                                                <p className='mb-0 filter_big_box_like'><img src={FilterPeople} alt='' /> &nbsp; 40k</p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                        </div>


                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ExploreQuests;
