import React from 'react';
import SideNav from '../../component/staticPages/SideNav';
import '../../css/questInvitations.css';


import TimeExchangePocket from "../../assets/svg/TimeExchangePocket.svg";
import TimeExchangeEmoji from "../../assets/svg/TimeExchangeEmoji.svg";
import Aten from "../../assets/svg/Aten.svg";
import Dimond from "../../assets/svg/Dimond.svg";
import JourneyPeople from "../../assets/svg/JourneyPeople.svg";
import JourneyShare from "../../assets/svg/JourneyShare.svg";
import JourneyLike from "../../assets/svg/JourneyLike.svg";
import Journey_goal from "../../assets/svg/journey_goal.svg";
import Quest_Detail_img from "../../assets/Quest_Detail_img.png";
import Relation from '../../assets/Relation.png';
import Invite_profile_img1 from '../../assets/Invite_profile_img1.png';
import Invite_profile_img3 from '../../assets/Invite_profile_img3.png';


function QuestInvitations() {
    return (
        <>

            <div className='row m-0 d-flex newsfeed_bg quest_detail_web'>

                <div className='news_left p-0'>
                    <SideNav Journey={true} />
                </div>

                <div className='news_right p-5'>
                    <div className='row m-0'>
                        <div className='col p-0'>

                            <div className='row m-0'>
                                <div className='col-8 p-0'>
                                    <p className='mb-0 notification_heading'>Quest Detail</p>
                                    <p className='mb-0 mt-3 journey_bredcrum '>Journey > Your Quest > <span className='quest_border'>Invitations</span></p>

                                </div>
                                <div className='col-4 p-0 d-flex align-items-center justify-content-end'>
                                    <p className='mb-0 time_exchange_right_head text-right'> <img className='mb-1' src={TimeExchangePocket} alt=' ' /> 36H &nbsp; <span className='deivder_time_ex'>|</span> &nbsp; <img className='mb-1' src={TimeExchangeEmoji} alt=' ' /> 2400 WBX</p>
                                </div>

                            </div>
                        </div>
                    </div>



                    <div className='row m-0 mt-4'>

                        <div className='col-8 p-0'>
                            <div className='quest_details_box invitation_details_box p-4'>

                                <div className='row m-0'>

                                    <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>

                                        <div className='quest_details_que_end_line d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 ong_que_end_line_text'>Ends in 2 days</p>
                                        </div>
                                        <div className='quest_details_img_box'>
                                            <img className='quest_details_img' src={Quest_Detail_img} alt='' />

                                        </div>

                                    </div>

                                    <div className='col-6 p-0'>
                                        <p className='mb-0 quest_details_heading'>Relationship Power Up!</p>
                                        <p className='mb-0 quest_details_session mt-2'>1-to-1 Sessions</p>
                                        <button className='strat_quest_btn mt-5'>Start Quest</button>

                                    </div>

                                    <div className='col-3 p-0'>

                                        <div className='row m-0'>
                                            <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                                <p className='mb-0 on_quest_big_box_right'>500 &nbsp; <img src={Dimond} alt='' /></p>
                                            </div>
                                        </div>

                                        <div className='row m-0 mt-4'>
                                            <div className='col-12 p-0 d-flex align-items-center justify-content-end'>

                                                <div className='quest_relation d-flex align-items-center justify-content-between ps-2 pe-2'>
                                                    <img className='quest_relation_img' src={Relation} alt='' />
                                                    <p className='mb-0 quest_relation_name'>Relationship </p>

                                                </div>

                                            </div>
                                        </div>

                                        <div className='row m-0 mt-4 d-flex align-items-center justify-content-around'>

                                            <div className='col-4 quest_relation_oprat d-flex align-items-center justify-content-center'>
                                                <img src={JourneyLike} alt='' />
                                            </div>

                                            <div className='col-4 quest_relation_oprat d-flex align-items-center justify-content-center'>
                                                <img src={JourneyPeople} alt='' />
                                            </div>

                                            <div className='col-4 quest_relation_oprat d-flex align-items-center justify-content-center'>
                                                <img src={JourneyShare} alt='' />
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className='row m-0 mt-4'>
                                    <div className='col-12 p-0'>
                                        <p className='mb-0 quest_description_heading'>Description</p>
                                        <p className='mb-0 mt-2 quest_description_details'>Invite 1 friend to a 1-to-1 Session with you, and simply talk about your day!</p>
                                    </div>
                                </div>

                                <div className='row m-0 mt-5'>
                                    <div className='col-12 p-0 pt-4'>
                                        <p className='mb-0 quest_description_heading'>How does this improve your well-being?</p>
                                        <p className='mb-0 mt-2 quest_description_details'>You will gain insights from people you interact with, especially with the coaches. Make yourself more confident to communicate your feeling with friends.</p>
                                    </div>
                                </div>


                                <div className='row m-0 mt-5'>
                                    <div className='col-6 p-0 pe-4'>

                                        <div className='row m-0 invite_row pt-3 pb-2'>
                                            <div className='col-10 p-0 d-flex align-items-center justify-content-start'>
                                                <p className='mb-0 invite_ttendees'>Attendees</p>
                                            </div>
                                            <div className='col-2 p-0 d-flex align-items-center justify-content-start ps-1'>
                                               <img src={Aten} alt=''/>
                                            </div>

                                        </div>

                                        <div className='row m-0 pt-3 pb-2'>

                                            <div className='col-1 p-0 d-flex align-items-center justify-content-center'>
                                                <div className='invite_img_box'>
                                                    <img className='invite_img' src={Invite_profile_img1} alt='' />
                                                </div>
                                            </div>
                                            <div className='col-9 p-0 d-flex align-items-center justify-content-start ps-2'>
                                                <p className='mb-0 invite_profile_name'>Blessy</p>
                                            </div>
                                            <div className='col-2 p-0 d-flex align-items-center justify-content-start'>
                                                <p className='mb-0 invite_status'>Joined</p>
                                            </div>

                                        </div>

                                        <div className='row m-0 pt-3 pb-2'>

                                            <div className='col-1 p-0 d-flex align-items-center justify-content-center'>
                                                <div className='invite_img_box'>
                                                    <img className='invite_img' src={Invite_profile_img1} alt='' />
                                                </div>
                                            </div>
                                            <div className='col-9 p-0 d-flex align-items-center justify-content-start ps-2'>
                                                <p className='mb-0 invite_profile_name'>Blessy</p>
                                            </div>
                                            <div className='col-2 p-0 d-flex align-items-center justify-content-start'>
                                                <p className='mb-0 invite_status'>Waiting</p>
                                            </div>

                                        </div>

                                    </div>

                                    <div className='col-6 p-0'>

                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className='col-4 p-0'>

                            <div className='row m-0'>
                                <div className='col-12 p-0 ps-5 pb-3'>
                                    <p className=' mb-0 journey_active_list ms-2'>Activity List</p>

                                </div>
                            </div>

                            <div className='row m-0'>
                                <div className='col-12 p-0 d-flex flex-column  align-items-end justify-content-end'>

                                    <div className='ongoing_quest_sml_box p-2'>

                                        <div className='row m-0'>
                                            <div className='col-10 p-0 d-flex'>
                                                <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Spends 3 hours of 1-1 sessions</p>
                                            </div>
                                            <div className='col-2 p-0 d-flex align-items-center justify-content-end'>
                                                <p className='mb-0 journey_session_details'>0/1</p>

                                            </div>
                                        </div>

                                    </div>

                                    <div className='ongoing_quest_sml_box p-2 mt-3'>
                                        <div className='row m-0'>
                                            <div className='col-10 p-0 d-flex'>
                                                <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Attend 1 live events</p>
                                            </div>
                                            <div className='col-2 p-0 d-flex align-items-center justify-content-end'>
                                                <p className='mb-0 journey_session_details'>0/3</p>

                                            </div>
                                        </div>

                                    </div>


                                    <div className='ongoing_quest_sml_box p-2 mt-3'>
                                        <div className='row m-0'>
                                            <div className='col-10 p-0 d-flex'>
                                                <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Messages 3 of your friends</p>
                                            </div>
                                            <div className='col-2 p-0 d-flex align-items-center justify-content-end'>
                                                <p className='mb-0 journey_session_details'>0/1</p>

                                            </div>
                                        </div>

                                    </div>


                                    <div className='ongoing_quest_sml_box p-2 mt-3'>
                                        <div className='row m-0'>
                                            <div className='col-10 p-0 d-flex'>
                                                <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Shares 3 events to your friends</p>
                                            </div>
                                            <div className='col-2 p-0 d-flex align-items-center justify-content-end'>
                                                <p className='mb-0 journey_session_details'>0/1</p>

                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default QuestInvitations;

