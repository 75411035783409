import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";

import "react-calendar/dist/Calendar.css";
import SmCommunicating from "../../../assets/SmCommunicating.png";
import SmCaring from "../../../assets/SmCaring.png";
import SmGiving from "../../../assets/SmGiving.png";
import Relation from "../../../assets/Relation.png";
import SmEmotions from "../../../assets/svg/SmEmotions.svg";
import SmBreathing from "../../../assets/svg/SmBreathing.svg";
import SmMoving from "../../../assets/svg/SmMoving.svg";
import SmWorking from "../../../assets/SmWorking.png";
import SmLearning from "../../../assets/SmLearning.png";
import SmNoticing from "../../../assets/SmNoticing.png";
import SmMeaning from "../../../assets/SmMeaning.png";
import Belonging from "../../../assets/Belonging.png";
import {
    createPostLike,
    deletePostLike,
    getAllFriends,
    getEventData,
    getPostComments,
    getPostLike
} from '../../../utils/apiUrlsSetup';
import CommunityHuddleWeb from "./communityHuddle-web";
import CommnunityMobile from "./commnunity-mobile";
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import InviteFriendModal from './../../../component/staticPages/inviteFriendModal';
import LikeProfileModal from './../../../component/modals/likeProfileModal/likeProfileModal';

const checkCommunityType = (value) => {
    if (value === "communityFeelings") {
        return "Feeling";
    } else if (value === "communityConnecting") {
        return "Connecting";
    } else {
        return "Functioning";
    }
}

const checkCommunityTypeForFilters = (value) => {
    if (value === "communityFeelings") {
        return ["breathing", "moving", "acknowledging", "belonging"];
    } else if (value === "communityConnecting") {
        return ["giving", "relating", "caring", "communicating"];
    } else {
        return ["working", "learning", "noticing", "meaning"];
    }
}

const getFilterImages = (value) => {
    if (value === "breathing") {
        return SmBreathing;
    } else if (value === "moving") {
        return SmMoving;
    } else if (value === "emotions") {
        return SmEmotions;
    } else if (value === "meaning") {
        return SmMeaning;
    } else if (value === "working") {
        return SmWorking;
    } else if (value === "learning") {
        return SmLearning;
    } else if (value === "communicating") {
        return SmCommunicating;
    } else if (value === "noticing") {
        return SmNoticing;
    } else if (value === "giving") {
        return SmGiving;
    } else if (value === "caring") {
        return SmCaring;
    } else if (value === "relating") {
        return Relation;
    } else if (value === "belonging") {
        return Belonging;
    } else {
        return SmMeaning;
    }
}

const userDetails = JSON.parse(localStorage.getItem("userDetails"));

const CommunityHuddles = (props) => {

    const [point, setPoints] = useState({});
    const data = useSelector(state => state);

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [currentId, setcurrentId] = useState([]);
    const [value, onChange] = useState(new Date());

    const [filterName, setFilterName] = useState("All");
    const [search, setSearch] = useState("");

    const [openModal, setOpenModal] = useState(false);

    const [isShowInviteFrndModal, setIsShowInviteFrndModal] = useState(false);

    const [friendsList, setFriendsList] = useState([]);
    const [likeBtnDisable, setLikeBtnDisable] = useState(false);
    const [postId, setPostId] = useState();
    const [isComment, setIsComment] = useState(false)
    const [showComment, setShowComment] = useState(false)
    const [comments, setComments] = useState({});

    const [eventDetails, setShowEvent] = useState({
        event: {}
    })

    const [limit, setLimit] = useState(9);

    const handleModal = (val) => {
        setOpenModal(val);
    };

    const [events, setEvents] = useState([]);
    const [allEvents, setAllEvents] = useState([]);

    const [listOfLikedUsers, setListOfLikedUsers] = useState([])
    const [showUserLikedList, setShowUserLikedList] = useState(false)

    useEffect(() => {
        getAllEventsList(limit);
        getAllFriendsList();
    }, []);

    useEffect(() => {
        if (_.isEmpty(data.points)) {
            setPoints({})
        } else {
            setPoints(data.points.points);
        }
    }, []);

    const getListOfLikedUsers = async (post) => {
        let response = await getPostLike(`eventId=${post.id}`)
        if (response.status === 200) {
            if (response.data.data.length) {
                setShowUserLikedList(true)
            }
            setListOfLikedUsers(response.data.data);
        }
    }


    const getAllEventsList = async (limit) => {
        const response = await getEventData(null, limit);
        if (response.status === 200 && response.data.data.eventArray.length) {
            setEvents(response.data.data.eventArray);
            let findsIds = response.data.data.eventArray.map(v => v.id);
            setcurrentId(findsIds);
            const responseData = await getEventData();
            if (responseData.status === 200) {
                setAllEvents(responseData.data.data.eventArray);
            }
        }
    }

    const fetchMoreData = () => {
        setLimit(limit + 5);
        setTimeout(() => {
            getAllEventsList(limit + 4);
        }, 1500);
    };

    const getAllFriendsList = async () => {
        const responseData = await getAllFriends();
        if (responseData.status === 200) {
            setFriendsList(responseData.data.data);
        }
    }

    const handleShowComment = async (postId) => {
        setPostId(postId);
        const responseData = await getPostComments(postId);
        if (responseData.status === 200) {
            setIsComment(true)
            setShowComment(true)
            setComments(responseData.data.data)
        }
    }

    const handleLike = async (id, commentId, post) => {
        setLikeBtnDisable(true);
        const responseData = commentId ? await getPostLike(`commentId=${commentId}`) : await getPostLike(`eventId=${post.id}`);
        let data = responseData.data.data.map(v => v.userId)
        let filterData = data.includes(userDetails.id);
        if (filterData) {
            if (commentId) {
                const response = await deletePostLike({commentId: commentId});
                if (response.status === 200) {
                    handleShowComment(id);
                    setLikeBtnDisable(false);
                }
            } else {
                const response = await deletePostLike({eventId: id});
                if (response.status === 200) {
                    getAllEventsList(limit);
                    setLikeBtnDisable(false);
                }

            }
        } else {
            if (commentId) {
                const response = await createPostLike({commentId: commentId});
                if (response.status === 200) {
                    handleShowComment(id);
                    setLikeBtnDisable(false);
                }
            } else {
                const response = await createPostLike({eventId: id});
                if (response.status === 200) {
                    getAllEventsList(limit);
                    setLikeBtnDisable(false);
                }

            }
        }

    }

    return <>
        <CommunityHuddleWeb
            setSearch={setSearch}
            setFilterName={setFilterName}
            filterName={filterName}
            checkCommunityTypeForFilters={checkCommunityTypeForFilters}
            getFilterImages={getFilterImages}
            events={events}
            search={search}
            fetchMoreData={fetchMoreData}
            currentId={currentId}
            setcurrentId={setcurrentId}
            userDetails={userDetails || {}}
            value={value}
            communityType={props.communityType}
            onChange={onChange}
            allEvents={allEvents}
            openModal={openModal}
            checkCommunityType={checkCommunityType}
            point={point}
            showInviteUserPopup={(data) => {
                setShowEvent(data);
                setIsShowInviteFrndModal(true)
            }}
            handleLike={(id, commentId, post) => {
                handleLike(id, commentId, post)
            }}
            setShowUserLikedList={(data) => getListOfLikedUsers(data)}
        />

        <CommnunityMobile
            setSearch={setSearch}
            setFilterName={setFilterName}
            filterName={filterName}
            communityType={props.communityType}
            checkCommunityTypeForFilters={checkCommunityTypeForFilters}
            getFilterImages={getFilterImages}
            events={events}
            search={search}
            fetchMoreData={fetchMoreData}
            currentId={currentId}
            setcurrentId={setcurrentId}
            userDetails={userDetails}
            value={value}
            onChange={onChange}
            allEvents={allEvents}
            showInviteUserPopup={(data) => {
                setShowEvent(data);
                setIsShowInviteFrndModal(true)
            }}
            handleLike={(id, commentId, post) => {
                handleLike(id, commentId, post)
            }}
            setShowUserLikedList={(data) => getListOfLikedUsers(data)}
        />


        {/* Modal's */}

        {/* < Modal /> */}

        {isShowInviteFrndModal && <InviteFriendModal setIsShowInviteFrndModal={() => setIsShowInviteFrndModal(false)}
                                                     isShowInviteFrndModal={isShowInviteFrndModal}
                                                     friendsList={friendsList} eventData={{event: eventDetails}}/>}

        <LikeProfileModal isOpen={showUserLikedList} setShowUserLikedList={() => setShowUserLikedList(false)}
                          listOfLikeUsers={listOfLikedUsers}/>

        {/* Modal's */}
    </>
}

export default CommunityHuddles;
