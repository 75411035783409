import SideNav from "../staticPages/SideNav";
import {ReactNode, useEffect} from "react";
import TopNav from "../staticPages/TopNav";
import {useZStore} from "../../utils/zStore";
import {useDispatch} from "react-redux";
import {add, savePoints, saveTourData} from "../../redux/actions/action";

export default function LoggedInLayout(props: { children: ReactNode; title: string; className?: string; }) {
  const {children, title, className = ""} = props;
  const zStore = useZStore()
  const dispatch = useDispatch();

  useEffect(() => {
    if (!zStore.userDetails) {
      dispatch(add({}));
      dispatch(savePoints({}));
      dispatch(saveTourData({}));
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userDetails");
      localStorage.removeItem("notificationsCount");
      window.location.href = "/"
    }
  }, [dispatch, zStore.userDetails]);

  if (!zStore.userDetails) {
    return <></>
  }

  return (
    <main className={`bg-logged-in ${className}`}>
      {/* Top Side */}
      <div className={"d-lg-none d-block"}>
        <TopNav title={title}/>
      </div>

      {/* Left Side */}
      <div className={"d-lg-block d-none"}>
        <SideNav/>
      </div>

      {/* Right Side */}
      <div className={"lg:tw-ms-[250px] px-xl-5 px-lg-4 px-md-3 min-vh-100"}>
        <div className="lg:tw-container py-lg-5 tw-pt-3 tw-pb-24">
          {children}
        </div>
      </div>
    </main>
  )
}