import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router';

import {
    getListOfExperUsers,
    getMetaData,
    getUserInformation,
    makeFavourite,
    updateMetaData
} from '../../utils/apiUrlsSetup';

import "../../css/expert.css";

import ExpertSearch from "../../assets/svg/ExpertSearch.svg";
import ExpertGrayHeart from "../../assets/svg/ExpertGrayHeart.svg";
import ExpertRedHeart from "../../assets/svg/ExpertRedHeart.svg";
import ExpertRatings from "../../assets/svg/ExpertRatings.svg";
import rating_gray from "../../assets/rating_gray.png";
import ExpertActive from "../../assets/svg/ExpertActive.svg";
import Carousel from "react-multi-carousel";
import Joyride from 'react-joyride';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import Rating from 'react-rating';
import {saveTourData} from '../../redux/actions/action';
import LoggedInLayout from "../../component/layout/LoggedInLayout.tsx";
import PageHeader from "../../component/PageHeader";
import {Link} from "react-router-dom";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 3,
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 3,
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 2,
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1,
    },
};


function Experts() {

    const navigate = useNavigate();

    let dispatch = useDispatch();

    const [userData, setUserData] = useState([]);
    const [favArr, setFavArr] = useState([]);

    const tourDataFromState = useSelector(state => state.tourData);

    const [tourData, setTourData] = useState({});

    let userDetails = JSON.parse(localStorage.getItem('userDetails'));

    useEffect(() => {
        if (!_.isEmpty(tourDataFromState)) {
            setTourData(tourDataFromState.tourData);
        }
    }, [tourDataFromState])


    const handleJoyrideCallback = data => {
        const {action, index, status, type} = data;

        if ((status === "finished" && type === "tour:end") || action === "skip") {
            updateUserMetaData();
        }
    };

    const updateUserMetaData = async () => {
        let platformTour = {
            newsFeedTour: tourData.newsFeedTour,
            timeExchangeTour: tourData.timeExchangeTour,
            communityTour: tourData.communityTour,
            journeyTour: tourData.journeyTour,
            expertTour: true,
        };

        let response = await updateMetaData(platformTour);
        if (response.status === 200) {
            const responseData = await getMetaData();
            if (responseData.status === 200) {
                dispatch(saveTourData(responseData.data.data));
            }
        }
    }

    useEffect(() => {
        getUserInformationFunc();
    }, []);

    const getUserInformationFunc = async () => {
        const result = await getUserInformation();
        if (result.status === 200) {
            setFavArr(result.data.data.favouritesByUserId ? result.data.data.favouritesByUserId : []);
            getListOfUsers();
        }
    }

    const getListOfUsers = async () => {
        const result = await getListOfExperUsers();
        if (result.status === 200) {
            let resultData = result.data.data;
            setUserData(resultData);
        }
    }

    const makeFavouriteCoach = async (id) => {
        let favouriteId = [...favArr];

        if (favArr.includes(id)) {
            favouriteId = favouriteId.filter(v => v !== id);
        } else {
            favouriteId = [...favouriteId, id];
        }

        let result = await makeFavourite({favouriteId});
        if (result.status === 200) {
            getUserInformationFunc();
        }
    }


    const checkExpertiseFilter = (value) => {
        if (value.expertiseHistory) {
            return value.expertiseHistory.expertise;
        }
    }

    return (
        <LoggedInLayout title={"Supporters"}>
            <div className="d-flex flex-column gap-4">
                <div className={"d-flex flex-column gap-2"}>
                    <PageHeader title={"Supporters"}/>
                    <p className='journey_quest'>See a Well-being Supporter here!</p>
                    <p className='journey_quest_text'>Journey with Well-being Specialists or Peer Supporters.</p>
                </div>

                <div className={"d-flex flex-column gap-2"}>
                    <PageHeader title={"Recommended Supporters"} rightElement={
                        <>
                            <Link to={"/supporters/supporter-search"}
                                  className="input-group tw-w-[180px] d-flex gap-2 align-items-center bg-white px-2 py-1 rounded-pill tw-border-[#54bfed] tw-border tw-text-[#54bfed]">
                                <div>
                                    <img src={ExpertSearch} alt=''/>
                                </div>
                                <div className={"tw-text-xs fw-bold montserrat"}>
                                    Search Directory
                                </div>
                            </Link>
                        </>
                    }/>

                    <p className='journey_quest_text'>Displaying the relevant Supporters for your well-being needs.</p>

                    <Carousel
                        responsive={responsive}
                        swipeable={true}
                        draggable={true}
                        keyBoardControl={true}
                        arrows={true}
                    >
                        {(userData || []).map((v, i) => {
                            return <div className='expert_card pt-2 me-2' key={i}>
                                <div className='row m-0'>
                                    <div
                                        className='col-4 p-2 d-flex justify-content-center align-items-center'>
                                        <img className='expert_pic cursor_pointer'
                                             src={v.user.profilePic ? v.user.profilePic : "/static/png/Male.png"}
                                             alt='' onClick={() => {
                                            navigate('/supporters/supporter-search', {state: {userDetails: v}})
                                        }}/>
                                    </div>
                                    <div className='col-8 p-2'>

                                        <div className='row m-0'>
                                            <div
                                                className='col-10 p-0 pt-1 d-flex align-items-center justify-content-start'>

                                                <p className='mb-0 expert_name cursor_pointer'
                                                   onClick={() => {
                                                       navigate('/supporters/supporter-search', {state: {userDetails: v}})
                                                   }}>{v.user.firstName} {v.user.lastName}</p>
                                            </div>

                                            {v.user.id !== userDetails.id ? <div
                                                className='col-2 p-0 d-flex align-items-center justify-content-center'>
                                                <img className="cursor_pointer"
                                                     src={favArr.includes(v.user.id) ? ExpertRedHeart : ExpertGrayHeart}
                                                     alt=''
                                                     onClick={() => makeFavouriteCoach(v.user.id)}/>
                                            </div> : null}

                                        </div>

                                        <div className='row m-0 mt-1'>
                                            <div className='col-12 p-0'>
                                                <p className='mb-0 expert_profession'>{checkExpertiseFilter(v)}</p>
                                            </div>
                                        </div>

                                        <div className='row m-0'>
                                            <div
                                                className='col-6 p-0 d-flex align-itmes-center justify-content-between'>
                                                <Rating
                                                    initialRating={
                                                        v.user
                                                            .avgRatingOfSessions
                                                            ? v.user
                                                                .avgRatingOfSessions
                                                            : 0
                                                    }
                                                    readonly={true}
                                                    emptySymbol={
                                                        <img
                                                            className="rating_stars icon"
                                                            src={rating_gray}
                                                            alt=""
                                                        />
                                                    }
                                                    fullSymbol={
                                                        <img
                                                            className="rating_stars icon"
                                                            src={ExpertRatings}
                                                            alt=""
                                                        />
                                                    }
                                                />
                                            </div>
                                            {/* <div className='col-6 p-0 ps-2'>
                                                                            <p className='mb-0 expert_reviews'>80 Reviews</p>
                                                                        </div> */}
                                        </div>

                                        <div className='row m-0 mt-1'>
                                            <div
                                                className='col-12 p-0 d-flex align-itmes-center justify-content-start'>
                                                <img src={ExpertActive} alt=''/>
                                                <p className='mb-0 expert_availability ms-2'>Available
                                                    Now</p>
                                            </div>
                                        </div>

                                        <div className='row m-0 mt-1'>
                                            <div className='col-12 p-0'>
                                                <p className='mb-0 expert_qouets'>Supported
                                                    for <b>{v.totalNoOfHours || 0}h</b> on
                                                    KScope</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        })}

                    </Carousel>
                </div>

                <div className={"d-flex flex-column gap-2"}>
                    <PageHeader title={"Browse By Category"}/>
                    <p className='journey_quest_text'>You can browse Supporters by Category.</p>

                    <div className="d-flex gap-3">
                        <button className='expert_categories' onClick={() => {
                            navigate('/supporters/supporter-search', {state: 'Coach'})
                        }}>Coach
                        </button>
                        <button className='expert_categories' onClick={() => {
                            navigate('/supporters/supporter-search', {state: 'Counsellor'})
                        }}>Counsellor
                        </button>
                        <button className='expert_categories' onClick={() => {
                            navigate('/supporters/supporter-search', {state: 'Peer Supporter'})
                        }}>Peer Supporter
                        </button>
                    </div>

                    <div className="d-flex gap-3">
                        <button className='expert_categories expert_categories_lg me-3'
                                onClick={() => {
                                    navigate('/supporters/supporter-search', {state: 'Therapist'})
                                }}>Therapist
                        </button>
                        <button className='expert_categories me-3' onClick={() => {
                            navigate('/supporters/supporter-search', {state: 'Trainer'})
                        }}>
                            Trainer
                        </button>
                    </div>
                </div>

                <Link to={"/supporters/sessions"} className={"text-decoration-underline"}>
                    <PageHeader title={"Browse By Availability"}/>
                </Link>

                {userDetails.role === 'instructor' && (
                    <Link to={"/mycalendar"} className={"text-decoration-underline"}>
                        <PageHeader title={"Browse By Approval"}/>
                    </Link>
                )}
            </div>

            {!_.isEmpty(tourData) && !tourData.expertTour ? <Joyride
                    continuous
                    showProgress
                    showSkipButton
                    run={true}
                    callback={handleJoyrideCallback}
                    steps={[
                        {
                            content: (
                                <React.Fragment>
                                    <h2>Recommended Supporters</h2>
                                    <p>Displaying the relevant Supporters for your well-being needs.</p>
                                </React.Fragment>
                            ),
                            target: ".recommend_list",
                            placement: 'top',
                        },
                        {
                            content: (
                                <React.Fragment>
                                    <h2>Find Supporters</h2>
                                    <p>Here you can search our Supporters directory.</p>
                                </React.Fragment>
                            ),
                            target: ".expert_search_box_body",
                            placement: 'right',
                        },
                        {
                            content: (
                                <React.Fragment>
                                    <h2>Search Shortcut</h2>
                                    <p>You can browse Supporters by Category.</p>
                                </React.Fragment>
                            ),
                            target: ".browse_by_category",
                            placement: 'top',
                        }
                    ]}
                    styles={{
                        buttonNext: {
                            backgroundColor: "#5458F7",
                            borderRadius: 4,
                            color: '#fff',
                        },
                        buttonBack: {
                            color: "#8D8D8D",
                            marginLeft: 'auto',
                            marginRight: 5,
                        },
                    }}
                />
                : null}

        </LoggedInLayout>
    )
}

export default Experts;
