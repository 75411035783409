import React,{ useState } from 'react';
import '../../css/exploreQuestInvitations.css';
import SideNav from '../../component/staticPages/SideNav';

import TimeExchangePocket from "../../assets/svg/TimeExchangePocket.svg";
import TimeExchangeEmoji from "../../assets/svg/TimeExchangeEmoji.svg";
import Dimond from "../../assets/svg/Dimond.svg";
import FilterLike from "../../assets/svg/FilterLike.svg";
import FilterPeople from "../../assets/svg/FilterPeople.svg";
import Fillter_img from "../../assets/Fillter_img.png";
import Invite_profile_img1 from '../../assets/Invite_profile_img1.png';

import Aten from "../../assets/svg/Aten.svg";
import JourneyPeople from "../../assets/svg/JourneyPeople.svg";
import JourneyShare from "../../assets/svg/JourneyShare.svg";
import JourneyLike from "../../assets/svg/JourneyLike.svg";
import Journey_goal from "../../assets/svg/journey_goal.svg";
import Quest_Detail_img from "../../assets/Quest_Detail_img.png";
import Relation from '../../assets/Relation.png';


function ExploreQuestInvitations() {

    const [isOpenModal, setIsOpenModal] = useState(false)

    const [isOpenModal2, setIsOpenModal2] = useState(false)


    const handleOpenModal = () => {
        setIsOpenModal(true)
    }

    const handleCloseModal = () => {
        setIsOpenModal(false)
        setIsOpenModal2(false)
    }

    const handleOpenModal2 = () => {
        setIsOpenModal2(true)
    }


    return (
        <>

            <div className='row m-0 d-flex newsfeed_bg'>

                <div className='news_left p-0'>
                    <SideNav Journey={true} />
                </div>

                <div className='news_right p-5'>
                    <div className='row m-0'>
                        <div className='col p-0'>

                            <div className='row m-0'>

                                <div className='col-8 p-0'>
                                    <p className='mb-0 notification_heading'>Your Quests</p>
                                    <p className='mb-0 mt-3 journey_bredcrum '>Journey > Quest > <span className='quest_border'>Explore Quests</span></p>

                                </div>
                                <div className='col-4 p-0 d-flex align-items-center justify-content-end'>
                                    <p className='mb-0 time_exchange_right_head text-right'> <img className='mb-1' src={TimeExchangePocket} alt=' ' /> 36H &nbsp; <span className='deivder_time_ex'>|</span> &nbsp; <img className='mb-1' src={TimeExchangeEmoji} alt=' ' /> 2400 WBX</p>
                                </div>

                            </div>



                            <div className='row m-0 mt-4'>
                                <div className='col-12 p-0'>
                                    <p className='invitation_heading'>Invitations</p>
                                </div>
                            </div>

                            <div className='row m-0'>
                                <div className='col-12 p-0'>

                                    <div className='row m-0'>
                                        <div className='col-4 p-0'>

                                            <div className='row m-0'>
                                                <div className='col-12 p-0'>

                                                    <div className='filter_big_box invitation_box'>

                                                        <div className='row m-0 pb-2 btm_border'>
                                                            <div className='pt-2 d-flex'>
                                                                <div className='col-1 p-0 d-flex justify-content-end'>
                                                                    <div className='invitation_img_box'>
                                                                        <img className='invitation_img' src={Invite_profile_img1} alt='' />
                                                                    </div>
                                                                </div>
                                                                <div className='col-9 p-0 d-flex flex-column align-items-start justify-content-start ps-2'>
                                                                    <p className='mb-0 invitation_profile_name'>Amanda invited you</p>
                                                                    <p className='mb-0 invitation_profile_time'>5 min ago</p>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className='pt-2 pb-4 ps-2'>

                                                            <div className='row m-0'>
                                                                <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                                    <div className='ong_que_end_line d-flex align-items-center justify-content-center'>
                                                                        <p className='mb-0 ong_que_end_line_text'>2 days</p>
                                                                    </div>

                                                                    <div className='filter_que_img_box'>
                                                                        <img src={Fillter_img} alt='' />
                                                                    </div>

                                                                </div>
                                                                <div className='col-9 p-0 pt-2 pe-2 ps-1'>

                                                                    <div className='row m-0'>
                                                                        <div className='col-9 p-0'>
                                                                            <p className='mb-0 on_quest_big_box_heading on_quest_big_box_heading_blue '>Improve Yourself</p>
                                                                        </div>
                                                                        <div className='col-3 p-0 d-flex align-itmes-center justify-content-center'>
                                                                            <p className='mb-0 on_quest_big_box_right'>500 &nbsp; <img src={Dimond} alt='' /></p>
                                                                        </div>
                                                                    </div>

                                                                    <div className='row m-0 mt-1'>
                                                                        <div className='col-12 p-0 pe-4'>
                                                                            <p className='mb-0 filter_quest_big_box_heading_text'>Well-being Touchpoint </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className='row m-0 mt-3'>

                                                                        <div className='col-12 p-0 d-flex align-items-center justify-content-start'>
                                                                            <p className='mb-0 filter_big_box_like me-4'><img src={FilterLike} alt='' /> &nbsp; 124</p>
                                                                            <p className='mb-0 filter_big_box_like'><img src={FilterPeople} alt='' /> &nbsp; 40k</p>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className='filter_big_box invitation_box invitation_box_opacity mt-3'>

                                                        <div className='row m-0 pb-2 btm_border'>
                                                            <div className='pt-2 d-flex'>
                                                                <div className='col-1 p-0 d-flex justify-content-end'>
                                                                    <div className='invitation_img_box'>
                                                                        <img className='invitation_img' src={Invite_profile_img1} alt='' />
                                                                    </div>
                                                                </div>
                                                                <div className='col-9 p-0 d-flex flex-column align-items-start justify-content-start ps-2'>
                                                                    <p className='mb-0 invitation_profile_name'>Amanda invited you</p>
                                                                    <p className='mb-0 invitation_profile_time'>5 min ago</p>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className='pt-2 pb-4 ps-2'>

                                                            <div className='row m-0'>
                                                                <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                                    <div className='ong_que_end_line d-flex align-items-center justify-content-center'>
                                                                        <p className='mb-0 ong_que_end_line_text'>2 days</p>
                                                                    </div>

                                                                    <div className='filter_que_img_box'>
                                                                        <img src={Fillter_img} alt='' />
                                                                    </div>

                                                                </div>
                                                                <div className='col-9 p-0 pt-2 pe-2 ps-1'>

                                                                    <div className='row m-0'>
                                                                        <div className='col-9 p-0'>
                                                                            <p className='mb-0 on_quest_big_box_heading on_quest_big_box_heading_blue '>Improve Yourself</p>
                                                                        </div>
                                                                        <div className='col-3 p-0 d-flex align-itmes-center justify-content-center'>
                                                                            <p className='mb-0 on_quest_big_box_right'>500 &nbsp; <img src={Dimond} alt='' /></p>
                                                                        </div>
                                                                    </div>

                                                                    <div className='row m-0 mt-1'>
                                                                        <div className='col-12 p-0 pe-4'>
                                                                            <p className='mb-0 filter_quest_big_box_heading_text'>Well-being Touchpoint </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className='row m-0 mt-3'>

                                                                        <div className='col-12 p-0 d-flex align-items-center justify-content-start'>
                                                                            <p className='mb-0 filter_big_box_like me-4'><img src={FilterLike} alt='' /> &nbsp; 124</p>
                                                                            <p className='mb-0 filter_big_box_like'><img src={FilterPeople} alt='' /> &nbsp; 40k</p>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className='filter_big_box invitation_box invitation_box_opacity  mt-3'>

                                                        <div className='row m-0 pb-2 btm_border'>
                                                            <div className='pt-2 d-flex'>
                                                                <div className='col-1 p-0 d-flex justify-content-end'>
                                                                    <div className='invitation_img_box'>
                                                                        <img className='invitation_img' src={Invite_profile_img1} alt='' />
                                                                    </div>
                                                                </div>
                                                                <div className='col-9 p-0 d-flex flex-column align-items-start justify-content-start ps-2'>
                                                                    <p className='mb-0 invitation_profile_name'>Amanda invited you</p>
                                                                    <p className='mb-0 invitation_profile_time'>5 min ago</p>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className='pt-2 pb-4 ps-2'>

                                                            <div className='row m-0'>
                                                                <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                                    <div className='ong_que_end_line d-flex align-items-center justify-content-center'>
                                                                        <p className='mb-0 ong_que_end_line_text'>2 days</p>
                                                                    </div>

                                                                    <div className='filter_que_img_box'>
                                                                        <img src={Fillter_img} alt='' />
                                                                    </div>

                                                                </div>
                                                                <div className='col-9 p-0 pt-2 pe-2 ps-1'>

                                                                    <div className='row m-0'>
                                                                        <div className='col-9 p-0'>
                                                                            <p className='mb-0 on_quest_big_box_heading on_quest_big_box_heading_blue '>Improve Yourself</p>
                                                                        </div>
                                                                        <div className='col-3 p-0 d-flex align-itmes-center justify-content-center'>
                                                                            <p className='mb-0 on_quest_big_box_right'>500 &nbsp; <img src={Dimond} alt='' /></p>
                                                                        </div>
                                                                    </div>

                                                                    <div className='row m-0 mt-1'>
                                                                        <div className='col-12 p-0 pe-4'>
                                                                            <p className='mb-0 filter_quest_big_box_heading_text'>Well-being Touchpoint </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className='row m-0 mt-3'>

                                                                        <div className='col-12 p-0 d-flex align-items-center justify-content-start'>
                                                                            <p className='mb-0 filter_big_box_like me-4'><img src={FilterLike} alt='' /> &nbsp; 124</p>
                                                                            <p className='mb-0 filter_big_box_like'><img src={FilterPeople} alt='' /> &nbsp; 40k</p>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className='filter_big_box invitation_box invitation_box_opacity mt-3'>

                                                        <div className='row m-0 pb-2 btm_border'>
                                                            <div className='pt-2 d-flex'>
                                                                <div className='col-1 p-0 d-flex justify-content-end'>
                                                                    <div className='invitation_img_box'>
                                                                        <img className='invitation_img' src={Invite_profile_img1} alt='' />
                                                                    </div>
                                                                </div>
                                                                <div className='col-9 p-0 d-flex flex-column align-items-start justify-content-start ps-2'>
                                                                    <p className='mb-0 invitation_profile_name'>Amanda invited you</p>
                                                                    <p className='mb-0 invitation_profile_time'>5 min ago</p>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className='pt-2 pb-4 ps-2'>

                                                            <div className='row m-0'>
                                                                <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                                    <div className='ong_que_end_line d-flex align-items-center justify-content-center'>
                                                                        <p className='mb-0 ong_que_end_line_text'>2 days</p>
                                                                    </div>

                                                                    <div className='filter_que_img_box'>
                                                                        <img src={Fillter_img} alt='' />
                                                                    </div>

                                                                </div>
                                                                <div className='col-9 p-0 pt-2 pe-2 ps-1'>

                                                                    <div className='row m-0'>
                                                                        <div className='col-9 p-0'>
                                                                            <p className='mb-0 on_quest_big_box_heading on_quest_big_box_heading_blue '>Improve Yourself</p>
                                                                        </div>
                                                                        <div className='col-3 p-0 d-flex align-itmes-center justify-content-center'>
                                                                            <p className='mb-0 on_quest_big_box_right'>500 &nbsp; <img src={Dimond} alt='' /></p>
                                                                        </div>
                                                                    </div>

                                                                    <div className='row m-0 mt-1'>
                                                                        <div className='col-12 p-0 pe-4'>
                                                                            <p className='mb-0 filter_quest_big_box_heading_text'>Well-being Touchpoint </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className='row m-0 mt-3'>

                                                                        <div className='col-12 p-0 d-flex align-items-center justify-content-start'>
                                                                            <p className='mb-0 filter_big_box_like me-4'><img src={FilterLike} alt='' /> &nbsp; 124</p>
                                                                            <p className='mb-0 filter_big_box_like'><img src={FilterPeople} alt='' /> &nbsp; 40k</p>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>


                                        </div>

                                        <div className='col-8 p-0'>


                                            <div className='quest_details_box invitation_details_box  pe-4 ps-4 pt-4 pb-5'>

                                                <div className='row m-0'>

                                                    <div className='col-3 p-0 d-flex flex-column align-items-center justify-content-center'>

                                                        <div className='quest_details_que_end_line d-flex align-items-center justify-content-center'>
                                                            <p className='mb-0 ong_que_end_line_text'>Ends in 2 days</p>
                                                        </div>
                                                        <div className='quest_details_img_box'>
                                                            <img className='quest_details_img' src={Quest_Detail_img} alt='' />

                                                        </div>

                                                    </div>

                                                    <div className='col-6 p-0'>
                                                        <p className='mb-0 quest_details_heading'>Relationship Power Up!</p>
                                                        <p className='mb-0 quest_details_session mt-2'>1-to-1 Sessions</p>

                                                        <div className='d-flex'>
                                                            <button className='strat_quest_btn mt-5' onClick={handleOpenModal}>Join</button>
                                                            <button className='ignore_quest_btn mt-5 ms-4' onClick={handleOpenModal2}>Ignore</button>

                                                        </div>

                                                    </div>

                                                    <div className='col-3 p-0'>

                                                        <div className='row m-0'>
                                                            <div className='col-12 p-0 d-flex align-items-center justify-content-end'>
                                                                <p className='mb-0 on_quest_big_box_right'>500 &nbsp; <img src={Dimond} alt='' /></p>
                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-4'>
                                                            <div className='col-12 p-0 d-flex align-items-center justify-content-end'>

                                                                <div className='quest_relation d-flex align-items-center justify-content-between ps-2 pe-2'>
                                                                    <img className='quest_relation_img' src={Relation} alt='' />
                                                                    <p className='mb-0 quest_relation_name'>Relationship </p>

                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className='row m-0 mt-4 d-flex align-items-center justify-content-around'>

                                                            <div className='col-4 quest_relation_oprat d-flex align-items-center justify-content-center'>
                                                                <img src={JourneyLike} alt='' />
                                                            </div>

                                                            <div className='col-4 quest_relation_oprat d-flex align-items-center justify-content-center'>
                                                                <img src={JourneyPeople} alt='' />
                                                            </div>

                                                            <div className='col-4 quest_relation_oprat d-flex align-items-center justify-content-center'>
                                                                <img src={JourneyShare} alt='' />
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                                <div className='row m-0 mt-4'>
                                                    <div className='col-12 p-0'>
                                                        <p className='mb-0 quest_description_heading'>Description</p>
                                                        <p className='mb-0 mt-2 quest_description_details'>Invite 1 friend to a 1-to-1 Session with you, and simply talk about your day!</p>
                                                    </div>
                                                </div>

                                                <div className='row m-0 mt-5'>
                                                    <div className='col-12 p-0 pt-4'>
                                                        <p className='mb-0 quest_description_heading'>How does this improve your well-being?</p>
                                                        <p className='mb-0 mt-2 quest_description_details'>You will gain insights from people you interact with, especially with the coaches. Make yourself more confident to communicate your feeling with friends.</p>
                                                    </div>
                                                </div>


                                                <div className='row m-0 mt-5'>
                                                    <div className='col-6 p-0 pe-4'>

                                                        <div className='row m-0 invite_row pt-3 pb-2'>
                                                            <div className='col-10 p-0 d-flex align-items-center justify-content-start'>
                                                                <p className='mb-0 invite_ttendees'>Attendees</p>
                                                            </div>
                                                            <div className='col-2 p-0 d-flex align-items-center justify-content-center ps-1'>
                                                                <img src={Aten} alt='' />
                                                            </div>

                                                        </div>

                                                        <div className='row m-0  invite_row pt-3 pb-2'>

                                                            <div className='col-1 p-0 d-flex align-items-center justify-content-center'>
                                                                <div className='invite_img_box'>
                                                                    <img className='invite_img' src={Invite_profile_img1} alt='' />
                                                                </div>
                                                            </div>
                                                            <div className='col-9 p-0 d-flex align-items-center justify-content-start ps-2'>
                                                                <p className='mb-0 invite_profile_name'>Blessy</p>
                                                            </div>
                                                            <div className='col-2 p-0 d-flex align-items-center justify-content-start'>
                                                                <p className='mb-0 invite_status'>Joined</p>
                                                            </div>

                                                        </div>

                                                        <div className='row m-0  invite_row pt-3 pb-2'>

                                                            <div className='col-1 p-0 d-flex align-items-center justify-content-center'>
                                                                <div className='invite_img_box'>
                                                                    <img className='invite_img' src={Invite_profile_img1} alt='' />
                                                                </div>
                                                            </div>
                                                            <div className='col-9 p-0 d-flex align-items-center justify-content-start ps-2'>
                                                                <p className='mb-0 invite_profile_name'>Blessy</p>
                                                            </div>
                                                            <div className='col-2 p-0 d-flex align-items-center justify-content-start'>
                                                                <p className='mb-0 invite_status'>Waiting</p>
                                                            </div>

                                                        </div>

                                                        <div className='row m-0  invite_row pt-3 pb-2'>

                                                            <div className='col-1 p-0 d-flex align-items-center justify-content-center'>
                                                                <div className='invite_img_box'>
                                                                    <img className='invite_img' src={Invite_profile_img1} alt='' />
                                                                </div>
                                                            </div>
                                                            <div className='col-9 p-0 d-flex align-items-center justify-content-start ps-2'>
                                                                <p className='mb-0 invite_profile_name'>Blessy</p>
                                                            </div>
                                                            <div className='col-2 p-0 d-flex align-items-center justify-content-start'>
                                                                <p className='mb-0 invite_status'>Waiting</p>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className='col-6 p-0'>

                                                        <div className='row m-0'>
                                                            <div className='col-12 p-0'>

                                                                <div className='row m-0'>
                                                                    <div className='col-12 p-0 ps-5 pb-3'>
                                                                        <p className=' mb-0 journey_active_list ms-3'>Activity List</p>
                                                                    </div>
                                                                </div>

                                                                <div className='row m-0 mt-3'>
                                                                    <div className='col-12 p-0 d-flex aline-items-center justify-content-center'>
                                                                        <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Spends 3 hours of 1-1 sessions</p>
                                                                    </div>

                                                                </div>

                                                                <div className='row m-0 mt-3'>
                                                                    <div className='col-12 p-0 d-flex aline-items-center justify-content-center'>
                                                                        <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Spends 3 hours of 1-1 sessions</p>
                                                                    </div>

                                                                </div>

                                                                <div className='row m-0 mt-3'>
                                                                    <div className='col-12 p-0 d-flex aline-items-center justify-content-center'>
                                                                        <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Spends 3 hours of 1-1 sessions</p>
                                                                    </div>

                                                                </div>

                                                                <div className='row m-0 mt-3'>
                                                                    <div className='col-12 p-0 d-flex aline-items-center justify-content-center'>
                                                                        <img src={Journey_goal} alt='' /><p className='mb-0 journey_session_details ms-2'>Spends 3 hours of 1-1 sessions</p>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal-1 */}

                <div class={`modal fade ${isOpenModal ? 'show' : ''}`} style={{ display: isOpenModal ? 'block' : 'none', background: isOpenModal ? 'rgba(0,0,0,0.5)' : '' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog questinvite_modal">
                        <div class="modal-content questinvite_modal_content p-4">
                            <div class="modal-body questinvite_modal_body">
                                <div className='row m-0'>
                                    <div className='col-12 p-0 d-flex aline-items-center justify-content-center'>
                                        <p className='questinvite_heading'>Ready to go?</p>
                                    </div>
                                </div>

                                <div className='row m-0'>
                                    <div className='col-12 p-0 pe-4 ps-4'>
                                        <p className='questinvite_heading_detail text-center'>The quest will start immediately after all the attendees have joined.</p>
                                    </div>
                                </div>

                                <div className='row m-0'>
                                    <div className='col-12 p-0 d-flex aline-items-center justify-content-center'>
                                        <button className='questinvite_cancle_btn me-3' onClick={handleCloseModal}>cancel</button>
                                        <button className='questinvite_join_btn me-3'>Join Quest</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* modal-2 */}

                <div class={`modal fade ${isOpenModal2 ? 'show' : ''}`} style={{ display: isOpenModal2 ? 'block' : 'none', background: isOpenModal2 ? 'rgba(0,0,0,0.5)' : '' }} id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel1" aria-hidden="true">
                    <div class="modal-dialog questinvite_modal">
                        <div class="modal-content questinvite_modal_content p-4">

                            <div class="modal-body questinvite_modal_body">
                                <div className='row m-0'>
                                    <div className='col-12 p-0 d-flex aline-items-center justify-content-center'>
                                        <p className='questinvite_heading'>Not coming?</p>
                                    </div>
                                </div>

                                <div className='row m-0'>
                                    <div className='col-12 p-0 pe-4 ps-4'>
                                        <p className='questinvite_heading_detail text-center'>Your friends will get notified that you won’t join the quest with them.</p>
                                    </div>
                                </div>

                                <div className='row m-0'>
                                    <div className='col-12 p-0 d-flex aline-items-center justify-content-center'>
                                        <button className='questinvite_cancle_btn_red me-3'onClick={handleCloseModal}>cancel</button>
                                        <button className='questinvite_join_btn_red me-3'>Join Quest</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>



            </div>


        </>
    )
}

export default ExploreQuestInvitations
