import React, {useEffect, useState} from 'react';
import 'react-multi-carousel/lib/styles.css';

import "./community.css";

import Header from '../../component/staticPages/header';
import Footer from '../../component/staticPages/footer';

import Illustration1 from "../../assets/svg/Community_illustration_one.svg";
import Illustration2 from "../../assets/svg/Community_illustration_two.svg";
import Illustration3 from "../../assets/svg/Community_illustration_three.svg";
import HeroBg from "../../assets/brainStrom.jpg"
import {getTotalUsersCount} from '../../utils/apiUrlsSetup';

function Community() {

    const [usersCount, setUsersCount] = useState({});

    useEffect(() => {
        getTotalUsersCountFunc();
    }, []);

    const getTotalUsersCountFunc = async () => {
        let responseData = await getTotalUsersCount();
        if (responseData.status === 200) {
            setUsersCount(responseData.data.data)
        }
    }

    return (
        <>
            <main className={"bg-logged-in"}>
                <div className="container-xl">
                    <Header/>
                </div>
                <div className="w-100 tw-h-[min(670px,100vh)] tw-bg-cover tw-bg-bottom d-flex align-items-end py-5"
                     style={{
                         backgroundImage: `url(${HeroBg})`,
                     }}>
                    <div className="container-xl">
                        <h1 className="montserrat tw-font-extrabold text-white tw-text-7xl ls-near">
                            A Community of <br/> communities
                        </h1>
                    </div>
                </div>
            </main>

            <p className="m-0 text-center montserrat tw-text-sm text-secondary">Photo: @Natanavo</p>

            <section className={"container-xl my-5 p-5"}>
                <div className="row text-center gy-4">
                    <div className="col-12 col-md-6">
                        <p className='tw-text-6xl montserrat ls-near tw-text-[#18214D] tw-font-extrabold'>{usersCount.usersCount || 0}</p>
                        <p className='inter fw-bold tw-text-[#E138BC] tw-text-2xl lh-1'>Well-being Champions<br/>and
                            Peer
                            Supporters</p>
                    </div>
                    <div className="col-12 col-md-6">
                        <p className='tw-text-6xl montserrat ls-near tw-text-[#18214D] tw-font-extrabold'>{usersCount.instructorsCount || 0}</p>
                        <p className='inter fw-bold tw-text-[#E138BC] tw-text-2xl lh-1'>Well-being Providers</p>
                    </div>
                </div>
            </section>

            <section className="container-xl my-5 p-5">
                <p className='community_mid_bold_text text-center mb-4'>What the Network Experiences Together</p>

                <div className='row'>

                    <div
                        className='col-lg-4 col-md-4 col-12 d-flex flex-column align-items-center justify-content-lg-center'>

                        <div className='illus_div'>
                            <img className='community_illustration' src={Illustration1} alt=''/>
                        </div>

                        <p className='mb-0 mt-4 community_pink_text text-center'>Communities</p>
                        <p className='mb-0 mt-3 community_pink_mid_text text text-center'>Communities of
                            care <br className='hide_in_mobile unshow_in_tab'/>across a multitude of
                            Well-being <br className='hide_in_mobile unshow_in_tab'/>topics and audiences.
                        </p>
                    </div>

                    <div
                        className='col-lg-4 col-md-4 col-12 d-flex flex-column align-items-center justify-content-lg-center'>

                        <div className='illus_div'>
                            <img className='community_illustration' src={Illustration2} alt=''/>
                        </div>

                        <p className='mb-0 mt-4 community_pink_text text-center'>Giving</p>
                        <p className='mb-0 mt-3 community_pink_mid_text text text-center'>A
                            first-of-its-kind <br className='hide_in_mobile unshow_in_tab'/> Well-being
                            Exchange to provide or seek <br
                                className='hide_in_mobile unshow_in_tab'/> well-being support in three
                            ways: <br className='hide_in_mobile unshow_in_tab'/> Listening, Mentoring, or
                            Doing.</p>
                    </div>

                    <div
                        className='col-lg-4 col-md-4 col-12 mt-lg-0 mt-md-0 mt-5 d-flex flex-column align-items-center justify-content-lg-center'>

                        <div className='illus_div'>
                            <img className='community_illustration' src={Illustration3} alt=''/>
                        </div>

                        <p className='mb-0 mt-4 community_pink_text text-center'>Quests (coming soon!)</p>
                        <p className='mb-0 mt-3 community_pink_mid_text text text-center'>Curated list of
                            daily challenges <br className='hide_in_mobile unshow_in_tab'/>& progressive
                            quests based on the<br className='hide_in_mobile unshow_in_tab'/>Hero's Journey
                            to build<br className='hide_in_mobile unshow_in_tab'/>your Well-being skills.
                        </p>
                    </div>

                </div>

            </section>

            <Footer/>
        </>
    )
}

export default Community;