import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {getExpertiseHistory, getUserInformationById, userStatus} from '../../utils/apiUrlsSetup';
import RejectModal from './component/reject-modal';
import "./superAdmin.css";
import {Link, useNavigate} from 'react-router-dom';
import CsvDownloadButton from 'react-json-to-csv'
import LoggedInLayout from "../../component/layout/LoggedInLayout.tsx";


const CoachProfile = () => {
    const {id} = useParams();
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [userInfo, saveUserInfo] = useState({
        jobHistoryArray: [],
        educationHistoryArray: []
    })

    const [surveyData, setSurveyData] = useState(null)

    const [userHistoryInfo, saveUserHistoryInfo] = useState({});

    const [showStatus, setShowStatus] = useState("1")

    const navigate = useNavigate();

    useEffect(() => {
        getCoachInformation();
        getExpertiseHistoryFunc();
    }, [])

    const getCoachInformation = async () => {
        let responseData = await getUserInformationById(id);
        if (responseData.status === 200) {
            saveUserInfo(responseData.data.data)
            if (responseData.data.data && responseData.data.data.surveyData) {
                const data = responseData.data.data.surveyData.surveyData
                const sData = Object.keys(data).map(key => {
                    return {'Question': data[key].description, 'Answer': data[key].answer}
                })

                setSurveyData(sData)
            }
        }

    }

    const getExpertiseHistoryFunc = async () => {
        let responseData = await getExpertiseHistory(id);
        if (responseData.statusCode === 200) {
            saveUserHistoryInfo(responseData.data || {})
        }
    }

    const handleApproval = async (id) => {
        let objData = {}
        objData.userId = id
        objData.status = "active"
        let responseData = await userStatus(objData);
        if (responseData.status === 200) {
            setShowStatus("2");
            getCoachInformation();
        }
    }


    const handleRejection = async (id) => {
        let objData = {}
        objData.userId = id
        objData.status = "rejected"
        let responseData = await userStatus(objData);
        if (responseData.status === 200) {
            setShowStatus("3")
            setIsOpenModal(false)
            getCoachInformation();
        }

    }


    const changeCreateOrgStatus = async (status) => {
        let objData = {}
        objData.userId = id
        objData.status = userInfo.status;
        objData.canCreateOrganisation = status;
        let responseData = await userStatus(objData);
        if (responseData.status === 200) {
            getCoachInformation();
        }
    }


    return (
        <LoggedInLayout title={"Coach Profile"}>
            <div className={"d-flex align-items-center gap-3"}>
                <Link to={"/userlist"}>
                    <i className="bi bi-chevron-left text-primary"/>
                </Link>
                <div className="tw-text-2xl fw-semibold montserrat tw-text-[#54bfed]">
                    Profile
                </div>
            </div>

            {
                showStatus === "2" && (
                    <div className='p-0 ps-4 approval_lable_in_green d-flex align-items-center'>
                        <p className='mb-0 approval_lable_text'>You approved {userInfo.firstName} as one of
                            the professional coaches for Kaleidoscope.</p>
                    </div>
                )
            }
            {
                showStatus === "3" && (
                    <div
                        className='p-0 ps-4 approval_lable_in_green reject_lable_in_red d-flex align-items-center'>
                        <p className='mb-0 approval_lable_text'>You rejected {userInfo.firstName} as one of
                            the professional coaches for Kaleidoscope.</p>
                    </div>
                )
            }

            <div className='coach_profile_update_box p-5 my-3'>
                <div className='row gy-5'>
                    <div className='col-3'>
                        <p className='mb-0 profile_edit_heading'>First Name</p>
                        <p className='mb-0 value_of_the_field'>{userInfo.firstName}</p>
                    </div>

                    <div className='col-3'>
                        <p className='mb-0 profile_edit_heading'>Last Name</p>
                        <p className='mb-0 value_of_the_field'>{userInfo.lastName}</p>
                    </div>

                    <div className='col-6'>
                        <p className='mb-0 profile_edit_heading'>Email</p>
                        <p className='mb-0 value_of_the_field'>{userInfo.email}</p>
                    </div>

                    {
                        userHistoryInfo.expertise && (
                            <>
                                <div className={"col-12"}>
                                    <p className='mb-0 profile_edit_heading'>Experience</p>
                                    <p className='mb-0 value_of_the_field'>{userHistoryInfo.expertise}</p>
                                </div>

                                {
                                    (Array.isArray(userInfo.certificates) && userInfo.certificates.length) && (
                                        <div className='col-12'>
                                            <p className='mb-0 profile_edit_heading'>Certificates</p>
                                            {Array.isArray(userInfo.certificates) && (
                                                <>
                                                    {
                                                        userInfo.certificates.map(c =>
                                                            <p className='mb-0 value_of_the_field'>
                                                                <a
                                                                    target="_blank"
                                                                    href={c} style={{cursor: 'pointer'}}
                                                                    rel="noreferrer">
                                                                    {c}
                                                                </a>
                                                            </p>
                                                        )
                                                    }
                                                </>
                                            )}
                                        </div>
                                    )
                                }

                                {
                                    (userHistoryInfo.communityArray.length > 0) && (
                                        <div className='col-12'>
                                            <p className='mb-0 title_of_the_field'>Community</p>
                                            {
                                                userHistoryInfo
                                                    .communityArray
                                                    .map(v => <p className='mb-0 value_of_the_field'>{v}</p>)
                                            }
                                        </div>
                                    )
                                }
                            </>
                        )
                    }

                    <div className='col-12 col-lg-6 d-flex justify-content-end justify-content-lg-start gap-3'>

                        {userInfo.canCreateOrganisation &&
                            <div>
                                <button className='btn btn-danger btn-sm text-white fw-semibold rounded-pill px-4'
                                        onClick={() => changeCreateOrgStatus(false)}>
                                    Remove from Community Admin
                                </button>
                            </div>
                        }
                        {!userInfo.canCreateOrganisation && userInfo.status !== 'rejected' &&
                            <div>
                                <button
                                    className='btn !tw-bg-[#109cf1] btn-sm text-white fw-semibold rounded-pill px-4 !tw-py-1'
                                    type={"button"}
                                    onClick={() => changeCreateOrgStatus(true)}>
                                    Assign as Community Admin
                                </button>
                            </div>
                        }

                        {
                            surveyData && (
                                <div>
                                    <CsvDownloadButton delimiter={","}
                                                       header={["Question", "Answer"]}
                                                       className='btn !tw-bg-[#109cf1] btn-sm text-white fw-semibold rounded-pill px-4 !tw-py-1'
                                                       data={surveyData}/>
                                </div>
                            )
                        }
                    </div>


                    {
                        userInfo.status === "pending" &&
                        <div className='col-12 col-lg-6 d-flex justify-content-end gap-3'>
                            <button className='coach_edit_btn_reject'
                                    onClick={() => setIsOpenModal(true)}>Reject
                            </button>
                            <button className='coach_edit_btn_approve'
                                    onClick={() => handleApproval(id)}>Approve
                            </button>
                        </div>
                    }
                </div>
            </div>

            {
                isOpenModal && (
                    <RejectModal
                        setIsOpenModal={setIsOpenModal}
                        isOpenModal={isOpenModal}
                        id={id}
                        handleRejection={handleRejection}
                    />
                )
            }
        </LoggedInLayout>
    )
}

export default CoachProfile;