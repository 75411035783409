import React, {useEffect, useState} from "react";
import {subCategories} from "../utils/constant";
import BackCross from "../assets/BackCross.png";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {createPost} from "../utils/apiUrlsSetup";
import S3FileUpload from "react-s3";
import {awsConfig} from "../env";
import {PDFDocument} from "pdf-lib";
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {generateUUID} from "pubnub";
import DocumentPostView from "../container/newsFeed/component/post/DocumentPostView";
import {useZStore} from "../utils/zStore";
import {PostCardPreview} from "../container/newsFeed/component/post/PostCardPreview";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

/**
 * @param {{
 *   referenceId: false | 0 | number,
 *   toggle: VoidFunction,
 *   afterSubmit: () => Promise<void>,
 *   organisationId?: number
 * }} props
 * @returns {Element}
 * @constructor
 */
export default function ModalCreatePost(props) {
  const store = useZStore();
  const userDetails = store.userDetails;

  if (!userDetails.profilePic) {
    userDetails.profilePic = "/static/png/Male.png"
  }

  const [subCategory, setSubCategory] = useState(null);
  const [description, setPostDescription] = useState('');
  const [visibility, setPostVisibility] = useState('everyone')
  const [selectedMedia, setSelectedMedia] = useState([])
  const [type, setType] = useState('text');
  const [selectedFile, setSelectedFile] = useState({});
  const [selectedVideo, setSelectedVideo] = useState({});
  const [publishedAt, setPublishedAt] = useState(new Date());

  useEffect(() => {
    console.log({publishedAt})
  }, [publishedAt]);

  const minDatetime = new Date();
  minDatetime.setMinutes(minDatetime.getMinutes() - 1);

  const handleSubmitForPost = async () => {
    let urls = selectedMedia.map(data => data.url);
    if (type === "document") {
      urls = [selectedFile.url]
    } else if (type === "video") {
      urls = [selectedVideo.url]
    }

    const data = {
      type: type,
      description: description,
      visible: visibility,
    }

    if (urls.length > 0) {
      data.attachments = urls
    }

    if (subCategory) {
      data.subCategory = [subCategory]

      if (["Breathing", "Moving", "Acknowledging", "Belonging"].includes(subCategory)) {
        data.category = "feeling"
      }
      if (["Giving", "Caring", "Relating", "Communicating"].includes(subCategory)) {
        data.category = "connecting"
      }
      if (["Working", "Learning", "Noticing", "Meaning"].includes(subCategory)) {
        data.category = "functioning"
      }
    }

    if (props.organisationId) {
      data.organisationId = props.organisationId;
    }

    if (props.referenceId) {
      data.linkedPostId = props.referenceId
    }

    data.publishedAt = new Date(publishedAt)

    const response = await createPost(data)

    if (response.status === 200) {
      setPostDescription("")
      setSelectedFile({});
      setType("text");
      setSelectedMedia([]);
      setSubCategory(null);
      await props.afterSubmit()
      props.toggle()
    }
  }

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);

      reader.readAsArrayBuffer(file);
    });
  };

  const getNumPages = async (file) => {
    const arrayBuffer = await readFile(file);
    const pdf = await PDFDocument.load(arrayBuffer);
    return pdf.getPages();
  };

  const handleFile = async (fileData) => {
    let pages = await getNumPages(fileData[0]);
    const response = await S3FileUpload.uploadFile(fileData[0], awsConfig)
    setType("document")
    if (response && response.result.status === 204) {
      let obj = {url: response.location, key: response.key, pages: pages.length}
      setSelectedFile(obj);
    }
  }

  const handleVideo = async (fileData) => {
    const response = await S3FileUpload.uploadFile(fileData[0], awsConfig)
    setType("video")
    if (response && response.result.status === 204) {
      let obj = {url: response.location, key: response.key}
      setSelectedVideo(obj);
    }
  }

  const deleteImage = async (key) => {
    const findIndex = selectedMedia.findIndex(data => data.key === key)
    selectedMedia.splice(findIndex, 1)
    setSelectedMedia([...selectedMedia])
    if (!selectedMedia.length) {
      setType("text");
    }
  }

  const deleteFile = async () => {
    setSelectedFile({});
    setType("text");
  }

  const deleteVideo = async () => {
    setSelectedVideo({});
    setType("text");
  }

  const handleMedia = async (fileData, type) => {
    if (type === "video") {
      return await handleVideo(fileData);
    } else if (type === "document") {
      return await handleFile(fileData);
    }

    window.Buffer = window.Buffer || require("buffer").Buffer;
    for (let i = 0; i < fileData.length; i++) {
      const response = await S3FileUpload.uploadFile(fileData[i], awsConfig)
      setType(type)
      if (response && response.result.status === 204) {
        selectedMedia.push({url: response.location, key: response.key})
        setSelectedMedia([...selectedMedia])
      }
    }
  }

  return (
    <Modal isOpen={props.referenceId !== false} toggle={props.toggle} size={"lg"}>
      <ModalHeader toggle={props.toggle} className={"align-items-start border-0 pb-0"}>
        <div className="d-flex gap-2 tw-items-center">
          <div>
            <img src={userDetails.profilePic} alt="" className={"rounded-circle"} width="50" height="50"/>
          </div>
          <div>
            <p className={"tw-font-medium tw-text-md mb-1"}>{userDetails.firstName} {userDetails.lastName}</p>
            <div className={"d-flex gap-1 tw-text-sm"}>
              Post to
              <select className="d-inline-block tw-outline-0 tw-border tw-rounded"
                      onChange={(e) => setPostVisibility(e.target.value)}>
                <option value="everyone">Anyone</option>
                <option value="friends-only">Friends only</option>
                <option value="only-me">Only me</option>
              </select>
            </div>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <textarea
          onChange={(e) => setPostDescription(e.target.value)}
          className='inter tw-border-0 tw-outline-0 tw-w-full'
          placeholder='What are you going to share ...'
          rows={5}
          value={description}
        />


        {type === "image" && (
          <div className='d-flex justify-content-start align-items-start gap-3 flex-wrap'>
            {
              selectedMedia.map((imageData, index) => {
                return (
                  <div className='tw-relative' key={index}>
                    <img className='tw-absolute tw-top-0 tw-end-0 tw-m-1 tw-cursor-pointer'
                         src={BackCross} alt=''
                         onClick={() => deleteImage(imageData.key)}/>

                    <img className='tw-rounded-xl tw-max-w-[150px]' src={imageData.url}
                         alt=''/>
                  </div>
                )
              })
            }
          </div>
        )}

        {type === "video" && (
          <>
            <div className="mb-1 w-100 justify-content-end d-flex">
              <img className='tw-cursor-pointer'
                   src={BackCross} alt=''
                   onClick={async (e) => {
                     e.stopPropagation();
                     await deleteVideo();
                   }}/>
            </div>

            <video controls id="video" className={"tw-rounded-2xl"}>
              <source
                src={selectedVideo.url ? selectedVideo.url : ''}
                type='video/mp4'/>
            </video>
          </>
        )}

        {type === 'document' && (
          <>
            <div className="tw-relative my-2">
              <div className="position-absolute top-0 end-0 m-2" style={{
                zIndex: 9999,
              }}>
                <div
                  onClick={deleteFile}
                  className={"ratio tw-cursor-pointer ratio-1x1 tw-w-[30px] bg-secondary rounded-circle shadow-sm"}>
                  <i
                    className="bi bi-x-lg text-white tw-font-medium d-flex justify-content-center align-items-center"></i>
                </div>
              </div>

              <DocumentPostView
                url={selectedFile.url}
              />
            </div>
          </>
        )}

        {!!props.referenceId && (
          <div className={"mt-4"}>
            <PostCardPreview id={props.referenceId}/>
          </div>
        )}

      </ModalBody>
      <ModalFooter className={"d-flex justify-content-start pt-0"}>
        {!props.referenceId && (
          <div className={"mb-3"}>
            <p className={"tw-text-md mb-1"}>Set Post Category</p>
            <div className="d-flex flex-wrap gap-2 mb-2">
              {subCategories.map(e => {
                return (
                  <div
                    key={e}
                    onClick={() => {
                      setSubCategory(prev => prev === e ? null : e)
                    }}
                    className={"tw-px-4 tw-cursor-pointer tw-py-0.5 tw-text-xs tw-font-medium tw-rounded-3xl pb-0.5 tw-border data-[selected=true]:!tw-border-[#ddceff] data-[selected=true]:!tw-bg-[#ddceff] hover:tw-border-[#ddceff] hover:tw-bg-[#efeeff]"}
                    data-selected={subCategory === e}
                  >
                    {e}
                  </div>
                )
              })}
            </div>
          </div>
        )}
        <div className="tw-flex tw-justify-between tw-items-center tw-w-full flex-wrap">
          <div>
            {type === 'text' && (
              <div className='d-flex align-items-center gap-4'>
                {[
                  {
                    tooltip: "Add Image",
                    onChangeType: "image",
                    icon: "bi-image-fill",
                    allow: "image/*"
                  },
                  {
                    tooltip: "Add Video",
                    onChangeType: "video",
                    icon: "bi-film",
                    allow: "video/*"
                  },
                  {
                    tooltip: "Add Document",
                    onChangeType: "document",
                    icon: "bi-paperclip",
                    allow: "application/pdf, text/plain, .doc, .docx, .pdf"
                  },
                ].map(e => {
                  const uuid = generateUUID()
                  return (
                    <div key={e.tooltip}>
                      <label
                        htmlFor={uuid}
                        data-tooltip-id={`file-${uuid}`}
                        data-tooltip-content={e.tooltip}
                        className={`bi ${e.icon} tw-text-xl hover:tw-text-opacity-100 tw-text-opacity-70 tw-transition-all tw-text-[#410db7]`}/>

                      <ReactTooltip
                        id={`file-${uuid}`}
                        place={"top"}
                        effect={"solid"}
                      />

                      <div className={"d-none"}>
                        <input
                          type='file'
                          id={uuid}
                          multiple
                          onChange={(ev) => handleMedia(ev.target.files, e.onChangeType)}
                          accept={e.allow}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          <div className={"d-flex tw-items-center justify-content-end gap-2 tw-w-full md:tw-w-fit"}>
            <div className={"d-flex tw-items-center"}>
              <Datetime
                initialValue={publishedAt}
                inputProps={{
                  className: "form-control form-control-sm tw-outline-none text-end border-0",
                  id: "__publishedAt"
                }}
                onChange={e => {
                  setPublishedAt(e.toDate())
                }}
              />
              <label htmlFor={"__publishedAt"} className="bi bi-clock"/>
            </div>

            <button
              className={`tw-bg-[#5458f7] disabled:tw-bg-opacity-60 disabled:tw-cursor-default tw-px-6 tw-text-white inter tw-font-medium tw-rounded-3xl tw-cursor-pointer tw-py-1`}
              onClick={handleSubmitForPost} disabled={!description}>Post
            </button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}