import { ADD, SAVE, ADD_TOUR_DATA } from './../types/types';

export const add = (data) => {
    return {
        type: ADD,
        payload: data
    }
}

export const savePoints = (data) => {
    return {
        type: SAVE,
        payload: data
    }
}

export const saveTourData = (data) => {
    return {
        type: ADD_TOUR_DATA,
        payload: data
    }
}