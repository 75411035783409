import React, {useEffect, useState} from 'react';
import {getUserInformation, updateUserPassword, updateUserprofile} from '../../../utils/apiUrlsSetup';
import ProfileModal from './editprofile-modal';
import './editProfile.css';
import "../../../component/staticPages/responsiveNavBar/responsiveNav.css";

import Edit from "../../../assets/svg/Edit.svg";
import {useDispatch} from 'react-redux';
import {add} from '../../../redux/actions/action';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {PostClipboard} from "../../../utils/plainClipboard";
import LoggedInLayout from "../../../component/layout/LoggedInLayout.tsx";

Quill.register("modules/clipboard", PostClipboard, true);


const EditProfile = () => {

    const userDetail = JSON.parse(localStorage.getItem('userDetails'));

    const [profilePic, setProfilePic] = useState()
    const [errorMsg, setErrorMsg] = useState()
    const [updatedinfo, setUpdateInfo] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [modalType, setModalType] = useState("");
    const [profileView, setProfileview] = useState(1)
    const [disablediv, setdisablediv] = useState(false)
    const [pwdChangeErrMsg, setPwdChangeErrMsg] = useState("");
    const [updateProfileDetails, setUpdateProfileDetails] = useState({})
    const [password, setPassword] = useState({
        confirmpassword: "",
        newpassword: "",
        prevpassword: ""
    })

    const dispatch = useDispatch();


    useEffect(() => {
        getUserProfileDetails();
    }, [])

    const getUserProfileDetails = async () => {
        let responseData = await getUserInformation();
        if (responseData.status === 200) {
            responseData.data.data.bioMobile = responseData.data.data.bio
            setUpdateProfileDetails(responseData.data.data)
            setProfilePic(responseData.data.data.profilePic)
        }
    }


    const onChangeHandlerProfileDetails = async (e, webBio) => {
        if (webBio) {
            delete updateProfileDetails.bioMobile;
        }
        let name = e.target.name
        let value = "";
        if (e.target.name === "profilePic") {
            let formData = new FormData();
            formData.append("profilePic", e.target.files[0]);
            let responseData = await updateUserprofile(formData)

            setProfilePic(responseData.data.data.profilePic)
            dispatch(add(responseData.data.data));
        } else {
            value = e.target.value
        }

        setUpdateProfileDetails({
            ...updateProfileDetails, [name]: value
        })

    }
    const onChangeHandlerForPassword = (e) => {
        let name = e.target.name
        let value = e.target.value
        setPassword({...password, [name]: value})

    }


    const handleChangePassword = () => {

        if (updateProfileDetails.newpassword !== updateProfileDetails.confirmpassword) {
            setPwdChangeErrMsg("New Password & Confirm New Password should be same.");

        } else {
            setIsOpen(true)
            setModalType("changepassword")
        }
    }

    const handlepassword = async () => {
        let objData = {}
        objData.previousPassword = password.prevpassword
        objData.newPassword = password.newpassword

        let responseData = await updateUserPassword(objData);

        if (responseData.status === 200) {
            setIsOpen(false)
            setUpdateInfo(true)
            setProfileview(1)
        } else {
            setErrorMsg(responseData.data.message)
            setIsOpen(false)
        }
    }

    const handleUpdateProfile = async () => {
        let formData = new FormData();
        formData.append("firstName", updateProfileDetails.firstName);
        formData.append("lastName", updateProfileDetails.lastName);
        formData.append("mobile", updateProfileDetails.mobile);
        formData.append("email", updateProfileDetails.email);

        if (updateProfileDetails.bioMobile) {
            formData.append("bio", updateProfileDetails.bioMobile);
        } else {
            formData.append("bio", updateProfileDetails.bio);
        }

        let responseData = await updateUserprofile(formData);

        if (responseData.status === 200) {
            setIsOpen(false)
            setProfileview(2)
            setUpdateInfo(true)
            setdisablediv(true)
            let responseData = await getUserInformation();
            responseData.data.data.bioMobile = responseData.data.data.bio
            setUpdateProfileDetails(responseData.data.data)
            dispatch(add(responseData.data.data));
        }
    }

    return (
        <LoggedInLayout title={"Edit Profile"}>
            {(profileView === 2 && updatedinfo) && (
                <div
                    className='approval_lable_in_green profile_approval_laval d-flex align-items-center'>
                    <p className='mb-0 approval_lable_text'>Your profile has been updated</p>
                </div>
            )}
            {(errorMsg) && (
                <div
                    className='approval_lable_in_green profile_approval_laval bg-danger d-flex align-items-center'>
                    <p className='mb-0 approval_lable_text'>{errorMsg}</p>
                </div>
            )}

            <div className='bg-white container py-3'>
                <div className='row gx-5 gy-2'>
                    <div className='col-xl-2 col-12 tw-border-b-2 md:tw-border-b-0 xl:tw-border-e-2 text-center'>
                        <div className="mx-auto tw-aspect-square tw-w-[100px] tw-relative">
                            <img src={profilePic ? profilePic : "/static/png/Male.png"} alt='' className={"rounded-circle"}/>

                            {profileView === 1 && (
                                <label
                                    className='tw-cursor-pointer tw-absolute end-0 bottom-0 pro_edit_circle d-flex align-items-center justify-content-center'>
                                    <input type='file' id="file-input"
                                           className={"d-none"}
                                           name='profilePic'
                                           onChange={onChangeHandlerProfileDetails}/>
                                    <img src={Edit} alt=''/>
                                </label>
                            )}
                        </div>

                        <div>
                            <div className="badge tw-bg-[#334d6e] px-4 my-2 fw-normal">
                                {userDetail.role === "instructor" ? "Well-being Supporter" : userDetail.role}
                            </div>
                        </div>

                    </div>
                    <div className='col-xl-5 col-md-6 col-12 tw-border-b-2 md:tw-border-b-0 tw-border-e-2'>
                        <p className='mb-0 profile_basic_heading'>Profile</p>

                        {
                            profileView === 2 && <div className={"d-flex flex-column gap-3"}>
                                <div>
                                    <p className='mb-0 profile_basic_labal'>First name</p>
                                    <p className='mb-0 profile_basic_info'>{updateProfileDetails.firstName}</p>
                                </div>
                                <div>
                                    <p className='mb-0 profile_basic_labal'>Last name</p>
                                    <p className='mb-0 profile_basic_info'>{updateProfileDetails.lastName}</p>
                                </div>
                                <div>
                                    <p className='mb-0 profile_basic_labal'>Email</p>
                                    <p className='mb-0 profile_basic_info'>{updateProfileDetails.email}</p>
                                </div>
                                <div>
                                    <p className='mb-0 profile_basic_labal'>Phone number</p>
                                    <p className='mb-0 profile_basic_info'>{updateProfileDetails.mobile}</p>
                                </div>

                                <div>
                                    <p className='mb-0 profile_basic_heading my-2 pb-2'>Bio</p>
                                    <p className='mb-0 user_commented'
                                       dangerouslySetInnerHTML={{__html: updateProfileDetails.bio}}></p>
                                </div>

                                <div className='row m-0 py-4 mt-3'>
                                    <div className='col-12 p-0 ps-1'>
                                        <button className='p-0 edit_profile_btn' onClick={() => {
                                            setProfileview(1)
                                            setdisablediv(false)
                                        }}>Edit Profile
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            profileView === 1 && <div className={"py-3 d-flex flex-column gap-3"}>
                                <div>
                                    <p className='mb-0 profile_basic_labal'>First name</p>
                                    <input className='profile_change_ped_ipt'
                                           name="firstName"
                                           value={updateProfileDetails.firstName}
                                           onChange={onChangeHandlerProfileDetails}/>
                                </div>

                                <div>
                                    <p className='mb-0 profile_basic_labal'>Last name</p>
                                    <input className='profile_change_ped_ipt'
                                           name='lastName'
                                           value={updateProfileDetails.lastName}
                                           onChange={onChangeHandlerProfileDetails}/>
                                </div>

                                <div>
                                    <p className='mb-0 profile_basic_labal'>Email</p>
                                    <input className='profile_change_ped_ipt'
                                           name='email'
                                           value={updateProfileDetails.email}
                                           onChange={onChangeHandlerProfileDetails} readOnly/>
                                </div>

                                <div>
                                    <p className='mb-0 profile_basic_labal'>Phone number</p>
                                    <input className='profile_change_ped_ipt'
                                           name='mobile'
                                           type="text"
                                           value={updateProfileDetails.mobile}
                                           onChange={(e) => {
                                               const regEx = /^[0-9\b]+$/;
                                               if (e.target.value === '' || regEx.test(e.target.value)) {
                                                   onChangeHandlerProfileDetails(e);
                                               }
                                           }}/>
                                </div>
                                <div className='bio_edit_section'>
                                    <p className='mb-0 profile_basic_heading my-2 pb-2'>Bio</p>

                                    <ReactQuill modules={{
                                        toolbar: false,
                                        clipboard: {
                                            // toggle to add extra line breaks when pasting HTML:
                                            matchVisual: false,
                                        }
                                    }} theme="snow" name='bio' value={updateProfileDetails.bio}

                                                onChange={(event) => {
                                                    onChangeHandlerProfileDetails({
                                                        target: {
                                                            name: 'bio',
                                                            value: event
                                                        }
                                                    }, true)
                                                }}

                                    />
                                </div>

                                <div/>
                                <button className='profile_savechange_btn' onClick={() => {
                                    setIsOpen(true)
                                    setModalType("saveChanges")
                                }}>
                                    Save changes
                                </button>
                            </div>
                        }

                    </div>
                    <div className='col-xl-5 col-md-6 col-12  d-flex flex-column gap-3'>
                        <p className='mb-0 profile_basic_heading'>Change password</p>

                        <input className='profile_change_ped_ipt'
                               type='password'
                               placeholder='Enter previous password'
                               name='prevpassword'
                               onChange={onChangeHandlerForPassword}
                               disabled={disablediv}
                        />
                        <input className='profile_change_ped_ipt'
                               type='password'
                               onChange={onChangeHandlerForPassword}
                               name="newpassword"
                               placeholder='New password'
                               disabled={disablediv}
                        />
                        <input className='profile_change_ped_ipt'
                               type='password'
                               name='confirmpassword'
                               onChange={onChangeHandlerForPassword}
                               placeholder='Confirm new password'
                               disabled={disablediv}
                        />
                        {
                            pwdChangeErrMsg ?
                                <span className='validation'>{pwdChangeErrMsg}</span> : null
                        }
                        <div/>
                        <button
                            className={`${disablediv ? 'profile_chnagepwd_btn_disable' : "profile_chnagepwd_btn"} d-block`}
                            onClick={handleChangePassword}>Change password
                        </button>
                    </div>
                </div>

                {isOpen && (
                    <ProfileModal
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        handlepassword={handlepassword}
                        handleUpdateProfile={handleUpdateProfile}
                        modalType={modalType}
                    />
                )}
            </div>
        </LoggedInLayout>
    )
}

export default EditProfile;