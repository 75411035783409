import React from 'react';
import { useNavigate } from "react-router-dom";

import '../community.css';

import Breathing from "../../../assets/Breathing.png";
import Moving from "../../../assets/Moving.png";
import EmotionEmoji from "../../../assets/EmotionEmoji.png";
import MeaningEmoji from "../../../assets/MeaningEmoji.png";
import Belonging from "../../../assets/Belonging.png";

const Feeling = () => {
  
    const navigate = useNavigate();

    return (
        <>
            <div className='row m-0 mt-4'>
                <div className='col-12 p-0'>
                    <p className=' mb-0 feeling_destination_heading resp_sub_heading'>Feeling Well</p>
                </div>
            </div>

            <div className='row m-0 mt-2'>
                <div className='col-12 p-0 pe-5'>
                    <p className='mb-0 feeling_destination_pera resp_sub_info'>Well-Being for the senses including relaxing, focusing, physical movement, managing emotions, and feeling a sense of social identity.</p>
                </div>
            </div>

            <div className='row m-0 mt-4'>
                <div className='col p-0'>

                    <div className='row m-0'>
                        <div className='col p-0'>
                            
                            <div class="accordion feeling_destination_accor" id="accordionExample1">
                                <div class="accordion-item feeling_destination_accor_item">
                                    
                                    <h2 class="accordion-header feeling_destination_accor_header" id="headingOne1">
                                        <button class="accordion-button feeling_destination_accor_btn ps-3 ps-md-4 ps-lg-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                            
                                            <div className='d-flex align-items-center justify-content-start'>
                                                <div className='wbd_accordian_img d-flex align-items-center justify-content-center' >
                                                    <img src={Breathing} alt='' />
                                                </div>
                                                <p className='mb-0 ms-4 feeling_destination_accor_name'>Breathing</p>
                                            </div>

                                            {/* <div className='row m-0'>
                                                <div className='col-2 p-0 bg-info'>
                                                    <img config={Breathing} alt='' />
                                                </div>
                                                <div className='col-10 p-0 bg-success'>
                                                    <p className='mb-0 feeling_destination_accor_name'>Breathing</p>
                                                </div>
                                            </div> */}

                                        </button>
                                    </h2>

                                    <div id="collapseOne1" class="accordion-collapse collapse" aria-labelledby="headingOne1" data-bs-parent="#accordionExample1">
                                        <div class="accordion-body feeling_destination_accor_body ps-4 pe-4 pt-3 pb-3">
                                            <div className='row m-0'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera'>Promoting relaxation, focus and better sensory awareness as you allow yourself to be more present.</p>
                                                </div>
                                            </div>
                                            {/* <div className='row m-0 mt-2'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera_2'><span className='feeling_destination_accor_pera_2_span'>Interests:</span> Beliefs and values, Diversity and inclusion, Religion, Cultural differences</p>
                                                </div>
                                            </div> */}
                                            <div className='row m-0 mt-3'>
                                                <div className='col-12 d-flex align-items-center justify-content-center'>
                                                    <button className='feeling_destination_accor_button'onClick={() => { navigate('/communitytab/feeling') }}>Find me experiences like these</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='row m-0 mt-1'>
                        <div className='col p-0'>
                            
                            <div class="accordion feeling_destination_accor" id="accordionExample2">
                                <div class="accordion-item feeling_destination_accor_item">
                                    
                                    <h2 class="accordion-header feeling_destination_accor_header" id="headingOne2">
                                        <button class="accordion-button feeling_destination_accor_btn ps-3 ps-md-4 ps-lg-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2" aria-expanded="true" aria-controls="collapseOne2">
                                            
                                            <div className='d-flex align-items-center justify-content-start'>
                                                <div className='wbd_accordian_img d-flex align-items-center justify-content-center' >
                                                    <img src={Moving} alt='' />
                                                </div>
                                                <p className='mb-0 ms-4 feeling_destination_accor_name'>Moving</p>
                                            </div>

                                            {/* <div className='row m-0'>
                                                <div className='col-2 p-0'>
                                                    <img config={Moving} alt='' />
                                                </div>
                                                <div className='col-10 p-0'>
                                                    <p className='mb-0 feeling_destination_accor_name'>Moving</p>
                                                </div>
                                            </div> */}

                                        </button>
                                    </h2>

                                    <div id="collapseOne2" class="accordion-collapse collapse" aria-labelledby="headingOne2" data-bs-parent="#accordionExample2">
                                        <div class="accordion-body feeling_destination_accor_body ps-4 pe-4 pt-3 pb-3">
                                            <div className='row m-0'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera'>Understanding the importance of physical movement and how to incorporate small steps into your busy life.</p>
                                                </div>
                                            </div>
                                            {/* <div className='row m-0 mt-2'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera_2'><span className='feeling_destination_accor_pera_2_span'>Interests:</span> Beliefs and values, Diversity and inclusion, Religion, Cultural differences</p>
                                                </div>
                                            </div> */}
                                            <div className='row m-0 mt-3'>
                                                <div className='col-12 d-flex align-items-center justify-content-center'>
                                                    <button className='feeling_destination_accor_button'onClick={() => { navigate('/communitytab/feeling') }}>Find me experiences like these</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='row m-0 mt-1'>
                        <div className='col p-0'>
                            
                            <div class="accordion feeling_destination_accor" id="accordionExample3">
                                <div class="accordion-item feeling_destination_accor_item">
                                    
                                    <h2 class="accordion-header feeling_destination_accor_header" id="headingOne3">
                                        <button class="accordion-button feeling_destination_accor_btn ps-3 ps-md-4 ps-lg-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne3" aria-expanded="true" aria-controls="collapseOne3">
                                            
                                            <div className='d-flex align-items-center justify-content-start'>
                                                <div className='wbd_accordian_img d-flex align-items-center justify-content-center' >
                                                    <img src={EmotionEmoji} alt='' />
                                                </div>
                                                <p className='mb-0 ms-4 feeling_destination_accor_name'>Acknowledging</p>
                                            </div>

                                            {/* <div className='row m-0'>
                                                <div className='col-2 p-0'>
                                                    <img config={EmotionEmoji} alt='' />
                                                </div>
                                                <div className='col-10 p-0'>
                                                    <p className='mb-0 feeling_destination_accor_name'>Emotions</p>
                                                </div>
                                            </div> */}

                                        </button>
                                    </h2>
                                    
                                    <div id="collapseOne3" class="accordion-collapse collapse" aria-labelledby="headingOne3" data-bs-parent="#accordionExample3">
                                        <div class="accordion-body feeling_destination_accor_body ps-4 pe-4 pt-3 pb-3">
                                            <div className='row m-0'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera'>Increasing emotional intelligence and honing abilities to identify, manage and acknowledge emotions.</p>
                                                </div>
                                            </div>
                                            {/* <div className='row m-0 mt-2'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera_2'><span className='feeling_destination_accor_pera_2_span'>Interests:</span> Beliefs and values, Diversity and inclusion, Religion, Cultural differences</p>
                                                </div>
                                            </div> */}
                                            <div className='row m-0 mt-3'>
                                                <div className='col-12 d-flex align-items-center justify-content-center'>
                                                    <button className='feeling_destination_accor_button'onClick={() => { navigate('/communitytab/feeling') }}>Find me experiences like these</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='row m-0 mt-1'>
                        <div className='col p-0'>
                            
                            <div class="accordion feeling_destination_accor" id="accordionExample4">
                                <div class="accordion-item feeling_destination_accor_item">
                                   
                                    <h2 class="accordion-header feeling_destination_accor_header" id="headingOne4">
                                        <button class="accordion-button feeling_destination_accor_btn ps-3 ps-md-4 ps-lg-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne4" aria-expanded="true" aria-controls="collapseOne4">
                                            
                                            <div className='d-flex align-items-center justify-content-start'>
                                                <div className='wbd_accordian_img d-flex align-items-center justify-content-center' >
                                                    <img src={Belonging} alt='' />
                                                </div>
                                                <p className='mb-0 ms-4 feeling_destination_accor_name'>Belonging</p>
                                            </div>

                                            {/* <div className='row m-0'>
                                                <div className='col-2 p-0'>
                                                    <img config={MeaningEmoji} alt='' />
                                                </div>
                                                <div className='col-10 p-0'>
                                                    <p className='mb-0 feeling_destination_accor_name'>Meaning</p>
                                                </div>
                                            </div> */}

                                        </button>
                                    </h2>

                                    <div id="collapseOne4" class="accordion-collapse collapse show" aria-labelledby="headingOne4" data-bs-parent="#accordionExample4">
                                        <div class="accordion-body feeling_destination_accor_body ps-4 pe-4 pt-3 pb-3">
                                            <div className='row m-0'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera'>Cultivating your sense of social identity and connectedness within your workplace, community or a group.</p>
                                                </div>
                                            </div>
                                            {/* <div className='row m-0 mt-2'>
                                                <div className='col-12'>
                                                    <p className='mb-0 feeling_destination_accor_pera_2'><span className='feeling_destination_accor_pera_2_span'>Interests:</span> Beliefs and values, Diversity and inclusion, Religion, Cultural differences</p>
                                                </div>
                                            </div> */}
                                            <div className='row m-0 mt-3'>
                                                <div className='col-12 d-flex align-items-center justify-content-center'>
                                                    <button className='feeling_destination_accor_button'onClick={() => { navigate('/communitytab/feeling') }}>Find me experiences like these</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Feeling;