import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../app/Home.css';
import KscopeLogo from "../../assets/wcn_logo.svg";

const Header = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <nav className="py-3">
            <div className="row align-items-center">
                {/* Logo Section */}
                <div className="col-6 col-md-3 mb-3 mb-md-0">
                    <Link to="/" className="d-block text-center text-md-start">
                        <img 
                            src={KscopeLogo} 
                            alt="logo" 
                            className="img-fluid" // Ensures the logo scales responsively
                            style={{ maxHeight: "50px" }} 
                        />
                    </Link>
                </div>
                {/* Navigation Links */}
                <div className="col-6 col-md-9 pe-md-3">
                    {/* Dropdown for small screens */}
                    <div className="d-md-none text-end">
                        <button 
                            className="btn btn-outline-secondary align-items-center" 
                            onClick={toggleDropdown} 
                            aria-expanded={isDropdownOpen}
                        >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-list"
                            viewBox="0 0 16 16"
                        >
                            <path 
                                fillRule="evenodd" 
                                d="M2.5 12.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zm0-5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zm0-5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11z"
                            />
                        </svg>
                        </button>
                        {isDropdownOpen && (
                            <div className="dropdown-menu show position-absolute start-0 end-0 mt-2">
                                <a 
                                    href="https://wellbeingchampions.sg/for-organisations/" 
                                    className="dropdown-item" 
                                    target="_blank" 
                                    rel="noreferrer"
                                >
                                    For Organisations
                                </a>
                                <a 
                                    href="https://wellbeingchampions.sg/for-champions/" 
                                    className="dropdown-item" 
                                    target="_blank" 
                                    rel="noreferrer"
                                >
                                    For Champions
                                </a>
                                <a 
                                    href="https://wellbeingchampions.sg/events/" 
                                    className="dropdown-item" 
                                    target="_blank" 
                                    rel="noreferrer"
                                >
                                    Events &amp; Press
                                </a>
                                <a 
                                    href="https://wellbeingchampions.sg/resources-2/" 
                                    className="dropdown-item" 
                                    target="_blank" 
                                    rel="noreferrer"
                                >
                                    Resources
                                </a>
                                <a 
                                    href="https://wellbeingchampions.sg/contact-us/" 
                                    className="dropdown-item" 
                                    target="_blank" 
                                    rel="noreferrer"
                                >
                                    Contact Us
                                </a>
                            </div>
                        )}
                    </div>

                    {/* Links for medium to large screens */}
                    <div 
                        className="d-none d-md-flex flex-row justify-content-between align-items-start fw-semibold montserrat"
                    >
                        <a 
                            href="https://wellbeingchampions.sg/for-organisations/" 
                            target="_blank" 
                            className="text-decoration-none mb-2 mb-md-0 text-start" 
                            rel="noreferrer"
                        >
                            For Organisations
                        </a>
                        <a 
                            href="https://wellbeingchampions.sg/for-champions/" 
                            target="_blank" 
                            className="text-decoration-none mb-2 mb-md-0 text-start" 
                            rel="noreferrer"
                        >
                            For Champions
                        </a>
                        <a 
                            href="https://wellbeingchampions.sg/events/" 
                            target="_blank" 
                            className="text-decoration-none mb-2 mb-md-0 text-start" 
                            rel="noreferrer"
                        >
                            Events &amp; Press
                        </a>
                        <a 
                            href="https://wellbeingchampions.sg/resources-2/" 
                            target="_blank" 
                            className="text-decoration-none mb-2 mb-md-0 text-start" 
                            rel="noreferrer"
                        >
                            Resources
                        </a>
                        <a 
                            href="https://wellbeingchampions.sg/contact-us/" 
                            target="_blank" 
                            className="text-decoration-none text-start" 
                            rel="noreferrer"
                        >
                            Contact Us
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header;
