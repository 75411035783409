import React from 'react';

import Mask from "../assets/svg/Emotions.svg";
import Madal from "../assets/svg/Madal.svg";
import Heart from "../assets/svg/BigHeart.svg";

import {Link} from "react-router-dom";
import Header from "./staticPages/header";
import Footer from "./staticPages/footer";

/**
 *
 * @param {{
 *     title: string,
 *     description: string,
 *     items: {
 *         title: string,
 *         img: string | {},
 *         text: string[]
 *     }[]
 * }} props
 * @returns {Element}
 * @constructor
 */
const FFCPageTemplate = (props) => {
    const cardsSliderData = [
        {link: 'feelings', slide: 1, src: Mask, heading: 'Feeling Well™'},
        {link: 'functioning', slide: 2, src: Madal, heading: 'Functioning Well™'},
        {link: 'connecting', slide: 3, src: Heart, heading: 'Connecting Well™'}
    ]

    return (
        <>
            <main className={"bg-logged-in min-vh-100"}>
                <div className="container-xl">
                    <Header/>
                </div>

                <div className="container-xl py-5 d-flex flex-column gap-3">
                    <p className='montserrat tw-text-4xl tw-text-[#18214D] fw-bold'>
                        Well-being Champions Network Experience
                    </p>
                    <p className='montserrat tw-text-2xl tw-text-[#18214D] tw-font-[500]'>
                        Three Experience Centres for Total Well-being.
                    </p>

                    <div>
                        <div className='row mt-4 g-3 align-items-center justify-content-center'>
                            {cardsSliderData.map(section => {
                                return (
                                    <div className='col-sm-6 col-md-4 col-12' key={section.link}>
                                        <Link
                                            to={`/${section.link}`}
                                            className='!tw-border-b-8 hover:!tw-border-[#FCC3C6] tw-border-white card card-body border-top-0 border-start-0 border-end-0 shadow-sm rounded-4 d-flex flex-column justify-content-center align-items-center'>
                                            <img className='tw-max-w-[80px]' src={section.src} alt=""/>
                                            <p className='inter fw-bold tw-text-2xl mt-3'>{section.heading}</p>
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <section
                    className={"tw-rounded-tr-3xl tw-rounded-tl-3xl lg:tw-rounded-tr-[90px] lg:tw-rounded-tl-[90px] shadow-sm tw-bg-[#F7F4FF]"}>
                    <div className="container-lg py-5">
                        <div className="d-flex gap-5 flex-column">
                            <div>
                                <p className='tw-text-[#18214D] montserrat fw-bold tw-text-5xl text-center mb-2'>
                                    {props.title}<sup className='fw-bold'>™</sup>
                                </p>
                                <p className='tw-font-[500] montserrat text-center'>
                                    {props.description}
                                </p>
                            </div>

                            {props.items.map((item, i) => {
                                return (
                                    <div
                                        className={`d-flex justify-content-center align-items-center gap-lg-5 gap-2 text-center text-lg-start ${i % 2 === 0 ? "flex-lg-row" : "flex-lg-row-reverse"} flex-column-reverse`}
                                        key={i}>
                                        <div className={"lg:tw-max-w-[max(40vw,500px)]"}>
                                            <p className='inter fw-bold tw-text-[#E138BC] tw-text-3xl mb-2'>{item.title}</p>
                                            <p className='inter tw-font-[500] tw-text-[#18214D] tw-text-lg lh-base lg:tw-text-justify'>
                                                {item.text.map((text, i) => {
                                                    if (i % 2 === 0) {
                                                        return <>{text}</>
                                                    }
                                                    return <span className={"tw-text-[#410DB7]"}> {text} </span>
                                                })}
                                            </p>
                                        </div>
                                        <img
                                            className='tw-max-w-[200px] tw-aspect-square tw-object-center tw-object-cover flex-shrink-0 rounded-4'
                                            src={item.img} alt=''/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </section>
            </main>

            <Footer/>
        </>
    )
}

export default FFCPageTemplate;