import React from 'react';

import FeelBreathing from "../../assets/FeelBreething.png";
import FeelMoving from "../../assets/FeelMoving.png";
import FeelEmotions from "../../assets/FeelEmotions.png";
import ConnectBelong from "../../assets/ConnectBelonging.png";
import FFCPageTemplate from "../../component/FFCPageTemplate";

export default function Feeling() {
    return <FFCPageTemplate
        title={"Feeling Well"}
        description={"Well-being for the senses, including physical and emotional well-being."}
        items={[
            {
                title: "Breathing",
                img: FeelBreathing,
                text: [
                    "Promoting relaxation, focus",
                    "and better sensory awareness",
                    "as",
                    "you allow yourself",
                    "to be more present."
                ]
            },
            {
                title: "Moving",
                img: FeelMoving,
                text: [
                    "Understanding the importance of",
                    "physical movement",
                    "and",
                    "how to incorporate",
                    "small steps into your busy life."
                ]
            },
            {
                title: "Acknowledging",
                img: FeelEmotions,
                text: [
                    "Increasing",
                    "emotional intelligence",
                    "and",
                    "honing abilities to identify,",
                    "manage and acknowledge emotions."
                ]
            },
            {
                title: "Belonging",
                img: ConnectBelong,
                text: [
                    "Cultivating your",
                    "sense of social identity and connectedness",
                    "within your workplace, community, or a group.",
                ]
            }
        ]}
    />
}