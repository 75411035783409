import React, {useState} from 'react';

import create_event_rect from '../../../assets/Image.png';
import CloseBtn from '../../../assets/Crossbtn.png';
import {createEventData} from '../../../utils/apiUrlsSetup';

const categoriesList = [
  "Breathing",
  "Moving",
  "Acknowledging",
  "Belonging",
  "Working",
  "Learning",
  "Noticing",
  "Meaning",
  "Giving",
  "Relating",
  "Caring",
  "Communicating"
];

function CreateEventModal(props) {

  const [eventName, setEventName] = useState("");
  const [eventType, setEventType] = useState("online");
  const [eventTimeZone, setEventTimeZone] = useState("+08:00 GMT");
  const [oneDayEvent, setOneDayEvent] = useState(false);
  const [eventLocation, setEventLocation] = useState(false);
  const [startDate, saveStartDate] = useState("");
  const [endDate, saveEndDate] = useState("");
  const [startTime, saveStartTime] = useState("");
  const [endTime, saveEndTime] = useState("")
  const [description, saveDesciption] = useState("");
  const [address, setAddress] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageObj, setImageObj] = useState("");
  const [addressErrorMsg, setAddressError] = useState("")
  const [meetingLinkErrorMsg, setMeetingLinkError] = useState("")
  const [category, setCategory] = useState([]);

  const CreateEvent = async () => {
    let formData = new FormData();
    let addr = ""
    formData.append("name", eventName);
    formData.append("type", eventType);
    formData.append("timezone", eventTimeZone);
    formData.append("description", description);
    formData.append("isOnlineEvent", eventLocation);
    if (eventType === 'online') {
      if (!meetingLink) {
        setMeetingLinkError("Meeting link is required")
        return
      }

      addr = null
    } else {
      if (!address) {
        setAddressError('Address is required')
        return
      }

      if (eventType === 'hybrid' && !meetingLink) {
        setMeetingLinkError("Meeting link is required")
        return
      }

      addr = address
    }
    formData.append("address", addr);
    let newMeetingLink = "";
    if (meetingLink.includes("http://") || meetingLink.includes("https://") || meetingLink.includes("www")) {
      newMeetingLink = meetingLink;
    } else {
      newMeetingLink = `https://${meetingLink}`
    }
    formData.append("link", newMeetingLink);
    if (category.length === 1) {
      formData.append("category", "null");
      formData.append("category", category[0]);
    } else {
      category.forEach((v) => {
        formData.append("category", v);
      })
    }

    let finalStartDateTime = "";
    let finalEndDateTime = "";

    if (oneDayEvent && startDate) {
      finalStartDateTime = `${startDate}T${startTime}:00.000Z`;
      finalEndDateTime = `${startDate}T${endTime}:00.000Z`;
    } else if (startDate && endDate) {
      finalStartDateTime = `${startDate}T${startTime}:00.000Z`;
      finalEndDateTime = `${endDate}T${endTime}:00.000Z`;
    }

    formData.append("startDate", finalStartDateTime);
    formData.append("endDate", finalEndDateTime);


    if (imageObj) {
      formData.append("image", imageObj);
    }

    const data = await createEventData(formData);

    if (data.status === 200) {
      props.setEventData && props.setEventData(data.data.data);
      setEventName("");
      setImageObj("");
      setImageUrl("");
      setMeetingLink("");
      setAddress("");
      saveDesciption("");
      saveEndTime("");
      saveStartTime("");
      saveEndDate("");
      saveStartDate("");
      setEventLocation(false);
      setOneDayEvent(false);
      props.navigate && props.navigate(`/event/${data.data.data.id}`)
      // props.handleModal(false);

    }
  }

  const newDate = new Date().toISOString().split('T')[0];


  return <>
    <div class={`modal fade ${props.isOpenModal ? 'show' : ''}`}
         style={{display: props.isOpenModal ? 'block' : 'none', background: props.isOpenModal ? 'rgba(0,0,0,0.5)' : ''}}
         id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg  news_feed_modal_dialog_2 modal_width_for_tab_view">
        <div class="modal-content news_feed_modal_content_2">
          <div className='modal_header p-4'>
            <div className='row m-0'>
              <div className='col-10 p-0'>
                <p className='mb-0 news_feed_modal_heading'>Create event</p>
              </div>

              <div className='col-2 p-0 d-flex justify-content-end align-items-center'>
                <img className='news_feed_modal_closebtn' src={CloseBtn} alt=''
                     onClick={() => props.handleModal(false)}/>
              </div>
            </div>
          </div>

          <div className='modal_body'>

            <div className='row m-0'>
              <div className='col-12 p-0'>

                <div className='row m-0'>
                  <div className='col-12 p-0'>
                    <div
                      className='create_event_rect_img d-flex justify-content-center align-items-center p-5 cursor_pointer'>
                      <input type='file' className="d-none" id="file-input" multiple onChange={(e) => {
                        setImageUrl(URL.createObjectURL(e.target.files[0]));
                        setImageObj(e.target.files[0]);
                      }}/>
                      <label for="file-input" className='cursor_pointer'>
                        <img src={imageUrl ? imageUrl : create_event_rect} alt='' width={imageUrl ? "180px" : "100%"}/>
                      </label>
                    </div>

                  </div>
                </div>

                <div className='row m-0 mt-2'>

                  <div className='col-12 p-3'>

                    <div className="row m-0">
                      <div className="col-12 p-0">
                        <p className="mb-0 create_event_rect_labal">Event Name</p>
                        <input className=" mt-3 create_event_rect_input" onChange={(e) => setEventName(e.target.value)}
                               value={eventName}/>
                      </div>
                    </div>

                    <div className="row m-0 mt-3">

                      <div className="col-6 p-0 pe-4">

                        <div className='row m-0'>
                          <div className='col-12 p-0'>
                            <p className="mb-0 create_event_rect_labal">Event type:</p>
                          </div>
                        </div>

                        <div className='row m-0 mt-3'>
                          <div className='col-12 p-0'>
                            <select onChange={(e) => setEventType(e.target.value)} value={eventType}
                                    class="form-select create_event_rect_selection" aria-label="Default select example">
                              <option value="online">Online Event</option>
                              <option value="physical">Physical Event</option>
                              <option value="hybrid">Hybrid Event</option>
                            </select>
                          </div>
                        </div>

                      </div>

                      <div className="col-6 p-0 ps-4">

                        <div className='row m-0'>
                          <div className='col-12 p-0'>
                            <p className="mb-0 create_event_rect_labal">Timezone:</p>
                          </div>
                        </div>

                        <div className='row m-0 mt-3'>
                          <div className='col-12 p-0'>
                            <select onChange={(e) => setEventTimeZone(e.target.value)} value={eventTimeZone}
                                    class="form-select create_event_rect_selection" aria-label="Default select example">
                              <option value="GMT +8:00H (Singapore)">GMT +8:00H (Singapore)</option>
                            </select>
                          </div>
                        </div>

                      </div>

                    </div>

                    <div className="row m-0 mt-2">

                      <div className="col-6 p-0 pe-4">

                        <div className='row m-0'>

                          <div className='col-6 p-0 pe-3'>

                            <div className='row m-0 pt-2 pb-2'>

                              <div className='col-4 p-0 d-flex align-items-center crt_evt_mdl_col_hgt'>
                                <p className='mb-0 create_event_rect_date'>Date:</p>
                              </div>

                              <div
                                className='col-8 p-0 d-flex align-items-center justify-content-end crt_evt_mdl_col_hgt'>

                                <div class="form-check ms-3 d-flex align-items-center">
                                  <input class="form-check-input create_event_rect_checkInput me-1 mb-0" type="checkbox"
                                         value="" id="flexCheckChecked"
                                         onChange={(e) => setOneDayEvent(e.target.checked)} checked={oneDayEvent}/>
                                  <label class="form-check-label create_event_rect_checkInput_labal mb-0"
                                         for="flexCheckChecked" onClick={() => setOneDayEvent(true)}>
                                    1 day Event
                                  </label>
                                </div>

                              </div>
                            </div>

                            <div className='row m-0'>
                              <div className='col-12 p-0'>
                                <input type="date" className='create_event_small_input' min={newDate}
                                       onChange={(e) => saveStartDate(e.target.value)} value={startDate}/>
                              </div>
                            </div>

                          </div>

                          <div className='col-6 p-0 ps-3'>

                            <div className='row m-0 pt-2 pb-2'>
                              <div className='col-12 p-0 d-flex align-items-center crt_evt_mdl_col_hgt'>
                                <p
                                  className={`mb-0 create_event_rect_date ${oneDayEvent && "create_event_rect_labal_disable"}`}>End
                                  Date:</p>
                              </div>
                            </div>

                            <div className='row m-0'>
                              <div className='col-12 p-0'>
                                <input type="date" className='create_event_small_input' min={startDate}
                                       disabled={oneDayEvent} onChange={(e) => saveEndDate(e.target.value)}
                                       value={endDate}/>
                              </div>
                            </div>

                          </div>

                        </div>

                      </div>

                      <div className="col-6 p-0 ps-4">

                        <div className='row m-0'>

                          <div className='col-6 p-0 pe-3'>

                            <div className='row m-0 pt-2 pb-2'>

                              <div className='col-8 p-0 d-flex align-items-center crt_evt_mdl_col_hgt'>
                                <p className='mb-0 create_event_rect_date'>Start time:</p>
                              </div>

                              <div
                                className='col-4 p-0 d-flex align-items-center justify-content-end crt_evt_mdl_col_hgt'>

                              </div>
                            </div>

                            <div className='row m-0'>
                              <div className='col-12 p-0'>
                                <input type="time" className='create_event_small_input'
                                       onChange={(e) => saveStartTime(e.target.value)} value={startTime}/>
                              </div>
                            </div>

                          </div>

                          <div className='col-6 p-0 ps-3'>

                            <div className='row m-0 pt-2 pb-2'>
                              <div className='col-12 p-0 d-flex align-items-center crt_evt_mdl_col_hgt'>
                                <p className='mb-0 create_event_rect_date'>End time:</p>
                              </div>
                            </div>

                            <div className='row m-0'>
                              <div className='col-12 p-0'>
                                <input type="time" className='create_event_small_input'
                                       onChange={(e) => saveEndTime(e.target.value)} value={endTime}/>
                              </div>
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>

                    <div className='row m-0 mt-3'>
                      <div className='col-12 p-0'>

                        <div className='row m-0'>
                          <div className='col-12 p-0'>
                            <p className="mb-0 create_event_rect_labal">Description</p>
                            <textarea className="mt-2 p-2 create_event_rect_biginput" rows="4" value={description}
                                      onChange={(e) => saveDesciption(e.target.value)}/>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className='row m-0 mt-4'>
                      {/* <div className='col-3 p-0'>

                                                <div className='row m-0'>
                                                    <div className='col-12 p-0'>
                                                        <p className="mb-0 create_event_rect_labal">Event Type</p>
                                                    </div>
                                                </div>

                                                <div className='row m-0'>
                                                    <div className='col-12 p-0 d-flex align-items-center'>
                                                        <div class="form-check d-flex align-items-center">
                                                            <input class="form-check-input create_event_rect_checkInput me-1 mb-0" type="checkbox" value="" id="flexCheckChecked1" onChange={(e) => setEventLocation(e.target.checked)} checked={eventLocation} />
                                                            <label class="form-check-label create_event_rect_checkInput_labal mb-0" for="flexCheckChecked1">
                                                                Online event
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div> */}
                      <div className='col-12 p-0'>

                        <div className='row m-0'>
                          <div className='col-12 p-0'>
                            <p className="mb-0 create_event_rect_labal">Category:</p>
                          </div>
                        </div>

                        <div className='row m-0'>
                          <div className='col-12 p-0 d-flex justify-content-between' style={{flexWrap: 'wrap'}}>

                            {categoriesList.map((v, i) => {
                              return <div class="form-check form-check-category d-flex align-items-center" key={i}>
                                <input class="form-check-input create_event_rect_checkInput me-1 mb-0" type="checkbox"
                                       value="" onChange={(e) => {
                                  if (e.target.checked) {
                                    setCategory([...category, v]);
                                  } else {
                                    let findCategory = category.filter(val => val !== v);
                                    setCategory(findCategory);
                                  }
                                }} id="flexCheckChecked2" checked={category.includes(v)}/>
                                <label class="form-check-label create_event_rect_checkInput_labal mb-0"
                                       for="flexCheckChecked2">
                                  {v}
                                </label>
                              </div>
                            })}
                          </div>
                        </div>

                      </div>
                    </div>

                    {
                      eventType !== 'online' ? (
                        <div className='row m-0 mt-3'>
                          <div className='col-12 p-0'>
                            <p className="mb-0 create_event_rect_labal">Physical Event Address</p>
                            <input className="mt-2 create_event_rect_input" value={address}
                                   onChange={(e) => setAddress(e.target.value)}/>
                          </div>
                          {addressErrorMsg ? (
                            <span className="validation mt-2">{addressErrorMsg}</span>
                          ) : null}
                        </div>
                      ) : null
                    }


                    {
                      eventType !== 'physical' ? (
                        <div className='row m-0 mt-3'>
                          <div className='col-12 p-0'>
                            <p className="mb-0 create_event_rect_labal">Online Event Link:</p>
                            <input className="mt-2 create_event_rect_input" value={meetingLink}
                                   onChange={(e) => setMeetingLink(e.target.value)}/>
                          </div>
                          {meetingLinkErrorMsg ? (
                            <span className="validation mt-2">{meetingLinkErrorMsg}</span>
                          ) : null}
                        </div>
                      ) : null
                    }

                    <div className='row m-0 mt-3'>
                      <div className='col-6 p-0'>

                      </div>
                      <div className='col-6 p-0 d-flex align-items-center justify-content-end'>
                        <button className='me-3 create_event_rect_btn_first'
                                onClick={() => props.handleModal(false)}>Cancel
                        </button>
                        <button className='create_event_rect_btn_sec' onClick={() => CreateEvent()}>Create Event
                        </button>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </>;
}

export default CreateEventModal;
