import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import dwnldArrow from "../../../assets/dwnld_arrow.png"
import view_icon from "../../../assets/view_icon.png"
import Like from '../../../assets/svg/Friends/Like.svg';
import More from "../../../assets/svg/More.svg";
import Photo_Video from "../../../assets/svg/PhotoVideo.svg";
import OutlinedLike from '../../../assets/svg/Friends/OutlinedLike.svg';
import OutlinedComment from '../../../assets/svg/Friends/OutlinedComment.svg';
import Logo from '../../../assets/Logo.png'
import {
    changeNotificationStatus,
    createPostComments,
    createPostLike,
    deletePostLike,
    getNotifications,
    getPostComments,
    getPostLike,
    getPosts,
    getUserInformation,
    respondToFriendRequest,
    updatePost,
    updateUserprofile
} from '../../../utils/apiUrlsSetup';
import moment from 'moment';

import FriendDashboardCover from '../../../assets/FriendDashboardCover.png';
import LikeProfileModal from './../../../component/modals/likeProfileModal/likeProfileModal';
import Linkify from 'react-linkify';
import LoggedInLayout from "../../../component/layout/LoggedInLayout.tsx";
import ImagePostCarousel from "../../newsFeed/component/post/ImagePostCarousel";
import DocumentPostView from "../../newsFeed/component/post/DocumentPostView";
import Dicline from "../../../assets/svg/DiclineButton.svg";
import {HOME} from "../../../config/ROUTES";
import {greeting} from "../../../utils/constant";

const getStatusName = (value) => {
    if (value === "friend-request") {
        return {
            name: "Friend Request",
            color: "yellow_btn",
        };
    } else if (value === "time-exchange") {
        return {
            name: "Time Exchange",
            color: "dark_blue_btn",
        };
    } else if (value === "well-being") {
        return {
            name: "Well-Being",
            color: "red_btn",
        };
    } else if (value === "journey") {
        return {
            name: "Journey",
            color: "green_btn",
        };
    } else if (value === "expert" || value === "Supporter") {
        return {
            name: "Supporter",
            color: "dark_pink_btn",
        };
    } else if (value === "organisation") {
        return {
            name: "Community",
            color: "sky_blue_btn",
        };
    } else if (value === "community") {
        return {
            name: "Community",
            color: "sky_blue_btn",
        };
    } else if (value === "my-kaliedoscope") {
        return {
            name: "myKaleidoscope",
            color: "green_btn",
        };
    } else if (value === "event") {
        return {
            name: "Experiences",
            color: "sky_blue_btn",
        };
    } else if (value === "experience") {
        return {
            name: "Experiences",
            color: "dark_blue_btn",
        };
    } else if (value === "giving") {
        return {
            name: "Giving",
            color: "green_btn",
        };
    } else if (value === "admin") {
        return {
            name: "Admin",
            color: "dark_blue_btn",
        };
    } else {
        return {
            name: "",
            color: "",
        };
    }

}

const getSubTitle = (value, obj, accept, reject, type) => {
    if (value === "Friend request received") {
        return ""
        // return <p className='mb-0 notification_responde'>Respond to {obj.firstName}: &nbsp;&nbsp; <span className='req_accept cursor_pointer' onClick={() => accept(obj.userId)}>Accept &nbsp; </span><span className='req_reject cursor_pointer' onClick={() => reject(obj.userId)}>| &nbsp; Decline</span></p>
    } else if (type === "time-exchange") {
        return "Use your free hours to book a session with Vanessa for free."
    } else if (type === "expert" || type === "journey") {
        return "You get an extra 4H free to spend on coaching sessions."
    } else if (type === "well-being") {
        return "Invite more friends and get 2H per friend invited, up to 20H for free!"
    }
}

const Profile = () => {
    const navigate = useNavigate();
    const [userprofileData, setUserprofileData] = useState({});
    const [postData, setPostData] = useState([]);
    const [comment, setComment] = useState("");
    const [index, setIndex] = useState("")
    const [commentsArr, setCommentsArr] = useState([])
    const [subComment, setSubComment] = useState('')
    const [showRepliedCommentInput, setShowRepliedCommentInput] = useState(false);
    const [showRepliedCommentId, setShowRepliedCommentId] = useState("");
    const [postId, setPostId] = useState()

    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const [notifications, setNotifications] = useState([])

    const [showUserLikedList, setShowUserLikedList] = useState(false);

    const [listOfLikeUsers, setListOfLikedUsers] = useState([]);

    useEffect(() => {
        getUserInformationbyuser();
        getPostList();

        getAllNotifications()
    }, []);

    const getAllNotifications = async () => {
        let resData = await getNotifications(1000);
        if (resData.status === 200) {
            setNotifications(resData.data.data.notificationArray.filter(e => e.status === 'sent'));
        }
    }

    const changeStatusOfNotifications = async () => {
        let obj = {
            status: 'read'
        }
        let response = await changeNotificationStatus(obj);
        if (response.status === 200) {
            getAllNotifications();
        }
    }

    const getUserInformationbyuser = async () => {
        let responseDatauserinfo = await getUserInformation();
        if (responseDatauserinfo.status === 200) {
            setUserprofileData(responseDatauserinfo.data.data)
        }
    }

    const deletePost = async (id) => {
        let obj = {
            isDeleted: true
        }
        let response = await updatePost(id, obj);
        if (response.status === 200) {
            getPostList();
        }
    }

    const getPostList = async () => {
        const responseData = await getPosts();
        if (responseData.status === 200) {
            let data = responseData.data.data.postArray;
            setPostData(data);
        }
    }

    const getFileType = (url) => {
        if (url) {
            let splitUrl = url.split(".");
            let findType = splitUrl[splitUrl.length - 1];
            return findType.toUpperCase();
        }
    }

    const getFileName = (url) => {
        if (url) {
            let splitUrl = url.split("/");
            return splitUrl[splitUrl.length - 1];
        }
    }

    const submitComment = async (type, data, value, cmmtId) => {
        const formData = new FormData();
        if (type === "text") {
            formData.append("postId", data.id);
            formData.append("comment", value);
        } else {
            formData.append("postId", data.id);
            formData.append("attachment", value);
            formData.append("comment", comment);
        }
        if (cmmtId) {
            formData.append("commentId", cmmtId);
        }
        const responseData = await createPostComments(formData);
        if (responseData.status === 200) {
            setComment("");
            setSubComment("");
            const responseData = await getPostComments(data.id);
            if (responseData.status === 200) {
                // setShowComment(true)
                setCommentsArr(responseData.data.data)

            }
        }
        getPostList()
    }

    // const handleLike = async (post) => {
    //     const response = await createPostLike({ postId: v.id });
    //     if (response.status === 200) {
    //         getPostList();
    //     }
    // }

    const handleLike = async (id, commentId, post) => {

        const responseData = commentId ? await getPostLike(`commentId=${commentId}`) : await getPostLike(`postId=${post.id}`);
        let data = responseData.data.data.map(v => v.userId)
        let filterData = data.includes(userDetails.id);
        if (filterData) {
            if (commentId) {
                const response = await deletePostLike({commentId: commentId});
                if (response.status === 200) {
                    handleShowComment(id);
                    getPostList();
                }
            } else {
                const response = await deletePostLike({postId: id});
                if (response.status === 200) {
                    getPostList();
                }

            }
        } else {
            if (commentId) {
                const response = await createPostLike({commentId: commentId});
                if (response.status === 200) {
                    handleShowComment(id);
                    getPostList();
                }
            } else {
                const response = await createPostLike({postId: id});
                if (response.status === 200) {
                    getPostList();
                }

            }
        }

    }

    const changeBackgroundImage = async (e) => {
        let formData = new FormData();
        formData.append("backgroundImage", e.target.files[0]);
        let responseData = await updateUserprofile(formData);
        if (responseData.status === 200) {
            getUserInformationbyuser();
        }

    }

    const handleShowComment = async (postId) => {
        setPostId(postId);
        const responseData = await getPostComments(postId);
        if (responseData.status === 200) {
            setCommentsArr(responseData.data.data)
        }
    }

    const findSharePostUserDetails = (id) => {
        let filterUser = (postData || []).filter(val => val.type !== "event").filter(v => v.id === id);
        return filterUser.length ? filterUser[0] : {
            user: {},
            organisation: {},
            event: {}
        }
    }

    const getListOfLikedUsers = async (post) => {
        let response = await getPostLike(`postId=${post.id}`)
        if (response.status === 200) {
            if (response.data.data.length) {
                setShowUserLikedList(true)
            }
            setListOfLikedUsers(response.data.data);
        }
    }

    const accept = async (id) => {
        let obj = {userId: id, status: "accepted"};
        const data = await respondToFriendRequest(obj);
        if (data.status === 200) {
            getAllNotifications();
        }
    }

    const reject = async (id) => {
        let obj = {userId: id, status: "rejected"};
        const data = await respondToFriendRequest(obj);
        if (data.status === 200) {
            getAllNotifications();
        }
    }

    const deleteNotification = async (id) => {
        let obj = {
            status: 'deleted'
        }
        let response = await changeNotificationStatus(obj, id);
        if (response.status === 200) {
            getAllNotifications();
        }
    }

    const navigateToFriends = async (id) => {
        navigate(`/friends/${id.userId}`)
    }

    const navigateToApproval = async () => {
        navigate(`/mycalendar`)
    }

    const navigateToOranisation = async (id) => {
        navigate(`/organisation/${id.organisationId}`, {state: {memebersTabActive: true}})
    }

    const navigateToProfile = async (id) => {
        navigate(`/admin/coach-profile/${id.userId}`);
    }

    const navigateToMyKaliedoscope = async () => {
        navigate(HOME)
    }

    const navigateToOranisationPost = async (id) => {
        navigate(`/organisation/${id.organisationId}`, {state: {postTabActive: true}})
    }

    const navigateToGiving = async (id) => {
        if (id.offerId) {
            navigate(`/time-exchange?offerId=${id.offerId}`);
        } else {
            navigate(`/time-exchange`);
        }
    }

    const navigateToExperience = async (id) => {
        if (id.eventId) {
            navigate(`/event/${id.eventId}`);
        }
    }


    return (
        <LoggedInLayout title={"Your Profile"}>
            <div className='row g-md-4 g-3'>
                <div className="col-12 col-xl-5 col-md-6 d-flex flex-column gap-3">
                    <div className='friends_profile_dashboard'>
                        <div className='m-0 frnd_prof_dash_cover position-relative'
                             style={{backgroundImage: `url(${userprofileData.backgroundImage ? userprofileData.backgroundImage : FriendDashboardCover})`}}>

                            <div
                                className="position-absolute m-3 end-0 top-0">
                                <input type='file' id="file-input" className={"d-none"}
                                       name='profilePic' onChange={changeBackgroundImage}/>

                                <label htmlFor="file-input"
                                       className={"m-0 p-0 tw-bg-white tw-bg-opacity-75 tw-cursor-pointer rounded-circle tw-w-[30px] shadow-sm tw-border tw-border-[#410db7] tw-h-[30px] d-flex justify-content-center align-items-center"}>
                                    <i className="bi bi-image tw-text-sm tw-text-[#410db7]"></i>
                                </label>
                            </div>
                        </div>


                        <div className="px-4 pb-4 position-relative d-flex flex-column gap-3" style={{zIndex: 10}}>
                            <div className='frinds_profile_pic shadow'>
                                <img src={userprofileData.profilePic ? userprofileData.profilePic : "/static/png/Male.png"} alt=''/>
                            </div>

                            <div className="tw-absolute top-0 end-0">
                                <div className="m-2">
                                    <Link
                                        to={"/editprofile"}
                                        className="btn btn-sm rounded-pill px-4 fw-semibold tw-text-xs btn-outline-info">
                                        Edit Profile Info
                                    </Link>
                                </div>
                            </div>

                            <div>
                                <p className='mb-0 frinds_profile_name'>{userprofileData.firstName} {userprofileData.lastName}</p>
                            </div>

                            <div>
                                <p className='frinds_profile_edu'>Email</p>
                                <p className='frinds_profile_edu_sub'>{userprofileData.email} </p>
                            </div>

                            <div className={`${!!userprofileData.mobile ? "" : "d-none"}`}>
                                <p className='frinds_profile_edu'>Phone Number</p>
                                <p className='frinds_profile_edu_sub'>{userprofileData.mobile} </p>
                            </div>

                            <div className={`${!!userprofileData.bio ? "" : "d-none"}`}>
                                <p className='frinds_profile_edu'>Bio</p>
                                <p className='frinds_profile_edu_sub'
                                   dangerouslySetInnerHTML={{__html: userprofileData.bio}}></p>
                            </div>
                        </div>
                    </div>

                    <div className={"d-none"}>
                        <div className="card card-body border-0 tw-rounded-3xl text-center">
                            <p className='mb-0 rec_post_heading'>Quest Progress</p>
                            <p>Coming Soon</p>
                        </div>
                    </div>
                    <div className={notifications.length ? "" : "d-none"}>
                        <div className="card card-body border-0 tw-rounded-3xl text-center d-flex flex-column gap-3">
                            <div className={"d-flex justify-content-between align-items-center"}>
                                <p className='mb-0 montserrat fw-semibold'>
                                    New Notifications {notifications.length ? `(${notifications.length})` : ""}
                                </p>
                                <button
                                    type={"button"}
                                    onClick={changeStatusOfNotifications}
                                    className="btn btn-sm rounded-pill px-4 fw-semibold tw-text-xs btn-outline-info">
                                    Mark All Read
                                </button>
                            </div>
                            {notifications.length === 0 && (
                                <p>Yay, you seen it all</p>
                            )}
                            {notifications.map((v, i) => {
                                return (
                                    <div className='p-3 notification_list' key={i}>
                                        <div className="d-flex flex-nowrap align-items-center gap-2">
                                            <div className={"flex-shrink-0 tw-w-[25px]"}>
                                                <img
                                                    src={Dicline} alt='' onClick={() => deleteNotification(v.id)}
                                                    className="tw-cursor-pointer mx-auto"
                                                />
                                            </div>

                                            <div className='flex-grow-1 p-0 cursor_pointer d-flex flex-column gap-2'
                                                 onClick={(e) => {
                                                     if (v.type === "friend-request") {
                                                         e.stopPropagation();
                                                         navigateToFriends(v.dataObject)
                                                     } else if (v.type === "community" && (v.title === "You are invited to organisation" || v.title === "Someone joined your organisation")) {
                                                         e.stopPropagation();
                                                         navigateToOranisation(v.dataObject)
                                                     } else if (v.type === "admin") {
                                                         e.stopPropagation();
                                                         navigateToProfile(v.dataObject)
                                                     } else if (v.type === "Supporter") {
                                                         e.stopPropagation();
                                                         navigateToApproval(v.dataObject)
                                                     } else if (v.type === "my-kaliedoscope") {
                                                         e.stopPropagation();
                                                         navigateToMyKaliedoscope(v.dataObject)
                                                     } else if (v.type === "community") {
                                                         e.stopPropagation();
                                                         navigateToOranisationPost(v.dataObject)
                                                     } else if (v.type === "giving") {
                                                         e.stopPropagation();
                                                         navigateToGiving(v.dataObject);
                                                     } else if (v.type === 'experience') {
                                                         e.stopPropagation();
                                                         navigateToExperience(v.dataObject);
                                                     }
                                                 }}>
                                                <div
                                                    className={"d-flex flex-nowrap justify-content-between align-items-center"}>
                                                    <button
                                                        className={`notification_button ${getStatusName(v.type).color}`}>
                                                        {getStatusName(v.type).name}
                                                    </button>
                                                    <div>
                                                        <p className='mb-0 notification_time'>
                                                            {moment(v.createdAt).format('hh:mm')} at {moment(v.createdAt).format('MMM DD, YYYY')}
                                                        </p>
                                                    </div>
                                                </div>

                                                <p className='montserrat fw-semibold tw-text-sm text-start lh-base'>{v.body}</p>

                                                <div>
                                                    {getSubTitle(v.title, v.dataObject, accept, reject, v.type)}
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className={"d-none"}>
                        <div className="card card-body border-0 tw-rounded-3xl text-center">
                            <p className='mb-0 rec_post_heading'>Chat List</p>
                        </div>
                    </div>

                </div>
                <div className="col-12 col-xl-7 col-md-6 d-flex flex-column gap-3">
                    <div>
                        <div
                            className="card card-body bg-logged-in shadow-sm tw-border-[#ece7fd] tw-rounded-3xl text-center">
                            <p className='mb-0 montserrat fw-semibold'>{greeting()}, {userDetails.firstName + " " + userDetails.lastName}</p>
                        </div>
                    </div>

                    <div className='d-flex flex-column gap-3'>
                        {(postData || []).filter(val => val.type !== "event").filter(val => val.userId === userDetails.id).length === 0 ?
                            <div className="card card-body border-0 tw-rounded-3xl text-center">
                                <p className="text-center">
                                    ~~ You haven't posted yet. When you do, your Posts will show up here ~~
                                </p>
                            </div>
                            :
                            <div className="card card-body border-0 tw-rounded-3xl text-center">
                                <p className='mb-0 rec_post_heading'>Recent Posts</p>
                            </div>
                        }

                        {(postData || []).filter(val => val.type !== "event").filter(val => val.userId === userDetails.id).slice(0, 5).map((v, i) => {
                            return (
                                <div
                                    className='card card-body tw-rounded-3xl border-0 shadow-sm d-flex flex-column gap-3'
                                    key={i}>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center gap-2">
                                            <div className='post_cards_pro_img'>
                                                <img src={v.user.profilePic ? v.user.profilePic : "/static/png/Male.png"}
                                                     alt=''/>
                                            </div>
                                            <div>
                                                <p className='post_cards_pro_name'>{v.user.firstName} {v.user.lastName}</p>
                                                <p className='post_cards_pro_post_date'>{moment(v.createdAt).fromNow()}</p>
                                            </div>
                                        </div>
                                        <div>
                                            {
                                                <>
                                                    <img src={More}
                                                         id="settings_friend_btn_drodwn cursor_pointer"
                                                         data-bs-toggle="dropdown" aria-expanded="false"/>

                                                    <ul className="dropdown-menu"
                                                        aria-labelledby="settings_friend_btn_drodwn">
                                                        <li className="dropdown-item settings_friend_btn_ul_li"
                                                            onClick={() => deletePost(v.id)}>Delete
                                                        </li>
                                                    </ul>
                                                </>
                                            }
                                        </div>
                                    </div>

                                    {v.type === "image" && (
                                        <div>
                                            {/* Carousel view for lot-of-images */}
                                            {v.attachment.length > 1 &&
                                                <ImagePostCarousel images={v.attachment}/>}
                                            {v.attachment.length === 1 &&
                                                <img className="post_img" alt={"Post"} src={v.attachment[0]}/>
                                            }
                                        </div>
                                    )}

                                    {v.type === "document" && (
                                        <DocumentPostView
                                            url={v.attachment[0]}
                                            count={v.count || 5}
                                        />
                                    )}

                                    {v.type === "video" && (
                                        <div>
                                            <video controls className={"tw-max-h-[500px] mx-auto"}>
                                                <source src={v.attachment[0]} type="video/mp4"/>
                                            </video>
                                        </div>
                                    )}

                                    <p className='mb-0 post_cards_post_text'>
                                        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a target="_blank" rel={"noreferrer"}
                                               className={"text-primary"}
                                               href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}>{v.description}</Linkify>
                                    </p>

                                    {v.linkedPostId ? <>
                                        <div className="row m-0">
                                            <div
                                                className="col-1 p-0 d-flex justify-content-center align-items-center">
                                                <img alt="user" className="newsfeed_logo"
                                                     src={findSharePostUserDetails(v.linkedPostId).user.profilePic ? findSharePostUserDetails(v.linkedPostId).user.profilePic : Logo}/>
                                            </div>
                                            <div
                                                className="col-11 p-0 d-flex align-items-center ps-3">
                                                <p className="mb-0 newsfeed_web_name d-flex"
                                                   onClick={(e) => {
                                                       e.stopPropagation();
                                                       if (findSharePostUserDetails(v.linkedPostId).organisationId) {
                                                           navigate(`/organisation/${findSharePostUserDetails(v.linkedPostId).organisationId}`)
                                                       } else {
                                                           navigate(`/friends/${findSharePostUserDetails(v.linkedPostId).user.id}`)
                                                       }
                                                   }}>{findSharePostUserDetails(v.linkedPostId).organisationId ? <>
                                                    {/* {findSharePostUserDetails(v.linkedPostId).organisation.name} */}
                                                    {findSharePostUserDetails(v.linkedPostId).user.firstName} {findSharePostUserDetails(v.linkedPostId).user.lastName}
                                                </> : <> {findSharePostUserDetails(v.linkedPostId).user.firstName} {findSharePostUserDetails(v.linkedPostId).user.lastName}</>}

                                                    {/* {v.linkedPostId ? <p className="mb-0">&nbsp;&nbsp;<span>shared a</span>&nbsp;&nbsp;post</p> : null} */}
                                                </p>
                                                <div className="d-flex align-items-center">
                                                    {/* <p className="mb-0 newsfeed_followers">36,000 followers</p> */}
                                                    <p className="mb-0 newsfeed_time ms-4">{moment(findSharePostUserDetails(v.linkedPostId).createdAt).fromNow()}</p>
                                                    {findSharePostUserDetails(v.linkedPostId).subCategory &&
                                                        <div className="category_badge">
                                                            {findSharePostUserDetails(v.linkedPostId).subCategory[0]}
                                                        </div>}

                                                </div>
                                            </div>
                                        </div>

                                        <p className="post_details">
                                            <Linkify
                                                componentDecorator={(decoratedHref, decoratedText, key) => (
                                                    <a
                                                        className={"text-primary"}
                                                        target="_blank"
                                                        rel={"noreferrer"}
                                                        href={decoratedHref}
                                                        key={key}>
                                                        {decoratedText}
                                                    </a>
                                                )}>{findSharePostUserDetails(v.linkedPostId).description}</Linkify>
                                        </p>

                                        {
                                            findSharePostUserDetails(v.linkedPostId).type === "image" ? (
                                                <div className="row m-0 mt-2 ">
                                                    <div className="col-12 p-0">
                                                        {findSharePostUserDetails(v.linkedPostId).attachment.length > 1 ?
                                                            <div id="demo"
                                                                 className="carousel slide d-flex justify-content-center"
                                                                 data-bs-ride="carousel"
                                                                 style={{background: 'black'}}>
                                                                <div className="carousel-inner w-50">
                                                                    {findSharePostUserDetails(v.linkedPostId).attachment.map((v, i) => {
                                                                        return <div
                                                                            className={`carousel-item ${i === 0 ? "active" : ""}`}
                                                                            key={i}>
                                                                            <img src={v}
                                                                                 alt="Los Angeles"
                                                                                 className="d-block"
                                                                                 width="100%"/>
                                                                            <div
                                                                                className="overlay_div_image">
                                                                                <div
                                                                                    className="d-flex justify-content-end align-items-center px-4 pb-2 h-100">
                                                                                    <div>
                                                                                        <img
                                                                                            src={dwnldArrow}
                                                                                            alt="Los Angeles"
                                                                                            className="cursor_pointer"
                                                                                            onClick={() => {
                                                                                                window.open(v)
                                                                                            }}/>
                                                                                        <img
                                                                                            src={view_icon}
                                                                                            alt="Los Angeles"
                                                                                            className="ms-3 cursor_pointer"
                                                                                            onClick={() => {
                                                                                                window.open(v)
                                                                                            }}/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    })}
                                                                </div>


                                                                <button
                                                                    className="carousel-control-prev"
                                                                    type="button" data-bs-target="#demo"
                                                                    data-bs-slide="prev">
                                                                                <span
                                                                                    className="carousel-control-prev-icon"></span>
                                                                </button>
                                                                <button
                                                                    className="carousel-control-next"
                                                                    type="button" data-bs-target="#demo"
                                                                    data-bs-slide="next">
                                                                                <span
                                                                                    className="carousel-control-next-icon"></span>
                                                                </button>
                                                            </div> : <img className="post_img"
                                                                          src={findSharePostUserDetails(v.linkedPostId).attachment[0]}/>}


                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )}

                                        {findSharePostUserDetails(v.linkedPostId).type === "document" ? (
                                            <div className='row m-0 mt-3 post_heading_bg'>
                                                <div className='col-12 p-0 px-4 py-4 cursor_pointer'
                                                     onClick={() => window.open(findSharePostUserDetails(v.linkedPostId).attachment[0])}>
                                                    <div className="file_back_div">
                                                        <div className="row m-0">
                                                            <div className="col-2 p-0 file_image">

                                                            </div>
                                                            <div
                                                                className="col-7 p-0 ps-4 d-flex align-items-center justify-content-between">
                                                                <div><p
                                                                    className="mb-0 file_name">{getFileName(findSharePostUserDetails(v.linkedPostId).attachment[0])}</p>
                                                                    <span
                                                                        className="pages_count">{findSharePostUserDetails(v.linkedPostId).count} pages</span>
                                                                    <p className="file_type">{getFileType(findSharePostUserDetails(v.linkedPostId).attachment[0])}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {findSharePostUserDetails(v.linkedPostId).type === "video" ? (
                                            <div className='row m-0 mt-3 post_heading_bg'>
                                                <div className='col-12 p-0 px-4 py-4'>
                                                    <video width="100%" height="240" controls>
                                                        <source
                                                            src={findSharePostUserDetails(v.linkedPostId).attachment && findSharePostUserDetails(v.linkedPostId).attachment[0]}
                                                            type="video/mp4" preload="metadata"/>
                                                    </video>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}


                                    </> : null}

                                    <div className='d-flex align-items-center gap-2 like_cmt_counts'>
                                        <div
                                            className='lcs_box like_box d-flex align-items-center justify-content-center cursor_pointer'
                                            onClick={() => getListOfLikedUsers(v)}>
                                            <img src={Like} alt=""/>
                                        </div>
                                        <span className="like_cmt_counts">
                                            {v.reactionCount}
                                        </span>

                                        <i className="bi bi-dot"></i>

                                        <p className='mb-0 like_cmt_counts cursor_pointer'>

                                            <span className="cursor_pointer" onClick={() => handleShowComment(v.id)}>
                                                {v.commentCount} comments
                                            </span>
                                        </p>
                                    </div>

                                    <div
                                        className='d-flex align-items-center justify-content-between'>

                                        <div className='post_cards_pro_img_on_cmnt'>
                                            <img
                                                src={userDetails.profilePic ? userDetails.profilePic : "/static/png/Male.png"}
                                                alt=''/>
                                        </div>

                                        <div
                                            className='cmnt_box_input_with_lcs ps-3 d-flex flex-column justify-content-center'>

                                            <div className='row m-0'>

                                                {<div className='col-8 p-0 d-flex align-items-center'>
                                                    <input className='write_a_cmnt_input' type="text"
                                                           placeholder='Add a comment...'
                                                           value={index === i ? comment : ""}
                                                           onChange={(event) => {
                                                               setIndex(i);
                                                               setComment(event.target.value);
                                                           }}
                                                           onKeyDown={(event) => {
                                                               if (event.key === "Enter") {
                                                                   submitComment("text", v, event.target.value, v.id);
                                                               }
                                                           }}
                                                    />
                                                </div>
                                                }

                                                <div
                                                    className='col-4 p-0 px-2 d-flex align-items-center justify-content-end'>
                                                    <div
                                                        className='lcs_circle d-flex align-items-center justify-content-center'>
                                                        <img src={OutlinedLike} className="cursor_pointer"
                                                             onClick={() => handleLike(v.id, '', v)}
                                                             alt=""/>
                                                    </div>
                                                    <div
                                                        className='lcs_circle d-flex align-items-center justify-content-center ms-2'>
                                                        <img src={OutlinedComment}
                                                             className="cursor_pointer"
                                                             alt=""
                                                             onClick={() => submitComment("text", v, comment)}/>
                                                    </div>
                                                    {/* <div className='lcs_circle d-flex align-items-center justify-content-center'>
                                                                <img config={OutlinedShare} alt="" />
                                                            </div> */}
                                                </div>

                                            </div>


                                        </div>

                                    </div>

                                    {
                                        postId === v.id && commentsArr.commentArray && commentsArr.commentArray.map((curr, i) => {
                                            return <div className={`row m-0 px-3 pb-3`} key={i}>
                                                <div className='EventProfilePic_section py-3 p-0 evt_bottom_border'>

                                                    <div className='EventProfilePic'>
                                                        <img className='EventProfilePic_img'
                                                             src={curr.user.profilePic ? curr.user.profilePic : "/static/png/Male.png"}
                                                             alt=""/>
                                                    </div>

                                                </div>

                                                <div className='EventComments_post py-3 p-0 px-2 evt_bottom_border'>
                                                    <p className='mb-0 user_commented'
                                                       dangerouslySetInnerHTML={{__html: curr.comment}}></p>

                                                    {
                                                        curr.attachment ?
                                                            <img width="300px" height="150px" className="my-2"
                                                                 src={curr.attachment} alt=""/> : null
                                                    }
                                                    <p className='mb-0 mt-2 user_commented_like_cmnt'>
                                                            <span className="cursor_pointer"
                                                                  onClick={() => handleLike(v.id, curr.id, v)}>{curr.reactionCount} Likes</span> &bull;
                                                        <span className="cursor_pointer" onClick={() => {
                                                            setShowRepliedCommentInput(true);
                                                            setShowRepliedCommentId(curr.id);
                                                        }}> {curr.repliesCount} Replies</span></p>
                                                </div>

                                                {showRepliedCommentId === curr.id && showRepliedCommentInput && <>
                                                    <div className='row m-0 mt-3'>
                                                        <div
                                                            className='col-12 p-0 ps-4 d-flex align-items-center justify-content-end'>

                                                            <div className="input_profile_img">
                                                                <img
                                                                    src={userDetails && userDetails.profilePic ? userDetails.profilePic : "/static/png/Male.png"}
                                                                    alt=""/>
                                                            </div>

                                                            <div className="d-flex" style={{width: '90%'}}>
                                                                <input type='text'
                                                                       className='comment_input ms-3 ps-4 pb-1'
                                                                       placeholder='Add a comment...'
                                                                       value={subComment}
                                                                       onChange={(event) => {
                                                                           setSubComment(event.target.value);
                                                                       }}
                                                                       onKeyDown={(event) => {
                                                                           if (event.key === "Enter") {
                                                                               submitComment("text", v, event.target.value, curr.id);
                                                                           }
                                                                       }}/>

                                                                <span
                                                                    className="input-group-text comment_input_capture d-flex align-items-center justify-content-around"
                                                                    id="basic-addon1"
                                                                >
                                                                        {/* <img className="" config={Smile} /> */}
                                                                    <input type='file' id="file-input"
                                                                           className="d-none" multiple
                                                                           onChange={(e) => submitComment("image", v, e.target.files[0])}/>
                                                                        <label htmlFor="file-input">
                                                                            <img className="ms-3" src={Photo_Video}/>
                                                                        </label>
                                                                    </span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div
                                                        className="d-flex justify-content-end align-items-end flex-column">
                                                        {curr.replies.map((v, i) => {

                                                            return <div className="d-flex align-items-center"
                                                                        style={{width: '93%'}} key={i}>
                                                                <div className='EventProfilePic_section py-3 p-0'>

                                                                    <div className='EventProfilePic'>
                                                                        <img className='EventProfilePic_img'
                                                                             src={v.user.profilePic ? v.user.profilePic : "/static/png/Male.png"}
                                                                             alt=""/>
                                                                    </div>


                                                                </div>

                                                                <div className='EventComments_post py-3 p-0 px-2'>
                                                                    <p className='mb-0 user_commented'
                                                                       dangerouslySetInnerHTML={{__html: v.comment}}></p>
                                                                </div>
                                                            </div>
                                                        })}
                                                    </div>
                                                </>}

                                            </div>
                                        })
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <LikeProfileModal isOpen={showUserLikedList} setShowUserLikedList={() => setShowUserLikedList(false)}
                              listOfLikeUsers={listOfLikeUsers}/>

        </LoggedInLayout>
    )
}

export default Profile;