import React, {useState} from 'react'
import PictureVacter from '../../../assets/svg/Organization/PictureVacter.svg';
import {createOrg} from '../../../utils/apiUrlsSetup'
import {useNavigate} from 'react-router-dom';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const CreateOrganizationModal = (props) => {
  const navigate = useNavigate();

  const [details, setDetails] = useState({
    name: "",
    description: "",
    image: "",
    privacy: "",
    inviteCode: ""
  })


  const [imgUrl, setImgUrl] = useState()

  const handleChange = (event) => {
    const name = event.target.name
    const value = name === 'image' ? event.target.files[0] : event.target.value
    if (name === 'image') {
      setImgUrl(URL.createObjectURL(event.target.files[0]))
    }
    const data = {...details}
    data[name] = value
    setDetails(data)
  }

  const createOrganization = async () => {
    const formData = new FormData()
    formData.append('name', details.name)
    formData.append('description', details.description)
    formData.append('image', details.image)
    formData.append('privacy', details.privacy)
    formData.append('inviteCode', details.inviteCode)
    const response = await createOrg(formData)
    if (response.status === 200) {
      await props.setOpenModal(false)
      navigate(`/organisation/${response.data.data.id}`)
    }
  }

  return (
    <Modal
      isOpen={props.openModal}
      toggle={() => props.setOpenModal(prev => !prev)}
      size='lg'
    >
      <ModalHeader toggle={() => props.setOpenModal(prev => !prev)} className={"text-uppercase tw-font-medium"}>Create New Community</ModalHeader>
      <div>
        <div className='createOrgCover d-flex flex-column align-items-center justify-content-center'>
          <label className={imgUrl ? "cover_photo_label_imgUrl" : "cover_photo_label"}>
            <img
              className={`${imgUrl ? "cover_photo_imgUrl" : "cover_photo"} tw-object-cover tw-object-center`}
              src={imgUrl ? imgUrl : PictureVacter}
              alt=''/>
            <input type="file" name="image" style={{display: "none"}} onChange={handleChange}/>
          </label>
          <p className='mb-0 upload_info mt-3'>Upload a cover photo here (919 x 197px)*</p>
        </div>
        <p className={`image_validation_Error pt-2 text-center ${imgUrl ? 'd-none' : 'd-block'}`}>
          *Please Upload cover Photo
        </p>
      </div>
      <ModalBody>
        <div className="d-flex flex-column gap-4">
          <div className={"form-group"}>
            <label className={"mb-1 tw-font-medium text-uppercase"}>Community name*</label>
            <input
              type="text"
              className="form-control"
              placeholder="Write the name of your community here"
              name='name'
              onChange={handleChange}/>
          </div>

          <div className={"form-group"}>
            <label className={"mb-1 tw-font-medium text-uppercase"}>Invite Code*</label>
            <input
              type="text"
              className="form-control"
              placeholder="Customize your invite code here"
              name='inviteCode'
              onChange={handleChange}/>
          </div>

          <div className={"form-group"}>
            <label className={"mb-1 tw-font-medium text-uppercase"}>Privacy*</label>

            <div className="d-flex gap-5 tw-items-center">
              <div className="form-check">
                <input className="form-check-input" type="radio" name="privacy" value="public" onChange={handleChange}/>
                <label className="form-check-label">
                  Public (Searchable)
                </label>
              </div>

              <div className="form-check">
                <input className="form-check-input" type="radio" name="privacy" value="private"
                       onChange={handleChange}/>
                <label className="form-check-label">
                  Private (Non-searchable)*
                </label>
              </div>
            </div>
          </div>

          <div className={"form-group"}>
            <label className={"mb-1 tw-font-medium text-uppercase"}>Description</label>
            <textarea className="form-control text_area"
                      placeholder='Write the description of your community here' rows="3" name='description'
                      onChange={handleChange}></textarea>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div
          className='d-flex align-items-center gap-2 justify-content-lg-end justify-content-md-end justify-content-center'>
          <button
            className='mdl_cancel_btn me-2'
            onClick={() => {
              setImgUrl("");
              props.setOpenModal(false);
            }}>
            Cancel
          </button>

          <div className="mdl_create_btn">
            <button
              className='mdl_create_btn mdl_create_btn_button'
              onClick={() => {
                createOrganization();
                setImgUrl(undefined);
              }}
              disabled={
                ((details.name && details.description && details.image && details.privacy) === "")
              }
            >
              Create
            </button>

            {
              !imgUrl &&
              <div className={`tooltip_create_btn bg-dark p-2 py-1 shadow ms-auto rounded`}>
                Please upload cover photo
              </div>
            }

          </div>

        </div>
      </ModalFooter>
    </Modal>
  )
}

export default CreateOrganizationModal
