import React from "react";
import {reportAnCommentApi, reportAnCommentApiV2} from "../../../../utils/apiUrlsSetup";
import Swal from "sweetalert2";
import {Modal, ModalBody, ModalHeader} from "reactstrap";

/**
 *
 * @param {{
 *   commentId: number,
 *   afterSubmit: Function
 * }} props
 * @constructor
 */
export default function CommentReportButton(props) {
  const [modal, setModal] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const formObject = {}; // Initialize empty object

    // Convert FormData to JSON object
    formData.forEach((value, key) => {
      formObject[key] = value;
    });

    await reportAnCommentApi(props.commentId, {isReported: true});
    await reportAnCommentApiV2({
      commentId: props.commentId,
      reason: formObject.reason
    });
    await props.afterSubmit()
    setModal(false)
    Swal.fire("Post reported", "Comment has been reported. <br/> Our admin will review soon.", "success")
  }

  return (
    <>
      <li
        className="dropdown-item montserrat tw-cursor-pointer tw-font-medium tw-text-sm py-1 tw-min-w-[200px]"
        onClick={() => setModal(true)}>
        <i className="bi bi-exclamation-circle me-1"/> Report Comment
      </li>

      <Modal isOpen={modal} toggle={() => setModal(false)} size={"lg"}>
        <ModalHeader toggle={() => setModal(false)} className={"align-items-start border-0 pb-0 tw-font-semibold"}>
          Report this comment
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <p>Select a reason that applies</p>

            <div className="d-flex gap-2 flex-wrap tw-w-full mt-2">
              {[
                "Harassment",
                "Fraud or scam",
                "Spam",
                "Misinformation",
                "Hateful speech",
                "Threats or violence",
                "Self-harm",
                "Graphic content",
                "Dangerous or extremist organizations",
                "Sexual content",
                "Fake account",
                "Hacked account",
                "Child exploitation",
                "Illegal goods and services",
                "Infringement"
              ].map((e, i) => {
                return (
                  <div key={e}>
                    <input type="radio" required className="btn-check" name="reason" id={`option_${i}`}
                           autoComplete="off"/>
                    <label className="btn btn-outline-secondary rounded-pill px-3 btn-sm tw-font-medium"
                           htmlFor={`option_${i}`}>{e}</label>
                  </div>
                )
              })}
            </div>

            <div className={"d-flex tw-items-center justify-content-end mt-3"}>
              <button
                className={`tw-text-sm tw-bg-[#5458f7] disabled:tw-bg-opacity-60 disabled:tw-cursor-default tw-px-6 tw-text-white inter tw-font-medium tw-rounded-3xl tw-cursor-pointer tw-py-1`}
              >
                Submit Report
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  )
}